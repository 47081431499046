<div id="school-vtp-vc-mapping" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/school-vt-mapping'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        {{ schoolVTMappingModel.Name }}
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        New School VT Mapping
                    </div>
                    <div class="subtitle secondary-text">
                        <span>School VT Mapping Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-sector-job-role-button" [disabled]="schoolVTMappingForm.invalid"
                *ngIf="PageRights.ActionType ==='new'" (click)="saveOrUpdateSchoolVTMappingDetails()">
                <span>ADD</span>
            </button>

            <button mat-raised-button class="save-sector-job-role-button" *ngIf="PageRights.ActionType ==='edit'"
                (click)="saveOrUpdateSchoolVTMappingDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="schoolVTMappingForm" [formGroup]="schoolVTMappingForm" class="sector-job-role w-100-p"
                    fxLayout="column" fxFlex>
                    <!-- <mat-tab label="Basic Info"> -->
                    <div class="tab-content p-24" fusePerfectScrollbar>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>Select StateCode</mat-label>

                                <mat-select name="stateCode" formControlName="StateCode" required
                                    [disabled]="PageRights.IsReadOnly">
                                    <mat-option [value]="statecodeItem.Id" *ngFor="let statecodeItem of statecodeList;">
                                        {{ statecodeItem.Name }} ({{ statecodeItem.Id }})
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="schoolVTMappingForm.controls.StateCode.invalid">
                                    {{getErrorMessage(schoolVTMappingForm, 'StateCode')}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>School Sector Job Roles</mat-label>

                                <mat-select name="schoolSectorJobRolesId" formControlName="SchoolSectorJobRolesId"
                                    required [disabled]="PageRights.IsReadOnly"
                                    (selectionChange)="onChangeVT($event.value);status('SchoolSectorJobRoles')" #SelectSchoolJobRole>
                                    <mat-select-filter class="select-filter" *ngIf="SelectSchoolJobRole.focused"
                                        [array]="roleList" (filteredReturn)="this.filteredSchoolJobRoleItems = $event"
                                        [displayMember]="'Name'"></mat-select-filter>
                                    <mat-option [value]="roleItem.Id"
                                        *ngFor="let roleItem of filteredSchoolJobRoleItems">
                                        {{ roleItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="schoolVTMappingForm.controls.SchoolSectorJobRolesId.invalid">
                                    {{getErrorMessage(schoolVTMappingForm, 'SchoolSectorJobRolesId')}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>Approval Year</mat-label>

                                <mat-select name="academicYearId" formControlName="AcademicYearId" required
                                    [disabled]="PageRights.IsReadOnly">
                                    <mat-option [value]="academicYearItem.Id"
                                        *ngFor="let academicYearItem of academicyearList">
                                        {{ academicYearItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="schoolVTMappingForm.controls.AcademicYearId.invalid">
                                    {{getErrorMessage(schoolVTMappingForm, 'AcademicYearId')}}
                                </mat-error>
                            </mat-form-field>

                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">

                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>VTP</mat-label>

                                <mat-select name="schoolSectorJobRolesVTPId" formControlName="SchoolSectorJobRolesVTPId"
                                    required [disabled]="PageRights.IsReadOnly"
                                    (selectionChange)="onChangeVTP($event.value);status('VTP')">
                                    <mat-option [value]="vtpItem.Id" *ngFor="let vtpItem of vtpList">
                                        {{ vtpItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="schoolVTMappingForm.controls.SchoolSectorJobRolesVTPId.invalid">
                                    {{getErrorMessage(schoolVTMappingForm, 'SchoolSectorJobRolesVTPId')}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>VC</mat-label>

                                <mat-select name="schoolVTPVCId" formControlName="SchoolVTPVCId" required
                                    [disabled]="PageRights.IsReadOnly">
                                    <mat-option [value]="vtpItem.Id" *ngFor="let vtpItem of vocationalCoordinatorList">
                                        {{ vtpItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="schoolVTMappingForm.controls.SchoolVTPVCId.invalid">
                                    {{getErrorMessage(schoolVTMappingForm, 'SchoolVTPVCId')}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>VT</mat-label>

                                <mat-select name="vTId" formControlName="VTId" required
                                    [disabled]="PageRights.IsReadOnly">
                                    <mat-option [value]="vtpItem.Id" *ngFor="let vtpItem of vocationList">
                                        {{ vtpItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="schoolVTMappingForm.controls.VTId.invalid">
                                    {{getErrorMessage(schoolVTMappingForm, 'VTId')}}
                                </mat-error>
                            </mat-form-field>


                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Date Of Allocation</mat-label>
                                <input matInput name="dateOfAllocation" formControlName="DateOfAllocation" required
                                    [matDatepicker]="dateOfAllocation" [disabled]="PageRights.IsReadOnly"
                                    [max]="CurrentDate">
                                <mat-datepicker-toggle matSuffix [for]="dateOfAllocation"></mat-datepicker-toggle>
                                <mat-datepicker #dateOfAllocation></mat-datepicker>

                                <mat-error *ngIf="schoolVTMappingForm.controls.DateOfAllocation.invalid">
                                    {{getErrorMessage(schoolVTMappingForm, 'DateOfAllocation')}}
                                </mat-error>
                            </mat-form-field>


                            <mat-icon matDatepickerToggleIcon
                                (click)="clearDateValueInFormControl(schoolVTMappingForm, 'DateOfAllocation', $event)"
                                *ngIf="schoolVTMappingForm.controls.DateOfAllocation.value && PageRights.ActionType !='view'">
                                clear
                            </mat-icon>
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                *ngIf="PageRights.ActionType ==='edit' || PageRights.ActionType ==='view'">
                                <mat-label>Date Of DeAllocation</mat-label>
                                <input matInput name="dateOfDeAllocation" formControlName="DateOfDeAllocation"
                                    [matDatepicker]="dateOfDeAllocation" [disabled]="PageRights.IsReadOnly"
                                    (dateChange)="onChangeDateEnableDisableCheckBox(schoolVTMappingForm, $event)"
                                    [max]="CurrentDate" [min]="schoolVTMappingForm.controls.DateOfAllocation.value">
                                <mat-datepicker-toggle matSuffix [for]="dateOfDeAllocation"></mat-datepicker-toggle>
                                <mat-datepicker #dateOfDeAllocation></mat-datepicker>

                                <mat-error *ngIf="schoolVTMappingForm.controls.DateOfDeAllocation.invalid">
                                    {{getErrorMessage(schoolVTMappingForm, 'DateOfDeAllocation')}}
                                </mat-error>

                            </mat-form-field>
                            <mat-icon matDatepickerToggleIcon
                                (click)="clearDateValueInFormControl(schoolVTMappingForm, 'DateOfDeAllocation', $event)"
                                *ngIf="schoolVTMappingForm.controls.DateOfDeAllocation.value &&  PageRights.ActionType !='view'">
                                clear
                            </mat-icon>


                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="0 1 calc(35%)" fxFlex.lt-md="0 1 calc(70%)"
                                fxFlex.lt-sm="100%">
                                <mat-label>Remarks</mat-label>
                                <input matInput name="remarks" formControlName="Remarks" placeholder="Ex. remarks">
                                <mat-error *ngIf="schoolVTMappingForm.controls.Remarks.invalid">
                                    {{getErrorMessage(schoolVTMappingForm, 'Remarks')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start start">
                            <span class="checkbox-space">Is Active?</span>
                            <mat-checkbox matInput formControlName="IsActive" [disabled]="PageRights.IsReadOnly">
                            </mat-checkbox>
                        </div>
                    </div>
                    <!-- </mat-tab>
                    </mat-tab-group> -->
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>