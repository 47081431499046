<div id="activity" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    Last Activity
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <!-- <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter (keyup)="applySearchFilter($event.target.value)" placeholder="Search for a ">
                </div>
            </div> -->
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <!-- <button mat-raised-button [routerLink]="['./','new']" skipLocationChange="true" replaceUrl="false" class="add-job-role-button fuse-white mt-24 mt-md-0">
                <span>ADD</span>
            </button>
            <a *ngIf="PageRights.IsExport" class="export-excel"
                    (click)="exporter.exportTable('xlsx', {fileName:'SectorJobRoles', sheet: 'SectorJobRoles', Props: {Author: 'Lighthouse'}})">
                    <mat-icon> cloud_download</mat-icon>
                </a> -->
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->
        <div class="padding-bottom">
            <mat-tab-group selectedIndex="0" (selectedTabChange)="changeTab($event)">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>All Activity</span>
                    </ng-template>

                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Diary Entry</span>
                    </ng-template>

                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Change Request</span>
                    </ng-template>

                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Approvals</span>
                    </ng-template>

                </mat-tab>
            </mat-tab-group>
        </div>

        <!-- CONTENT CARD -->
        <div class="content-card" fxFlex fusePerfectScrollbar>

            <ng-container matColumnDef="Status" *ngIf="tabIndex===0">
                <p class="fs-25 text-black font-weight-bold mt-lg-3 mt-10 text-center mb-25" style="font-size: 25px;">
                    <b>Notice</b>
                </p>
                <div *ngFor="let activityResponseItems of activityResponse">
                    <!-- <div *ngFor="let activityResponseApprovalItems of activityResponseItems.activity"> -->
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap.lt-md="0px">
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(49%)" fxFlex.lt-md="0 1 calc(49%)" fxFlex.lt-sm="49%"
                            style="margin-right: 0px;display: none;" class="border-right border-grey-500 p-5">

                            <p class="heading-of-activity">Approvals</p>
                            <div class="border-top border-grey-500 px-10 py-20" style="width: 100%;margin: 0;">

                                <div *ngFor="let activityResponseApprovalItems of activityResponseItems.activity">
                                    <div *ngIf="activityResponseApprovalItems.ActivityType=='Approval'"
                                        style="padding:5px">
                                        <a *ngIf="activityResponseApprovalItems.ActivitySubType=='InternshipApproval'" class="a-tag-activity d-flex text-black"
                                            style="text-decoration: none;margin-bottom: 10px;"
                                            [routerLink]="['/internship-approval']">
                                            <div style="width:10%;float: left;">
                                                <img [src]="ApiBaseUrlImage+activityResponseApprovalItems.StudentPhoto"
                                                    class="rounded-circle me-3" style="border-radius: 50%;" alt srcset>
                                            </div>
                                            <div style="width:85%;float: left;margin-left:5px">
                                                <div style="width:100%;float: left;">
                                                    <b class="text-black d-block max-w-500"><span
                                                            class="student-name-activicty">{{activityResponseApprovalItems.StudentName}}
                                                            Applied for
                                                            Internship -
                                                            <span
                                                                style="color:#0d6efd!important;text-decoration: none;">View</span></span></b>
                                                </div>
                                                <div style="width:100%;float: left;">
                                                    <span class="fw-400 fs-13 mt-5px d-block decrption"
                                                        style=" text-align: justify;">
                                                        {{activityResponseApprovalItems.ActivityDesc}}</span>
                                                </div>
                                            </div>
                                        </a>

                                        <a *ngIf="activityResponseApprovalItems.ActivitySubType=='Gratitude'" class="a-tag-activity d-flex text-black"
                                        style="text-decoration: none;margin-bottom: 10px;"
                                        [routerLink]="['/gratitude-approved-vt']">
                                        <div style="width:10%;float: left;">
                                            <img [src]="ApiBaseUrlImage+activityResponseApprovalItems.StudentPhoto"
                                                class="rounded-circle me-3" style="border-radius: 50%;" alt srcset>
                                        </div>
                                        <div style="width:85%;float: left;margin-left:5px">
                                            <div style="width:100%;float: left;">
                                                <b class="text-black d-block max-w-500"><span
                                                        class="student-name-activicty">{{activityResponseApprovalItems.StudentName}}
                                                        has Applied for Gratitude approval -
                                                        <span
                                                            style="color:#0d6efd!important;text-decoration: none;">View</span></span></b>
                                            </div>
                                            <div style="width:100%;float: left;">
                                                <span class="fw-400 fs-13 mt-5px d-block decrption"
                                                    style=" text-align: justify;">
                                                    {{activityResponseApprovalItems.ActivityDesc}}</span>
                                            </div>
                                        </div>
                                        </a>
                                        <p class="small text-gray-600 fs-13 activity-timing mt-5px time-activity">
                                            {{activityResponseApprovalItems.ActivityDateTime | date:"HH:mm a"}}</p>
                                    </div>


                                </div>

                            </div>
                            <!-- <div *ngIf="NoDataMessage==true" style="padding:5px;width: 100%;">
                                <p style="width:100%;float:left;color:white;" *ngIf="NoDataMessage==true">........</p>
                            </div> -->

                            <!-- <ng-template #other_content>No Data</ng-template> -->

                        </div>
                    </div>
                    <!-- </div> -->
                    <!--/Approvals end-->

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap.lt-md="0px">
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(49%)" fxFlex.lt-md="0 1 calc(49%)" fxFlex.lt-sm="49%">
                        </div>

                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="right-content"
                            appearance="outline" fxFlex="0 1 calc(49%)" fxFlex.lt-md="0 1 calc(49%)" fxFlex.lt-sm="49%"
                            style="height: auto;" class="border-left border-grey-500 p-5">
                            <p class="heading-of-activity">Notice Board</p>
                            <div class="border-top border-grey-500 px-10 py-20" style="width: 100%;margin: 0;">
                                <div *ngFor="let activityResponseNoticeBoardItems of activityResponseItems.activity">
                                    <div *ngIf="activityResponseNoticeBoardItems.ActivityType=='NoticeBoard'"
                                        style="padding:5px;margin-top: 10px;">
                                        <!-- <p class="small text-gray-600 fs-13 activity-timing mt-5px  time-activity">
                                        {{activityResponseNoticeBoardItems.ActivityDateTime | date:"HH:mm a"}}</p>
                                    <a class="a-tag-activity d-flex text-black max-w-500  py-15"
                                        style="text-decoration: none;color: black;">
                                        {{activityResponseNoticeBoardItems.ActivityDesc}}
                                    </a> -->

                                        <p class="small text-gray-600 fs-13 activity-timing mt-5px  time-activity">
                                            {{activityResponseNoticeBoardItems.ActivityDateTime | date:"HH:mm a"}}</p>
                                        <a class="a-tag-activity d-flex text-black max-w-500  py-15"
                                            style="text-decoration: none;color: black;margin-bottom: 10px;"
                                            [routerLink]="['/student-notice-board']">
                                            {{activityResponseNoticeBoardItems.ActivityDesc}}<span
                                                style="color:#0d6efd!important;text-decoration: none;margin-left: 5px;">View</span>
                                        </a>

                                    </div>
                                </div>
                            </div>
                            <!-- <div *ngIf="NoDataMessageNoticeBoard==true" style="padding:5px;width: 100%;"
                                >
                                <p style="width:100%;float:left;color:white;" *ngIf="NoDataMessageNoticeBoard==true">
                                    .....</p>
                            </div> -->


                        </div>
                    </div><!--/Notice -->


                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap.lt-md="0px">
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            fxFlex="0 1 calc(49%)" fxFlex.lt-md="0 1 calc(49%)" fxFlex.lt-sm="49%"
                            style="margin-right: 0px;display: unset!important;width: 100%;margin: 0;"
                            class="border-right border-grey-500">

                            <p class="heading-of-activity">Diary Entry</p>
                            <div class="border-top border-grey-500 px-10 py-20" style="width: 100%;margin: 0;">

                                <div *ngFor="let activityResponseDailyDiaryItems of activityResponseItems.activity">
                                    <div *ngIf="activityResponseDailyDiaryItems.ActivityType=='DailyDiary'"
                                        style="padding:5px">
                                        <a class="a-tag-activity d-flex text-black"
                                            style="text-decoration: none;margin-bottom: 10px;"
                                            [routerLink]="['/diary-entry']">

                                            <div style="width:10%;float: left;">
                                                <img [src]="ApiBaseUrlImage+activityResponseDailyDiaryItems.StudentPhoto"
                                                    class="rounded-circle me-3" style="border-radius: 50%;" alt srcset>
                                            </div>
                                            <div style="width:85%;float: left;margin-left:5px">
                                                <div style="width:100%;float: left;">
                                                    <b class="text-black d-block max-w-500"><span
                                                            class="student-name-activicty">{{activityResponseDailyDiaryItems.StudentName}}
                                                            submitted Dairy Entry
                                                            <span
                                                                style="color:#0d6efd!important;text-decoration: none;">View</span></span></b>
                                                </div>
                                                <div style="width:100%;float: left;">
                                                    <span class="fw-400 fs-13 mt-5px d-block decrption"
                                                        style=" text-align: justify;">
                                                        {{activityResponseDailyDiaryItems.ActivityDesc}}</span>
                                                </div>
                                            </div>
                                        </a>
                                        <p class="small text-gray-600 fs-13 activity-timing mt-5px time-activity">
                                            {{activityResponseDailyDiaryItems.ActivityDateTime | date:"HH:mm a"}}</p>
                                    </div>

                                </div>
                            </div>
                            <!-- <div *ngIf="NoDataMessageDailyDiary==true" style="padding:5px;width: 100%;">
                                <p style="width:100%;float:left;color:white;" *ngIf="NoDataMessageDailyDiary==true">
                                    .....</p>
                            </div> -->


                        </div>
                    </div>





                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap.lt-md="0px">
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(49%)" fxFlex.lt-md="0 1 calc(49%)" fxFlex.lt-sm="49%">
                        </div>

                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="right-content"
                            appearance="outline" fxFlex="0 1 calc(49%)" fxFlex.lt-md="0 1 calc(49%)" fxFlex.lt-sm="49%"
                            style="height: auto;" class="border-left border-grey-500 p-5">
                            <p class="heading-of-activity">Change Request</p>
                            <div class="border-top border-grey-500 px-10 py-20" style="width: 100%;margin: 0;">
                                <div *ngFor="let activityResponseChangeRequestItems of activityResponseItems.activity">
                                    <div *ngIf="activityResponseChangeRequestItems.ActivityType=='ChangeRequest'"
                                        style="padding:5px">

                                        <p class="small text-gray-600 fs-13 activity-timing mt-5px  time-activity">
                                            {{activityResponseChangeRequestItems.ActivityDateTime | date:"HH:mm a"}}</p>
                                        <a class="a-tag-activity d-flex text-black"
                                            style="text-decoration: none;margin-bottom: 10px;"
                                            [routerLink]="['/profile-change-request']">

                                            <div style="width:10%;float: left;">
                                                <img [src]="ApiBaseUrlImage+activityResponseChangeRequestItems.StudentPhoto"
                                                    class="rounded-circle me-3" style="border-radius: 50%;" alt srcset>
                                            </div>
                                            <div style="width:85%;float: left;margin-left:5px">
                                                <div style="width:100%;float: left;">
                                                    <b class="text-black d-block max-w-500"><span
                                                            class="student-name-activicty">{{activityResponseChangeRequestItems.StudentName}}
                                                            <span
                                                                style="color:#0d6efd!important;text-decoration: none;">View</span></span></b>
                                                </div>
                                                <div style="width:100%;float: left;">
                                                    <span class="fw-400 fs-13 mt-5px d-block decrption"
                                                        style=" text-align: justify;">
                                                        {{activityResponseChangeRequestItems.ActivityDesc}}</span>
                                                </div>
                                            </div>
                                        </a>

                                    </div>
                                </div>
                            </div>
                            <!-- <div *ngIf="NoDataMessageChangeRequest==true" style="padding:5px;width: 100%;">
                                <p style="width:100%;float:left;color:white;" *ngIf="NoDataMessageChangeRequest==true">
                                    .....</p>
                            </div> -->


                        </div>
                    </div><!--/Notice -->

                    <!-- <div *ngFor="let groupByItem of groupBy"> -->
                    <p class="text-center date">
                        {{activityResponseItems.date | date:"mediumDate"}}
                    </p> <!--</div>-->
                </div>
            </ng-container><!--/All Activity-->

            <ng-container matColumnDef="Status" *ngIf="tabIndex===1">
                <p class="fs-25 text-black font-weight-bold mt-lg-3 mt-10 text-center mb-25" style="font-size: 25px;">
                    <b>Notice</b>
                </p>
                <div *ngFor="let activityResponseItems of activityResponse">
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap.lt-md="0px">
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(49%)" fxFlex.lt-md="0 1 calc(49%)" fxFlex.lt-sm="49%"
                            style="margin-right: 0px;" class="border-right border-grey-500 p-5">
                            <p class="heading-of-activity">Diary Entry</p>
                            <div class="border-top border-grey-500 px-10 py-20" style="width: 100%;margin: 0;">
                                <div *ngFor="let activityResponseDailyDiaryItems of activityResponseItems.activity">
                                    <div *ngIf="activityResponseDailyDiaryItems.ActivityType=='DailyDiary'"
                                        style="padding:5px">
                                        <a class="a-tag-activity d-flex text-black"
                                            style="text-decoration: none;margin-bottom: 10px;"
                                            [routerLink]="['/diary-entry']">
                                            <div style="width:10%;float: left;">
                                                <img [src]="ApiBaseUrlImage+activityResponseDailyDiaryItems.StudentPhoto"
                                                    class="rounded-circle me-3" style="border-radius: 50%;" alt srcset>
                                            </div>
                                            <div style="width:85%;float: left;margin-left:5px">
                                                <div style="width:100%;float: left;">
                                                    <b class="text-black d-block max-w-500"><span
                                                            class="student-name-activicty">{{activityResponseDailyDiaryItems.StudentName}}
                                                            submitted Dairy Entry
                                                            <span
                                                                style="color:#0d6efd!important;text-decoration: none;">View</span></span></b>
                                                </div>
                                                <div style="width:100%;float: left;">
                                                    <span class="fw-400 fs-13 mt-5px d-block decrption"
                                                        style=" text-align: justify;">
                                                        {{activityResponseDailyDiaryItems.ActivityDesc}}</span>
                                                </div>
                                            </div>
                                        </a>
                                        <p class="small text-gray-600 fs-13 activity-timing mt-5px time-activity">
                                            {{activityResponseDailyDiaryItems.ActivityDateTime | date:"HH:mm a"}}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!--/Diary Entry end-->
                    <p class="text-center date">{{activityResponseItems.date | date:"mediumDate"}}</p>

                </div>
            </ng-container>

            <ng-container matColumnDef="Status" *ngIf="tabIndex===2">

                <p class="fs-25 text-black font-weight-bold mt-lg-3 mt-10 text-center mb-25" style="font-size: 25px;">
                    <b>Notice</b>
                </p>
                <div *ngFor="let activityResponseItems of activityResponse">
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap.lt-md="0px"
                        style="margin:10px">
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(49%)" fxFlex.lt-md="0 1 calc(49%)" fxFlex.lt-sm="49%"
                            style="margin-right: 0px;display: none;" class="border-right border-grey-500 p-5">
                            <p class="heading-of-activity">Change Request</p>
                            <div class="border-top border-grey-500 px-10 py-20">
                                <div *ngFor="let activityResponseChangeRequestItems of activityResponseItems.activity">
                                    <div *ngIf="activityResponseChangeRequestItems.ActivityType=='ChangeRequest'"
                                        style="padding:5px">
                                        <a class="a-tag-activity d-flex text-black"
                                            style="text-decoration: none;margin-bottom: 10px;"
                                            [routerLink]="['/profile-change-request']">
                                            <div style="width:10%;float: left;">
                                                <img [src]="ApiBaseUrlImage+activityResponseChangeRequestItems.StudentPhoto"
                                                    class="rounded-circle me-3" style="border-radius: 50%;" alt srcset>
                                            </div>
                                            <div style="width:85%;float: left;margin-left:5px">
                                                <div style="width:100%;float: left;">
                                                    <b class="text-black d-block max-w-500"><span
                                                            class="student-name-activicty">{{activityResponseChangeRequestItems.StudentName}}
                                                            <span
                                                                style="color:#0d6efd!important;text-decoration: none;">View</span></span></b>
                                                </div>
                                                <div style="width:100%;float: left;">
                                                    <span class="fw-400 fs-13 mt-5px d-block decrption"
                                                        style=" text-align: justify;">
                                                        {{activityResponseChangeRequestItems.ActivityDesc}}</span>
                                                </div>
                                            </div>

                                        </a>
                                        <p class="small text-gray-600 fs-13 activity-timing mt-5px time-activity">
                                            {{activityResponseChangeRequestItems.ActivityDateTime | date:"HH:mm a"}}</p>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div><!--/Approvals end-->


                    <p class="text-center date">{{activityResponseItems.date | date:"mediumDate"}}</p>
                </div>

            </ng-container>

            <ng-container matColumnDef="Status" *ngIf="tabIndex===3">

                <p class="fs-25 text-black font-weight-bold mt-lg-3 mt-10 text-center mb-25" style="font-size: 25px;">
                    <b>Notice</b>
                </p>
                <div *ngFor="let activityResponseItems of activityResponse">
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap.lt-md="0px"
                        style="margin:10px">
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(49%)" fxFlex.lt-md="0 1 calc(49%)" fxFlex.lt-sm="49%"
                            style="margin-right: 0px;" class="border-right border-grey-500 p-5">
                            <p class="heading-of-activity">Approvals</p>
                            <div class="border-top border-grey-500 px-10 py-20">
                                <div *ngFor="let activityResponseApprovalItems of activityResponseItems.activity">
                                    <div *ngIf="activityResponseApprovalItems.ActivityType=='Approval'"
                                        style="padding:5px">
                                        <a *ngIf="activityResponseApprovalItems.ActivitySubType=='InternshipApproval'" class="a-tag-activity d-flex text-black"
                                        style="text-decoration: none;margin-bottom: 10px;"
                                        [routerLink]="['/internship-approval']">
                                        <div style="width:10%;float: left;">
                                            <img [src]="ApiBaseUrlImage+activityResponseApprovalItems.StudentPhoto"
                                                class="rounded-circle me-3" style="border-radius: 50%;" alt srcset>
                                        </div>
                                        <div style="width:85%;float: left;margin-left:5px">
                                            <div style="width:100%;float: left;">
                                                <b class="text-black d-block max-w-500"><span
                                                        class="student-name-activicty">{{activityResponseApprovalItems.StudentName}}
                                                        Applied for
                                                        Internship -
                                                        <span
                                                            style="color:#0d6efd!important;text-decoration: none;">View</span></span></b>
                                            </div>
                                            <div style="width:100%;float: left;">
                                                <span class="fw-400 fs-13 mt-5px d-block decrption"
                                                    style=" text-align: justify;">
                                                    {{activityResponseApprovalItems.ActivityDesc}}</span>
                                            </div>
                                        </div>
                                    </a>

                                    <a *ngIf="activityResponseApprovalItems.ActivitySubType=='Gratitude'" class="a-tag-activity d-flex text-black"
                                    style="text-decoration: none;margin-bottom: 10px;"
                                    [routerLink]="['/gratitude-approved-vt']">
                                    <div style="width:10%;float: left;">
                                        <img [src]="ApiBaseUrlImage+activityResponseApprovalItems.StudentPhoto"
                                            class="rounded-circle me-3" style="border-radius: 50%;" alt srcset>
                                    </div>
                                    <div style="width:85%;float: left;margin-left:5px">
                                        <div style="width:100%;float: left;">
                                            <b class="text-black d-block max-w-500"><span
                                                    class="student-name-activicty">{{activityResponseApprovalItems.StudentName}}
                                                    has Applied for Gratitude approval -
                                                    <span
                                                        style="color:#0d6efd!important;text-decoration: none;">View</span></span></b>
                                        </div>
                                        <div style="width:100%;float: left;">
                                            <span class="fw-400 fs-13 mt-5px d-block decrption"
                                                style=" text-align: justify;">
                                                {{activityResponseApprovalItems.ActivityDesc}}</span>
                                        </div>
                                    </div>
                                    </a>
                                        <!-- <a class="a-tag-activity d-flex text-black"
                                            style="text-decoration: none;margin-bottom: 10px;"
                                            [routerLink]="['/internship-approval']">
                                            <div style="width:10%;float: left;">
                                                <img [src]="ApiBaseUrlImage+activityResponseApprovalItems.StudentPhoto"
                                                    class="rounded-circle me-3" style="border-radius: 50%;" alt srcset>
                                            </div>
                                            <div style="width:85%;float: left;margin-left:5px">
                                                <div style="width:100%;float: left;">
                                                    <b class="text-black d-block max-w-500"><span
                                                            class="student-name-activicty">{{activityResponseApprovalItems.StudentName}}
                                                            Applied for Internship -
                                                            <span
                                                                style="color:#0d6efd!important;text-decoration: none;">View</span></span></b>
                                                </div>
                                                <div style="width:100%;float: left;">
                                                    <span class="fw-400 fs-13 mt-5px d-block decrption"
                                                        style=" text-align: justify;">
                                                        {{activityResponseApprovalItems.ActivityDesc}}</span>
                                                </div>
                                            </div>
                                        </a> -->
                                        <p class="small text-gray-600 fs-13 activity-timing mt-5px time-activity">
                                            {{activityResponseApprovalItems.ActivityDateTime | date:"HH:mm a"}}</p>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div><!--/Approvals end-->

                    <p class="text-center date">{{activityResponseItems.date | date:"mediumDate"}}</p>
                </div>
            </ng-container>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>