import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { RouteConstants } from 'app/constants/route.constant'
import { SchoolSectorJobRoleService } from '../school-sector-job-role.service';
import { SchoolSectorJobRoleModel } from '../school-sector-job-role.model';
import { DropdownModel } from 'app/models/dropdown.model';

@Component({
  selector: 'school-sector-job-role',
  templateUrl: './create-school-sector-job-role.component.html',
  styleUrls: ['./create-school-sector-job-role.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CreateSchoolSectorJobRoleComponent extends BaseComponent<SchoolSectorJobRoleModel> implements OnInit {
  schoolSectorJobRoleForm: FormGroup;
  schoolSectorJobRoleModel: SchoolSectorJobRoleModel;
  academicyearList: [DropdownModel];
  phaseList: [DropdownModel];
  sectorList: [DropdownModel];
  jobRoleList: [DropdownModel];
  schoolsList: [DropdownModel];
  roleList: [DropdownModel];
  statecodeList: [DropdownModel];
  remarkText: string;
  filteredSchoolItems: any;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private route: ActivatedRoute,
    private schoolSectorJobRoleService: SchoolSectorJobRoleService,
    private dialogService: DialogService,
    private formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Set the default sectorJobRole Model
    this.schoolSectorJobRoleModel = new SchoolSectorJobRoleModel();
  }

  ngOnInit(): void {

    this.schoolSectorJobRoleService.getDropdownforSectorJobRole(this.UserModel).subscribe((response: any) => {

      if (response[0].Success) {
        this.sectorList = response[0].Results;
      }
      if (response[1].Success) {
        this.schoolsList = response[1].Results;
        this.filteredSchoolItems = this.schoolsList.slice();
      }
      if (response[2].Success) {
        this.statecodeList = response[2].Results;
        for (const i of response[2].Results) {
          this.schoolSectorJobRoleForm.get('StateCode').setValue(i.Id);
        }
      }
      if (response[3].Success) {
        this.academicyearList = response[3].Results;
      }

      this.route.paramMap.subscribe(params => {
        if (params.keys.length > 0) {
          this.PageRights.ActionType = params.get('actionType');

          if (this.PageRights.ActionType == this.Constants.Actions.New) {
            this.schoolSectorJobRoleModel = new SchoolSectorJobRoleModel();

          } else {
            var schoolSectorJobRolesId: string = params.get('SchoolSectorJobRolesId')

            this.schoolSectorJobRoleService.getSchoolSectorJobRoleById(schoolSectorJobRolesId)
              .subscribe((response: any) => {
                this.schoolSectorJobRoleModel = response.Result;

                if (this.PageRights.ActionType == this.Constants.Actions.Edit)
                  this.schoolSectorJobRoleModel.RequestType = this.Constants.PageType.Edit;
                else if (this.PageRights.ActionType == this.Constants.Actions.View) {
                  this.schoolSectorJobRoleModel.RequestType = this.Constants.PageType.View;
                  this.PageRights.IsReadOnly = true;
                }
                this.onChangeSector(this.schoolSectorJobRoleModel.SectorId)
                this.schoolSectorJobRoleForm = this.createSchoolSectorJobRoleForm();
            
              });
          }
        }
      });
    });
    this.schoolSectorJobRoleForm = this.createSchoolSectorJobRoleForm();
  }

  saveOrUpdateSchoolSectorJobRoleDetails() {
    console.log(this.schoolSectorJobRoleForm.value)
    if (!this.schoolSectorJobRoleForm.valid) {
      this.validateAllFormFields(this.schoolSectorJobRoleForm);
      return;
    }

    this.setValueFromFormGroup(this.schoolSectorJobRoleForm, this.schoolSectorJobRoleModel);
    this.schoolSectorJobRoleModel.Remarks = this.remarkText;

    this.schoolSectorJobRoleService.createOrUpdateSchoolSectorJobRole(this.schoolSectorJobRoleModel)
      .subscribe((schoolSectorJobRoleResp: any) => {

        if (schoolSectorJobRoleResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );

            this.router.navigate([RouteConstants.SchoolSectorJobRole.List]);
          });
        }
        else {
          var errorMessages = this.getHtmlMessage(schoolSectorJobRoleResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('SchoolSectorJobRole deletion errors =>', error);
      });
  }

  onChangeSector(evt) {

    this.commonService
      .GetMasterDataByType({ DataType: 'JobRoles', ParentId: evt, SelectTitle: 'Job Role' })
      .subscribe((response: any) => {

        if (response.Success) {
          this.jobRoleList = response.Results;
          var sectorItem = this.sectorList.find(x => x.Id == evt);
          var jobRoleItem = this.jobRoleList.find(x => x.Id == this.schoolSectorJobRoleForm.get('JobRoleId').value);
          this.remarkText = sectorItem.Name + (jobRoleItem == null ? '' : '-' + jobRoleItem.Name);
          this.schoolSectorJobRoleForm.get('Remarks').setValue(this.remarkText);
        }
      });
  }

  onChangeJobRole(evt) {
    var sectorItem = this.sectorList.find(x => x.Id == this.schoolSectorJobRoleForm.get('SectorId').value);
    var jobRoleItem = this.jobRoleList.find(x => x.Id == evt);
    this.remarkText = (sectorItem == null ? '' : sectorItem.Name + '-') + jobRoleItem.Name;
    this.schoolSectorJobRoleForm.get('Remarks').setValue(this.remarkText);
  }
  status(){
    this.schoolSectorJobRoleForm.patchValue( {'JobRoleId':null} );
  }

  //Create sectorJobRole form and returns {FormGroup}
  createSchoolSectorJobRoleForm(): FormGroup {
    return this.formBuilder.group({
      SchoolSectorJobRolesId: new FormControl(this.schoolSectorJobRoleModel.SchoolSectorJobRolesId),
      SectorId: new FormControl({ value: this.schoolSectorJobRoleModel.SectorId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      JobRoleId: new FormControl({ value: this.schoolSectorJobRoleModel.JobRoleId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      SchoolId: new FormControl({ value: this.schoolSectorJobRoleModel.SchoolId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      Remarks: new FormControl({ value: this.schoolSectorJobRoleModel.Remarks, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(350)),
      IsActive: new FormControl({ value: this.schoolSectorJobRoleModel.IsActive, disabled: this.PageRights.IsReadOnly }),
      StateCode: new FormControl({ value: this.schoolSectorJobRoleModel.StateCode, disabled: true }, Validators.required),
      AcademicYearId: new FormControl({ value: this.schoolSectorJobRoleModel.AcademicYearId, disabled: this.PageRights.IsReadOnly }, Validators.required),

    });
  }
}
