<!-- HEADER -->
<div class="header1" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="space-between center" style="background: #3f51b5;height: 64px; height: 61px;
  padding: 0px 24px;
">
    <!-- APP TITLE -->
    <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center"
        [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
        <div class="h2 mt-8 mb-12  heading-text1" style="color:#fff;">
            Mark As Drop Out
        </div>
    </div>
    <!-- / APP TITLE -->
    <!-- ADD BUTTON -->

    <!-- <button mat-flat-button [color]="'warn'" (click)="onClose()" class="mat-elevation-z1" style="background-color: #f44336 !important;
  color: #fff !important;">
        <mat-icon>clear</mat-icon>
    </button> -->
    <!-- / ADD BUTTON -->
</div>
<!-- / HEADER -->
<div id="staff" class="page-layout carded fullwidth inner-scroll">
    <!-- TOP BACKGROUND -->
    <!-- <div class="top-bg accent"></div> -->
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">
        <!-- CONTENT CARD -->
        <div class="content-card mat-elevation-z2 p-5 mb-12">
            <div class="content">

                <form name="dropOutForm" [formGroup]="dropOutForm" class="internship w-100-p p-24 mr-24"
                    fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex fusePerfectScrollbar>

                    <div class="required-mark">
                        <span><span class="note">Note: </span>All fields marked with an asterisk (<span
                                class="asterisk-color">*</span>) are required.</span>
                    </div>

                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start"
                        style="height: auto !important;" id="displayContent">


                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Dropout Date</mat-label>
                            <input matInput [matDatepicker]="drop" name="dropOutDate" formControlName="DropOutDate"
                                [max]="CurrentDate" [disabled]="PageRights.IsReadOnly" required>
                            <mat-datepicker-toggle matSuffix [for]="drop"></mat-datepicker-toggle>
                            <mat-datepicker #drop></mat-datepicker>
                            <mat-error *ngIf="dropOutForm.controls.DropOutDate.invalid">
                                {{getErrorMessage(dropOutForm, 'DropOutDate')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                            <mat-label>Reason</mat-label>
                            <input matInput name="remarks" formControlName="Reason" placeholder="Reason for drop out"
                                matTooltip="Reason" matTooltipPosition="above">
                            <mat-error *ngIf="dropOutForm.controls.Reason.invalid">
                                {{getErrorMessage(dropOutForm, 'Reason ')}}
                            </mat-error>
                        </mat-form-field>


                        <!-- <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                            <mat-label>Remarks</mat-label>
                            <input matInput name="remarks" formControlName="Remarks" placeholder="Remarks"
                                matTooltip="Remarks" matTooltipPosition="above">
                            <mat-error *ngIf="dropOutForm.controls.Remarks.invalid">
                                {{getErrorMessage(dropOutForm, 'Remarks')}}
                            </mat-error>
                        </mat-form-field> -->

                    </div>
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start">
                        <button mat-raised-button class="add-internship-button btn-warning mls"
                            (click)="onSubmit(dropOutForm)" fxFlex="0 1 calc(47%)" fxFlex.lt-md="0 1 calc(47%)"
                            fxFlex.lt-sm="50%">
                            <span>Save </span>
                        </button>

                        <button mat-raised-button class="add-internship-button btn-cancel" (click)="onClose()"
                            fxFlex="0 1 calc(47%)" fxFlex.lt-md="0 1 calc(47%)" fxFlex.lt-sm="50%" sty>
                            <span>Cancel </span>
                        </button>
                    </div>
                </form>
            </div>
            <!-- / CONTENT CARD -->
        </div>
        <!-- / CENTER -->
    </div>