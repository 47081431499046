<div id="vt-assessment" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    VC Assessment/Performance Report
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter (keyup)="applySearchFilter($event.target.value)" placeholder="Search for  VC Report">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">
                <a *ngIf="PageRights.IsExport" class="export-excel"
                    (click)="exporter.exportTable('xlsx', {fileName:'vcReport', sheet: 'vcReport', Props: {Author: 'Pathways'}})">
                    <mat-icon> cloud_download</mat-icon>
                </a>
            </div>
            <!-- / ADD BUTTON -->
        </div>
        <!-- / HEADER -->

        <!-- <div fxLayout="row" fxLayoutAlign="start center">
            <button mat-raised-button class="fuse-white filter-button" style="margin-bottom: 10px;"
                (click)="isShowFilterContainer=!isShowFilterContainer"><span>FILTERS</span></button>
        </div> -->

        <!-- Filter Section -->
        <div class="filter-content-card">
            <div class="filter-content">
                <form name="vcReportForm" [formGroup]="vcReportForm" class="vt-school-sector" fxLayout="column"
                    fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label> Academic Year</mat-label>

                            <mat-select formControlName="AcademicYearId" name="academicYearId" appearance="fill"
                                (selectionChange)="onChangeAct($event.value)">
                                <mat-option [value]="academicYearItem.Id"
                                    (onSelectionChange)="onSelectEvent($event, academicYearItem.Name)"
                                    *ngFor="let academicYearItem of academicyearList">
                                    {{ academicYearItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100" *ngIf="isShowFilter">

                            <mat-label>From</mat-label>
                            <input [min]="minDate" matInput formControlName="StartDateTime" [matDatepicker]="picker1"
                                name="StartDateTime" [max]="maxDate">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100" *ngIf="isShowFilter">
                            <mat-label>To</mat-label>
                            <input matInput [min]="minDate" formControlName="EndDateTime" [matDatepicker]="picker2"
                                name="EndDateTime" [max]="maxDate">
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100" *ngIf="isShowFilter">
                            <mat-label>Vocational Training Provider</mat-label>

                            <mat-select formControlName="VTPGuid" name="vTPGuid" appearance="fill"
                                (selectionChange)="onChangeVTP($event.value)">
                                <mat-option [value]="vtpItem.Id" *ngFor="let vtpItem of vtpList">
                                    {{ vtpItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>


                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="filter-disabled"
                            *ngIf="isShowFilter">
                            <mat-label>Division</mat-label>

                            <mat-select formControlName="DivisionCode" name="divisionCode" appearance="fill"
                                (selectionChange)="onChangeDivision($event.value)">
                                <mat-option [value]="Item.Id" *ngFor="let Item of divisionList">
                                    {{ Item.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="filter-disabled"
                            *ngIf="isShowFilter">
                            <mat-label>DistrictCode</mat-label>

                            <mat-select formControlName="DistrictCode" name="districtCode" appearance="fill"
                                (selectionChange)="onChangeDistrict($event.value)">
                                <mat-option [value]="districtItem.Id" *ngFor="let districtItem of districtList">
                                    {{ districtItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="filter-disabled"
                            *ngIf="isShowFilter">
                            <mat-label>BlockId</mat-label>

                            <mat-select formControlName="BlockId" name="blockId" appearance="fill">
                                <mat-option [value]="blockItem.Id" *ngFor="let blockItem of blockList">
                                    {{ blockItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>




                        <!-- <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Vocational Coordinator</mat-label>

                            <mat-select formControlName="VCGuid" name="vCGuid" appearance="fill">
                                <mat-option [value]="vtItem.Id" *ngFor="let vtItem of vocationalCoordinatorList">
                                    {{ vtItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field> -->





                        <div class="clear-filter pr-1" fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="GetVCAssessmentReports()"><span>SEARCH</span></button>
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="resetReportFilters()"><span>CLEAR</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- /Filter Section -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table class="vt-school-sector-report-table responsive-table" #table [dataSource]="tableDataSource"
                matTableExporter #exporter="matTableExporter" [hiddenColumns]="[0]" matSort fusePerfectScrollbar
                [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- SrNo Column -->
                <ng-container matColumnDef="SrNo">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Sr No</mat-header-cell>
                    <mat-cell *matCellDef="let vcReport; let RowIndex = index;">
                        <p class="text-truncate">{{RowIndex+1}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VCFullName Column -->
                <ng-container matColumnDef="VCFullName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VC Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vcReport">
                        <p class="text-truncate">{{vcReport.VCFullName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VCMobile Column -->
                <ng-container matColumnDef="VCMobile">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VC Contact 1
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vcReport">
                        <p class="text-truncate">{{vcReport.VCMobile}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VCMobile1 Column -->
                <ng-container matColumnDef="VCMobile1">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VC Contact 2
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vcReport">
                        <p class="text-truncate">{{vcReport.VCMobile1}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VCEmailId Column -->
                <ng-container matColumnDef="VCEmailId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VC EmailId
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vcReport">
                        <p class="text-truncate">{{vcReport.VCEmailId}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VTPName Column -->
                <ng-container matColumnDef="VTPName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VTP Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vcReport">
                        <p class="text-truncate">{{vcReport.VTPName}}</p>
                    </mat-cell>
                </ng-container>
                <!-- VCTrade Column -->
                <ng-container matColumnDef="VCTrade">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Trade / Vocational
                        subject
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vcReport">
                        <p class="text-truncate">{{vcReport.VCTrade}}</p>
                    </mat-cell>
                </ng-container>
                <!-- TotalVTs Column -->
                <ng-container matColumnDef="TotalVTs">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Total VTs Reporting
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vcReport">
                        <p class="text-truncate">{{vcReport.TotalVTs}}</p>
                    </mat-cell>
                </ng-container>



                <!-- TotalSchools Column -->
                <ng-container matColumnDef="TotalSchools">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Total Schools
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vcReport">
                        <p class="text-truncate">{{vcReport.TotalSchools}}</p>
                    </mat-cell>
                </ng-container>
                <!-- TotalStudents Column -->
                <ng-container matColumnDef="TotalStudents">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Total No. of students /
                        Enrollment
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vcReport">
                        <p class="text-truncate">{{vcReport.TotalStudents}}</p>
                    </mat-cell>
                </ng-container>


                <!-- CountOfBoys Column -->
                <ng-container matColumnDef="CountOfBoys">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Total No. of students /
                        Enrollment Boys
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vcReport">
                        <p class="text-truncate">{{vcReport.CountOfBoys}}</p>
                    </mat-cell>
                </ng-container>

                <!-- CountOfGirls Column -->
                <ng-container matColumnDef="CountOfGirls">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Total No. of students /
                        Enrollment Girls
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vcReport">
                        <p class="text-truncate">{{vcReport.CountOfGirls}}</p>
                    </mat-cell>
                </ng-container>

                <!-- BoysWithInternship Column -->
                <ng-container matColumnDef="BoysWithInternship">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md> No. of students doing
                        Internship Boys
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vcReport">
                        <p class="text-truncate">{{vcReport.BoysWithInternship}}</p>
                    </mat-cell>
                </ng-container>
                <!-- GirlsWithInternship Column -->
                <ng-container matColumnDef="GirlsWithInternship">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md> No. of students doing
                        Internship Girls
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vcReport">
                        <p class="text-truncate">{{vcReport.GirlsWithInternship}}</p>
                    </mat-cell>
                </ng-container>

                <!-- BoysWithInternshipCompleted Column -->
                <ng-container matColumnDef="BoysWithInternshipCompleted">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md> Internships completed
                        till now Boys
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vcReport">
                        <p class="text-truncate">{{vcReport.BoysWithInternshipCompleted}}</p>
                    </mat-cell>
                </ng-container>



                <!-- GirlsWithInternshipCompleted Column -->
                <ng-container matColumnDef="GirlsWithInternshipCompleted">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Internships completed
                        till now Girls
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vcReport">
                        <p class="text-truncate">{{vcReport.GirlsWithInternshipCompleted}}</p>
                    </mat-cell>
                </ng-container>





                <!-- ActiveEmployerWithInternship Column -->
                <ng-container matColumnDef="ActiveEmployerWithInternship">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Active Employer mapped
                        for Internship
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vcReport">
                        <p class="text-truncate">{{vcReport.ActiveEmployerWithInternship}}</p>
                    </mat-cell>
                </ng-container>



                <!-- ActiveEmployerWithCompletedInternship Column -->
                <ng-container matColumnDef="ActiveEmployerWithCompletedInternship">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>No. of employers
                        engaged in Completed Internships
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vcReport">
                        <p class="text-truncate">{{vcReport.ActiveEmployerWithCompletedInternship }}</p>
                    </mat-cell>
                </ng-container>

                <!-- SafetyConcernsRaised Column -->
                <ng-container matColumnDef="SafetyConcernsRaised">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Total Safety Concerns
                        raised
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vcReport">
                        <p class="text-truncate">{{vcReport.SafetyConcernsRaised }}</p>
                    </mat-cell>
                </ng-container>

                <!-- SafetyConcernsResolved Column -->
                <ng-container matColumnDef="SafetyConcernsResolved">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Total Safety Concerns
                        resolved
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vcReport">
                        <p class="text-truncate">{{vcReport.SafetyConcernsResolved }}</p>
                    </mat-cell>
                </ng-container>


                <!-- InternshipApprovalRequest Column -->
                <ng-container matColumnDef="InternshipApprovalRequest">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Pending Internship
                        Approvals
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vcReport">
                        <p class="text-truncate">{{vcReport.InternshipApprovalRequest}}</p>
                    </mat-cell>
                </ng-container>

                <!-- PendingProfileEditRequest Column -->
                <ng-container matColumnDef="PendingProfileEditRequest">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Pending Profile edit
                        requests
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vcReport">
                        <p class="text-truncate">{{vcReport.PendingProfileEditRequest}}</p>
                    </mat-cell>
                </ng-container>

                <!-- FinalReportPendingAck Column -->
                <ng-container matColumnDef="FinalReportPendingAck">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Pending Ackn. on
                        Internship Report

                    </mat-header-cell>
                    <mat-cell *matCellDef="let vcReport">
                        <p class="text-truncate">{{vcReport.FinalReportPendingAck}}</p>
                    </mat-cell>
                </ng-container>


                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let vcReport; columns: displayedColumns;" class="vt-assessment" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

            <!-- <mat-paginator #ListPaginator [length]="SearchBy.TotalResults" [pageIndex]="SearchBy.PageIndex"
                [pageSize]="SearchBy.PageSize" [pageSizeOptions]="SearchBy.PageSizeOptions"
                [showFirstLastButtons]="SearchBy.ShowFirstLastButtons" (page)="onPageIndexChanged($event)">
            </mat-paginator> -->

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>