<div id="employer-listing" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row wrap" fxLayoutAlign="space-between center">


            <!-- APP TITLE -->
            <div fxLayout="row" class="screen-heading" fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)"
                fxFlex.lt-sm="100%">
                <div fxLayout="row" fxLayoutAlign="start center">

                    <!-- <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/vt-student'">
                        <mat-icon>arrow_back</mat-icon>
                    </button> -->

                    <div fxLayout="column" fxLayoutAlign="start start"
                        [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                        <div class="h1" *ngIf="PageRights.PageType ==='Edit'">
                            <!-- {{ schoolModel.SchoolName }} -->
                            <h1> Edit Employer </h1>
                        </div>
                        <div class="h1" *ngIf="PageRights.PageType ==='New'">
                            <h1>Add New Employer </h1>
                        </div>
                        <div class="subtitle secondary-text" *ngIf="PageRights.PageType ==='New'">
                            <h1><span>Add New Employer </span></h1>
                        </div>
                    </div>
                </div>
            </div>

            <!-- / APP TITLE -->

            <div fxLayout="row" class="add-edit-button" fxLayoutAlign="flex-start" fxFlex="0 1 calc(50%)"
                fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="100%">

                <button mat-raised-button class="add-school-button btn-outline-dark" [routerLink]="'/employer-listing'"
                    fxFlex="0 1 calc(33%)" fxFlex.lt-md="0 1 calc(33%)" fxFlex.lt-sm="50%">
                    <span>Manage Employer </span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="ms-2" width="20" height="20" viewBox="0 0 24 24"
                        stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"></path>
                        <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3"></path>
                        <line x1="16" y1="5" x2="19" y2="8"></line>
                    </svg>
                </button>

                <button mat-raised-button class="add-school-button btn-warning mls"
                    *ngIf="PageRights.ActionType ==='new'" (click)="saveOrCreateOrUpdateEmployersDetails()"
                    fxFlex="0 1 calc(33%)" fxFlex.lt-md="0 1 calc(33%)" fxFlex.lt-sm="50%">
                    <span>Add Employer </span>
                </button>

                <button mat-raised-button class="save-school-button" *ngIf="PageRights.ActionType ==='edit'"
                    (click)="saveOrCreateOrUpdateEmployersDetails()">
                    <span>SAVE</span>
                </button>

            </div>

        </div>
        <!-- / HEADER -->
        <div class="padding-bottom">
            <mat-tab-group selectedIndex="0" (selectedTabChange)="changeTab($event)">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Add An Employer</span>
                    </ng-template>

                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Add Multiple Employer</span>
                    </ng-template>

                </mat-tab>
            </mat-tab-group>
        </div>


        <!-- CONTENT CARD -->
        <div class="content-card" *ngIf="tabIndex === 0">


            <!-- CONTENT -->
            <div class="content">


                <form name="employerForm" [formGroup]="employerForm" class="account w-100-p" fxLayout="column" fxFlex>
                    <div class="tab-content p-24" fusePerfectScrollbar>
                        <div class="required-mark">
                            <span><span class="note">Note: </span>All fields marked with an asterisk (<span
                                    class="asterisk-color">*</span>) are required.</span>
                        </div>

                        <fieldset>
                            <legend>Personal Details</legend>
                            <!-- <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Employer Name</mat-label>
                                    <input matInput required formControlName="EmployerName" placeholder="Ex. Rakesh"
                                        matTooltip="First Name should be typed in Full, first letter of each word in 
                                Capital followed by small letters. Please avoid commas and dots in name."
                                        matTooltipPosition="above">
                                    <mat-error *ngIf="employerForm.controls.EmployerName.invalid">
                                        {{getErrorMessage(employerForm, 'EmployerName')}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Gender</mat-label>
                                    <mat-select name="gender" formControlName="EmployerGender" required
                                        appearance="outline" [disabled]="PageRights.IsReadOnly">
                                        <mat-option [value]="genderItem.Id" *ngFor="let genderItem of genderList">
                                            {{ genderItem.Name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="employerForm.controls.EmployerGender.invalid">
                                        {{getErrorMessage(employerForm, 'EmployerGender')}}
                                    </mat-error>
                                </mat-form-field>
                            </div> -->
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label> First Name</mat-label>
                                    <input matInput required formControlName="EmployerFirstName"
                                        placeholder="Ex. Rakesh" matTooltip="First Name should be typed in Full, first letter of each word in 
                                Capital followed by small letters. Please avoid commas and dots in name."
                                        matTooltipPosition="above">
                                    <mat-error *ngIf="employerForm.controls.EmployerFirstName.invalid">
                                        {{getErrorMessage(employerForm, 'EmployerFirstName')}}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label> Last Name</mat-label>
                                    <input matInput name="EmployerLastName" required formControlName="EmployerLastName"
                                        placeholder="Ex. Patel" matTooltip="Last Name should be typed in Full, first letter of each word in 
                                Capital followed by small letters. Please avoid commas and dots in name."
                                        matTooltipPosition="above">
                                    <mat-error *ngIf="employerForm.controls.EmployerLastName.invalid">
                                        {{getErrorMessage(employerForm, 'EmployerLastName')}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Gender</mat-label>
                                    <mat-select name="gender" formControlName="EmployerGender" required
                                        appearance="outline" [disabled]="PageRights.IsReadOnly">
                                        <mat-option [value]="genderItem.Id" *ngFor="let genderItem of genderList">
                                            {{ genderItem.Name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="employerForm.controls.EmployerGender.invalid">
                                        {{getErrorMessage(employerForm, 'EmployerGender')}}
                                    </mat-error>
                                </mat-form-field>

                            </div>

                        </fieldset>

                        <fieldset>
                            <legend>Business Details</legend>
                            <!-- <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline"  fxFlex="100" fxFlex.lt-md="50"
                                fxFlex.lt-sm="100">
                                <mat-label>Employer Photo</mat-label>
                                <ngx-mat-file-input name="uploadFile" formControlName="EmpPhotoUrl" required
                                    [accept]=""  (change)="changePhoto($event)"
                                    placeholder="Select excel template file" matTooltip="EmpPhotoUrl"
                                    matTooltipPosition="above"></ngx-mat-file-input>
    
                                <mat-error *ngIf="employerForm.controls.EmpPhotoUrl.invalid">
                                    {{getErrorMessage(employerForm, 'EmpPhotoUrl')}}
                                </mat-error>
                                <img  [src]="img" class="preview">

                            </mat-form-field>

                            </div> -->
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">

                                <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Shop/Organization's Name </mat-label>
                                    <input matInput required formControlName="OrganizationName"
                                        placeholder="Ex. Shop/Organization's Name">
                                    <mat-error *ngIf="employerForm.controls.OrganizationName.invalid">
                                        {{getErrorMessage(employerForm, 'OrganizationName')}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label> Business Description</mat-label>
                                    <input matInput formControlName="BusinessDesc"
                                        placeholder="Ex. Business Description">
                                    <mat-error *ngIf="employerForm.controls.BusinessDesc.invalid">
                                        {{getErrorMessage(employerForm, 'BusinessDesc')}}
                                    </mat-error>
                                </mat-form-field>
                                <!-- <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Number of Employees</mat-label>
                                    <input matInput required digitOnly formControlName="NoOfEmployees"
                                        placeholder="Ex. 123" matTooltipPosition="above">
                                    <mat-error *ngIf="employerForm.controls.NoOfEmployees.invalid">
                                        {{getErrorMessage(employerForm, 'NoOfEmployees')}}
                                    </mat-error>
                                </mat-form-field> -->

                                <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Number of Employees</mat-label>
                                    <mat-select name="internshipType" formControlName="NoOfEmployees" required
                                        appearance="outline" [disabled]="PageRights.IsReadOnly">
                                        <mat-option [value]="employeeItem.Id"
                                            *ngFor="let employeeItem of numberOfemployeesList">
                                            {{ employeeItem.Name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="employerForm.controls.NoOfEmployees.invalid">
                                        {{getErrorMessage(employerForm, 'NoOfEmployees')}}
                                    </mat-error>
                                </mat-form-field>

                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">

                                <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Year of Foundation</mat-label>
                                  
                                    <input matInput [matDatepicker]="dp" placeholder="Year of Foundation"
                                        name="dateOfFoundation" formControlName="DateOfFoundation" [max]="CurrentDate"
                                        [disabled]="PageRights.IsReadOnly" required>
                                    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                                    <mat-datepicker #dp startView="multi-year"
                                        (yearSelected)="chosenYearHandler($event, dp)"
                                        panelClass="example-month-picker">
                                    </mat-datepicker>
                                    <mat-error *ngIf="employerForm.controls.DateOfFoundation.invalid">
                                        {{getErrorMessage(employerForm, 'DateOfFoundation')}}
                                    </mat-error>
                                </mat-form-field>

                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-checkbox matInput formControlName="BusinessType"
                                    [disabled]="PageRights.IsReadOnly">
                                </mat-checkbox>
                                <span class="checkbox-space">I am a Freelancer/ Independent</span>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <span class="checkbox-space">Is Active?</span>
                                <mat-checkbox matInput formControlName="IsActive" [disabled]="PageRights.IsReadOnly">
                                </mat-checkbox>

                            </div>

                        </fieldset>

                        <fieldset>
                            <legend>Office Address</legend>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Branch Type</mat-label>
                                    <mat-select name="isSingleBranch" formControlName="IsSingleBranch" required
                                        appearance="outline" [disabled]="PageRights.IsReadOnly"
                                        (selectionChange)="onChangeBranchType($event.value)">
                                        <mat-option [value]="branchItem.Id" *ngFor="let branchItem of branchTypeList">
                                            {{ branchItem.Name }}
                                        </mat-option>
                                    </mat-select>
                                    <!-- <mat-error *ngIf="internshipOpportunityForm.controls.BranchType.invalid">
                                    {{getErrorMessage(internshipOpportunityForm, 'InternshipType')}}
                                </mat-error> -->
                                </mat-form-field>

                                <!-- <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Branch Type</mat-label>
                                    <mat-select name="branchType" formControlName="IsSingleBranch" required
                                        appearance="outline" [disabled]="PageRights.IsReadOnly"  (selectionChange)="onChangeBranchType($event.value)">
                                        <mat-option [value]="branchItem.Id"
                                            *ngFor="let branchItem of branchTypeList">
                                            {{ branchItem.Name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field> -->

                            </div>
                            <div formGroupName="EmployerListingBranchForm">

                                <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                    fxLayoutGap.lt-md="0px" *ngIf="isSingleBranch==false">
                                    <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100"
                                        fxFlex.lt-sm="100">
                                        <mat-label>Branch Name</mat-label>
                                        <input matInput required formControlName="BranchName"
                                            placeholder="Ex. Branch Name" matTooltipPosition="above">
                                        <!-- <mat-error *ngIf="employerForm.controls.EmployerListingBranchForm.get('BranchName').invalid">
                                       {{getErrorMessage(employerForm.controls.EmployerListingBranchForm, 'BranchName')}}
                                   </mat-error> -->
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                    fxLayoutGap.lt-md="0px">
                                    <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100"
                                        fxFlex.lt-sm="100">
                                        <mat-label>Branch Address</mat-label>
                                        <input matInput required formControlName="BranchAddress"
                                            placeholder="Ex. BranchAddress" matTooltipPosition="above">
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                    fxLayoutGap.lt-md="0px" *ngIf="isSingleBranch==false">
                                    <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="100"
                                        fxFlex.lt-sm="100">
                                        <mat-label>Point of Contact/ Supervisor</mat-label>
                                        <input matInput required formControlName="POCName"
                                            placeholder="Ex. Point of Contact/ Supervisor" matTooltipPosition="above">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="100"
                                        fxFlex.lt-sm="100">
                                        <mat-label>POC Mobile No.</mat-label>
                                        <input matInput required formControlName="POCMobile"
                                            placeholder="Ex. 9999912345" matTooltipPosition="above" digitOnly
                                            minlength="10" maxlength="10">
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="100"
                                        fxFlex.lt-sm="100">
                                        <mat-label>POC Email ID</mat-label>
                                        <input matInput formControlName="POCEmailId"
                                            placeholder="Ex. POCEmailId@xyz.com" matTooltipPosition="above">
                                    </mat-form-field>

                                </div>

                                <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                    fxLayoutGap.lt-md="0px">
                                    <mat-form-field fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                        appearance="outline">
                                        <mat-label>State</mat-label>

                                        <mat-select formControlName="StateCode" name="stateCode"
                                            [disabled]="PageRights.ActionType == 'new'"
                                            (selectionChange)="onChangeState($event.value)">
                                            <mat-option [value]="stateItem.Id" *ngFor="let stateItem of stateList">
                                                {{ stateItem.Name }}
                                            </mat-option>
                                        </mat-select>
                                        <!-- <mat-error *ngIf="employerForm.controls.workLocationForm.get('StateCode').invalid">
                                       {{getErrorMessage(employerForm.controls.workLocationForm, 'StateCode')}}
                                   </mat-error> -->
                                    </mat-form-field>



                                    <mat-form-field fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                        appearance="outline">
                                        <mat-label>District</mat-label>
                                        <mat-select formControlName="DistrictCode" name="districtCode" required
                                            [disabled]="PageRights.IsReadOnly">
                                            <mat-option [value]="divisionItem.Id"
                                                *ngFor="let divisionItem of divisionList">
                                                {{ divisionItem.Name }}
                                            </mat-option>
                                        </mat-select>
                                        <!-- <mat-error *ngIf="employerForm.controls.workLocationForm.get('DistrictId').invalid">
                                        {{getErrorMessage(employerForm.controls.workLocationForm, 'DistrictId')}}
                                    </mat-error> -->
                                    </mat-form-field>
                                    <mat-form-field fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                        appearance="outline">
                                        <mat-label>Pincode</mat-label>
                                        <input matInput name="pincode" required formControlName="Pincode" digitOnly
                                            minlength="6" maxlength="6" matTooltip="Enter the 6 digit pincode."
                                            matTooltipPosition="above">

                                    </mat-form-field>
                                </div>


                                <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                    fxLayoutGap.lt-md="0px">


                                    <div fxFlex.lt-md="75" fxFlex.lt-sm="100" fxLayoutGap="10px"
                                        fxLayoutGap.lt-md="0px">
                                        <!-- <div class="checkbox-row" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100"> -->

                                        <div class="checkbox-row IsActiveBranch" fxFlex="100" fxFlex.lt-md="50"
                                            fxFlex.lt-sm="100" style="float: left;max-width: 15%;">
                                            <span class="checkbox-space" fxFlex="80">Is Active?</span>
                                            <mat-checkbox matInput formControlName="IsActive" fxFlex="20"
                                                [disabled]="PageRights.IsReadOnly">
                                            </mat-checkbox>
                                        </div>
                                        <!-- <div class="IsMainBranch" fxFlex="100" fxFlex.lt-md="50" fxFlex.lt-sm="100"
                                        style="float: left;max-width: 30%;" *ngIf="isSingleBranch==false">
                                        <span class="checkbox-space">Is Main Branch?</span>
                                        <mat-checkbox matInput formControlName="IsMainBranch" fxFlex="20"
                                            [disabled]="PageRights.IsReadOnly">
                                        </mat-checkbox>
                                    </div> -->


                                        <!-- </div> -->
                                    </div>
                                    <p id="hideMeAfter5Seconds" *ngIf="errorMessage!=undefined"><b></b> <span
                                            style="color:red;">
                                            {{errorMessage}}</span></p>
                                </div>
                                <div fxLayout="row" fxLayout="row" fxLayoutAlign="end end" fxLayout.lt-md="column"
                                    class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px"
                                    *ngIf="isSingleBranch==false">

                                    <!-- <div fxFlex="15" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="wl-action-div"> -->
                                    <button mat-raised-button (click)="onAddWorkLocation()"
                                        class="add-account-button fuse-white mt-24 mt-md-0">
                                        <span>{{ workLocationAction =='add' ? 'Add' : 'Save' }}</span>
                                    </button>
                                    <button mat-raised-button (click)="onClearWorkLocation()"
                                        class="add-account-button fuse-white mt-24 mt-md-0">
                                        <span>Clear</span>
                                    </button>
                                    <!-- </div> -->
                                </div>
                                <mat-divider class="ig-mat-divider"></mat-divider>
                                <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                    fxLayoutGap.lt-md="0px" *ngIf="isSingleBranch==false">

                                    <div class="content-card">
                                        <mat-table class="account-table responsive-table" #table
                                            [dataSource]="tableDataSource" matSort fusePerfectScrollbar
                                            [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                                            <!-- StateName Column -->
                                            <ng-container matColumnDef="StateName">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    State Name
                                                </th>
                                                <td mat-cell *matCellDef="let element"> {{element.StateName}} </td>
                                            </ng-container>

                                            <!-- BranchName Column -->
                                            <ng-container matColumnDef="BranchName">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Branch Name
                                                </th>
                                                <td mat-cell *matCellDef="let element"> {{element.BranchName}} </td>
                                            </ng-container>

                                            <!-- BranchAddress Column -->
                                            <ng-container matColumnDef="BranchAddress">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Branch Address
                                                </th>
                                                <td mat-cell *matCellDef="let element"> {{element.BranchAddress}} </td>
                                            </ng-container>

                                            <!-- BlockName Column -->
                                            <ng-container matColumnDef="POCName">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    POC Name
                                                </th>
                                                <td mat-cell *matCellDef="let element"> {{element.POCName}} </td>
                                            </ng-container>

                                            <!-- ClusterName Column -->
                                            <ng-container matColumnDef="POCEmailId">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    POC EmailId
                                                </th>
                                                <td mat-cell *matCellDef="let element"> {{element.POCEmailId}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="Actions" stickyEnd>
                                                <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                                                <mat-cell *matCellDef="let element, let wlIndex = index">
                                                    <button mat-icon-button (click)="onEditWorkLocation(wlIndex)">
                                                        <mat-icon color="primary">edit</mat-icon>
                                                    </button>
                                                    <button mat-icon-button (click)="onDeleteWorkLocation(wlIndex)">
                                                        <mat-icon color="warn">delete_outline</mat-icon>
                                                    </button>
                                                    <!-- *ngIf="element.IsMainBranch == false" -->
                                                </mat-cell>
                                            </ng-container>

                                            <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true">
                                            </mat-header-row>
                                            <mat-row *matRowDef="let account; columns: displayedColumns;"
                                                class="account" matRipple></mat-row>
                                        </mat-table>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset formGroupName="EmployersSectorsForm">
                            <legend>Sector</legend>



                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100" appearance="outline">
                                    <mat-label>Sector</mat-label>

                                    <mat-select formControlName="SectorId" required name="sectorId"
                                        [disabled]="PageRights.IsReadOnly">
                                        <mat-option [value]="stateItem.Id" *ngFor="let stateItem of sectorList">
                                            {{ stateItem.Name }}
                                        </mat-option>
                                    </mat-select>

                                </mat-form-field>




                            </div>
                            <div fxLayout="row" fxLayout="row" fxLayoutAlign="end end" fxLayout.lt-md="column"
                                class="input-row" fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                                <button mat-raised-button (click)="onAddSector()"
                                    class="add-account-button fuse-white mt-24 mt-md-0">
                                    <span>{{ workAction =='add' ? 'Add' : 'Save' }}</span>
                                </button>
                                <button mat-raised-button (click)="onClearSector()"
                                    class="add-account-button fuse-white mt-24 mt-md-0">
                                    <span>Clear</span>
                                </button>

                            </div>




                            <mat-divider class="ig-mat-divider"></mat-divider>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">

                                <div class="content-card">
                                    <mat-table class="account-table responsive-table" #table
                                        [dataSource]="tableDataSource1" matSort fusePerfectScrollbar
                                        [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                                        <ng-container matColumnDef="SectorName">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                Sector
                                            </th>
                                            <td mat-cell *matCellDef="let element"> {{element.SectorName}} </td>
                                        </ng-container>





                                        <ng-container matColumnDef="Actions" stickyEnd>
                                            <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                                            <mat-cell *matCellDef="let element, let wlIndex = index">
                                                <button mat-icon-button (click)="onEditSector(wlIndex)">
                                                    <mat-icon color="primary">edit</mat-icon>
                                                </button>
                                                <button mat-icon-button (click)="onDeleteSector(wlIndex)">
                                                    <mat-icon color="warn">delete_outline</mat-icon>
                                                </button>
                                            </mat-cell>
                                        </ng-container>

                                        <mat-header-row *matHeaderRowDef="displayedColumns1; sticky:true">
                                        </mat-header-row>
                                        <mat-row *matRowDef="let account; columns: displayedColumns1;" class="account"
                                            matRipple></mat-row>
                                    </mat-table>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->


        <!-- CONTENT CARD 1 -->
        <div class="content-card" *ngIf="tabIndex === 1">

            <div class="px-3 px-xxl-5">
                <div class="container-fluid px-0">
                    <div class="row">
                        <div class="col-12">
                            <h5 class="font-weight-semibold opensans-font mt-3 pb-md-3">Upload Excel</h5>
                            <div class="card rounded-12 shadow-dark-80 border border-gray-50 mb-3 mb-xl-4 p-3">
                                <div
                                    class="card-body p-0 d-flex flex-column justify-content-center py-5 align-items-center">
                                    <!-- <span class="d-block font-size-16 text-gray-600 mt-1 mb-3 mt-5 fw-400">Drag & Drop
                                        Files here or</span>

                                    <button type="button" class="btn btn-outline-primary btn-lg border-2 mb-5">Choose a
                                        file</button> -->
                                    <mat-form-field appearance="outline" class="pr-20" fxFlex="70" fxFlex.lt-md="50"
                                        fxFlex.lt-sm="100">
                                        <mat-label>Upload Excel File</mat-label>
                                        <ngx-mat-file-input name="uploadFile" formControlName="UploadFile" required
                                            [accept]="AllowedExcelExtensions" (change)="uploadedFile($event)"
                                            placeholder="Select excel template file"
                                            matTooltip="Select excel template file" matTooltipPosition="above">
                                        </ngx-mat-file-input>

                                        <mat-error *ngIf="dataUploadForm.controls.UploadFile.invalid">
                                            {{getErrorMessage(dataUploadForm, 'UploadFile')}}
                                        </mat-error>
                                    </mat-form-field>





                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="border-top  mb-5 pb-5 mt-4">
                        <!-- <div
                            class="d-flex justify-content-between align-items-center border-bottom border-gray-400 py-3 px-2">
                            <div>1. <span>Student-list-file.xlsx</span></div>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-x cursor-pointer" width="18" height="18"
                                viewBox="0 0 24 24" stroke-width="1.5" stroke="red" fill="none" stroke-linecap="round"
                                stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                        </div> -->

                        <div class="row" style="text-align:center ;">
                            <div class="col-12">
                                <div class=" justify-content-center">
                                    <a class="btn btn-lg btn-success transition-3d-hover hover-shadow-success px-3 me-2 me-md-3 add-school-button"
                                        (click)="uploadExcelData()">
                                        <span class="ps-1">Upload File</span>
                                    </a>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>


        </div>
        <!-- / CONTENT1 CARD -->

    </div>
    <!-- / CENTER -->

</div>