<div id="profile-change-request" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <!-- {{ 'SCHOOL.SCHOOLS' | translate }} -->
                    Change Profile Detail Request
                    <!-- <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a> -->
                    <button mat-icon-button class="refresh-data mt-24 mt-md-0" (click)="refreshPage()">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}"
                            style="color: #a4ef13;margin-top: -15% !important;">
                            refresh</mat-icon>
                    </button>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <form [formGroup]="studentSearchForm">
                    <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon>search</mat-icon>
                        <input #filter (keyup)="applySearchFilter($event.target.value)"
                            placeholder="Search for a change profile detail request">
                    </div>
                </form>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">




            </div>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->
        <!-- Tab Section -->

        <div class="padding-bottom">
            <mat-tab-group selectedIndex="0" (selectedTabChange)="changeTab($event)">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>New Request</span>
                    </ng-template>

                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Completed</span>
                    </ng-template>

                </mat-tab>
            </mat-tab-group>
        </div>
        <!-- /Tab Section -->

        <!-- Filter Section -->
        <div class="filter-content-card">
            <div class="filter-content">
                <form name="studentFilterForm" [formGroup]="studentFilterForm" class="vc-issue-report" fxLayout="column"
                    fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">



                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Sector</mat-label>
                            <mat-select formControlName="SectorId" name="sectorId" appearance="outline">
                                <mat-option [value]="sectorItem.Id" *ngFor="let sectorItem of sectorList">
                                    {{ sectorItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>



                        <div class="clear-filter pr-1" fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                            *ngIf="tabIndex === 0">
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="onLoadSchoolsByCriteria()"><span>SEARCH</span></button>
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="resetFilters()"><span>CLEAR</span></button>
                        </div>

                        <div class="clear-filter pr-1" fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                            *ngIf="tabIndex === 1">
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="onLoadSchoolsByCriteriaCom()"><span>SEARCH</span></button>
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="resetFilters()"><span>CLEAR</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- /Filter Section -->


        <!-- CONTENT CARD 0 -->
        <div class="content-card rounded-12 shadow-dark-80" *ngIf="tabIndex === 0">


            <mat-table class="school-table responsive-table" [dataSource]="tableDataSource" matSort fusePerfectScrollbar
                [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- FullName Column -->
                <ng-container matColumnDef="COMPLETE">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>
                        COMPLETE
                    </mat-header-cell>
                    <mat-cell *matCellDef="let profileChane">
                        <mat-checkbox (change)="onChangeStudent(profileChane.RequestId)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>


                <!-- STUDENT Column -->
                <ng-container matColumnDef="STUDENT">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
                        STUDENT
                    </mat-header-cell>
                    <mat-cell *matCellDef="let profileChane" fxHide fxShow.gt-xs >
                        <p class="text-truncate" (click)="studentLink(profileChane.StudentId)">
                            <span *ngIf="profileChane.StudentProfilePhoto !== ''  ">
                                <span *ngIf="profileChane.StudentProfilePhoto !== null "><img
                                        [src]="ApiBaseUrlImage+profileChane.StudentProfilePhoto" width="50" height="50"
                                        style="border-radius: 50%;">
                                </span>
                            </span>
                            <span style="margin-left: 5%;">{{profileChane.StudentName}}</span>
                        </p>
                    </mat-cell>
                </ng-container>



                <!-- CHANGES Column -->
                <ng-container matColumnDef="CHANGES">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>CHANGES
                    </mat-header-cell>
                    <mat-cell *matCellDef="let profileChane">
                        <p class="text-truncate">{{profileChane.RequestDesc}}</p>
                    </mat-cell>
                </ng-container>

                <!-- CHANGES Column -->
                <ng-container matColumnDef="DATE">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>DATE
                    </mat-header-cell>
                    <mat-cell *matCellDef="let profileChane">
                        <p class="text-truncate">{{profileChane.RequestedDate | date: Constants.ShortDateFormat}}</p>
                    </mat-cell>
                </ng-container>




                <ng-container matColumnDef="Actions" stickyEnd>
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let profileChane" fxShow.gt-xs>

                        <button *ngIf="PageRights.IsEdit || UserModel.RoleCode != 'HM'" mat-icon-button
                            [routerLink]="['/profile-change-request', 'edit', profileChane.StudentId]">
                            <mat-icon color="primary">edit</mat-icon>
                        </button>

                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let profileChane; columns: displayedColumns;" 
                     class="school" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="SearchBy.TotalResults" [pageIndex]="SearchBy.PageIndex"
                [pageSize]="SearchBy.PageSize" [pageSizeOptions]="SearchBy.PageSizeOptions"
                [showFirstLastButtons]="SearchBy.ShowFirstLastButtons" (page)="onPageIndexChanged($event)">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD 0 -->
        <!-- CONTENT CARD 1-->     
        <div class="content-card rounded-12 shadow-dark-80" *ngIf="tabIndex === 1">


            <mat-table class="school-table responsive-table" [dataSource]="tableDataSource" matSort fusePerfectScrollbar
                [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- FullName Column -->



                <!-- STUDENT Column -->
                <ng-container matColumnDef="STUDENT">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
                        STUDENT
                    </mat-header-cell>
                    <mat-cell *matCellDef="let profileChane" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{profileChane.StudentName}}</p>
                    </mat-cell>
                </ng-container>



                <!-- CHANGES Column -->
                <ng-container matColumnDef="CHANGES">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>CHANGES
                    </mat-header-cell>
                    <mat-cell *matCellDef="let profileChane">
                        <p class="text-truncate">{{profileChane.RequestDesc}}</p>
                    </mat-cell>
                </ng-container>

                <!-- CHANGES Column -->
                <ng-container matColumnDef="DATE">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>DATE
                    </mat-header-cell>
                    <mat-cell *matCellDef="let profileChane">
                        <p class="text-truncate">{{profileChane.RequestedDate | date: Constants.ShortDateFormat}}</p>
                    </mat-cell>
                </ng-container>






                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let student; columns: displayedColumns;" class="school" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="SearchBy.TotalResults" [pageIndex]="SearchBy.PageIndex"
                [pageSize]="SearchBy.PageSize" [pageSizeOptions]="SearchBy.PageSizeOptions"
                [showFirstLastButtons]="SearchBy.ShowFirstLastButtons" (page)="onPageIndexChanged($event)">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD 1-->
    </div>
    <!-- / CENTER -->
</div>