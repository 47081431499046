<div id="internship-assignments" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    Employer-Student Mapping

                    <button mat-icon-button class="refresh-data mt-24 mt-md-0" (click)="refreshPage()">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}"
                            style="color: #a4ef13;margin-top: -15% !important;">
                            refresh</mat-icon>
                    </button>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <form [formGroup]="studentSearchForm">
                    <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon>search</mat-icon>
                        <input #filter (keyup)="applySearchFilter($event.target.value)"
                            placeholder="Search for a student name request">
                    </div>
                </form>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons" *ngIf="selectedStudentIds.length != 0">

                <button mat-raised-button (click)="onClickMap()" *ngIf="tabIndex === 0"
                    class="add-sector-job-role-button fuse-white mt-24 mt-md-0 btnC">
                    <span>Map</span>
                </button>



            </div>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->
        <!-- Tab Section -->

        <div class="padding-bottom">
            <mat-tab-group selectedIndex="0" (selectedTabChange)="changeTab($event.index)">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Unmapped Students</span>
                    </ng-template>

                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Mapped Students</span>
                    </ng-template>

                </mat-tab>
            </mat-tab-group>
        </div>
        <!-- /Tab Section -->

        <!-- Filter Section -->
        <div class="filter-content-card padding-bottom">
            <div class="filter-content">
                <form name="studentFilterForm" [formGroup]="studentFilterForm" class="vc-issue-report" fxLayout="column"
                    fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <!-- <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Name </mat-label>

                            <mat-select formControlName="Name" name="name" appearance="outline">
                                <mat-option [value]="ctem.Id" *ngFor="let ctem of classList">
                                    {{ ctem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field> -->

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Gender </mat-label>

                            <mat-select formControlName="Gender" name="gender" appearance="outline">
                                <mat-option [value]="gtem.Id" *ngFor="let gtem of genderList">
                                    {{ gtem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Class </mat-label>

                            <mat-select formControlName="Class" name="class" appearance="outline"
                                (selectionChange)="onChangeSection($event.value)">
                                <mat-option [value]="ctem.Id" *ngFor="let ctem of classList">
                                    {{ ctem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Section </mat-label>

                            <mat-select formControlName="Section" name="section" appearance="outline">
                                <mat-option [value]="stem.Id" *ngFor="let stem of sectionList">
                                    {{ stem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>



                        <div class="clear-filter pr-1 padding-bottom" fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                            *ngIf="tabIndex === 0">

                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="onLoadSchoolsByCriteria()"><span>SEARCH</span></button>
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="resetFilters()"><span>CLEAR</span></button>
                        </div>

                        <div class="clear-filter pr-1 padding-bottom" fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                            *ngIf="tabIndex === 1">
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="onLoadSchoolsByCriteriaCom()"><span>SEARCH</span></button>
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="resetFilters()"><span>CLEAR</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- /Filter Section -->


        <!-- CONTENT CARD 0 -->
        <div class="content-card rounded-12 shadow-dark-80" *ngIf="tabIndex === 0">


            <mat-table class="school-table responsive-table" [dataSource]="tableDataSource" matSort fusePerfectScrollbar
                [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- FullName Column -->
                <ng-container matColumnDef="COMPLETE">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>
                        COMPLETE
                    </mat-header-cell>
                    <mat-cell *matCellDef="let profileChane">
                        <mat-checkbox (change)="onChangeStudent(profileChane.StudentId, $event)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>


                <!-- STUDENT Column -->
                <ng-container matColumnDef="STUDENT">
                    <!-- <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md fxShow.gt-xs>
                        STUDENT
                    </mat-header-cell>
                    <mat-cell *matCellDef="let profileChane" fxShow.gt-xs> -->

                        <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md fxShow.gt-xs>
                            STUDENT
                        </mat-header-cell>
                        <mat-cell *matCellDef="let profileChane" fxShow.lt-sm fxShow.gt-md fxShow.gt-xs>
                            <p class="text-truncate">
                                <span *ngIf="profileChane.StudentProfilePhoto">
                                    <span *ngIf="profileChane.StudentProfilePhoto !== null "><img
                                            [src]="ApiBaseUrlImage+profileChane.StudentProfilePhoto" width="50"
                                            height="50" style="border-radius: 50%;
                                        width: 50px !important;
                                        height: 50px !important;">
                                    </span>
                                </span>
                                <span style="margin-left: 5%;">{{profileChane.StudentName}}</span>
                            </p>
                        </mat-cell>
                </ng-container>



                <!-- GENDER Column -->
                <ng-container matColumnDef="GENDER">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide>GENDER
                    </mat-header-cell>
                    <mat-cell *matCellDef="let profileChane" fxHide>
                        <p class="text-truncate">{{profileChane.StudentGender}}</p>
                    </mat-cell>
                </ng-container>

                <!-- MOBILE Column -->
                <ng-container matColumnDef="MOBILE">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>MOBILE
                    </mat-header-cell>
                    <mat-cell *matCellDef="let profileChane">
                        <p class="text-truncate">{{profileChane.Mobile}}</p>
                    </mat-cell>
                </ng-container>

                <!-- ClASS Column -->
                <ng-container matColumnDef="ClASS">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>ClASS
                    </mat-header-cell>
                    <mat-cell *matCellDef="let profileChane">
                        <p class="text-truncate">{{profileChane.ClassName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SECTION Column -->
                <ng-container matColumnDef="SECTION">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>SECTION
                    </mat-header-cell>
                    <mat-cell *matCellDef="let profileChane">
                        <p class="text-truncate">{{profileChane.SectionName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- JOBROLE Column -->
                <ng-container matColumnDef="JOBROLE">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>JOBROLE
                    </mat-header-cell>
                    <mat-cell *matCellDef="let profileChane">
                        <p class="text-truncate">{{profileChane.JobRoleName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SECTOR Column -->
                <ng-container matColumnDef="SECTOR">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>SECTOR
                    </mat-header-cell>
                    <mat-cell *matCellDef="let profileChane">
                        <p class="text-truncate">{{profileChane.SectorName}}</p>
                    </mat-cell>
                </ng-container>



                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let student; columns: displayedColumns;" class="school" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="SearchBy.TotalResults" [pageIndex]="SearchBy.PageIndex"
                [pageSize]="SearchBy.PageSize" [pageSizeOptions]="SearchBy.PageSizeOptions"
                [showFirstLastButtons]="SearchBy.ShowFirstLastButtons" (page)="onPageIndexChanged($event)">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD 0 -->
        <!-- CONTENT CARD 1-->
        <div class="content-card rounded-12 shadow-dark-80" *ngIf="tabIndex === 1">


            <mat-table class="school-table responsive-table" [dataSource]="tableDataSource" matSort fusePerfectScrollbar
                [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">




                <!-- STUDENT Column -->
                <ng-container matColumnDef="STUDENT">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>
                        STUDENT
                    </mat-header-cell>
                    <mat-cell *matCellDef="let profileChane" fxShow.lt-sm fxShow.gt-md>
                        <p class="text-truncate">
                            <span *ngIf="profileChane.StudentProfilePhoto">
                                <span *ngIf="profileChane.StudentProfilePhoto !== null "><img
                                        [src]="ApiBaseUrlImage+profileChane.StudentProfilePhoto" width="50" height="50"
                                        style="border-radius: 50%;">
                                </span>
                            </span>
                            <span style="margin-left: 5%;">{{profileChane.StudentName}}</span>
                        </p>
                    </mat-cell>
                </ng-container>



                <!-- GENDER Column -->
                <ng-container matColumnDef="GENDER">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>GENDER
                    </mat-header-cell>
                    <mat-cell *matCellDef="let profileChane" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{profileChane.StudentGender}}</p>
                    </mat-cell>
                </ng-container>

                <!-- MOBILE Column -->
                <ng-container matColumnDef="MOBILE">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>MOBILE
                    </mat-header-cell>
                    <mat-cell *matCellDef="let profileChane">
                        <p class="text-truncate">{{profileChane.Mobile}}</p>
                    </mat-cell>
                </ng-container>

                <!-- ClASS Column -->
                <ng-container matColumnDef="ClASS">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>ClASS
                    </mat-header-cell>
                    <mat-cell *matCellDef="let profileChane">
                        <p class="text-truncate">{{profileChane.ClassName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SECTION Column -->
                <ng-container matColumnDef="SECTION">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>SECTION
                    </mat-header-cell>
                    <mat-cell *matCellDef="let profileChane">
                        <p class="text-truncate">{{profileChane.SectionName}}</p>
                    </mat-cell>
                </ng-container>



                <!-- INTERNSHIPTITLE Column -->
                <ng-container matColumnDef="INTERNSHIPTITLE">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>INTERNSHIPTITLE
                    </mat-header-cell>
                    <mat-cell *matCellDef="let profileChane">
                        <p class="text-truncate">{{profileChane.InternshipTitle}}</p>
                    </mat-cell>
                </ng-container>

                <!-- EMPLOYERNAME Column -->
                <ng-container matColumnDef="EMPLOYERNAME">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>EMPLOYERNAME
                    </mat-header-cell>
                    <mat-cell *matCellDef="let profileChane">
                        <p class="text-truncate">{{profileChane.EmployerName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- CHANGES Column -->
                <ng-container matColumnDef="DATE">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>DATE
                    </mat-header-cell>
                    <mat-cell *matCellDef="let profileChane">
                        <p class="text-truncate">{{profileChane.CommencementDate | date: Constants.ShortDateFormat}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Actions" stickyEnd>
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let profileChane" fxShow.gt-xs>

                        <button mat-icon-button (click)="onClickMapEdit(profileChane)"
                            *ngIf="profileChane.DiaryEntryFound == false">
                            <mat-icon color="primary">edit</mat-icon>
                        </button>

                        <button mat-icon-button
                            (click)="onClickMapCancel(profileChane.StudentId,profileChane.InternshipId)">
                            <mat-icon color="primary">delete</mat-icon>
                        </button>

                    </mat-cell>
                </ng-container>


                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let student; columns: displayedColumns;" class="school" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="SearchBy.TotalResults" [pageIndex]="SearchBy.PageIndex"
                [pageSize]="SearchBy.PageSize" [pageSizeOptions]="SearchBy.PageSizeOptions"
                [showFirstLastButtons]="SearchBy.ShowFirstLastButtons" (page)="onPageIndexChanged($event)">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD 1-->
    </div>
    <!-- / CENTER -->
</div>