import { Component, OnInit, NgZone, ViewEncapsulation, ViewChild, Inject } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StudentService } from '../student.service';
import { StudentModel } from '../student.model';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';


import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { environment } from 'environments/environment';
import { AnySoaRecord } from 'dns';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MarksDetailsComponent } from '../marks-details/marks-details.component';
import { DropDetailsComponent } from '../drop-details/drop-details.component';


@Component({
  selector: 'StudentDetails',
  templateUrl: './student-details.component.html',
  styleUrls: ['./student-details.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class StudentDetailsComponent extends BaseListComponent<StudentModel> implements OnInit {
  studentDetailsData: any;
  DailyDiaryImageAttachments: any;
  StudentDashboardInternshipData: any;
  DailyDiaryEntryByStudentId: any;
  RequestsByStudentIdRequestType: any;
  RequestsByStudentIdRequestTypeProfile: any;
  StudentInternshipsByCriteria: any;
  RejectInternshipApplication: any;
  studentFilterForm: FormGroup;
  tabIndex = 0;
  img = this.Constants.CommonImageUrl.Account;
  imageAttachmentsFA = this.Constants.CommonImageUrl.Attachments;
  imageAttachments = this.Constants.CommonImageUrl.Attachments;
  FinalInternshipImageBaseURL = this.Constants.CommonImageUrl.FinalInternshipReports;

  tableDataSource: any;
  tableDataSourceFinalAssignment: any;
  tableDataSourceGallery: any;
  ListPaginator: any;
  IsLoading: boolean;
  displayedColumns: string[];
  ListSort: any;
  StudentId: string;
  data: any;
  progressData: any;
  // options
  gradient: boolean = true;
  showLegend: boolean = false;
  showLabels: boolean = false;
  isDoughnut: boolean = false;
  view: any[] = [700, 400];
  colorScheme = {
    // domain: ['#A10A28', '#5AA454', '#C7B42C', '#AAAAAA'],
    domain: ['rgb(168, 203, 254)', 'rgb(0, 143, 251)'],
  };

  // single = [];
  single = [
    // {
    //   name: 'Total Hrs',
    //   value: 68
    // },
    // {
    //   name: 'Actual Hrs',
    //   value: 12
    // }
    // {
    //   "name": "France",
    //   "value": 7200000
    // },
    //   {
    //   "name": "UK",
    //   "value": 6200000
    // }
  ];
  reportData: any;
  btnHide: boolean;
  internshipId: string;
  certificate: any;
  bestPicture: any;
  reportPhotos: any;
  errorMessage: string;
  isReviewReport: any;
  galleryData: any;
  IRRportPhotos: any;
  IRCetificate: any;
  IRBestPicture: any;
  ImageGalleryData = [] as any;
  dateArray = [{}] as any;
  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private translationLoaderService: FuseTranslationLoaderService,
    public zone: NgZone,
    private dialogService: DialogService,
    private formBuilder: FormBuilder,
    private studentService: StudentService,
    private dialog: MatDialog,
    @Inject(DOCUMENT) document: Document) {
    super(commonService, router, routeParams, snackBar, zone);
  }

  ngOnInit(): void {
    var key = true;
    sessionStorage.setItem('key', JSON.stringify(key));

    this.routeParams.paramMap.subscribe(params => {
      if (params.keys.length > 0) {
        this.StudentId = params.get('StudentId');
      }
    });
    this.onGetDailyDiaryImageAttachments();
    this.onGetStudentDashboardInternshipData();
    this.onGetGetDailyDiaryEntryByStudentId();

    this.onGetRequestsByStudentIdRequestType();
    this.onGetStudentInternshipsByCriteria();
    this.onGetRequestsByStudentIdRequestTypePro()
    // this. onRejectInternshipApplication();
    this.onGetStudentsById();
    this.onLoadFinalInternshipReport();
  }
  onSelect(data): void {
    //console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    //console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    //console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
  onGetStudentsById() {
    let data = {
      DataId: this.StudentId,
      DataId1: sessionStorage.getItem('GlobalAcademicYearId')
    }

    this.studentService.GetStudentsById(data).subscribe(response => {
      this.studentDetailsData = response.Result;

      this.IsLoading = false;
    }, error => {
      console.log(error);
    });
  }






  onGetDailyDiaryImageAttachments() {
    let data = {
      // "StudentID": this.StudentId,
      // "StartDateTime": null,
      // "EndDateTime": null
      "UserTypeId": this.StudentId,
      "PageIndex": 0,
      "PageSize": 10000

    }


    this.studentService.GetAttachmentsByAccountId(data).subscribe(response => {
      // this.displayedColumnsGallery = ['InternshipImage', 'CertificatePicture', 'ProjectReport', 'ReportDetail', 'Actions'];
      this.galleryData = response.Results;
      if (this.galleryData != null && this.galleryData != undefined) {
        //let dateArray = [{ date: '' }];
        for (let i = 0; i < this.galleryData.length; i++) {
          var dateExits = this.dateArray.filter(x => x.date == this.galleryData[i].AttachmentDate.slice(0, 10));
          if (dateExits.length == 0) {
            this.dateArray.push({
              date: this.galleryData[i].AttachmentDate.slice(0, 10),
            });
          }

        }

        // let ArrayObje = [] as any;
        this.IRRportPhotos = this.galleryData.filter(x => x.SectionType == "IRRportPhotos");
        this.IRRportPhotos.forEach(reportPhotos => {
          reportPhotos.AttachmentDate = reportPhotos.AttachmentDate.slice(0, 10);
          this.ImageGalleryData.push(reportPhotos);
        });
        this.IRCetificate = this.galleryData.filter(x => x.SectionType == "IRCetificate");
        this.IRCetificate.forEach(cetificates => {
          cetificates.AttachmentDate = cetificates.AttachmentDate.slice(0, 10);
          this.ImageGalleryData.push(cetificates);
        });
        this.IRBestPicture = this.galleryData.filter(x => x.SectionType == "IRBestPicture");
        this.IRBestPicture.forEach(bestPicture => {
          bestPicture.AttachmentDate = bestPicture.AttachmentDate.slice(0, 10);
          this.ImageGalleryData.push(bestPicture);
        });



      }
      const dateArray = response.Results.reduce((groups, Arrayactivity) => {
        const date = Arrayactivity.AttachmentDate.split(' ')[0];
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(Arrayactivity);
        return groups;
      }, {});

      const finalArrayDailyDiaryImageAttachments = Object.keys(dateArray).map((date) => {
        return {
          date,
          Gallery: dateArray[date]
        };
      });





      this.DailyDiaryImageAttachments = finalArrayDailyDiaryImageAttachments;
      // this.DailyDiaryImageAttachments.map(item => {
      //   item['AttachmentPath'] = item.AttachmentPaths.split(',');

      // })


      this.IsLoading = false;
    }, error => {
      console.log(error);
    });

  }

  onGetStudentDashboardInternshipData() {
    let data = {
      "DataId": this.StudentId,
      "DataId1": null

    }


    this.studentService.GetStudentDashboardInternshipData(data).subscribe(response => {
      this.StudentDashboardInternshipData = response.Results[0];
      let data = response.Results;
      this.single = [];
      if (data.length > 0) {
        data.forEach(element => {
          this.progressData = {
            Points: element.StudentPoints,
            Attendance: element.Attendance,
            Hours: element.Hours
          }

          if (Number(element.Hours) > 0 || Number(this.progressData.Hours.split(':')[0]) > 0) {
            var hh = element.Hours.split(":")[0];
            var mm = element.Hours.split(":")[1];
            let time = Number(hh) * 60 + Number(mm);
            let spentTime = time / 60;

            let TotalHrs: number = 80;
            let ActualHrs: any;
            if (spentTime > TotalHrs) {
              ActualHrs = 0;
            } else {
              ActualHrs = TotalHrs - spentTime;
            }

            this.single.push({
              name: 'Remaining Hours',
              value: ActualHrs
            });

            this.single.push({
              name: 'Actual Hours Spent',
              value: spentTime
            });

          } else {
            this.single.push({
              name: 'Remaining Hours',
              value: 80
            });
            this.single.push({
              name: 'Actual Hours Spent',
              value: 0
            });
          }
        });
      }
      this.IsLoading = false;
    }, error => {
      console.log(error);
    });

  }
  onPageIndexChanged(evt) {
    this.SearchBy.PageIndex = evt.pageIndex;
    this.SearchBy.PageSize = evt.pageSize;

    this.onGetGetDailyDiaryEntryByStudentId();
  }

  onGetGetDailyDiaryEntryByStudentId() {
    let data = {
      "StudentID": this.StudentId,
      "InternshipId": null,
      "StartDateTime": null,
      "EndDateTime": null


    }
    // this.SearchBy.SchoolGuid = '1290cce3-aeed-4d77-b3d1-327cd820feee';//(this.Constants.GlobalSchoolId == 'undefined' || this.Constants.GlobalSchoolId == 'null') ? null : this.Constants.GlobalSchoolId;
    // this.SearchBy.AcademicYearGuid = 'fda59df6-729e-493c-8d48-fc65b90008fe';// (this.Constants.GlobalAcademicYearId == 'undefined' || this.Constants.GlobalAcademicYearId == 'null') ? null : this.Constants.GlobalAcademicYearId;
    // this.SearchBy.SectorGuid = 'a5f9eb0b-c395-11eb-a9b6-000c29808af1';//this.diaryEntryVTFilterForm.controls['SectorId'].value;
    // this.SearchBy.EmployerGuid = 'eeeeeeee-140b-4af8-bade-679404fd3791';//this.diaryEntryVTFilterForm.controls['EmployerId'].value;
    // this.SearchBy.StartDateTime = '2022-07-20';// this.fromDate == 'undefined' ? '' : this.fromDate;//"2020-01-08";
    // this.SearchBy.EndDatetime = '2022-08-30';// this.toDate == 'undefined' ? '' : this.toDate;//"2022-08-08";
    // this.SearchBy.PageIndex = 1;


    this.studentService.GetDailyDiaryEntryByStudentId(data).subscribe(response => {
      this.DailyDiaryEntryByStudentId = response.Results;
      // this.displayedColumns = ['DIARYENTRY', 'HOURSSPENT', 'HIGHLIGTS', 'Actions'];
      this.displayedColumns = ['LessonsLearnt', 'Hours', 'Highlight', 'Actions'];
      let rowdata: any;
      rowdata = response.Results;

      let object = [] as any;
      if (rowdata != null && rowdata != '') {
        for (let i = 0; i < rowdata.length; i++) {
          if (rowdata[i].listEntries.length > 0) {
            var attatchments = rowdata[i].listAttachments;
            rowdata[i].listEntries.forEach(element => {
              element.Attachments = attatchments;
              object.push(element);
            });

          } else {
            console.log('listEntries not available');
          }

        }

        const dateArray: GroupByDateArray[] = [];
        for (let k = 0; k < object.length; k++) {
          let xxx = dateArray.filter(x => x.date == object[k].EntryDate);
          if (xxx == null || xxx.length == 0) {
            dateArray.push({
              date: object[k].EntryDate,
              isGroupBy: true
            })
          }
        }
        //console.log(dateArray);
        let soretdDateArray = dateArray.sort((a, b) => a.date > b.date ? -1 : 1);
        const finalArray: (StudentModel | GroupByDateArray)[] = [];
        for (let i = 0; i < soretdDateArray.length; i++) {
          let yyd = soretdDateArray.filter(x => x.date == soretdDateArray[i].date);
          if (yyd != null) {
            yyd.forEach(element => {
              finalArray.push({
                date: element.date,
                isGroupBy: true
              });
            });
          }
          let asd = object.filter(x => x.EntryDate == soretdDateArray[i].date);

          if (asd != null || asd != '') {
            asd.forEach(element => {
              finalArray.push(element);
            });
          }
        }
        //console.log(finalArray);
        this.tableDataSource.data = finalArray;
      }


      // this.tableDataSource.data = response.Results;
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;
      this.SearchBy.TotalResults = response.TotalResults;
      this.IsLoading = false;
    }, error => {
      console.log(error);
    });

  }

  onGetRequestsByStudentIdRequestType() {
    let data = {
      StudentId: this.StudentId,
      TypeofRequest: "Raise risk Report",
      SchoolGuid: (this.Constants.GlobalSchoolId == 'undefined' || this.Constants.GlobalSchoolId == 'null') ? null : this.Constants.GlobalSchoolId,
      AcademicYearGuid: (this.Constants.GlobalAcademicYearId == 'undefined' || this.Constants.GlobalAcademicYearId == 'null') ? null : this.Constants.GlobalAcademicYearId,
      IsActionTaken: true

    }
    this.studentService.GetRequestsByStudentIdRequestType(data).subscribe(response => {
      this.RequestsByStudentIdRequestType = response.Results;
      this.IsLoading = false;
    }, error => {
      console.log(error);
    });

  }

  onGetRequestsByStudentIdRequestTypePro() {
    let data = {
      StudentId: this.StudentId,
      TypeofRequest: "Profile Correction Details",
      SchoolGuid: (this.Constants.GlobalSchoolId == 'undefined' || this.Constants.GlobalSchoolId == 'null') ? null : this.Constants.GlobalSchoolId,
      AcademicYearGuid: (this.Constants.GlobalAcademicYearId == 'undefined' || this.Constants.GlobalAcademicYearId == 'null') ? null : this.Constants.GlobalAcademicYearId,
      IsActionTaken: true

    }
    this.studentService.GetRequestsByStudentIdRequestType(data).subscribe(response => {
      this.RequestsByStudentIdRequestTypeProfile = response.Results;
      this.IsLoading = false;
    }, error => {
      console.log(error);
    });

  }

  onGetStudentInternshipsByCriteria() {
    let data = {
      "DataId": this.StudentId

    }

    this.studentService.GetStudentInternshipsByCriteria(data).subscribe(response => {
      this.StudentInternshipsByCriteria = response.Results;
      this.IsLoading = false;
    }, error => {
      console.log(error);
    });

  }

  onRejectInternshipApplication(InternshipId: string) {
    let data = {
      "DataId": this.StudentId, //StudentId
      "DataId1": InternshipId  //InternshipId

    }


    // this.studentService.RejectInternshipApplication(data).subscribe(response => {
    //   this.RejectInternshipApplication=response.Results;
    //   this.IsLoading = false;
    // }, error => {
    //   console.log(error);
    // });
    this.dialogService
      .openConfirmDialog('Are you sure reject internship')
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          this.studentService.RejectInternshipApplication(data)
            .subscribe((schoolResp: any) => {

              this.zone.run(() => {
                if (schoolResp.Success) {
                  this.showActionMessage(
                    this.Constants.Messages.RecordDeletedMessage,
                    this.Constants.Html.SuccessSnackbar
                  );
                }
                // this.ngOnInit();
              }, error => {
                console.log(' errors =>', error);
              });

            });
          // this.ngOnInit();
        }
      });

  }

  onClickHireStudent(internshipId: string) {
    let data = {
      "DataId": this.StudentId, //StudentId
      "DataId1": internshipId  //InternshipId

    }


    this.studentService.hireStudent(this.StudentId, internshipId).subscribe(response => {
      if (response.Success) {
        this.zone.run(() => {
          this.showActionMessage(
            response.Messages[0],
            this.Constants.Html.SuccessSnackbar
          );

          // this.router.navigate([RouteConstants.Student.List]);
        });
      } else {
        this.zone.run(() => {
          this.showActionMessage(
            response.Messages[0],
            this.Constants.Html.ErrorSnackbar,
          );

        });
      }

    });
  }

  onLoadFinalInternshipReport() {
    this.studentService.getFinalInternshipReport(this.StudentId).subscribe(response => {
      this.displayedColumnsFinalInternship = ['InternshipImage', 'CertificatePicture', 'ProjectReport', 'ReportDetail', 'Actions'];
      this.reportData = response.Results;

      const dateArray: GroupByDateArraysFA[] = [];
      for (let k = 0; k < response.Results.length; k++) {
        let resDate = dateArray.filter(x => x.date == response.Results[k].ActualEndDate);
        if (resDate == null || resDate.length == 0) {
          dateArray.push({
            date: response.Results[k].ActualEndDate,
            isGroupBy: true
          })
        }
      }

      // console.log(dateArray);

      let soretdDateArray = dateArray.sort((a, b) => a.date > b.date ? -1 : 1);
      const finalArrayInternshipAssignment: (StudentModel | GroupByDateArraysFA)[] = [];
      for (let i = 0; i < soretdDateArray.length; i++) {
        let yyd = soretdDateArray.filter(x => x.date == soretdDateArray[i].date);
        if (yyd != null) {
          yyd.forEach(element => {
            finalArrayInternshipAssignment.push({
              date: element.date,
              isGroupBy: true
            });
          });
        }
        let finalDate = response.Results.filter(x => x.ActualEndDate == soretdDateArray[i].date);


        if (finalDate != null || finalDate != '') {
          finalDate.forEach(element => {
            finalArrayInternshipAssignment.push(element);
          });
        }
      }
      this.tableDataSourceFinalAssignment.data = finalArrayInternshipAssignment
      this.tableDataSourceFinalAssignment.sort = this.ListSort;
      this.tableDataSourceFinalAssignment.paginator = this.ListPaginator;
      this.tableDataSourceFinalAssignment.filteredData = this.tableDataSourceFinalAssignment.data;
      //this.SearchBy.TotalResults = response.TotalResults;
      this.SearchBy.TotalResults = this.tableDataSourceFinalAssignment.filteredData.length


    });
  }

  onDeleteSchool(StudentId: string) {
    this.dialogService
      .openConfirmDialog(this.Constants.Messages.DeleteConfirmationMessage)
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          this.studentService.DeleteStudentsById(StudentId)
            .subscribe((schoolResp: any) => {

              this.zone.run(() => {
                if (schoolResp.Success) {
                  this.showActionMessage(
                    this.Constants.Messages.RecordDeletedMessage,
                    this.Constants.Html.SuccessSnackbar
                  );
                }
                this.ngOnInit();
              }, error => {
                console.log('Student deletion errors =>', error);
              });

            });
          this.ngOnInit();
        }
      });
  }

  changeTab(event) {

    this.tabIndex = event.index;
  }

  scrollPoint1(el: HTMLElement) {

    // el.scrollIntoView({behavior: "smooth"});
    el.scrollIntoView();
  }

  onChangeStudent(RequestId: string) {
    this.dialogService
      .openConfirmDialog('Is the safety concern request completed?')
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          this.studentService.SetActionTaken(RequestId)
            .subscribe((schoolResp: any) => {
              this.onGetRequestsByStudentIdRequestType()
              this.zone.run(() => {
                if (schoolResp.Success) {
                  this.showActionMessage(
                    this.Constants.Messages.RecordDeletedMessage,
                    this.Constants.Html.SuccessSnackbar
                  );
                }
                this.ngOnInit();
              }, error => {
                console.log('Student  errors =>', error);
              });

            });
          this.ngOnInit();
        }
      });
  }

  isGroup(index, item): boolean {
    return item.isGroupBy;
  }

  isGroupFA(index, item): boolean {
    return item.isGroupBy;
  }

  isGroupG(index, item): boolean {
    return item.isGroupBy;
  }

  getImage(stringToSplit: any) {

    let x = stringToSplit.split(",");

    return x
  }
  onClickMarkDetails() {
    let viewData = this.StudentId;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.maxWidth = "50%";
    dialogConfig.width = "50%";
    //  dialogConfig.height="765px";
    dialogConfig.height = "auto";
    dialogConfig.data = viewData;//this.staffResulatData;//
    this.dialog.open(MarksDetailsComponent, dialogConfig);
  }
  onClickDropDetails() {
    let viewData = this.data;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.maxWidth = "50%";
    dialogConfig.width = "50%";
    //  dialogConfig.height="765px";
    dialogConfig.height = "auto";
    // dialogConfig.data = viewData;//this.staffResulatData;//
    this.dialog.open(DropDetailsComponent, dialogConfig);
  }


}

interface GroupByDateArray {
  date: string;
  isGroupBy: boolean;
}

interface GroupByDateArraysFA {
  date: string;
  isGroupBy: boolean;
}

interface GroupByDateArraysG {
  date: string;
  isGroupBy: boolean;
}