import { FuseUtils } from '@fuse/utils';

export class DistrictModel {
    // DistrictCode: string; //old 
    DistrictId: string; //new
    StateCode: string; 
    StateId: string; //new
    DivisionId: string;
    DistrictName: string;
    Description: string;
    IsActive: boolean;
    RequestType: any;
    DistrictCode:any;

    constructor(districtItem?: any) {
        districtItem = districtItem || {};

        // this.DistrictCode = districtItem.DistrictCode || ''; //old
        this.DistrictId = districtItem.DistrictId || ''; //new
        this.DistrictCode = districtItem.DistrictId || ''; //new
        this.StateCode = districtItem.StateCode || ''; //old
        this.StateId = districtItem.StateId || ''; //new

        this.DivisionId = districtItem.DivisionId || '';
        this.DistrictName = districtItem.DistrictName || '';
        this.Description = districtItem.Description || '';
        this.IsActive = districtItem.IsActive || true;
        this.RequestType = 0; // New
    }
}
