import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmployerStudentMappingModel } from './employer-student-mapping.model';
import { EmployerStudentMappingService } from './employer-student-mapping.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as guarati } from './i18n/gj';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

@Component({
  selector: 'data-list-view',
  templateUrl: './employer-student-mapping.component.html',
  styleUrls: ['./employer-student-mapping.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class EmployerStudentMappingComponent extends BaseListComponent<EmployerStudentMappingModel> implements OnInit {
  vocationalCoordinatorFilterForm: FormGroup;
  vocationalCoordinatorForm: FormGroup;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private translationLoaderService: FuseTranslationLoaderService,
    public zone: NgZone,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private employerStudentMappingService: EmployerStudentMappingService) {
    super(commonService, router, routeParams, snackBar, zone);
    this.translationLoaderService.loadTranslations(english, guarati);
    this.vocationalCoordinatorFilterForm = this.createEmployerStudentMappingFilterForm();
  }

  ngOnInit(): void {
    this.onLoadEmployerStudentMappingCriteria();
    this.vocationalCoordinatorForm = this.formBuilder.group({ filterText: '' });
    this.vocationalCoordinatorForm.get('filterText').valueChanges.pipe(
      // if character length greater then 2
      filter(res => {
        this.SearchBy.PageIndex = 0;

        if (res.length == 0) {
          this.SearchBy.Name = '';
          this.onLoadEmployerStudentMappingCriteria();
          return false;
        }

        return res.length > 2
      }),

      // Time in milliseconds between key events
      debounceTime(650),

      // If previous query is diffent from current   
      distinctUntilChanged()

      // subscription for response
    ).subscribe((searchText: string) => {
      this.SearchBy.Name = searchText;
      this.onLoadEmployerStudentMappingCriteria();
    });
  }

  ngAfterViewInit() {
    this.tableDataSource.paginator = this.ListPaginator;
  }

  onPageIndexChanged(evt) {
    this.SearchBy.PageIndex = evt.pageIndex;
    this.SearchBy.PageSize = evt.pageSize;

    this.onLoadEmployerStudentMappingCriteria();
  }

  onLoadEmployerStudentMappingCriteria(): any {
    this.SearchBy.IsActive = this.vocationalCoordinatorFilterForm.controls["Status"].value;
    this.IsLoading = true;

    this.employerStudentMappingService.getDropdownforEmployerStudentMappings(this.UserModel).subscribe((results: any) => {
      if (results[1].Success) {
        //  this.stateCodeList = results[1].Results;
        for (const i of results[1].Results) {
          //  this.employerStudentMappingForm.get('StateCode').setValue(i.Id);
          this.SearchBy.StateCode = i.Id;
        }
        debugger;
        this.employerStudentMappingService.GetDirectMapping(this.SearchBy).subscribe(response => {
          debugger;
          // this.displayedColumns = ['StateCode', 'StateName', 'AcademicYearId', 'YearName', 'IsDirectMappingEnabled', 'IsActive', 'Actions'];
          this.displayedColumns = ['StateName', 'YearName', 'IsDirectMappingEnabled', 'IsActive', 'Actions'];

          this.tableDataSource.data = response.Results;
          this.tableDataSource.sort = this.ListSort;
          this.tableDataSource.paginator = this.ListPaginator;
          this.tableDataSource.filteredData = this.tableDataSource.data;

          this.zone.run(() => {
            if (this.tableDataSource.data.length == 0) {
              this.showNoDataFoundSnackBar();
            }
          });
          this.IsLoading = false;
        }, error => {
          console.log(error);
        });

      }
    });
  }

  onDeleteEmployerStudentMapping(configId: string) {
    this.dialogService
      .openConfirmDialog(this.Constants.Messages.DeleteConfirmationMessage)
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          debugger;
          this.employerStudentMappingService.deleteEmployerStudentMappingById(configId)
            .subscribe((response: any) => {

              this.zone.run(() => {
                if (response.Success == true) {
                  this.showActionMessage(
                    this.Constants.Messages.RecordDeletedMessage,
                    this.Constants.Html.SuccessSnackbar
                  );
                } else {
                  this.showActionMessage(
                    response.Result,
                    this.Constants.Html.SuccessSnackbar
                  );
                  this.ngOnInit();

                }
                this.ngOnInit();
              }, error => {
                console.log('Employer Student Mapping deletion errors =>', error);
              });

            });
          this.ngOnInit();
        }
      });
  }

  exportExcel(): void {
    this.IsLoading = true;
    this.SearchBy.PageIndex = 0;
    this.SearchBy.PageSize = 10000;


    this.employerStudentMappingService.GetDirectMapping(this.SearchBy).subscribe(response => {
      response.Results.forEach(
        function (obj) {
          if (obj.hasOwnProperty('IsActive')) {
            obj.IsActive = obj.IsActive ? 'Yes' : 'No';
          }
          if (obj.hasOwnProperty('IsDirectMappingEnabled')) {
            obj.IsDirectMappingEnabled = obj.IsDirectMappingEnabled ? 'Direct Mapped' : 'Indirect Mapped';
          }
          obj.StateName = obj.StateName;
          delete obj.ConfigId;
          delete obj.StateCode;

          delete obj.AcademicYearId;

          delete obj.TotalRows;
        });

      this.exportExcelFromTable(response.Results, "EmployerStudentMappings");

      this.IsLoading = false;
      this.SearchBy.PageIndex = 0;
      this.SearchBy.PageSize = 10;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  //Create EmployerStudentMapping form and returns {FormGroup}
  createEmployerStudentMappingFilterForm(): FormGroup {
    return this.formBuilder.group({
      //AcademicYearId: new FormControl(),
      Status: new FormControl()
    });
  }

  onLoadEmployerStudentMappingByFilters(): any {
    this.SearchBy.PageIndex = 0;
    this.onLoadEmployerStudentMappingCriteria();
  }

  resetFilters(): void {
    this.SearchBy.PageIndex = 0;
    this.vocationalCoordinatorFilterForm.reset();
    //this.schoolFilterForm.get('AcademicYearId').setValue(this.AcademicYearId);

    this.onLoadEmployerStudentMappingCriteria();
  }
}
