import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { ResetVtPasswordService } from './reset-vt-password.service';
import { ResetVtPasswordModel } from './reset-vt-password.model';
import { DropdownModel } from 'app/models/dropdown.model';
import { AccountTransactionService } from 'app/main/account-transactions/account-transaction.service';
import { RouteConstants } from 'app/constants/route.constant';

@Component({
  selector: 'reset-vt-password',
  templateUrl: './reset-vt-password.component.html',
  styleUrls: ['./reset-vt-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ResetVtPasswordComponent extends BaseComponent<ResetVtPasswordModel> implements OnInit {
  resetVtForm: FormGroup;
  resetVtModel: ResetVtPasswordModel;
  roleList: [DropdownModel];
  accountList: [DropdownModel];
  filteredAccountItems: any;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private changeLoginService: ResetVtPasswordService,
    private accountTransactionService: AccountTransactionService,
    private dialogService: DialogService,
    private formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Set the default account Model
    this.resetVtModel = new ResetVtPasswordModel();
  }

  ngOnInit(): void {


    this.accountTransactionService.getAllRoles().subscribe((result) => {
      if (result.Success) {
        debugger;
        if (this.UserModel.RoleCode == 'SUR') {
          this.roleList = result.Results;

          for (let i = 0; i < this.roleList.length; i++) {
            if (this.roleList[i].Id == "259b3087-4e2a-435a-accc-cf8980ffbcca") { this.resetVtForm.get('RoleId').patchValue(this.roleList[i].Id); }
          }

        }
        else {
          debugger;
          // this.roleList = result.Results.filter(r => r.Id != this.Constants.SuperUser);
          this.roleList = result.Results.filter(x => x.Id == '259b3087-4e2a-435a-accc-cf8980ffbcca'); // Filter applied on VT role Id
          for (let i = 0; i < this.roleList.length; i++) {
            if (this.roleList[i].Id == "259b3087-4e2a-435a-accc-cf8980ffbcca") { // checking for VT role only
              // this.resetVtForm.get('RoleId').setValue(this.roleList[i]);

              this.resetVtForm.controls['RoleId'].patchValue(this.roleList[i].Id);
              this.onChangeRole('259b3087-4e2a-435a-accc-cf8980ffbcca');
            }
          }
        }

        this.resetVtForm = this.createResetVtPasswordForm();
      }
    });

    this.resetVtForm = this.createResetVtPasswordForm();
  }

  onChangeRole(roleId: any) {
    this.commonService.GetMasterDataByType({ DataType: 'AccountsByRole', UserId: this.UserModel.LoginId, ParentId: roleId, SelectTitle: 'Account' }).subscribe((response: any) => {
      this.accountList = response.Results;
      this.filteredAccountItems = this.accountList.slice();
    });
  }

  ResetVtPassword() {

    if (!this.resetVtForm.valid) {
      this.validateAllFormFields(this.resetVtForm);
      return;
    }
    debugger;
    this.setValueFromFormGroup(this.resetVtForm, this.resetVtModel);
    debugger;
    this.resetVtModel.UserId = this.UserModel.UserId;
    let xxx = this.accountList.filter(x => x.Id == this.resetVtModel.AccountId);
    this.resetVtModel.LoginId = xxx[0].Name.split('-')[1];
    debugger;
    this.changeLoginService.resetVtPassword(this.resetVtModel)
      .subscribe((accountResp: any) => {

        if (accountResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.ResetVtPasswordMessage,
              this.Constants.Html.SuccessSnackbar
            );
            this.resetVtForm.controls["AccountId"].patchValue(null);
            // this.resetVtForm.controls["RoleId"].patchValue(null);
            this.resetVtForm.controls["AccountId"].clearValidators();
            // this.resetVtForm.controls["RoleId"].clearValidators();
            this.resetVtForm.get("AccountId").updateValueAndValidity();
            // this.resetVtForm.get("RoleId").updateValueAndValidity();
            // this.ngOnInit();
          });
        }
        else {
          var errorMessages = this.getHtmlMessage(accountResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('reset vt password errors =>', error);
      });
  }

  //Create account form and returns {FormGroup}
  createResetVtPasswordForm(): FormGroup {
    return this.formBuilder.group({
      RoleId: new FormControl({ value: this.resetVtModel.RoleId, disabled: false }, Validators.required),
      AccountId: new FormControl({ value: this.resetVtModel.AccountId, disabled: false }, Validators.required),
    });
  }
}
