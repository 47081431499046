<div id="school-sector-job-role" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    SCHOOL SECTOR JOB ROLES
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter (keyup)="applySearchFilter($event.target.value)"
                        placeholder="Search for a school sector job role">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">
                <button mat-raised-button class="fuse-white filter-button"
                    (click)="IsShowFilter=!IsShowFilter"><span>FILTERS</span>
                </button>

                <button mat-raised-button [routerLink]="['./','new']" skipLocationChange="true" replaceUrl="false"
                    class="add-sector-job-role-button fuse-white mt-24 mt-md-0">
                    <span>ADD</span>
                </button>
                <a *ngIf="PageRights.IsExport" class="export-excel" (click)="exportExcel()">
                    <mat-icon> cloud_download</mat-icon>
                </a>
            </div>

            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- Filter Section -->
        <div class="filter-content-card" *ngIf="IsShowFilter">
            <div class="filter-content">
                <form name="schoolSectorJobRoleFilterForm" [formGroup]="schoolSectorJobRoleFilterForm"
                    class="vc-issue-report" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <!-- <mat-form-field fxFlex="30" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Academic Year</mat-label>

                            <mat-select name="academicYearId" formControlName="AcademicYearId" appearance="fill" [disabled]="true">
                                <mat-option [value]="academicYearIdItem.Id"
                                    *ngFor="let academicYearIdItem of academicYearList">
                                    {{ academicYearIdItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field> -->

                        <mat-form-field fxFlex="30" fxFlex.lt-md="10" fxFlex.lt-sm="100">
                            <mat-label>Status</mat-label>
                            <mat-select name="status" formControlName="Status" appearance="fill">
                                <mat-option value="">Status</mat-option>
                                <mat-option value="true">Active</mat-option>
                                <mat-option value="false">Inactive</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="clear-filter pr-1" fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="onLoadSchoolSectorJobRoleByFilters()"><span>SEARCH</span></button>
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="resetFilters()"><span>CLEAR</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- /Filter Section -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table class="sector-job-role-table responsive-table" #table [dataSource]="tableDataSource" matSort fusePerfectScrollbar
                [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- SchoolName Column -->
                <ng-container matColumnDef="SchoolName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>School</mat-header-cell>
                    <mat-cell *matCellDef="let schoolSectorJobRole" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{schoolSectorJobRole.SchoolName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SectorName Column -->
                <ng-container matColumnDef="SectorName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Sector
                    </mat-header-cell>
                    <mat-cell *matCellDef="let schoolSectorJobRole">
                        <p class="text-truncate">{{schoolSectorJobRole.SectorName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- JobRoleName Column -->
                <ng-container matColumnDef="JobRoleName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Job Role</mat-header-cell>
                    <mat-cell *matCellDef="let schoolSectorJobRole" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{schoolSectorJobRole.JobRoleName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- AcademicYear Column -->
                <ng-container matColumnDef="AcademicYear">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>AcademicYear
                    </mat-header-cell>
                    <mat-cell *matCellDef="let schoolSectorJobRole" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{schoolSectorJobRole.AcademicYear}}</p>
                    </mat-cell>
                </ng-container>

                <!-- UDISE Column -->
                <ng-container matColumnDef="UDISE">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>UDISE CODE</mat-header-cell>
                    <mat-cell *matCellDef="let schoolSectorJobRole" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{schoolSectorJobRole.UDISE}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Remarks Column -->
                <ng-container matColumnDef="Remarks">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Sector-Job Role
                    </mat-header-cell>
                    <mat-cell *matCellDef="let schoolSectorJobRole" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{schoolSectorJobRole.Remarks}}</p>
                    </mat-cell>
                </ng-container>

                <!-- IsActive Column -->
                <ng-container matColumnDef="IsActive">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Is Active?</mat-header-cell>
                    <mat-cell *matCellDef="let schoolSectorJobRole" fxHide fxShow.gt-xs>
                        <mat-icon *ngIf="schoolSectorJobRole.IsActive" class="active-icon green-600 s-16">check
                        </mat-icon>
                        <mat-icon *ngIf="!schoolSectorJobRole.IsActive" class="active-icon red-500 s-16">close
                        </mat-icon>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Actions">
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let schoolSectorJobRole" fxShow.gt-xs>
                        <button *ngIf="PageRights.IsView" mat-icon-button
                            [routerLink]="['/school-sector-job-roles', 'view', schoolSectorJobRole.SchoolSectorJobRolesId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">visibility</mat-icon>
                        </button>
                        <button *ngIf="PageRights.IsEdit" mat-icon-button
                            [routerLink]="['/school-sector-job-roles', 'edit', schoolSectorJobRole.SchoolSectorJobRolesId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">edit</mat-icon>
                        </button>
                        <button mat-icon-button color="warn"
                            (click)="onDeleteSchoolSectorJobRole(schoolSectorJobRole.SchoolSectorJobRolesId)"
                            *ngIf="PageRights.IsDelete">
                            <mat-icon>delete_outline</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let schoolSectorJobRole; columns: displayedColumns;" class="sector-job-role"
                    matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>