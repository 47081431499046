import { FuseUtils } from '@fuse/utils';

export class StudentRegistrationModel {
    SrNo: number;
    VCSchoolSectorId: string;
    AcademicYearId: string;
    VCId: string;
    AcademicYearSchoolVTPSectorJobRoleId: string;
    DateOfAllocation: Date;
    VCSchoolStatus: string;
    DateOfRemoval: Date;
    VCSchoolSectorName: string;
    IsActive: boolean;
    RequestType: any;

    constructor() { }
}

export class StudentRegistrationViewModel {
    RowIndex: string;
    StudentId: string;
    YearName: string;
    AcademicYearId: string;
    FullName: string;
    Gender: string;
    FatherName: string;
    MotherName: string;
    MobileNo: string;
    WhatsappNo: string;
    EmailId: string;
    DateOfBirth: string;
    SchoolName: string;
    SchoolId: string;
    Udise: string;
    SchoolManagement: string;
    Schoolcategory: string;
    Class: string;
    Section: string;
    State: string;
    Division: string;
    District: string;
    Block: string;
    Sector: string;
    JobRole: string;
    VocationalTrainer: string;
    VocationalCoordinator: string;
    VTP: string;
    Dateofstudentsenrollement: string;
    RegistrationNumber: string;
    AppliedforInternship: string;
    HiredforInternship: string;
    EntryCreatedby: string;
    CreatedOn: string;
    IsActive: string;

}