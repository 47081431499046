import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import {StudentCertificatesModel } from './student-certificates.model';
import {StudentCertificatesService } from './student-certificates.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { environment } from 'environments/environment';


@Component({
  selector: 'data-list-view',
  templateUrl: './student-certificates.component.html',
  styleUrls: ['./student-certificates.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class StudentCertificatesComponent extends BaseListComponent<StudentCertificatesModel> implements OnInit {
  studentCertificatesFilterForm: FormGroup;
  classList:any;
  sectionList:any
  certificatePdf: any;
  DownloadCertificatePath: string = this.Constants.CommonImageUrl.CertificatePDF;
  ProfileImageBaseURL = this.Constants.CommonImageUrl.Account;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    public zone: NgZone,
    private dialogService: DialogService,
    private formBuilder: FormBuilder,

    private studentCertificatesService:StudentCertificatesService) {
    super(commonService, router, routeParams, snackBar, zone);
    this.studentCertificatesFilterForm = this.createStudentCertificatesFilterForm();
  }

  ngOnInit(): void {
    this.SearchBy. VTGuid= this.UserModel.UserTypeId

    this.studentCertificatesService.GetCertificateForStudentByVTId(this.SearchBy).subscribe(response => {
      this.displayedColumns = ['CertificateTitle', 'OrganizationName','CertificateContent', 'CertificateIssueDate', 'Actions'];

      this.tableDataSource.data = response.Results;
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;

      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
    });
  }



  createStudentCertificatesFilterForm(): FormGroup {
    return this.formBuilder.group({
      SectorId: new FormControl(),
      Class: new FormControl(),
      Section: new FormControl(),
    });
  }

  downloadCertificate(certificateId: string) {
    this.studentCertificatesService.GetCertificatePdf(certificateId).subscribe(response => {
      this.certificatePdf = response.Result;
      this.DownloadCertificatePath + this.certificatePdf;
      let xxx = document.createElement("a");
      xxx.download = this.DownloadCertificatePath + this.certificatePdf;
      xxx.href = this.DownloadCertificatePath + this.certificatePdf;
      xxx.target = "_blank";
      xxx.click();
    });
  }
}
