import { Component, OnInit, NgZone, ViewEncapsulation, ViewChild } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { environment } from 'environments/environment';
import { VTDashboardModel } from './vt-dashboard.model';
import { VTDashboardService } from './vt-dashboard.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { DropdownModel } from 'app/models/dropdown.model';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common'

export interface type {
  name: string;
  series: [{ name: string; value: number }, { name: string; value: number },];
}

export interface typeNew {
  name: string;
  series: [{ name: string; value: number }, { name: string; value: number }, { name: string; value: number }, { name: string; value: number }];
}


@Component({
  selector: 'data-list-view',
  templateUrl: './vt-dashboard.component.html',
  styleUrls: ['./vt-dashboard.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class VTDashboardComponent extends BaseListComponent<VTDashboardModel> implements OnInit {

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  monthsInYear = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  monthsInYearS = [{ Id: 'January', Name: 'January' }, { Id: 'February', Name: 'February' }, { Id: 'March', Name: 'March' }, { Id: 'April', Name: 'April' }, { Id: 'May', Name: 'May' }, { Id: 'June', Name: 'June' }, { Id: 'July', Name: 'July' }, { Id: 'August', Name: 'August' }, { Id: 'September', Name: 'September' }, { Id: 'October', Name: 'October' }, { Id: 'November', Name: 'November' }, { Id: 'December', Name: 'December' },];
  internshipTypeList = [{ Id: 'Online', Name: 'Online' }, { Id: 'Offline', Name: 'Offline' }, { Id: 'Hybrid', Name: 'Hybrid' }];
  workTypeList = [{ Id: 'FullTime', Name: 'Full Time' }, { Id: 'PartTime', Name: 'Part Time' }];
  genderList: [DropdownModel];
  classList: [DropdownModel];
  chackT: any;
  vtDashboardFilterForm: FormGroup;
  sectionList: any
  DownloadCertificatePath: string = this.Constants.CommonImageUrl.CertificatePDF;
  certificatePdf: any;
  ProfileImageBaseURL = this.Constants.CommonImageUrl.Employer;
  ProfileImageBase = this.Constants.CommonImageUrl.Account;
  // multi: any;
  view: any[] = [600, 300];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = '';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = '';
  legendTitle: string = '';
  legendPosition: string = 'below';

  colorScheme = {
    domain: ['#0D6EFD', '#A8CBFE']
  };


  single: any[];
  view1: any[] = [300, 300];
  // multi = [] as any;
  multi: any = [];
  // options

  legendPosition1: string = 'below';
  gradient1: boolean = true;
  showLegend1: boolean = false;
  showLabels: boolean = false;
  isDoughnut: boolean = false;

  colorScheme1 = {
    domain: ['#0D6EFD', '#A8CBFE', '#7D3C98', '#A88BF3']
  };
  VTDashboardPending: any;
  VTDashboardDailyDiaryEntries: any;
  series: any

  VTDashboardInternshipBarChart: any;
  ChartData: any;
  dataSortedMonthWise = [] as any;
  barchartDataActive = [] as any;
  barchartDataPrevActiveInternship = [] as any;
  type = 'Active';
  VTDashboardInternshipConsolidated: any;
  toDate = false;
  minDate: Date;
  maxDate: Date;
  maxDates: Date;
  maxDated: Date;
  PageIndex = 0;
  PageSize = 10
  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    public zone: NgZone,
    private dialogService: DialogService,
    private formBuilder: FormBuilder,
    public datepipe: DatePipe,

    private vTDashboardService: VTDashboardService) {
    super(commonService, router, routeParams, snackBar, zone);
    this.vtDashboardFilterForm = this.createVTDashboardFilterForm();

  }

  ngOnInit(): void {
    // const currentYear = new Date().getFullYear();
    // this.minDate = new Date(currentYear - 1, 11, 31);
    // this.minDate = new Date(this.maxDate.getDate() - 90);
    var startDateN = new Date(); // today
    var startDate = new Date(); // today
    startDate.setMonth(startDateN.getMonth() + 1);
    var endDateY = new Date();

    endDateY.setMonth(startDate.getMonth() - 12);
    var endDateM = new Date();
    endDateM.setMonth(endDateY.getMonth() - 1);
    var nw = this.datepipe.transform(endDateY, 'yyyy-MM-dd');
    this.maxDate = new Date();
    this.maxDates = new Date();
    // var s = this.datepipe.transform(this.minDate, 'yyyy-MM-dd');                                     
    var e = this.datepipe.transform(this.maxDate, 'yyyy-MM-dd');

    // this.vtDashboardFilterForm.get('StartDateTime').setValue(s);
    this.vtDashboardFilterForm.get('StartDateTime').setValue(nw);
    this.vtDashboardFilterForm.get('EndDateTime').setValue(e);

    this.vTDashboardService.getDropdownfor(this.UserModel).subscribe((results) => {
      if (results[0].Success) {
        this.genderList = results[0].Results;
      }
      if (results[1].Success) {
        this.classList = results[1].Results;
      }
    });
    this.GetVTDashboardPending();
    this.GetVTDashboardDailyDiaryEntries();
    this.GetVTDashboardInternshipConsolidated()
    this.GetVTDashboardInternshipBarChart(this.type);
    this.GetVTDashboardStudentsPieChart();



  }



  GetVTDashboardPending() {

    var data = {
      "VTGuid": this.UserModel.UserTypeId,
      "ClassGuid": this.vtDashboardFilterForm.get('Grade').value,
      "SchoolGuid": this.Constants.GlobalSchoolId,
      "Gender": this.vtDashboardFilterForm.get('Gender').value,
      "InternshipTypeName": this.vtDashboardFilterForm.get('InternshipType').value,
      "WorkTypeName": this.vtDashboardFilterForm.get('WorkType').value,
      "StartDateTime": this.datepipe.transform(this.vtDashboardFilterForm.get('StartDateTime').value, 'yyyy-MM-dd'),
      "EndDateTime": this.datepipe.transform(this.vtDashboardFilterForm.get('EndDateTime').value, 'yyyy-MM-dd'),

    }
    this.vTDashboardService.GetVTDashboardPending(data).subscribe((response: any) => {

      this.VTDashboardPending = response.Result


      this.IsLoading = false;
    }, error => {
      console.log(error);
    });
  }

  GetVTDashboardInternshipConsolidated() {
    var data = {
      "VTGuid": this.UserModel.UserTypeId,
      "ClassGuid": this.vtDashboardFilterForm.get('Grade').value,
      "SchoolGuid": this.Constants.GlobalSchoolId,
      "Gender": this.vtDashboardFilterForm.get('Gender').value,
      "InternshipTypeName": this.vtDashboardFilterForm.get('InternshipType').value,
      "WorkTypeName": this.vtDashboardFilterForm.get('WorkType').value,
      "StartDateTime": this.datepipe.transform(this.vtDashboardFilterForm.get('StartDateTime').value, 'yyyy-MM-dd'),
      "EndDateTime": this.datepipe.transform(this.vtDashboardFilterForm.get('EndDateTime').value, 'yyyy-MM-dd'),


    }
    this.vTDashboardService.GetVTDashboardInternshipConsolidated(data).subscribe((response: any) => {
      this.VTDashboardInternshipConsolidated = response.Result
      this.IsLoading = false;
    }, error => {
      console.log(error);
    });
  }

  GetVTDashboardInternshipBarChart(types: any) {
    this.type = types
    this.multi = []
    if (types == 'Active') {
      this.chackT = false
    } else {
      this.chackT = true

    }
    var data = {
      "VTGuid": this.UserModel.UserTypeId,
      "ClassGuid": this.vtDashboardFilterForm.get('Grade').value,
      "SchoolGuid": this.Constants.GlobalSchoolId,
      "Gender": this.vtDashboardFilterForm.get('Gender').value,
      "InternshipTypeName": this.vtDashboardFilterForm.get('InternshipType').value,
      "WorkTypeName": this.vtDashboardFilterForm.get('WorkType').value,

      "StartDateTime": this.datepipe.transform(this.vtDashboardFilterForm.get('StartDateTime').value, 'yyyy-MM-dd'),
      "EndDateTime": this.datepipe.transform(this.vtDashboardFilterForm.get('EndDateTime').value, 'yyyy-MM-dd'),
      "IsPreviousYear": this.chackT
    }
    this.vTDashboardService.GetVTDashboardInternshipBarChart(data).subscribe((response: any) => {

      this.VTDashboardInternshipBarChart = response.Results
      this.ChartData = response.Results
      // Start data prepartion for bar chart
      this.dataSortedMonthWise = [];
      for (let i = 0; i < this.monthsInYear.length; i++) {
        let xxx = this.ChartData.filter(x => x.MonthOfDate == this.monthsInYear[i]);

        if (xxx[0]) {
          this.dataSortedMonthWise.push(xxx[0]);
        }
      }

      if (types == 'Active') {

        this.barchartDataActive = []
        this.multi = [];
        this.ChartData.forEach(item => {
          let preparedDataforBarChart: type[] = [{
            name: item.MonthYear,
            series: [{
              name: 'ActiveInternship',
              value: item.ActiveInternship
            },
            {
              name: 'CompleteInternship',
              value: item.CompleteInternship,
            }
            ],
          }];
          this.barchartDataActive.push(preparedDataforBarChart[0]);
        });
        this.multi = this.barchartDataActive;

      } else {

        this.barchartDataActive = []
        this.multi = [];
        this.ChartData.forEach(item => {
          let preparedDataforBarChart: typeNew[] = [{
            name: item.MonthYear,
            series: [{
              name: 'ActiveInternship',
              value: item.ActiveInternship
            },
            {
              name: 'CompleteInternship',
              value: item.CompleteInternship,
            },
            {
              name: 'PrevActiveInternship',
              value: item.PrevActiveInternship
            },
            {
              name: 'PrevCompleteInternship',
              value: item.PrevCompleteInternship,
            }
            ],
          }];
          this.barchartDataActive.push(preparedDataforBarChart[0]);
        });
        // this.dataSortedMonthWise.forEach(item => {
        //   let preparedDataforBarChart: type[] = [{
        //     name: item.MonthOfDate,
        //     series: [{
        //       name: 'PrevActiveInternship',
        //       value: item.PrevActiveInternship
        //     },
        //     {
        //       name: 'PrevCompleteInternship',
        //       value: item.PrevCompleteInternship,
        //     },

        //     ],
        //   }];
        //   this.barchartDataActive.push(preparedDataforBarChart[0]);

        // });
        this.multi = this.barchartDataActive;

      }

      // End data prepartion for bar chart


      this.IsLoading = false;
    }, error => {
      console.log(error);
    });
  }

  GetVTDashboardStudentsPieChart() {

    var data = {
      "VTGuid": this.UserModel.UserTypeId,

      "ClassGuid": this.vtDashboardFilterForm.get('Grade').value,
      "SchoolGuid": this.Constants.GlobalSchoolId,
      "Gender": this.vtDashboardFilterForm.get('Gender').value,
      "InternshipTypeName": this.vtDashboardFilterForm.get('InternshipType').value,
      "WorkTypeName": this.vtDashboardFilterForm.get('WorkType').value,
      "StartDateTime": this.datepipe.transform(this.vtDashboardFilterForm.get('StartDateTime').value, 'yyyy-MM-dd'),
      "EndDateTime": this.datepipe.transform(this.vtDashboardFilterForm.get('EndDateTime').value, 'yyyy-MM-dd'),

      // "StartDateTime": "2022-11-11",
      // "EndDateTime": "2023-05-05",
      // "VTGuid": "605ddab9-24bf-4c9c-aa7f-d0b8244dc3fd",
      // "ClassGuid": "ef71e220-ed0a-4cec-a5b0-e06325d3dbf4",
      // "SchoolGuid": "38251cb3-25a5-46be-a610-fe1d31d35481",
      // "Gender": null,
      // "InternshipType": null,
      // "WorkType": null,
      // "StartDateTime": "2000-06-30",
      // "EndDateTime": "2025-09-30"
    }
    this.vTDashboardService.GetVTDashboardStudentsPieChart(data).subscribe((response: any) => {
      this.IsLoading = false;
      let data = response.Result;
      this.series = [
        {
          "name": "Onboarded",
          "value": data.OnboardedCount,
          "label": "Onboarded"
        },
        {
          "name": "Not Mapped",
          "value": data.NotMappedCount,
          "label": "Not Mapped"
        },
        {
          "name": "completed",
          "value": data.CompletedCount,
          "label": "completed"
        }
      ];
      // this.single = [];
      // if (data.length > 0) {
      //   data.forEach(element => {
      //     this.VTDashboardDailyDiaryEntries = {
      //       Points: element.StudentPoints,
      //       Attendance: element.Attendance,
      //       Hours: element.HoursSpent
      //     }
      //     console.log(this.VTDashboardDailyDiaryEntries);
      //     if (Number(element.Hours) > 0 || Number(this.VTDashboardDailyDiaryEntries.Hours.split(':')[0]) > 0) {
      //       var hh = element.Hours.split(":")[0];
      //       var mm = element.Hours.split(":")[1];
      //       let time = Number(hh) * 60 + Number(mm);
      //       let spentTime = time / 60;

      //       let TotalHrs: number = 80;
      //       let ActualHrs: any;
      //       if (spentTime > TotalHrs) {
      //         ActualHrs = 0;
      //       } else {
      //         ActualHrs = TotalHrs - spentTime;
      //       }

      //       this.single.push({
      //         name: 'Remaining Hours',
      //         value: ActualHrs
      //       });

      //       this.single.push({
      //         name: 'Actual Hours Spent',
      //         value: spentTime
      //       });
      //       console.log(this.single);
      //     } else {
      //       this.single.push({
      //         name: 'Remaining Hours',
      //         value: 80
      //       });
      //       this.single.push({
      //         name: 'Actual Hours Spent',
      //         value: 0
      //       });
      //     }
      //   });
      // }
    }, error => {
      console.log(error);
    });

  }
  onPageIndexChanged(evt) {

    this.PageIndex = evt.pageIndex;
    this.PageSize = evt.pageSize;

    this.GetVTDashboardDailyDiaryEntries();
  }
  GetVTDashboardDailyDiaryEntries() {
    var data = {
      "VTGuid": this.UserModel.UserTypeId,
      "ClassGuid": this.vtDashboardFilterForm.get('Grade').value,

      "SchoolGuid": this.Constants.GlobalSchoolId,
      "Gender": this.vtDashboardFilterForm.get('Gender').value,
      "InternshipTypeName": this.vtDashboardFilterForm.get('InternshipType').value,
      "WorkTypeName": this.vtDashboardFilterForm.get('WorkType').value,
      "StartDateTime": this.datepipe.transform(this.vtDashboardFilterForm.get('StartDateTime').value, 'yyyy-MM-dd'),
      "EndDateTime": this.datepipe.transform(this.vtDashboardFilterForm.get('EndDateTime').value, 'yyyy-MM-dd'),
      "PageIndex": this.PageIndex,
      "PageSize": this.PageSize,

    }
    this.vTDashboardService.GetVTDashboardDailyDiaryEntries(data).subscribe((response: any) => {
      this.displayedColumns = ['StudentName', 'Status', 'OrganizationName', 'DiaryEntryCount', 'HoursSpent'];

      this.tableDataSource.data = response.Results;
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;




      this.IsLoading = false;
    }, error => {
      console.log(error);
    });
  }


  createVTDashboardFilterForm(): FormGroup {
    return this.formBuilder.group({
      Gender: new FormControl(),
      InternshipType: new FormControl(),
      WorkType: new FormControl(),
      Grade: new FormControl(),
      StartDateTime: new FormControl(),
      EndDateTime: new FormControl(),
    });


  }
  pieChartLabel(series: any[], name: string): string {
    const item = series.filter(data => data.name === name);
    if (item.length > 0) {
      return item[0].label;
    }
    return name;
  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }



  onSelect1(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }


  studentLink(StudentId) {
    if (this.Constants.GlobalSchoolId != null && this.Constants.GlobalAcademicYearId != null) {
      if (this.Constants.GlobalSchoolId != "undefined" && this.Constants.GlobalAcademicYearId != "undefined") {
        this.router.navigate(['/vt-student-details', StudentId]);
      } else {
        this.zone.run(() => {
          this.showActionMessage(
            'Please Select  AcademicYear',
            this.Constants.Html.SuccessSnackbar
          );
        });
      }

    } else {
      this.zone.run(() => {
        this.showActionMessage(
          'Please Select School & AcademicYear',
          this.Constants.Html.SuccessSnackbar
        );
      });

    }
  }

  empLink(EmployerId) {
    this.router.navigate(['/employer-details', EmployerId]);

  }

  addEventS(type: string, event: MatDatepickerInputEvent<Date>) {
    console.log(type);
    console.log(event.value);
    // Write TypeScript code!

    var startDate = new Date(this.vtDashboardFilterForm.get('StartDateTime').value); // today
    console.log(`today startDate: ${startDate}`);
    this.vtDashboardFilterForm.get('EndDateTime').setValue('');
    console.log(startDate)
    var endDateY = new Date();

    endDateY.setMonth(startDate.getMonth() + 12);
    console.log(`endDateY: ${endDateY}`);
    var endDateM = new Date();
    endDateM.setMonth(endDateY.getMonth() - 1);
    console.log(`endDateM: ${endDateM}`);
    var nw = this.datepipe.transform(endDateM, 'yyyy-MM-dd');
    var endDateME = new Date();

    if (this.datepipe.transform(this.vtDashboardFilterForm.get('StartDateTime').value, 'yyyy-MM-dd') < nw) {
      if (nw < this.datepipe.transform(endDateME, 'yyyy-MM-dd')) {
        this.maxDates = new Date(nw);
        this.maxDated = new Date(nw);
        this.maxDated.setDate(28);
        this.vtDashboardFilterForm.get('EndDateTime').setValue(this.maxDated);

      } else {
        this.vtDashboardFilterForm.get('EndDateTime').setValue(this.datepipe.transform(endDateME, 'yyyy-MM-dd'));
        this.maxDates = new Date(endDateME);
        this.maxDated = new Date(endDateME);

      }
    } else {
      this.maxDates = new Date(endDateME);
      this.maxDated = new Date(endDateME);

      this.vtDashboardFilterForm.get('EndDateTime').setValue(this.datepipe.transform(endDateME, 'yyyy-MM-dd'));
    }

    if (this.datepipe.transform(this.vtDashboardFilterForm.get('StartDateTime').value, 'yyyy-MM-dd') < this.datepipe.transform(this.vtDashboardFilterForm.get('EndDateTime').value, 'yyyy-MM-dd')) {
      this.GetVTDashboardPending();
      this.GetVTDashboardDailyDiaryEntries();
      this.GetVTDashboardInternshipConsolidated()
      this.GetVTDashboardInternshipBarChart(this.type);
      this.GetVTDashboardStudentsPieChart();
    }

    this.toDate = true;
  }
  addEventE(type: string, event: MatDatepickerInputEvent<Date>) {
    console.log(type);
    console.log(event);
    if (this.datepipe.transform(this.maxDated, 'yyyy-MM-dd') > this.datepipe.transform(this.vtDashboardFilterForm.get('EndDateTime').value, 'yyyy-MM-dd')) {

      if (this.datepipe.transform(this.vtDashboardFilterForm.get('StartDateTime').value, 'yyyy-MM-dd') < this.datepipe.transform(this.vtDashboardFilterForm.get('EndDateTime').value, 'yyyy-MM-dd')) {
        this.GetVTDashboardPending();
        this.GetVTDashboardDailyDiaryEntries();
        this.GetVTDashboardInternshipConsolidated()
        this.GetVTDashboardInternshipBarChart(this.type);
        this.GetVTDashboardStudentsPieChart();
      }
    } else {
      this.showActionMessage(
        'Duration can not exceed 12 months',
        this.Constants.Html.SuccessSnackbar
      );
      var endDateM = new Date();

      if (this.datepipe.transform(this.maxDated, 'yyyy-MM-dd') < this.datepipe.transform(endDateM, 'yyyy-MM-dd')) {

        this.vtDashboardFilterForm.get('EndDateTime').setValue(this.maxDated);
        if (this.datepipe.transform(this.vtDashboardFilterForm.get('StartDateTime').value, 'yyyy-MM-dd') < this.datepipe.transform(this.vtDashboardFilterForm.get('EndDateTime').value, 'yyyy-MM-dd')) {
          this.GetVTDashboardPending();
          this.GetVTDashboardDailyDiaryEntries();
          this.GetVTDashboardInternshipConsolidated()
          this.GetVTDashboardInternshipBarChart(this.type);
          this.GetVTDashboardStudentsPieChart();
        }

      } else {
        this.vtDashboardFilterForm.get('EndDateTime').setValue(this.datepipe.transform(endDateM, 'yyyy-MM-dd'));
        this.maxDate = new Date(endDateM);
        this.maxDated = new Date(endDateM);
        if (this.datepipe.transform(this.vtDashboardFilterForm.get('StartDateTime').value, 'yyyy-MM-dd') < this.datepipe.transform(this.vtDashboardFilterForm.get('EndDateTime').value, 'yyyy-MM-dd')) {
          this.GetVTDashboardPending();
          this.GetVTDashboardDailyDiaryEntries();
          this.GetVTDashboardInternshipConsolidated()
          this.GetVTDashboardInternshipBarChart(this.type);
          this.GetVTDashboardStudentsPieChart();
        }

      }

    }

  }

  onResize(event) {
    this.view = [event.target.innerWidth / 1.3, 400];
  }

  onResize1(event) {
    this.view1 = [event.target.innerWidth / 1.2, 400];
  }

  resetFilters(): void {
    this.vtDashboardFilterForm.reset();
    var startDateN = new Date(); // today
    var startDate = new Date(); // today
    startDate.setMonth(startDateN.getMonth() + 1);
    var endDateY = new Date();

    endDateY.setMonth(startDate.getMonth() - 12);
    var endDateM = new Date();
    endDateM.setMonth(endDateY.getMonth() - 1);
    var nw = this.datepipe.transform(endDateY, 'yyyy-MM-dd');
    this.maxDate = new Date();
    // var s = this.datepipe.transform(this.minDate, 'yyyy-MM-dd');                                     
    var e = this.datepipe.transform(this.maxDate, 'yyyy-MM-dd');

    // this.vtDashboardFilterForm.get('StartDateTime').setValue(s);
    this.vtDashboardFilterForm.get('StartDateTime').setValue(nw);
    this.vtDashboardFilterForm.get('EndDateTime').setValue(e);
    this.GetVTDashboardPending();
    this.GetVTDashboardDailyDiaryEntries();
    this.GetVTDashboardInternshipConsolidated()
    this.GetVTDashboardInternshipBarChart(this.type);
    this.GetVTDashboardStudentsPieChart();
  }

}


