import { Component, Inject, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { AnyKindOfDictionary } from 'lodash';
import { DropModel } from '../student.model';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-drop-details',
  templateUrl: './drop-details.component.html',
  styleUrls: ['./drop-details.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class DropDetailsComponent extends BaseComponent<DropModel> implements OnInit {

  internshipData: any;
  isLoading: boolean = true;
  selectedIndex: number;
  selectedObjects: any = {};
  errorMessage: any;
  studentSearchData = [] as any;
  studentFeeId: number;
  receiptType: number;
  receiptNo: number;
  studentInternshipDataById = [] as any;
  dropForm: FormGroup;
  dropModel: DropModel;
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DropDetailsComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(commonService, router, routeParams, snackBar);
    // dialogRef.disableClose = true;
    this.dropModel = new DropModel();

    this.dropForm = this.createStudentForm();
  }

  setTab(tabname: string) {
    this.router.navigate([`/${tabname}`]);
  }
  ngOnInit() {
    // console.log(this.data);
    // this.internshipData = this.data;
    // console.log(this.internshipData);

  }

  onClose() {
    this.dialog.closeAll();
  }

  onAdd() {

  }
  onClear() {

  }

  //Create Student form and returns {FormGroup}
  createStudentForm(): FormGroup {
    return this.formBuilder.group({
      DropText: new FormControl({ value: this.dropModel.DropText }, Validators.required),
      DropDate: new FormControl({ value: this.dropModel.DropDate }, Validators.required),

    });
  }

}
