import { FuseUtils } from '@fuse/utils';

export class ResetVtPasswordModel {
    RoleId: string;
    AccountId: string;
    LoginId: string;
    UserId: string;
    constructor(accountItem?: any) {
        accountItem = accountItem || {};

        this.RoleId = accountItem.RoleId || '259b3087-4e2a-435a-accc-cf8980ffbcca';
        this.AccountId = accountItem.AccountId || '';
        this.LoginId=accountItem.LoginId ||'';
        this.UserId=accountItem.UserId ||'';
    }
}
