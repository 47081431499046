import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { ReportService } from 'app/reports/report.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DataUploadModel } from './data-upload.model';
import { DropdownModel } from 'app/models/dropdown.model';
import { FileUploadModel } from 'app/models/file.upload.model';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';

@Component({
  selector: 'data-upload',
  templateUrl: './data-upload.component.html',
  styleUrls: ['./data-upload.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class DataUploadComponent extends BaseListComponent<DataUploadModel> implements OnInit {
  dataUploadForm: FormGroup;
  fileUploadModel: FileUploadModel;
  dataTypetList: DropdownModel[];
  uploadedFileUrl: string;
  isAvailableUploadedExcel: boolean = false;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    public zone: NgZone,
    private dialogService: DialogService,
    public formBuilder: FormBuilder,
    private reportService: ReportService) {
    super(commonService, router, routeParams, snackBar, zone);

    // Set the default school Model
    this.fileUploadModel = new FileUploadModel();
    this.dataTypetList = <DropdownModel[]>[
      { Id: '', Name: 'Select Excel Template' },
      { Id: 'School', Name: '01-School', Template: 'SchoolNoErrorTemplate.xlsx' },
      { Id: 'VTP', Name: '02-Vocational Training Providers', Template: 'VTPTemplate.xlsx' },
      { Id: 'VC', Name: '03-Vocational Coordinators', Template: 'VCTemplate.xlsx' },
      { Id: 'VT', Name: '04-Vocational Trainers', Template: 'VTTemplate.xlsx' },
      { Id: 'SchoolSectorJobRole', Name: '05-School Sector Job Role', Template: 'SchoolSectorJobRoleTemplate.xlsx' },
      { Id: 'SchoolSectorJobRolesVTP', Name: '06-School Sector Job Roles VTP', Template: 'SchoolSectorJobRoleVTPTemplate.xlsx' },
      { Id: 'SchoolVTPVCMapping', Name: '07-School VTP VC Mapping', Template: 'SchoolVTPVCMappingTemplate.xlsx' },
      { Id: 'SchoolVTMapping', Name: '08-School VT Mapping', Template: 'SchoolVTMappingNoErrorTemplate.xlsx' },
      { Id: 'SchoolClassSection', Name: '09-School Class Section', Template: 'SchoolClassSectionNoErrorTemplate.xlsx' },
      { Id: 'HeadMaster', Name: '10-Head Masters', Template: 'HeadMasterNoErrorTemplate.xlsx' },
      { Id: 'Student', Name: '11-Students', Template: 'StudentRegistrationNOErrorTemplate.xlsx' },
      { Id: 'Employer', Name: '12-Employer', Template: 'EmployerTemplateNOERROR.xlsx' },
      { Id: 'SchoolSecJobClassSecVT', Name: '13-SchoolSecJobClassSecVT', Template: 'SchoolSecJobClassSecVTNoErrorTemplate.xlsx' },
      { Id: 'Block', Name: '14-Block', Template: 'BlockTemplate.xlsx' },
      { Id: 'Division', Name: '15-Division', Template: 'DivisionTemplate.xlsx' },
      { Id: 'District', Name: '16-Districts', Template: 'DistrictTemplate.xlsx' },
    ]
  }

  ngOnInit(): void {
    this.dataUploadForm = this.createDataUploadForm();
  }

  uploadedFile(event) {

    if (event.target.files.length > 0) {
      var fileExtn = event.target.files[0].name.split('.').pop().toLowerCase();

      if (this.AllowedExcelExtensions.indexOf(fileExtn) == -1) {
        this.dataUploadForm.get('UploadFile').setValue(null);
        this.dialogService.openShowDialog("Please upload excel file only.");
        return;
      }

      this.getUploadedFileDataXl(event, this.Constants.DocumentType.BulkUploadData).then((response: FileUploadModel) => {
        this.fileUploadModel = response;
      });

      this.isAvailableUploadedExcel = false;
    }
  }

  //Create VTMonthlyAttendance form and returns {FormGroup}
  createDataUploadForm(): FormGroup {
    return this.formBuilder.group({
      ContentType: new FormControl({ value: this.fileUploadModel.ContentType }, Validators.required),
      UploadFile: new FormControl({ value: this.fileUploadModel.UploadFile }, Validators.required)
    });
  }

  uploadExcelData(): void {
    let dataTypeCtrl = this.dataUploadForm.get('ContentType').value;

    if (dataTypeCtrl.Id === undefined) {
      this.dialogService.openShowDialog("Please select data type first !!!");
      return;
    }

    if (this.fileUploadModel.FileName === "") {
      this.dialogService.openShowDialog("Please upload excel template data first !!!");
      return;
    }

    let excelFormData = this.setUploadedFile(this.fileUploadModel);
    excelFormData.UserId = this.UserModel.UserTypeId;
    excelFormData.ContentType = dataTypeCtrl.Id;
    this.reportService.UploadExcelData(excelFormData).subscribe(response => {
      if (response.Success) {
        this.uploadedFileUrl = response.Result;
        this.isAvailableUploadedExcel = true;
        this.dialogService.openShowDialog("Template data executed successfully for " + dataTypeCtrl.Name + '. Please download uploaded excel file and check status');
      }
      else {
        this.dialogService.openShowDialog("Data uploading failed for " + dataTypeCtrl.Name + " " + response.Errors.pop());
      }
    });
  }

  downloadUploadedExcelResults() {
    let pdfReportUrl = this.Constants.Services.BaseUrl + 'Lighthouse/DownloadFile?fileUrl=' + this.uploadedFileUrl;
    window.open(pdfReportUrl, '_blank', '');
  }

  downloadTemplateExcel() {
    let dataTypeCtrl = this.dataUploadForm.get('ContentType').value;

    if (dataTypeCtrl.Template === undefined) {
      this.dialogService.openShowDialog("Please select data type first for downloading templates !!!");
      return;
    }

    let pdfReportUrl = '/assets/templates/bulk_upload/' + dataTypeCtrl.Template;
    window.open(pdfReportUrl, '_blank', '');
  }
}
