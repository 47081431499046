import { Injectable } from '@angular/core';
import { forkJoin, Observable } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';
import { UserModel } from "app/models/user.model";

@Injectable({
  providedIn: 'root'
})
export class ResourceStudyMaterialService {

  constructor(private http: BaseService) { }

  getSchools(): Observable<any> {
    return this.http
      .HttpGet(this.http.Services.Resources.GetAll)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(response => {
          return response.Results;
        })
      );
  }

  GetAllByCriteria(filters: any): Observable<any> {
    
    return this.http
      .HttpPost(this.http.Services.Resources.GetAllByCriteria, filters)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(response => {
          return response.Results;
        })
      );
  }

  // GetNoticesByCriteria(filters: any): Observable<any> {
  //   return this.http
  //     .HttpPost(this.http.Services.Notices.GetNoticesByCriteria, filters)
  //     .pipe(
  //       retry(this.http.Services.RetryServieNo),
  //       catchError(this.http.HandleError),
  //       tap(response => {
  //         return response.Results;
  //       })
  //     );
  // }

  GetResourceStudyMaterialById(videoId: any) {
    let requestParams = {
      DataId: videoId
    };
    return this.http.HttpPost(this.http.Services.Resources.GetResourcesById, requestParams)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(function (response: any) {
          return response.Results;
        })
      );
  }
  
  uploadStudyMaterial(formData: any) {
    return this.http
      .HttpPost(this.http.Services.Resources.UploadStudyMaterial, formData)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(response => {
          return response;
        })
      );
  }

  CreateStudyMaterialResources(formData: any) {
    return this.http
      .HttpPost(this.http.Services.Resources.CreateStudyMaterialResources, formData)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(response => {
          return response;
        })
      );
  }

  DeleteResourceVideosById(videoId: string) {
    let videosParams = {
      DataId: videoId
    };

    return this.http
      .HttpPost(this.http.Services.Resources.DeleteResourcesById, videosParams)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(response => {
          return response;
        })
      );
  }


  getDropdownforResourceVideos(userModel: UserModel): Observable<any[]> {
    let sectorRequest = this.http.GetMasterDataByType({ DataType: 'Sectors', SelectTitle: 'Sector' }, false);
    let employerRequest = this.http.GetMasterDataByType({ DataType: 'Employers', SelectTitle: 'Employer' });
    let districtRequest = this.http.GetMasterDataByType({ DataType: 'Districts', SelectTitle: 'District' });
    let academicYearRequest = this.http.GetMasterDataByType({ DataType: 'AcademicYearsByState', UserId: userModel.UserTypeId, SelectTitle: 'Academic Year' });
    let branchRequest = this.http.GetMasterDataByType({ DataType: 'EmpBranch', ParentId: 'EmployeerId', SelectTitle: 'Branch Name' })
    let interestRequest = this.http.GetMasterDataByType({ DataType: 'Interests', SelectTitle: 'Interests' }, false);
    let statecodeRequest = this.http.GetMasterDataByType({ DataType: 'StatesByUserId', UserId:userModel.UserTypeId, SelectTitle: 'StateCode' });

    // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
    return forkJoin([sectorRequest, employerRequest, districtRequest, academicYearRequest, branchRequest, interestRequest,statecodeRequest]);
  }
}
