import { Component, Inject, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { AnyKindOfDictionary } from 'lodash';
import { MarksModel } from '../student.model';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { StudentService } from '../student.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-marks-details',
  templateUrl: './marks-details.component.html',
  styleUrls: ['./marks-details.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class MarksDetailsComponent extends BaseComponent<MarksModel> implements OnInit {

  internshipData: any;
  isLoading: boolean = true;
  selectedIndex: number;
  selectedObjects: any = {};
  errorMessage: any;
  studentSearchData = [] as any;
  studentFeeId: number;
  receiptType: number;
  receiptNo: number;
  studentInternshipDataById = [] as any;
  markForm: FormGroup;
  marksModel: MarksModel;
  DailyDiaryImageAttachments: any;
  galleryData: any;
  IRRportPhotos: any;
  IRCetificate: any;
  IRBestPicture: any;
  ImageGalleryData = [] as any;
  dateArray = [{}] as any;

  imageAttachments = this.Constants.CommonImageUrl.Attachments;
  FinalInternshipImageBaseURL =this.Constants.CommonImageUrl.FinalInternshipReports;
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private studentService: StudentService,
    public dialogRef: MatDialogRef<MarksDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(commonService, router, routeParams, snackBar);
    // dialogRef.disableClose = true;
    this.marksModel = new MarksModel();

  }

  setTab(tabname: string) {
    this.router.navigate([`/${tabname}`]);
  }
  ngOnInit() {

    
    this.onGetDailyDiaryImageAttachments()
  }

  onClose() {
    this.dialog.closeAll();
  }

  onGetDailyDiaryImageAttachments() {
    let getdata = {
      // "UserTypeId": "5f2aa875-d7e2-4605-a02c-ce34de7ab2fa",
      "UserTypeId": this.data,
      "PageIndex": 0,
      "PageSize": 10000

    }
 


    this.studentService.GetAttachmentsByAccountId(getdata).subscribe(response => {
      this.galleryData = response.Results;

      if (this.galleryData != null && this.galleryData != undefined) {
        //let dateArray = [{ date: '' }];
        for (let i = 0; i < this.galleryData.length; i++) {
          var dateExits = this.dateArray.filter(x => x.date == this.galleryData[i].AttachmentDate.slice(0, 10));
       
          if (dateExits.length == 0) {
            this.dateArray.push({
              date: this.galleryData[i].AttachmentDate.slice(0, 10),
            });
          }

        }
       
        // let ArrayObje = [] as any;
        this.IRRportPhotos = this.galleryData.filter(x => x.SectionType == "IRRportPhotos");
        this.IRRportPhotos.forEach(reportPhotos => {
          reportPhotos.AttachmentDate = reportPhotos.AttachmentDate.slice(0, 10);
          this.ImageGalleryData.push(reportPhotos);
        });
        this.IRCetificate = this.galleryData.filter(x => x.SectionType == "IRCetificate");
        this.IRCetificate.forEach(cetificates => {
          cetificates.AttachmentDate = cetificates.AttachmentDate.slice(0, 10);
          this.ImageGalleryData.push(cetificates);
        });
        this.IRBestPicture = this.galleryData.filter(x => x.SectionType == "IRBestPicture");
        this.IRBestPicture.forEach(bestPicture => {
          bestPicture.AttachmentDate = bestPicture.AttachmentDate.slice(0, 10);
          this.ImageGalleryData.push(bestPicture);
        });
      
    


      }

      // this.DailyDiaryImageAttachments = response.Results;
      // this.DailyDiaryImageAttachments.map(item => {
      //   item['AttachmentPath'] = item.AttachmentPath.split(',');

      // })

      // console.log(this.DailyDiaryImageAttachments)
      this.IsLoading = false;
    }, error => {
      console.log(error);
    });

  }


}
