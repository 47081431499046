import { Component, Inject, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { BaseComponent } from 'app/common/base/base.component';
import { InternshipOpportunity } from 'app/main/internship-opportunity/internship-opportunity.model';
import { CommonService } from 'app/services/common.service';
import { environment } from 'environments/environment';
import { InternshipApprovalService } from '../internship-approval.service';
import { ImageService } from './image.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-review-internship-report',
  templateUrl: './review-internship-report.component.html',
  styleUrls: ['./review-internship-report.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ReviewInternshipReportComponent extends BaseComponent<InternshipOpportunity> implements OnInit {
  passDatatoChild: any[];
  isLoading: boolean = true;
  errorMessage: any;
  studentInternshipDataById = [] as any;
  studentId: any;
  internshipId: any;
  reportData: any;
  certificate: any;
  bestPicture: any;
  reportPhotos = [] as any;
  btnHide: boolean = false;
  url = this.Constants.CommonImageUrl.FinalInternshipReports;
  isReviewReport: boolean = false;
  isIRReviewed: any;
  isCompleted: any;
  data1: string;
  bestp: string;
  urlB: string;
  fileUrl: any;
  imgData: any;
  urlC: string;
  constructor(
    public service: InternshipApprovalService,
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    public dialog: MatDialog,
    private imageService: ImageService,
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<ReviewInternshipReportComponent>,private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(commonService, router, routeParams, snackBar);
    
    // dialogRef.disableClose = true;
  }
  ngOnInit() {
    console.log(this.data);
    this.studentId = this.data.StudentId;
    this.internshipId = this.data.InternshipId;
    this.isCompleted = this.data.IsCompleted;
    this.isIRReviewed = this.data.IsIRReviewed;
    this.studentInternshipDataById = this.data[0];
    // {"DataId":"132e50f9-6531-4083-a2e4-a44cc32ba2cc","DataId1":"6c21172e-db6d-46e6-b6c8-e60205aae53e"}
    // this.service.getFinalInternshipReport("132e50f9-6531-4083-a2e4-a44cc32ba2cc", "6c21172e-db6d-46e6-b6c8-e60205aae53e").subscribe(response => {
    this.service.getFinalInternshipReport(this.studentId, this.internshipId).subscribe(response => {
      this.reportData = response.Result;
      if (this.reportData != null || this.reportData != undefined) {
        (this.reportData.ReportDetail == '' || this.reportData.ReportDetail == null) ? this.reportData.ReportDetail = 'Report details are not Available' : this.reportData.ReportDetail;

        const fileData = this.reportData.ReportDetail;
        const blob = new Blob([fileData], { type: 'application/octet-stream' });
    
        this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
      }
      if (this.reportData.listAttachments.length > 0) {
        this.btnHide = true;
      } {
        this.btnHide = false;
      }
      if (response.Result.listAttachments.length > 0) {

        response.Result.listAttachments.forEach(element => {
          if (element.SectionType == 'IRCetificate') {
            // this.certificate = this.Constants.CommonImageUrl.FinalInternshipReports + element.AttachmentPath;
            this.certificate = element.AttachmentPath;
            this.urlC=this.url + this.certificate;

          } else if (element.SectionType == 'IRBestPicture') {
            
            this.bestPicture = element.AttachmentPath;
            this.urlB=this.url + this.bestPicture;

          } else {
            this.reportPhotos.push(this.Constants.CommonImageUrl.FinalInternshipReports + element.AttachmentPath);
            console.log('Report Photos', this.reportPhotos);
          }
        });
      } else {
        this.errorMessage = 'Final Internship Report data not available for Review...!!';
        this.zone.run(() => {
          this.showActionMessage(
            'Final Internship Report data not available for Review...!!',
            this.Constants.Html.ErrorSnackbar,
          );
        });
      }

      if ((this.bestPicture != null && this.bestPicture != undefined) && (this.reportPhotos.length >= 1)) {
        this.isReviewReport = true;

      } else {
        this.isReviewReport = false;
        this.errorMessage = 'Final Internship Report data not available for Review...!!';
      }
  
      // if (this.certificate != null && this.certificate != undefined) {
      //   this.certificate = this.Constants.CommonImageUrl.FinalInternshipReports + this.certificate.AttachmentPath;
      //   console.log('Internship Certificate', this.certificate);
      //   this.bestPicture = this.Constants.CommonImageUrl.FinalInternshipReports + this.bestPicture.AttachmentPath;
      //   console.log('Best Picture', this.bestPicture);
      // }
      // console.log('Report Photos', this.reportPhotos);
    });

    //text download from file

   
  }

  
  downloadImage(url) {
    this.service.GetBase64(url).subscribe(response => {
      let xx='data:image/jpeg;base64,'+response.Result;
      this.imgData = xx;
      this.imageService.download(this.imgData);
    });
  }


  onClose() {
    this.dialog.closeAll();
  }

  onSubmit(dropOutForm) {
    this.dialogRef.close({ ReportViewed: 'true', IsReviewReport: this.isReviewReport });
  }


  reviewInternshipReportForm: FormGroup = new FormGroup({
    // IsActive: new FormControl("", Validators.required),
  });

}
