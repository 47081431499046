<div id="school-sector-job-role" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/school-sector-job-roles'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        {{ schoolSectorJobRoleModel.Name }}
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        New School Sector Job Role
                    </div>
                    <div class="subtitle secondary-text">
                        <span>School Sector Job Role Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <!-- <button mat-raised-button class="add-sector-job-role-button" [disabled]="schoolSectorJobRoleForm.invalid"
                *ngIf="PageRights.ActionType ==='new'" (click)="saveOrUpdateSchoolSectorJobRoleDetails()">
                <span>ADD</span>
            </button> -->
            <button mat-raised-button class="add-sector-job-role-button" *ngIf="PageRights.ActionType ==='new'"
                (click)="saveOrUpdateSchoolSectorJobRoleDetails()">
                <span>ADD</span>
            </button>
            <button mat-raised-button class="save-sector-job-role-button" *ngIf="PageRights.ActionType ==='edit'"
                (click)="saveOrUpdateSchoolSectorJobRoleDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="schoolSectorJobRoleForm" [formGroup]="schoolSectorJobRoleForm"
                    class="sector-job-role w-100-p" fxLayout="column" fxFlex>

                    <!-- <mat-tab label="Basic Info"> -->
                    <div class="tab-content p-24" fusePerfectScrollbar>
                        <div class="required-mark">
                            <span><span class="note">Note: </span>All fields marked with an asterisk (<span
                                    class="asterisk-color">*</span>) are required.</span>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="0 1 calc(35%)" fxFlex.lt-md="0 1 calc(70%)"
                                fxFlex.lt-sm="100%">
                                <mat-label>Select State Code</mat-label>

                                <mat-select formControlName="StateCode" required appearance="outline"
                                    [disabled]="PageRights.IsReadOnly">
                                    <mat-option [value]="codeItem.Id" *ngFor="let codeItem of statecodeList">
                                        {{ codeItem.Name }} ({{ codeItem.Id }})
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="schoolSectorJobRoleForm.controls.StateCode.invalid">
                                    {{getErrorMessage(schoolSectorJobRoleForm, 'StateCode')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="0 1 calc(35%)" fxFlex.lt-md="0 1 calc(70%)"
                                fxFlex.lt-sm="100%">
                                <mat-label>Academic Year</mat-label>

                                <mat-select name="academicYearId" formControlName="AcademicYearId" required
                                    [disabled]="PageRights.IsReadOnly">
                                    <mat-option [value]="academicYearItem.Id"
                                        *ngFor="let academicYearItem of academicyearList">
                                        {{ academicYearItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="schoolSectorJobRoleForm.controls.AcademicYearId.invalid">
                                    {{getErrorMessage(schoolSectorJobRoleForm, 'AcademicYearId')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="0 1 calc(35%)" fxFlex.lt-md="0 1 calc(70%)"
                                fxFlex.lt-sm="100%">
                                <mat-label>Select School</mat-label>

                                <mat-select formControlName="SchoolId" required appearance="outline"
                                    [disabled]="PageRights.IsReadOnly" #SelectSchool>
                                    <mat-select-filter class="select-filter" *ngIf="SelectSchool.focused"
                                        [array]="schoolsList" (filteredReturn)="this.filteredSchoolItems = $event"
                                        [displayMember]="'Name'"></mat-select-filter>
                                    <mat-option [value]="schoolItem.Id" *ngFor="let schoolItem of filteredSchoolItems">
                                        {{ schoolItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="schoolSectorJobRoleForm.controls.SchoolId.invalid">
                                    {{getErrorMessage(schoolSectorJobRoleForm, 'SchoolId')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="0 1 calc(35%)" fxFlex.lt-md="0 1 calc(70%)"
                                fxFlex.lt-sm="100%">
                                <mat-label>Select Sector</mat-label>

                                <mat-select formControlName="SectorId" required appearance="outline"
                                    [disabled]="PageRights.IsReadOnly"
                                    (selectionChange)="onChangeSector($event.value);status()">
                                    <mat-option [value]="sectorItem.Id" *ngFor="let sectorItem of sectorList">
                                        {{ sectorItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="schoolSectorJobRoleForm.controls.SectorId.invalid">
                                    {{getErrorMessage(schoolSectorJobRoleForm, 'SectorId')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="0 1 calc(35%)" fxFlex.lt-md="0 1 calc(70%)"
                                fxFlex.lt-sm="100%">
                                <mat-label>Select Job Role</mat-label>

                                <mat-select formControlName="JobRoleId" required appearance="outline"
                                    [disabled]="PageRights.IsReadOnly"
                                    (selectionChange)="onChangeJobRole($event.value)">
                                    <mat-option [value]="jobRoleItem.Id" *ngFor="let jobRoleItem of jobRoleList">
                                        {{ jobRoleItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="schoolSectorJobRoleForm.controls.JobRoleId.invalid">
                                    {{getErrorMessage(schoolSectorJobRoleForm, 'JobRoleId')}}
                                </mat-error>
                            </mat-form-field>
                        </div>



                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="0 1 calc(35%)" fxFlex.lt-md="0 1 calc(70%)"
                                fxFlex.lt-sm="100%">
                                <mat-label>Sector Job Role Name</mat-label>
                                <input matInput name="Remarks" formControlName="Remarks">
                                <mat-error *ngIf="schoolSectorJobRoleForm.controls.Remarks.invalid">
                                    {{getErrorMessage(schoolSectorJobRoleForm, 'Remarks')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start start">
                            <span class="checkbox-space">Is Active?</span>
                            <mat-checkbox matInput formControlName="IsActive" [disabled]="PageRights.IsReadOnly">
                            </mat-checkbox>
                        </div>
                    </div>
                    <!-- </mat-tab>
                    </mat-tab-group> -->
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>