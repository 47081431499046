import { FuseUtils } from '@fuse/utils';

export class VCAssessmentModel {
    SrNo: number;
    VCSchoolSectorId: string;
    AcademicYearId: string;
    VCId: string;
    AcademicYearSchoolVTPSectorJobRoleId: string;
    DateOfAllocation: Date;
    VCSchoolStatus: string;
    DateOfRemoval: Date;
    VCSchoolSectorName: string;
    IsActive: boolean;
    RequestType: any;
 
    constructor() { }
}

export class VCAssessmentViewModel {
    RowIndex: string;
  
    VCFullName: string;
    VCMobile: string;
    VCMobile1: string;
    VCEmailId: string;
    VTPName: string;
    TotalVTs: string;
    TotalSchools: string;
    TotalStudents: string;
    AcademicYearId: string;
    CountOfBoys: string;
    CountOfGirls: string;
    BoysWithInternship: string;
    GirlsWithInternship: string;
    BoysWithInternshipCompleted: string;
    GirlsWithInternshipCompleted: string;
    ActiveEmployerWithInternship: string;
    ActiveEmployerWithCompletedInternship: string;
    SafetyConcernsRaised: string;
    SafetyConcernsResolved: string;
    InternshipApprovalRequest: string;
    PendingProfileEditRequest: string;
    FinalReportPendingAck: string;
    VCTrade: string;

}