import { Component, OnInit, NgZone, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InternshipAssignmentsRequestModel } from './internship-assignments.model';
import { InternshipAssignmentsRequestService } from './internship-assignments.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as guarati } from './i18n/gj';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { DropdownModel } from 'app/models/dropdown.model';
import { environment } from 'environments/environment';
import { UserModel } from "app/models/user.model";
import { ReviewInternshipAssignmentsComponent } from '../internship-assignments/review-internship-assignments/review-internship-assignments.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditInternshipAssignmentsComponent } from '../internship-assignments/edit-internship-assignments/edit-internship-assignments.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'data-list-view',
  templateUrl: './internship-assignments.component.html',
  styleUrls: ['./internship-assignments.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class InternshipAssignmentsRequestComponent extends BaseListComponent<InternshipAssignmentsRequestModel> implements OnInit {
  studentSearchForm: FormGroup;

  currentAcademicYearId: string;
  academicYearList: [DropdownModel];
  sectorList: [DropdownModel];
  vtpList: [DropdownModel];
  filteredVtpSectorItems: any;
  schoolList: [DropdownModel];
  filteredSchoolItems: any;
  classList: [DropdownModel];
  sectionList: [DropdownModel];
  studentList: [DropdownModel];
  genderList: [DropdownModel];
  // schoolVTPSectorSearchForm: FormGroup;
  studentFilterForm: FormGroup;

  tabIndex = 0;
  isChecked = false
  ApiBaseUrlImage = this.Constants.CommonImageUrl.Account;
  selectedStudentIds: string[] = [];

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private translationLoaderService: FuseTranslationLoaderService,
    public zone: NgZone,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    public datepipe: DatePipe,
    private internshipAssignmentsRequestService: InternshipAssignmentsRequestService, private dialog: MatDialog,) {
    super(commonService, router, routeParams, snackBar, zone);
    this.translationLoaderService.loadTranslations(english, guarati);
    this.studentSearchForm = this.formBuilder.group({ filterText: '' });
    this.studentFilterForm = this.createSchoolVtpSectorFilterForm();

  }

  ngOnInit(): void {
    this.internshipAssignmentsRequestService.getDropdownforSchool(this.UserModel).subscribe(results => {


      if (results[6].Success) {
        this.genderList = results[6].Results;
      }



    });
    this.onChangeClass()
    this.onLoadSchoolsByCriteria();

    this.SearchBy.PageIndex = 0; // delete after script changed
    this.SearchBy.PageSize = 10; // delete after script changed

    this.studentSearchForm = this.formBuilder.group({ filterText: '' });

    // Load initial schools data
    this.onLoadSchoolsByCriteria();

    this.studentSearchForm.get('filterText').valueChanges.pipe(
      // if character length greater then 2
      filter(res => {
        this.SearchBy.PageIndex = 0;

        if (res.length == 0) {
          this.SearchBy.Name = '';
          this.onLoadSchoolsByCriteria();
          return false;
        }

        return res.length > 2
      }),

      // Time in milliseconds between key events
      debounceTime(650),

      // If previous query is diffent from current   
      distinctUntilChanged()

      // subscription for response
    ).subscribe((searchText: string) => {
      this.SearchBy.Name = searchText;
      this.onLoadSchoolsByCriteria();
    });
  }
  refreshPage() {
    if (this.tabIndex === 0) {
      this.onLoadSchoolsByCriteria();

    } else {
      this.onLoadSchoolsByCriteriaCom()
    }
  }

  changeTab(event) {
    console.log(event)

    this.tabIndex = event == undefined ? event : event;
    if (this.tabIndex === 0) {
      this.studentSearchForm = this.formBuilder.group({ filterText: '' });
      this.onLoadSchoolsByCriteria();
      this.selectedStudentIds = []
    } else {
      this.studentSearchForm = this.formBuilder.group({ filterText: '' });

      this.onLoadSchoolsByCriteriaCom()
    }
  }
  ngAfterViewInit() {
    this.tableDataSource.paginator = this.ListPaginator;
  }

  onPageIndexChanged(evt) {
    this.SearchBy.PageIndex = evt.pageIndex;
    this.SearchBy.PageSize = evt.pageSize;

    if (this.tabIndex === 0) {
      this.onLoadSchoolsByCriteria();

    } else {
      this.onLoadSchoolsByCriteriaCom()
    }
  }

  onLoadSchoolsByCriteria(): any {
    this.IsLoading = true;
    this.SearchBy.UserTypeId = this.UserModel.UserTypeId;
    this.SearchBy.SchoolGuid = (this.Constants.GlobalSchoolId == 'undefined' || this.Constants.GlobalSchoolId == 'null') ? null : this.Constants.GlobalSchoolId;
    this.SearchBy.AcademicYearGuid = (this.Constants.GlobalAcademicYearId == 'undefined' || this.Constants.GlobalAcademicYearId == 'null') ? null : this.Constants.GlobalAcademicYearId;
    this.SearchBy.VTGuid = this.UserModel.UserTypeId;
    //  this.SearchBy.Name= this.studentFilterForm.controls['Name'].value;
    this.SearchBy.ClassName = this.studentFilterForm.controls['Class'].value;
    this.SearchBy.SectionName = this.studentFilterForm.controls['Section'].value;
    this.SearchBy.Gender = this.studentFilterForm.controls['Gender'].value;
    this.SearchBy.IsMapped = false;
    //  var data={
    //   "UserTypeId": this.UserModel.UserTypeId,
    //   "SchoolGuid": (this.Constants.GlobalSchoolId == 'undefined' || this.Constants.GlobalSchoolId == 'null') ? null : this.Constants.GlobalSchoolId,
    //   "AcademicYearGuid": (this.Constants.GlobalAcademicYearId == 'undefined' || this.Constants.GlobalAcademicYearId == 'null') ? null : this.Constants.GlobalAcademicYearId,
    //   "VTGuid": this.UserModel.UserTypeId,
    //   "Name": null,
    //   "ClassName": null,
    //   "SectionName": null,
    //   "Gender": null,
    //   "PageIndex": 0,
    //   "PageSize": 10000,
    //   "IsMapped": 0
    // }

    this.internshipAssignmentsRequestService.GetStudentInternshipsForVTByCriteria(this.SearchBy).subscribe(response => {
      this.displayedColumns = ['COMPLETE', 'STUDENT', 'GENDER', 'MOBILE', 'ClASS', 'SECTION', 'JOBROLE', 'SECTOR'];

      this.tableDataSource.data = response.Results;
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;
      this.SearchBy.TotalResults = response.Results[0].TotalRows;

      setTimeout(() => {
        this.ListPaginator.pageIndex = this.SearchBy.PageIndex;
        this.ListPaginator.length = this.SearchBy.TotalResults;
      });

      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  onLoadSchoolsByCriteriaCom(): any {
    this.IsLoading = true;
    this.SearchBy.UserTypeId = this.UserModel.UserTypeId;
    this.SearchBy.SchoolGuid = (this.Constants.GlobalSchoolId == 'undefined' || this.Constants.GlobalSchoolId == 'null') ? null : this.Constants.GlobalSchoolId;
    this.SearchBy.AcademicYearGuid = (this.Constants.GlobalAcademicYearId == 'undefined' || this.Constants.GlobalAcademicYearId == 'null') ? null : this.Constants.GlobalAcademicYearId;
    this.SearchBy.VTGuid = this.UserModel.UserTypeId;
    //  this.SearchBy.Name= this.studentFilterForm.controls['Name'].value;
    this.SearchBy.ClassName = this.studentFilterForm.controls['Class'].value;
    this.SearchBy.SectionName = this.studentFilterForm.controls['Section'].value;
    this.SearchBy.Gender = this.studentFilterForm.controls['Gender'].value;
    this.SearchBy.IsMapped = true;
    this.internshipAssignmentsRequestService.GetStudentInternshipsForVTByCriteria(this.SearchBy).subscribe(response => {
      this.displayedColumns = ['STUDENT', 'GENDER', 'MOBILE', 'ClASS', 'SECTION', 'INTERNSHIPTITLE','EMPLOYERNAME', 'DATE', 'Actions'];

      this.tableDataSource.data = response.Results;
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;
      this.SearchBy.TotalResults = response.Results[0].TotalRows;

      setTimeout(() => {
        this.ListPaginator.pageIndex = this.SearchBy.PageIndex;
        this.ListPaginator.length = this.SearchBy.TotalResults;
      });

      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  onChangeStudent(StudentId: string, e: any) {

    var xxx = StudentId;
    const isChecked = e.checked;

    if (isChecked) {
      // Add the selected student ID to the array
      this.selectedStudentIds.push(xxx);
    } else {
      // Remove the deselected student ID from the array
      const index = this.selectedStudentIds.indexOf(xxx);
      if (index !== -1) {
        this.selectedStudentIds.splice(index, 1);
      }
    }
    console.log(this.selectedStudentIds)
  }



  onLoadSchoolVTPSectorsByFilters(): any {
    this.SearchBy.PageIndex = 0;
    this.onLoadSchoolsByCriteria();
  }

  resetFilters(): void {
    this.studentFilterForm.reset();

    if (this.tabIndex === 0) {
      this.studentSearchForm = this.formBuilder.group({ filterText: '' });

      this.onLoadSchoolsByCriteria();

    } else {
      this.studentSearchForm = this.formBuilder.group({ filterText: '' });

      this.onLoadSchoolsByCriteriaCom()
    }
  }
  //Create SchoolVtpSectorFilter form and returns {FormGroup}
  createSchoolVtpSectorFilterForm(): FormGroup {
    return this.formBuilder.group({
      Name: new FormControl(),
      Gender: new FormControl(),
      Class: new FormControl(),
      Section: new FormControl(),
    });
  }



  onClickMap() {
    let studentData: any = {};
    // studentData.StudentId = studentId;
    // studentData.InternshipId = internshipId;
    // studentData.IsCompleted = isCompleted;
    // studentData.IsIRReviewed = isIRReviewed;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.maxWidth = "80%";
    dialogConfig.width = "80%";
    //  dialogConfig.height="765px";
    dialogConfig.height = "auto";
    dialogConfig.data = studentData;
    this.dialog.open(ReviewInternshipAssignmentsComponent, dialogConfig).afterClosed()
      .subscribe(AcknowledgeData => {
        console.log(AcknowledgeData);
        console.log(AcknowledgeData.data.value);

        if (AcknowledgeData) {
          var addData = {
            "InternshipId": "00000000-0000-0000-0000-000000000000",
            "EmployerId": AcknowledgeData.data.value.EmployerId,
            "BranchId": "00000000-0000-0000-0000-000000000000",
            "SectorId": AcknowledgeData.data.value.Sector,
            "BranchName":  "string",
            "InterestId": "00000000-0000-0000-0000-000000000000",
            "UserTypeId": this.UserModel.UserTypeId,
            "CommencementDate": this.datepipe.transform(AcknowledgeData.CommencementDate, 'yyyy-MM-dd'),
            "StudentIds": this.selectedStudentIds,
            "AuthUserId": this.UserModel.UserName,
            "InternshipType":AcknowledgeData.data.value.InternshipType,
            "InternshipStartTime": AcknowledgeData.data.value.StartDate,
            "InternshipEndTime": AcknowledgeData.data.value.EndDate,
          }
          console.log(addData)
          this.internshipAssignmentsRequestService.AssignInternshipToStudents(addData).subscribe((response) => {
            if (response.Success) {
              this.onLoadSchoolsByCriteria()
              this.zone.run(() => {
                this.showActionMessage(
                  response.Result,
                  this.Constants.Html.SuccessSnackbar
                );
              });
            } else {
              this.zone.run(() => {
                this.showActionMessage(
                  response.Result,
                  this.Constants.Html.ErrorSnackbar,
                );

              });
            }
          });

        }
        else {
          this.zone.run(() => {
            //@ts-ignore
            this.snackBar.open("No data found..", "Dismiss", {
              duration: 5000,
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
              panelClass: ["error-snackbar"]
            });
          });
          this.showActionMessage(
            "No changes made, request cancelled...!!",
            this.Constants.Html.ErrorSnackbar,
          );
          return false;
        }
      });
  }

  onClickMapEdit(data) {
console.log(data)
this.selectedStudentIds.push(data.StudentId);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.maxWidth = "80%";
    dialogConfig.width = "80%";
    //  dialogConfig.height="765px";
    dialogConfig.height = "auto";
    dialogConfig.data = data;
    this.dialog.open(EditInternshipAssignmentsComponent, dialogConfig).afterClosed()
      .subscribe(AcknowledgeData => {
        console.log(AcknowledgeData)
        if (AcknowledgeData) {
          var editData = {
            // "InternshipId": data.InternshipId,
            // "CommencementDate": this.datepipe.transform(AcknowledgeData.CommencementDate, 'yyyy-MM-dd'),
            // "StudentId": data.StudentId,
            // "AuthUserId": this.UserModel.UserName
            "InternshipId": "00000000-0000-0000-0000-000000000000",
            "EmployerId": AcknowledgeData.Interns.EmployerId,
            "BranchId": "00000000-0000-0000-0000-000000000000",
            "SectorId": AcknowledgeData.Interns.Sector,
            "BranchName":  "string",
            "InterestId": "00000000-0000-0000-0000-000000000000",
            "UserTypeId": this.UserModel.UserTypeId,
            "CommencementDate": this.datepipe.transform(AcknowledgeData.CommencementDate, 'yyyy-MM-dd'),
            "StudentIds": this.selectedStudentIds,
            "AuthUserId": this.UserModel.UserName,
            "InternshipType":AcknowledgeData.data.value.InternshipType,
            "InternshipStartTime": AcknowledgeData.data.value.StartDate,
            "InternshipEndTime":AcknowledgeData.data.value.EndDate,

          }
          console.log(editData)
          this.selectedStudentIds=[]
          this.internshipAssignmentsRequestService.AssignInternshipToStudents(editData).subscribe((response) => {
            if (response.Success) {
              this.onLoadSchoolsByCriteriaCom();
              this.zone.run(() => {
                this.showActionMessage(
                  response.Result,
                  this.Constants.Html.SuccessSnackbar
                );
              });
            } else {
              this.zone.run(() => {
                this.showActionMessage(
                  response.Result,
                  this.Constants.Html.ErrorSnackbar,
                );

              });
            }
          });

        }
        else {
          this.zone.run(() => {
            //@ts-ignore
            this.snackBar.open("No data found..", "Dismiss", {
              duration: 5000,
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
              panelClass: ["error-snackbar"]
            });
          });
          this.showActionMessage(
            "No changes made, request cancelled...!!",
            this.Constants.Html.ErrorSnackbar,
          );
          return false;
        }
      });
  }

  onClickMapCancel(studentId, internshipId) {
    var cancelledData = {
      "DataId": studentId,
      "DataId1": internshipId,
      "DataId2": "true",
      "DataId3": "Internship Cancellation due to unknown reasons"
    }

    this.internshipAssignmentsRequestService.SetCancellationStatus(cancelledData).subscribe((response) => {
      if (response.Success) {
        this.zone.run(() => {
          //@ts-ignore
          this.snackBar.open("Internship Cancellation due to unknown reasons", "Dismiss", {
            duration: 5000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
            panelClass: ["error-snackbar"]
          });
        });
      } else {
        this.zone.run(() => {
          this.showActionMessage(
            response.Result,
            this.Constants.Html.ErrorSnackbar,
          );

        });
      }
    });
  }

  onChangeClass() {
    this.commonService.GetMasterDataByType({ DataType: 'ClassesByVT', ParentId: this.Constants.GlobalSchoolId, UserId: this.UserModel.UserTypeId, SelectTitle: 'Class' }).subscribe((response: any) => {
      this.classList = response.Results;
    });
  }
  onChangeSection(ClassId: any) {
    this.commonService.GetMasterDataByType({ DataType: 'Sections', ParentId: ClassId, SelectTitle: 'Sections' }).subscribe((response: any) => {
      this.sectionList = response.Results;
    });
  }
}
