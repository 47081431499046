import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VTAssessmentModel, VTAssessmentViewModel } from './vt-assessment.model';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { ReportService } from '../report.service';
import { DropdownModel } from 'app/models/dropdown.model';
import { FormBuilder, FormControl, FormGroup,Validators } from '@angular/forms';
import { environment } from 'environments/environment';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'data-list-view',
  templateUrl: './vt-assessment.component.html',
  styleUrls: ['./vt-assessment.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class VTAssessmentComponent extends BaseListComponent<VTAssessmentModel> implements OnInit {
  VTAssessmentViewModel = new VTAssessmentViewModel();

  vtReportForm: FormGroup;
  academicyearList: [DropdownModel];
  divisionList: [DropdownModel];
  districtList: DropdownModel[];
  vtpList: [DropdownModel];
  stateList: [DropdownModel];
  blockList: [DropdownModel];
  vocationalCoordinatorList: [DropdownModel];
  minDate: Date;
  maxDate: Date;
  currentAcademicYearId: string;
  isShowFilterContainer = false;
  isShowFilter = false

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    public zone: NgZone,
    public formBuilder: FormBuilder,
    public datepipe: DatePipe,

    private reportService: ReportService) {
    super(commonService, router, routeParams, snackBar, zone);
  }

  ngOnInit(): void {
    this.maxDate = new Date();
    this.PageRights.IsExport = true;
    this.reportService.GetDropdownForReports(this.UserModel).subscribe(results => {
      if (results[0].Success) {
        this.academicyearList = results[0].Results;
      }

      if (results[1].Success) {
        this.divisionList = results[1].Results;
      }



      if (results[3].Success) {
        this.vtpList = results[3].Results;
      }



      if (results[7].Success) {
        this.stateList = results[7].Results;
      }


      // this.GetVTAssessmentReport();



    });

    this.vtReportForm = this.createVTReportForm();
  }

  // onChangeState(Statecode: any) {
  //   this.commonService.GetMasterDataByType({ DataType: 'Divisions', ParentId: Statecode, SelectTitle: 'Division' }).subscribe((response: any) => {
  //     this.divisionList = response.Results;
  //   });
  // }

  onChangeDivision(divisionId: any) {

    this.commonService.GetMasterDataByType({ DataType: 'Districts', ParentId: divisionId, SelectTitle: 'District' }).subscribe((response: any) => {
      this.districtList = response.Results;
    });
  }
  onChangeDistrict(districtId: any) {
    this.commonService.GetMasterDataByType({ DataType: 'Blocks', UserId: this.UserModel.DefaultStateId, ParentId: districtId, SelectTitle: 'Block' }).subscribe((response: any) => {
      this.blockList = response.Results;
    });
  }

  onChangeVTP(vtpId:any) {

    this.commonService.GetMasterDataByType({ DataType: 'VocationalCoordinatorsByUserId', ParentId: vtpId, UserId: this.UserModel.LoginId, SelectTitle: 'Vocational Coordinator' }).subscribe((response) => {
      
        this.vocationalCoordinatorList = response.Results;
    
    });

  }
  resetReportFilters(): void {
    this.vtReportForm.reset();
    this.isShowFilter=false;

    // this.vtReportForm.get('AcademicYearId').setValue(this.currentAcademicYearId);
    // this.districtList = <DropdownModel[]>[];

    // if (this.UserModel.RoleCode === 'DisRP') {
    //   this.vtReportForm.controls["DivisionId"].setValue(this.UserModel.DivisionId);
    //   this.vtReportForm.controls["DistrictId"].setValue(this.UserModel.DistrictId);
    // }

    // this.GetVTAssessmentReport();
    this.tableDataSource.data=[];
    this.onChangeAct(null) ;
  }





  //Create VTSchoolSector form and returns {FormGroup}
  createVTReportForm(): FormGroup {
    return this.formBuilder.group({
      AcademicYearId: new FormControl(),
      VTPGuid: new FormControl(),
      VCGuid: new FormControl(),
      DivisionCode: new FormControl(),
      DistrictCode: new FormControl(),
      BlockId: new FormControl(),
      StartDateTime: new FormControl(),
      EndDateTime: new FormControl(),

    });
  }
  onPageIndexChanged(evt) {

    this.SearchBy.PageIndex = evt.pageIndex;
    this.SearchBy.PageSize = evt.pageSize;

    this.GetVTAssessmentReport();
  }

  onChangeAct(c:any){

    if(c == null){
      this.isShowFilter=false;
      this.vtReportForm.get('StartDateTime').setValue('');
      this.vtReportForm.get('EndDateTime').setValue('');
    }else{
    

      this.academicyearList.map(item => {
        if (item.Id ===c) {

          var acd=item.Name;
          

          var xxx=acd.split("-");
          let a :any=xxx[0];
          let b:any=xxx[1];
          this.minDate= new Date(a, 0, 1); 
          this.maxDate = new Date(b, 11, 31); 
          var e = this.datepipe.transform(this.minDate, 'yyyy-MM-dd');
    
          this.datepipe.transform(this.vtReportForm.get('StartDateTime').setValue(this.getDateValue(e)));
          this.datepipe.transform(this.vtReportForm.get('EndDateTime').setValue(this.getDateValue(e)));
        }
       })
      this.isShowFilter=true;

    }
  }                                        
  onSelectEvent($event:any, acd:any){
   
    if(acd === 'Select Academic Year'){
      this.isShowFilter=false;
      this.vtReportForm.get('StartDateTime').setValue('');
      this.vtReportForm.get('EndDateTime').setValue('');

    }else{
      this.isShowFilter=true;
      var xxx=acd.split("-")
      var a=xxx[0];
      var b=xxx[1];
      this.minDate= new Date(a, 0, 1); 
      this.maxDate = new Date(b, 11, 31); 
      var e = this.datepipe.transform(this.minDate, 'yyyy-MM-dd');

      this.datepipe.transform(this.vtReportForm.get('StartDateTime').setValue(this.getDateValue(e)));
      this.datepipe.transform(this.vtReportForm.get('EndDateTime').setValue(this.getDateValue(e)));
    }
  

  }
  GetVTAssessmentReport(): void {
 if(this.datepipe.transform(this.vtReportForm.get('StartDateTime').value, 'yyyy-MM-dd') == this.datepipe.transform(this.vtReportForm.get('EndDateTime').value, 'yyyy-MM-dd') || this.datepipe.transform(this.vtReportForm.get('StartDateTime').value, 'yyyy-MM-dd') < this.datepipe.transform(this.vtReportForm.get('EndDateTime').value, 'yyyy-MM-dd')){
   

    var data = {
      // "UserGuid": "fac71e7e-6661-4691-9e20-842a92948958",
      // "UserRoleCode": "PMU",
      "UserGuid": this.UserModel.UserTypeId,
      "UserRoleCode": this.UserModel.RoleCode,
      "AcademicYearGuid": this.vtReportForm.get('AcademicYearId').value,
      "VTPGuid": this.vtReportForm.get('VTPGuid').value,
      "VCGuid": this.vtReportForm.get('VCGuid').value,
      "DistrictCode": this.vtReportForm.get('DistrictCode').value,
      "BlockId": this.vtReportForm.get('BlockId').value,
      "StartDateTime": this.vtReportForm.get('StartDateTime').value,
      "EndDateTime": this.vtReportForm.get('EndDateTime').value
    }



    this.reportService.GetVTAssessmentReport(data).subscribe(response => {
      this.displayedColumns = ['SrNo', 'VTFullName', 'VTMobile', 'VTMobile1', 'VTEmailId','SchoolName','ClassName','SectionName','VocationalSubject', 'SchoolPhone','SchoolEmailId', 'SchoolStateCode', 'SchoolBlockName', 'SchoolVillage','DivisionName','DistrictName', 'HeadMasterName', 'YearName','SchoolStudentCount', 'CountOfBoys', 'CountOfGirls', 'BoysWithInternship', 'GirlsWithInternship', 'BoysWithInternshipCompleted','GirlsWithInternshipCompleted', 'TotalEmployers', 'TotalEmployersOnboarded', 'ActiveEmployerWithInternship', 'ActiveEmployerWithCompletedInternship',
        'SafetyConcernsRaised', 'SafetyConcernsResolved', 'InternshipApprovalRequest', 'PendingProfileEditRequest', 'FinalReportPendingAck'];


      this.tableDataSource.data = response.Results;

      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;
      this.SearchBy.TotalResults = response.TotalResults;

      setTimeout(() => {
        this.ListPaginator.pageIndex = this.SearchBy.PageIndex;
        this.ListPaginator.length = this.SearchBy.TotalResults;
      });

      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
    });
  }else{
      this.showActionMessage(
        'End Date is less than start date ',
        this.Constants.Html.SuccessSnackbar
      );
     }
  }

  exportExcel(): void {
    this.IsLoading = true;
    this.SearchBy.PageIndex = 1;
    this.SearchBy.PageSize = 10000;

    this.reportService.GetVTAssessmentReport(this.SearchBy).subscribe(response => {
      response.Results.forEach(
        function (obj) {
          if (obj.hasOwnProperty('IsActive')) {
            obj.IsActive = obj.IsActive ? 'Yes' : 'No';
          }
          if (obj.hasOwnProperty('IsImplemented')) {
            obj.IsImplemented = obj.IsImplemented ? 'Yes' : 'No';
          }
          delete obj.SchoolId;
          delete obj.TotalRows;
        });

      this.exportExcelFromTable(response.Results, "StudentReport");

      this.IsLoading = false;
      this.SearchBy.PageIndex = 0;
      this.SearchBy.PageSize = 10;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

}
