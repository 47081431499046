<!-- HEADER -->
<div class="header1" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="space-between center" style="background: #3f51b5;height: 64px; height: 61px;
  padding: 0px 24px;
">
    <!-- APP TITLE -->
    <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center"
        [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
        <div class="h2 mt-8 mb-12  heading-text1" style="color:#fff;">
            Safety Concern Remark
        </div>
    </div>
    <!-- / APP TITLE -->
    <!-- ADD BUTTON -->

   
    <!-- / ADD BUTTON -->
</div>
<!-- / HEADER -->
<div id="staff" class="page-layout carded fullwidth inner-scroll" style="height: 500px;">
    <!-- TOP BACKGROUND -->
    <!-- <div class="top-bg accent"></div> -->
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">
        <!-- CONTENT CARD -->
        <div class="content-card mat-elevation-z2 p-5 mb-12">
            <div class="content">
                 <form name="reviewInternshipReportForm" [formGroup]="reviewInternshipReportForm" 
              class="internship w-100-p p-24 mr-24" fxLayout="column"
                    fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex fusePerfectScrollbar>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                    fxLayoutGap.lt-md="0px">
                   
                    <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                        <mat-label>Remark</mat-label>
                        <input matInput required formControlName="Remark" placeholder="Remark" 
                            matTooltipPosition="above">
                        <mat-error *ngIf="reviewInternshipReportForm.controls.Remark.invalid">
                            {{getErrorMessage(reviewInternshipReportForm, 'Remark')}}
                        </mat-error>
                    </mat-form-field>
                    </div>
                 

                
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start">
                        <button mat-raised-button class="add-internship-button btn-warning mls"  
                            (click)="onSubmit(reviewInternshipReportForm)" fxFlex="0 1 calc(47%)"
                            fxFlex.lt-md="0 1 calc(47%)" fxFlex.lt-sm="50%">
                            <span>Save </span>
                        </button>

                        <button mat-raised-button class="add-internship-button btn-cancel" (click)="onClose()"
                            fxFlex="0 1 calc(47%)" fxFlex.lt-md="0 1 calc(47%)" fxFlex.lt-sm="50%" sty>
                            <span>Cancel </span>
                        </button>
                    </div>
                </form>
            </div>
            <!-- / CONTENT CARD -->
        </div>
        <!-- / CENTER -->
    </div>