import { FuseUtils } from '@fuse/utils';

export class AcademicYearModel {
    AcademicYearId: string;
    PhaseId: string;
    YearName: string;
    Description: string;
    StartMonth:Date;
    EndMonth:Date;
    IsCurrentAcademicYear: boolean;
    IsActive: boolean;
    RequestType: any;
    StateCode:any;

    constructor(academicYearItem?: any) {
        academicYearItem = academicYearItem || {};

        this.AcademicYearId = academicYearItem.AcademicYearId || FuseUtils.NewGuid();
        this.PhaseId = academicYearItem.PhaseId || '';
        this.YearName = academicYearItem.YearName || '';
        this.Description = academicYearItem.Description || '';
        this.StartMonth = academicYearItem.StartMonth || '';
        this.EndMonth = academicYearItem.EndMonth || '';
        this.IsCurrentAcademicYear = academicYearItem.IsCurrentAcademicYear || false;
        this.IsActive = academicYearItem.IsActive || true;
        this.StateCode = academicYearItem.StateCode || '';
        this.RequestType = 0; // New
    }
}
