import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';
import { UserModel } from "app/models/user.model";


@Injectable()
export class InternshipAssignmentsRequestService {
    constructor(private http: BaseService) { }


    GetStudentInternshipsForVTByCriteria(filters:any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.StudentInternships.GetStudentInternshipsForVTByCriteria, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetInternshipByActiveCriteria(filters: any): Observable<any> {
        return this.http
          .HttpPost(this.http.Services.InternshipOpportunity.GetInternshipByActiveCriteria, filters)
          .pipe(
            retry(this.http.Services.RetryServieNo),
            catchError(this.http.HandleError),
            tap(response => {
              return response.Results;
            })
          );
    }
    AssignInternshipToStudents(data: any): Observable<any> {
      return this.http
        .HttpPost(this.http.Services.StudentInternships.AssignInternshipToStudents, data)
        .pipe(
          retry(this.http.Services.RetryServieNo),
          catchError(this.http.HandleError),
          tap(response => {
            return response.Results;
          })
        );
  }
  UpdateCommencementDate(data: any): Observable<any> {
      return this.http
        .HttpPost(this.http.Services.StudentInternships.UpdateCommencementDate, data)
        .pipe(
          retry(this.http.Services.RetryServieNo),
          catchError(this.http.HandleError),
          tap(response => {
            return response.Results;
          })
        );
  }

  SetCancellationStatus(data: any): Observable<any> {
    return this.http
      .HttpPost(this.http.Services.StudentInternships.SetCancellationStatus, data)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(response => {
          return response.Results;
        })
      );
}


    getDropdownforSchool(userModel: UserModel): Observable<any[]> {
        let schoolCategoryRequest = this.http.GetMasterDataByType({ DataType: 'SchoolCategories', SelectTitle: 'School Category' });
        let academicYearRequest = this.http.GetMasterDataByType({ DataType: 'AcademicYears', SelectTitle: 'Academic Year' });
        let schoolTypeRequest = this.http.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'SchoolType', SelectTitle: 'School Type' });
        let stateRequest = this.http.GetMasterDataByType({DataType: 'StatesByUserId', UserId:userModel.UserTypeId, SelectTitle: 'StateCode' });
        let schoolManagementRequest = this.http.GetMasterDataByType({ DataType: 'BasicList', ParentId: 'SchoolManagement', SelectTitle: 'School Management' });
        let statecodeRequest = this.http.GetMasterDataByType({ DataType: 'StatesByUserId', UserId:userModel.UserTypeId, SelectTitle: 'StateCode' });
        let genderRequest = this.http.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'StudentGender', SelectTitle: 'Gender' });
        let schoolRequest = this.http.GetMasterDataByType({ DataType: 'Schools',UserId:userModel.UserTypeId, ParentId: null, SelectTitle: 'School' });
        let socialCategoryRequest = this.http.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'SocialCategory', SelectTitle: 'Social Category' });
        let religionRequest = this.http.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'Religion', SelectTitle: 'Religion' });
        let vtpRequest = this.http.GetMasterDataByType({ DataType: 'VocationalTrainers', SelectTitle: 'VT' });
       let sectorRequest = this.http.GetMasterDataByType({ DataType: 'Sectors', SelectTitle: 'Sector' }, false);

        

        
        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        return forkJoin([schoolCategoryRequest, academicYearRequest, schoolTypeRequest, stateRequest, schoolManagementRequest,statecodeRequest,genderRequest,schoolRequest,socialCategoryRequest,religionRequest,vtpRequest,sectorRequest]);
    }
}
