import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { RouteConstants } from 'app/constants/route.constant'
import { SchoolClassSectionsService } from '../school-class-sections.service';
import { SchoolClassSectionsModel } from '../school-class-sections.model';
import { DropdownModel } from 'app/models/dropdown.model';

@Component({
  selector: 'create-school-class-sections',
  templateUrl: './create-school-class-sections.component.html',
  styleUrls: ['./create-school-class-sections.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CreateSchoolClassSectionsComponent extends BaseComponent<SchoolClassSectionsModel> implements OnInit {
  schoolClassSectionForm: FormGroup;
  schoolClassSectionsModel: SchoolClassSectionsModel;
  schoolsList: [DropdownModel];
  classList: [DropdownModel];
  sectionList: [DropdownModel];
  statecodeList: [DropdownModel];
  academicyearList: [DropdownModel];
  filteredSchoolItems: any;




  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private route: ActivatedRoute,
    private schoolClassSectionsService: SchoolClassSectionsService,
    private dialogService: DialogService,
    private formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Set the default SchoolClassSections Model
    this.schoolClassSectionsModel = new SchoolClassSectionsModel();
  }

  ngOnInit(): void {
    this.schoolClassSectionsService.getDropdownforSchoolClassSections(this.UserModel).subscribe((results) => {
      console.log(results[1].Results)
      if (results[0].Success) {
        this.schoolsList = results[0].Results;
        this.filteredSchoolItems = this.schoolsList.slice();
      }
      if (results[1].Success) {
        this.classList = results[1].Results;
      }
      if (results[2].Success) {
        this.sectionList = results[2].Results;
      }
      if (results[3].Success) {
        this.statecodeList = results[3].Results;
        for (const i of results[3].Results) {
          this.schoolClassSectionForm.get('StateCode').setValue(i.Id);
        }
      }
      if (results[4].Success) {
        this.academicyearList = results[4].Results;
      }


      this.route.paramMap.subscribe(params => {
        if (params.keys.length > 0) {
          this.PageRights.ActionType = params.get('actionType');

          if (this.PageRights.ActionType == this.Constants.Actions.New) {
            this.schoolClassSectionsModel = new SchoolClassSectionsModel();

          } else {
            var schoolClassSectionId: string = params.get('SchoolClassSectionId')

            this.schoolClassSectionsService.getSchoolClassSectionsById(schoolClassSectionId)
              .subscribe((response: any) => {
                this.schoolClassSectionsModel = response.Result;

                if (this.PageRights.ActionType == this.Constants.Actions.Edit)
                  this.schoolClassSectionsModel.RequestType = this.Constants.PageType.Edit;
                else if (this.PageRights.ActionType == this.Constants.Actions.View) {
                  this.schoolClassSectionsModel.RequestType = this.Constants.PageType.View;
                  this.PageRights.IsReadOnly = true;
                }
                this.schoolClassSectionForm = this.createSchoolClassSectionForm();
              });
          }
        }
      });
    });

    this.schoolClassSectionForm = this.createSchoolClassSectionForm();
  }



  saveOrUpdateSchoolClassSectionDetails() {
    if (!this.schoolClassSectionForm.valid) {
      this.validateAllFormFields(this.schoolClassSectionForm);
      return;
    }

    this.setValueFromFormGroup(this.schoolClassSectionForm, this.schoolClassSectionsModel);

    this.schoolClassSectionsService.createOrUpdateSchoolClassSections(this.schoolClassSectionsModel)
      .subscribe((schoolClassSectionsResp: any) => {

        if (schoolClassSectionsResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );

            this.router.navigate([RouteConstants.SchoolClassSections.List]);
          });
        }
        else {
          var errorMessages = this.getHtmlMessage(schoolClassSectionsResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('HeadMaster deletion errors =>', error);
      });
  }

  //Create schoolClassSections form and returns {FormGroup}
  createSchoolClassSectionForm(): FormGroup {
    return this.formBuilder.group({
      HMId: new FormControl(this.schoolClassSectionsModel.HMId),
      SchoolId: new FormControl({ value: this.schoolClassSectionsModel.SchoolId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      ClassId: new FormControl({ value: this.schoolClassSectionsModel.ClassId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      SectionId: new FormControl({ value: this.schoolClassSectionsModel.SectionId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      Remarks: new FormControl({ value: this.schoolClassSectionsModel.Remarks, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(50), Validators.pattern(this.Constants.Regex.CharWithTitleCaseSpaceAndSpecialChars)]),
      IsActive: new FormControl({ value: this.schoolClassSectionsModel.IsActive, disabled: this.PageRights.IsReadOnly }),
      StateCode: new FormControl({ value: this.schoolClassSectionsModel.StateCode, disabled: true }, Validators.required),
      AcademicYearId: new FormControl({ value: this.schoolClassSectionsModel.AcademicYearId, disabled: this.PageRights.IsReadOnly }, Validators.required),

    });
  }
}
