<div id="vocational-trainer" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    {{ 'VT.VT' | translate}}
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <form [formGroup]="vocationalTrainerForm">
                    <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon>search</mat-icon>
                        <input name="filterText" formControlName="filterText" placeholder="Search for VT">
                    </div>
                </form>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">
                <button mat-raised-button class="fuse-white filter-button"
                    (click)="IsShowFilter=!IsShowFilter"><span>FILTERS</span>
                </button>

                <button *ngIf="PageRights.IsAdd" mat-raised-button [routerLink]="['./','new']" skipLocationChange="true"
                    replaceUrl="false" class="add-vocational-trainer-button fuse-white mt-24 mt-md-0">
                    <span>{{'VT.ADD_NEW_VT' | translate}}</span>
                </button>

                <!-- <a *ngIf="PageRights.IsExport" class="export-excel" (click)="exporter.exportTable('xlsx', {fileName:'VocationalTrainers', sheet: 'VocationalTrainers', Props: {Author: 'Lighthouse'}})">
                <mat-icon> cloud_download</mat-icon>
            </a> -->
                <a *ngIf="PageRights.IsExport" class="export-excel" (click)="exportExcel()">
                    <mat-icon> cloud_download</mat-icon>
                </a>
            </div>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- Filter Section -->
        <div class="filter-content-card" *ngIf="IsShowFilter">
            <div class="filter-content">
                <form name="vocationalTrainerFilterForm" [formGroup]="vocationalTrainerFilterForm"
                    class="vc-issue-report" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <!-- <mat-form-field fxFlex="30" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Academic Year</mat-label>

                            <mat-select name="academicYearId" formControlName="AcademicYearId" appearance="fill" [disabled]="true">
                                <mat-option [value]="academicYearIdItem.Id"
                                    *ngFor="let academicYearIdItem of academicYearList">
                                    {{ academicYearIdItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field> -->

                        <mat-form-field fxFlex="30" fxFlex.lt-md="10" fxFlex.lt-sm="100">
                            <mat-label>Status</mat-label>
                            <mat-select name="status" formControlName="Status" appearance="fill">
                                <mat-option value="">Status</mat-option>
                                <mat-option value="true">Active</mat-option>
                                <mat-option value="false">Inactive</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="clear-filter pr-1" fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="onLoadVocationalTrainerByFilters()"><span>SEARCH</span></button>
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="resetFilters()"><span>CLEAR</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- /Filter Section -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table matTableExporter class="vocational-trainer-table responsive-table" #table
                [dataSource]="tableDataSource" #exporter="matTableExporter" [hiddenColumns]="[7]" matSort
                fusePerfectScrollbar [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- VTPName Column -->
                <ng-container matColumnDef="VTPName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VTP Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vocationalTrainer">
                        <p class="text-truncate">{{vocationalTrainer.VTPName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VCName Column -->
                <ng-container matColumnDef="VCName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VC Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vocationalTrainer">
                        <p class="text-truncate">{{vocationalTrainer.VCName}}</p>
                    </mat-cell>
                </ng-container>


                <!-- FullName Column -->
                <ng-container matColumnDef="FullName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VT Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vocationalTrainer">
                        <p class="text-truncate">{{vocationalTrainer.FullName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Mobile Column -->
                <ng-container matColumnDef="Mobile">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Mobile No.
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vocationalTrainer" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vocationalTrainer.Mobile}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Email Column -->
                <ng-container matColumnDef="Email">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Email Id</mat-header-cell>
                    <mat-cell *matCellDef="let vocationalTrainer" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vocationalTrainer.Email}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Gender Column -->
                <ng-container matColumnDef="Gender">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Gender
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vocationalTrainer" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vocationalTrainer.Gender}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SocialCategory Column -->
                <!-- <ng-container matColumnDef="SocialCategory">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Social Category
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vocationalTrainer" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vocationalTrainer.SocialCategory}}</p>
                    </mat-cell>
                </ng-container> -->

                <!-- VCMoblie Column -->
                <ng-container matColumnDef="VCMoblie">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VC's Mobile No.
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vocationalTrainer">
                        <p class="text-truncate">{{vocationalTrainer.VCMoblie}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VCEmail Column -->
                <ng-container matColumnDef="VCEmail">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VC's Email Id
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vocationalTrainer">
                        <p class="text-truncate">{{vocationalTrainer.VCEmail}}</p>
                    </mat-cell>
                </ng-container>

                <!-- IsActive Column -->
                <ng-container matColumnDef="IsActive">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Is Active?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vocationalTrainer" fxHide fxShow.gt-xs>
                        <mat-icon *ngIf="vocationalTrainer.IsActive" class="active-icon green-600 s-16">check</mat-icon>
                        <mat-icon *ngIf="!vocationalTrainer.IsActive" class="active-icon red-500 s-16">close</mat-icon>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Actions" stickyEnd>
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let vocationalTrainer" fxShow.gt-xs>
                        <button *ngIf="PageRights.IsView" mat-icon-button
                            [routerLink]="['/vocational-trainers', 'view', vocationalTrainer.VTId,vocationalTrainer.VCId,vocationalTrainer.VTPId,vocationalTrainer.AcademicYearId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">visibility</mat-icon>
                        </button>
                        <button *ngIf="PageRights.IsEdit && vocationalTrainer.IsActive == true" mat-icon-button
                            [routerLink]="['/vocational-trainers', 'edit', vocationalTrainer.VTId,vocationalTrainer.VCId,vocationalTrainer.VTPId,vocationalTrainer.AcademicYearId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">edit</mat-icon>
                        </button>
                        <button *ngIf="PageRights.IsDelete && vocationalTrainer.IsActive == true" mat-icon-button color="warn"
                            (click)="onDeleteVocationalTrainer(vocationalTrainer.VTId,vocationalTrainer.VCId,vocationalTrainer.VTPId,vocationalTrainer.AcademicYearId)">
                            <mat-icon>delete_outline</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let vocationalTrainer; columns: displayedColumns;" class="vocational-trainer"
                    matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>