<div id="sector-job-role" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row wrap" fxLayoutAlign="space-between center">


            <!-- APP TITLE -->
            <div fxLayout="row" class="screen-heading" fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)"
                fxFlex.lt-sm="100%">
                <div fxLayout="row" fxLayoutAlign="start center">

                   

                    <div fxLayout="column" fxLayoutAlign="start start"
                        [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                        <div class="h1" *ngIf="PageRights.PageType ==='Edit'">
                            <!-- {{ schoolModel.SchoolName }} -->
                            <h1> Edit Student Certificate</h1>
                        </div>
                        <div class="h1" *ngIf="PageRights.PageType ==='New'">
                            <h1>Add New Student Certificate</h1>
                        </div>
                        <div class="subtitle secondary-text">
                            <h1><span>Add New Student Certificate</span></h1>
                        </div>
                    </div>
                </div>
            </div>

            <!-- / APP TITLE -->

            <div fxLayout="row" class="add-edit-button" fxLayoutAlign="flex-start" fxFlex="0 1 calc(50%)"
                fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="100%">

                <button mat-raised-button class="add-school-button btn-outline-dark" [routerLink]="'/student-certificates'"
                    fxFlex="50%" fxFlex.lt-md="50%" fxFlex.lt-sm="50%">
                    <span>Manage Students Certificate </span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="ms-2" width="20" height="20" viewBox="0 0 24 24"
                        stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"></path>
                        <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3"></path>
                        <line x1="16" y1="5" x2="19" y2="8"></line>
                    </svg>
                </button>

                <button mat-raised-button class="add-school-button btn-warning mls" [disabled]="studentCertificatesForm.invalid"
                    *ngIf="PageRights.ActionType ==='new'" (click)="saveOrUpdateStudentCertificates()" 
                    fxFlex="50%" fxFlex.lt-md="50%" fxFlex.lt-sm="50%">
                    <span>Add Student Certificate</span>
                </button>

                <button mat-raised-button class="save-school-button" *ngIf="PageRights.ActionType ==='edit'"
                    (click)="saveOrUpdateStudentCertificates()">
                    <span>SAVE</span>
                </button>

            </div>

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="studentCertificatesForm" [formGroup]="studentCertificatesForm"
                    class="sector-job-role w-100-p" fxLayout="column" fxFlex>
                    <!-- <mat-tab label="Basic Info"> -->
                    <div class="tab-content p-24" fusePerfectScrollbar>



                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Certificate Type</mat-label>

                                <mat-select formControlName="CertificateType" required appearance="outline"
                                    [disabled]="PageRights.IsReadOnly">
                                    <mat-option [value]="jobRoleItem.Id" *ngFor="let jobRoleItem of certificateList">
                                        {{ jobRoleItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="studentCertificatesForm.controls.CertificateType.invalid">{{getErrorMessage(studentCertificatesForm,
                                    'CertificateType')}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Issue Date</mat-label>

                                <input matInput [matDatepicker]="picker1" name="qpCode"
                                    formControlName="CertificateIssueDate" required placeholder="Ex. BSC/Q0201">
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                                <mat-error
                                    *ngIf="studentCertificatesForm.controls.CertificateIssueDate.invalid">{{getErrorMessage(studentCertificatesForm,
                                    'CertificateIssueDate')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>
                                    Certificate Title</mat-label>

                                <input matInput name="CertificateTitle" formControlName="CertificateTitle" required
                                    placeholder="">
                                <mat-error
                                    *ngIf="studentCertificatesForm.controls.CertificateTitle.invalid">{{getErrorMessage(studentCertificatesForm,
                                    'CertificateTitle')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>First Name</mat-label>


                                <mat-select formControlName="StudentId" appearance="outline"
                                    [disabled]="PageRights.IsReadOnly" required #SelectSchool
                                     (selectionChange)="onChangeEmp($event.value)">
                                    <mat-select-filter class="select-filter" *ngIf="SelectSchool.focused"
                                        [array]="schoolsList" (filteredReturn)="this.filteredSchoolItems = $event"
                                        [displayMember]="'Name'"></mat-select-filter>
                                    <mat-option [value]="schoolItem.Id" *ngFor="let schoolItem of filteredSchoolItems">
                                        {{ schoolItem.Name }}
                                    </mat-option>
                                </mat-select>
                               
                                <mat-error
                                    *ngIf="studentCertificatesForm.controls.StudentId.invalid">{{getErrorMessage(studentCertificatesForm,
                                    'StudentId')}}
                                </mat-error>
                            </mat-form-field>

                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>Company Name</mat-label>


                                <mat-select formControlName="EmployerId" appearance="outline"
                                    [disabled]="PageRights.IsReadOnly" required #SelectSchool1
                                    (selectionChange)="onChangeOrganizationName($event.value)" >
                                    <mat-select-filter class="select-filter" *ngIf="SelectSchool1.focused"
                                        [array]="empList" (filteredReturn)="this.filteredEmpItems = $event"
                                        [displayMember]="'Name'"></mat-select-filter>
                                    <mat-option [value]="empItem.Id" *ngFor="let empItem of filteredEmpItems">
                                        {{ empItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="studentCertificatesForm.controls.EmployerId.invalid">{{getErrorMessage(studentCertificatesForm,
                                    'EmployerId')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>
                                    Content</mat-label>

                                <input matInput name="CertificateContent" formControlName="CertificateContent" required
                                    placeholder="">
                                <mat-error
                                    *ngIf="studentCertificatesForm.controls.CertificateContent.invalid">{{getErrorMessage(studentCertificatesForm,
                                    'CertificateContent')}}
                                </mat-error>
                            </mat-form-field>
                        </div>


                        <!-- <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>
                                    Upload Signature</mat-label>


                                <ngx-mat-file-input name="SignatureImage" formControlName="SignatureImage"
                                    (change)="uploadedFile($event)" placeholder="Select  file" matTooltip="Select  file"
                                    matTooltipPosition="above"></ngx-mat-file-input>
                            </mat-form-field>
                        </div> -->



                        <div fxLayout="row" fxLayoutAlign="start start">
                            <span class="checkbox-space">Is Active?</span>
                            <mat-checkbox matInput formControlName="IsActive" [disabled]="PageRights.IsReadOnly">
                            </mat-checkbox>
                        </div>
                    </div>
                    <!-- </mat-tab>
                    </mat-tab-group> -->
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>