import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HeadMasterModel } from './head-master.model';
import { HeadMasterService } from './head-master.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as guarati } from './i18n/gj';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DropdownModel } from 'app/models/dropdown.model';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

@Component({
  selector: 'data-list-view',
  templateUrl: './head-master.component.html',
  styleUrls: ['./head-master.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class HeadMasterComponent extends BaseListComponent<HeadMasterModel> implements OnInit {
  headMasterFilterForm: FormGroup;
  headMasterForm: FormGroup;
  data: Element[];

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private translationLoaderService: FuseTranslationLoaderService,
    public zone: NgZone,
    private dialogService: DialogService,
    private formBuilder: FormBuilder,
    private headMasterService: HeadMasterService) {
    super(commonService, router, routeParams, snackBar, zone);
    this.translationLoaderService.loadTranslations(english, guarati);
    this.headMasterFilterForm = this.createHeadMasterFilterForm();

  }

  ngOnInit(): void {
    this.onLoadHeadMasterByCriteria();
    this.headMasterForm = this.formBuilder.group({ filterText: '' });
    this.headMasterForm.get('filterText').valueChanges.pipe(
      // if character length greater then 2
      filter(res => {
        this.SearchBy.PageIndex = 0;

        if (res.length == 0) {
          this.SearchBy.Name = '';
          this.onLoadHeadMasterByCriteria();
          return false;
        }

        return res.length > 2
      }),

      // Time in milliseconds between key events
      debounceTime(650),

      // If previous query is diffent from current   
      distinctUntilChanged()

      // subscription for response
    ).subscribe((searchText: string) => {
      this.SearchBy.Name = searchText;
      this.onLoadHeadMasterByCriteria();
    });

  }

  ngAfterViewInit() {
    this.tableDataSource.paginator = this.ListPaginator;
  }

  onPageIndexChanged(evt) {
    this.SearchBy.PageIndex = evt.pageIndex;
    this.SearchBy.PageSize = evt.pageSize;

    this.onLoadHeadMasterByCriteria();
  }


  onLoadHeadMasterByCriteria(): any {
    this.IsLoading = true;
    this.SearchBy.IsActive = this.headMasterFilterForm.controls["Status"].value=='true'?true:this.headMasterFilterForm.controls["Status"].value=='false'?false:this.headMasterFilterForm.controls["Status"].value,
      this.headMasterService.GetAllByCriteria(this.SearchBy).subscribe(response => {
        // this.displayedColumns = ['SchoolName', 'SchoolUDISE', 'FullName', 'Mobile', 'Email', 'Gender', 'YearsInSchool', 'IsActive', 'Actions'];
       this.displayedColumns = ['FullName', 'SchoolName', 'Mobile', 'Email', 'SchoolUDISE', 'Gender',  'IsActive', 'Actions'];

        this.data = this.tableDataSource.data;
        this.tableDataSource.data = response.Results;
        this.tableDataSource.sort = this.ListSort;
        this.tableDataSource.paginator = this.ListPaginator;
        this.tableDataSource.filteredData = this.tableDataSource.data;

        this.zone.run(() => {
          if (this.tableDataSource.data.length == 0) {
            this.showNoDataFoundSnackBar();
          }
        });
        this.IsLoading = false;
      }, error => {
        console.log(error);
      });
  }

  applyFilter(filterValue: string) {

    this.tableDataSource.filter = filterValue.trim().toLowerCase();
    this.zone.run(() => {
      //@ts-ignore
      if (this.tableDataSource.filteredData.length <= 0) {
        //@ts-ignore
        this.snackBar.open("No data found..", "Dismiss", {
          duration: 5000,
          panelClass: ["info-snackbar"]
        });
      }
    });
  }

  onDeleteHeadMaster(hmId: string) {
    this.dialogService
      .openConfirmDialog(this.Constants.Messages.DeleteConfirmationMessage)
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          this.headMasterService.deleteHeadMasterById(hmId)
            .subscribe((headMasterResp: any) => {

              this.zone.run(() => {
                if (headMasterResp.Success  == true) {
                  this.showActionMessage(
                    this.Constants.Messages.RecordDeletedMessage,
                    this.Constants.Html.SuccessSnackbar
                  );
                } else {
                  this.showActionMessage(
                    headMasterResp.Result,
                    this.Constants.Html.SuccessSnackbar
                  );
                  this.ngOnInit();

                }
                this.ngOnInit();
              }, error => {
                console.log('HeadMaster deletion errors =>', error);
              });

            });
          this.ngOnInit();
        }
      });
  }

  exportExcel(): void {
    this.IsLoading = true;
    this.SearchBy.PageIndex = 0;
    this.SearchBy.PageSize = 10000;

    this.headMasterService.GetAllByCriteria(this.SearchBy).subscribe(response => {
      debugger;
      response.Results.forEach(

        function (obj) {
          if (obj.hasOwnProperty('IsActive')) {
            obj.IsActive = obj.IsActive ? 'Yes' : 'No';
          }
          if (obj.hasOwnProperty('IsImplemented')) {
            obj.IsImplemented = obj.IsImplemented ? 'Yes' : 'No';
          }
          delete obj.HMId;
          delete obj.StateCode;
          delete obj.StateName;
          delete obj.TotalRows;
        });


      this.exportExcelFromTable(response.Results, "HeadMaster");

      this.IsLoading = false;
      this.SearchBy.PageIndex = 0;
      this.SearchBy.PageSize = 10;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  //Create HeadMaster form and returns {FormGroup}
  createHeadMasterFilterForm(): FormGroup {
    return this.formBuilder.group({
      // AcademicYearId: new FormControl(),
      Status: new FormControl()
    });
  }

  onLoadHeadMasterByFilters(): any {
    this.SearchBy.PageIndex = 0;
    this.onLoadHeadMasterByCriteria();
  }

  resetFilters(): void {
    this.SearchBy.PageIndex = 0;
    this.headMasterFilterForm.reset();
    //this.schoolFilterForm.get('AcademicYearId').setValue(this.AcademicYearId);

    this.onLoadHeadMasterByCriteria();
  }



}
