<!-- HEADER -->
<div class="header1" fxLayout="column" fxLayoutAlign="start center" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="space-between center" style="background: #3f51b5;height: 64px; height: 61px;
  padding: 0px 24px;
">
    <!-- APP TITLE -->
    <div
        [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
        <div class="h2 mt-4 mb-12  heading-text1" style="color:#fff;">
            Employer-Student Mapping
        </div>
    </div>
    <!-- / APP TITLE -->
    <!-- ADD BUTTON -->

    <!-- <button mat-flat-button [color]="'warn'" (click)="onClose()" class="mat-elevation-z1" style="background-color: #f44336 !important;
  color: #fff !important;">
        <mat-icon>clear</mat-icon>
    </button> -->
    <!-- / ADD BUTTON -->
</div>
<!-- / HEADER -->
<div id="staff" class="page-layout carded fullwidth inner-scroll" style="height: 500px;">
    <!-- TOP BACKGROUND -->
    <!-- <div class="top-bg accent"></div> -->
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">
        <!-- CONTENT CARD -->
        <div class="content-card mat-elevation-z2 p-5 mb-12">
            <div class="content">
                 <form name="reviewInternshipReportForm" [formGroup]="reviewInternshipReportForm" 
              class="internship w-100-p p-24 mr-24" fxLayout="column"
                    fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex fusePerfectScrollbar>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                    fxLayoutGap.lt-md="0px">
                   <!-- <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                        <mat-label>Employer Name</mat-label>-->
                        <!-- <mat-select name="EmployerId" formControlName="EmployerId" required appearance="outline"
                         >
                            <mat-option [value]="gItem.Id" *ngFor="let gItem of interList" (click)="onChange(gItem)">
                                {{ gItem.Name }}
                            </mat-option>
                        </mat-select> -->
                        <!--<mat-select formControlName="EmployerId" appearance="outline"
                        required #SelectSchool (selectionChange)="onChange($event.value)">
                       <mat-select-filter class="select-filter" *ngIf="SelectSchool.focused"
                           [array]="interList" (filteredReturn)="this.filteredEmpItems = $event"
                           [displayMember]="'Name'"></mat-select-filter>
                       <mat-option [value]="empItem.Id" *ngFor="let empItem of filteredEmpItems">
                           {{ empItem.Name }}
                       </mat-option>
                   </mat-select>
                        <mat-error *ngIf="reviewInternshipReportForm.controls.EmployerId.invalid">
                            {{getErrorMessage(reviewInternshipReportForm, 'EmployerId')}}
                        </mat-error>
                    </mat-form-field> -->

                    <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                        <mat-label> Employer</mat-label>

                        <mat-select formControlName="EmployerId" required name="employerId" appearance="outline"
                            #SelectEmployer (selectionChange)="onChange($event.value)">
                            <mat-select-filter class="select-filter" *ngIf="SelectEmployer.focused"
                                [array]="employerList" (filteredReturn)="this.filteredEmployerItems = $event"
                                [displayMember]="'Name'">
                            </mat-select-filter>
                            <mat-option [value]="employerItem.Id"
                                *ngFor="let employerItem of filteredEmployerItems">
                                {{ employerItem.Name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                        <mat-label>Sector  Name</mat-label>
                        <mat-select name="Sector" formControlName="Sector" required appearance="outline"
                         >
                            <mat-option [value]="gItem.Id" *ngFor="let gItem of sectorList" >
                                {{ gItem.Name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="reviewInternshipReportForm.controls.Sector.invalid">
                            {{getErrorMessage(reviewInternshipReportForm, 'Sector')}}
                        </mat-error>
                    </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="100"
                        fxFlex.lt-sm="100">
                        <mat-label>Commencement Date</mat-label>
                        <input matInput [matDatepicker]="picker1"  [min]="myDate" name="CommencementDate" formControlName="CommencementDate"
                           required>
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                        <mat-error *ngIf="reviewInternshipReportForm.controls.CommencementDate.invalid">
                            {{getErrorMessage(reviewInternshipReportForm, 'CommencementDate')}}
                        </mat-error>  
                           
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                    fxLayoutGap.lt-md="0px">
                    <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                        <mat-label>Internship Type</mat-label>
                        <mat-select name="internshipType" formControlName="InternshipType" required
                            appearance="outline" >
                            <mat-option [value]="internshipTypeItem.Id"
                                *ngFor="let internshipTypeItem of internshipTypeList">
                                {{ internshipTypeItem.Name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="reviewInternshipReportForm.controls.InternshipType.invalid">
                            {{getErrorMessage(reviewInternshipReportForm, 'InternshipType')}}
                        </mat-error>
                    </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="100"
                        fxFlex.lt-sm="100">
                        <mat-label>Start Time </mat-label>
                        <mat-select name="StartDate" formControlName="StartDate" 
                        appearance="outline" >
                        <mat-option [value]="internshipTypeItem.Id"
                            *ngFor="let internshipTypeItem of timeList">
                            {{ internshipTypeItem.Name }}
                        </mat-option>
                    </mat-select>
                       
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="100"
                        fxFlex.lt-sm="100">
                        <mat-label>End Time</mat-label>
                      
                        <mat-select name="EndDate" formControlName="EndDate" 
                        appearance="outline" >
                        <mat-option [value]="internshipTypeItem.Id"
                            *ngFor="let internshipTypeItem of timeList">
                            {{ internshipTypeItem.Name }}
                        </mat-option>
                    </mat-select>
                       
                           
                        </mat-form-field>
                        
                    </div>

                
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start">
                        <button mat-raised-button class="add-internship-button btn-warning mls"  
                            (click)="onSubmit(reviewInternshipReportForm)" fxFlex="0 1 calc(47%)"
                            fxFlex.lt-md="0 1 calc(47%)" fxFlex.lt-sm="50%">
                            <span>Save </span>
                        </button>

                        <button mat-raised-button class="add-internship-button btn-cancel" (click)="onClose()"
                            fxFlex="0 1 calc(47%)" fxFlex.lt-md="0 1 calc(47%)" fxFlex.lt-sm="50%" sty>
                            <span>Cancel </span>
                        </button>
                    </div>
                </form>
            </div>
            <!-- / CONTENT CARD -->
        </div>
        <!-- / CENTER -->
    </div>