import { Component, Inject, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { AnyKindOfDictionary } from 'lodash';
import { InternshipDetails } from '../internship-details.model';

@Component({
  selector: 'app-view-internship-details',
  templateUrl: './view-internship-details.component.html',
  styleUrls: ['./view-internship-details.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class ViewInternshipDetailsComponent extends BaseComponent<InternshipDetails> implements OnInit {

  internshipData: any;
  isLoading: boolean = true;
  selectedIndex: number;
  selectedObjects: any = {};
  errorMessage: any;
  studentSearchData = [] as any;
  studentFeeId: number;
  receiptType: number;
  receiptNo: number;
  studentInternshipDataById = [] as any;
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ViewInternshipDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(commonService, router, routeParams, snackBar);
    // dialogRef.disableClose = true;
  }

  setTab(tabname: string) {
    this.router.navigate([`/${tabname}`]);
  }
  ngOnInit() {
    console.log(this.data);
    this.internshipData = this.data;
    console.log(this.internshipData);
  }

  onClose() {
    this.dialog.closeAll();
  }

}
