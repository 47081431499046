import { Component, Inject, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { BaseComponent } from 'app/common/base/base.component';
import { InternshipOpportunity } from 'app/main/internship-opportunity/internship-opportunity.model';
import { CommonService } from 'app/services/common.service';
import { environment } from 'environments/environment';
import { InternshipAssignmentsRequestService } from '../internship-assignments.service';
import { DropdownModel } from 'app/models/dropdown.model';
import { InternshipOpportunityService } from 'app/main/internship-opportunity/internship-opportunity.service';

@Component({
  selector: 'app-review-internship-assignments',
  templateUrl: './review-internship-assignments.component.html',
  styleUrls: ['./review-internship-assignments.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ReviewInternshipAssignmentsComponent extends BaseComponent<InternshipOpportunity> implements OnInit {
  passDatatoChild: any[];
  isLoading: boolean = true;
  errorMessage: any;
  studentInternshipDataById = [] as any;
  studentId: any;
  internshipId: any;
  reportData: any;
  btnHide: boolean = false;
  internData: any;
 
  interList: [DropdownModel];
  sectorList: [DropdownModel];
  myDate:any;
  internshipTypeList = [{ Id: 'Online', Name: 'Online' }, { Id: 'Offline', Name: 'Offline' }, { Id: 'Hybrid', Name: 'Hybrid' }, { Id: ' Internship@School', Name: 'Internship @School' }, { Id: 'Internship @Home', Name: 'Internship @Home' }];
  filteredEmpItems: any;
  timeList = [{ Id: '7:00AM', Name: '7:00AM' }, { Id: '7:30AM', Name: '7:30AM' }, { Id: '8AM', Name: '8:00AM' }, { Id: '8:30AM', Name: '8:30AM' }, { Id: '9:00AM', Name: '9:00AM' }, { Id: '9:30AM', Name: '9:30AM' },{ Id: '10:00AM', Name: '10:00AM' },{ Id: '10:30AM', Name: '10:30AM' },{ Id: '11:00AM', Name: '11:00AM' },{ Id: '11:30AM', Name: '11:30AM' },{ Id: '12:00PM', Name: '12:00PM' },{ Id: '12:30PM', Name: '12:30PM' },{ Id: '1:00PM', Name: '1:00PM' },{ Id: '1:30PM', Name: '1:30PM' },{ Id: '2:00PM', Name: '2:00PM' },{ Id: '2:30PM', Name: '2:30PM' },{ Id: '3:00PM', Name: '3:00PM' },{ Id: '3:30PM', Name: '3:30PM' },{ Id: '4:00PM', Name: '4:00PM' },{ Id: '4:30PM', Name: '4:30PM' },{ Id: '5:00PM', Name: '5:00PM' },{ Id: '5:30PM', Name: '5:30PM' },{ Id: '6:00PM', Name: '6:00PM' },{ Id: '6:30PM', Name: '6:30PM' },{ Id: '7:00PM', Name: '7:00PM' }, { Id: '7:30PM', Name: '7:30PM' }, { Id: '8PM', Name: '8:00PM' }, { Id: '8:30PM', Name: '8:30PM' }, { Id: '9:00PM', Name: '9:00PM' }, { Id: '9:30PM', Name: '9:30PM' },{ Id: '10:00PM', Name: '10:00PM' },{ Id: '10:30PM', Name: '10:30PM' },{ Id: '11:00PM', Name: '11:00PM' },{ Id: '11:30PM', Name: '11:30PM' },{ Id: '12:00PM', Name: '12:00PM' }];
  employerList: any;
  filteredEmployerItems: any;

  constructor(
    public internshipAssignmentsRequestService: InternshipAssignmentsRequestService,
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    public dialog: MatDialog,
    private internshiOpportunityService: InternshipOpportunityService,
    public dialogRef: MatDialogRef<ReviewInternshipAssignmentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(commonService, router, routeParams, snackBar);
    // dialogRef.disableClose = true;
  }
  ngOnInit() {
 this.myDate=new Date()
    console.log(this.data);
    // this.reviewInternshipReportForm.controls['InternshipId'].patchValue(this.data.InternshipId);
    // this.reviewInternshipReportForm.controls['CommencementDate'].patchValue(this.getDateValue(this.data.CommencementDate));
   
    // var data={
    //   "UserTypeId": this.UserModel.UserTypeId,
    //   "SchoolGuid": (this.Constants.GlobalSchoolId == 'undefined' || this.Constants.GlobalSchoolId == 'null') ? null : this.Constants.GlobalSchoolId,
    //   "AcademicYearGuid": (this.Constants.GlobalAcademicYearId == 'undefined' || this.Constants.GlobalAcademicYearId == 'null') ? null : this.Constants.GlobalAcademicYearId,
    //   "SectorGuid": null,
    //   "RoleCode": this.UserModel.RoleCode,
    //   "EmployerGuid": null,
    //   "PageIndex": 0,
    //   "PageSize": 10000,
    //   "IsCriterion": true
    // }
    //   this.internshipAssignmentsRequestService.GetInternshipByActiveCriteria(data).subscribe(response => {
    //     this.interList=response.Results
    //     this.IsLoading = false;
    //   }, error => {
    //     console.log(error);
    //     this.IsLoading = false;
    //   });
   
      this.commonService.GetMasterDataByType({ DataType: 'EmployersForVT', UserId: this.UserModel.UserTypeId, ParentId: null,  }).subscribe((response: any) => {
        this.interList = response.Results;
      this.filteredEmpItems = this.interList.slice();

        console.log( this.interList);
      });
      this.getEmployersByCriteria();
  }

  onClose() {
    this.dialog.closeAll();
  }

  onSubmit(reviewInternshipReportForm) {
    this.dialogRef.close({Interns: this.internData,CommencementDate:reviewInternshipReportForm.controls['CommencementDate'].value,data:reviewInternshipReportForm });
  }

  getEmployersByCriteria(): any {
    debugger;
    this.IsLoading = true;
    let data = {
      VTId: this.UserModel.UserTypeId,
      VTGuid: this.UserModel.UserTypeId,
      UserTypeId: null,
      RoleCode: this.UserModel.RoleCode,
      PageIndex: 0,
      PageSize: 10000,
      Name: null,
      CharBy: null,
      SectorGuid: null,
      StartDateTime: null,
      EndDatetime: null,
      IsActive: null
    }
    this.internshiOpportunityService.GetEmployersByCriteria(data).subscribe(response => {
      debugger;
      let empList = response.Results;
      let arr = [] as any;
      for (let i = 0; i < empList.length; i++) {
        let xxx = {
          Id: empList[i].EmployerId,
          Name: empList[i].OrganizationName,
          Description: '',
          IsSelected: false,
          IsDisabled: false,
          SequenceNo: 1
        }
        arr.push(xxx);
      }
      this.employerList = arr;
      this.filteredEmployerItems = this.employerList.slice()
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  onChange(data: any) {
   console.log(data);
   this.internData=data
   this.myDate=this.getDateValue(data.StartDate)
  this.commonService.GetMasterDataByType({ DataType: 'SectorsForEmp', UserId: this.UserModel.UserTypeId, ParentId: data,  }).subscribe((response: any) => {
    this.sectorList = response.Results;
    for (let i = 0; i < this.sectorList.length; i++) {
      if (this.sectorList[i].Id ) { 
        this.reviewInternshipReportForm.controls['Sector'].patchValue(this.sectorList[i].Id);
      }
    }

    console.log( this.interList);
  });

  }
  reviewInternshipReportForm: FormGroup = new FormGroup({
    CommencementDate: new FormControl("", Validators.required),
    EmployerId: new FormControl("", Validators.required),
    Sector : new FormControl("", Validators.required),
    StartDate: new FormControl(""),
    EndDate: new FormControl(""),
    InternshipType: new FormControl("", Validators.required),


  });

}
