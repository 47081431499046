import { FuseUtils } from '@fuse/utils';

export class EmployerCertificatesModel {
    EmployerId: string;
    CertificateType: string;
    CertificateIssueDate: string;
    OrganizationName: string;
    CertificateContent: string;  
    IsActive: boolean;
    RequestType: any;


    CertificateId: string;
    StudentId : string; 
    TargetAudienceType: string;
    CertificateTitle: string;
    CertificateImage:string;
    SignatureImage:string;
    constructor(employerCertificatesItem?: any) {
        employerCertificatesItem = employerCertificatesItem || {};

        this.EmployerId = employerCertificatesItem.EmployerId || '';
        this.CertificateType = employerCertificatesItem.CertificateType || '';
        this.CertificateIssueDate = employerCertificatesItem.CertificateIssueDate || '';
        this.OrganizationName = employerCertificatesItem.OrganizationName || '';
        this.CertificateContent = employerCertificatesItem.CertificateContent || '';
        this.IsActive = employerCertificatesItem.IsActive || true;
        this.RequestType = 0; // New


        this.CertificateId = "00000000-0000-0000-0000-000000000000"; // New
        this.StudentId = '00000000-0000-0000-0000-000000000000';// New
        this.TargetAudienceType = 'Employer';// New
        this.CertificateTitle = employerCertificatesItem.CertificateTitle || '';// New
        this.CertificateImage = employerCertificatesItem.CertificateImage || '';// New
        this.SignatureImage = employerCertificatesItem.SignatureImage || '';// New


    }
}
