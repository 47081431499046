<div id="vt-assessment" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    VT Assessment/Performance Report
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter (keyup)="applySearchFilter($event.target.value)" placeholder="Search for  VT Report">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">
                <a *ngIf="PageRights.IsExport" class="export-excel"
                    (click)="exporter.exportTable('xlsx', {fileName:'vtReport', sheet: 'vtReport', Props: {Author: 'Pathways'}})">
                    <mat-icon> cloud_download</mat-icon>
                </a>
            </div>
            <!-- / ADD BUTTON -->
        </div>
        <!-- / HEADER -->

        <!-- <div fxLayout="row" fxLayoutAlign="start center">
            <button mat-raised-button class="fuse-white filter-button" style="margin-bottom: 10px;"
                (click)="isShowFilterContainer=!isShowFilterContainer"><span>FILTERS</span></button>
        </div> -->

        <!-- Filter Section -->
        <div class="filter-content-card">
            <div class="filter-content">
                <form name="vtReportForm" [formGroup]="vtReportForm" class="vt-school-sector" fxLayout="column"
                    fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label> Academic Year</mat-label>

                            <mat-select formControlName="AcademicYearId" name="academicYearId" appearance="fill"
                                (selectionChange)="onChangeAct($event.value)">
                                <mat-option [value]="academicYearItem.Id"
                                    (onSelectionChange)="onSelectEvent($event, academicYearItem.Name)"
                                    *ngFor="let academicYearItem of academicyearList">
                                    {{ academicYearItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100" *ngIf="isShowFilter">

                            <mat-label>From</mat-label>
                            <input [min]="minDate" [max]="maxDate" matInput formControlName="StartDateTime"
                                [matDatepicker]="picker1" name="StartDateTime" [max]="maxDate">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100" *ngIf="isShowFilter">
                            <mat-label>To</mat-label>
                            <input matInput formControlName="EndDateTime" [matDatepicker]="picker2" name="EndDateTime"
                                [min]="minDate" [max]="maxDate">
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100" *ngIf="isShowFilter">
                            <mat-label>Vocational Training Provider</mat-label>

                            <mat-select formControlName="VTPGuid" name="vTPGuid" appearance="fill"
                                (selectionChange)="onChangeVTP($event.value)">
                                <mat-option [value]="vtpItem.Id" *ngFor="let vtpItem of vtpList">
                                    {{ vtpItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100" *ngIf="isShowFilter">
                            <mat-label>Vocational Coordinator</mat-label>

                            <mat-select formControlName="VCGuid" name="vCGuid" appearance="fill">
                                <mat-option [value]="vtItem.Id" *ngFor="let vtItem of vocationalCoordinatorList">
                                    {{ vtItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>



                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="filter-disabled"
                            *ngIf="isShowFilter">
                            <mat-label>Division</mat-label>

                            <mat-select formControlName="DivisionCode" name="divisionCode" appearance="fill"
                                (selectionChange)="onChangeDivision($event.value)">
                                <mat-option [value]="Item.Id" *ngFor="let Item of divisionList">
                                    {{ Item.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="filter-disabled"
                            *ngIf="isShowFilter">
                            <mat-label>DistrictCode</mat-label>

                            <mat-select formControlName="DistrictCode" name="districtCode" appearance="fill"
                                (selectionChange)="onChangeDistrict($event.value)">
                                <mat-option [value]="districtItem.Id" *ngFor="let districtItem of districtList">
                                    {{ districtItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="filter-disabled"
                            *ngIf="isShowFilter">
                            <mat-label>BlockId</mat-label>

                            <mat-select formControlName="BlockId" name="blockId" appearance="fill">
                                <mat-option [value]="blockItem.Id" *ngFor="let blockItem of blockList">
                                    {{ blockItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>







                        <div class="clear-filter pr-1" fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="GetVTAssessmentReport()"><span>SEARCH</span></button>
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="resetReportFilters()"><span>CLEAR</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- /Filter Section -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table class="vt-school-sector-report-table responsive-table" #table [dataSource]="tableDataSource"
                matTableExporter #exporter="matTableExporter" [hiddenColumns]="[0]" matSort fusePerfectScrollbar
                [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- SrNo Column -->
                <ng-container matColumnDef="SrNo">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Sr No</mat-header-cell>
                    <mat-cell *matCellDef="let vtReport; let RowIndex = index;">
                        <p class="text-truncate">{{RowIndex+1}}</p>
                    </mat-cell>
                </ng-container>



                <!-- VTFullName Column -->
                <ng-container matColumnDef="VTFullName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VT Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.VTFullName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VTMobile Column -->
                <ng-container matColumnDef="VTMobile">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VT Contact 1
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.VTMobile}}</p>
                    </mat-cell>
                </ng-container>


                <!-- VTMobile1 Column -->
                <ng-container matColumnDef="VTMobile1">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VT Contact 2
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.VTMobile1}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VTEmailId Column -->
                <ng-container matColumnDef="VTEmailId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VT EmailId
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.VTEmailId}}</p>
                    </mat-cell>
                </ng-container>
                  <!-- SchoolName Column -->
                  <ng-container matColumnDef="SchoolName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>School Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.SchoolName}}</p>
                    </mat-cell>
                </ng-container>
                <!-- ClassName Column -->
                <ng-container matColumnDef="ClassName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Class Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.ClassName}}</p>
                    </mat-cell>
                </ng-container> 
                <!-- SectionName Column -->
                <ng-container matColumnDef="SectionName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Section Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.SectionName}}</p>
                    </mat-cell>
                </ng-container> 
                <!-- VocationalSubject Column -->
                <ng-container matColumnDef="VocationalSubject">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Trade / Vocational subject
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.VocationalSubject}}</p>
                    </mat-cell>
                </ng-container>


              

                 <!-- SchoolPhone Column -->
                 <ng-container matColumnDef="SchoolPhone">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>School Phone
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.SchoolPhone}}</p>
                    </mat-cell>
                </ng-container> 
                <!-- SchoolEmailId Column -->
                <ng-container matColumnDef="SchoolEmailId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>School EmailId
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.SchoolEmailId}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SchoolStateCode Column -->
                <ng-container matColumnDef="SchoolStateCode">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>School District
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.SchoolStateCode}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SchoolBlockName Column -->
                <ng-container matColumnDef="SchoolBlockName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>School Block
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.SchoolBlockName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SchoolVillage Column -->
                <ng-container matColumnDef="SchoolVillage">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>School Address
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.SchoolVillage}}</p>
                    </mat-cell>
                </ng-container>
                <!-- DivisionName Column -->
                <ng-container matColumnDef="DivisionName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Division Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.DivisionName}}</p>
                    </mat-cell>
                </ng-container>
                <!-- DistrictName Column -->
                <ng-container matColumnDef="DistrictName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>District Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.DistrictName}}</p>
                    </mat-cell>
                </ng-container>
                <!-- SchoolPanchayat Column -->
                <!-- <ng-container matColumnDef="SchoolPanchayat">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>School Panchayat
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.SchoolPanchayat}}</p>
                    </mat-cell>
                </ng-container> -->

                <!-- SchoolPincode Column -->
                <!-- <ng-container matColumnDef="SchoolPincode">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>School Pincode
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.SchoolPincode}}</p>
                    </mat-cell>
                </ng-container> -->

                <!-- SchoolLongitude Column -->
                <!-- <ng-container matColumnDef="SchoolLongitude">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>School Longitude
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.SchoolLongitude}}</p>
                    </mat-cell>
                </ng-container> -->
                <!-- SchoolLatitude Column -->
                <!-- <ng-container matColumnDef="SchoolLatitude">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>School Latitude
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.SchoolLatitude}}</p>
                    </mat-cell>
                </ng-container> -->



                <!-- HeadMasterName Column -->
                <ng-container matColumnDef="HeadMasterName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>HM Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.HeadMasterName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- YearName Column -->
                <ng-container matColumnDef="YearName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Year Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.YearName}}</p>
                    </mat-cell>
                </ng-container>
            
                 <!-- SchoolStudentCount Column -->
                 <ng-container matColumnDef="SchoolStudentCount">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>School Student Count
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.SchoolStudentCount}}</p>
                    </mat-cell>
                </ng-container>

                <!-- CountOfBoys Column -->
                <ng-container matColumnDef="CountOfBoys">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Total No. of students /
                        Enrollment Boys
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.CountOfBoys}}</p>
                    </mat-cell>
                </ng-container>

                <!-- CountOfGirls Column -->
                <ng-container matColumnDef="CountOfGirls">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Total No. of students /
                        Enrollment Girls
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.CountOfGirls}}</p>
                    </mat-cell>
                </ng-container>

                <!-- BoysWithInternship Column -->
                <ng-container matColumnDef="BoysWithInternship">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>No. of Boys doing
                        Internship
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.BoysWithInternship}}</p>
                    </mat-cell>
                </ng-container>

                <!-- GirlsWithInternship Column -->
                <ng-container matColumnDef="GirlsWithInternship">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>No. of Girls doing
                        Internship
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.GirlsWithInternship}}</p>
                    </mat-cell>
                </ng-container>

                <!-- BoysWithInternshipCompleted Column -->
                <ng-container matColumnDef="BoysWithInternshipCompleted">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Internships completed
                        till now Boys
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.BoysWithInternshipCompleted}}</p>
                    </mat-cell>
                </ng-container>

                <!-- GirlsWithInternshipCompleted Column -->
                <ng-container matColumnDef="GirlsWithInternshipCompleted">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Internships completed
                        till now Girls
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.GirlsWithInternshipCompleted}}</p>
                    </mat-cell>
                </ng-container>

                <!-- TotalEmployers Column -->
                <ng-container matColumnDef="TotalEmployers">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>No. of Employers
                        surveyed/ added
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.TotalEmployers}}</p>
                    </mat-cell>
                </ng-container>

                <!-- TotalEmployersOnboarded Column -->
                <ng-container matColumnDef="TotalEmployersOnboarded">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>No. of Employers
                        onboarded (Opportunity created)
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.TotalEmployersOnboarded}}</p>
                    </mat-cell>
                </ng-container>

                <!-- ActiveEmployerWithInternship Column -->
                <ng-container matColumnDef="ActiveEmployerWithInternship">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Active employers mapped
                        for internships
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.ActiveEmployerWithInternship}}</p>
                    </mat-cell>
                </ng-container>



                <!-- ActiveEmployerWithCompletedInternship Column -->
                <ng-container matColumnDef="ActiveEmployerWithCompletedInternship">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>No. of employers
                        engaged in Completed Internships
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.ActiveEmployerWithCompletedInternship }}</p>
                    </mat-cell>
                </ng-container>

                <!-- SafetyConcernsRaised Column -->
                <ng-container matColumnDef="SafetyConcernsRaised">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md> Total Safety Concerns
                        raised
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.SafetyConcernsRaised }}</p>
                    </mat-cell>
                </ng-container>

                <!-- SafetyConcernsResolved Column -->
                <ng-container matColumnDef="SafetyConcernsResolved">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Total Safety Concerns
                        resolved
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.SafetyConcernsResolved }}</p>
                    </mat-cell>
                </ng-container>


                <!-- InternshipApprovalRequest Column -->
                <ng-container matColumnDef="InternshipApprovalRequest">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Pending Internship
                        Approvals
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.InternshipApprovalRequest}}</p>
                    </mat-cell>
                </ng-container>

                <!-- PendingProfileEditRequest Column -->
                <ng-container matColumnDef="PendingProfileEditRequest">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Pending Profile edit
                        requests
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.PendingProfileEditRequest}}</p>
                    </mat-cell>
                </ng-container>

                <!-- FinalReportPendingAck Column -->
                <ng-container matColumnDef="FinalReportPendingAck">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Pending Ackn. on Final
                        Internship Report

                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtReport">
                        <p class="text-truncate">{{vtReport.FinalReportPendingAck}}</p>
                    </mat-cell>
                </ng-container>


                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let vtReport; columns: displayedColumns;" class="vt-assessment" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

            <!-- <mat-paginator #ListPaginator [length]="SearchBy.TotalResults" [pageIndex]="SearchBy.PageIndex"
                [pageSize]="SearchBy.PageSize" [pageSizeOptions]="SearchBy.PageSizeOptions"
                [showFirstLastButtons]="SearchBy.ShowFirstLastButtons" (page)="onPageIndexChanged($event)">
            </mat-paginator> -->

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>