<div id="sector-job-role" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    Student Certificates
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter (keyup)="applySearchFilter($event.target.value)"
                        placeholder="Search for a student certificates">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <button mat-raised-button [routerLink]="['./','new']" skipLocationChange="true" replaceUrl="false"
                class="add-sector-job-role-button fuse-white mt-24 mt-md-0">
                <span>ADD</span>
            </button>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- Filter Section -->
        <div class="filter-content-card">
            <div class="filter-content">
                <form name="studentCertificatesFilterForm" [formGroup]="studentCertificatesFilterForm" class="vc-issue-report" fxLayout="column"
                    fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">



                        <mat-form-field fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Class </mat-label>

                            <mat-select formControlName="Class" name="class" appearance="outline"
                               >
                                <mat-option [value]="ctem.Id" *ngFor="let ctem of classList">
                                    {{ ctem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Section </mat-label>

                            <mat-select formControlName="Section" name="section" appearance="outline">
                                <mat-option [value]="stem.Id" *ngFor="let stem of sectionList">
                                    {{ stem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>





                        <div class="clear-filter pr-1" fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <button mat-raised-button class="fuse-white filter-button"
                               ><span>SEARCH</span></button>
                            <button mat-raised-button class="fuse-white filter-button"
                                ><span>CLEAR</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- /Filter Section -->

        <!-- CONTENT CARD -->
        <div class="content-card">

           
            <mat-table class="sector-job-role-table responsive-table" #table [dataSource]="tableDataSource" matSort fusePerfectScrollbar
                [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- CERTIFIFCATES Column -->
                <ng-container matColumnDef="CertificateTitle">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide
                        fxShow.gt-xs>CERTIFIFCATES</mat-header-cell>
                    <mat-cell *matCellDef="let empCertificate" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{empCertificate.CertificateTitle}}</p>
                    </mat-cell>
                </ng-container>

                <!-- COMPANY Column -->
                <ng-container matColumnDef="OrganizationName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm
                        fxShow.gt-md>COMPANY</mat-header-cell>
                    <mat-cell *matCellDef="let empCertificate">
                        <p class="text-truncate">{{empCertificate.OrganizationName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- EMPLOYER NAME Column -->
                <ng-container matColumnDef="CertificateContent">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Student
                        NAME</mat-header-cell>
                    <mat-cell *matCellDef="let empCertificate" fxHide fxShow.gt-xs>
                        <div [routerLink]="['/vt-student-details', empCertificate.StudentId]">
                        <p class="text-truncate">
                            <span class="stud-img">
                                <img *ngIf="empCertificate.StudentPhoto!=''"
                                    [src]="ProfileImageBaseURL + empCertificate.StudentPhoto"
                                    class="stud-profile-pic" style="max-width: 30px !important;
                                    height: 30px !important;
                                    vertical-align: top;
                                    border: none; border-radius: 50%;"/>

                                <mat-icon *ngIf="empCertificate.StudentPhoto==''">face</mat-icon>
                               
                            </span>
                            <span>{{empCertificate.StudentFirstName}}{{empCertificate.StudentLastName}}</span><br>
                            <!-- {{empCertificate.Mobile}} -->

                        </p>
                    </div>
                    </mat-cell>
                </ng-container>

                <!-- ISSUE DATE Column -->
                <ng-container matColumnDef="CertificateIssueDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>ISSUE DATE</mat-header-cell>
                    <mat-cell *matCellDef="let empCertificate" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{empCertificate.CertificateIssueDate | date: Constants.ShortDateFormat}}</p>
                    </mat-cell>
                </ng-container>



                <ng-container matColumnDef="Actions">
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let empCertificate" fxShow.gt-xs>
                        <button *ngIf="PageRights.IsView" mat-icon-button
                            [routerLink]="['/student-certificates', 'view', empCertificate.CertificateId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">visibility</mat-icon>
                        </button>
                        <button  mat-icon-button
                        (click)="downloadCertificate(empCertificate.CertificateId)"
                           
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon> cloud_download</mat-icon>
                        </button>
                       
                        <!-- <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                            <mat-icon>more_vert</mat-icon>
                        </button> -->
                        <!-- <mat-menu #menu="matMenu">
                            <button mat-menu-item 
                                >
                                
                                <mat-icon>done</mat-icon>
                                <span>Complete</span>
                            </button>
                            <button mat-menu-item >
                                
                                <span>No Actions</span>
                            </button>
                            <button mat-menu-item 
                                >
                                <mat-icon>publish</mat-icon>
                                <span>Publish</span>
                            </button>
                        </mat-menu> -->
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let empCertificate; columns: displayedColumns;" class="sector-job-role" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>