import { Injectable } from "@angular/core";
import { retry, catchError, tap } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';
import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";


@Injectable()
export class ChangePasswordService {
    constructor(private http: BaseService) { }

    createOrUpdatePassword(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Account.ChangePassword, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }
   

    forgotPassword(formData: any) {
        return this.http
            .HttpPost("OtpDetails/CreateSendOtpDetails", formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }
    VerifyOtpEmail(formData: any) {
        return this.http
            .HttpPost("OtpDetails/VerifyOtpDetails", formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }
    UpdatePassword(formData: any, AuthToken: any) {
        const httpOptionsAuth = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': AuthToken.AuthToken })
        };
        console.log(AuthToken.AuthToken)

        return this.http
         .post('https://dev.pathways.net.in/LighthouseServices/' + this.http.Services.Account.ChangePassword, formData, httpOptionsAuth)
        // .post('https://admin-uat.pathways.net.in/LighthouseServices/' + this.http.Services.Account.ChangePassword, formData, httpOptionsAuth)
         //.post('https://admin.pathways.net.in/LighthouseServices/' + this.http.Services.Account.ChangePassword, formData, httpOptionsAuth)
        // .post('https://delhi-admin.pathways.net.in/LighthouseServices/' + this.http.Services.Account.ChangePassword, formData, httpOptionsAuth)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    resetPassword(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Account.ResetPassword, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }
}
