import { Component, OnInit, NgZone, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { DropdownModel } from 'app/models/dropdown.model';
import { environment } from 'environments/environment';
import { ResourceVideosService } from './resource-videos.service';
import { ResourceVideosModel } from './resource-videos-model.model';

@Component({
  selector: 'data-list-view',
  templateUrl: './resource-videos.component.html',
  styleUrls: ['./resource-videos.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ResourceVideosComponent extends BaseListComponent<ResourceVideosModel> implements OnInit {
  ResourceVideosForm: FormGroup;
  ResourceVideosFilterForm: FormGroup;
  data: Element[];
  resourceVideosModel: ResourceVideosModel;
  currentAcademicYearId: string;
  academicYearList: [DropdownModel];
  sectorList: [DropdownModel];
  vtpList: [DropdownModel];
  filteredVtpSectorItems: any;
  filteredSchoolItems: any;
  ApiBaseUrlImage = this.Constants.CommonImageUrl.Account;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private translationLoaderService: FuseTranslationLoaderService,
    public zone: NgZone,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private resourceVideosService: ResourceVideosService,) {
    super(commonService, router, routeParams, snackBar, zone);
    this.ResourceVideosForm = this.formBuilder.group({ filterText: '' });
    this.resourceVideosModel = new ResourceVideosModel();
    this.ResourceVideosFilterForm = this.createResourceVideosFilterForm();
  }

  ngOnInit(): void {
    this.onLoadResourceVideosByCriteria();
    this.ResourceVideosForm = this.formBuilder.group({ filterText: '' });
    this.ResourceVideosForm.get('filterText').valueChanges.pipe(
      // if character length greater then 2
      filter(res => {
        this.SearchBy.PageIndex = 0;

        if (res.length == 0) {
          this.SearchBy.Name = '';
          this.onLoadResourceVideosByCriteria();
          return false;
        }

        return res.length > 2
      }),

      // Time in milliseconds between key events
      debounceTime(650),

      // If previous query is diffent from current   
      distinctUntilChanged()

      // subscription for response
    ).subscribe((searchText: string) => {
      this.SearchBy.Name = searchText;
      this.onLoadResourceVideosByCriteria();
    });

  }

  ngAfterViewInit() {
    this.tableDataSource.paginator = this.ListPaginator;
  }

  onPageIndexChanged(evt) {
    this.SearchBy.PageIndex = evt.pageIndex;
    this.SearchBy.PageSize = evt.pageSize;

    this.onLoadResourceVideosByCriteria();
  }


  onLoadResourceVideosByCriteria(): any {
    this.IsLoading = true;
    this.SearchBy.Name = 'Videos',
      this.resourceVideosService.GetAllByCriteria(this.SearchBy).subscribe(response => {
        this.displayedColumns = ['ResourceTitle', 'ResourceType', 'Category', 'SubCategory', 'ResourceDescription', 'ResourceLink', 'PublishDate', 'IsActive', 'Actions'];

        this.data = this.tableDataSource.data;
        this.tableDataSource.data = response.Results;
        this.tableDataSource.sort = this.ListSort;
        this.tableDataSource.paginator = this.ListPaginator;
        this.tableDataSource.filteredData = this.tableDataSource.data;

        this.zone.run(() => {
          if (this.tableDataSource.data.length == 0) {
            this.showNoDataFoundSnackBar();
          }
        });
        this.IsLoading = false;
      }, error => {
        console.log(error);
      });
  }

  applyFilter(filterValue: string) {

    this.tableDataSource.filter = filterValue.trim().toLowerCase();
    this.zone.run(() => {
      //@ts-ignore
      if (this.tableDataSource.filteredData.length <= 0) {
        //@ts-ignore
        this.snackBar.open("No data found..", "Dismiss", {
          duration: 5000,
          panelClass: ["info-snackbar"]
        });
      }
    });
  }

  onDeleteResourceVideos(resourceId: string) {
    this.dialogService
      .openConfirmDialog(this.Constants.Messages.DeleteConfirmationMessage)
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          this.resourceVideosService.DeleteResourceVideosById(resourceId)
            .subscribe((ResourceVideosResp: any) => {

              this.zone.run(() => {
                if (ResourceVideosResp.Success) {
                  this.showActionMessage(
                    this.Constants.Messages.RecordDeletedMessage,
                    this.Constants.Html.SuccessSnackbar
                  );
                } else {
                  this.showActionMessage(
                    this.Constants.Messages.RecordNotDMessage,
                    this.Constants.Html.SuccessSnackbar
                  );
                  this.ngOnInit();

                }
                this.ngOnInit();
              }, error => {
                console.log('ResourceVideos deletion errors =>', error);
              });

            });
          this.ngOnInit();
        }
      });
  }

  exportExcel(): void {
    this.IsLoading = true;
    this.SearchBy.PageIndex = 0;
    this.SearchBy.PageSize = 10000;

    this.resourceVideosService.GetAllByCriteria(this.SearchBy).subscribe(response => {
      response.Results.forEach(

        function (obj) {
          if (obj.hasOwnProperty('IsActive')) {
            obj.IsActive = obj.IsActive ? 'Yes' : 'No';
          }
          if (obj.hasOwnProperty('IsImplemented')) {
            obj.IsImplemented = obj.IsImplemented ? 'Yes' : 'No';
          }
          delete obj.ResourceId;
          delete obj.ResourceCategoryId;
          delete obj.ResourceSubcategoryId;
          delete obj.ExpiryDate;
          delete obj.TotalRows;
        });


      this.exportExcelFromTable(response.Results, "ResourceVideos");

      this.IsLoading = false;
      this.SearchBy.PageIndex = 0;
      this.SearchBy.PageSize = 10;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  //Create ResourceVideos form and returns {FormGroup}
  createResourceVideosFilterForm(): FormGroup {
    return this.formBuilder.group({
      // AcademicYearId: new FormControl(),
      Status: new FormControl()
    });
  }

  onLoadResourceVideosByFilters(): any {
    this.SearchBy.PageIndex = 0;
    this.onLoadResourceVideosByCriteria();
  }

  resetFilters(): void {
    this.SearchBy.PageIndex = 0;
    this.ResourceVideosFilterForm.reset();
    //this.schoolFilterForm.get('AcademicYearId').setValue(this.AcademicYearId);

    this.onLoadResourceVideosByCriteria();
  }



}
