import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { RouteConstants } from 'app/constants/route.constant'
import { SchoolVTPVCMappingService } from '../school-vtp-vc-mapping.service';
import { SchoolVTPVCMappingModel } from '../school-vtp-vc-mapping.model';
import { DropdownModel } from 'app/models/dropdown.model';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'school-vtp-vc-mapping',
  templateUrl: './create-school-vtp-vc-mapping.component.html',
  styleUrls: ['./create-school-vtp-vc-mapping.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CreateSchoolVTPVCMappingComponent extends BaseComponent<SchoolVTPVCMappingModel> implements OnInit {
  schoolVTPVCMappingForm: FormGroup;
  schoolVTPVCMappingModel: SchoolVTPVCMappingModel;
  academicyearList: [DropdownModel];
  phaseList: [DropdownModel];
  sectorList: [DropdownModel];
  jobRoleList: [DropdownModel];
  schoolsList: [DropdownModel];
  roleList: [DropdownModel];
  vtpList: [DropdownModel];
  vocationalCoordinatorList: [DropdownModel];
  statecodeList: [DropdownModel];
  remarkText: string;
  filteredSchoolJobRoleItems: any;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private route: ActivatedRoute,
    private schoolVTPVCMappingService: SchoolVTPVCMappingService,
    private dialogService: DialogService,
    private formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Set the default sectorJobRole Model
    this.schoolVTPVCMappingModel = new SchoolVTPVCMappingModel();
  }

  ngOnInit(): void {
   
    this.schoolVTPVCMappingService.getDropdownforSectorJobRole(this.UserModel).subscribe((response: any) => {
      if (response[0].Success) {
        this.sectorList = response[0].Results;
      }
      if (response[1].Success) {
        this.schoolsList = response[1].Results;
      }
      if (response[2].Success) {
        this.roleList = response[2].Results;
        this.filteredSchoolJobRoleItems = this.roleList.slice();
      }
      if (response[3].Success) {
        this.academicyearList = response[3].Results;
      }
      if (response[4].Success) {
        this.vtpList = response[4].Results;
      }
      if (response[5].Success) {
        this.statecodeList = response[5].Results;
        for (const i of response[5].Results) {
          this.schoolVTPVCMappingForm.get('StateCode').setValue(i.Id);
        }
      }
     

      this.route.paramMap.subscribe(params => {
        if (params.keys.length > 0) {
          this.PageRights.ActionType = params.get('actionType');

          if (this.PageRights.ActionType == this.Constants.Actions.New) {
            this.schoolVTPVCMappingModel = new SchoolVTPVCMappingModel();

          } else {
            var schoolVTPVCId: string = params.get('SchoolVTPVCId')
            
            this.schoolVTPVCMappingService.getSchoolVTPVCMappingById(schoolVTPVCId)
              .subscribe((response: any) => {
                this.schoolVTPVCMappingModel = response.Result;
             

                
                if (this.PageRights.ActionType == this.Constants.Actions.Edit)
                  this.schoolVTPVCMappingModel.RequestType = this.Constants.PageType.Edit;
                else if (this.PageRights.ActionType == this.Constants.Actions.View) {
                  this.schoolVTPVCMappingModel.RequestType = this.Constants.PageType.View;
                  this.PageRights.IsReadOnly = true;
                }
                if (this.schoolVTPVCMappingModel.DateOfDeAllocation != null) {
                  this.schoolVTPVCMappingForm.get("DateOfDeAllocation").setValue(this.getDateValue(this.schoolVTPVCMappingModel.DateOfDeAllocation));
                  let dateOfResignationCtrl = this.schoolVTPVCMappingForm.get("DateOfDeAllocation");
                  this.onChangeDateEnableDisableCheckBox(this.schoolVTPVCMappingForm, dateOfResignationCtrl);
                }
                this.onChangeVT(this.schoolVTPVCMappingModel.SchoolSectorJobRolesId);
                this.onChangeVTP(this.schoolVTPVCMappingModel.SchoolSectorJobRolesVTPId);
                this.schoolVTPVCMappingForm = this.createSchoolVTPVCMappingForm();
              });
          }
        }
      });
    });
    this.schoolVTPVCMappingForm = this.createSchoolVTPVCMappingForm();
  }
  onChangeVT(ssjbId){
    this.commonService.GetMasterDataByType({ DataType: 'VTPForSchoolSectorJobRoles', ParentId: ssjbId, SelectTitle: 'Vocational Training Provider' }).subscribe((response) => {
      if (response.Success) {
        this.vtpList = response.Results;
      }
    })
  }
  onChangeVTP(vtpId) {
    // this.commonService.GetMasterDataByType({ DataType: 'VocationalCoordinatorsByUserId', ParentId: vtpId, SelectTitle: 'Vocational Coordinator' }).subscribe((response) => {
    //   if (response.Success) {
    //     this.vocationalCoordinatorList = response.Results;
    //   }
    // });
    this.commonService.GetMasterDataByType({ DataType: 'VocationalCoordinatorsByUserId', ParentId: vtpId,UserId: this.UserModel.LoginId, SelectTitle: 'Vocational Coordinator' }).subscribe((response) => {
      if (response.Success) {
  
        this.vocationalCoordinatorList = response.Results;


      }
    });

  }
  status(item:any){
    if(item == 'SchoolSectorJobRoles'){
      this.schoolVTPVCMappingForm.patchValue( {'SchoolSectorJobRolesVTPId':null} );
    }else if(item == 'VTP'){
      this.schoolVTPVCMappingForm.patchValue( {'VCId':null} );
    }
  }
  saveOrUpdateSchoolVTPVCMappingDetails() {
    if (!this.schoolVTPVCMappingForm.valid) {
      this.validateAllFormFields(this.schoolVTPVCMappingForm);
      return;
    }
    this.setValueFromFormGroup(this.schoolVTPVCMappingForm, this.schoolVTPVCMappingModel);
    // this.schoolVTPVCMappingModel.Remarks = this.remarkText;

    this.schoolVTPVCMappingService.createOrUpdateSchoolVTPVCMapping(this.schoolVTPVCMappingModel)
      .subscribe((schoolVTPVCMappingResp: any) => {

        if (schoolVTPVCMappingResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );

            this.router.navigate([RouteConstants.SchoolVTPVCMapping.List]);
          });
        }
        else {
          var errorMessages = this.getHtmlMessage(schoolVTPVCMappingResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('SchoolVTPVCMapping deletion errors =>', error);
      });
  }

  onChangeSector(evt) {

    this.commonService
      .GetMasterDataByType({ DataType: 'JobRoles', ParentId: evt.value })
      .subscribe((response: any) => {

        if (response.Success) {
          this.jobRoleList = response.Results;
          var sectorItem = this.sectorList.find(x => x.Id == evt.value);
          var jobRoleItem = this.jobRoleList.find(x => x.Id == this.schoolVTPVCMappingForm.get('JobRoleId').value);
          this.remarkText = sectorItem.Name + (jobRoleItem == null ? '' : '-' + jobRoleItem.Name);
          this.schoolVTPVCMappingForm.get('Remarks').setValue(this.remarkText);
        }
      });
  }
  
  showOptions(event:MatCheckboxChange): void {
    if(event.checked == false){
      this.dialogService
      .openConfirmDialog(this.Constants.Messages.IsactiveConfirmationMessage)
      .afterClosed()
      .subscribe(confResp => {
        if (confResp == true) {
          console.log(event.checked);
          if (event.checked == false) {
            var currentDate = new Date();
            this.schoolVTPVCMappingForm.get('DateOfDeAllocation').setValue(this.getDateValue(currentDate));
  
          } else {
            this.schoolVTPVCMappingForm.get('DateOfDeAllocation').setValue('');
          }
        } else {
          this.onChangeDateEnableDisableCheckBox(this.schoolVTPVCMappingForm, event.checked);
          this.schoolVTPVCMappingForm.get('DateOfDeAllocation').setValue('');
  
        }
      });

    }else{
      this.schoolVTPVCMappingForm.get('DateOfDeAllocation').setValue('');
      
    }
 
  }

  onChangeJobRole(evt) {
    var sectorItem = this.sectorList.find(x => x.Id == this.schoolVTPVCMappingForm.get('SectorId').value);
    var jobRoleItem = this.jobRoleList.find(x => x.Id == evt.value);
    this.remarkText = (sectorItem == null ? '' : sectorItem.Name + '-') + jobRoleItem.Name;
    this.schoolVTPVCMappingForm.get('Remarks').setValue(this.remarkText);
  }

  //Create sectorJobRole form and returns {FormGroup}
  createSchoolVTPVCMappingForm(): FormGroup {
    return this.formBuilder.group({
      SchoolVTPVCId: new FormControl(this.schoolVTPVCMappingModel.SchoolVTPVCId),
      SchoolSectorJobRolesVTPId: new FormControl({ value: this.schoolVTPVCMappingModel.SchoolSectorJobRolesVTPId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      VCId: new FormControl({ value: this.schoolVTPVCMappingModel.VCId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      SchoolSectorJobRolesId: new FormControl({ value: this.schoolVTPVCMappingModel.SchoolSectorJobRolesId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      AcademicYearId: new FormControl({ value: this.schoolVTPVCMappingModel.AcademicYearId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      DateOfAllocation: new FormControl({ value: new Date(this.schoolVTPVCMappingModel.DateOfAllocation), disabled: this.PageRights.IsReadOnly }, Validators.required), 
      DateOfDeAllocation: new FormControl({ value: this.getDateValue(this.schoolVTPVCMappingModel.DateOfDeAllocation), disabled: this.PageRights.IsReadOnly }), 
      Remarks: new FormControl({ value: this.schoolVTPVCMappingModel.Remarks, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(350)),
      IsActive: new FormControl({ value: this.schoolVTPVCMappingModel.IsActive, disabled: this.PageRights.IsReadOnly }),
      StateCode: new FormControl({ value: this.schoolVTPVCMappingModel.StateCode, disabled: true }, Validators.required),
      DateOfResignation: new FormControl({ value: this.schoolVTPVCMappingModel.DateOfResignation }),


    });
  }
}
