import { FuseUtils } from '@fuse/utils';

export class SchoolVTMappingModel {
    SchoolSectorJobRolesVTPId: string;
    SchoolVTPVCId:string;
    VTPId: string;
    VCId: string;
    VTId: string;
    SchoolSectorJobRolesId:string;
    StateCode: string; // New Parameters
    
    AcademicYearId: string;
    DateOfAllocation: Date;
    DateOfDeAllocation:Date
    Remarks: string;    
    IsActive: boolean;
    RequestType: any;

    constructor(schoolVTPVCMappingItem?: any) {
        schoolVTPVCMappingItem = schoolVTPVCMappingItem || {};

        this.SchoolSectorJobRolesVTPId = schoolVTPVCMappingItem.SchoolSectorJobRolesVTPId || '';
        this.SchoolVTPVCId = schoolVTPVCMappingItem.SchoolVTPVCId || '';
        this.VTPId = schoolVTPVCMappingItem.VTPId || '';
        this.VCId = schoolVTPVCMappingItem.VCId || '';
        this.VTId = schoolVTPVCMappingItem.VTId || '';
        this.SchoolSectorJobRolesId = schoolVTPVCMappingItem.SchoolSectorJobRolesId || '';
        this.AcademicYearId = schoolVTPVCMappingItem.AcademicYearId || '';
        this.DateOfAllocation = schoolVTPVCMappingItem.DateOfAllocation || '';
        this.DateOfDeAllocation = schoolVTPVCMappingItem.DateOfDeAllocation || '';
        this.Remarks = schoolVTPVCMappingItem.Remarks || '';
        this.StateCode = schoolVTPVCMappingItem.StateCode || '';
        this.IsActive = schoolVTPVCMappingItem.IsActive || true;
        this.RequestType = 0; // New
    }
}
