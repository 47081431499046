<div id="school" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <!-- {{ 'SCHOOL.SCHOOLS' | translate }} -->
                    Interface
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0" style=" width: 100%;
        max-width: 480px;
        border-radius: 28px;
        overflow: hidden;background-color: white;">
                <!-- <form [formGroup]="StudentNoticeBoardSearchForm">
                    <div class="search" style=" width: 100%;
                height: 48px;
                line-height: 48px;
                padding: 0 18px;" fxFlex fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon>search</mat-icon>
                        <input #filter (keyup)="applySearchFilter($event.target.value)"  formControlName="filterText" placeholder="Search for a student">
                        <input #filter (keyup)="applySearchFilterStudent($event.target.value)" placeholder="Search"
                            style=" width: 100%;
                        height: 48px;
                        min-height: 48px;
                        max-height: 48px;
                        padding: 0 16px;
                        border: none;
                        outline: none;">
                    </div>
                </form> -->
            </div>
            <!-- / SEARCH -->
            <!-- ADD BUTTON -->
            <div class="action-buttons">


                <button mat-raised-button [routerLink]="['./','new']"
                    class="add-sector-job-role-button fuse-white mt-24 mt-md-0 btnC" style="display: none;">
                    <span>{{'SCHOOL.ADD_NEW_SCHOOL' | translate}}</span>
                </button>

                <a *ngIf="PageRights.IsExport" class="export-excel" (click)="exportExcel()" style="display: none;">
                    <mat-icon> cloud_download</mat-icon>
                </a>
            </div>
            <!-- / ADD BUTTON -->
        </div>
        <!-- / HEADER -->



        <!-- CONTENT CARD -->
        <div class="content-card rounded-12 shadow-dark-80" style="overflow: auto;">
            <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start"
                style="height: auto !important;">
                <div style="width:100%;border-radius: 18px;border: 1px solid #dee2e6;padding: 20px;margin: 15px;float: left;overflow-x: auto;
                    word-wrap: break-word;">
                    <div style="margin: 5px 0; "> <span><b>State Code: </b></span><span>{{StateName}}</span></div>
                    <div style="margin: 5px 0;"> <span><b>Last date: </b></span><span>{{currentDate | date:'dd/MM/yyyy'}}</span></div>
                    <div style="margin: 5px 0;"> <span class="note" style="color:red">Note: </span>All the data added after {{currentDate | date:'dd/MM/yyyy'}}, would be pulled from LH for State {{StateName}}</div>
                    <div>
                        <button mat-raised-button class="fuse-white filter-button"
                            (click)="onClickStartSyncingData()"><span>START</span>
                        </button>
                    </div>

                </div>
            </div>

            <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" style="height: auto !important;"
                
                style="border-radius: 18px;border: 1px solid #dee2e6;padding: 20px;margin: 15px;float: left;word-wrap: break-word;">
                <div  *ngIf="interfaceForm" name="interfaceForm" [formGroup]="interfaceForm" >
                    <!-- <form name="interfaceForm" [formGroup]="interfaceForm" > -->
                        <div >
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>Select School</mat-label>
                                <mat-select formControlName="SchoolName" multiple appearance="outline"
                                    [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeSchool($event.value)">
                                    <mat-option *ngFor="let schoolItem of schoolList;"
                                        [value]="schoolItem.SchoolId" [disabled]="schoolItem.IsDisabled">
                                        {{ schoolItem.SchoolName }}
                                    </mat-option>
                                </mat-select>

                            </mat-form-field>
                        </div>
                        <div>
                            <button (click)="downloadTxtFile()" mat-raised-button class="fuse-white filter-button"><span>Import Status</span>
                            </button>
                        </div>
                    <!-- </form> -->
                </div>
            </div>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>