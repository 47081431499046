import { Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { BaseComponent } from 'app/common/base/base.component';
import { environment } from 'environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { ChangePasswordService } from 'app/auth/change-password/change-password.service';
import { FuseUtils } from '@fuse/utils';
import { HttpUrlEncodingCodec } from '@angular/common/http';

@Component({
  selector: 'forgot-password-new-pass',
  templateUrl: './forgot-password-new-pass.component.html',
  styleUrls: ['./forgot-password-new-pass.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ForgotPasswordNewPassComponent extends BaseComponent<any> implements OnInit {
  changePasswordForm: FormGroup;
  hideConfirmPassword: boolean = true;
  UserTypeId:any

  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private dialogService: DialogService,
    private accountService: ChangePasswordService,
    private fuseConfigService: FuseConfigService,
    private formBuilder: FormBuilder
  ) {
    super(commonService, router, routeParams, snackBar);

    // Configure the layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
  }

  ngOnInit(): void {
    this.changePasswordForm = this.createChangePasswordForm();
    this.UserTypeId=JSON.parse(localStorage.getItem('UserTypeId'));
  }

  saveOrUpdateChangePasswordDetails() {
    let NewPassword = this.changePasswordForm.get('NewPassword').value;
    let ConfirmPassword = this.changePasswordForm.get('ConfirmPassword').value;
    let data={
      UserId :this.UserTypeId.UserTypeId,
      ConfirmPassword:ConfirmPassword,
      NewPassword:NewPassword
    }
    let data1={
      AuthToken :this.UserTypeId.AuthToken
    }
    this.accountService.UpdatePassword(data, data1)
      .subscribe((accountResp: any) => {

        if (accountResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.PasswordChangeMessage,
              this.Constants.Html.SuccessSnackbar
            );

            this.router.navigateByUrl(RouteConstants.Login);
            localStorage.clear()

          });
        }
        else {
          var errorMessages = this.getHtmlMessage(accountResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('change password errors =>', error);
      });
  }

  setVisibleConfirmPassword(): void {
    this.hideConfirmPassword = !this.hideConfirmPassword;
  }

  //Create account form and returns {FormGroup}
  createChangePasswordForm(): FormGroup {
    return this.formBuilder.group({
      NewPassword: new FormControl({ value: '', disabled: false}, [Validators.required, Validators.pattern(this.Constants.Regex.Password)]),
      ConfirmPassword: new FormControl({ value: '',  disabled: false}, [Validators.required, Validators.pattern(this.Constants.Regex.Password)])
    }, { validator: this.checkIfMatchingPasswords('NewPassword', 'ConfirmPassword') });
  }
}
