<div id="resource-video" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/resource-study-material'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        {{ resourceVideosModel.ResourceTitle }}
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        New Resource Study Material
                    </div>
                    <div class="subtitle secondary-text">
                        <span>Resource Study Material Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-head-master-button" *ngIf="PageRights.ActionType ==='new'"
                (click)="saveOrUpdateResourceStudyMaterialDetails()">
                <span>ADD</span>
            </button>

            <button mat-raised-button class="save-head-master-button" *ngIf="PageRights.ActionType ==='edit'"
                (click)="saveOrUpdateResourceStudyMaterialDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="resourceStudyMaterialForm" [formGroup]="resourceStudyMaterialForm" class="head-master w-100-p p-24 mr-24"
                    fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex fusePerfectScrollbar>
                    <!-- <mat-tab label="Basic Info"> -->
                    <!-- <div class="tab-content p-24" fusePerfectScrollbar> -->
                    <div class="required-mark">
                        <span><span class="note">Note: </span>All fields marked with an asterisk (<span
                                class="asterisk-color">*</span>) are required.</span>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Title</mat-label>
                            <input matInput name="resourceTitle" formControlName="ResourceTitle" required
                                placeholder="Ex. Title" matTooltip="Resource Video Title" matTooltipPosition="above">
                            <mat-error *ngIf="resourceStudyMaterialForm.controls.ResourceTitle.invalid">
                                {{getErrorMessage(resourceStudyMaterialForm, 'ResourceTitle')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Select Category</mat-label>

                            <mat-select formControlName="ResourceCategoryId" appearance="outline"
                                [disabled]="PageRights.IsReadOnly" required #VideoCategory>
                                <mat-select-filter class="select-filter" *ngIf="VideoCategory.focused"
                                    [array]="sectorList" (filteredReturn)="this.filteredSectorListItems = $event"
                                    [displayMember]="'Name'"></mat-select-filter>
                                <mat-option [value]="categoryItem.Id"
                                    *ngFor="let categoryItem of filteredSectorListItems">
                                    {{ categoryItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="resourceStudyMaterialForm.controls.ResourceCategoryId.invalid">
                                {{getErrorMessage(resourceStudyMaterialForm, 'ResourceCategoryId')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Select Sub-Category</mat-label>
                            <mat-select formControlName="ResourceSubcategoryId" appearance="outline"
                                [disabled]="PageRights.IsReadOnly" #VideoSubCategory>
                                <mat-select-filter class="select-filter" *ngIf="VideoSubCategory.focused"
                                    [array]="categoryList" (filteredReturn)="this.filteredSubCategoryItems = $event"
                                    [displayMember]="'Name'"></mat-select-filter>
                                <mat-option [value]="SubCategoryItem.Id"
                                    *ngFor="let SubCategoryItem of filteredSubCategoryItems">
                                    {{ SubCategoryItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="resourceStudyMaterialForm.controls.ResourceSubcategoryId.invalid">
                                {{getErrorMessage(resourceStudyMaterialForm, 'ResourceSubcategoryId')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Published Date</mat-label>
                            <input matInput [matDatepicker]="picker1" name="publishDate" formControlName="PublishDate"
                                [max]="CurrentDate" [disabled]="PageRights.IsReadOnly" required>
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                            <mat-error *ngIf="resourceStudyMaterialForm.controls.PublishDate.invalid">
                                {{getErrorMessage(resourceStudyMaterialForm, 'PublishDate')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Upload Study Material</mat-label>
                            <ngx-mat-file-input  name="ResourceLink" formControlName="ResourceLink"
                                [accept]="" [disabled]="PageRights.IsReadOnly"
                                (change)="uploadStudyMaterialFilePath($event)" required>
                            </ngx-mat-file-input>
                            <mat-icon matSuffix>folder</mat-icon>
                            <span *ngIf="imageShow  == true ">{{uploadedFile}}</span>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Resource Description</mat-label>
                            <textarea matInput formControlName="ResourceDescription" required></textarea>
                            <mat-error *ngIf="resourceStudyMaterialForm.controls.ResourceDescription.invalid">
                                {{getErrorMessage(resourceStudyMaterialForm, 'ResourceDescription')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <span class="checkbox-space">Is Active?</span>
                        <mat-checkbox matInput formControlName="IsActive" [disabled]="PageRights.IsReadOnly">
                        </mat-checkbox>
                    </div>



                    <!-- <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <span class="checkbox-space">Is Active?</span>
                        <mat-checkbox matInput formControlName="IsActive" [disabled]="PageRights.IsReadOnly">
                        </mat-checkbox>
                    </div> -->
                    <!-- </div> -->
                    <!-- </mat-tab>
                    </mat-tab-group> -->
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>