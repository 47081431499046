import { DatePipe } from '@angular/common';
import { Component, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { BaseComponent } from 'app/common/base/base.component';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { SearchFilterModel } from 'app/models/search.filter.model';
import { CommonService } from 'app/services/common.service';
import { environment } from 'environments/environment';
import { DiaryEntry } from '../diary-entry/diary-entry.model';
import { InternshipApproval } from '../internship-approval/internship-approval.model';
import { InternshipApprovalService } from '../internship-approval/internship-approval.service';
import { InternshipDetails } from './internship-details.model';
import { InternshipDetailsService } from './internship-details.service';
import { ViewInternshipDetailsComponent } from './view-internship-details/view-internship-details.component';
import { RouteConstants } from 'app/constants/route.constant';

@Component({
  selector: 'app-internship-deatils',
  templateUrl: './internship-deatils.component.html',
  styleUrls: ['./internship-deatils.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class InternshipDeatilsComponent extends BaseComponent<InternshipDetails> implements OnInit {
  internshipDetailsForm: FormGroup;
  internshipDetailsModel: InternshipDetails;
  data: any;
  SearchBy: SearchFilterModel;
  activeInternData: any;
  imageBasePath: string;
  ProfileImageBaseURL: string;
  tableDataSource: any;
  ListPaginator: any;
  IsLoading: boolean;
  displayedColumns: string[];
  ListSort: any;
  dailyDiaryDisplayedColumns: string[];
  dailyDiaryDataSource: any;
  fromDate: any;
  toDate: any;
  today = new Date();
  displayedColumns1: string[];
  dataSource: MatTableDataSource<InternshipApproval>;
  isLoading: boolean = true;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  avengers = [] as any;
  progressData: any;
  InternshipId: string;
  SelectedStudents: number;
  finalArray: (DiaryEntry | GroupByDateArray)[] = [];
  listData: boolean = false;
  // single: any[];
  view: any[] = [700, 400];

  // options
  gradient: boolean = true;
  showLegend: boolean = false;
  showLabels: boolean = false;
  isDoughnut: boolean = false;

  colorScheme = {
    // domain: ['#A10A28', '#5AA454', '#C7B42C', '#AAAAAA'],
    domain: ['rgb(168, 203, 254)', 'rgb(0, 143, 251)'],
  };

  // single = [];
  single = [
    // {
    //   name: 'Total Hrs',
    //   value: 68
    // },
    // {
    //   name: 'Actual Hrs',
    //   value: 12
    // }
    // {
    //   "name": "France",
    //   "value": 7200000
    // },
    //   {
    //   "name": "UK",
    //   "value": 6200000
    // }
  ];

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private route: ActivatedRoute,
    private internshipDetailsService: InternshipDetailsService,
    private internshipApprovalService: InternshipApprovalService,
    private dialogService: DialogService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private datePipe: DatePipe) {
    super(commonService, router, routeParams, snackBar);
    this.SearchBy = new SearchFilterModel(this.UserModel);
    // Set the default school Model
    this.internshipDetailsModel = new InternshipDetails();

  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
  ngOnInit(): void {
    this.imageBasePath =this.Constants.CommonImageUrl.Attachments;
    this.ProfileImageBaseURL = this.Constants.CommonImageUrl.Account;

    // this.imageBasePath = environment.DailyDiartyImageBaseURL;
    // this.ProfileImageBaseURL = environment.ProfileImageBaseURL;
    this.route.paramMap.subscribe(params => {
      if (params.keys.length > 0) {
        this.InternshipId = params.get('internshipId');
      }
    });
    //eaeeaaee-140b-4af8-bade-679404fd3792 //'eaeeaaee-140b-4af8-bade-679404fd3794'
    this.internshipDetailsService.getInternshipById(this.InternshipId).subscribe(response => {
      if (response.Success) {
        this.data = response.Result;
        if (this.data != null && this.data != undefined) {
          this.LoadActiveInterns();
          this.loadInternshipApplicationStatus();
        }



      } else {
        this.zone.run(() => {
          this.showActionMessage(
            "No Intern data...",
            this.Constants.Html.SuccessSnackbar
          );

        });
      }

    });

  }

  LoadActiveInterns(): any {
    this.IsLoading = true;
    this.SearchBy.UserId = this.UserModel.UserId;//'rakesh.gtmcs';
    this.SearchBy.UserTypeId = this.UserModel.UserTypeId;// '3fa85f64-5717-4562-b3fc-2c963f66afa6';
    this.SearchBy.Name = null;
    this.SearchBy.CharBy = null;
    this.SearchBy.RequestFrom = null;
    this.SearchBy.PageIndex = 0;
    this.SearchBy.PageSize = 10;
    this.SearchBy.SchoolGuid = null;
    this.SearchBy.AcademicYearGuid = null;
    this.SearchBy.InternshipGuid = this.data.InternshipId;
    this.SearchBy.SectorGuid = null;
    this.SearchBy.EmployerGuid = null;
    this.SearchBy.ClassGuid = null;
    this.SearchBy.StartDateTime = null;
    this.SearchBy.EndDatetime = null;
    this.SearchBy.IgnoreCriteria = true;
    this.internshipDetailsService.getActiveInterns(this.SearchBy).subscribe(response => {
      // this.displayedColumns = ['Title', 'PublishDate', 'StartDate', 'NoOfOpenings', 'IsCompleted', 'IsPublished', 'Actions'];
      console.log(response.Results);
      this.activeInternData = response.Results;
      this.SelectedStudents = this.activeInternData.length;
      this.avengers.push(this.activeInternData);
      console.log('Avengers data =' + this.avengers);
      this.IsLoading = false;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  onPageIndexChanged(evt) {
    this.SearchBy.PageIndex = evt.pageIndex;
    this.SearchBy.PageSize = evt.pageSize;

    this.loadInternshipApplicationStatus();
  }
  loadInternshipApplicationStatus(): any {

    this.IsLoading = true;
    this.SearchBy.SchoolGuid = (this.Constants.GlobalSchoolId == 'undefined' || this.Constants.GlobalSchoolId == 'null') ? null : this.Constants.GlobalSchoolId;
    this.SearchBy.AcademicYearGuid = (this.Constants.GlobalAcademicYearId == 'undefined' || this.Constants.GlobalAcademicYearId == 'null') ? null : this.Constants.GlobalAcademicYearId;
    this.SearchBy.SectorGuid = this.data.SectorId;
    this.SearchBy.EmployerGuid = this.data.EmployerId;
    // this.SearchBy.StartDateTime = this.fromDate == 'undefined' ? '' : this.fromDate;//"2020-01-08";
    // this.SearchBy.EndDatetime = this.toDate == 'undefined' ? '' : this.toDate;//"2022-08-08";
    // this.SearchBy.PageIndex = 1;
    this.internshipApprovalService.GetAllByCriteria(this.SearchBy).subscribe(response => {
      this.tableDataSource = new MatTableDataSource<InternshipApproval>();
      //@ts-ignore
      this.tableDataSource.data = response.Results.filter(x => x.IsApplied == true && x.IsPlaced == false && x.IsApprovedByVT == false && x.IsApprovedByEmployer == false);
      this.displayedColumns = ['StudentName', 'InternshipStartDate', 'Actions'];
      this.dataSource = new MatTableDataSource<InternshipApproval>();
      this.displayedColumns1 = ['StudentName', 'Status', 'Actions'];
      //this.dataSource.data = response.Results.filter(x => x.IsApplied == true && x.IsPlaced == false && x.IsApprovedByVT == true);

      this.dataSource.data = response.Results.filter(x => x.IsApplied == true && x.IsApprovedByVT == true);

      this.zone.run(() => {
        //@ts-ignore
        if (response.Results.length < 1) {
          //@ts-ignore
          this.snackBar.open("No data found..", "Dismiss", {
            duration: 5000,
            panelClass: ["info-snackbar"]
          });
        }
      });
      this.isLoading = false;
      this.tableDataSource.paginator = this.paginator;
      this.tableDataSource.sort = this.sort;
    });
  }

  GetStudentDataforAccordian(studentId: string) {
    // this.internshipDetailsService.getInternProgressDashBoard('fee96add-e921-470e-b57b-7f009c63a360', 'eaeeaaee-140b-4af8-bade-679404fd3794').subscribe(response => {


    this.loadStudentProgressData(studentId)
    this.loadDailyDiaryEntries(studentId,);

  }

  loadStudentProgressData(studentId: string) {
    this.internshipDetailsService.getInternProgressDashBoard(studentId, this.data.InternshipId).subscribe(response => {
      let data = response.Results;
      this.single = [];
      if (data.length > 0) {
        data.forEach(element => {
          this.progressData = {
            Points: element.StudentPoints,
            Attendance: element.Attendance,
            Hours: element.Hours
          }
          console.log(this.progressData);
          if (Number(element.Hours) > 0 || Number(this.progressData.Hours.split(':')[0]) > 0) {
            var hh = element.Hours.split(":")[0];
            var mm = element.Hours.split(":")[1];
            let time = Number(hh) * 60 + Number(mm);
            let spentTime = time / 60;

            let TotalHrs: number = 80;
            let ActualHrs = TotalHrs - spentTime;


            this.single.push({
              name: 'Total Hours',
              value: ActualHrs
            });
            // var xxx = element.Hours.substring(0, 5);
            // var yyy = xxx.replace(':', ".");
            this.single.push({
              name: 'Actual Hours Spent',
              value: spentTime
            });
            console.log(this.single);
          } else {
            this.single.push({
              name: 'Total Hours',
              value: 80
            });
          }
        });
      }
    });
  }

  loadDailyDiaryEntries(studentId: string): any {
    this.IsLoading = true;
    let DiaryEntryStudentDetails = {
      StudentID: studentId,
      InternshipId: this.data.InternshipId,
      StartDateTime: this.data.StartDate.replaceAll('/', '-').split(' ')[0],//'2022-08-01',
      EndDatetime: this.datePipe.transform(this.today, 'yyyy-MM-dd')//'2022-08-01'
    }

    this.internshipDetailsService.getDailyDiary(DiaryEntryStudentDetails).subscribe(response => {
      this.dailyDiaryDisplayedColumns = ['LessonsLearnt', 'Hours', 'Actions'];
      let rowdata: any;
      rowdata = response.Results;

      let object = [] as any;
      if (rowdata != null && rowdata != '') {

        for (let i = 0; i < rowdata.length; i++) {
          if (rowdata[i].listEntries.length > 0) {
            var attatchments = rowdata[i].listAttachments;
            rowdata[i].listEntries.forEach(element => {
              element.Attachments = attatchments;
              object.push(element);
            });
            console.log(object);
          } else {
            console.log('listEntries not available');
          }

        }

        const dateArray: GroupByDateArray[] = [];
        for (let k = 0; k < object.length; k++) {
          let xxx = dateArray.filter(x => x.date == object[k].EntryDate);
          if (xxx == null || xxx.length == 0) {
            dateArray.push({
              date: object[k].EntryDate,
              isGroupBy: true
            })
          }
        }
        let soretdDateArray = dateArray.sort((a, b) => a.date > b.date ? -1 : 1);
        // const finalArray: (DiaryEntry | GroupByDateArray)[] = [];
        for (let i = 0; i < soretdDateArray.length; i++) {
          let yyd = soretdDateArray.filter(x => x.date == soretdDateArray[i].date);
          if (yyd != null) {
            yyd.forEach(element => {
              this.finalArray.push({
                date: element.date,
                isGroupBy: true
              });
            });
          }
          let asd = object.filter(x => x.EntryDate == soretdDateArray[i].date);
          if (asd != null || asd != '') {
            asd.forEach(element => {
              this.finalArray.push(element);
            });
          }
        }
        console.log(this.finalArray);
        let xxxx = [];
        // for (let j = 0; j < this.finalArray.length; j++) {
        //   if (j <= 3) {
        //     xxxx.push(j);
        //   }
        // }
        let k: number = 0;
        this.finalArray.forEach(element => {
          if (k <= 3) {
            xxxx.push(element);
          }
          k++;
        });
        console.log(xxxx);

        if (xxxx.length > 0) { this.listData = true; }
        // this.dailyDiaryDataSource = this.finalArray;
        this.dailyDiaryDataSource = xxxx;
      }


      // this.dailyDiaryDataSource.data = response.Results;
      this.dailyDiaryDataSource.sort = this.ListSort;
      this.dailyDiaryDataSource.paginator = this.ListPaginator;
      this.dailyDiaryDataSource.filteredData = this.dailyDiaryDataSource;
      this.SearchBy.TotalResults = response.TotalResults;
      // setTimeout(() => {
      //   this.ListPaginator.pageIndex = this.SearchBy.PageIndex;
      //   this.ListPaginator.length = this.SearchBy.TotalResults;
      // });

      // this.zone.run(() => {
      //   if (this.dailyDiaryDataSource.data.length == 0) {
      //     this.showNoDataFoundSnackBar();
      //   }
      // });
      this.IsLoading = false;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }
  onChangeDuration(evt) {

    let duration = evt;
    switch (duration) {

      case 1:

        let todaysDate = this.datePipe.transform(this.today, 'yyyy-MM-dd');
        this.fromDate = todaysDate;
        this.toDate = todaysDate;
        console.log("Today", this.fromDate, this.toDate);
        break;
      case 2:
        var tdate = new Date();
        tdate.setDate(tdate.getDate() - 1);
        let yesterdaysDate = this.datePipe.transform(tdate, 'yyyy-MM-dd');
        this.fromDate = yesterdaysDate;
        this.toDate = yesterdaysDate;
        console.log("Yesterday", this.fromDate, this.toDate);
        break;
      case 3:
        let wdate: Date = new Date();
        wdate.setDate(wdate.getDate() - 6);
        // date.setHours(0);
        // date.setMinutes(0);
        // date.setSeconds(0);
        // date.setMilliseconds(0);
        // let datePipe: DatePipe = new DatePipe('en-US');
        // console.log(datePipe.transform(date, 'short'));
        this.fromDate = this.datePipe.transform(wdate, 'yyyy-MM-dd');
        this.toDate = this.datePipe.transform(this.today, 'yyyy-MM-dd');
        console.log("Last 7 days", this.fromDate, this.toDate);
        break;
      case 4:
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.fromDate = this.datePipe.transform(firstDay, 'yyyy-MM-dd');
        this.toDate = this.datePipe.transform(lastDay, 'yyyy-MM-dd');
        console.log("This Month", this.fromDate, this.toDate);
        break;
      case 5:
        const cdate = new Date();

        const firstDayPrevMonth = new Date(cdate.getFullYear(), cdate.getMonth() - 1, 1);
        const lastDayPrevMonth = new Date(cdate.getFullYear(), cdate.getMonth(), 0);
        this.fromDate = this.datePipe.transform(firstDayPrevMonth, 'yyyy-MM-dd');
        this.toDate = this.datePipe.transform(lastDayPrevMonth, 'yyyy-MM-dd');
        console.log("Last Month", this.fromDate, this.toDate);
        break;
      default:
        console.log("Invalid");
    }

  }
  onClickHireStudent(studentId: string, internshipId: string) {
    this.internshipApprovalService.hireStudent(studentId, internshipId).subscribe(response => {
      if (response.Success) {
        this.zone.run(() => {
          this.showActionMessage(
            response.Messages[0],
            this.Constants.Html.SuccessSnackbar
          );
          // this.onLoadInternshipApprovalByCriteria();
        });
      } else {
        this.zone.run(() => {
          this.showActionMessage(
            response.Messages[0],
            this.Constants.Html.ErrorSnackbar,
          );
          // this.onLoadInternshipApprovalByCriteria();
        });
      }

    });
  }

  onClickViewStudentDiaryEnteryDetails(studentName){
    localStorage.setItem('StudentName', studentName);
    this.router.navigate([RouteConstants.DiaryEntryVT.List]);
  }

  onClickSendToEmployer(studentId: string, internshipId: string) {

    this.internshipApprovalService.sendToEmployer(studentId, internshipId).subscribe(response => {
      if (response.Success) {
        this.zone.run(() => {
          this.showActionMessage(
            response.Messages[0],
            this.Constants.Html.SuccessSnackbar
          );
          // this.onLoadInternshipApprovalByCriteria();
        });
      } else {
        this.zone.run(() => {
          this.showActionMessage(
            response.Messages[0],
            this.Constants.Html.ErrorSnackbar,
          );
          // this.onLoadInternshipApprovalByCriteria();
        });
      }

    });
  }

  onClickViewInternshipDetails() {

    //  let viewData = this.dataxxxx.filter(x => x.StudentId == studentId && x.InternshipId == internshipId);
    let viewData = this.data;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.maxWidth = "80%";
    dialogConfig.width = "80%";
    //  dialogConfig.height="765px";
    dialogConfig.height = "auto";
    dialogConfig.data = viewData;//this.staffResulatData;//
    this.dialog.open(ViewInternshipDetailsComponent, dialogConfig);
  }

  isGroup(index, item): boolean {
    return item.isGroupBy;
  }
}

interface GroupByDateArray {
  date: string;
  isGroupBy: boolean;
}