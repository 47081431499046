import { Component, OnInit, NgZone, ViewEncapsulation, ElementRef } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { FuseUtils } from '@fuse/utils';


import { RouteConstants } from 'app/constants/route.constant'
import { EmployerListingService } from '../employer-listing.service';
import { EmployerListingModel, EmployerListingBranchModel, EmployerListingSectorsModel } from '../employer-listing.model';
import { DropdownModel } from 'app/models/dropdown.model';
import { FileUploadModel } from 'app/models/file.upload.model';
import { ReportService } from 'app/reports/report.service';
import { duration } from 'moment';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};


@Component({
  selector: 'employer-listing',
  templateUrl: './create-employer-listing.component.html',
  styleUrls: ['./create-employer-listing.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CreateEmployerListingComponent extends BaseListComponent<EmployerListingModel> implements OnInit {



  dataUploadForm: FormGroup;
  tabIndex = 0;
  genderList: [DropdownModel];
  sectorList: [DropdownModel];
  isAvailableUploadedExcel: boolean = false;
  uploadedFileUrl: string;
  fileUploadModel: FileUploadModel;

  employerForm: FormGroup;
  employerListingModel: EmployerListingModel;

  errorMessage: any;
  stateList: [DropdownModel];
  divisionList: DropdownModel[];
  districtList: DropdownModel[];

  branchTypeList = [{ Id: true, Name: 'Only Branch' }, { Id: false, Name: 'Multiple Branches' }];
  numberOfemployeesList = [{ Id: '0-1', Name: '0-1' }, { Id: '2-5', Name: '2-5' }, { Id: '6-10', Name: '6-10' }, { Id: '11-20', Name: '11-20' }, { Id: '20 Above', Name: '20 Above' }];
  employerListingBranchModel: EmployerListingBranchModel;
  employerListingSectorsModel: EmployerListingSectorsModel;

  EmployerListingBranchForm: FormGroup;
  EmployersSectorsForm: FormGroup;
  workLocationAction: string = 'add';
  workAction: string = 'add';

  EmpB: string = 'add'
  EmpS: string = 'add'
  currentWorkLocationIndex: number = 0;
  img: any
  ApiBaseUrlImage = "https://user-uat.pathways.net.in//Documents/Account/";
  mainBranchExist: boolean = false;
  editMode: boolean = false;
  isSingleBranch: boolean = false;
  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    public zone: NgZone,
    private route: ActivatedRoute,
    private reportService: ReportService,
    private employerListingService: EmployerListingService,
    private dialogService: DialogService,
    private elRef: ElementRef,
    private formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar, zone);

    // Set the default account Model
    this.employerListingModel = new EmployerListingModel();
    this.workLocationAction = 'add';
    this.workAction = 'add';
    this.EmpB == 'add';
    this.EmpS == 'add';
    this.employerListingBranchModel = new EmployerListingBranchModel();
    this.employerListingSectorsModel = new EmployerListingSectorsModel();
    this.fileUploadModel = new FileUploadModel();
    this.employerForm = this.createAccountForm();
    this.dataUploadForm = this.createDataUploadForm();

  }

  ngOnInit(): void {

    this.employerListingService.getDropdownforSchool(this.UserModel).subscribe((results) => {
      if (results[0].Success) {
        this.stateList = results[0].Results;

        for (let i of results[0].Results) {
          // this.employerForm.get('StateCode').patchValue(i.Id);
          let EmployerListingBranchFormGroup: any = this.employerForm.controls.EmployerListingBranchForm;
          EmployerListingBranchFormGroup.controls["StateCode"].setValue(i.Id);

          this.onChangeState(i.Id);
        }
      }


      if (results[1].Success) {
        this.genderList = results[1].Results;
      }
      if (results[2].Success) {
        this.sectorList = results[2].Results;
      }

      this.route.paramMap.subscribe(params => {
        if (params.keys.length > 0) {
          this.PageRights.ActionType = params.get('actionType');

          if (this.PageRights.ActionType == this.Constants.Actions.New) {
            this.employerListingModel = new EmployerListingModel();

          } else {
            var employerId: string = params.get('EmployerId');
            let data = {
              DataId: employerId,
            }

            this.employerListingService.GetEmployersById(data)
              .subscribe((response: any) => {
                this.employerListingModel = response.Result;

                this.onChangeBranchType(this.employerListingModel.IsSingleBranch);
                //  let i =response.Result.EmpPhotoUrl;
                //  this.img= this.ApiBaseUrlImage+ i;
                if (this.PageRights.ActionType == this.Constants.Actions.Edit) {
                  this.employerListingModel.RequestType = this.Constants.PageType.Edit;
                  debugger;
                  let xx = this.branchTypeList.filter(x => x.Id == true);
                  this.employerForm.controls["IsSingleBranch"].patchValue(xx);

                  // for (let i of this.stateList) {
                  //   // this.employerForm.get('StateCode').patchValue(i.Id);
                  //   // let xx = this.stateList.filter(x => x.Id == this.employerListingModel.EmployersBranches[0].StateCode)
                  //   this.EmployerListingBranchForm.controls["StateCode"].patchValue(i.Id);

                  //   this.onChangeState(i.Id);
                  // }
                }
                else if (this.PageRights.ActionType == this.Constants.Actions.View) {
                  this.employerListingModel.RequestType = this.Constants.PageType.View;
                  this.PageRights.IsReadOnly = true;

                  // Set all roles for view VT/VC/HM role
                }

                this.editMode = true;

                this.employerForm = this.createAccountForm();
                this.EmployerListingBranchForm = this.employerForm.controls.EmployerListingBranchForm as FormGroup;
                this.EmployersSectorsForm = this.employerForm.controls.EmployersSectorsForm as FormGroup;
                if (!this.employerListingModel.IsSingleBranch == true) { this.populateWorkLocations(); }
                else {
                  // let xx=this.internshipTypeList.filter(x=>x.Id==true);
                  // this.employerForm.controls["IsSingleBranch"].patchValue(xx);
                  this.employerForm.controls["IsSingleBranch"].updateValueAndValidity();
                  this.onEditWorkLocation(0);
                }

                for (let i of this.stateList) {
                  // this.employerForm.get('StateCode').patchValue(i.Id);
                  // let xx = this.stateList.filter(x => x.Id == this.employerListingModel.EmployersBranches[0].StateCode)
                  this.EmployerListingBranchForm.controls["StateCode"].patchValue(i.Id);

                  this.onChangeState(i.Id);
                }
                this.populatSector();
              });
          }
        }
      });
    });
  }

  onChangeState(stateId: string): any {
    let promise = new Promise((resolve, reject) => {
      this.commonService.GetMasterDataByType({ DataType: 'DistrictsByStateCode', ParentId: stateId, SelectTitle: 'District' }).subscribe((response: any) => {
        if (response.Success) {
          this.divisionList = response.Results;

        }
        resolve(true);
      })
    });
    return promise;
  }

  changeTab(event) {
    console.log(event.index)
    this.tabIndex = event.index;
  }

  onChangeBranchType(branchType: any) {
    this.isSingleBranch = branchType == true ? true : false;
  }

  saveOrCreateOrUpdateEmployersDetails() {
    if (!this.editMode) {
      if (this.employerListingModel.EmployersBranches.length >= 1 && this.employerListingModel.EmployersSectors.length >= 1) {
        this.removeValidatoresForEmployerBranch();
        this.removeValidatorsForEmployerSectors();
      }
      if (this.employerListingModel.EmployersBranches.length >= 1) {
        this.removeValidatoresForEmployerBranch();
      }
      if (this.employerListingModel.EmployersSectors.length >= 1) {
        this.removeValidatorsForEmployerSectors();
      }
    } else {

      if (this.employerListingModel.EmployersBranches.length >= 1 && this.employerListingModel.EmployersSectors.length >= 1 && !this.EmployerListingBranchForm.valid && !this.EmployersSectorsForm.valid) {
        this.onClearWorkLocation();
        this.onClearSector();
      }
      if (this.employerListingModel.EmployersBranches.length >= 1 && !this.EmployerListingBranchForm.valid) {
        this.onClearWorkLocation();
      }
      if (this.employerListingModel.EmployersSectors.length >= 1 && !this.EmployersSectorsForm.valid) {
        this.onClearSector();
      }

    }

    debugger;
    if (this.employerForm.controls.IsSingleBranch.value == true) {
      // this.EmployerListingBranchForm.controls['BranchName'].setValue(this.employerForm.controls.OrganizationName.value);
      // this.EmployerListingBranchForm.controls['POCEmailId'].setValue(this.employerForm.controls.EmployerFirstName.value + '_' + this.employerForm.controls.EmployerLastName.value + '@gmail.com');
      // this.EmployerListingBranchForm.controls['POCName'].setValue(this.employerForm.controls.EmployerFirstName.value + ' ' + this.employerForm.controls.EmployerLastName.value);
      // this.EmployerListingBranchForm.controls['POCMobile'].setValue(9970851600);
      this.clearValidatoresIsSingleBranchForEmployerBranch();
    }

    if (!this.employerForm.valid) {
      this.validateAllFormFields(this.employerForm);
      return;
    }
    if (this.employerForm.controls.EmployerListingBranchForm.value.BranchName == '' && this.employerForm.controls.EmployerListingBranchForm.value.BranchAddress == '' && this.employerForm.controls.EmployerListingBranchForm.value.BranchAddress == '' && this.employerForm.controls.EmployerListingBranchForm.value.POCName == '' && this.employerForm.controls.EmployerListingBranchForm.value.POCMobile == '' && this.employerForm.controls.EmployerListingBranchForm.value.POCEmailId == '' && this.employerForm.controls.EmployerListingBranchForm.value.Pincode == '') {
      this.EmpB = 'Edit';
    }

    if (this.EmpB == 'add') {
      // if (!this.employerForm.controls.BranchType.value == true) {
      this.onAddWorkLocation();
      // }
      if (this.mainBranchExist == true) {
        return false;
      }

    }
    if (this.employerForm.controls.EmployersSectorsForm.value.SectorId == '' && this.employerForm.controls.EmployersSectorsForm.value.SectorName == '') {
      this.EmpS = 'Edit';
    }
    if (this.EmpS == 'add') {
      this.onAddSector();

    }
    //  delete this.employerForm.controls['EmployerListingBranchForm'];
    //  delete this.employerForm.controls['EmployersSectorsForm'];
    this.employerForm.updateValueAndValidity();
    console.log(this.employerForm.value)



    this.setValueFromFormGroup(this.employerForm, this.employerListingModel);
    this.employerListingModel.EmployerName = this.employerForm.value.EmployerFirstName + ' ' + this.employerForm.value.EmployerLastName
    console.log(this.employerListingModel)

    // if (this.employerListingModel.EmployersBranches.filter(x => x.IsMainBranch == true).length == 0 && this.employerListingModel.IsSingleBranch == false) {
    //   // this.zone.run(() => {
    //   //   this.snackBar.open("System automatically set Main Branch address" +
    //   //     this.employerListingModel.EmployersBranches[0].BranchName + ', ' + this.employerListingModel.EmployersBranches[0].BranchAddress
    //   //     + ', ' + this.employerListingModel.EmployersBranches[0].DistrictName + ', ' + this.employerListingModel.EmployersBranches[0].StateName + ', ' +
    //   //     this.employerListingModel.EmployersBranches[0].Pincode + 'for Employer' + this.employerListingModel.EmployerName, "Dismiss", {
    //   //     duration: 10000,
    //   //     verticalPosition: 'top',
    //   //     horizontalPosition: 'right',
    //   //     panelClass: ["info-snackbar"]
    //   //   });
    //   // });
    //   // this.employerListingModel.EmployersBranches[0].IsMainBranch = true;

    //   this.zone.run(() => {
    //     this.snackBar.open("Please mark one address as Main Branch address", "Dismiss", {
    //       duration: 10000,
    //       verticalPosition: 'top',
    //       horizontalPosition: 'right',
    //       panelClass: ["error-snackbar"]
    //     });
    //   });
    //   return false;
    // }

    // else {

    // }
    debugger;
    this.employerListingService.CreateOrUpdateEmployersDetails(this.employerListingModel)
      .subscribe((accountResp: any) => {

        if (accountResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );

            this.router.navigate([RouteConstants.EmployerListing.List]);
          });
        }
        else {
          var errorMessages = this.getHtmlMessage(accountResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('EmployerListing deletion errors =>', error);
      });
  }

  //Create account form and returns {FormGroup}
  createAccountForm(): FormGroup {
    return this.formBuilder.group({

      UpdatedBy: new FormControl(this.UserModel.UserId),
      CreatedBy: new FormControl(this.UserModel.UserId),
      AuthUserId: new FormControl(this.UserModel.UserId),
      IsActive: new FormControl({ value: this.employerListingModel.IsActive, disabled: this.PageRights.IsReadOnly }),
      EmployerId: new FormControl(this.employerListingModel.EmployerId),
      EmployerFirstName: new FormControl({ value: this.employerListingModel.EmployerFirstName, disabled: this.PageRights.IsReadOnly }, [Validators.required, Validators.pattern(this.Constants.Regex.CharsWithTitleCase), Validators.maxLength(150)]),
      EmployerLastName: new FormControl({ value: this.employerListingModel.EmployerLastName, disabled: this.PageRights.IsReadOnly }, [Validators.pattern(this.Constants.Regex.CharsWithTitleCase), Validators.maxLength(150)]),
      EmployerGender: new FormControl({ value: this.employerListingModel.EmployerGender, disabled: this.PageRights.IsReadOnly }, Validators.required),
      // EmployerName: new FormControl({ value: this.employerListingModel.EmployerName, disabled: this.PageRights.IsReadOnly }),
      BusinessType: new FormControl({ value: this.employerListingModel.BusinessType, disabled: this.PageRights.IsReadOnly },),
      BusinessDesc: new FormControl({ value: this.employerListingModel.BusinessDesc, disabled: this.PageRights.IsReadOnly },),
      OrganizationName: new FormControl({ value: this.employerListingModel.OrganizationName, disabled: this.PageRights.IsReadOnly }, Validators.required),
      NoOfEmployees: new FormControl({ value: this.employerListingModel.NoOfEmployees, disabled: this.PageRights.IsReadOnly }, Validators.required),
      EmpPhotoUrl: new FormControl({ value: this.employerListingModel.EmpPhotoUrl, disabled: this.PageRights.IsReadOnly }),
      IsPreferred: new FormControl({ value: this.employerListingModel.IsPreferred, disabled: this.PageRights.IsReadOnly }),
      IsFreelancer: new FormControl({ value: this.employerListingModel.IsFreelancer, disabled: this.PageRights.IsReadOnly }),
      DateOfFoundation: new FormControl({ value: this.getDateValue(this.employerListingModel.DateOfFoundation), disabled: this.PageRights.IsReadOnly }),
      RequestType: new FormControl({ value: this.employerListingModel.RequestType, disabled: this.PageRights.IsReadOnly }),
      IsSingleBranch: new FormControl({ value: this.employerListingModel.IsSingleBranch, disabled: this.PageRights.IsReadOnly }, Validators.required),

      EmployerListingBranchForm: this.formBuilder.group({
        EmployerId: new FormControl(this.employerListingModel.EmployerId),
        EmployerBranchId: new FormControl(this.employerListingBranchModel.EmployerBranchId),
        StateName: new FormControl(this.employerListingBranchModel.StateName),
        BranchName: new FormControl({ value: this.employerListingBranchModel.BranchName, disabled: this.PageRights.IsReadOnly }, Validators.required),
        BranchAddress: new FormControl({ value: this.employerListingBranchModel.BranchAddress, disabled: this.PageRights.IsReadOnly }, Validators.required),
        DistrictCode: new FormControl({ value: this.employerListingBranchModel.DistrictCode, disabled: this.PageRights.IsReadOnly }, Validators.required),
        DistrictName: new FormControl({ value: this.employerListingBranchModel.DistrictName, disabled: this.PageRights.IsReadOnly }),
        Pincode: new FormControl({ value: this.employerListingBranchModel.Pincode, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(6), Validators.minLength(6), Validators.pattern(this.Constants.Regex.Number)]),
        StateCode: new FormControl({ value: this.employerListingBranchModel.StateCode }, Validators.required),
        Longitude: new FormControl({ value: this.employerListingBranchModel.Longitude, disabled: this.PageRights.IsReadOnly }),
        Lattitude: new FormControl({ value: this.employerListingBranchModel.Lattitude, disabled: this.PageRights.IsReadOnly }),
        IsMainBranch: new FormControl({ value: this.employerListingBranchModel.IsMainBranch, disabled: this.PageRights.IsReadOnly }),
        POCName: new FormControl({ value: this.employerListingBranchModel.POCName, disabled: this.PageRights.IsReadOnly }, [Validators.required, Validators.pattern(this.Constants.Regex.CharsWithTitleCase), Validators.maxLength(150)]),
        POCMobile: new FormControl({ value: this.employerListingBranchModel.POCMobile, disabled: this.PageRights.IsReadOnly }, [Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern(this.Constants.Regex.MobileNumber)]),
        POCEmailId: new FormControl({ value: this.employerListingBranchModel.POCEmailId, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(50), Validators.pattern(this.Constants.Regex.Email)]),
        IsActive: new FormControl({ value: this.employerListingBranchModel.IsActive, disabled: this.PageRights.IsReadOnly }),
        AuthUserId: new FormControl(this.UserModel.UserId),
        RequestType: new FormControl({ value: this.employerListingBranchModel.RequestType, disabled: this.PageRights.IsReadOnly }),
      }),

      EmployersSectorsForm: this.formBuilder.group({
        EmpLoyerSectorId: new FormControl(this.employerListingSectorsModel.EmpLoyerSectorId),
        SectorId: new FormControl({ value: this.employerListingSectorsModel.SectorId, disabled: this.PageRights.IsReadOnly }, Validators.required),
        EmployerId: new FormControl(this.employerListingModel.EmployerId),
        IsActive: new FormControl({ value: this.employerListingSectorsModel.IsActive, disabled: this.PageRights.IsReadOnly }),
        AuthUserId: new FormControl(this.UserModel.UserId),
        RequestType: new FormControl({ value: this.employerListingSectorsModel.RequestType, disabled: this.PageRights.IsReadOnly }),
        SectorName: new FormControl(this.employerListingSectorsModel.SectorName),


      })
    });
  }

  populateWorkLocations(): void {
    this.displayedColumns = ['StateName', 'BranchName', 'BranchAddress', 'POCName', 'POCEmailId', 'Actions'];

    this.tableDataSource.data = this.employerListingModel.EmployersBranches;
    console.log(this.tableDataSource.data)
    this.tableDataSource.sort = this.ListSort;
    this.tableDataSource.paginator = this.ListPaginator;
    this.tableDataSource.filteredData = this.tableDataSource.data;
  }

  onAddWorkLocation(): void {

    let EmployerListingBranchFormGroup: any = this.employerForm.controls.EmployerListingBranchForm;
    debugger;
    if (this.employerForm.controls.IsSingleBranch.value == false) {
      if (EmployerListingBranchFormGroup.get('BranchName').value == '' ||
        EmployerListingBranchFormGroup.get('POCMobile').value == '' || EmployerListingBranchFormGroup.get('POCName').value == '' ||
        EmployerListingBranchFormGroup.get('BranchAddress').value == '' || EmployerListingBranchFormGroup.get('Pincode').value == '' || EmployerListingBranchFormGroup.get('DistrictCode').value == ''
      ) {
        this.zone.run(() => {
          this.showActionMessage(
            'All fields marked with an asterisk (*) are required. in',
            this.Constants.Html.ErrorSnackbar,
          );
          this.errorMessage = 'All fields marked with an asterisk (*) are required. in, please fill allrequired fields ';
        });
        return;
      } else {
        this.errorMessage = '';
      }

      // EmployerListingBranchFormGroup.get('BranchName').value == '' ? EmployerListingBranchFormGroup.get('BranchName').setValidators(Validators.required) : EmployerListingBranchFormGroup.controls["BranchName"].clearValidators();
      // EmployerListingBranchFormGroup.get('POCEmailId').value == '' ? EmployerListingBranchFormGroup.get('POCEmailId').setValidators(Validators.required) : EmployerListingBranchFormGroup.controls["POCEmailId"].clearValidators();
      // EmployerListingBranchFormGroup.get('POCMobile').value == '' ? EmployerListingBranchFormGroup.get('POCMobile').setValidators(Validators.required) : EmployerListingBranchFormGroup.controls["POCMobile"].clearValidators();
      // EmployerListingBranchFormGroup.get('POCName').value == '' ? EmployerListingBranchFormGroup.get('POCName').setValidators(Validators.required) : EmployerListingBranchFormGroup.controls["POCName"].clearValidators();
      // EmployerListingBranchFormGroup.get('BranchAddress').value == '' ? EmployerListingBranchFormGroup.get('BranchAddress').setValidators(Validators.required) : EmployerListingBranchFormGroup.controls["BranchAddress"].clearValidators();
      // EmployerListingBranchFormGroup.get('Pincode').value == '' ? EmployerListingBranchFormGroup.get('Pincode').setValidators(Validators.required) : EmployerListingBranchFormGroup.controls["Pincode"].clearValidators();
      // EmployerListingBranchFormGroup.get('DistrictCode').value == '' ? EmployerListingBranchFormGroup.get('DistrictCode').setValidators(Validators.required) : EmployerListingBranchFormGroup.controls["DistrictCode"].clearValidators();
      // EmployerListingBranchFormGroup.controls["BranchName"].updateValueAndValidity();
      // EmployerListingBranchFormGroup.controls["POCEmailId"].updateValueAndValidity();
      // EmployerListingBranchFormGroup.controls["POCMobile"].updateValueAndValidity();
      // EmployerListingBranchFormGroup.controls["POCName"].updateValueAndValidity();
      // EmployerListingBranchFormGroup.controls["BranchAddress"].updateValueAndValidity();
      // EmployerListingBranchFormGroup.controls["Pincode"].updateValueAndValidity();
      // EmployerListingBranchFormGroup.controls["DistrictCode"].updateValueAndValidity();
      if (EmployerListingBranchFormGroup.status == 'INVALID') {
        return;
      }
    }
    // this.EmpB = 'Edit';

    this.employerListingBranchModel = {
      // AccountWorkLocationId: (this.workLocationAction == 'edit' ? this.employerListingBranchModel.AccountWorkLocationId : FuseUtils.NewGuid()),
      // EmployerBranchId: (this.workLocationAction == 'edit' ? this.employerListingBranchModel.EmployerBranchId : '00000000-0000-0000-0000-000000000000'),
      EmployerId: this.employerListingModel.EmployerId,
      AuthUserId: this.UserModel.UserId,
      // EmployerBranchId: this.editMode == true && this.employerListingModel.EmployersBranches.length > 0 ? '00000000-0000-0000-0000-000000000000' : this.employerListingBranchModel.EmployerBranchId,
      EmployerBranchId: this.employerListingBranchModel.EmployerBranchId,
      Longitude: this.employerListingBranchModel.Longitude,
      Lattitude: this.employerListingBranchModel.Lattitude,
      POCName: this.employerForm.controls.IsSingleBranch.value == true ? this.employerForm.controls.EmployerFirstName.value + ' ' + this.employerForm.controls.EmployerLastName.value : EmployerListingBranchFormGroup.controls["POCName"].value,
      POCMobile: this.employerForm.controls.IsSingleBranch.value == true ? '9970851600' : EmployerListingBranchFormGroup.controls["POCMobile"].value,
      POCEmailId: this.employerForm.controls.IsSingleBranch.value == true ? this.employerForm.controls.EmployerFirstName.value + '_' + this.employerForm.controls.EmployerLastName.value + '@gmail.com' : EmployerListingBranchFormGroup.controls["POCEmailId"].value,
      IsMainBranch: this.employerForm.controls.IsSingleBranch.value == true ? true : EmployerListingBranchFormGroup.controls["IsMainBranch"].value,
      StateName: this.stateList.filter(x => x.Id == EmployerListingBranchFormGroup.get('StateCode').value)[0].Name,//EmployerListingBranchFormGroup.controls["StateName"].value,
      BranchName: this.employerForm.controls.IsSingleBranch.value == true ? this.employerForm.controls.OrganizationName.value : EmployerListingBranchFormGroup.controls["BranchName"].value,
      BranchAddress: EmployerListingBranchFormGroup.controls["BranchAddress"].value,
      Pincode: EmployerListingBranchFormGroup.controls["Pincode"].value,
      StateCode: EmployerListingBranchFormGroup.get('StateCode').value,//EmployerListingBranchFormGroup.controls["StateCode"].value,
      DistrictCode: EmployerListingBranchFormGroup.controls["DistrictCode"].value,
      DistrictName: this.elRef.nativeElement.querySelector('mat-select[name="districtCode"]').innerText,
      IsActive: EmployerListingBranchFormGroup.controls["IsActive"].value,
      RequestType: (this.workLocationAction == 'add' ? this.Constants.PageType.New : this.Constants.PageType.Edit),
    };

    if (this.workLocationAction == 'add') {
      let workLocations = this.employerListingModel.EmployersBranches.filter(wl => wl.StateCode == this.employerListingBranchModel.StateCode
        && wl.DistrictCode == this.employerListingBranchModel.DistrictCode);
      // if (workLocations.length > 0) {
      //   this.dialogService.openShowDialog('Current work location is already exists');
      //   return;
      // }
    }
    //#region  Compare Barnch Address with Main Barnch Address
    if (this.editMode == true) {
      let ExistingMainBarnchRecord = this.employerListingModel.EmployersBranches.filter(x => x.EmployerBranchId == this.employerListingBranchModel.EmployerBranchId);
      // if (ExistingMainBarnchRecord.length > 0) {
      if (ExistingMainBarnchRecord.length > 0 && this.editMode == true && this.employerListingBranchModel.RequestType == 4) {
        if (this.employerListingBranchModel.EmployerBranchId == ExistingMainBarnchRecord[0].EmployerBranchId) {
          this.workLocationAction = 'edit';
        }
      } else {
        this.workLocationAction = 'add';
        this.employerListingBranchModel.EmployerBranchId = '00000000-0000-0000-0000-000000000000';
      }
    }
    //#endregionCompare Barnch Address with Main Barnch Address

    if (this.employerListingModel.EmployersBranches.length > 0) {
      if (this.employerListingModel.EmployersBranches.filter(x => x.IsMainBranch == true).length > 0 && this.employerListingModel.EmployersBranches.filter(x => x.IsMainBranch == true).length == 1) {
        if (this.workLocationAction == 'edit') {
          let xxx: any;
          xxx = this.employerListingModel.EmployersBranches.filter(x => x.IsMainBranch == true);
          // xxx = this.employerListingModel.EmployersBranches.filter(x => x.IsMainBranch == true);
          if (xxx != null && xxx.length >= 1 && this.employerListingBranchModel.IsMainBranch) {
            if (this.employerListingBranchModel.EmployerBranchId == xxx[0].EmployerBranchId && this.employerListingBranchModel.IsMainBranch == true && xxx[0].IsMainBranch == true) {
              this.employerListingModel.EmployersBranches[this.currentWorkLocationIndex] = this.employerListingBranchModel;
              this.mainBranchExist = false;
            } else {
              this.mainBranchExist = true;
              this.zone.run(() => {
                this.snackBar.open(
                  xxx[0].BranchName + ', ' + xxx[0].BranchAddress
                  + ', ' + xxx[0].DistrictName + ', ' + xxx[0].StateName + ', '
                  + xxx[0].Pincode + 'Already marked as Main Branch', "Dismiss", {
                  duration: 10000,
                  verticalPosition: 'bottom',
                  horizontalPosition: 'center',
                  panelClass: ["warning-snackbar"]
                });
              });
              return;
            }
          } else {
            this.mainBranchExist = false;
            this.employerListingModel.EmployersBranches[this.currentWorkLocationIndex] = this.employerListingBranchModel;
          }
        }
        else {
          let xxx: any;
          xxx = this.employerListingModel.EmployersBranches.filter(x => x.IsMainBranch == true);
          // xxx = this.employerListingModel.EmployersBranches.filter(x => x.IsMainBranch == true);
          if (xxx != null && xxx.length >= 1 && this.employerListingBranchModel.IsMainBranch) {
            if (this.employerForm.controls.IsSingleBranch.value == true) {
              this.employerListingModel.EmployersBranches[this.currentWorkLocationIndex] = this.employerListingBranchModel; // balaji added
            } else {
              this.mainBranchExist = true;
              this.zone.run(() => {
                this.snackBar.open(
                  xxx[0].BranchName + ', ' + xxx[0].BranchAddress
                  + ', ' + xxx[0].DistrictName + ', ' + xxx[0].StateName + ', '
                  + xxx[0].Pincode + 'Already marked as Main Branch', "Dismiss", {
                  duration: 10000,
                  verticalPosition: 'bottom',
                  horizontalPosition: 'center',
                  panelClass: ["warning-snackbar"]
                });
              });
              return;
            }
          } else {
            this.mainBranchExist = false;
            this.employerListingModel.EmployersBranches.push(this.employerListingBranchModel);
            // if (this.employerListingBranchModel.EmployerBranchId == xxx[0].EmployerBranchId && this.editMode == true) {
            //   let yyy: any;
            //   yyy = this.employerListingModel.EmployersBranches.filter(x => x.EmployerBranchId == this.employerListingBranchModel.EmployerBranchId);
            //   this.employerListingModel.EmployersBranches.splice(yyy, 1);
            //   this.employerListingModel.EmployersBranches.push(this.employerListingBranchModel);
            // } else {
            //   this.employerListingModel.EmployersBranches.push(this.employerListingBranchModel);
            // }
          }
          // this.employerListingModel.EmployersBranches.push(this.employerListingBranchModel); // old code
        }
      }
      // else if (this.employerListingModel.EmployersBranches.filter(x => x.IsMainBranch == true).length >= 1 && this.employerListingBranchModel.IsMainBranch == true) {
      //   this.zone.run(() => {
      //     this.showActionMessage(
      //       'Main Branch adress already present',
      //       this.Constants.Html.ErrorSnackbar,
      //     );
      //   });
      //   return;
      // }
      else {
        if (this.employerListingBranchModel.IsMainBranch == true) {
          if (this.workLocationAction == 'edit') {
            this.employerListingModel.EmployersBranches[this.currentWorkLocationIndex] = this.employerListingBranchModel;
          }
          else {
            this.employerListingModel.EmployersBranches.push(this.employerListingBranchModel);
          }
        }
        else {
          // this.zone.run(() => {
          //   this.snackBar.open("Please specify Main Branch Address, Otherwise system will automatically set Main Branch address .." +
          //     this.employerListingModel.EmployersBranches[0].BranchName + ', ' + this.employerListingModel.EmployersBranches[0].BranchAddress
          //     + ', ' + this.employerListingModel.EmployersBranches[0].DistrictName + ', ' + this.employerListingModel.EmployersBranches[0].StateName + ', '
          //     + this.employerListingModel.EmployersBranches[0].Pincode, "Dismiss", {
          //     duration: 10000,
          //     verticalPosition: 'top',
          //     horizontalPosition: 'center',
          //     panelClass: ["warning-snackbar"]
          //   });
          // });
          this.zone.run(() => {
            this.snackBar.open("Please note no branch is marked as Main Branch...", "Dismiss", {
              duration: 10000,
              verticalPosition: 'top',
              horizontalPosition: 'center',
              panelClass: ["warning-snackbar"]
            });
          });
          if (this.workLocationAction == 'edit') {
            this.employerListingModel.EmployersBranches[this.currentWorkLocationIndex] = this.employerListingBranchModel;
          }
          else {
            this.employerListingModel.EmployersBranches.push(this.employerListingBranchModel);
          }
        }

      }
    }
    else {
      debugger;
      if (this.employerListingModel.EmployersBranches.length < 1) {
        this.employerListingBranchModel.IsMainBranch = true;
      }
      if (this.employerListingBranchModel.IsMainBranch == true) {
        if (this.workLocationAction == 'edit') {
          this.employerListingModel.EmployersBranches[this.currentWorkLocationIndex] = this.employerListingBranchModel;
        }
        else {
          this.employerListingModel.EmployersBranches.push(this.employerListingBranchModel);
        }
      } else {
        this.zone.run(() => {
          this.snackBar.open("Please specify Main Branch Address, Otherwise system will automatically set Main Branch address ..", "Dismiss", {
            duration: 10000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ["warning-snackbar"]
          });
        });
        if (this.workLocationAction == 'edit') {
          this.employerListingModel.EmployersBranches[this.currentWorkLocationIndex] = this.employerListingBranchModel;
        }
        else {
          let xxx: any;
          xxx = this.employerListingModel.EmployersBranches.filter(x => x.IsMainBranch == true);
          if (xxx != undefined && xxx != null && xxx.length > 0) {
            this.zone.run(() => {
              this.snackBar.open(
                xxx.BranchName + ', ' + xxx.BranchAddress
                + ', ' + xxx.DistrictName + ', ' + xxx.StateName + ', '
                + xxx.Pincode + 'Already marked as Main Branch', "Dismiss", {
                duration: 10000,
                verticalPosition: 'bottom',
                horizontalPosition: 'center',
                panelClass: ["warning-snackbar"]
              });
            });
            return;
          } else {
            this.employerListingModel.EmployersBranches.push(this.employerListingBranchModel);
          }
        }
        // this.zone.run(() => {
        //   this.showActionMessage(
        //     'You have not mentioned Main branch Address, please add Main Branch Adress othere wise system will consider this address as Main Barnch Address ',
        //     this.Constants.Html.ErrorSnackbar,
        //   );
        //   if (this.workLocationAction == 'edit') {
        //     this.employerListingModel.EmployersBranches[this.currentWorkLocationIndex] = this.employerListingBranchModel;
        //   }
        //   else {
        //     this.employerListingModel.EmployersBranches.push(this.employerListingBranchModel);
        //   }
        // });
      }
    }

    // if (this.workLocationAction == 'edit') {
    //   this.employerListingModel.EmployersBranches[this.currentWorkLocationIndex] = this.employerListingBranchModel;
    // }
    // else {
    //   this.employerListingModel.EmployersBranches.push(this.employerListingBranchModel);
    // }

    this.populateWorkLocations();
    this.onClearWorkLocation();
    this.scrollToBottom();
    EmployerListingBranchFormGroup.controls["StateCode"].patchValue(EmployerListingBranchFormGroup.get('StateCode').value);
  }

  onEditWorkLocation(wlIndex: any): void {
    this.employerListingBranchModel = this.employerListingModel.EmployersBranches[wlIndex];
    this.currentWorkLocationIndex = wlIndex;
    this.workLocationAction = 'edit';

    this.onChangeState(this.employerListingBranchModel.StateCode).then(res => {
      let EmployerListingBranchFormGroup: any = this.employerForm.controls.EmployerListingBranchForm;
      EmployerListingBranchFormGroup.controls["StateCode"].patchValue(this.employerListingModel.EmployersBranches[wlIndex].StateCode);
      EmployerListingBranchFormGroup.controls["StateName"].patchValue(this.employerListingModel.EmployersBranches[wlIndex].StateName);
      EmployerListingBranchFormGroup.controls["BranchName"].patchValue(this.employerListingModel.EmployersBranches[wlIndex].BranchName);
      EmployerListingBranchFormGroup.controls["BranchAddress"].patchValue(this.employerListingModel.EmployersBranches[wlIndex].BranchAddress);
      EmployerListingBranchFormGroup.controls["DistrictCode"].patchValue(this.employerListingModel.EmployersBranches[wlIndex].DistrictCode);
      EmployerListingBranchFormGroup.controls["POCName"].patchValue(this.employerListingModel.EmployersBranches[wlIndex].POCName);
      EmployerListingBranchFormGroup.controls["POCMobile"].patchValue(this.employerListingModel.EmployersBranches[wlIndex].POCMobile);
      EmployerListingBranchFormGroup.controls["POCEmailId"].patchValue(this.employerListingModel.EmployersBranches[wlIndex].POCEmailId);
      EmployerListingBranchFormGroup.controls["IsMainBranch"].patchValue(this.employerListingModel.EmployersBranches[wlIndex].IsMainBranch);
      EmployerListingBranchFormGroup.controls["Pincode"].patchValue(this.employerListingModel.EmployersBranches[wlIndex].Pincode);
      EmployerListingBranchFormGroup.controls["EmployerBranchId"].patchValue(this.employerListingModel.EmployersBranches[wlIndex].EmployerBranchId);
      EmployerListingBranchFormGroup.controls["EmployerId"].patchValue(this.employerListingModel.EmployersBranches[wlIndex].EmployerId);
    });
  }



  onDeleteWorkLocation(wlIndex: any): void {
    this.employerListingModel.EmployersBranches.splice(wlIndex, 1);
    this.populateWorkLocations();
  }

  onClearWorkLocation(): void {
    this.workLocationAction = 'add';
    let EmployerListingBranchFormGroup: any = this.employerForm.controls.EmployerListingBranchForm;

    // EmployerListingBranchFormGroup.controls["StateCode"].patchValue(this.UserModel.DefaultStateId);
    EmployerListingBranchFormGroup.controls["BranchName"].patchValue('');
    EmployerListingBranchFormGroup.controls["POCEmailId"].patchValue('');
    EmployerListingBranchFormGroup.controls["POCMobile"].patchValue('');
    EmployerListingBranchFormGroup.controls["POCName"].patchValue('');
    EmployerListingBranchFormGroup.controls["BranchAddress"].patchValue('');
    EmployerListingBranchFormGroup.controls["Pincode"].patchValue('');
    EmployerListingBranchFormGroup.controls["DistrictCode"].patchValue('');
    EmployerListingBranchFormGroup.controls["IsMainBranch"].patchValue(false);
    // Balaji Added below code to clearValidators()
    EmployerListingBranchFormGroup.controls["BranchName"].clearValidators();
    EmployerListingBranchFormGroup.controls["POCEmailId"].clearValidators();
    EmployerListingBranchFormGroup.controls["POCMobile"].clearValidators();
    EmployerListingBranchFormGroup.controls["POCName"].clearValidators();
    EmployerListingBranchFormGroup.controls["BranchAddress"].clearValidators();
    EmployerListingBranchFormGroup.controls["Pincode"].clearValidators();
    EmployerListingBranchFormGroup.controls["DistrictCode"].clearValidators();

    for (let control in EmployerListingBranchFormGroup.controls) {
      EmployerListingBranchFormGroup.controls[control].updateValueAndValidity();
      EmployerListingBranchFormGroup.controls[control].markAsPristine();
      EmployerListingBranchFormGroup.controls[control].markAsUntouched();
    }
    // for (let inner in EmployerListingBranchFormGroup.controls) {
    //   EmployerListingBranchFormGroup.get(inner).markAsTouched();
    //   EmployerListingBranchFormGroup.get(inner).updateValueAndValidity();
    // }
  }

  removeValidatoresForEmployerBranch() {
    let EmployerListingBranchFormGroup: any = this.employerForm.controls.EmployerListingBranchForm;
    EmployerListingBranchFormGroup.controls["BranchName"].clearValidators();
    EmployerListingBranchFormGroup.controls["POCEmailId"].clearValidators();
    EmployerListingBranchFormGroup.controls["POCMobile"].clearValidators();
    EmployerListingBranchFormGroup.controls["POCName"].clearValidators();
    EmployerListingBranchFormGroup.controls["BranchAddress"].clearValidators();
    EmployerListingBranchFormGroup.controls["Pincode"].clearValidators();
    EmployerListingBranchFormGroup.controls["DistrictCode"].clearValidators();

    for (let control in EmployerListingBranchFormGroup.controls) {
      EmployerListingBranchFormGroup.controls[control].updateValueAndValidity();
      EmployerListingBranchFormGroup.controls[control].markAsPristine();
      EmployerListingBranchFormGroup.controls[control].markAsUntouched();
    }
  }


  clearValidatoresIsSingleBranchForEmployerBranch() {
    let EmployerListingBranchFormGroup: any = this.employerForm.controls.EmployerListingBranchForm;
    EmployerListingBranchFormGroup.controls["BranchName"].clearValidators();
    EmployerListingBranchFormGroup.controls["POCEmailId"].clearValidators();
    EmployerListingBranchFormGroup.controls["POCMobile"].clearValidators();
    EmployerListingBranchFormGroup.controls["POCName"].clearValidators();
    EmployerListingBranchFormGroup.controls["BranchName"].updateValueAndValidity();
    EmployerListingBranchFormGroup.controls["BranchName"].markAsPristine();
    EmployerListingBranchFormGroup.controls["BranchName"].markAsUntouched();

    EmployerListingBranchFormGroup.controls["POCEmailId"].updateValueAndValidity();
    EmployerListingBranchFormGroup.controls["POCEmailId"].markAsPristine();
    EmployerListingBranchFormGroup.controls["POCEmailId"].markAsUntouched();

    EmployerListingBranchFormGroup.controls["POCMobile"].updateValueAndValidity();
    EmployerListingBranchFormGroup.controls["POCMobile"].markAsPristine();
    EmployerListingBranchFormGroup.controls["POCMobile"].markAsUntouched();

    EmployerListingBranchFormGroup.controls["POCName"].updateValueAndValidity();
    EmployerListingBranchFormGroup.controls["POCName"].markAsPristine();
    EmployerListingBranchFormGroup.controls["POCName"].markAsUntouched();
  }

  removeValidatorsForEmployerSectors() {
    let EmployersSectorsFormGroup: any = this.employerForm.controls.EmployersSectorsForm;
    EmployersSectorsFormGroup.controls["SectorId"].clearValidators();
    for (let control in EmployersSectorsFormGroup.controls) {
      EmployersSectorsFormGroup.controls[control].updateValueAndValidity();
      EmployersSectorsFormGroup.controls[control].markAsPristine();
      EmployersSectorsFormGroup.controls[control].markAsUntouched();
    }
  }
  populatSector(): void {
    this.displayedColumns1 = ['SectorName', 'Actions'];

    this.tableDataSource1.data = this.employerListingModel.EmployersSectors;
    console.log(this.tableDataSource1)
    this.tableDataSource1.sort = this.ListSort;
    this.tableDataSource1.paginator = this.ListPaginator;
    this.tableDataSource1.filteredData = this.tableDataSource1.data;
  }

  onAddSector(): void {
    let EmployersSectorsFormGroup: any = this.employerForm.controls.EmployersSectorsForm;

    if (EmployersSectorsFormGroup.status == 'INVALID') {
      return;
    }
    this.EmpS = 'Edit';
    this.employerListingSectorsModel = {
      // AccountLocationId: (this.workAction == 'edit' ? this.employerListingSectorsModel.AccountLocationId : FuseUtils.NewGuid()),
      // EmpLoyerSectorId: (this.workAction == 'edit' ? this.employerListingSectorsModel.EmpLoyerSectorId : '00000000-0000-0000-0000-000000000000'),
      EmployerId: this.employerListingModel.EmployerId,
      AuthUserId: this.UserModel.UserId,
      EmpLoyerSectorId: this.employerListingSectorsModel.EmpLoyerSectorId,
      SectorId: EmployersSectorsFormGroup.controls["SectorId"].value,
      SectorName: this.elRef.nativeElement.querySelector('mat-select[name="sectorId"]').innerText,

      IsActive: EmployersSectorsFormGroup.controls["IsActive"].value,
      RequestType: (this.workAction == 'add' ? this.Constants.PageType.New : this.Constants.PageType.Edit),
    };

    if (this.workAction == 'add') {
      let workLocations = this.employerListingModel.EmployersSectors.filter(wl => wl.SectorId == this.employerListingSectorsModel.SectorId);
      if (workLocations.length > 0) {
        this.dialogService.openShowDialog('Sector is already exists');
        return;
      }
    }

    if (this.workAction == 'edit') {
      this.employerListingModel.EmployersSectors[this.currentWorkLocationIndex] = this.employerListingSectorsModel;
    }
    else {
      this.employerListingModel.EmployersSectors.push(this.employerListingSectorsModel);
    }

    this.populatSector();
    this.onClearSector();
    this.scrollToBottom();
  }

  onEditSector(wlIndex: any): void {
    this.employerListingSectorsModel = this.employerListingModel.EmployersSectors[wlIndex];
    this.currentWorkLocationIndex = wlIndex;
    this.workAction = 'edit';
    let EmployersSectorsFormGroup: any = this.employerForm.controls.EmployersSectorsForm;
    EmployersSectorsFormGroup.controls["SectorId"].patchValue(this.employerListingSectorsModel.SectorId);
  }

  onDeleteSector(wlIndex: any): void {
    this.employerListingModel.EmployersSectors.splice(wlIndex, 1);
    this.populatSector();
  }

  onClearSector(): void {
    this.workLocationAction = 'add';
    let EmployersSectorsFormGroup: any = this.employerForm.controls.EmployersSectorsForm;

    EmployersSectorsFormGroup.controls["SectorId"].patchValue('');

    EmployersSectorsFormGroup.controls["SectorId"].clearValidators();
    for (let control in EmployersSectorsFormGroup.controls) {
      EmployersSectorsFormGroup.controls[control].updateValueAndValidity();
      EmployersSectorsFormGroup.controls[control].markAsPristine();
      EmployersSectorsFormGroup.controls[control].markAsUntouched();
    }
  }

  changePhoto($event): void {
    console.log($event)
    this.readThis($event.target);
    this.getUploadedFileDataXl($event, this.Constants.DocumentType.Employer).then((response: FileUploadModel) => {
      this.fileUploadModel = response;
      this.employerForm.value.EmpPhotoUrl = this.fileUploadModel
    });
  }
  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.img = myReader.result;
    }
    myReader.readAsDataURL(file);
  }

  //Create MultipalStudent form and returns {FormGroup}
  createDataUploadForm(): FormGroup {
    return this.formBuilder.group({
      UploadFile: new FormControl({ value: this.fileUploadModel.UploadFile }, Validators.required)
    });
  }

  uploadedFile(event) {

    if (event.target.files.length > 0) {
      var fileExtn = event.target.files[0].name.split('.').pop().toLowerCase();

      if (this.AllowedExcelExtensions.indexOf(fileExtn) == -1) {
        this.dataUploadForm.get('UploadFile').setValue(null);
        this.dialogService.openShowDialog("Please upload excel file only.");
        return;
      }

      this.getUploadedFileDataXl(event, this.Constants.DocumentType.Employer).then((response: FileUploadModel) => {
        this.fileUploadModel = response;
        console.log(response)
      });

      this.isAvailableUploadedExcel = false;
    }
  }

  uploadExcelData(): void {


    if (this.fileUploadModel.FileName === "") {
      this.dialogService.openShowDialog("Please upload excel template data first !!!");
      return;
    }

    let excelFormData = this.setUploadedFile(this.fileUploadModel);
    excelFormData.UserId = this.UserModel.UserTypeId;

    this.reportService.UploadExcelData(excelFormData).subscribe(response => {
      if (response.Success) {
        this.uploadedFileUrl = response.Messages.pop();
        this.isAvailableUploadedExcel = true;
        this.router.navigate([RouteConstants.EmployerListing.List]);

        this.dialogService.openShowDialog("Template data executed successfully for " + 'Multipal Employer' + '. Please download uploaded excel file and check status');
      }
      else {
        this.dialogService.openShowDialog("Data uploading failed for " + 'Multipal Employer' + " " + response.Errors.pop());
      }
    });
  }


    date = new FormControl(moment());
    chosenYearHandler(normalizedYear: Moment, dp: any) {
      const ctrlValue = this.date.value;
      ctrlValue.year(normalizedYear.year());
      this.date.setValue(ctrlValue);
      dp.close();
      console.log(this.date.value, ctrlValue);
      this.employerForm.controls["DateOfFoundation"].setValue(this.date.value);

    }
}

