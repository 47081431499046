import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SchoolSectorJobRoleModel } from './school-sector-job-role.model';
import { SchoolSectorJobRoleService } from './school-sector-job-role.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DropdownModel } from 'app/models/dropdown.model';

@Component({
  selector: 'data-list-view',
  templateUrl: './school-sector-job-role.component.html',
  styleUrls: ['./school-sector-job-role.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class SchoolSectorJobRoleComponent extends BaseListComponent<SchoolSectorJobRoleModel> implements OnInit {
  schoolSectorJobRoleFilterForm: FormGroup;
  schoolsList: [DropdownModel];
  schoolSectotJobRole: any = [];
  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    public zone: NgZone,
    private dialogService: DialogService,
    private formBuilder: FormBuilder,
    private schoolSectorJobRoleService: SchoolSectorJobRoleService) {
    super(commonService, router, routeParams, snackBar, zone);
    this.schoolSectorJobRoleFilterForm = this.createSchoolSectorJobRoleFilterForm();

  }

  ngOnInit(): void {
    this.schoolSectorJobRoleService.getDropdownforSectorJobRole(this.UserModel).subscribe((response: any) => {
      if (response[1].Success) {
        this.schoolsList = response[1].Results;
      }
    });

    this.onLoadSchoolSectorJobRoleCriteria();
    // this.schoolSectorJobRoleService.GetAllByCriteria(this.SearchBy).subscribe(response => {
    //   this.displayedColumns = ['SchoolName', 'SectorName', 'JobRoleName','AcademicYear','UDISE', 'Remarks', 'IsActive', 'Actions'];
    //   console.log(response.Results)
    //   this.schoolSectotJobRole = response.Results;
    //   this.tableDataSource.data = response.Results;
    //   this.tableDataSource.sort = this.ListSort;
    //   this.tableDataSource.paginator = this.ListPaginator;
    //   this.tableDataSource.filteredData = this.tableDataSource.data;

    //   this.zone.run(() => {
    //     if (this.tableDataSource.data.length == 0) {
    //       this.showNoDataFoundSnackBar();
    //     }
    //   });
    //   this.IsLoading = false;
    // }, error => {
    //   console.log(error);
    // });
  }

  onLoadSchoolSectorJobRoleCriteria(): any {
    this.SearchBy.IsActive = this.schoolSectorJobRoleFilterForm.controls["Status"].value == 'true' ? true : this.schoolSectorJobRoleFilterForm.controls["Status"].value == 'false' ? false : this.schoolSectorJobRoleFilterForm.controls["Status"].value;
    this.IsLoading = true;
    this.schoolSectorJobRoleService.GetAllByCriteria(this.SearchBy).subscribe(response => {
      this.displayedColumns = ['SchoolName', 'SectorName', 'JobRoleName', 'AcademicYear', 'UDISE', 'Remarks', 'IsActive', 'Actions'];
      console.log(response.Results)
      this.schoolSectotJobRole = response.Results;
      this.tableDataSource.data = response.Results;
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;

      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
    });
  }

  onDeleteSchoolSectorJobRole(sectorJobRoleId: string) {
    this.dialogService
      .openConfirmDialog(this.Constants.Messages.DeleteConfirmationMessage)
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          this.schoolSectorJobRoleService.deleteSchoolSectorJobRoleById(sectorJobRoleId)
            .subscribe((schoolSectorJobRoleResp: any) => {

              this.zone.run(() => {
                if (schoolSectorJobRoleResp.Success == true) {
                  this.showActionMessage(
                    this.Constants.Messages.RecordDeletedMessage,
                    this.Constants.Html.SuccessSnackbar
                  );
                } else {
                  this.showActionMessage(
                    schoolSectorJobRoleResp.Result,
                    this.Constants.Html.SuccessSnackbar
                  );
                  this.ngOnInit();

                }
                this.ngOnInit();
              }, error => {
                console.log('SchoolSectorJobRole deletion errors =>', error);
              });

            });
          this.ngOnInit();
        }
      });
  }

  exportExcel(): void {
    this.IsLoading = true;
    this.SearchBy.PageIndex = 0;
    this.SearchBy.PageSize = 10000;

    this.schoolSectorJobRoleService.GetAllByCriteria(this.SearchBy).subscribe(response => {
      response.Results.forEach(
        function (obj) {
          if (obj.hasOwnProperty('IsActive')) {
            obj.IsActive = obj.IsActive ? 'Yes' : 'No';
          }
          if (obj.hasOwnProperty('IsImplemented')) {
            obj.IsImplemented = obj.IsImplemented ? 'Yes' : 'No';
          }
          delete obj.SchoolId;
          delete obj.SchoolSectorJobRolesId;
          delete obj.SectorId;
          delete obj.JobRoleId;
          delete obj.TotalRows;
        });

      this.exportExcelFromTable(response.Results, "SchoolSectorJobRole");

      this.IsLoading = false;
      this.SearchBy.PageIndex = 0;
      this.SearchBy.PageSize = 10;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  //Create SchoolSectorJobRole form and returns {FormGroup}
  createSchoolSectorJobRoleFilterForm(): FormGroup {
    return this.formBuilder.group({
      // AcademicYearId: new FormControl(),
      Status: new FormControl()
    });
  }

  onLoadSchoolSectorJobRoleByFilters(): any {
    this.SearchBy.PageIndex = 0;
    this.onLoadSchoolSectorJobRoleCriteria();
  }

  resetFilters(): void {
    this.SearchBy.PageIndex = 0;
    this.schoolSectorJobRoleFilterForm.reset();
    //this.schoolFilterForm.get('AcademicYearId').setValue(this.AcademicYearId);

    this.onLoadSchoolSectorJobRoleCriteria();
  }


}
