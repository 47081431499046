import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';
import { UserModel } from "app/models/user.model";

@Injectable()
export class VocationalCoordinatorService {
    constructor(private http: BaseService) { }

    getVocationalCoordinators(): Observable<any> {
        return this.http
            .HttpGet(this.http.Services.VocationalCoordinator.GetAll)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetAllByCriteria(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.VocationalCoordinator.GetAllByCriteria, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    getVocationalCoordinatorById(vcId: string, vtpId: string, academicYearId: string) {
        let requestParams = {
            DataId: vcId,
            DataId1: vtpId,
            DataId2: academicYearId,
        };

        return this.http
            .HttpPost(this.http.Services.VocationalCoordinator.GetById, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    createOrUpdateVocationalCoordinator(formData: any) {
        return this.http
            .HttpPost(this.http.Services.VocationalCoordinator.CreateOrUpdate, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    deleteVocationalCoordinatorById(vcId: string, vtpId: string) {
        var vocationalCoordinatorParams = {
            DataId: vcId,
            DataId1: vtpId
        };

        return this.http
            .HttpPost(this.http.Services.VocationalCoordinator.Delete, vocationalCoordinatorParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    getDropdownforVocationalCoordinators(userModel: UserModel): Observable<any[]> {
        let vtpRequest = this.http.GetMasterDataByType({ DataType: 'VocationalTrainingProviders', UserId: userModel.LoginId, ParentId: userModel.UserTypeId, SelectTitle: 'Vocational Training Provider' });
        let natureOfAppointmentRequest = this.http.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'NatureOfAppointment', SelectTitle: 'Nature Of Appointment' });
        let genderRequest = this.http.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'Gender', SelectTitle: 'Gender' });
        let academicYearRequest = this.http.GetMasterDataByType({ DataType: 'AcademicYearsByState', UserId: userModel.UserTypeId, SelectTitle: 'Academic Year' });
        let statecodeRequest = this.http.GetMasterDataByType({ DataType: 'StatesByUserId', UserId: userModel.UserTypeId, SelectTitle: 'StateCode' });

        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        return forkJoin([vtpRequest, natureOfAppointmentRequest, genderRequest, academicYearRequest, statecodeRequest]);
    }
}
