<mat-toolbar class="p-0 mat-elevation-z1">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <!-- gloabl search  -->
        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start" id="gloabl-search" *ngIf="IsAllowedGloablSearch">

            <mat-form-field appearance="outline" class="glb-school" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                <mat-label>Select School </mat-label>

                <mat-select #searchSchoolInput name="schoolName" appearance="outline" [value]="selectedShool"
                    (selectionChange)="onChangeSchool($event)">
                    <mat-option [value]="schoolItem.Id" *ngFor="let schoolItem of schoolsList">
                        {{ schoolItem.Name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                class="mls glb-academic-year">
                <mat-label>Academic Year</mat-label>

                <mat-select #searchAcademicYearInput name="academicYear" required appearance="outline"
                    [value]="selectedAcademicYear" (selectionChange)="onChangeSchoolAndAcademicYear($event)">
                    <mat-option [value]="academicYearItem.Id" *ngFor="let academicYearItem of academicyearList">
                        {{ academicYearItem.Name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- {{ getCurrentUrl }} -->
        </div>

        <!-- <app-internship-opportunity (onInitEvent)="receiveCurrentUrlHandler($event)"></app-internship-opportunity> -->
        <!-- <base-list-component (sendCurrentUrl)="getCurrentUrl = $event"></base-list-component> -->

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <div class="left-logo" style="display:none">
                <!-- <img  class="left-logo-icon" src="assets/images/logos/samagra-shiksha-abhiyan-{{appInfo.target}}.png" alt=""> -->
                <img class="left-logo-icon" src="assets/images/logos/pathways-logo.png" alt="">
            </div>

            <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar"
                (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img class="logo-icon" src="assets/images/logos/pathways-logo.png">
                </div>
            </div>

            <div class="px-8 px-md-16" style="display: none;">
                <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
            </div>

        </div>

        <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
            <span class="username mr-12" fxHide fxShow.gt-sm>{{currentUser.AccountType}}</span>
        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
            <!-- <div class="toolbar-separator"></div>-->

            <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="avatar mr-0 mr-sm-16" fxHide.gt-sm src="assets/images/avatars/profile2.png">
                    <span class="username mr-12" fxHide fxShow.gt-sm>{{currentUser.FirstName}}
                        {{currentUser.LastName}}</span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
                <button mat-menu-item class="" (click)="changePassword()">
                    <mat-icon>vpn_key</mat-icon>
                    <span>Change Password</span>
                </button>

                <button mat-menu-item class="" (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>

            </mat-menu>

            <div class="toolbar-separator"></div>

            <!-- <fuse-search-bar (input)="search($event)"></fuse-search-bar> -->

            <div class="toolbar-separator"></div>

            <!-- <button mat-button fxHide fxShow.gt-xs class="language-button" [matMenuTriggerFor]="languageMenu">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
                    <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
                </div>
            </button>

            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
                <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                        <span class="iso">{{lang.title}}</span>
                    </span>
                </button>
            </mat-menu> -->

            <!-- <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

            <button mat-icon-button
                    class="quick-panel-toggle-button"
                    (click)="toggleSidebarOpen('quickPanel')"
                    aria-label="Toggle quick panel">
                <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

            <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button> -->

        </div>

    </div>

</mat-toolbar>