import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
// import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { RouteConstants } from 'app/constants/route.constant'
import { ProfileChangeRequestModel } from '../profile-change-request.model';
import { ProfileChangeRequestService } from '../profile-change-request.service';
import { DropdownModel } from 'app/models/dropdown.model';
import { FileUploadModel } from 'app/models/file.upload.model';
import { ReportService } from 'app/reports/report.service';
import { Router, RouterEvent, NavigationEnd,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'profile-change-request',
  templateUrl: './create-profile-change-request.component.html',
  styleUrls: ['./create-profile-change-request.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CreateProfileChangeRequestComponent extends BaseComponent<ProfileChangeRequestModel> implements OnInit {
  studentForm: FormGroup;
  profileChangeRequestModel: ProfileChangeRequestModel;
  dataUploadForm: FormGroup;

  fileUploadModel: FileUploadModel;
  schoolCategoryList: [DropdownModel];
  academicyearList: [DropdownModel];
  phaseList: [DropdownModel];
  divisionList: [DropdownModel];
  stateList: [DropdownModel];
  districtList: [DropdownModel];
  schoolTypeList: [DropdownModel];
  schoolManagementList: [DropdownModel];
  blockList: [DropdownModel];
  clusterList: [DropdownModel];
  stateCodeList: [DropdownModel];
  genderList: [DropdownModel];
  schoolsList: [DropdownModel];
  socialCategoryList: [DropdownModel];
  classList: [DropdownModel];
  sectionList: [DropdownModel];
  religionList: [DropdownModel];
  vtList: [DropdownModel];
  vocationalSubjectList: [DropdownModel];

  isAvailableUploadedExcel: boolean = false;
  uploadedFileUrl: string;

  private previousUrl: string = undefined;
  private currentUrl: string = undefined;
  
  tabIndex = 0 ;
  subjectList = [{ Id: 'Arts', Name: 'Arts' }, { Id: 'Science', Name: 'Science' }, { Id: 'Commerce', Name: 'Commerce' } ]
  cwList = [{ Id: 'No', Name: 'No' }, { Id: 'Yes', Name: 'Yes' } ]
  schoolId: any;
  academicYearId: any;
  classId: any;
  sectionId: any;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private route: ActivatedRoute,
    private profileChangeRequestService: ProfileChangeRequestService,
    private dialogService: DialogService,
    private reportService: ReportService,
    private formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Set the default school Model
    this.profileChangeRequestModel = new ProfileChangeRequestModel();
    this.fileUploadModel = new FileUploadModel();
  }

  ngOnInit(): void {
    console.log(this.router.url);
    this.currentUrl = this.router.url;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
       console.log(this.previousUrl);
        
        this.currentUrl = event.url;
       console.log(this.currentUrl);

      };
    });
    this.profileChangeRequestService.getDropdownforSchool(this.UserModel).subscribe((results) => {
      console.log(results[5].Success     )
      if (results[0].Success) {
        this.schoolCategoryList = results[0].Results;
      }

      if (results[1].Success) {
        this.academicyearList = results[1].Results;
      }

      if (results[2].Success) {
        this.schoolTypeList = results[2].Results;
      }

      if (results[3].Success) {
        this.stateList = results[3].Results;
      }
      
      if (results[4].Success) {
        this.schoolManagementList = results[4].Results;
      }
      if (results[5].Success) {
        this.stateCodeList = results[5].Results;
      }
      if (results[6].Success) {
        this.genderList = results[6].Results;
      }
      if (results[7].Success) {
        this.schoolsList = results[7].Results;
      }
      if (results[8].Success) {
        this.socialCategoryList = results[8].Results;
      }
      if (results[9].Success) {
        this.religionList = results[9].Results;
      }

      if (results[10].Success) {
        this.vtList = results[10].Results;
      }
     
      
      this.route.paramMap.subscribe(params => {

        if (params.keys.length > 0) {
          this.PageRights.ActionType = params.get('actionType');

          if (this.PageRights.ActionType == this.Constants.Actions.New) {
            this.profileChangeRequestModel = new ProfileChangeRequestModel();

          } else {
            var StudentId: string = params.get('StudentId');
            let data={
              DataId: StudentId,
              DataId1:  this.Constants.GlobalAcademicYearId
            }

            this.profileChangeRequestService.GetStudentsById(data)
              .subscribe((response: any) => {
                this.profileChangeRequestModel = response.Result;

                if (this.PageRights.ActionType == this.Constants.Actions.Edit)
                  this.profileChangeRequestModel.RequestType = this.Constants.PageType.Edit;
                else if (this.PageRights.ActionType == this.Constants.Actions.View) {
                  this.profileChangeRequestModel.RequestType = this.Constants.PageType.View;
                  this.PageRights.IsReadOnly = true;
                }
                this.classId = this.profileChangeRequestModel.ClassId
                this.sectionId = this.profileChangeRequestModel.SectionId
                this.schoolId = this.profileChangeRequestModel.SchoolId
                
                this.profileChangeRequestModel.VocationalSubject = response.Result.VocationalSubjectID;
                this.onChangeAcademicYear(this.profileChangeRequestModel.AcademicYearId);
                this.onChangeState(this.profileChangeRequestModel.StateName);
                this.onChangeDivision(this.profileChangeRequestModel.DivisionId);
                this.onChangeDistrict(this.profileChangeRequestModel.DistrictCode);
                this.onChangeStateCode(this.profileChangeRequestModel.StateName)
                this.onChangeClass(this.profileChangeRequestModel.SchoolId)
                this.onChangeSection(this.profileChangeRequestModel.ClassId)
                this.studentForm = this.createStudentForm();
              });
          }
        }
      });
    });

    this.studentForm = this.createStudentForm();
  }

  changeTab(event){
    console.log(event.index)
    this.tabIndex = event.index;
  }

  onChangeAcademicYear(academicYearId: any) {
    this.academicYearId = academicYearId;
    // this.commonService.GetMasterDataByType({ DataType: 'ApprovalPhases', ParentId: academicYearId,UserId:this.UserModel.UserTypeId, SelectTitle: 'Approval Phase' }).subscribe((response: any) => {
    //   this.phaseList = response.Results;
    // });

    this.commonService.GetSectorJobRolesForStudents(this.classId, this.sectionId, this.schoolId, this.academicYearId).subscribe((response: any) => {
      
      
      
      this.vocationalSubjectList = response.Results;
      

    });
  }
  
  // onChangeAcademicYear(academicYearId: any) {
  //   this.commonService.GetMasterDataByType({ DataType: 'ApprovalPhases', ParentId: academicYearId, SelectTitle: 'Approval Phase' }).subscribe((response: any) => {
  //     this.phaseList = response.Results;
  //   });
  // }
  onChangeClass(SchoolId: any) {
    this.commonService.GetMasterDataByType({ DataType: 'SchoolClasses', ParentId: SchoolId, SelectTitle: 'Class' }).subscribe((response: any) => {
      this.classList = response.Results;
    });
  }
  onChangeSection(ClassId: any) {
    this.commonService.GetMasterDataByType({ DataType: 'Sections', ParentId: ClassId, SelectTitle: 'Sections' }).subscribe((response: any) => {
      this.sectionList = response.Results;
    });
  }

  onChangeState(stateId: any) {
    this.commonService.GetMasterDataByType({ DataType: 'Divisions', ParentId: stateId, SelectTitle: 'Division' }).subscribe((response: any) => {
      this.divisionList = response.Results;
    });
  }

  onChangeDivision(divisionId: any) {
    var Statecode = this.studentForm.get('StateName').value;

    this.commonService.GetMasterDataByType({ DataType: 'Districts', UserId: Statecode, ParentId: divisionId, SelectTitle: 'District' }).subscribe((response: any) => {
      this.districtList = response.Results;
    });
  }
  onChangeStateCode(divisionId: any) {
    var Statecode = this.studentForm.get('StateName').value;

    this.commonService.GetMasterDataByType({ DataType: 'stateCode', UserId: Statecode, ParentId: divisionId, SelectTitle: 'stateCode' }).subscribe((response: any) => {
      this.stateCodeList = response.Results;
    });
  }
  onChangeDistrict(districtId: any) {
    this.commonService.GetMasterDataByType({ DataType: 'Blocks', UserId: this.UserModel.DefaultStateId, ParentId: districtId, SelectTitle: 'Block' }).subscribe((response: any) => {
      this.blockList = response.Results;
    });
  }

  onChangeBlock(blockId: any) {
    this.commonService.GetMasterDataByType({ DataType: 'Clusters', UserId: this.UserModel.DefaultStateId, ParentId: blockId, SelectTitle: 'Cluster' }).subscribe((response: any) => {
      this.clusterList = response.Results;
    });
  }

  saveOrUpdateStudentDetails() {
    console.log(this.studentForm.value)
    if (!this.studentForm.valid) {
      this.validateAllFormFields(this.studentForm);
      return;
    }
  
    this.setValueFromFormGroup(this.studentForm, this.profileChangeRequestModel);
    this.profileChangeRequestModel.AuthUserId=this.UserModel.UserId;
    this.profileChangeRequestModel.VTId=this.UserModel.UserTypeId;
    this.profileChangeRequestModel.DateOfVTAllocation=this.CurrentDate
    this.profileChangeRequestModel.DateOfEnrollment=this.CurrentDate

    this.profileChangeRequestService.CreateStudents(this.profileChangeRequestModel)
      .subscribe((schoolResp: any) => {

        if (schoolResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );
            window.history.back();

            // this.router.navigate([RouteConstants.ProfileChangeRequest.List]);
          });
        }
        else {
          var errorMessages = this.getHtmlMessage(schoolResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('Profile deletion errors =>', error);
      });
  }
 
  //Create Student form and returns {FormGroup}
   //Create Student form and returns {FormGroup}
   createStudentForm(): FormGroup {
    return this.formBuilder.group({
    
      StudentId: new FormControl(this.profileChangeRequestModel.StudentId),
      IsActive: new FormControl({ value: this.profileChangeRequestModel.IsActive, disabled: this.PageRights.IsReadOnly }),
      FirstName: new FormControl({ value: this.profileChangeRequestModel.FirstName, disabled: this.PageRights.IsReadOnly }, [Validators.required, Validators.pattern(this.Constants.Regex.CharsWithTitleCase), Validators.maxLength(150)]),
      MiddleName: new FormControl({ value: this.profileChangeRequestModel.MiddleName, disabled: this.PageRights.IsReadOnly }, [ Validators.pattern(this.Constants.Regex.CharsWithTitleCase), Validators.maxLength(150)]),
      LastName: new FormControl({ value: this.profileChangeRequestModel.LastName, disabled: this.PageRights.IsReadOnly }, [ Validators.pattern(this.Constants.Regex.CharsWithTitleCase), Validators.maxLength(150)]),
      Mobile: new FormControl({ value: this.profileChangeRequestModel.Mobile, disabled: this.PageRights.IsReadOnly }, [Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern(this.Constants.Regex.MobileNumber)]),
      Mobile1: new FormControl({ value: this.profileChangeRequestModel.Mobile1, disabled: this.PageRights.IsReadOnly }, [ Validators.maxLength(10), Validators.minLength(10), Validators.pattern(this.Constants.Regex.MobileNumber)]),
      DateOfBirth: new FormControl({ value:   this.getDateValue(this.profileChangeRequestModel.DateOfBirth), disabled: this.PageRights.IsReadOnly }, Validators.required),
      RegistrationNo: new FormControl({ value: this.profileChangeRequestModel.RegistrationNo, disabled: this.PageRights.IsReadOnly }, Validators.required),
      Gender: new FormControl({ value: this.profileChangeRequestModel.Gender, disabled: this.PageRights.IsReadOnly }, Validators.required),
      EmailId: new FormControl({ value: this.profileChangeRequestModel.EmailId, disabled: (this.PageRights.IsReadOnly || this.PageRights.ActionType == this.Constants.Actions.Edit) }, [Validators.required, Validators.maxLength(50), Validators.pattern(this.Constants.Regex.Email)]),
      FatherName: new FormControl({ value: this.profileChangeRequestModel.FatherName, disabled: this.PageRights.IsReadOnly }, [Validators.required, Validators.pattern(this.Constants.Regex.CharsWithTitleCase), Validators.maxLength(150)]),
      FatherOccupation: new FormControl({ value: this.profileChangeRequestModel.FatherOccupation, disabled: this.PageRights.IsReadOnly }, [Validators.required, Validators.maxLength(150)]),
      FatherMobile: new FormControl({ value: this.profileChangeRequestModel.FatherMobile, disabled: this.PageRights.IsReadOnly }, [Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern(this.Constants.Regex.MobileNumber)]),
      MotherName: new FormControl({ value: this.profileChangeRequestModel.MotherName, disabled: this.PageRights.IsReadOnly }, [Validators.required, Validators.pattern(this.Constants.Regex.CharsWithTitleCase), Validators.maxLength(150)]),
      MotherOccupation: new FormControl({ value: this.profileChangeRequestModel.MotherOccupation, disabled: this.PageRights.IsReadOnly }, [Validators.required, Validators.maxLength(150)]),
      MotherMobile: new FormControl({ value: this.profileChangeRequestModel.MotherMobile, disabled: this.PageRights.IsReadOnly }, [Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern(this.Constants.Regex.MobileNumber)]),
      GuardianName: new FormControl({ value: this.profileChangeRequestModel.GuardianName, disabled: this.PageRights.IsReadOnly }, Validators.required),
      SocialCategory: new FormControl({ value: this.profileChangeRequestModel.SocialCategory, disabled: this.PageRights.IsReadOnly }, Validators.required),
      AadhaarNumber: new FormControl({ value: this.maskingStudentAadhaarNo(this.profileChangeRequestModel.AadhaarNumber), disabled: this.PageRights.IsReadOnly }),
      Religion: new FormControl({ value: this.profileChangeRequestModel.Religion, disabled: this.PageRights.IsReadOnly }, Validators.required),
      // VTId: new FormControl({ value: this.profileChangeRequestModel.VTId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      Address3: new FormControl({ value: this.profileChangeRequestModel.Address3, disabled: this.PageRights.IsReadOnly }),
      Address2: new FormControl({ value: this.profileChangeRequestModel.Address2, disabled: this.PageRights.IsReadOnly }),
      Address1: new FormControl({ value: this.profileChangeRequestModel.Address1, disabled: this.PageRights.IsReadOnly }, Validators.required),
      SchoolId: new FormControl({ value: this.profileChangeRequestModel.SchoolId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      SectionId: new FormControl({ value: this.profileChangeRequestModel.SectionId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      ClassId: new FormControl({ value: this.profileChangeRequestModel.ClassId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      AcademicYearId: new FormControl({ value: this.profileChangeRequestModel.AcademicYearId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      Statecode: new FormControl({ value: this.profileChangeRequestModel.Statecode, disabled: this.PageRights.IsReadOnly }, Validators.required),
      StateName: new FormControl({ value: this.profileChangeRequestModel.StateName, disabled: this.PageRights.IsReadOnly }),
      DivisionId: new FormControl({ value: this.profileChangeRequestModel.DivisionId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      DistrictCode: new FormControl({ value: this.profileChangeRequestModel.DistrictCode, disabled: this.PageRights.IsReadOnly }, Validators.required),      
      BlockId: new FormControl({ value: this.profileChangeRequestModel.BlockId, disabled: this.PageRights.IsReadOnly }, Validators.required),      
      Pincode: new FormControl({ value: this.profileChangeRequestModel.Pincode, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(6), Validators.minLength(6), Validators.pattern(this.Constants.Regex.Number)]),
      // CWSNStatus: new FormControl({ value: this.profileChangeRequestModel.CWSNStatus, disabled: this.PageRights.IsReadOnly }, Validators.required),
      StudentRollNumber: new FormControl({ value: this.profileChangeRequestModel.StudentRollNumber, disabled: this.PageRights.IsReadOnly }, Validators.required),
      VocationalSubject: new FormControl({ value: this.profileChangeRequestModel.VocationalSubject, disabled: this.PageRights.IsReadOnly }, Validators.required),
      ProfilePhoto: new FormControl({ value: this.profileChangeRequestModel.ProfilePhoto, disabled: this.PageRights.IsReadOnly }, ),
      Stream: new FormControl({ value: this.profileChangeRequestModel.Stream, disabled: this.PageRights.IsReadOnly }, Validators.required),
      CWSNStatus: new FormControl({ value: this.profileChangeRequestModel.CWSNStatus, disabled: this.PageRights.IsReadOnly }, Validators.required),
      City: new FormControl({ value: this.profileChangeRequestModel.City, disabled: this.PageRights.IsReadOnly }),
   
       
    });
  }


}
