import { FuseUtils } from '@fuse/utils';
import { VTStudentDetailModel } from './vt-student-detail.model';

export class VTStudentExitSurveyDetailModel {
    VTStudentDetail: VTStudentDetailModel;
    VTStudentExitSurveyDetailId: string;
    VTId: string;
    ExitStudentId:string;

    DateOfIntv: Date;
    CityOfResidence: String;
    DistrictOfResidence: string;
    BlockOfResidence: string;
    PinCode: string;
    StudentMobileNo: Number;
    ParentMobileNo: string;
    // ParentFirstName: string;
    // ParentMiddleName: string;
    // ParentLastName: string;
    ParentName:string;
    DoneInternship: number;
    CurrentlyEmployed: number;

    DetailsOfEmployment: string;
    IsFullTime: string;
    SectorsOfEmployment: string;
    IsRelevantToVocCourse: number;
    WillContHigherStudies: string;
    WillBeFullTime: string;
    CourseToPursue: string;
    OtherCourse: string;
    StreamOfEducation: string;
    WillContVocEdu: number;
    ReasonsNOTToContinue: string;
    OtherReasons: string;
    WillContSameSector: number;
    DoesFieldStudyHveVocSub: number;
    HveRegisteredOnEmploymentPortal: number;
    WillingToGetRegisteredOnNAPS: number;
    InterestedInSkillDevelopmentPgms: number;
    SectorsInterestedIn: string;
    InterestedInJobOrSelfEmployment: number;
    TopicsOfInterest: any;
    AnyPreferredLocForEmployment: string;
    PreferredLocations: string;
    CanSendTheUpdates: number;
    IsActive: boolean;
    RequestType: any;

    constructor(vtStudentExitSurveyDetailItem?: any) {
        vtStudentExitSurveyDetailItem = vtStudentExitSurveyDetailItem || {};

        this.VTStudentExitSurveyDetailId = vtStudentExitSurveyDetailItem.VTStudentExitSurveyDetailId || FuseUtils.NewGuid();
        this.VTId = vtStudentExitSurveyDetailItem.VTId || FuseUtils.NewGuid();
        this.ExitStudentId = vtStudentExitSurveyDetailItem.ExitStudentId || FuseUtils.NewGuid();
       
        this.DateOfIntv = vtStudentExitSurveyDetailItem.DateOfIntv || '';
        this.CityOfResidence = vtStudentExitSurveyDetailItem.CityOfResidence || '';
        this.DistrictOfResidence = vtStudentExitSurveyDetailItem.DistrictOfResidence || '';
        this.BlockOfResidence = vtStudentExitSurveyDetailItem.BlockOfResidence || '';
        this.PinCode = vtStudentExitSurveyDetailItem.PinCode || '';
        this.StudentMobileNo = vtStudentExitSurveyDetailItem.StudentMobileNo || '';
        this.ParentMobileNo = vtStudentExitSurveyDetailItem.ParentMobileNo || '';
        // this.ParentFirstName = vtStudentExitSurveyDetailItem.ParentFirstName || '';
        // this.ParentMiddleName = vtStudentExitSurveyDetailItem.ParentMiddleName || '';
        // this.ParentLastName = vtStudentExitSurveyDetailItem.ParentLastName || '';
        this.ParentName = vtStudentExitSurveyDetailItem.ParentName || '';
        this.DoneInternship = vtStudentExitSurveyDetailItem.DoneInternship || '';
        this.CurrentlyEmployed = vtStudentExitSurveyDetailItem.CurrentlyEmployed || '';
        this.DetailsOfEmployment = vtStudentExitSurveyDetailItem.DetailsOfEmployment || '';
        this.IsFullTime = vtStudentExitSurveyDetailItem.IsFullTime || 0;
        this.SectorsOfEmployment = vtStudentExitSurveyDetailItem.SectorsOfEmployment || '';
        this.IsRelevantToVocCourse = vtStudentExitSurveyDetailItem.IsRelevantToVocCourse || '';
        this.WillContHigherStudies = vtStudentExitSurveyDetailItem.WillContHigherStudies || '';
        this.WillBeFullTime = vtStudentExitSurveyDetailItem.WillBeFullTime || 0;
        this.CourseToPursue = vtStudentExitSurveyDetailItem.CourseToPursue || '';
        this.OtherCourse = vtStudentExitSurveyDetailItem.OtherCourse || '';
        this.StreamOfEducation = vtStudentExitSurveyDetailItem.StreamOfEducation || '';
        this.WillContVocEdu = vtStudentExitSurveyDetailItem.WillContVocEdu || '';
        this.ReasonsNOTToContinue = vtStudentExitSurveyDetailItem.ReasonsNOTToContinue || '';
        this.OtherReasons = vtStudentExitSurveyDetailItem.OtherReasons || '';
        this.WillContSameSector = vtStudentExitSurveyDetailItem.WillContSameSector || '';
        this.DoesFieldStudyHveVocSub = vtStudentExitSurveyDetailItem.DoesFieldStudyHveVocSub || '';
        this.HveRegisteredOnEmploymentPortal = vtStudentExitSurveyDetailItem.HveRegisteredOnEmploymentPortal || '';
        this.WillingToGetRegisteredOnNAPS = vtStudentExitSurveyDetailItem.WillingToGetRegisteredOnNAPS || '';
        this.InterestedInSkillDevelopmentPgms = vtStudentExitSurveyDetailItem.InterestedInSkillDevelopmentPgms || '';
        this.SectorsInterestedIn = vtStudentExitSurveyDetailItem.SectorsInterestedIn || '';
        this.InterestedInJobOrSelfEmployment = vtStudentExitSurveyDetailItem.InterestedInJobOrSelfEmployment || '';
        this.TopicsOfInterest = vtStudentExitSurveyDetailItem.TopicsOfInterest || '';
        this.AnyPreferredLocForEmployment = vtStudentExitSurveyDetailItem.AnyPreferredLocForEmployment || '';
        this.PreferredLocations = vtStudentExitSurveyDetailItem.PreferredLocations || '';
        this.CanSendTheUpdates = vtStudentExitSurveyDetailItem.CanSendTheUpdates || '';
        this.IsActive = vtStudentExitSurveyDetailItem.IsActive || true;
        this.RequestType = 0; // New
    }
}
