import { FuseUtils } from '@fuse/utils';

export class StudentReportModel {
    SrNo: number;
    VCSchoolSectorId: string;
    AcademicYearId: string;
    VCId: string;
    AcademicYearSchoolVTPSectorJobRoleId: string;
    DateOfAllocation: Date;
    VCSchoolStatus: string;
    DateOfRemoval: Date;
    VCSchoolSectorName: string;
    IsActive: boolean;
    RequestType: any;

    constructor() { }
}

export class StudentReportViewModel {
    RowIndex: string;
    StudentId: string;
    YearName: string;
    AcademicYearId: string;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    FullName: string;
    Gender: string;
    DateOfBirth: string;
    Mobile: string;
    Whatsapp: string;
    EmailId: string;
    MotherName: string;
    FatherName: string;
    StateCode: string;
    StateName: string;
    DistrictCode: string;
    DistrictName: string;
    BlockId: string;
    BlockName: string;
    Pincode: string;
    FullAddress: string;
    SchoolVillage: string;
    SchoolId: string;
    SchoolName: string;
    UDISE: string;
    Stream: string;
    ClassId: string;
    ClassName: string;
    SectionName: string;
    JobRoleName: string;
    SectorName: string;
    VTPId: string;
    VTPName: string;
    VCFullName: string;
    VTFullName: string;
    EmployerName: string;
    EstablishmentName: string;
    Title: string;
    InternshipType: string;
    DateCommenced: string;
    DateCompleted: string;
    InternshipStatus: string;
    TotalDaysStd: string;
    HrsPerDayStd: string;
    TotalInternshipHrsStd: string;
    IsInternshipCompleted: string;
    DailyDiaryAvailable: string;
    IsSafetyConcernRaised: boolean;
    SafetyConcernDetails: string;
    Attachments: string;
    Attachment1: string;
    Attachment2: string;
    Attachment3: string;
    Attachment4: string;
    Attachment5: string;
}