<div id="student-class" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/student-registrations'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        {{ studentClassModel.FullName }}
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        New Student Registration
                    </div>
                    <div class="subtitle secondary-text">
                        <span>Student Registration Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-student-class-button"
                *ngIf="PageRights.ActionType ==='new'" (click)="saveOrUpdateStudentClassDetails()">
                <span>ADD</span>
            </button>

            <button mat-raised-button class="save-student-class-button"
                *ngIf="PageRights.ActionType ==='edit'" (click)="saveOrUpdateStudentClassDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="studentClassForm" [formGroup]="studentClassForm" class="student-class w-100-p p-24 mr-24"
                    fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex fusePerfectScrollbar>
                    <!-- <mat-tab label="Basic Info"> -->
                    <!-- <div class="tab-content p-24" fusePerfectScrollbar> -->

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                    fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline"  fxFlex="33" fxFlex.lt-md="50"
                            fxFlex.lt-sm="100">
                            <mat-label>Select Academic Year</mat-label>

                            <mat-select formControlName="AcademicYearId" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly"
                                (selectionChange)="onChangeAcademicYear($event.value)">
                                <mat-option [value]="academicYearItem.Id"
                                    *ngFor="let academicYearItem of academicYearList">
                                    {{ academicYearItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="studentClassForm.controls.AcademicYearId.invalid">
                                {{getErrorMessage(studentClassForm, 'AcademicYearId')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="50"
                            fxFlex.lt-sm="100">
                            <mat-label>Select Class</mat-label>

                            <mat-select formControlName="ClassId" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeClass($event.value)">
                                <mat-option [value]="classItem.Id" *ngFor="let classItem of classList">
                                    {{ classItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="studentClassForm.controls.ClassId.invalid">
                                {{getErrorMessage(studentClassForm, 'ClassId')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50"
                            fxFlex.lt-sm="100">
                            <mat-label>Select Section</mat-label>

                            <mat-select formControlName="SectionId" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="sectionItem.Id" *ngFor="let sectionItem of sectionList">
                                    {{ sectionItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="studentClassForm.controls.SectionId.invalid">
                                {{getErrorMessage(studentClassForm, 'SectionId')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                    fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50"
                            fxFlex.lt-sm="100">
                            <mat-label>First Name</mat-label>
                            <input matInput name="firstName" formControlName="FirstName" required
                                placeholder="Ex. Rakesh" matTooltip="First letter should be capital."
                                matTooltipPosition="above">
                            <mat-error *ngIf="studentClassForm.controls.FirstName.invalid">
                                {{getErrorMessage(studentClassForm, 'FirstName')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="50"
                            fxFlex.lt-sm="100">
                            <mat-label>Middle Name</mat-label>
                            <input matInput name="middleName" formControlName="MiddleName" placeholder="Ex. Kumar" matTooltip="First letter should be capital."
                            matTooltipPosition="above">
                            <mat-error *ngIf="studentClassForm.controls.MiddleName.invalid">
                                {{getErrorMessage(studentClassForm, 'MiddleName')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50"
                            fxFlex.lt-sm="100">
                            <mat-label>Last Name</mat-label>
                            <input matInput name="lastName" formControlName="LastName" placeholder="Ex. Nayak"
                            matTooltip="First letter should be capital."
                                matTooltipPosition="above">
                            <mat-error *ngIf="studentClassForm.controls.LastName.invalid">
                                {{getErrorMessage(studentClassForm, 'LastName')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                    fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline"  fxFlex="50" fxFlex.lt-md="75"
                            fxFlex.lt-sm="100">
                            <mat-label>Gender</mat-label>
                            <mat-select name="gender" formControlName="Gender" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="genderItem.Id" *ngFor="let genderItem of genderList">
                                    {{ genderItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="studentClassForm.controls.Gender.invalid">
                                {{getErrorMessage(studentClassForm, 'Gender')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75"
                            fxFlex.lt-sm="100">
                            <mat-label>Date Of Enrollment</mat-label>
                            <input matInput [matDatepicker]="dateOfEnrollment" name="dateOfEnrollment" required
                                [max]="CurrentDate" formControlName="DateOfEnrollment"
                                [disabled]="PageRights.IsReadOnly">
                            <mat-datepicker-toggle matSuffix [for]="dateOfEnrollment"></mat-datepicker-toggle>
                            <mat-datepicker #dateOfEnrollment></mat-datepicker>
                            <mat-error *ngIf="studentClassForm.controls.DateOfEnrollment.invalid">
                                {{getErrorMessage(studentClassForm, 'DateOfEnrollment')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                    fxLayoutGap.lt-md="0px"
                        *ngIf="PageRights.ActionType ==='edit' || PageRights.ActionType ==='view'">
                        <mat-form-field appearance="outline" fxFlex="0 1 calc(35%)" fxFlex.lt-md="0 1 calc(70%)"
                            fxFlex.lt-sm="100%">
                            <mat-label>Date Of Dropout</mat-label>
                            <input matInput [matDatepicker]="dateOfDropout" name="dateOfDropout" [max]="CurrentDate"
                                (dateChange)="onChangeDateEnableDisableCheckBox(studentClassForm, $event)"
                                [min]="studentClassForm.controls.DateOfEnrollment.value" formControlName="DateOfDropout"
                                [disabled]="PageRights.IsReadOnly">
                            <mat-datepicker-toggle matSuffix [for]="dateOfDropout"></mat-datepicker-toggle>
                            <mat-datepicker #dateOfDropout></mat-datepicker>
                            <mat-error *ngIf="studentClassForm.controls.DateOfDropout.invalid">
                                {{getErrorMessage(studentClassForm, 'DateOfDropout')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-icon matDatepickerToggleIcon
                            (click)="clearDateValueInFormControl(studentClassForm, 'DateOfDropout', $event)"
                            *ngIf="studentClassForm.controls.DateOfDropout.value && PageRights.ActionType !='view'">
                            clear</mat-icon>

                        <mat-form-field appearance="outline"  fxFlex="0 1 calc(65%)" fxFlex.lt-md="75"
                            fxFlex.lt-sm="100" *ngIf="studentClassForm.controls.DateOfDropout.value">
                            <mat-label>Dropout Reason</mat-label>
                            <input matInput name="dropoutReason" formControlName="DropoutReason"
                                [required]="studentClassForm.controls.DateOfDropout.value != null || studentClassForm.controls.DateOfDropout.value != ''"
                                [disabled]="studentClassForm.controls.DateOfDropout.value == null || studentClassForm.controls.DateOfDropout.value == ''">
                            <mat-error *ngIf="studentClassForm.controls.DropoutReason.invalid">
                                {{getErrorMessage(studentClassForm, 'DropoutReason')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                    fxLayoutGap.lt-md="0px">

                         <mat-form-field appearance="outline"  fxFlex="50" fxFlex.lt-md="50"
                            fxFlex.lt-sm="100">
                            <mat-label>Mobile Number</mat-label>
                            <input matInput name="mobile" formControlName="Mobile" digitOnly minlength="10"
                                maxlength="10" placeholder="Ex. 9800098000">
                            <mat-error *ngIf="studentClassForm.controls.Mobile.invalid">
                                {{getErrorMessage(studentClassForm, 'Mobile')}}
                            </mat-error>
                        </mat-form-field>

                        <span class="checkbox-space">Is Active?</span>
                        <mat-checkbox matInput formControlName="IsActive" [disabled]="PageRights.IsReadOnly">
                        </mat-checkbox>
                    </div>
                    <!-- </div> -->
                    <!-- </mat-tab>
                    </mat-tab-group> -->
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>