<div id="school" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <!-- {{ 'SCHOOL.SCHOOLS' | translate }} -->
                    Gratitude From Students
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0" style=" width: 100%;
        max-width: 480px;
        border-radius: 28px;
        overflow: hidden;background-color: white;">
                <form [formGroup]="StudentNoticeBoardSearchForm">
                    <div class="search" style=" width: 100%;
                height: 48px;
                line-height: 48px;
                padding: 0 18px;" fxFlex fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon>search</mat-icon>
                        <!-- <input #filter (keyup)="applySearchFilter($event.target.value)"  formControlName="filterText" placeholder="Search for a student"> -->
                        <input #filter (keyup)="applySearchFilterStudent($event.target.value)" placeholder="Search"
                            style=" width: 100%;
                        height: 48px;
                        min-height: 48px;
                        max-height: 48px;
                        padding: 0 16px;
                        border: none;
                        outline: none;">
                    </div>
                </form>
            </div>
            <!-- / SEARCH -->
            <!-- ADD BUTTON -->
            <div class="action-buttons">


                <button mat-raised-button [routerLink]="['./','new']"
                    class="add-sector-job-role-button fuse-white mt-24 mt-md-0 btnC" style="display: none;">
                    <span>{{'SCHOOL.ADD_NEW_SCHOOL' | translate}}</span>
                </button>

                <a *ngIf="PageRights.IsExport" class="export-excel" (click)="exportExcel()" style="display: none;">
                    <mat-icon> cloud_download</mat-icon>
                </a>
            </div>
            <!-- / ADD BUTTON -->
        </div>
        <!-- / HEADER -->

        <!-- Filter Section -->
        <!-- <div class="filter-content-card">
            <div class="filter-content">
                <form name="StudentNoticeBoardFilterForm" [formGroup]="StudentNoticeBoardFilterForm"
                    class="vc-issue-report" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Filter1 </mat-label>

                            <mat-select formControlName="Class" name="class" appearance="outline"
                                (selectionChange)="onChangeSection($event.value)">
                                <mat-option [value]="ctem.Id" *ngFor="let ctem of classList">
                                    {{ ctem.Name }}
                                </mat-option>
                                <mat-option value="Today">Today</mat-option>
                                <mat-option value="Yesterday">Yesterday</mat-option>
                                <mat-option value="Last 7 days">Last 7 days</mat-option>
                                <mat-option value="This Month">This Month</mat-option>
                                <mat-option value="LAst Month">LAst Month</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Filter2 </mat-label>

                            <mat-select formControlName="Class" name="class" appearance="outline"
                                (selectionChange)="onChangeSection($event.value)">
                                <mat-option [value]="ctem.Id" *ngFor="let ctem of classList">
                                    {{ ctem.Name }}
                                </mat-option>
                                <mat-option value="Today">Today</mat-option>
                                <mat-option value="Yesterday">Yesterday</mat-option>
                                <mat-option value="Last 7 days">Last 7 days</mat-option>
                                <mat-option value="This Month">This Month</mat-option>
                                <mat-option value="LAst Month">LAst Month</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Filter3 </mat-label>

                            <mat-select formControlName="Class" name="class" appearance="outline"
                                (selectionChange)="onChangeSection($event.value)">
                                <mat-option [value]="ctem.Id" *ngFor="let ctem of classList">
                                    {{ ctem.Name }}
                                </mat-option>
                                <mat-option value="Today">Today</mat-option>
                                <mat-option value="Yesterday">Yesterday</mat-option>
                                <mat-option value="Last 7 days">Last 7 days</mat-option>
                                <mat-option value="This Month">This Month</mat-option>
                                <mat-option value="LAst Month">LAst Month</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="clear-filter pr-1" fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="onLoadStudentNoticeBoardByCriteria()"><span>SEARCH</span></button>
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="resetFilters()"><span>CLEAR</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div> -->
        <!-- /Filter Section -->


        <!-- CONTENT CARD -->
        <div class="content-card rounded-12 shadow-dark-80" style="overflow: auto;">
            <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start"
                style="height: auto !important;">
                <div fxFlex="0 1 calc(47%)" fxFlex.lt-md="0 1 calc(47%)" fxFlex.lt-sm="100%"
                    *ngFor="let studGratitude of studentGratitudeData; index as i;" style="border-radius: 18px;border: 1px solid #dee2e6;padding: 20px;margin: 15px;float: left;overflow-x: auto;
                    word-wrap: break-word;">
                    <div> <span>{{studGratitude.GratitudeDate | date:"dd MMM yyyy"}}</span></div>
                    <div style="border-radius: 18px;border: 1px solid #dee2e6;padding: 20px;">
                        <h4 class="msg-label" style="font-weight: 600;">Message</h4>
                        <p>{{studGratitude.GratitudeDesc}}</p>
                        <hr />
                        <h4 style="margin:0;padding:0;font-weight: 600;">{{studGratitude.StudentName}}</h4>
                        <h6 style="margin:0;padding:0;">{{studGratitude.StudentAddress1}},
                            {{studGratitude.StudentAddress2}}, {{studGratitude.StudentCity}}</h6>
                    </div>
                </div>
            </div>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>