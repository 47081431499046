import { FuseUtils } from '@fuse/utils';

export class SchoolSectorJobRoleModel {
    SchoolSectorJobRolesId: string;
    SchoolId: string;
    SectorId: string;
    JobRoleId: string;
    Remarks: string;
    IsActive: boolean;
    RequestType: any;
    StateCode: string; // New Parameters
    AcademicYearId: string;


    constructor(schoolSectorJobRoleItem?: any) {
        schoolSectorJobRoleItem = schoolSectorJobRoleItem || {};

        this.SchoolSectorJobRolesId = schoolSectorJobRoleItem.SchoolSectorJobRolesId || FuseUtils.NewGuid();
        this.SchoolId = schoolSectorJobRoleItem.SchoolId || FuseUtils.NewGuid();
        this.SectorId = schoolSectorJobRoleItem.SectorId || '';
        this.JobRoleId = schoolSectorJobRoleItem.JobRoleId || '';
        this.Remarks = schoolSectorJobRoleItem.Remarks || '';
        this.IsActive = schoolSectorJobRoleItem.IsActive || true;
        this.RequestType = 0; // New
        this.StateCode = schoolSectorJobRoleItem.StateCode || '';
        this.AcademicYearId = schoolSectorJobRoleItem.AcademicYearId || '';

    }
}
