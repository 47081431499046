export class StudentNoticeBoardModel {
    NoticeId: String
    PublishDate: string;
    ExpiryDate: string;
    NoticeDescription: string;
    StudentNoticeBoardId: string;
    RequestType: any;
    AuthUserId: string; // New Parameters
    IsActive: boolean;
    NoticeType: string;
    NoticeTitle: string;


    constructor(studentNoticeBoardItem?: any) {
        studentNoticeBoardItem = studentNoticeBoardItem || {};
        //this.NoticeId = studentNoticeBoardItem.NoticeId || FuseUtils.NewGuid();
        this.NoticeId = studentNoticeBoardItem.NoticeId || '00000000-0000-0000-0000-000000000000';
        this.PublishDate = studentNoticeBoardItem.SectorId || '';
        this.ExpiryDate = studentNoticeBoardItem.AuthUserId || '';
        this.NoticeDescription = studentNoticeBoardItem.Notice || '';
        this.RequestType = 0; // New Parameters
        this.AuthUserId = studentNoticeBoardItem.AuthUserId || '';
        this.IsActive = studentNoticeBoardItem.IsActive || true;
        this.NoticeType = studentNoticeBoardItem.NoticeType || 'Student';
        this.NoticeTitle = studentNoticeBoardItem.NoticeType || '';

    }
}



