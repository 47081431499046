<div id="school" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    {{ 'SCHOOL.SCHOOLS' | translate }}
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <form [formGroup]="schoolForm">
                    <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon>search</mat-icon>
                        <input formControlName="filterText" placeholder="Search for a school">
                    </div>
                </form>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">
                <button mat-raised-button class="fuse-white filter-button"
                    (click)="IsShowFilter=!IsShowFilter"><span>FILTERS</span>
                </button>

                <button mat-raised-button [routerLink]="['./','new']" skipLocationChange="true" replaceUrl="false"
                    class="add-school-button fuse-white mt-24 mt-md-0">
                    <span>{{'SCHOOL.ADD_NEW_SCHOOL' | translate}}</span>
                </button>

                <!--<a *ngIf="PageRights.IsExport" class="export-excel"
                   (click)="exporter.exportTable('xlsx', {fileName:'Schools', sheet: 'Schools', Props: {Author: 'Lighthouse'}})">
                    <mat-icon> cloud_download</mat-icon>
                </a>-->

                <a *ngIf="PageRights.IsExport" class="export-excel" (click)="exportExcel()">
                    <mat-icon> cloud_download</mat-icon>
                </a>
            </div>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- Filter Section -->
        <div class="filter-content-card" *ngIf="IsShowFilter">
            <div class="filter-content">
                <form name="schoolFilterForm" [formGroup]="schoolFilterForm" class="vc-issue-report" fxLayout="column"
                    fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field fxFlex="30" fxFlex.lt-md="10" fxFlex.lt-sm="100">
                            <mat-label>Status of Implementation</mat-label>
                            <mat-select name="statusOfImplemented" formControlName="StatusOfImplemented"
                                appearance="fill">
                                <mat-option value="">Status of Implementation</mat-option>
                                <mat-option value="true">Yes</mat-option>
                                <mat-option value="false">No</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="30" fxFlex.lt-md="10" fxFlex.lt-sm="100">
                            <mat-label>Status</mat-label>
                            <mat-select name="status" formControlName="Status" appearance="fill">
                                <mat-option value="">Status</mat-option>
                                <mat-option value="true">Active</mat-option>
                                <mat-option value="false">Inactive</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="clear-filter pr-1" fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="onLoadSchoolByFilters()"><span>SEARCH</span></button>
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="resetFilters()"><span>CLEAR</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- /Filter Section -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!--<mat-table matTableExporter class="school-table responsive-table" [dataSource]="tableDataSource"
                    #exporter="matTableExporter" [hiddenColumns]="[8]" matSort fusePerfectScrollbar
                    [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">-->

            <mat-table class="school-table responsive-table" [dataSource]="tableDataSource" matSort fusePerfectScrollbar
                [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- SchoolName Column -->
                <ng-container matColumnDef="SchoolName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>
                        School
                    </mat-header-cell>
                    <mat-cell *matCellDef="let school">
                        <p class="text-truncate">{{school.SchoolName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- CategoryName Column -->
                <ng-container matColumnDef="CategoryName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
                        School Category
                    </mat-header-cell>
                    <mat-cell *matCellDef="let school" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{school.CategoryName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Udise Column -->
                <ng-container matColumnDef="Udise">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>UDISE Code</mat-header-cell>
                    <mat-cell *matCellDef="let school">
                        <p class="text-truncate">{{school.Udise}}</p>
                    </mat-cell>
                </ng-container>

                <!-- DivisionId Column -->
                <ng-container matColumnDef="DivisionName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
                        Division
                    </mat-header-cell>
                    <mat-cell *matCellDef="let school" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{school.DivisionName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- DistrictCode Column -->
                <ng-container matColumnDef="DistrictName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
                        District
                    </mat-header-cell>
                    <mat-cell *matCellDef="let school" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{school.DistrictName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- BlockName Column -->
                <ng-container matColumnDef="BlockName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Block</mat-header-cell>
                    <mat-cell *matCellDef="let school" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{school.BlockName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- IsImplemented Column -->
                <ng-container matColumnDef="IsImplemented">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
                        Is Implemented?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let school" fxHide fxShow.gt-xs>
                        <mat-icon *ngIf="school.IsImplemented" class="active-icon green-600 s-16">check</mat-icon>
                        <mat-icon *ngIf="!school.IsImplemented" class="active-icon red-500 s-16">close</mat-icon>
                    </mat-cell>
                </ng-container>

                <!-- IsActive Column -->
                <ng-container matColumnDef="IsActive">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
                        Is Active?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let school" fxHide fxShow.gt-xs>
                        <mat-icon *ngIf="school.IsActive" class="active-icon green-600 s-16">check</mat-icon>
                        <mat-icon *ngIf="!school.IsActive" class="active-icon red-500 s-16">close</mat-icon>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Actions" stickyEnd>
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let school" fxShow.gt-xs>
                        <button *ngIf="PageRights.IsView" mat-icon-button
                            [routerLink]="['/schools', 'view', school.SchoolId]" skipLocationChange="true"
                            replaceUrl="false">
                            <mat-icon color="primary">visibility</mat-icon>
                        </button>
                        <button *ngIf="PageRights.IsEdit" mat-icon-button
                            [routerLink]="['/schools', 'edit', school.SchoolId]" skipLocationChange="true"
                            replaceUrl="false">
                            <mat-icon color="primary">edit</mat-icon>
                        </button>
                        <button mat-icon-button color="warn" (click)="onDeleteSchool(school.SchoolId)"
                            *ngIf="PageRights.IsDelete">
                            <mat-icon>delete_outline</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let school; columns: displayedColumns;" class="school" matRipple>
                </mat-row>
            </mat-table>

            <!-- <mat-paginator #ListPaginator [length]="SearchBy.TotalResults" [pageIndex]="SearchBy.PageIndex"
                [pageSize]="SearchBy.PageSize" [pageSizeOptions]="SearchBy.PageSizeOptions"
                [showFirstLastButtons]="SearchBy.ShowFirstLastButtons" (page)="onPageIndexChanged($event)">
            </mat-paginator> -->
            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>