import { FuseUtils } from '@fuse/utils';

export class VocationalCoordinatorModel {
    VCId: string;
    VTPId: string; 
    FirstName: string;
    MiddleName: string;
    LastName: string;
    FullName: string;
    Mobile: string;
    Mobile1: string;
    EmailId: string;
    NatureOfAppointment: string;  
    Gender: string;
    DateOfJoining: Date; 
    DateOfResignation?: Date; 
    AcademicYearId:string;  
    IsActive: boolean;
    RequestType: any;
    StateCode: string; // New Parameters
    
    constructor(vocationalCoordinatorItem?: any) {
        vocationalCoordinatorItem = vocationalCoordinatorItem || {};
        
        this.VCId = vocationalCoordinatorItem.VCId || FuseUtils.NewGuid();
        this.VTPId = vocationalCoordinatorItem.VTPId || ''; 
        this.FirstName = vocationalCoordinatorItem.FirstName || '';
        this.MiddleName = vocationalCoordinatorItem.MiddleName || '';
        this.LastName = vocationalCoordinatorItem.LastName || '';
        this.FullName = vocationalCoordinatorItem.FullName || '';
        this.Mobile = vocationalCoordinatorItem.Mobile || '';
        this.Mobile1 = vocationalCoordinatorItem.Mobile1 || '';
        this.EmailId = vocationalCoordinatorItem.EmailId || '';
        this.NatureOfAppointment = vocationalCoordinatorItem.NatureOfAppointment || ''; 
        this.Gender = vocationalCoordinatorItem.Gender || '';
        this.DateOfJoining = vocationalCoordinatorItem.DateOfJoining || ''; 
        this.DateOfResignation = vocationalCoordinatorItem.DateOfResignation || ''; 
        this.AcademicYearId = vocationalCoordinatorItem.AcademicYearId || '';
        this.StateCode = vocationalCoordinatorItem.StateCode || '';

        this.IsActive = vocationalCoordinatorItem.IsActive || true;
        this.RequestType = 0; // New
    }
}
