import { Component, OnInit, NgZone, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { DropdownModel } from 'app/models/dropdown.model';
import { environment } from 'environments/environment';
import { StudentModel } from '../student/student.model';
import { InternshipAssignmentService } from './internship-assignment.service';
import { InternshipAssignmentModel } from './internship-assignment.model';
import { ReviewInternshipReportComponent } from '../internship-approval/review-internship-report/review-internship-report.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';


@Component({
  selector: 'app-internship-assignment',
  templateUrl: './internship-assignment.component.html',
  styleUrls: ['./internship-assignment.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class InternshipAssignmentComponent extends BaseListComponent<InternshipAssignmentModel> implements OnInit {
  internshipAssignmentSearchForm: FormGroup;
  internshipAssignmentModel: InternshipAssignmentModel;
  currentAcademicYearId: string;
  academicYearList: [DropdownModel];
  sectorList: [DropdownModel];
  vtpList: [DropdownModel];
  filteredVtpSectorItems: any;
  schoolList: [DropdownModel];
  classList: [DropdownModel];
  sectionList: [DropdownModel];
  filteredSchoolItems: any;
  internshipAssignmentFilterForm: FormGroup;
  ApiBaseUrlImage = this.Constants.CommonImageUrl.Account;
  ApiBaseUrlImageAttachments = this.Constants.CommonImageUrl.Attachments;
  FinalInternshipImageBaseURL =this.Constants.CommonImageUrl.FinalInternshipReports;
  tableDataSource: any;
  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private translationLoaderService: FuseTranslationLoaderService,
    public zone: NgZone,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private internshipAssignmentService: InternshipAssignmentService, private dialog: MatDialog,) {
    super(commonService, router, routeParams, snackBar, zone);
    // this.translationLoaderService.loadTranslations(english, guarati);
    this.internshipAssignmentSearchForm = this.formBuilder.group({ filterText: '' });
    this.internshipAssignmentModel = new InternshipAssignmentModel();
    this.internshipAssignmentFilterForm = this.createSchoolVtpSectorFilterForm();


  }

  ngOnInit(): void {
    this.internshipAssignmentSearchForm = this.formBuilder.group({ filterText: '' });

    // Load initial schools data
    this.onLoadInternshipAssignmentByCriteria();

    this.internshipAssignmentSearchForm.get('filterText').valueChanges.pipe(
      // if character length greater then 2
      filter(res => {
        this.onLoadInternshipAssignmentByCriteria();

        if (res.length == 0) {
          this.SearchBy.Name = '';
          this.onLoadInternshipAssignmentByCriteria();
          return false;
        }

        return res.length > 2
      }),

      // Time in milliseconds between key events
      debounceTime(650),

      // If previous query is diffent from current   
      distinctUntilChanged()

      // subscription for response
    ).subscribe((searchText: string) => {
      this.SearchBy.Name = searchText;
      this.onLoadInternshipAssignmentByCriteria();
    });
  }

  ngAfterViewInit() {
    this.tableDataSource.paginator = this.ListPaginator;
  }

  onPageIndexChanged(evt) {
    // console.log(evt)
    // if(evt.pageIndex == 0){
    //   this.SearchBy.PageIndex = 1;
    // }else{
    //   this.SearchBy.PageIndex = evt.pageIndex;
    // }
    this.SearchBy.PageIndex = evt.pageIndex;

    this.SearchBy.PageSize = evt.pageSize;

    this.onLoadInternshipAssignmentByCriteria();
  }

  onLoadInternshipAssignmentByCriteria(): any {
    this.IsLoading = true;

    console.log(this.Constants.GlobalSchoolId)


    this.SearchBy.VTId = this.UserModel.UserTypeId;

    this.SearchBy.UserTypeId = this.UserModel.UserTypeId;

    let internshipAssignmentrequestParams = {
      DataId: this.UserModel.UserTypeId
    };
    this.internshipAssignmentService.GetAllByCriteria(internshipAssignmentrequestParams).subscribe(response => {
      this.displayedColumns = ['StudentName', 'InternshipImage', 'CertificatePicture', 'ProjectReport', 'ReportDetail', 'Actions'];
      const dateArray: GroupByDateArray[] = [];
      for (let k = 0; k < response.Results.length; k++) {
        let resDate = dateArray.filter(x => x.date == response.Results[k].ReportDate);
        if (resDate == null || resDate.length == 0) {
          dateArray.push({
            date: response.Results[k].ReportDate,
            isGroupBy: true
          })
        }
      }
      console.log(dateArray);

      let soretdDateArray = dateArray.sort((a, b) => a.date > b.date ? -1 : 1);
      const finalArrayInternshipAssignment: (InternshipAssignmentModel | GroupByDateArray)[] = [];
      for (let i = 0; i < soretdDateArray.length; i++) {
        let yyd = soretdDateArray.filter(x => x.date == soretdDateArray[i].date);
        if (yyd != null) {
          yyd.forEach(element => {
            finalArrayInternshipAssignment.push({
              date: element.date,
              isGroupBy: true
            });
          });
        }
        let finalDate = response.Results.filter(x => x.ReportDate == soretdDateArray[i].date);


        if (finalDate != null || finalDate != '') {
          finalDate.forEach(element => {
            finalArrayInternshipAssignment.push(element);
          });
        }
      }
      this.tableDataSource.data = finalArrayInternshipAssignment
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;
      //this.SearchBy.TotalResults = response.TotalResults;
      this.SearchBy.TotalResults = this.tableDataSource.filteredData.length

      setTimeout(() => {
        this.ListPaginator.pageIndex = this.SearchBy.PageIndex;
        this.ListPaginator.length = this.SearchBy.TotalResults;
      });
      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          // this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });

  }



  DeleteNoticesById(noticeId: any) {
    this.dialogService
      .openConfirmDialog(this.Constants.Messages.DeleteConfirmationMessage)
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          this.internshipAssignmentService.DeleteNoticesById(noticeId)
            .subscribe((schoolResp: any) => {

              this.zone.run(() => {
                if (schoolResp.Success) {
                  this.showActionMessage(
                    this.Constants.Messages.RecordDeletedMessage,
                    this.Constants.Html.SuccessSnackbar
                  );
                }
                this.ngOnInit();
              }, error => {
                console.log('School deletion errors =>', error);
              });

            });
          this.ngOnInit();
        }
      });
  }

  exportExcel(): void {
    this.IsLoading = true;
    this.SearchBy.PageIndex = 0;
    this.SearchBy.PageSize = 10000;

    this.internshipAssignmentService.GetNoticesByCriteria(this.SearchBy).subscribe(response => {
      response.Results.forEach(
        function (obj) {
          if (obj.hasOwnProperty('IsActive')) {
            obj.IsActive = obj.IsActive ? 'Yes' : 'No';
          }
          if (obj.hasOwnProperty('IsImplemented')) {
            obj.IsImplemented = obj.IsImplemented ? 'Yes' : 'No';
          }
          delete obj.SchoolId;
          delete obj.StudentId;
          delete obj.AcademicYearId;
          delete obj.SectionId;
          delete obj.ClassId;
          delete obj.TotalRows;
        });

      this.exportExcelFromTable(response.Results, "Student");

      this.IsLoading = false;
      this.SearchBy.PageIndex = 0;
      this.SearchBy.PageSize = 10;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  onLoadInternshipAssignmentByFilters(): any {
    this.onLoadInternshipAssignmentByCriteria();
  }

  resetFilters(): void {
    this.internshipAssignmentFilterForm.reset();

    this.onLoadInternshipAssignmentByCriteria();

    this.internshipAssignmentFilterForm.reset();
    // this.StudentNoticeBoardFilterForm.get('AcademicYearId').setValue(this.currentAcademicYearId);
    // this.SearchBy.SectorGuid = ''
  }
  //Create SchoolVtpSectorFilter form and returns {FormGroup}
  createSchoolVtpSectorFilterForm(): FormGroup {
    return this.formBuilder.group({
      // AcademicYearId: new FormControl(),
      SectorId: new FormControl(),
      Class: new FormControl(),
      Section: new FormControl(),
      // SchoolId: new FormControl(),
    });
  }

  onChangeClass() {
    this.commonService.GetMasterDataByType({ DataType: 'ClassesByVT', ParentId: this.Constants.GlobalSchoolId, UserId: this.UserModel.UserTypeId, SelectTitle: 'Class' }).subscribe((response: any) => {
      this.classList = response.Results;
    });
  }
  onChangeSection(ClassId: any) {
    this.commonService.GetMasterDataByType({ DataType: 'Sections', ParentId: ClassId, SelectTitle: 'Sections' }).subscribe((response: any) => {
      this.sectionList = response.Results;
    });
  }

  isGroup(index, item): boolean {
    return item.isGroupBy;
  }

  onClickReviewReport(studentId: string, internshipId: string, isCompleted: string, isIRReviewed: string) {
    let studentData: any = {};
    studentData.StudentId = studentId;
    studentData.InternshipId = internshipId;
    studentData.IsCompleted = isCompleted;
    studentData.IsIRReviewed = isIRReviewed;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.maxWidth = "80%";
    dialogConfig.width = "80%";
    //  dialogConfig.height="765px";
    dialogConfig.height = "auto";
    dialogConfig.data = studentData;
    this.dialog.open(ReviewInternshipReportComponent, dialogConfig).afterClosed()
      .subscribe(AcknowledgeData => {
        // console.log(AcknowledgeData.ReportViewed);
        // if (newCollegeAdded.AddedNewCollge == true) {
        // this.ngOnInit();
         if (AcknowledgeData.ReportViewed == true && AcknowledgeData.IsReviewReport == true) {
        var currentDate = new Date();
        var xxx = 'Internship Report reviewed BY :' + this.UserModel.RoleCode + ' ' + this.UserModel.UserName + ', On Dated : ' + this.DateFormatPipe.transform(currentDate, this.Constants.ServerDateFormat);
      
        this.internshipAssignmentService.setIRReViewedStatus(studentId, internshipId, AcknowledgeData.ReportViewed, xxx).subscribe((response) => {
          if (response.Success) {
           
            this.zone.run(() => {
              this.showActionMessage(
                response.Messages[0],
                this.Constants.Html.SuccessSnackbar
              );
              this.onLoadInternshipAssignmentByCriteria();
              //  this.changeTab(1);
            });
          } else {
            this.zone.run(() => {
              this.showActionMessage(
                response.Messages[0],
                this.Constants.Html.ErrorSnackbar,
              );

            });
          }
        });

        } 
        else {
          this.zone.run(() => {
            //@ts-ignore
            this.snackBar.open("No data found..", "Dismiss", {
              duration: 5000,
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
              panelClass: ["error-snackbar"]
            });
          });
          this.showActionMessage(
            "Final Internship data is not available for report review so you can not mark it as Review Completd...!!",
            this.Constants.Html.ErrorSnackbar,
          );
          return false;
        }
      });
  }
}

interface GroupByDateArray {
  date: string;
  isGroupBy: boolean;
}
