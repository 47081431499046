import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
// import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { RouteConstants } from 'app/constants/route.constant'
import { StudentService } from '../student.service';
import { StudentModel } from '../student.model';
import { DropdownModel } from 'app/models/dropdown.model';
import { FileUploadModel } from 'app/models/file.upload.model';
import { ReportService } from 'app/reports/report.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'student',
  templateUrl: './create-student.component.html',
  styleUrls: ['./create-student.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CreateStudentComponent extends BaseComponent<StudentModel> implements OnInit {
  studentForm: FormGroup;
  studentModel: StudentModel;
  dataUploadForm: FormGroup;

  fileUploadModel: FileUploadModel;
  schoolCategoryList: [DropdownModel];
  academicyearList: [DropdownModel];
  phaseList: [DropdownModel];
  divisionList: [DropdownModel];
  vocationalSubjectList: [DropdownModel];
  stateList: [DropdownModel];
  districtList: [DropdownModel];
  schoolTypeList: [DropdownModel];
  schoolManagementList: [DropdownModel];
  blockList: [DropdownModel];
  clusterList: [DropdownModel];
  stateCodeList: [DropdownModel];
  genderList: [DropdownModel];
  schoolsList: [DropdownModel];
  socialCategoryList: [DropdownModel];
  classList: [DropdownModel];
  sectionList: [DropdownModel];
  religionList: [DropdownModel];
  subjectList: [DropdownModel];
  vtList: [DropdownModel];
  isAvailableUploadedExcel: boolean = false;
  uploadedFileUrl: string;
  tabButton = ''
  id: any
  // subjectList: [DropdownModel];

  // subjectList = [{ Id: 'Arts', Name: 'Arts' }, { Id: 'Science', Name: 'Science' }, { Id: 'Commerce', Name: 'Commerce' }]
  cwList = [{ Id: 'No', Name: 'No' }, { Id: 'Yes', Name: 'Yes' }]


  tabIndex = 0;
  previousUrl: string = null;
  currentUrl: string = null;
  schoolId: any;
  academicYearId: any;
  classId: any;
  sectionId: any;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private route: ActivatedRoute,
    private studentService: StudentService,
    private dialogService: DialogService,
    private reportService: ReportService,
    private formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Set the default school Model
    this.studentModel = new StudentModel();
    this.fileUploadModel = new FileUploadModel();


  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        if (this.previousUrl == '/vt-student') {
          this.tabButton = 'VTSTUDENT'

        } else {

          this.tabButton = 'VTSTUDENTDETAILS'
        }
        this.currentUrl = event.url;
      });

    this.studentService.getDropdownforSchool(this.UserModel).subscribe((results) => {

      if (results[0].Success) {
        this.schoolCategoryList = results[0].Results;
      }

      if (results[1].Success) {
        this.academicyearList = results[1].Results;
      }

      if (results[2].Success) {
        this.schoolTypeList = results[2].Results;
      }

      if (results[3].Success) {
        this.stateList = results[3].Results;
        for (const i of results[3].Results) {
          this.studentForm.get('StateName').setValue(i.Id);
          this.onChangeState(i.Id)
        }
      }

      if (results[4].Success) {
        this.schoolManagementList = results[4].Results;
      }
      if (results[5].Success) {
        this.stateCodeList = results[5].Results;
        for (const i of results[5].Results) {
          this.studentForm.get('Statecode').setValue(i.Id);
          this.onChangeState(i.Id)
        }
      }
      if (results[6].Success) {
        this.genderList = results[6].Results;
      }
      if (results[7].Success) {
        this.schoolsList = results[7].Results;

      }
      if (results[8].Success) {
        this.socialCategoryList = results[8].Results;
      }
      if (results[9].Success) {
        this.religionList = results[9].Results;
      }

      if (results[10].Success) {
        this.vtList = results[10].Results;
      }
      if (results[12].Success) {
        this.subjectList = results[12].Results;
      }


      this.route.paramMap.subscribe(params => {

        if (params.keys.length > 0) {
          this.PageRights.ActionType = params.get('actionType');

          if (this.PageRights.ActionType == this.Constants.Actions.New) {
            this.studentModel = new StudentModel();

          } else {
            var StudentId: string = params.get('StudentId');
            this.id = StudentId
            let data = {
              DataId: StudentId,
              DataId1: sessionStorage.getItem('GlobalAcademicYearId')
            }

            this.studentService.GetStudentsById(data)
              .subscribe((response: any) => {

                this.studentModel = response.Result;

                if (this.PageRights.ActionType == this.Constants.Actions.Edit)
                  this.studentModel.RequestType = this.Constants.PageType.Edit;
                else if (this.PageRights.ActionType == this.Constants.Actions.View) {
                  this.studentModel.RequestType = this.Constants.PageType.View;
                  this.PageRights.IsReadOnly = true;
                }

                this.classId = this.studentModel.ClassId
                this.sectionId = this.studentModel.SectionId
                this.schoolId = this.studentModel.SchoolId

                this.studentModel.VocationalSubject = response.Result.VocationalSubjectID;

                this.onChangeAcademicYear(this.studentModel.AcademicYearId);

                this.onChangeState(this.studentModel.Statecode);
                this.onChangeDivision(this.studentModel.DivisionId);
                this.onChangeDistrict(this.studentModel.DistrictCode);
                this.onChangeStateCode(this.studentModel.Statecode)
                this.onChangeClass(this.studentModel.SchoolId)
                this.onChangeSection(this.studentModel.ClassId)
                this.studentForm = this.createStudentForm();
              });
          }
        }
      });
    });

    this.studentForm = this.createStudentForm();
    this.dataUploadForm = this.createDataUploadForm();
  }

  changeTab(event) {

    this.tabIndex = event.index;
  }

  onChangeAcademicYear(academicYearId: any) {
    this.academicYearId = academicYearId;
    // this.commonService.GetMasterDataByType({ DataType: 'ApprovalPhases', ParentId: academicYearId,UserId:this.UserModel.UserTypeId, SelectTitle: 'Approval Phase' }).subscribe((response: any) => {
    //   this.phaseList = response.Results;
    // });

    this.commonService.GetSectorJobRolesForStudents(this.classId, this.sectionId, this.schoolId, this.academicYearId).subscribe((response: any) => {



      this.vocationalSubjectList = response.Results;


    });
  }
  onChangeClass(SchoolId: any) {
    this.schoolId = SchoolId
    this.commonService.GetMasterDataByType({ DataType: 'ClassesByVT', ParentId: SchoolId, UserId: this.UserModel.UserTypeId, SelectTitle: 'Class' }).subscribe((response: any) => {
      this.classList = response.Results;
    });
  }
  onChangeSection(ClassId: any) {
    this.classId = ClassId;
    this.commonService.GetMasterDataByType({ DataType: 'SectionsBySchoolClass', ParentId: ClassId, UserId: this.schoolId, SelectTitle: 'Sections' }).subscribe((response: any) => {
      this.sectionList = response.Results;
    });
  }

  onChangeState(Statecode: any) {
    this.commonService.GetMasterDataByType({ DataType: 'Divisions', ParentId: Statecode, SelectTitle: 'Division' }).subscribe((response: any) => {
      this.divisionList = response.Results;
    });
  }

  onChangeSectionId(sectionId: any) {
    this.sectionId = sectionId;


  }

  onChangeDivision(divisionId: any) {
    var Statecode = this.studentForm.get('Statecode').value;

    this.commonService.GetMasterDataByType({ DataType: 'Districts', UserId: Statecode, ParentId: divisionId, SelectTitle: 'District' }).subscribe((response: any) => {
      this.districtList = response.Results;
    });
  }
  onChangeStateCode(divisionId: any) {
    var Statecode = this.studentForm.get('Statecode').value;

    this.commonService.GetMasterDataByType({ DataType: 'stateCode', UserId: Statecode, ParentId: divisionId, SelectTitle: 'stateCode' }).subscribe((response: any) => {
      this.stateCodeList = response.Results;
    });
  }
  onChangeDistrict(districtId: any) {
    var Statecode = this.studentForm.get('Statecode').value;
    this.commonService.GetMasterDataByType({ DataType: 'Blocks', UserId: Statecode, ParentId: districtId, SelectTitle: 'Block' }).subscribe((response: any) => {
      this.blockList = response.Results;
    });
  }
  getPreviousUrl() {

    return this.previousUrl;
  }

  saveOrUpdateStudentDetails() {
    if (!this.studentForm.valid) {
      this.validateAllFormFields(this.studentForm);
      return;
    }

    this.setValueFromFormGroup(this.studentForm, this.studentModel);
    this.studentModel.AuthUserId = this.UserModel.UserId;
    this.studentModel.VTId = this.UserModel.UserTypeId;
    this.studentModel.DateOfVTAllocation = this.CurrentDate
    this.studentModel.DateOfEnrollment = this.CurrentDate
    this.studentModel.CreatedBy = this.UserModel.UserId;
    this.studentModel.UpdatedBy = this.UserModel.UserId;
    this.studentModel.AadhaarNumber = null;
    this.studentModel.UserTypeId = this.UserModel.UserTypeId;

    this.studentService.CreateStudents(this.studentModel)
      .subscribe((schoolResp: any) => {

        if (schoolResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );
            window.history.back();
            // this.router.navigate([RouteConstants.Student.List]);
          });
        }
        else {
          var errorMessages = this.getHtmlMessage(schoolResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('Student deletion errors =>', error);
      });
  }

  //Create Student form and returns {FormGroup}
  createStudentForm(): FormGroup {
    return this.formBuilder.group({

      StudentId: new FormControl(this.studentModel.StudentId),
      IsActive: new FormControl({ value: this.studentModel.IsActive, disabled: this.PageRights.IsReadOnly }),
      FirstName: new FormControl({ value: this.studentModel.FirstName, disabled: this.PageRights.IsReadOnly }, [Validators.required, Validators.pattern(this.Constants.Regex.CharsWithTitleCase), Validators.maxLength(150)]),
      MiddleName: new FormControl({ value: this.studentModel.MiddleName, disabled: this.PageRights.IsReadOnly }, [Validators.pattern(this.Constants.Regex.CharsWithTitleCase), Validators.maxLength(150)]),
      LastName: new FormControl({ value: this.studentModel.LastName, disabled: this.PageRights.IsReadOnly }, [Validators.pattern(this.Constants.Regex.CharsWithTitleCase), Validators.maxLength(150)]),
      Mobile: new FormControl({ value: this.studentModel.Mobile, disabled: this.PageRights.IsReadOnly }, [Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern(this.Constants.Regex.MobileNumber)]),
      Mobile1: new FormControl({ value: this.studentModel.Mobile1, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(10), Validators.minLength(10), Validators.pattern(this.Constants.Regex.MobileNumber)]),
      DateOfBirth: new FormControl({ value: this.getDateValue(this.studentModel.DateOfBirth), disabled: this.PageRights.IsReadOnly }, Validators.required),
      RegistrationNo: new FormControl({ value: this.studentModel.RegistrationNo, disabled: this.PageRights.IsReadOnly }, Validators.required),
      Gender: new FormControl({ value: this.studentModel.Gender, disabled: this.PageRights.IsReadOnly }, Validators.required),
      EmailId: new FormControl({ value: this.studentModel.EmailId, disabled: (this.PageRights.IsReadOnly || this.PageRights.ActionType == this.Constants.Actions.Edit) }, [Validators.required, Validators.maxLength(50), Validators.pattern(this.Constants.Regex.Email)]),
      FatherName: new FormControl({ value: this.studentModel.FatherName, disabled: this.PageRights.IsReadOnly }, [Validators.required, Validators.pattern(this.Constants.Regex.CharsWithTitleCase), Validators.maxLength(150)]),
      FatherOccupation: new FormControl({ value: this.studentModel.FatherOccupation, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(150)]),
      FatherMobile: new FormControl({ value: this.studentModel.FatherMobile, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(10), Validators.minLength(10), Validators.pattern(this.Constants.Regex.MobileNumber)]),
      MotherName: new FormControl({ value: this.studentModel.MotherName, disabled: this.PageRights.IsReadOnly }, [Validators.required, Validators.pattern(this.Constants.Regex.CharsWithTitleCase), Validators.maxLength(150)]),
      MotherOccupation: new FormControl({ value: this.studentModel.MotherOccupation, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(150)]),
      MotherMobile: new FormControl({ value: this.studentModel.MotherMobile, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(10), Validators.minLength(10), Validators.pattern(this.Constants.Regex.MobileNumber)]),
      GuardianName: new FormControl({ value: this.studentModel.GuardianName, disabled: this.PageRights.IsReadOnly },),
      SocialCategory: new FormControl({ value: this.studentModel.SocialCategory, disabled: this.PageRights.IsReadOnly }, Validators.required),
      // AadhaarNumber: new FormControl({ value: this.maskingStudentAadhaarNo(this.studentModel.AadhaarNumber), disabled: this.PageRights.IsReadOnly }),
      AadhaarNumber: new FormControl({ value: this.studentModel.AadhaarNumber, disabled: this.PageRights.IsReadOnly }),
      Religion: new FormControl({ value: this.studentModel.Religion, disabled: this.PageRights.IsReadOnly }, Validators.required),
      // VTId: new FormControl({ value: this.studentModel.VTId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      Address3: new FormControl({ value: this.studentModel.Address3, disabled: this.PageRights.IsReadOnly }),
      Address2: new FormControl({ value: this.studentModel.Address2, disabled: this.PageRights.IsReadOnly }),
      Address1: new FormControl({ value: this.studentModel.Address1, disabled: this.PageRights.IsReadOnly }, Validators.required),
      SchoolId: new FormControl({ value: this.studentModel.SchoolId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      SectionId: new FormControl({ value: this.studentModel.SectionId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      ClassId: new FormControl({ value: this.studentModel.ClassId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      AcademicYearId: new FormControl({ value: this.studentModel.AcademicYearId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      Statecode: new FormControl({ value: this.studentModel.Statecode, disabled: true }, Validators.required),
      StateName: new FormControl({ value: this.studentModel.StateName, disabled: true }),
      DivisionId: new FormControl({ value: this.studentModel.DivisionId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      DistrictCode: new FormControl({ value: this.studentModel.DistrictCode, disabled: this.PageRights.IsReadOnly }, Validators.required),
      BlockId: new FormControl({ value: this.studentModel.BlockId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      Pincode: new FormControl({ value: this.studentModel.Pincode, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(6), Validators.minLength(6), Validators.pattern(this.Constants.Regex.Number)]),
      // CWSNStatus: new FormControl({ value: this.studentModel.CWSNStatus, disabled: this.PageRights.IsReadOnly }, Validators.required),
      StudentRollNumber: new FormControl({ value: this.studentModel.StudentRollNumber, disabled: this.PageRights.IsReadOnly }, Validators.required),
      VocationalSubject: new FormControl({ value: this.studentModel.VocationalSubject, disabled: this.PageRights.IsReadOnly }, Validators.required),
      ProfilePhoto: new FormControl({ value: this.studentModel.ProfilePhoto, disabled: this.PageRights.IsReadOnly },),
      Stream: new FormControl({ value: this.studentModel.Stream, disabled: this.PageRights.IsReadOnly }, Validators.required),
      // CWSNStatus: new FormControl({ value: this.studentModel.CWSNStatus, disabled: this.PageRights.IsReadOnly }, Validators.required),
      CWSNStatus: new FormControl({ value: this.studentModel.CWSNStatus, disabled: this.PageRights.IsReadOnly },),

      City: new FormControl({ value: this.studentModel.City, disabled: this.PageRights.IsReadOnly }),


    });
  }

  //Create MultipalStudent form and returns {FormGroup}
  createDataUploadForm(): FormGroup {
    return this.formBuilder.group({
      UploadFile: new FormControl({ value: this.fileUploadModel.UploadFile }, Validators.required)
    });
  }

  uploadedFile(event) {

    if (event.target.files.length > 0) {
      var fileExtn = event.target.files[0].name.split('.').pop().toLowerCase();

      if (this.AllowedExcelExtensions.indexOf(fileExtn) == -1) {
        this.dataUploadForm.get('UploadFile').setValue(null);
        this.dialogService.openShowDialog("Please upload excel file only.");
        return;
      }

      this.getUploadedFileDataXl(event, this.Constants.DocumentType.Student).then((response: FileUploadModel) => {
        this.fileUploadModel = response;

      });

      this.isAvailableUploadedExcel = false;
    }
  }

  uploadExcelData(): void {


    if (this.fileUploadModel.FileName === "") {
      this.dialogService.openShowDialog("Please upload excel template data first !!!");
      return;
    }

    let excelFormData = this.setUploadedFile(this.fileUploadModel);
    excelFormData.UserId = this.UserModel.UserTypeId;

    this.reportService.UploadExcelData(excelFormData).subscribe(response => {
      if (response.Success) {
        this.uploadedFileUrl = response.Messages.pop();
        this.isAvailableUploadedExcel = true;
        this.router.navigate([RouteConstants.Student.List]);

        this.dialogService.openShowDialog("Template data executed successfully for " + 'Multipal Student' + '. Please download uploaded excel file and check status');
      }
      else {
        this.dialogService.openShowDialog("Data uploading failed for " + 'Multipal Student' + " " + response.Errors.pop());
      }
    });
  }
}
