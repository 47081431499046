import { FuseUtils } from '@fuse/utils';

export class VTAssessmentModel {
    SrNo: number;
    VCSchoolSectorId: string;
    AcademicYearId: string;
    VCId: string;
    AcademicYearSchoolVTPSectorJobRoleId: string;
    DateOfAllocation: Date;
    VCSchoolStatus: string;
    DateOfRemoval: Date;
    VCSchoolSectorName: string;
    IsActive: boolean;
    RequestType: any;

    constructor() { }
}

export class VTAssessmentViewModel {
    RowIndex: string;
    VTId: string;
    VTFullName: string;
    VTMobile: string;
    VTMobile1: string;
    VTEmailId: string;
    SchoolId: string;
    SchoolName: string;
    SchoolStateCode: string;
    SchoolBlockName: string;
    SchoolVillage: string;
    SchoolPanchayat: string;
    SchoolPincode: string;
    SchoolLongitude: string;
    SchoolLatitude: string;
    HMId: string;
    HeadMasterName: string;
    AcademicYearId: string;
    CountOfBoys: string;
    CountOfGirls: string;
    BoysWithInternship: string;
    GirlsWithInternship: string;
    BoysWithInternshipCompleted: string;
    GirlsWithInternshipCompleted: string;
    TotalEmployers: string;
    TotalEmployersOnboarded: string;
    ActiveEmployerWithInternship: string;
    ActiveEmployerWithCompletedInternship: string;
    SafetyConcernsRaised: string;
    SafetyConcernsResolved: string;
    InternshipApprovalRequest: string;
    PendingProfileEditRequest: string;
    FinalReportPendingAck: string;
    DivisionName: string;
    DistrictName: string;
    ClassName: string;
    SectionName: string;
    VocationalSubject: string;
    SchoolStudentCount: string;
    SchoolPhone: string;
    SchoolEmailId: string;

 
}

