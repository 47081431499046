<div id="student-report" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    Interns Report
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter (keyup)="applySearchFilter($event.target.value)"
                        placeholder="Search for student Report">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">
                <a *ngIf="PageRights.IsExport" class="export-excel"
                    (click)="exporter.exportTable('xlsx', {fileName:'Interns Report', sheet: 'Interns Report', Props: {Author: 'Pathways'}})">
                    <mat-icon> cloud_download</mat-icon>
                </a>
            </div>
            <!-- / ADD BUTTON -->
        </div>
        <!-- / HEADER -->

        <div fxLayout="row" fxLayoutAlign="start center">
            <button mat-raised-button class="fuse-white filter-button" style="margin-bottom: 10px;"
                (click)="isShowFilterContainer=!isShowFilterContainer"><span>FILTERS</span></button>
        </div>

        <!-- Filter Section -->
        <div class="filter-content-card" *ngIf="isShowFilterContainer">
            <div class="filter-content">
                <form name="studentReportForm" [formGroup]="studentReportForm" class="vt-school-sector"
                    fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label> Academic Year</mat-label>

                            <mat-select formControlName="AcademicYearId" name="academicYearId" appearance="fill">
                                <mat-option [value]="academicYearItem.Id"
                                    *ngFor="let academicYearItem of academicyearList">
                                    {{ academicYearItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Student Full Name</mat-label>

                            <mat-select formControlName="StudentGuid" name="studentGuid" appearance="fill">
                                <mat-option [value]="Item.Id" *ngFor="let Item of studentList">
                                    {{ Item.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <!-- <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="filter-disabled">
                            <mat-label>State</mat-label>

                            <mat-select formControlName="StateCode" name="stateCode" appearance="fill"
                                (selectionChange)="onChangeState($event.value)">
                                <mat-option [value]="Item.Id" *ngFor="let Item of stateList">
                                    {{ Item.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field> -->
                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="filter-disabled">
                            <mat-label>Division</mat-label>

                            <mat-select formControlName="DivisionCode" name="divisionCode" appearance="fill"
                                (selectionChange)="onChangeDivision($event.value)">
                                <mat-option [value]="Item.Id" *ngFor="let Item of divisionList">
                                    {{ Item.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <!-- <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="filter-disabled">
                            <mat-label>District</mat-label>

                            <mat-select formControlName="DistrictCode" name="districtCode" appearance="fill" multiple>
                                <mat-option [value]="districtItem.Id" *ngFor="let districtItem of districtList">
                                    {{ districtItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field> -->



                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>District</mat-label>
                            <mat-select #districtMatSelect formControlName="DistrictCode" name="districtCode"
                                appearance="fill" multiple>
                                <mat-option (click)="toggleDistrictSelections($event)" [value]="0">All Districts
                                </mat-option>
                                <mat-option *ngFor="let districtItem of districtList" [value]="districtItem.Id">
                                    {{districtItem.Name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>




                        <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>School</mat-label>

                            <mat-select formControlName="SchoolGuid" name="schoolGuid" appearance="fill">
                                <mat-option [value]="Item.Id" *ngFor="let Item of schtList">
                                    {{ Item.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Class</mat-label>

                            <mat-select formControlName="ClassGuid" name="classGuid" appearance="fill">
                                <mat-option [value]="item.Id" *ngFor="let item of classList">
                                    {{ item.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <!-- <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Sector</mat-label>

                            <mat-select formControlName="SectorGuid" name="sectorGuid" appearance="fill" multiple>
                                <mat-option [value]="Item.Id" *ngFor="let Item of sectorList">
                                    {{ Item.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field> -->


                        <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Sector</mat-label>
                            <mat-select #sectorMatSelect formControlName="SectorGuid" name="sectorGuid"
                                appearance="fill" multiple>
                                <mat-option (click)="toggleSectorSelections($event)" [value]="0">All Sectors
                                </mat-option>
                                <mat-option *ngFor="let Item of sectorList" [value]="Item.Id">
                                    {{Item.Name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <!-- <mat-form-field fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Vocational Training Provider</mat-label>

                            <mat-select formControlName="VTPGuid" name="vTPGuid" appearance="fill" multiple>
                                <mat-option [value]="vtpItem.Id" *ngFor="let vtpItem of vtpList">
                                    {{ vtpItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field> -->



                        <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Vocational Training Provider</mat-label>
                            <mat-select #vtpMatSelect formControlName="VTPGuid" name="vTPGuid"
                                appearance="fill" multiple>
                                <mat-option (click)="toggleVTPSelections($event)" [value]="0">All VTP
                                </mat-option>
                                <mat-option *ngFor="let vtpItem of vtpList" [value]="vtpItem.Id">
                                    {{vtpItem.Name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="clear-filter pr-1" fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="getStudentReports()"><span>SEARCH</span></button>
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="resetReportFilters()"><span>CLEAR</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- /Filter Section -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table class="vt-school-sector-report-table responsive-table" #table [dataSource]="tableDataSource"
                matTableExporter #exporter="matTableExporter" [hiddenColumns]="[0]" matSort fusePerfectScrollbar
                [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- SrNo Column -->
                <ng-container matColumnDef="SrNo">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Sr No</mat-header-cell>
                    <mat-cell *matCellDef="let studentReport; let RowIndex = index;">
                        <p class="text-truncate">{{RowIndex+1}}</p>
                    </mat-cell>
                </ng-container>

                <!-- AcademicYear Column -->
                <ng-container matColumnDef="AcademicYear">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Academic Year
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.YearName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- FullName Column -->
                <ng-container matColumnDef="FullName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Full Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.FullName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- DOB Column -->
                <ng-container matColumnDef="DOB">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>DOB
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.DateOfBirth | date: Constants.ShortDateFormat}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Gender Column -->
                <ng-container matColumnDef="Gender">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Gender
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.Gender}}</p>
                    </mat-cell>
                </ng-container>

                <!-- PhoneNO Column -->
                <ng-container matColumnDef="PhoneNO">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Phone NO
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.Mobile}}</p>
                    </mat-cell>
                </ng-container>

                <!-- WhatsApp Column -->
                <ng-container matColumnDef="WhatsApp">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>WhatsApp
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.Whatsapp}}</p>
                    </mat-cell>
                </ng-container>

                <!-- EmailId Column -->
                <ng-container matColumnDef="EmailId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>EmailId
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.EmailId}}</p>
                    </mat-cell>
                </ng-container>

                <!-- State Column -->
                <ng-container matColumnDef="State">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>State
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.StateName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- District Column -->
                <ng-container matColumnDef="District">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>District
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.DistrictName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Block Column -->
                <ng-container matColumnDef="Block">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Block
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.BlockName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Pincode Column -->
                <ng-container matColumnDef="Pincode">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Pincode
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.Pincode}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SchoolName Column -->
                <ng-container matColumnDef="SchoolName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>School Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.SchoolName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- UDISE Column -->
                <ng-container matColumnDef="UDISE">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>UDISE
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.UDISE}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Class Column -->
                <ng-container matColumnDef="Class">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Class
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.ClassName}}</p>
                    </mat-cell>
                </ng-container>
                <!-- Section Column -->
                <ng-container matColumnDef="Section">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Section
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.SectionName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Stream Column -->
                <ng-container matColumnDef="Stream">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Stream
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.VTEmail}}</p>
                    </mat-cell>
                </ng-container>

                <!-- JobRole Column -->
                <ng-container matColumnDef="JobRole">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>JobRole
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.JobRoleName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Sector Column -->
                <ng-container matColumnDef="Sector">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Sector
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.SectorName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VillageSchool Column -->
                <ng-container matColumnDef="VillageSchool">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Village School
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.SchoolVillage}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VTPName Column -->
                <ng-container matColumnDef="VTPName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VTP Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.VTPName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VCName Column -->
                <ng-container matColumnDef="VCName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VC Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.VCFullName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VTName Column -->
                <ng-container matColumnDef="VTName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VT Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.VTFullName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- EmployerName Column -->
                <ng-container matColumnDef="EmployerName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Employer Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.EmployerName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- EstablishmentName Column -->
                <ng-container matColumnDef="EstablishmentName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Establishment Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.EstablishmentName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Title Column -->
                <ng-container matColumnDef="Title">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Internship Title
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.Title}}</p>
                    </mat-cell>
                </ng-container>

                <!-- IntershipType Column -->
                <ng-container matColumnDef="IntershipType">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Intership Type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.InternshipType}}</p>
                    </mat-cell>
                </ng-container>



                <!-- DateComm Column -->
                <ng-container matColumnDef="DateComm">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Date Commenced
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.DateCommenced | date: Constants.ShortDateFormat}}</p>
                    </mat-cell>
                </ng-container>

                <!-- DateComplete Column -->
                <ng-container matColumnDef="DateComplete">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>DateComplete
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.DateCompleted | date: Constants.ShortDateFormat}}</p>
                    </mat-cell>
                </ng-container>

                <!-- InternshipStatus Column -->
                <ng-container matColumnDef="InternshipStatus">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Internship Status
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p *ngIf="studentReport.InternshipStatus==true" class="text-truncate">Active</p>
                        <p *ngIf="studentReport.InternshipStatus==false" class="text-truncate">Completed</p>
                    </mat-cell>
                </ng-container>


                <!-- TotalDaysStd Column -->
                <ng-container matColumnDef="TotalDaysStd">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Total Days Std
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.TotalDaysStd}}</p>
                    </mat-cell>
                </ng-container>

                <!-- HrsPerDayStd Column -->
                <ng-container matColumnDef="HrsPerDayStd">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Hrs Per Day Std
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.HrsPerDayStd}}</p>
                    </mat-cell>
                </ng-container>

                <!-- TotalInternshipHrsStd Column -->
                <ng-container matColumnDef="TotalInternshipHrsStd">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Total Internship Hrs
                        Std
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.TotalInternshipHrsStd}}</p>
                    </mat-cell>
                </ng-container>

                <!-- IsInternshipCompleted Column -->
                <ng-container matColumnDef="IsInternshipCompleted">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Internship Completed
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.IsInternshipCompleted}}</p>
                    </mat-cell>
                </ng-container>

                <!-- DailyDiaryAvailable Column -->
                <ng-container matColumnDef="DailyDiaryAvailable">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>DailyDiaryAvailable
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.DailyDiaryAvailable}}</p>
                    </mat-cell>
                </ng-container>

                <!-- IsSafetyConcernRaised Column -->
                <ng-container matColumnDef="IsSafetyConcernRaised">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Is Safety Concern
                        Raised ?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p *ngIf="studentReport.IsSafetyConcernRaised==true" class="text-truncate">Yes</p>
                        <p *ngIf="studentReport.IsSafetyConcernRaised==false" class="text-truncate">No</p>
                    </mat-cell>
                </ng-container>

                <!-- SafetyConcernDetails Column -->
                <ng-container matColumnDef="SafetyConcernDetails">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Safety Concern Details
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.SafetyConcernDetails}}</p>
                    </mat-cell>
                </ng-container>


                <!-- Attachment1 Column -->
                <ng-container matColumnDef="Attachment1">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Attachment 1
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">
                            <a class="truncate pointer" target="_blank"
                                href="{{studentReport.Attachment1}}">{{studentReport.Attachment1}} </a>
                        </p>
                    </mat-cell>
                </ng-container>

                <!-- Attachment2 Column -->
                <ng-container matColumnDef="Attachment2">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Attachment 2
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">
                            <a class="truncate" target="_blank" href="{{studentReport.Attachment2}}"
                                style="cursor:pointer;">{{studentReport.Attachment2}}</a>
                        </p>
                    </mat-cell>
                </ng-container>

                <!-- Attachment3 Column -->
                <ng-container matColumnDef="Attachment3">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Attachment 3
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">
                            <a class="truncate pointer" target="_blank"
                                href="{{studentReport.Attachment3}}">{{studentReport.Attachment3}}</a>
                        </p>
                    </mat-cell>
                </ng-container>

                <!-- Attachment4 Column -->
                <ng-container matColumnDef="Attachment4">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Attachment 4
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">
                            <!-- <a class="truncate" target="_blank"
                                href="{{studentReport.Attachment4}}"><span
                                    *ngIf="studentReport.Attachment4 !=''">View</span></a> -->
                            <a class="truncate pointer" target="_blank"
                                href="{{studentReport.Attachment4}}">{{studentReport.Attachment4}}</a>

                        </p>
                    </mat-cell>
                </ng-container>

                <!-- Attachment5 Column -->
                <ng-container matColumnDef="Attachment5">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Attachment 5
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">
                            <a class="truncate pointer" target="_blank"
                                href="{{studentReport.Attachment5}}">{{studentReport.Attachment5}}</a>
                        </p>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let studentReport; columns: displayedColumns;" class="student-report" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

            <!-- <mat-paginator #ListPaginator [length]="SearchBy.TotalResults" [pageIndex]="SearchBy.PageIndex"
                [pageSize]="SearchBy.PageSize" [pageSizeOptions]="SearchBy.PageSizeOptions"
                [showFirstLastButtons]="SearchBy.ShowFirstLastButtons" (page)="onPageIndexChanged($event)">
            </mat-paginator> -->

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>