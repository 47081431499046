export const locale = {
    lang: 'en',
    data: {
        'SchoolClassSections': {
            'School_Class_Sections': 'School Class Sections',
            'ADD_NEW_School_Class_Sections': 'ADD',
            'SEARCH_FOR_A_School_Class_Sections': 'Search for a school class section',
        }
    }
};

