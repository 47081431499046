<div id="school" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <!-- {{ 'SCHOOL.SCHOOLS' | translate }} -->
                    STUDENT
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <form [formGroup]="studentSearchForm">
                    <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon>search</mat-icon>
                        <!-- <input #filter (keyup)="applySearchFilter($event.target.value)"  formControlName="filterText" placeholder="Search for a student"> -->
                        <input #filter (keyup)="applySearchFilterStudent($event.target.value)"
                            placeholder="Search for a student">
                    </div>
                </form>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">


                <!-- <button mat-raised-button class="add-school-button btn-warning mls" [routerLink]="['./','new']"
                    skipLocationChange="true" replaceUrl="false" fxFlex="0 1 calc(33%)" fxFlex.lt-md="0 1 calc(33%)"
                    fxFlex.lt-sm="50%">
                    <span>{{'SCHOOL.ADD_NEW_SCHOOL' | translate}} </span>

                </button> -->
                <!-- <button mat-raised-button class="add-school-button btn-warning mls" [routerLink]="['./','new']"
                    skipLocationChange="true" replaceUrl="false" >
                    <span>{{'SCHOOL.ADD_NEW_SCHOOL' | translate}} </span>
                    
                </button> -->
                <button mat-raised-button [routerLink]="['./','new']" *ngIf="UserModel.RoleCode != 'VC'"
                    class="add-sector-job-role-button fuse-white mt-24 mt-md-0 btnC">
                    <span>{{'SCHOOL.ADD_NEW_SCHOOL' | translate}}</span>
                </button>
                <!--<a *ngIf="PageRights.IsExport" class="export-excel"
                   (click)="exporter.exportTable('xlsx', {fileName:'Schools', sheet: 'Schools', Props: {Author: 'Lighthouse'}})">
                    <mat-icon> cloud_download</mat-icon>
                </a>-->

                <a *ngIf="PageRights.IsExport" class="export-excel" (click)="exportExcel()">
                    <mat-icon> cloud_download</mat-icon>
                </a>
            </div>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- Filter Section -->
        <div class="filter-content-card">
            <div class="filter-content">
                <form name="studentFilterForm" [formGroup]="studentFilterForm" class="vc-issue-report" fxLayout="column"
                    fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <!-- <mat-form-field fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Academic Year</mat-label>
                            <mat-select formControlName="AcademicYearId" name="academicYearId" appearance="outline">
                                <mat-option [value]="academicYearItem.Id"
                                    *ngFor="let academicYearItem of academicYearList">
                                    {{ academicYearItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field> -->

                        <mat-form-field fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Class </mat-label>

                            <mat-select formControlName="Class" name="class" appearance="outline"
                                (selectionChange)="onChangeSection($event.value)">
                                <mat-option [value]="ctem.Id" *ngFor="let ctem of classList">
                                    {{ ctem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Section </mat-label>

                            <mat-select formControlName="Section" name="section" appearance="outline">
                                <mat-option [value]="stem.Id" *ngFor="let stem of sectionList">
                                    {{ stem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <!-- <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Sector</mat-label>
                            <mat-select formControlName="SectorId" name="sectorId" appearance="outline">
                                <mat-option [value]="sectorItem.Id" *ngFor="let sectorItem of sectorList">
                                    {{ sectorItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field> -->



                        <div class="clear-filter pr-1" fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="onLoadSchoolsByCriteria()"><span>SEARCH</span></button>
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="resetFilters()"><span>CLEAR</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- /Filter Section -->


        <!-- CONTENT CARD -->
        <div class="content-card rounded-12 shadow-dark-80">


            <mat-table class="school-table responsive-table" [dataSource]="tableDataSource" matSort fusePerfectScrollbar
                [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- FullName Column -->
                <ng-container matColumnDef="FullName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Student Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let student">
                        <p class="text-truncate">
                            <span *ngIf="student.ProfilePhoto">
                                <span *ngIf="student.ProfilePhoto !== null "><img
                                        [src]="ApiBaseUrlImage+student.ProfilePhoto" width="50" height="50"
                                        style="border-radius: 50%;"></span></span>
                            <span style="margin-left: 5%;">{{student.FullName}}</span>
                        </p>
                    </mat-cell>
                </ng-container>

                <!-- Gender Column -->
                <ng-container matColumnDef="Gender">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Gender
                    </mat-header-cell>
                    <mat-cell *matCellDef="let student">
                        <p class="text-truncate">{{student.Gender}}</p>
                    </mat-cell>
                </ng-container>



                <!-- Mobile Column -->
                <ng-container matColumnDef="Mobile">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Mobile
                    </mat-header-cell>
                    <mat-cell *matCellDef="let student">
                        <p class="text-truncate">{{student.Mobile}}</p>
                    </mat-cell>
                </ng-container>
                <!-- EmailId Column -->
                <ng-container matColumnDef="EmailId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Email Id
                    </mat-header-cell>
                    <mat-cell *matCellDef="let student">
                        <p class="text-truncate">{{student.EmailId}}</p>
                    </mat-cell>
                </ng-container>
                <!-- DOB Column -->
                <ng-container matColumnDef="DOB">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>DOB
                    </mat-header-cell>
                    <mat-cell *matCellDef="let student">
                        <p class="text-truncate">{{student.DateOfBirth | date:'dd/MM/yyyy'}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Whatsapp Column -->
                <ng-container matColumnDef="WhatsappNumber">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>WhatsApp Mobile No
                    </mat-header-cell>
                    <mat-cell *matCellDef="let student">
                        <p class="text-truncate">{{student.Mobile1}}</p>
                    </mat-cell>
                </ng-container>
                <!-- ClassName Column -->
                <ng-container matColumnDef="ClassName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Class
                    </mat-header-cell>
                    <mat-cell *matCellDef="let student">
                        <p class="text-truncate">{{student.ClassName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SectionName Column -->
                <ng-container matColumnDef="SectionName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Section
                    </mat-header-cell>
                    <mat-cell *matCellDef="let student">
                        <p class="text-truncate">{{student.SectionName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Sector Column -->
                <ng-container matColumnDef="Sector">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Stream
                    </mat-header-cell>
                    <mat-cell *matCellDef="let student">
                        <p class="text-truncate">{{student.Stream}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Actions" stickyEnd>
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let student" fxShow.gt-xs>
                        <button *ngIf="PageRights.IsView" mat-icon-button
                            [routerLink]="['/vt-student', 'view', student.StudentId]">
                            <mat-icon color="primary">visibility</mat-icon>
                        </button>
                        <button *ngIf="PageRights.IsEdit || UserModel.RoleCode != 'VC'" mat-icon-button
                            [routerLink]="['/vt-student', 'edit', student.StudentId]">
                            <mat-icon color="primary">edit</mat-icon>
                        </button>
                        <button mat-icon-button color="warn" (click)="onDeleteSchool(student.StudentId)"
                            *ngIf="PageRights.IsDelete || UserModel.RoleCode != 'VC'">
                            <mat-icon>delete_outline</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <!-- <mat-row *matRowDef="let student; columns: displayedColumns;" class="school" matRipple>
                </mat-row> -->
                <!-- <mat-row *matRowDef="let student; columns: displayedColumns;"
                    [routerLink]="['/vt-student-details', student.StudentId]" skipLocationChange="true"
                    replaceUrl="true" class="school" matRipple>
                </mat-row> -->
                <mat-row *matRowDef="let student; columns: displayedColumns;"
                    [routerLink]="['/vt-student-details', student.StudentId]" class="school" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

            <!-- <mat-paginator #ListPaginator [length]="SearchBy.TotalResults" [pageIndex]="SearchBy.PageIndex"
                [pageSize]="SearchBy.PageSize" [pageSizeOptions]="SearchBy.PageSizeOptions"
                [showFirstLastButtons]="SearchBy.ShowFirstLastButtons" (page)="onPageIndexChanged($event)">
            </mat-paginator> -->

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>