import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { RouteConstants } from 'app/constants/route.constant';
import { DropdownModel } from 'app/models/dropdown.model';
import { SchoolSectorjobroleClasssectionMappingModel } from '../school-sectorjobrole-classsection-mapping.model';
import { SchoolSectorjobroleClasssectionMappingService } from '../school-sectorjobrole-classsection-mapping.service';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-create-school-sectorjobrole-classsection-mapping',
  templateUrl: './create-school-sectorjobrole-classsection-mapping.component.html',
  styleUrls: ['./create-school-sectorjobrole-classsection-mapping.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class CreateSchoolSectorjobroleClasssectionMappingComponent extends BaseComponent<SchoolSectorjobroleClasssectionMappingModel> implements OnInit {
  schoolSjrClssctMappingForm: FormGroup;
  schoolSectorjobroleClasssectionMappingModel: SchoolSectorjobroleClasssectionMappingModel;
  academicyearList: [DropdownModel];
  phaseList: [DropdownModel];
  sectorList: [DropdownModel];
  classList: [DropdownModel];
  sectionList: [DropdownModel];
  jobRoleList: [DropdownModel];
  schoolsList: [DropdownModel];
  roleList: [DropdownModel];
  vtpList: [DropdownModel];
  vocationalCoordinatorList: [DropdownModel];
  vocationList: [DropdownModel];
  remarkText: string;
  statecodeList: [DropdownModel];
  academicId: any;
  schSectorJobRoleId: any;
  classId: any;
  stateId: any;
  vtpId: any;
  vcId: any;
  filteredSchoolJobRoleItems: any;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private route: ActivatedRoute,
    private schoolSectorjobroleClasssectionMappingService: SchoolSectorjobroleClasssectionMappingService,
    private dialogService: DialogService,
    private formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Set the default sectorJobRole Model
    this.schoolSectorjobroleClasssectionMappingModel = new SchoolSectorjobroleClasssectionMappingModel();
  }

  ngOnInit(): void {
    this.schoolSectorjobroleClasssectionMappingService.getDropdownforSectorJobRole(this.UserModel).subscribe((response: any) => {
      if (response[0].Success) {
        this.academicyearList = response[0].Results;
      }
      if (response[1].Success) {
        this.roleList = response[1].Results;
        this.filteredSchoolJobRoleItems = this.roleList.slice();
      }

      // if (response[2].Success) {
      //   this.vocationList = response[2].Results;
      // }
      // if (response[3].Success) {
      //   this.vtpList = response[3].Results;
      // }
      // if (response[4].Success) {
      //   this.vocationalCoordinatorList = response[4].Results;
      // }
      if (response[5].Success) {
        this.statecodeList = response[5].Results;
        for (const i of response[5].Results) {
          this.schoolSjrClssctMappingForm.get('StateCode').setValue(i.Id);
        }
      }



      this.route.paramMap.subscribe(params => {
        if (params.keys.length > 0) {
          this.PageRights.ActionType = params.get('actionType');

          if (this.PageRights.ActionType == this.Constants.Actions.New) {
            this.schoolSectorjobroleClasssectionMappingModel = new SchoolSectorjobroleClasssectionMappingModel();

          } else {
            var schoolSecJobClassSecVTId: string = params.get('SchoolSecJobClassSecVTId')

            this.schoolSectorjobroleClasssectionMappingService.getSchoolSecJobClassSecVTMappingById(schoolSecJobClassSecVTId)
              .subscribe((response: any) => {
                this.schoolSectorjobroleClasssectionMappingModel = response.Result;

                if (this.PageRights.ActionType == this.Constants.Actions.Edit)
                  this.schoolSectorjobroleClasssectionMappingModel.RequestType = this.Constants.PageType.Edit;
                else if (this.PageRights.ActionType == this.Constants.Actions.View) {
                  this.schoolSectorjobroleClasssectionMappingModel.RequestType = this.Constants.PageType.View;
                  this.PageRights.IsReadOnly = true;
                }
                if (this.schoolSectorjobroleClasssectionMappingModel.DateOfDeAllocation != null) {
                  this.schoolSjrClssctMappingForm.get("DateOfDeAllocation").setValue(this.getDateValue(this.schoolSectorjobroleClasssectionMappingModel.DateOfDeAllocation));
                  let dateOfResignationCtrl = this.schoolSjrClssctMappingForm.get("DateOfDeAllocation");
                  this.onChangeDateEnableDisableCheckBox(this.schoolSjrClssctMappingForm, dateOfResignationCtrl);
                }
                this.onChangeSchoolAY(this.schoolSectorjobroleClasssectionMappingModel.AcademicYearId)
                this.onChangeSchoolSectorJobRole(this.schoolSectorjobroleClasssectionMappingModel.SchoolSectorJobRoleId);
                this.onChangeClass(this.schoolSectorjobroleClasssectionMappingModel.ClassId);



                this.onChangeVTP(this.schoolSectorjobroleClasssectionMappingModel.VTPId);

                this.onChangeVC(this.schoolSectorjobroleClasssectionMappingModel.VCId);

                this.schoolSjrClssctMappingForm = this.createSchoolVTMappingForm();
              });
          }
        }
      });
    });
    this.schoolSjrClssctMappingForm = this.createSchoolVTMappingForm();
  }

  onChangeSchoolAY(ayId) {
    this.academicId = ayId;
    this.commonService.GetMasterDataByType({ DataType: 'SchoolSectorJobRolesByAY', ParentId: ayId, UserId: this.UserModel.UserTypeId, SelectTitle: 'Select School Sector Job Roles' }).subscribe((response) => {
      if (response.Success) {
        this.roleList = response.Results;
        this.filteredSchoolJobRoleItems = this.roleList.slice();
      }
    })
  }

  onChangeSchoolSectorJobRole(sSectorJobRoleId) {
    this.schSectorJobRoleId = sSectorJobRoleId;
    this.commonService.GetMasterDataByType({ DataType: 'ClassesBySchoolSectorJobRole', ParentId: sSectorJobRoleId, SelectTitle: 'Select Class' }).subscribe((response) => {
      if (response.Success) {
        this.classList = response.Results;
      }
    })

    this.commonService.GetMasterDataByType({ DataType: 'VTPForSchoolSectorJobRolesAY', UserId: this.academicId, ParentId: sSectorJobRoleId }).subscribe((response) => {
      if (response.Success) {
        this.vtpList = response.Results;
      }
    });
  }

  onChangeClass(clsId) {
    this.classId = clsId;
    this.commonService.GetMasterDataByType({ DataType: 'SectionsBySchoolClass', UserId: this.schSectorJobRoleId, ParentId: clsId, SelectTitle: 'Select Class' }).subscribe((response) => {
      if (response.Success) {
        this.sectionList = response.Results;
      }
    })
  }

  onChangeState(stateId) {
    this.stateId = stateId;
  }



  onChangeVTP(vtpId) {

    this.commonService.GetMasterDataByType({ DataType: 'VocationalCoordinatorsByUserId', ParentId: vtpId, UserId: this.UserModel.LoginId, SelectTitle: 'Vocational Coordinator' }).subscribe((response) => {
      if (response.Success) {

        this.vocationalCoordinatorList = response.Results;


      }
    });

  }

  onChangeVC(vcId) {
    this.commonService.GetMasterDataByType({ DataType: 'VTByVCAY', UserId: vcId, ParentId: this.academicId, SelectTitle: 'Vocational Trainers' }).subscribe((response) => {
      if (response.Success) {
        this.vocationList = response.Results;
      }
    })
  }
  status(item: any) {
    if (item == 'SchoolSectorJobRoles') {
      this.schoolSjrClssctMappingForm.patchValue({ 'ClassId': null });
    } else if (item == 'Class') {
      this.schoolSjrClssctMappingForm.patchValue({ 'SectionId': null });
    } else if (item == 'VTP') {
      this.schoolSjrClssctMappingForm.patchValue({ 'VCId': null });
    } else if (item == 'VC') {
      this.schoolSjrClssctMappingForm.patchValue({ 'VTId': null });
    }
  }
  saveOrUpdateSchoolVTMappingDetails() {
    if (!this.schoolSjrClssctMappingForm.valid) {
      this.validateAllFormFields(this.schoolSjrClssctMappingForm);
      return;
    }

    this.setValueFromFormGroup(this.schoolSjrClssctMappingForm, this.schoolSectorjobroleClasssectionMappingModel);
    this.schoolSectorjobroleClasssectionMappingService.createOrUpdateSchoolSecJobClassSecVTMapping(this.schoolSectorjobroleClasssectionMappingModel)
      .subscribe((SchoolSecJobClassSecVTMappingResp: any) => {

        if (SchoolSecJobClassSecVTMappingResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );

            this.router.navigate([RouteConstants.SchoolSJRAndClsSMapping.List]);
          });
        }
        else {
          var errorMessages = this.getHtmlMessage(SchoolSecJobClassSecVTMappingResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('SchoolSecJobClassSecVTMapping deletion errors =>', error);
      });
  }


  showOptions(event: MatCheckboxChange): void {
    if (event.checked == false) {
      this.dialogService
        .openConfirmDialog(this.Constants.Messages.IsactiveConfirmationMessage)
        .afterClosed()
        .subscribe(confResp => {
          if (confResp == true) {
            console.log(event.checked);
            if (event.checked == false) {
              var currentDate = new Date();
              this.schoolSjrClssctMappingForm.get('DateOfDeAllocation').setValue(this.getDateValue(currentDate));

            } else {
              this.schoolSjrClssctMappingForm.get('DateOfDeAllocation').setValue('');
            }
          } else {
            this.onChangeDateEnableDisableCheckBox(this.schoolSjrClssctMappingForm, event.checked);
            this.schoolSjrClssctMappingForm.get('DateOfDeAllocation').setValue('');

          }
        });

    } else {
      this.schoolSjrClssctMappingForm.get('DateOfDeAllocation').setValue('');

    }

  }


  //Create sectorJobRole form and returns {FormGroup}
  createSchoolVTMappingForm(): FormGroup {
    return this.formBuilder.group({
      SchoolSecJobClassSecVTId: new FormControl(this.schoolSectorjobroleClasssectionMappingModel.SchoolSecJobClassSecVTId),
      ClassId: new FormControl({ value: this.schoolSectorjobroleClasssectionMappingModel.ClassId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      SchoolSectorJobRoleId: new FormControl({ value: this.schoolSectorjobroleClasssectionMappingModel.SchoolSectorJobRoleId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      VTId: new FormControl({ value: this.schoolSectorjobroleClasssectionMappingModel.VTId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      AcademicYearId: new FormControl({ value: this.schoolSectorjobroleClasssectionMappingModel.AcademicYearId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      DateOfAllocation: new FormControl({ value: this.getDateValue(this.schoolSectorjobroleClasssectionMappingModel.DateOfAllocation), disabled: this.PageRights.IsReadOnly }, Validators.required),
      DateOfDeAllocation: new FormControl({ value: this.getDateValue(this.schoolSectorjobroleClasssectionMappingModel.DateOfDeAllocation), disabled: this.PageRights.IsReadOnly }),
      Remarks: new FormControl({ value: this.schoolSectorjobroleClasssectionMappingModel.Remarks, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(350)),
      IsActive: new FormControl({ value: this.schoolSectorjobroleClasssectionMappingModel.IsActive, disabled: this.PageRights.IsReadOnly }),
      StateCode: new FormControl({ value: this.schoolSectorjobroleClasssectionMappingModel.StateCode, disabled: true }, Validators.required),
      SectionId: new FormControl({ value: this.schoolSectorjobroleClasssectionMappingModel.SectionId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      VTPId: new FormControl({ value: this.schoolSectorjobroleClasssectionMappingModel.VTPId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      VCId: new FormControl({ value: this.schoolSectorjobroleClasssectionMappingModel.VCId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      DateOfResignation: new FormControl({ value: this.getDateValue(this.schoolSectorjobroleClasssectionMappingModel.DateOfResignation), disabled: this.PageRights.IsReadOnly }),



    });
  }

}
