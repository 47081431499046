import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StudentRegistrationModel, StudentRegistrationViewModel } from './student-registration.model';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { ReportService } from '../report.service';
import { DropdownModel } from 'app/models/dropdown.model';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { environment } from 'environments/environment';

@Component({
  selector: 'data-list-view',
  templateUrl: './student-registration.component.html',
  styleUrls: ['./student-registration.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class StudentRegistrationComponent extends BaseListComponent<StudentRegistrationModel> implements OnInit {
  studentReportForm: FormGroup;
  StudentRegistrationViewModel = new StudentRegistrationViewModel();
  academicyearList: [DropdownModel];
  divisionList: [DropdownModel];
  districtList: DropdownModel[];
  sectorList: [DropdownModel];
  jobRoleList: DropdownModel[];
  vtpList: [DropdownModel];
  classList: [DropdownModel];
  stateList: [DropdownModel];
  schoolList: [DropdownModel];
  filteredSchoolItems: any;
  studentList: [DropdownModel];
  schtList: [DropdownModel];
  sCode: any;
  currentAcademicYearId: string;
  isShowFilterContainer = false;
  UdiseList: [DropdownModel];
  SchoolManagementtList: [DropdownModel];
  SchoolCategoryList: [DropdownModel];
  SectionList: [DropdownModel];
  BlockList: [DropdownModel];
  VocationalTrainerList: [DropdownModel];
  vocationalCoordinatorList: [DropdownModel];
  academicId: any;
  filteredVocationalTrainerItems: any;
  filteredBlockItems: any;
  filteredDistrictItems: any;
  filteredSectorItems: any;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    public zone: NgZone,
    public formBuilder: FormBuilder,
    private dialogService: DialogService,
    private reportService: ReportService) {
    super(commonService, router, routeParams, snackBar, zone);
  }


  ngOnInit(): void {
    this.PageRights.IsExport = true;
    this.SearchBy.PageIndex = 0;

    this.SearchBy.PageSize = 10;
    this.reportService.GetDropdownForReports(this.UserModel).subscribe(results => {
      if (results[0].Success) {
        this.academicyearList = results[0].Results;
      }

      // if (results[1].Success) {
      //   this.divisionList = results[1].Results;
      // }

      if (results[2].Success) {
        this.sectorList = results[2].Results;
        this.filteredSectorItems = this.sectorList.slice();
      }

      if (results[3].Success) {
        this.vtpList = results[3].Results;
      }

      if (results[4].Success) {
        this.classList = results[4].Results;
      }

      if (results[6].Success) {
        this.SchoolManagementtList = results[6].Results;
      }


      if (results[7].Success) {
        this.stateList = results[7].Results;
      }

      if (results[8].Success) {
        this.studentList = results[8].Results;
      }
      if (results[10].Success) {
        this.schoolList = results[10].Results;
        this.filteredSchoolItems = this.schoolList.slice();
      }
      if (results[11].Success) {
        this.sCode = results[11].Results;
      }
      if (results[12].Success) {
        this.SchoolCategoryList = results[12].Results;
      }
      if (results[13].Success) {
        this.SectionList = results[13].Results;
      }
      // if (results[14].Success) {
      //   this.BlockList = results[14].Results;
      // }
      // if (results[15].Success) {
      //   this.VocationalTrainerList = results[15].Results;
      // }
      // if (results[16].Success) {
      //   this.vocationalCoordinatorList = results[16].Results;
      // }
      this.studentReportForm.get('StudentStateCode').setValue(this.sCode[1].Id);
      //this.getStudentReports();
      this.onChangeState(this.sCode[1].Id)


    });

    this.studentReportForm = this.createStudentReportForm();
  }
  onChangeState(Statecode: any) {
    this.commonService.GetMasterDataByType({ DataType: 'Divisions', ParentId: Statecode, SelectTitle: 'Division' }).subscribe((response: any) => {
      this.divisionList = response.Results;
    });
  }

  onChangeDivision(divisionId: any) {

    this.commonService.GetMasterDataByType({ DataType: 'Districts', ParentId: divisionId, SelectTitle: 'District' }).subscribe((response: any) => {
      this.districtList = response.Results;
      this.filteredDistrictItems = this.districtList.slice();
    });
  }
  onChangeBlock(DistrictCode: any) {

    this.commonService.GetMasterDataByType({ DataType: 'Blocks', ParentId: DistrictCode, SelectTitle: 'Block' }).subscribe((response: any) => {
      this.BlockList = response.Results;
      this.filteredBlockItems = this.BlockList.slice();
    });
  }

  onChangeSchoolAY(ayId) {
    this.academicId = ayId;

  }

  onChangeVC(id: any) {

    this.commonService.GetMasterDataByType({ DataType: 'VocationalCoordinatorsByUserIdVTPId', ParentId: id, SelectTitle: 'VocationalCoordinators', UserId: this.UserModel.LoginId }).subscribe((response: any) => {
      this.vocationalCoordinatorList = response.Results;
    });
  }
  onChangeVT(id: any) {
    if (this.academicId != undefined && this.academicId != null) {
      this.commonService.GetMasterDataByType({ DataType: 'VTByVCAY', UserId: id, ParentId: this.academicId, SelectTitle: 'Vocational Trainers' }).subscribe((response: any) => {
        this.VocationalTrainerList = response.Results;
        this.filteredVocationalTrainerItems = this.VocationalTrainerList.slice();
        
      });
    }
    else
    {
      this.dialogService.openShowDialog("Please Select Academic Year");
      this.ngOnInit();
    }
  }

  resetReportFilters(): void {
    this.studentReportForm.reset();
    this.studentReportForm.get('StudentStateCode').setValue(this.sCode[1].Id);
    this.districtList = <DropdownModel[]>[];
    this.jobRoleList = <DropdownModel[]>[];
    this.SearchBy.PageIndex = 0;

    this.SearchBy.PageSize = 10;
    if (this.UserModel.RoleCode === 'DisRP') {
      this.studentReportForm.controls["DivisionId"].setValue(this.UserModel.DivisionId);
      this.studentReportForm.controls["DistrictId"].setValue(this.UserModel.DistrictId);
    }
    this.tableDataSource.data = [];
    // this.getStudentReports();
  }



  onChangeSector(sectorId: string): void {
    this.commonService.GetMasterDataByType({ DataType: 'JobRoles', ParentId: sectorId, SelectTitle: 'Job Role' }).subscribe((response: any) => {
      this.jobRoleList = response.Results;
    });
  }

  //Create VTSchoolSector form and returns {FormGroup}
  createStudentReportForm(): FormGroup {
    return this.formBuilder.group({
      AcademicYearGuid: new FormControl(),
      SchoolGuid: new FormControl(),
      SchoolManagementId: new FormControl(),
      SchoolCategoryId: new FormControl(),
      ClassGuid: new FormControl(),
      SectionGuid: new FormControl(),
      StudentStateCode: new FormControl(),
      DivisionGuid: new FormControl(),
      DistrictCode: new FormControl(),
      BlockGuid: new FormControl(),
      SectorGuid: new FormControl(),
      JobRoleGuid: new FormControl(),
      VTGuid: new FormControl(),
      VCGuid: new FormControl(),
      VTPGuid: new FormControl(),
    });
  }
  onPageIndexChanged(evt) {

    this.SearchBy.PageIndex = evt.pageIndex;
    this.SearchBy.PageSize = evt.pageSize;

    this.getStudentReports();
  }
  getStudentReports(): void {
    this.SearchBy.UserGuid = this.UserModel.UserTypeId;
    this.SearchBy.AcademicYearGuid = this.studentReportForm.get('AcademicYearGuid').value;
    this.SearchBy.SchoolGuid = this.studentReportForm.get('SchoolGuid').value;
    this.SearchBy.SchoolManagementId = this.studentReportForm.get('SchoolManagementId').value;
    this.SearchBy.SchoolCategoryId = this.studentReportForm.get('SchoolCategoryId').value;
    this.SearchBy.ClassGuid = this.studentReportForm.get('ClassGuid').value;
    this.SearchBy.SectionGuid = this.studentReportForm.get('SectionGuid').value;
    this.SearchBy.StudentStateCode = this.studentReportForm.get('StudentStateCode').value;
    this.SearchBy.DivisionGuid = this.studentReportForm.get('DivisionGuid').value;
    this.SearchBy.DistrictCode = this.studentReportForm.get('DistrictCode').value;
    this.SearchBy.BlockGuid = this.studentReportForm.get('BlockGuid').value;
    this.SearchBy.SectorGuid = this.studentReportForm.get('SectorGuid').value;
    this.SearchBy.JobRoleGuid = this.studentReportForm.get('JobRoleGuid').value;
    this.SearchBy.VTGuid = this.studentReportForm.get('VTGuid').value;
    this.SearchBy.VCGuid = this.studentReportForm.get('VCGuid').value;
    this.SearchBy.VTPGuid = this.studentReportForm.get('VTPGuid').value;
    this.SearchBy.StudentStateCode = this.sCode[1].Id;



    this.reportService.GetStudentRegistrationReports(this.SearchBy).subscribe(response => {
      this.displayedColumns = ['SrNo', 'AcademicYear', 'FullName', 'Gender', 'FatherName', 'MotherName', 'MobileNo', 'WhatsappNo', 'EmailId', 'DateOfBirth', 'SchoolName', 'SchoolId',
        'Udise', 'SchoolManagement', 'Schoolcategory', 'Class', 'Section', 'State', 'Division', 'District', 'Block', 'Sector', 'JobRole', 'VocationalTrainer', 'VocationalCoordinator', 'VTP', 'Dateofstudentsenrollement', 'RegistrationNumber',
        'AppliedforInternship', 'HiredforInternship', 'EntryCreatedby', 'CreatedOn', 'IsActive'];

      response.Results.forEach((value, index, array) => {
        value.SrNo = index + 1;
      });
      this.tableDataSource.data = response.Results;
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;
      this.SearchBy.TotalResults = response.TotalResults;

      setTimeout(() => {
        this.ListPaginator.pageIndex = this.SearchBy.PageIndex;
        this.ListPaginator.length = this.SearchBy.TotalResults;
      });

      this.zone.run(() => {
        if (response.Results.length == 0 || response.Results.length == null) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
    });
  }

  exportExcel(): void {
    this.IsLoading = true;
    this.SearchBy.PageIndex = 1;
    this.SearchBy.PageSize = 10000;

    this.reportService.GetStudentReportsByCriteria(this.SearchBy).subscribe(response => {
      response.Results.forEach(
        function (obj) {
          if (obj.hasOwnProperty('IsActive')) {
            obj.IsActive = obj.IsActive ? 'Yes' : 'No';
          }
          if (obj.hasOwnProperty('IsImplemented')) {
            obj.IsImplemented = obj.IsImplemented ? 'Yes' : 'No';
          }
          delete obj.SchoolId;
          delete obj.TotalRows;
        });

      this.exportExcelFromTable(response.Results, "StudentRegistration");

      this.IsLoading = false;
      this.SearchBy.PageIndex = 0;
      this.SearchBy.PageSize = 10;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

}
