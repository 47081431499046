import { Component, OnInit, NgZone, ViewEncapsulation, ViewChild } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SafetyConcernModel } from './safety-concern.model';
import { SafetyConcernService } from './safety-concern.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as guarati } from './i18n/gj';
import { MatMenuTrigger } from '@angular/material/menu';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { UserModel } from "app/models/user.model";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RemarkSafetyComponent } from '../safety-concern/remark-safety/remark-safety.component';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'data-list-view',
  templateUrl: './safety-concern.component.html',
  styleUrls: ['./safety-concern.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class SafetyConcernComponent extends BaseListComponent<SafetyConcernModel> implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  safetyConcernData:any
  studentFilterForm: FormGroup;
  img = this.Constants.CommonImageUrl.Account;
  tabIndex = 0;
  safetyConcernD:any

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private translationLoaderService: FuseTranslationLoaderService,
    public zone: NgZone,
    private dialogService: DialogService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private safetyConcernService: SafetyConcernService) {
    super(commonService, router, routeParams, snackBar, zone);
    this.translationLoaderService.loadTranslations(english, guarati);
    this.studentFilterForm = this.createSchoolVtpSectorFilterForm();
    // const firstTime = localStorage.getItem('key')
    const firstTime = JSON.parse(sessionStorage.getItem("key"));

    if (firstTime == true) {
      window.location.reload();
      var key = false;
      sessionStorage.setItem('key', JSON.stringify(key));
    }

  }

  ngOnInit(): void {
    this.GetRequestsByVTIdRequestTypeIsActionTaken();
  }
  someMethod() {
    this.trigger.openMenu();
  }

  resetFilters(): void {
    this.studentFilterForm.reset();
    // this.studentFilterForm.get('AcademicYearId').setValue(this.currentAcademicYearId);

    // this.onLoadSchoolsByCriteria();
  }
  //Create SchoolVtpSectorFilter form and returns {FormGroup}
  createSchoolVtpSectorFilterForm(): FormGroup {
    return this.formBuilder.group({
      AcademicYearId: new FormControl(),
      SectorId: new FormControl(),
      VTPId: new FormControl(),
      // SchoolId: new FormControl(),
    });
  }

  // onChangeStudent(RequestId: string) {
  //   this.dialogService
  //     .openConfirmDialog('Is the safety concern request completed?')
  //     .afterClosed()
  //     .subscribe(confResp => {
  //       if (confResp) {
  //         this.safetyConcernService.SetActionTaken(RequestId)
  //           .subscribe((schoolResp: any) => {

  //             this.zone.run(() => {
  //               if (schoolResp.Success) {
  //                 this.showActionMessage(
  //                   this.Constants.Messages.RecordCompletedMessage,
  //                   this.Constants.Html.SuccessSnackbar
                    
  //                 );
  //               }
  //               this.ngOnInit();
  //             }, error => {
  //               console.log('Student  errors =>', error);
  //             });

  //           });
  //         this.ngOnInit();
  //       }
  //     });
  // }
  onChangeStudent(data) {
    console.log(data)
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.maxWidth = "80%";
        dialogConfig.width = "80%";
        //  dialogConfig.height="765px";
        dialogConfig.height = "auto";
        dialogConfig.data = data;
        this.dialog.open(RemarkSafetyComponent, dialogConfig).afterClosed()
          .subscribe(AcknowledgeData => {
            console.log(AcknowledgeData)
            if (AcknowledgeData) {
              var editData = {
             "RequestId":data.RequestId,
             "RemarksFromVT":AcknowledgeData.data.value.Remark
         
                
              }
              console.log(editData)
              this.safetyConcernService.SetActionTaken(editData)
              .subscribe((schoolResp: any) => {
  
                this.zone.run(() => {
                  if (schoolResp.Success) {
                    this.showActionMessage(
                      this.Constants.Messages.RecordCompletedMessage,
                      this.Constants.Html.SuccessSnackbar
                      
                    );
                  }
                  this.ngOnInit();
                }, error => {
                  console.log('Student  errors =>', error);
                });
  
              });
            this.ngOnInit();
    
            }
            else {
              this.zone.run(() => {
                //@ts-ignore
                this.snackBar.open("No data found..", "Dismiss", {
                  duration: 5000,
                  verticalPosition: 'bottom',
                  horizontalPosition: 'center',
                  panelClass: ["error-snackbar"]
                });
              });
              this.showActionMessage(
                "No changes made, request cancelled...!!",
                this.Constants.Html.ErrorSnackbar,
              );
              return false;
            }
          });
      }

  changeTab(event) {
    this.tabIndex = event.index == undefined ? event : event.index;
    if (this.tabIndex === 0) {
      this.GetRequestsByVTIdRequestTypeIsActionTaken();

    } else {
      this.GetRequestsByVTIdRequestType()
    }
  }

  GetRequestsByVTIdRequestTypeIsActionTaken(){
    this.IsLoading = true;
    this.SearchBy.SchoolGuid = (this.Constants.GlobalSchoolId == 'undefined' || this.Constants.GlobalSchoolId == 'null') ? null : this.Constants.GlobalSchoolId;
    this.SearchBy.AcademicYearGuid = (this.Constants.GlobalAcademicYearId == 'undefined' || this.Constants.GlobalAcademicYearId == 'null') ? null : this.Constants.GlobalAcademicYearId;
    this.SearchBy.TypeofRequest = "Raise risk Report";
    this.SearchBy.VTId=this.UserModel.UserTypeId;
    this.SearchBy.IsActionTaken=false;
    this.SearchBy.RoleCode = this.UserModel.RoleCode;

    this.SearchBy.SectorGuid = this.studentFilterForm.controls['SectorId'].value;
      this.safetyConcernService.GetRequestsByVTIdRequestTypeIsActionTaken(this.SearchBy).subscribe(response => {
        this.safetyConcernData=response.Results;
  

        
        this.IsLoading = false;
      }, error => {
        console.log(error);
      });
  }


  GetRequestsByVTIdRequestType(){
    this.IsLoading = true;
    this.SearchBy.SchoolGuid = (this.Constants.GlobalSchoolId == 'undefined' || this.Constants.GlobalSchoolId == 'null') ? null : this.Constants.GlobalSchoolId;
    this.SearchBy.AcademicYearGuid = (this.Constants.GlobalAcademicYearId == 'undefined' || this.Constants.GlobalAcademicYearId == 'null') ? null : this.Constants.GlobalAcademicYearId;
    this.SearchBy.TypeofRequest = "Raise risk Report";
    this.SearchBy.VTId=this.UserModel.UserTypeId;
    this.SearchBy.IsActionTaken=true;
    this.SearchBy.SectorGuid = this.studentFilterForm.controls['SectorId'].value;
      this.safetyConcernService.GetRequestsByVTIdRequestTypeIsActionTaken(this.SearchBy).subscribe(response => {
        this.safetyConcernD=response.Results;
      
        this.IsLoading = false;
      }, error => {
        console.log(error);
      });
  }

}
