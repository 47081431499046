import { Component, OnInit, NgZone, ViewEncapsulation, ViewChild } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageForVTModel } from './message-for-vt.model';
import { MessageForVTService } from './message-for-vt.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';


import { MatMenuTrigger } from '@angular/material/menu';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { environment } from 'environments/environment';
@Component({
  selector: 'data-list-view',
  templateUrl: './message-for-vt.component.html',
  styleUrls: ['./message-for-vt.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class MessageForVTComponent extends BaseListComponent<MessageForVTModel> implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  newMessageData:any
  readMessageData:any
  studentFilterForm: FormGroup;
  img =this.Constants.CommonImageUrl.Employer;
  tabIndex = 0;
sectorList:any;
academicYearList:any;
vtpList:any;
msgForVtSearchForm: FormGroup;
  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private translationLoaderService: FuseTranslationLoaderService,
    public zone: NgZone,
    private dialogService: DialogService,
    private formBuilder: FormBuilder,
    private messageForVTService: MessageForVTService) {
    super(commonService, router, routeParams, snackBar, zone);
    this.studentFilterForm = this.createSchoolVtpSectorFilterForm();
    this.msgForVtSearchForm = this.formBuilder.group({ filterText: '' });

  }

  ngOnInit(): void {
    if ( this.tabIndex == 0) {
      this.newMessage();
    }
    else if (this.tabIndex == 1) {
      this.readMessage();
    }
    this.msgForVtSearchForm = this.formBuilder.group({ filterText: '' });

    // Load initial schools data
 

    this.msgForVtSearchForm.get('filterText').valueChanges.pipe(
      // if character length greater then 2
      filter(res => {
        if ( this.tabIndex == 0) {
          this.newMessage();
        }
        else if (this.tabIndex == 1) {
          this.readMessage();
        }

        if (res.length == 0) {
          this.SearchBy.Name = '';
          if ( this.tabIndex == 0) {
            this.newMessage();
          }
          else if (this.tabIndex == 1) {
            this.readMessage();
          }
          return false;
        }

        return res.length > 2
      }),

      // Time in milliseconds between key events
      debounceTime(650),

      // If previous query is diffent from current   
      distinctUntilChanged()

      // subscription for response
    ).subscribe((searchText: string) => {
      this.SearchBy.Name = searchText;
      if ( this.tabIndex == 0) {

        this.newMessage();
      }
      else if (this.tabIndex == 1) {

        this.readMessage();
      }
    });


 
  }
  someMethod() {
    this.trigger.openMenu();
  }

  resetFilters(): void {
    this.studentFilterForm.reset();
  }
  //Create SchoolVtpSectorFilter form and returns {FormGroup}
  createSchoolVtpSectorFilterForm(): FormGroup {
    return this.formBuilder.group({
      AcademicYearId: new FormControl(),
      SectorId: new FormControl(),
      VTPId: new FormControl(),
      // SchoolId: new FormControl(),
    });
  }

  changeTab(event) {
    this.tabIndex = event.index == undefined ? event : event.index;
    if (event.index == 0) {

      this.newMessage();
    }
    else if (event.index == 1) {

      this.readMessage();
    }
   
  }
  

  newMessage(){
    this.IsLoading = true;
    this.SearchBy.VTGuid=this.UserModel.UserTypeId;
  
    
      this.messageForVTService.GetUnReadMessagesForVT(this.SearchBy).subscribe(response => {
        this.newMessageData=response.Results;
        console.log(this.newMessageData)
       
        this.zone.run(() => {
          if (this.tableDataSource.data.length == 0) {
            this.showNoDataFoundSnackBar();
          }
        });
        this.IsLoading = false;
      }, error => {
        console.log(error);
      });
  }

  readMessage(){
    this.IsLoading = true;
    this.SearchBy.VTGuid=this.UserModel.UserTypeId;
  
    
      this.messageForVTService.GetReadMessagesForVT(this.SearchBy).subscribe(response => {
        this.readMessageData=response.Results;
        this.zone.run(() => {
          if (this.tableDataSource.data.length == 0) {
            this.showNoDataFoundSnackBar();
          }
        });
        this.IsLoading = false;
      }, error => {
        console.log(error);
      });
  }

  onDeleteJobRole(RequestId: string) {
    this.dialogService
      .openConfirmDialog('Are you sure read this message?')
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          this.messageForVTService.SetMessageRead(RequestId)
            .subscribe((msgResp: any) => {

              this.zone.run(() => {
                if (msgResp.Success) {
                  this.showActionMessage(
                    this.Constants.Messages.RecordDeletedMessage,
                    this.Constants.Html.SuccessSnackbar
                  );
                }
                this.ngOnInit();
              }, error => {
                console.log('Read this message errors =>', error);
              });

            });
          this.ngOnInit();
        }
      });
  }
}
