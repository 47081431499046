import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'app/common/base/base.component';
import { InternshipOpportunity } from 'app/main/internship-opportunity/internship-opportunity.model';
import { CommonService } from 'app/services/common.service';
import moment from 'moment';
import { InternshipApprovalService } from '../internship-approval.service';

@Component({
  selector: 'app-mark-internship-completed',
  templateUrl: './mark-internship-completed.component.html',
  styleUrls: ['./mark-internship-completed.component.scss']
})
export class MarkInternshipCompletedComponent extends BaseComponent<InternshipOpportunity> implements OnInit {

  passDatatoChild: any[];
  isLoading: boolean = true;
  errorMessage: any;
  studentInternshipDataById = [] as any;
  studentId: any;
  internshipId: any;
  complitionData: any;
  constructor(
    public service: InternshipApprovalService,
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MarkInternshipCompletedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(commonService, router, routeParams, snackBar);
    // dialogRef.disableClose = true;
  }


  // setTab(tabname: string) {
  //   this.router.navigate([`/${tabname}`]);
  // }
  ngOnInit() {
    console.log(this.data);
    this.studentId = this.data.StudentId;
    this.internshipId = this.data.InternshipId;
    this.studentInternshipDataById = this.data[0];
    this.markInternshipCompletedForm.get('CompletionDate').patchValue(moment(this.data.ActualEndDate));
  }

  onClose() {
    this.dialog.closeAll();
  }

  onSubmit(markInternshipCompletedForm) {
    this.dialogRef.close({ CompletionStatus: "true", Remarks: markInternshipCompletedForm.value.Remarks, CompletionDate: markInternshipCompletedForm.value.CompletionDate });
    // this.service.setCompletionStatus(this.studentId, this.internshipId, true, markInternshipCompletedForm.value.Remarks, markInternshipCompletedForm.value.CompletionDate).subscribe(response => {
    //   this.complitionData = response.Result;
  
    //   if (response.Success) {
    //     this.zone.run(() => {
    //       this.showActionMessage(
    //         response.Messages[0],
    //         this.Constants.Html.SuccessSnackbar
    //       );

    //     });
    //   } else {
    //     this.zone.run(() => {
    //       this.showActionMessage(
    //         response.Messages[0],
    //         this.Constants.Html.ErrorSnackbar,
    //       );
    //     });
    //   }
    // });
  }

  markInternshipCompletedForm: FormGroup = new FormGroup({
    CompletionDate: new FormControl("", Validators.required),
    Remarks: new FormControl(""),
  });

}
