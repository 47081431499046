import { Component, OnInit, NgZone, ViewEncapsulation, ElementRef } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { FuseUtils } from '@fuse/utils';
import { RouteConstants } from 'app/constants/route.constant'

import { DropdownModel } from 'app/models/dropdown.model';
import { FileUploadModel } from 'app/models/file.upload.model';
import { ReportService } from 'app/reports/report.service';
import { duration } from 'moment';
import { ResourceVideosService } from '../resource-videos.service';
import { ResourceVideosModel } from '../resource-videos-model.model';

@Component({
  selector: 'app-create-resource-videos',
  templateUrl: './create-resource-videos.component.html',
  styleUrls: ['./create-resource-videos.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CreateResourceVideosComponent extends BaseComponent<ResourceVideosModel> implements OnInit {
  resourceVideosForm: FormGroup;
  resourceVideosModel: ResourceVideosModel;
  id: string;
  title = 'angular-ckeditor-test';
  sectorList: [DropdownModel];
  filteredSectorListItems: any;
  filteredSubCategoryItems:any;
  stateCodeList: [DropdownModel];

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    public zone: NgZone,
    private route: ActivatedRoute,
    private reportService: ReportService,
    private resourceVideosService: ResourceVideosService,
    private dialogService: DialogService,
    private elRef: ElementRef,
    private formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Set the default account Model

    this.resourceVideosModel = new ResourceVideosModel();
  }


  ngOnInit(): void {
    this.resourceVideosService.getDropdownforResourceVideos(this.UserModel).subscribe((results) => {
      if (results[0].Success) {
        this.sectorList = results[0].Results;
        this.filteredSectorListItems = this.sectorList.slice();
      }
      if (results[6].Success) {
        this.stateCodeList = results[6].Results;
        for (const i of results[6].Results) {
          this.resourceVideosForm.get('StateCode').setValue(i.Id);
        }
      }

      // if (results[1].Success) {
      //   this.genderList = results[1].Results;
      // }
      // if (results[2].Success) {
      //   this.academicYearList = results[2].Results;
      // }
      // if (results[3].Success) {
      //   this.stateCodeList = results[3].Results;
      //   for (const i of results[3].Results) {
      //     this.resourceVideosForm.get('StateCode').setValue(i.Id);
      //   }
      // }

      this.route.paramMap.subscribe(params => {
        if (params.keys.length > 0) {
          this.PageRights.ActionType = params.get('actionType');

          if (this.PageRights.ActionType == this.Constants.Actions.New) {
            this.resourceVideosModel = new ResourceVideosModel();

          } else {
            var resourceId: string = params.get('videoLinkId')

            this.resourceVideosService.GetResourceVideosById(resourceId)
              .subscribe((response: any) => {
                this.resourceVideosModel = response.Result;
                this.resourceVideosForm.get("ResourceCategoryId").patchValue(response.Result.ResourceCategoryId);
                if (this.PageRights.ActionType == this.Constants.Actions.Edit)
                  this.resourceVideosModel.RequestType = this.Constants.PageType.Edit;
                else if (this.PageRights.ActionType == this.Constants.Actions.View) {
                  this.resourceVideosModel.RequestType = this.Constants.PageType.View;
                  this.PageRights.IsReadOnly = true;
                }
               
               
                this.resourceVideosForm = this.createResourceVideosForm();
                // this.resourceVideosForm.controls['ResourceCategoryId'].patchValue(response.Result.ResourceCategoryId);
                this.resourceVideosForm.get("ResourceCategoryId").patchValue(response.Result.ResourceCategoryId);
              });
          }
        }
      });
    });

    this.resourceVideosForm = this.createResourceVideosForm();
  }


  saveOrUpdateResourceVideosDetails() {
    if (this.stateCodeList) {
      for (const i of this.stateCodeList) {
        this.resourceVideosForm.get('StateCode').setValue(i.Id);
        this.resourceVideosForm.get('CreatedBy').setValue(this.UserModel.UserId);

      }
    }
    if (!this.resourceVideosForm.valid) {
      this.validateAllFormFields(this.resourceVideosForm);
      return;
    }

    this.setValueFromFormGroup(this.resourceVideosForm, this.resourceVideosModel);
    // this.resourceVideosModel.VTId = this.UserModel.UserTypeId; //old
    this.resourceVideosModel.ResourceType = 'Videos'
    this.resourceVideosModel.TargetAudienceType = 'Student';
    let categoryName: any = this.sectorList.filter(x => x.Id == this.resourceVideosModel.ResourceCategoryId);
    this.resourceVideosModel.Category = categoryName[0].Name;
    this.resourceVideosService.CreateVideoResources(this.resourceVideosModel)
      .subscribe((headMasterResp: any) => {

        if (headMasterResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );

            this.router.navigate([RouteConstants.ResourceVideos.List]);
          });
        }
        else {
          var errorMessages = this.getHtmlMessage(headMasterResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('Resource Videos deletion errors =>', error);
      });
  }

  //Create headMaster form and returns {FormGroup}
  createResourceVideosForm(): FormGroup {
    return this.formBuilder.group({
      ResourceId: new FormControl(this.resourceVideosModel.ResourceId),
      ResourceTitle: new FormControl({ value: this.resourceVideosModel.ResourceTitle, disabled: this.PageRights.IsReadOnly }, Validators.required),
      ResourceType: new FormControl({ value: this.resourceVideosModel.ResourceType, disabled: this.PageRights.IsReadOnly }),
      ResourceCategoryId: new FormControl({ value: this.resourceVideosModel.ResourceCategoryId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      Category: new FormControl({ value: this.resourceVideosModel.Category, disabled: this.PageRights.IsReadOnly }),
      ResourceSubcategoryId: new FormControl({ value: this.resourceVideosModel.ResourceSubcategoryId, disabled: this.PageRights.IsReadOnly }),
      SubCategory: new FormControl({ value: this.resourceVideosModel.SubCategory, disabled: this.PageRights.IsReadOnly }),
      ResourceDescription: new FormControl({ value: this.resourceVideosModel.ResourceDescription, disabled: (this.PageRights.IsReadOnly) }, Validators.required),
      ResourceLink: new FormControl({ value: this.resourceVideosModel.ResourceLink, disabled: this.PageRights.IsReadOnly }, Validators.required),
      TargetAudienceType: new FormControl({ value: this.resourceVideosModel.TargetAudienceType, disabled: true }),
      ResourcesImageUrl: new FormControl({ value: this.resourceVideosModel.ResourcesImageUrl, disabled: this.PageRights.IsReadOnly }),
      IsActive: new FormControl({ value: this.resourceVideosModel.IsActive, disabled: this.PageRights.IsReadOnly }),
      StateCode: new FormControl({ value: this.resourceVideosModel.StateCode, }),
      CreatedBy: new FormControl({ value: this.resourceVideosModel.CreatedBy, }),
      PublishDate: new FormControl({ value: this.getDateValue(this.resourceVideosModel.PublishDate), disabled: this.PageRights.IsReadOnly }, Validators.required), 

    });
  }
}
