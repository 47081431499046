import { Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { BaseComponent } from 'app/common/base/base.component';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { RouteConstants } from 'app/constants/route.constant';
import { DropdownModel } from 'app/models/dropdown.model';
import { CommonService } from 'app/services/common.service';
import { InternshipOpportunity } from '../internship-opportunity.model';
import { InternshipOpportunityService } from '../internship-opportunity.service';

@Component({
  selector: 'app-create-internship-opportunity',
  templateUrl: './create-internship-opportunity.component.html',
  styleUrls: ['./create-internship-opportunity.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CreateInternshipOpportunityComponent extends BaseComponent<InternshipOpportunity> implements OnInit {
  internshipOpportunityForm: FormGroup;
  internshipOpportunityModel: InternshipOpportunity

  currentAcademicYearId: string;
  sectorList: [DropdownModel];

  filteredVtpSectorItems: any;
  employerList: [DropdownModel];
  distList: [DropdownModel];
  filteredDistrictItems: any;
  filteredEmployerItems: any;
  academicYearList: [DropdownModel];
  internshipTypeList = [{ Id: 'Online', Name: 'Online' }, { Id: 'Offline', Name: 'Offline' }, { Id: 'Hybrid', Name: 'Hybrid' }];
  branchList: [DropdownModel];
  isPublished: boolean = false;
  interestList: any;
  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private route: ActivatedRoute,
    private internshiOpportunityService: InternshipOpportunityService,
    private dialogService: DialogService,
    private formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Set the default school Model
    this.internshipOpportunityModel = new InternshipOpportunity();
  }

  ngOnInit(): void {
    this.internshiOpportunityService.getDropdownforInternshipOpportunity(this.UserModel).subscribe((results) => {

      if (results[0].Success) {
        this.sectorList = results[0].Results;
      }

      // if (results[1].Success) {
      //   this.employerList = results[1].Results;
      //   this.filteredEmployerItems = this.employerList.slice();
      // }

      if (results[2].Success) {
        this.distList = results[2].Results;
        this.filteredDistrictItems = this.distList.slice();
      }

      // if (results[3].Success) {
      //   this.stateList = results[3].Results;
      // }
      if (results[3].Success) {
        this.academicYearList = results[3].Results;
      }

      if (results[4].Success) {
        this.branchList = results[4].Results;
      }

      if (results[5].Success) {
        this.interestList = results[5].Results;
      }

      let currentYearItem = this.academicYearList.find(ay => ay.IsSelected == true)
      if (currentYearItem != null) {
        this.currentAcademicYearId = currentYearItem.Id;
      }

      this.getEmployersByCriteria();
      this.route.paramMap.subscribe(params => {

        if (params.keys.length > 0) {
          this.PageRights.ActionType = params.get('actionType');

          if (this.PageRights.ActionType == this.Constants.Actions.New) {
            this.internshipOpportunityModel = new InternshipOpportunity();

          } else {
            var InternshipId: string = params.get('InternshipId');

            this.internshiOpportunityService.getInternshipById(InternshipId)
              .subscribe((response: any) => {
                this.internshipOpportunityModel = response.Result;

                if (this.PageRights.ActionType == this.Constants.Actions.Edit)
                  this.internshipOpportunityModel.RequestType = this.Constants.PageType.Edit;
                else if (this.PageRights.ActionType == this.Constants.Actions.View) {
                  this.internshipOpportunityModel.RequestType = this.Constants.PageType.View;
                  this.PageRights.IsReadOnly = true;
                }
                this.onChangeEmployeer(this.internshipOpportunityModel.EmployerId);
                this.internshipOpportunityForm = this.createInternshipOpportunityForm();
              });
          }
        }
      });
    });

    this.internshipOpportunityForm = this.createInternshipOpportunityForm();
  }


  getEmployersByCriteria(): any {
    debugger;
    this.IsLoading = true;
    let data = {
      VTId: this.UserModel.UserTypeId,
      VTGuid: this.UserModel.UserTypeId,
      UserTypeId: null,
      RoleCode: this.UserModel.RoleCode,
      PageIndex: 0,
      PageSize: 10000,
      Name: null,
      CharBy: null,
      SectorGuid: null,
      StartDateTime: null,
      EndDatetime: null,
      IsActive: null
    }
    this.internshiOpportunityService.GetEmployersByCriteria(data).subscribe(response => {
      debugger;
      let empList = response.Results;
      let arr = [] as any;
      for (let i = 0; i < empList.length; i++) {
        let xxx = {
          Id: empList[i].EmployerId,
          Name: empList[i].OrganizationName,
          Description: '',
          IsSelected: false,
          IsDisabled: false,
          SequenceNo: 1
        }
        arr.push(xxx);
      }
      this.employerList = arr;
      this.filteredEmployerItems = this.employerList.slice()
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }


  saveOrUpdateInternshipOpportunityDetails(type: any) {


    if (type == 'draft') {
      this.isPublished = false;
    } else {
      this.isPublished = true;
    }

    if (!this.internshipOpportunityForm.valid) {
      this.validateAllFormFields(this.internshipOpportunityForm);
      return;
    }

    this.setValueFromFormGroup(this.internshipOpportunityForm, this.internshipOpportunityModel);

    this.internshipOpportunityModel.AuthUserId = this.UserModel.UserId;
    if (this.isPublished) {
      this.internshipOpportunityModel.IsPublished = true;
    } else {
      this.internshipOpportunityModel.IsPublished = false;
    }

    //  let xxx=this.branchList.filter(x=>x.Id==this.internshipOpportunityForm.value.BranchId);

    this.internshipOpportunityModel.BranchName = this.branchList.filter(x => x.Id == this.internshipOpportunityForm.value.BranchId)[0].Name;
    this.internshipOpportunityModel.DistrictName = this.distList.filter(x => x.Id == this.internshipOpportunityForm.value.DistrictCode)[0].Name;
    this.internshipOpportunityModel.AcademicYearId = this.currentAcademicYearId;
    // this.internshipOpportunityModel.DateOfEnrollment = this.CurrentDate

    this.internshiOpportunityService.createOrUpdateInternship(this.internshipOpportunityModel)
      .subscribe((internshipResp: any) => {

        if (internshipResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );

            this.router.navigate([RouteConstants.InternshipOpportunity.List]);
            // window.history.back();  // Nilesh has Implemeted this logic

          });
        }
        else {
          var errorMessages = this.getHtmlMessage(internshipResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('Student deletion errors =>', error);
      });
  }

  onChangeEmployeer(EmployeerId: any) {
    this.commonService.GetMasterDataByType({ DataType: 'EmpBranch', ParentId: EmployeerId, SelectTitle: 'Employer Branch' }).subscribe((response: any) => {

      this.branchList = response.Results;
    });
  }

  onChangeBranchName(evt: any) {

  }
  //Create Internship Opportunity form and returns {FormGroup}
  createInternshipOpportunityForm(): FormGroup {
    return this.formBuilder.group({

      InternshipId: new FormControl(this.internshipOpportunityModel.InternshipId),
      IsActive: new FormControl({ value: this.internshipOpportunityModel.IsActive, disabled: this.PageRights.IsReadOnly }, Validators.required),
      WorkingType: new FormControl({ value: this.internshipOpportunityModel.WorkingType, disabled: this.PageRights.IsReadOnly }),
      Title: new FormControl({ value: this.internshipOpportunityModel.Title, disabled: this.PageRights.IsReadOnly }, [Validators.required, Validators.pattern(this.Constants.Regex.CharsWithTitleCase), Validators.maxLength(150)]),
      EmployerId: new FormControl({ value: this.internshipOpportunityModel.EmployerId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      BranchId: new FormControl({ value: this.internshipOpportunityModel.BranchId, disabled: this.PageRights.IsReadOnly }),
      BranchName: new FormControl({ value: this.internshipOpportunityModel.BranchName, disabled: this.PageRights.IsReadOnly }),
      InternshipType: new FormControl({ value: this.internshipOpportunityModel.InternshipType, disabled: this.PageRights.IsReadOnly }, Validators.required),
      SectorId: new FormControl({ value: this.internshipOpportunityModel.SectorId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      StartDate: new FormControl({ value: this.getDateValue(this.internshipOpportunityModel.StartDate), disabled: this.PageRights.IsReadOnly }, Validators.required),
      Duration: new FormControl({ value: this.internshipOpportunityModel.Duration, disabled: this.PageRights.IsReadOnly }, Validators.required),
      Stipend: new FormControl({ value: this.internshipOpportunityModel.Stipend, disabled: this.PageRights.IsReadOnly }, Validators.required),
      NoOfOpenings: new FormControl({ value: this.internshipOpportunityModel.NoOfOpenings, disabled: this.PageRights.IsReadOnly }, Validators.required),
      JobDescription: new FormControl({ value: this.internshipOpportunityModel.JobDescription, disabled: this.PageRights.IsReadOnly }),
      Perks: new FormControl({ value: this.internshipOpportunityModel.Perks, disabled: this.PageRights.IsReadOnly }),
      RequiredSkills: new FormControl({ value: this.internshipOpportunityModel.RequiredSkills, disabled: this.PageRights.IsReadOnly }),
      DistrictCode: new FormControl({ value: this.internshipOpportunityModel.DistrictCode, disabled: this.PageRights.IsReadOnly }, [Validators.required]),
      DistrictName: new FormControl({ value: this.internshipOpportunityModel.DistrictName, disabled: this.PageRights.IsReadOnly }),
      Pincode: new FormControl({ value: this.internshipOpportunityModel.Pincode, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(6), Validators.minLength(6), Validators.pattern(this.Constants.Regex.Number)]),
      IsCompleted: new FormControl({ value: this.internshipOpportunityModel.IsCompleted, disabled: this.PageRights.IsReadOnly }),
      IsPublished: new FormControl({ value: this.internshipOpportunityModel.IsPublished, disabled: this.PageRights.IsReadOnly }),
      PublishDate: new FormControl({ value: this.getDateValue(this.internshipOpportunityModel.PublishDate), disabled: this.PageRights.IsReadOnly }, Validators.required),
      AcademicYearId: new FormControl({ value: this.internshipOpportunityModel.AcademicYearId, disabled: this.PageRights.IsReadOnly }),
      POCForInternship: new FormControl({ value: this.internshipOpportunityModel.POCForInternship, disabled: this.PageRights.IsReadOnly }),
      POCEmail: new FormControl({ value: this.internshipOpportunityModel.POCEmail, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(50), Validators.pattern(this.Constants.Regex.Email)]),
      POCMobile: new FormControl({ value: this.internshipOpportunityModel.POCMobile, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(10), Validators.minLength(10), Validators.pattern(this.Constants.Regex.MobileNumber)]),

      InterestId: new FormControl({ value: this.internshipOpportunityModel.InterestId, disabled: this.PageRights.IsReadOnly }, Validators.required),
    });
  }

}
