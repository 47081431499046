import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SchoolClassSectionsModel } from './school-class-sections.model';
import { SchoolClassSectionsService } from './school-class-sections.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as guarati } from './i18n/gj';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DropdownModel } from 'app/models/dropdown.model';

@Component({
  selector: 'data-list-view',
  templateUrl: './school-class-sections.component.html',
  styleUrls: ['./school-class-sections.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class SchoolClassSectionsComponent extends BaseListComponent<SchoolClassSectionsModel> implements OnInit {
  schoolClassSectionFilterForm:FormGroup;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private translationLoaderService: FuseTranslationLoaderService,
    public zone: NgZone,
    private dialogService: DialogService,
    private formBuilder: FormBuilder,
    private schoolClassSectionsService: SchoolClassSectionsService) {
    super(commonService, router, routeParams, snackBar, zone);
    this.translationLoaderService.loadTranslations(english, guarati);
    this.schoolClassSectionFilterForm = this.createSchoolClassSectionFilterForm();

  }

  ngOnInit(): void {
    this.onLoadSchoolClassSectionCriteria();
    // this.schoolClassSectionsService.GetAllByCriteria(this.SearchBy).subscribe(response => {
    //   this.displayedColumns = ['SchoolName', 'ClassName', 'SectionName','UDISE', 'IsActive', 'Actions'];

    //   this.tableDataSource.data = response.Results;
    //   this.tableDataSource.sort = this.ListSort;
    //   this.tableDataSource.paginator = this.ListPaginator;
    //   this.tableDataSource.filteredData = this.tableDataSource.data;

    //   this.zone.run(() => {
    //     if (this.tableDataSource.data.length == 0) {
    //       this.showNoDataFoundSnackBar();
    //     }
    //   });
    //   this.IsLoading = false;
    // }, error => {
    //   console.log(error);
    // });
  }

  onLoadSchoolClassSectionCriteria(): any {
    this.IsLoading = true;
    this.SearchBy.IsActive = this.schoolClassSectionFilterForm.controls["Status"].value=='true'?true:this.schoolClassSectionFilterForm.controls["Status"].value=='false'?false:this.schoolClassSectionFilterForm.controls["Status"].value;
    this.schoolClassSectionsService.GetAllByCriteria(this.SearchBy).subscribe(response => {
      this.displayedColumns = ['SchoolName', 'ClassName', 'SectionName','UDISE', 'IsActive', 'Actions'];

      this.tableDataSource.data = response.Results;
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;

      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
    });
  }

  onDeletetSchoolClassSections(schoolClassSectionId: string) {
    this.dialogService
      .openConfirmDialog(this.Constants.Messages.DeleteConfirmationMessage)
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          this.schoolClassSectionsService.deleteSchoolClassSectionsById(schoolClassSectionId)
            .subscribe((schoolClassSectionsResp: any) => {

              this.zone.run(() => {
                if (schoolClassSectionsResp.Success == true) {
                  this.showActionMessage(
                    this.Constants.Messages.RecordDeletedMessage,
                    this.Constants.Html.SuccessSnackbar
                  );
                }else{
                  this.showActionMessage(
                    schoolClassSectionsResp.Result,
                    this.Constants.Html.SuccessSnackbar
                  );
                   this.ngOnInit();

                }
                this.ngOnInit();
              }, error => {
                console.log('SchoolClassSections deletion errors =>', error);
              });

            });
          this.ngOnInit();
        }
      });
  }

  exportExcel(): void {
    this.IsLoading = true;
    this.SearchBy.PageIndex = 0;
    this.SearchBy.PageSize = 10000;
  
    this.schoolClassSectionsService.GetAllByCriteria(this.SearchBy).subscribe(response => {
      response.Results.forEach(
        function (obj) {
          if (obj.hasOwnProperty('IsActive')) {
            obj.IsActive = obj.IsActive ? 'Yes' : 'No';
          }
          if (obj.hasOwnProperty('IsImplemented')) {
            obj.IsImplemented = obj.IsImplemented ? 'Yes' : 'No';
          }
          delete obj.SchoolClassSectionId;
          delete obj.SchoolId;
          delete obj.ClassId;
          delete obj.SectionId;
          delete obj.StateCode;
          delete obj.StateName;
          delete obj.Remarks;
          delete obj.TotalRows;
        });
  
      this.exportExcelFromTable(response.Results, "SchoolClassSections");
  
      this.IsLoading = false;
      this.SearchBy.PageIndex = 0;
      this.SearchBy.PageSize = 10;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  //Create SchoolClassSection form and returns {FormGroup}
  createSchoolClassSectionFilterForm(): FormGroup {
    return this.formBuilder.group({
     // AcademicYearId: new FormControl(),
      Status: new FormControl()
    });
  }

  onLoadSchoolClassSectionByFilters(): any {
    this.SearchBy.PageIndex = 0;
    this.onLoadSchoolClassSectionCriteria();
  }

  resetFilters(): void {
    this.SearchBy.PageIndex = 0;
    this.schoolClassSectionFilterForm.reset();
    //this.schoolFilterForm.get('AcademicYearId').setValue(this.AcademicYearId);

    this.onLoadSchoolClassSectionCriteria();
  }

}
