import { Component, OnInit, NgZone, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { DropdownModel } from 'app/models/dropdown.model';
import { environment } from 'environments/environment';
import { EmployerNoticeBoardService } from './employer-notice-board.service';
import { EmployerNoticeBoardModel } from './employer-notice-board.model';




@Component({
  selector: 'app-employer-notice-board',
  templateUrl: './employer-notice-board.component.html',
  styleUrls: ['./employer-notice-board.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class EmployerNoticeBoardComponent extends BaseListComponent<EmployerNoticeBoardModel> implements OnInit {

  employerNoticeBoardSearchForm: FormGroup;
  employerNoticeBoardModel: EmployerNoticeBoardModel;
  currentAcademicYearId: string;
  academicYearList: [DropdownModel];
  sectorList: [DropdownModel];
  vtpList: [DropdownModel];
  filteredVtpSectorItems: any;
  schoolList: [DropdownModel];
  classList: [DropdownModel];
  sectionList: [DropdownModel];
  filteredSchoolItems: any;
  employerNoticeBoardFilterForm: FormGroup;
  ApiBaseUrlImage = this.Constants.CommonImageUrl.Account;
  showMore = false;
  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private translationLoaderService: FuseTranslationLoaderService,
    public zone: NgZone,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private employerNoticeBoardService: EmployerNoticeBoardService,) {
    super(commonService, router, routeParams, snackBar, zone);
    //this.translationLoaderService.loadTranslations(english, guarati);
    this.employerNoticeBoardSearchForm = this.formBuilder.group({ filterText: '' });
    this.employerNoticeBoardModel = new EmployerNoticeBoardModel();
    this.employerNoticeBoardFilterForm = this.createSchoolVtpSectorFilterForm();


  }

  ngOnInit(): void {

    this.employerNoticeBoardSearchForm = this.formBuilder.group({ filterText: '' });

    // Load initial schools data
    this.onLoadEmployerNoticeBoardByCriteria();

    this.employerNoticeBoardSearchForm.get('filterText').valueChanges.pipe(
      // if character length greater then 2
      filter(res => {
        this.onLoadEmployerNoticeBoardByCriteria();

        if (res.length == 0) {
          this.SearchBy.Name = '';
          this.onLoadEmployerNoticeBoardByCriteria();
          return false;
        }

        return res.length > 2
      }),

      // Time in milliseconds between key events
      debounceTime(650),

      // If previous query is diffent from current   
      distinctUntilChanged()

      // subscription for response
    ).subscribe((searchText: string) => {
      this.SearchBy.Name = searchText;
      this.onLoadEmployerNoticeBoardByCriteria();
    });
  }

  ngAfterViewInit() {
    this.tableDataSource.paginator = this.ListPaginator;
  }

  onPageIndexChanged(evt) {
    // console.log(evt)
    // if(evt.pageIndex == 0){
    //   this.SearchBy.PageIndex = 1;
    // }else{
    //   this.SearchBy.PageIndex = evt.pageIndex;
    // }
    this.SearchBy.PageIndex = evt.pageIndex;

    this.SearchBy.PageSize = evt.pageSize;

    this.onLoadEmployerNoticeBoardByCriteria();
  }

  onLoadEmployerNoticeBoardByCriteria(): any {
    this.IsLoading = true;

    console.log(this.Constants.GlobalSchoolId)

    //if (this.Constants.GlobalSchoolId != null && this.Constants.GlobalAcademicYearId != null) {
    //if (this.Constants.GlobalSchoolId != "undefined" && this.Constants.GlobalAcademicYearId != "undefined") {
    this.SearchBy.VTId = this.UserModel.UserTypeId;

    this.SearchBy.UserTypeId = this.UserModel.UserTypeId;
    this.SearchBy.SchoolGuid = this.Constants.GlobalSchoolId;
    this.SearchBy.AcademicYearGuid = this.Constants.GlobalAcademicYearId;
    this.SearchBy.Name = 'Employer';
    this.SearchBy.RoleCode = this.UserModel.RoleCode;
    this.SearchBy.SectorGuid = this.employerNoticeBoardFilterForm.controls['SectorId'].value;
    this.SearchBy.ClassGuid = this.employerNoticeBoardFilterForm.controls['Class'].value;
    this.SearchBy.SectionGuid = this.employerNoticeBoardFilterForm.controls['Section'].value;
    this.employerNoticeBoardService.GetNoticesByType(this.SearchBy).subscribe(response => {
      this.displayedColumns = ['NoticeDescription', 'PublishDate', 'ExpiryDate', 'Actions'];
      let resEmpNoticeBoard = response.Results.filter(eNB => eNB.NoticeType == 'Employer');

      this.tableDataSource.data = resEmpNoticeBoard;
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;
      this.SearchBy.TotalResults = this.tableDataSource.filteredData.length;

      setTimeout(() => {
        this.ListPaginator.pageIndex = this.SearchBy.PageIndex;
        this.ListPaginator.length = this.SearchBy.TotalResults;
      });
      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          // this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
    //   } else {
    //     this.zone.run(() => {
    //       this.showActionMessage(
    //         'Please Select  AcademicYear',
    //         this.Constants.Html.SuccessSnackbar
    //       );
    //     });
    //   }

    // } else {
    //   this.zone.run(() => {
    //     this.showActionMessage(
    //       'Please Select School & AcademicYear',
    //       this.Constants.Html.SuccessSnackbar
    //     );
    //   });

    // }


  }



  DeleteNoticesById(noticeId: any) {
    this.dialogService
      .openConfirmDialog(this.Constants.Messages.DeleteConfirmationMessage)
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          this.employerNoticeBoardService.DeleteNoticesById(noticeId)
            .subscribe((schoolResp: any) => {

              this.zone.run(() => {
                if (schoolResp.Success) {
                  this.showActionMessage(
                    this.Constants.Messages.RecordDeletedMessage,
                    this.Constants.Html.SuccessSnackbar
                  );
                }
                this.ngOnInit();
              }, error => {
                console.log('School deletion errors =>', error);
              });

            });
          this.ngOnInit();
        }
      });
  }

  exportExcel(): void {
    this.IsLoading = true;
    this.SearchBy.PageIndex = 0;
    this.SearchBy.PageSize = 10000;

    this.employerNoticeBoardService.GetNoticesByType(this.SearchBy).subscribe(response => {
      response.Results.forEach(
        function (obj) {
          if (obj.hasOwnProperty('IsActive')) {
            obj.IsActive = obj.IsActive ? 'Yes' : 'No';
          }
          if (obj.hasOwnProperty('IsImplemented')) {
            obj.IsImplemented = obj.IsImplemented ? 'Yes' : 'No';
          }
          delete obj.SchoolId;
          delete obj.StudentId;
          delete obj.AcademicYearId;
          delete obj.SectionId;
          delete obj.ClassId;
          delete obj.TotalRows;
        });

      this.exportExcelFromTable(response.Results, "Student");

      this.IsLoading = false;
      this.SearchBy.PageIndex = 0;
      this.SearchBy.PageSize = 10;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  onLoadSchoolVTPSectorsByFilters(): any {
    this.onLoadEmployerNoticeBoardByCriteria();
  }

  resetFilters(): void {
    this.employerNoticeBoardSearchForm.reset();

    this.onLoadEmployerNoticeBoardByCriteria();

    this.employerNoticeBoardSearchForm.reset();
    // this.StudentNoticeBoardFilterForm.get('AcademicYearId').setValue(this.currentAcademicYearId);
    // this.SearchBy.SectorGuid = ''
  }
  //Create SchoolVtpSectorFilter form and returns {FormGroup}
  createSchoolVtpSectorFilterForm(): FormGroup {
    return this.formBuilder.group({
      // AcademicYearId: new FormControl(),
      SectorId: new FormControl(),
      Class: new FormControl(),
      Section: new FormControl(),
      // SchoolId: new FormControl(),
    });
  }

  onChangeClass() {
    this.commonService.GetMasterDataByType({ DataType: 'ClassesByVT', ParentId: this.Constants.GlobalSchoolId, UserId: this.UserModel.UserTypeId, SelectTitle: 'Class' }).subscribe((response: any) => {
      this.classList = response.Results;
    });
  }
  onChangeSection(ClassId: any) {
    this.commonService.GetMasterDataByType({ DataType: 'Sections', ParentId: ClassId, SelectTitle: 'Sections' }).subscribe((response: any) => {
      this.sectionList = response.Results;
    });
  }

}
