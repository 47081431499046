<div id="student-report" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    Student Registration Report
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter (keyup)="applySearchFilter($event.target.value)"
                        placeholder="Search for Student Registration Report">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">
                <a *ngIf="PageRights.IsExport" class="export-excel"
                    (click)="exporter.exportTable('xlsx', {fileName:'StudentRegistration', sheet: 'StudentRegistration', Props: {Author: 'Pathways'}})">
                    <mat-icon> cloud_download</mat-icon>
                </a>
            </div>
            <!-- / ADD BUTTON -->
        </div>
        <!-- / HEADER -->

        <!-- <div fxLayout="row" fxLayoutAlign="start center">
            <button mat-raised-button class="fuse-white filter-button" style="margin-bottom: 10px;"
                (click)="isShowFilterContainer=!isShowFilterContainer"><span>FILTERS</span></button>
        </div> -->

        <!-- Filter Section -->
        <div class="filter-content-card">
            <div class="filter-content">
                <form name="studentReportForm" [formGroup]="studentReportForm" class="vt-school-sector"
                    fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label> Academic Year</mat-label>

                            <mat-select formControlName="AcademicYearGuid" name="academicYearGuid" (selectionChange)="onChangeSchoolAY($event.value)" appearance="fill">
                                <mat-option [value]="academicYearItem.Id"
                                    *ngFor="let academicYearItem of academicyearList">
                                    {{ academicYearItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>School Name</mat-label>
                            
                            <mat-select formControlName="SchoolGuid" name="schoolGuid" appearance="fill" #SelectSchool>
                                <mat-select-filter class="select-filter" *ngIf="SelectSchool.focused"
                                            [array]="schoolList"
                                            (filteredReturn)="this.filteredSchoolItems = $event"
                                            [displayMember]="'Name'">
                                        </mat-select-filter>
                                <mat-option [value]="Item.Id" *ngFor="let Item of filteredSchoolItems">
                                    {{ Item.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Udise</mat-label>

                            <mat-select formControlName="UdiseGuid" name="udiseGuid" appearance="fill">
                                <mat-option [value]="Item.Id" *ngFor="let Item of UdiseList">
                                    {{ Item.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field> -->

                        <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>School Management</mat-label>

                            <mat-select formControlName="SchoolManagementId" name="schoolManagementId"
                                appearance="fill">
                                <mat-option [value]="Item.Id" *ngFor="let Item of SchoolManagementtList">
                                    {{ Item.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>School category</mat-label>

                            <mat-select formControlName="SchoolCategoryId" name="schoolCategoryId" appearance="fill">
                                <mat-option [value]="Item.Id" *ngFor="let Item of SchoolCategoryList">
                                    {{ Item.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Class</mat-label>

                            <mat-select formControlName="ClassGuid" name="classGuid" appearance="fill">
                                <mat-option [value]="item.Id" *ngFor="let item of classList">
                                    {{ item.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Section</mat-label>

                            <mat-select formControlName="SectionGuid" name="sectionGuid" appearance="fill">
                                <mat-option [value]="item.Id" *ngFor="let item of SectionList">
                                    {{ item.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="filter-disabled">
                            <mat-label>State</mat-label>

                            <mat-select formControlName="StudentStateCode" name="studentStateCode" appearance="fill"
                                (selectionChange)="onChangeState($event.value)">
                                <mat-option [value]="Item.Id" *ngFor="let Item of stateList">
                                    {{ Item.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="filter-disabled">
                            <mat-label>Division</mat-label>

                            <mat-select formControlName="DivisionGuid" name="divisionGuid" appearance="fill"
                                (selectionChange)="onChangeDivision($event.value)">
                                <mat-option [value]="Item.Id" *ngFor="let Item of divisionList">
                                    {{ Item.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="filter-disabled">
                            <mat-label>District</mat-label>

                            <mat-select formControlName="DistrictCode" name="districtCode" appearance="fill"  (selectionChange)="onChangeBlock($event.value)" #SelectDistrict>
                                <mat-select-filter class="select-filter" *ngIf="SelectDistrict.focused"
                                            [array]="districtList"
                                            (filteredReturn)="this.filteredDistrictItems = $event"
                                            [displayMember]="'Name'">
                                        </mat-select-filter>
                                <mat-option [value]="districtItem.Id" *ngFor="let districtItem of filteredDistrictItems">
                                    {{ districtItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>


                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Block</mat-label>

                            <mat-select formControlName="BlockGuid" name="blockGuid" appearance="fill" #SelectBlock>
                                <mat-select-filter class="select-filter" *ngIf="SelectBlock.focused"
                                            [array]="BlockList"
                                            (filteredReturn)="this.filteredBlockItems = $event"
                                            [displayMember]="'Name'">
                                        </mat-select-filter>
                                <mat-option [value]="Item.Id" *ngFor="let Item of filteredBlockItems">
                                    {{ Item.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Sector</mat-label>

                            <mat-select formControlName="SectorGuid" name="sectorGuid" appearance="fill"
                                (selectionChange)="onChangeSector($event.value)" #SelectSector>
                                <mat-select-filter class="select-filter" *ngIf="SelectSector.focused"
                                            [array]="sectorList"
                                            (filteredReturn)="this.filteredSectorItems = $event"
                                            [displayMember]="'Name'">
                                        </mat-select-filter>
                                <mat-option [value]="Item.Id" *ngFor="let Item of filteredSectorItems">
                                    {{ Item.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Job Role</mat-label>

                            <mat-select formControlName="JobRoleGuid" name="JobRoleGuid" appearance="fill">
                                <mat-option [value]="item.Id" *ngFor="let item of jobRoleList">
                                    {{ item.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>VTP</mat-label>

                            <mat-select formControlName="VTPGuid" name="vTPGuid" appearance="fill" (selectionChange)="onChangeVC($event.value)">
                                <mat-option [value]="Item.Id" *ngFor="let Item of vtpList">
                                    {{ Item.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                      

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Vocational Coordinator </mat-label>

                            <mat-select formControlName="VCGuid" name="vCGuid" appearance="fill" (selectionChange)="onChangeVT($event.value)">
                                <mat-option [value]="vtpItem.Id" *ngFor="let vtpItem of vocationalCoordinatorList">
                                    {{ vtpItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>




                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">


                        <mat-form-field fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Vocational Trainer</mat-label>

                            <mat-select formControlName="VTGuid" name="vTGuid" appearance="fill" #SelectVocationalTrainer>
                                <mat-select-filter class="select-filter" *ngIf="SelectVocationalTrainer.focused"
                                            [array]="VocationalTrainerList"
                                            (filteredReturn)="this.filteredVocationalTrainerItems = $event"
                                            [displayMember]="'Name'">
                                        </mat-select-filter>
                                <mat-option [value]="Item.Id" *ngFor="let Item of filteredVocationalTrainerItems">
                                    {{ Item.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="clear-filter pr-1" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="getStudentReports()"><span>SEARCH</span></button>
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="resetReportFilters()"><span>CLEAR</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- /Filter Section -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table class="vt-school-sector-report-table responsive-table" #table [dataSource]="tableDataSource"
                matTableExporter #exporter="matTableExporter" [hiddenColumns]="[0]" matSort fusePerfectScrollbar
                [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- SrNo Column -->
                <ng-container matColumnDef="SrNo">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Sr No</mat-header-cell>
                    <mat-cell *matCellDef="let studentReport; let RowIndex = index;">
                        <p class="text-truncate">{{studentReport.SrNo}}</p>
                    </mat-cell>
                </ng-container>

                <!-- AcademicYear Column -->
                <ng-container matColumnDef="AcademicYear">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Academic Year
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.YearName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- FullName Column -->
                <ng-container matColumnDef="FullName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Full Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.FullName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Gender Column -->
                <ng-container matColumnDef="Gender">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Gender
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.StudentGender}}</p>
                    </mat-cell>
                </ng-container>

                <!-- FatherName Column -->
                <ng-container matColumnDef="FatherName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Father Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.FatherName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- MotherName Column -->
                <ng-container matColumnDef="MotherName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Mother Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.MotherName}}</p>
                    </mat-cell>
                </ng-container>





                <!-- MobileNo. Column -->
                <ng-container matColumnDef="MobileNo">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Mobile No.
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.Mobile}}</p>
                    </mat-cell>
                </ng-container>

                <!-- WhatsApp Column -->
                <ng-container matColumnDef="WhatsappNo">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Whatsapp No.
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.Mobile1}}</p>
                    </mat-cell>
                </ng-container>

                <!-- EmailId Column -->
                <ng-container matColumnDef="EmailId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Emailid
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.EmailId}}</p>
                    </mat-cell>
                </ng-container>

                <!-- DOB Column -->
                <ng-container matColumnDef="DateOfBirth">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Date Of Birth
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.DateOfBirth | date: Constants.ShortDateFormat}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SchoolName Column -->
                <ng-container matColumnDef="SchoolName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>School Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.SchoolName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SchoolId Column -->
                <ng-container matColumnDef="SchoolId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>SchoolId
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.SchoolId}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Udise Column -->
                <ng-container matColumnDef="Udise">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Udise
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.UDISE}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SchoolManagement Column -->
                <ng-container matColumnDef="SchoolManagement">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>School Management
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.SchoolManagement}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Schoolcategory Column -->
                <ng-container matColumnDef="Schoolcategory">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>School category
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.SchoolCategory}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Class Column -->
                <ng-container matColumnDef="Class">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Class
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.ClassName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Section Column -->
                <ng-container matColumnDef="Section">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Section
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.SectionName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- State Column -->
                <ng-container matColumnDef="State">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>State
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.StateCode}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Division Column -->
                <ng-container matColumnDef="Division">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Division
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.DivisionName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- District Column -->
                <ng-container matColumnDef="District">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>District
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.DistrictName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Block Column -->
                <ng-container matColumnDef="Block">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Block
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.BlockName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Sector Column -->
                <ng-container matColumnDef="Sector">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Sector
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.SectorName}}</p>
                    </mat-cell>
                </ng-container>



                <!-- JobRole Column -->
                <ng-container matColumnDef="JobRole">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Job Role
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.JobRoleName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VocationalTrainer Column -->
                <ng-container matColumnDef="VocationalTrainer">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md> VTP
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.VTPName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VocationalCoordinator Column -->
                <ng-container matColumnDef="VocationalCoordinator">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Vocational Coordinator
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.VCName}}</p>
                    </mat-cell>
                </ng-container>
                <!-- VTP Column -->
                <ng-container matColumnDef="VTP">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Vocational Trainer
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.VTName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Dateofstudent'senrollement Column -->
                <ng-container matColumnDef="Dateofstudentsenrollement">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Date of student's
                        enrollement
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.DateOfEnrollment}}</p>
                    </mat-cell>
                </ng-container>

                <!-- RegistrationNumber Column -->
                <ng-container matColumnDef="RegistrationNumber">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Registration Number
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.RegistrationNo}}</p>
                    </mat-cell>
                </ng-container>

                <!-- AppliedforInternship? Column -->
                <ng-container matColumnDef="AppliedforInternship">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Applied for Internship?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.InternshipApplied}}</p>
                    </mat-cell>
                </ng-container>

                <!-- HiredforInternship? Column -->
                <ng-container matColumnDef="HiredforInternship">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Hired for Internship?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.StudentHired}}</p>
                    </mat-cell>
                </ng-container>

                <!-- EntryCreatedby Column -->
                <ng-container matColumnDef="EntryCreatedby">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Entry Created by
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.CreatedByName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- CreatedOn(Opt_in)  Column -->
                <ng-container matColumnDef="CreatedOn">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Created On (Opt_in)
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.CreatedOn | date: Constants.ShortDateFormat}}</p>
                    </mat-cell>
                </ng-container>

                <!-- IsActive? Column -->
                <ng-container matColumnDef="IsActive">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Is Active?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentReport">
                        <p class="text-truncate">{{studentReport.StudentActive}}</p>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let studentReport; columns: displayedColumns;" class="student-report" matRipple>
                </mat-row>
            </mat-table>

            <!-- <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
               
             [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator> -->
            <mat-paginator #ListPaginator [length]="SearchBy.TotalResults" [pageIndex]="SearchBy.PageIndex"
                [pageSize]="SearchBy.PageSize" [pageSizeOptions]="[5, 10, 25, 100]"
                [showFirstLastButtons]="SearchBy.ShowFirstLastButtons" (page)="onPageIndexChanged($event)">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>