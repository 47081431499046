import { Injectable, OnInit, Component, ViewChild, ElementRef, NgZone } from "@angular/core";
import { CommonService } from "../../services/common.service";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar"
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BaseComponent } from '../base/base.component';
import { MatTableDataSource } from '@angular/material/table';
import { SearchFilterModel } from 'app/models/search.filter.model';
import * as XLSX from 'xlsx';

@Component({
    templateUrl: './base.list.component.html',
    styleUrls: ['./base.list.component.scss']
})
@Injectable()
export class BaseListComponent<T> extends BaseComponent<T> implements OnInit {
    tableDataSource: MatTableDataSource<Element>;
    tableDataSource1: MatTableDataSource<Element>;
    tableDataSourceFinalAssignment: MatTableDataSource<Element>;
    displayedColumns: string[];
    displayedColumns1: string[];
    displayedColumnsFinalInternship: string[];
    SearchBy: SearchFilterModel;
    IsShowFilter: boolean = false;
    @ViewChild(MatPaginator, { static: true })
    ListPaginator: MatPaginator;

    @ViewChild(MatSort, { static: true })
    ListSort: MatSort;

    @ViewChild('filter', { static: true })
    ListFilter: ElementRef;

    constructor(
        public commonService: CommonService,
        public router: Router,
        public routeParams: ActivatedRoute,
        public snackBar: MatSnackBar,
        public zone: NgZone
    ) {
        super(commonService, router, routeParams, snackBar);
        this.tableDataSource = new MatTableDataSource<Element>();
        this.tableDataSource1 = new MatTableDataSource<Element>();
        this.tableDataSourceFinalAssignment = new MatTableDataSource<Element>();

        this.SearchBy = new SearchFilterModel(this.UserModel);

        // Force reload/refresh current route with RouteReuseStrategy
        // Override the route reuse strategy
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        }

        this.router.events.subscribe((evt) => {
            if (evt instanceof NavigationEnd) {
                // trick the Router into believing it's last link wasn't previously loaded
                this.router.navigated = false;
                // if you need to scroll back to top, here is the right place
                window.scrollTo(0, 0);
            }
        });
    }

    ngOnInit() { }

    applySearchFilter(filterValue: string) {
        this.tableDataSource.filter = filterValue.trim().toLowerCase();
        this.zone.run(() => {
            if (this.tableDataSource.filteredData.length == 0) {
                this.showNoDataFoundSnackBar();
            }
        });
        this.tableDataSource1.filter = filterValue.trim().toLowerCase();
        this.zone.run(() => {
            if (this.tableDataSource1.filteredData.length == 0) {
                this.showNoDataFoundSnackBar();
            }
        });

        this.tableDataSourceFinalAssignment.filter = filterValue.trim().toLowerCase();
        this.zone.run(() => {
            if (this.tableDataSourceFinalAssignment.filteredData.length == 0) {
                this.showNoDataFoundSnackBar();
            }
        });
    }
    applySearchFilterStudent(filterValue: string) {
        this.tableDataSource.filter = filterValue.trim().toLowerCase();
        this.zone.run(() => {
            if (this.tableDataSource.filteredData.length == 0) {
                // this.showNoDataFoundSnackBar();
            }
        });
        this.tableDataSource1.filter = filterValue.trim().toLowerCase();
        this.zone.run(() => {
            if (this.tableDataSource1.filteredData.length == 0) {
                // this.showNoDataFoundSnackBar();
            }
        });

        this.tableDataSourceFinalAssignment.filter = filterValue.trim().toLowerCase();
        this.zone.run(() => {
            if (this.tableDataSourceFinalAssignment.filteredData.length == 0) {
                this.showNoDataFoundSnackBar();
            }
        });
    }

    exportExcelFromTable(dataSource: any, dataType: string): void {
        let currentDateTime = this.DateFormatPipe.transform(Date.now(), 'yyyyMMdd-HHmmss');

        //converts a DOM TABLE element to a worksheet
        //const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(dataTable.nativeElement);
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataSource);
        if (dataType === "Diary Entry") {
            let heading = [['Entry Date', 'Intern\'s Name', 'Today\'s Learning', 'Hours Spent', 'Attachments', 'Highlight']];
            XLSX.utils.sheet_add_aoa(ws, heading);
            const range = XLSX.utils.decode_range(ws['!ref']);
            debugger;
            const C = range.e.c;
            for (var R = 1; R <= range.e.r; ++R) {
                let index = 'E' + R;
                const cell = ws[XLSX.utils.encode_cell({ c: C, r: R })];
                if (cell != undefined && cell != null) {
                    const hyperlink: XLSX.Hyperlink = {
                        Target: cell.v,
                        Tooltip: cell.v
                    };
                    var cell_add = { c: C, r: R };
                    if (ws[index] != undefined && ws[index] != null) {
                        ws[index].l = hyperlink;
                    }
                }

            }
        }
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, dataType);
        /* save to file */
        let fileName = dataType + '-' + currentDateTime + '.xlsx';
        XLSX.writeFile(wb, fileName);
    }
}
