import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { RouteConstants } from 'app/constants/route.constant'
import { StudentCertificatesService } from '../student-certificates.service';
import { StudentCertificatesModel } from '../student-certificates.model';
import { DropdownModel } from 'app/models/dropdown.model';
import { FileUploadModel } from 'app/models/file.upload.model';


@Component({
  selector: 'student-certificates',
  templateUrl: './create-student-certificates.component.html',
  styleUrls: ['./create-student-certificates.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CreateStudentCertificatesComponent extends BaseComponent<StudentCertificatesModel> implements OnInit {
  studentCertificatesForm: FormGroup;
  studentCertificatesModel: StudentCertificatesModel;

  schoolsList: [DropdownModel];
  certificateList: [DropdownModel];
  empList: [DropdownModel];

  jobRoleList: [DropdownModel];
  filteredSchoolItems: any;
  filteredEmpItems: any;
  fileUploadModel: FileUploadModel;
  uploadedFileUrl: any;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private route: ActivatedRoute,
    private studentCertificatesService: StudentCertificatesService,
    private dialogService: DialogService,
    private formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Set the default sectorJobRole Model
    this.studentCertificatesModel = new StudentCertificatesModel();
    this.fileUploadModel = new FileUploadModel();

  }

  ngOnInit(): void {

    this.studentCertificatesService.getDropdownforSectorJobRole(this.UserModel).subscribe((results: any) => {
      if (results[0].Success) {
        this.schoolsList = results[0].Results;
        this.filteredSchoolItems = this.schoolsList.slice();
      }
      if (results[1].Success) {
        this.certificateList = results[1].Results;
      }
      // if (this.PageRights.ActionType == this.Constants.Actions.Edit) {
      //   if (results[2].Success) {
      //     this.empList = results[2].Results;
      //     this.filteredEmpItems = this.empList.slice();
      //   }
      // }
      // if (this.PageRights.ActionType == this.Constants.Actions.View) {
      //   if (results[2].Success) {
      //     this.empList = results[2].Results;
      //     this.filteredEmpItems = this.empList.slice();
      //   }
      // }


      this.route.paramMap.subscribe(params => {
        if (params.keys.length > 0) {
          this.PageRights.ActionType = params.get('actionType');

          if (this.PageRights.ActionType == this.Constants.Actions.New) {
            this.studentCertificatesModel = new StudentCertificatesModel();

          } else {
            var CertificateId: string = params.get('CertificateId')

            this.studentCertificatesService.GetCertificateById(CertificateId)
              .subscribe((response: any) => {
                this.studentCertificatesModel = response.Result;

                if (this.PageRights.ActionType == this.Constants.Actions.Edit)
                  this.studentCertificatesModel.RequestType = this.Constants.PageType.Edit;
                else if (this.PageRights.ActionType == this.Constants.Actions.View) {
                  this.studentCertificatesModel.RequestType = this.Constants.PageType.View;
                  this.PageRights.IsReadOnly = true;
                }

                this.studentCertificatesForm = this.createStudentCertificateForm();
                this.onChangeEmp(this.studentCertificatesModel.StudentId);

              });
          }
        }
      });
    });
    this.studentCertificatesForm = this.createStudentCertificateForm();
  }

  saveOrUpdateStudentCertificates() {
    this.studentCertificatesForm.get('SignatureImage').setValue('');

    this.setValueFromFormGroup(this.studentCertificatesForm, this.studentCertificatesModel);

    this.studentCertificatesService.CreateOrUpdateCertificate(this.studentCertificatesModel)
      .subscribe((sectorJobRoleResp: any) => {

        if (sectorJobRoleResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );

            this.router.navigate([RouteConstants.StudentCertificates.List]);
          });
        }
        else {
          var errorMessages = this.getHtmlMessage(sectorJobRoleResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('SectorJobRole deletion errors =>', error);
      });
  }

  onChangeEmp(stuId: any) {
    this.commonService.GetMasterDataByType({ DataType: 'EmployersForStudent', UserId: null, ParentId: stuId, SelectTitle: 'EmployersForStudent' }).subscribe((response: any) => {
      // this.divisionList = response.Results;
      // this.districtList = <DropdownModel[]>[];
      this.empList = response.Results;
      this.filteredEmpItems = this.empList.slice();
    });
  }



  //Create sectorJobRole form and returns {FormGroup}
  createStudentCertificateForm(): FormGroup {
    return this.formBuilder.group({
      EmployerId: new FormControl({ value: this.studentCertificatesModel.EmployerId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      StudentId: new FormControl({ value: this.studentCertificatesModel.StudentId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      OrganizationName: new FormControl({ value: this.studentCertificatesModel.OrganizationName, disabled: this.PageRights.IsReadOnly }, Validators.required,),
      CertificateType: new FormControl({ value: this.studentCertificatesModel.CertificateType, disabled: this.PageRights.IsReadOnly }, Validators.required),
      CertificateTitle: new FormControl({ value: this.studentCertificatesModel.CertificateTitle, disabled: this.PageRights.IsReadOnly }, Validators.required),
      CertificateIssueDate: new FormControl({ value: this.getDateValue(this.studentCertificatesModel.CertificateIssueDate), disabled: this.PageRights.IsReadOnly }, Validators.required),
      CertificateContent: new FormControl({ value: this.studentCertificatesModel.CertificateContent, disabled: this.PageRights.IsReadOnly }, Validators.required),
      CertificateImage: new FormControl({ value: this.studentCertificatesModel.CertificateImage, disabled: this.PageRights.IsReadOnly }),
      SignatureImage: new FormControl({ value: this.studentCertificatesModel.SignatureImage, disabled: this.PageRights.IsReadOnly }),
      IsActive: new FormControl({ value: this.studentCertificatesModel.IsActive, disabled: this.PageRights.IsReadOnly }),
    });
  }


  onChangeStudentName(evt) {

    var eItem = this.empList.find(x => x.Id == this.studentCertificatesForm.get('StudentId').value);
    var oText = (eItem == null ? '' : eItem.Name + '-')
    // this.studentCertificatesForm.get('StudentName').setValue(oText);
  }
  onChangeOrganizationName(evt) {

    var eItem = this.empList.find(x => x.Id == this.studentCertificatesForm.get('EmployerId').value);
    var oText = (eItem == null ? '' : eItem.Name)
    this.studentCertificatesForm.get('OrganizationName').setValue(oText);
  }

  uploadedFile(event) {

    if (event.target.files.length > 0) {
      // var fileExtn = event.target.files[0].name.split('.').pop().toLowerCase();

      // if (this.AllowedExcelExtensions.indexOf(fileExtn) == -1) {
      //   this.dialogService.openShowDialog("Please upload excel file only.");
      //   return;
      // }

      this.getUploadedFileCertifition(event, this.Constants.DocumentType.CertificateImage).then((response: FileUploadModel) => {
        this.fileUploadModel = response;
        this.uploadExcelData();
      });

      // this.isAvailableUploadedExcel = false;
    }
  }

  uploadExcelData(): void {


    // if (this.fileUploadModel.FileName === "") {
    //   this.dialogService.openShowDialog("Please upload excel template data first !!!");
    //   return;
    // }
    var uploadImg = {
      CertificateId: "00000000-0000-0000-0000-000000000000",
      PhotoFile: this.fileUploadModel
    }

    this.studentCertificatesService.UploadSignatureImage(uploadImg).subscribe(response => {
      if (response.Success) {
        this.uploadedFileUrl = response.Result

        this.studentCertificatesForm.get('CertificateImage').setValue(this.uploadedFileUrl);

        this.dialogService.openShowDialog("Upload Signature Image  successfully uploaded ");
      }
      else {
        this.dialogService.openShowDialog("Upload Signature Image  " + " " + response.Errors.pop());
      }
    });
  }
}
