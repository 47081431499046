import { Component, OnInit, NgZone, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmployerListingModel } from './employer-listing.model';
import { EmployerListingService } from './employer-listing.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as guarati } from './i18n/gj';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { DropdownModel } from 'app/models/dropdown.model';
import { DatePipe } from '@angular/common';
import { environment } from 'environments/environment';
@Component({
  selector: 'data-list-view',
  templateUrl: './employer-listing.component.html',
  styleUrls: ['./employer-listing.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class EmployerListingComponent extends BaseListComponent<EmployerListingModel> implements OnInit {
  studentSearchForm: FormGroup;

  currentAcademicYearId: string;
  academicYearList: [DropdownModel];
  sectorList: [DropdownModel];
  vtpList: [DropdownModel];
  filteredVtpSectorItems: any;
  schoolList: [DropdownModel];
  filteredSchoolItems: any;
  ApiBaseUrlImage = this.Constants.CommonImageUrl.Account;

  // schoolVTPSectorSearchForm: FormGroup;
  studentFilterForm: FormGroup;
  fromDate: any;
  toDate: any;
  today = new Date();
  aList = [{ Id: true, Name: 'Active' }, { Id: false, Name: 'Inactive' }]

  durationList = [{ Id: 1, Name: 'Today' }, { Id: 2, Name: 'Yesterday' }, { Id: 3, Name: 'Last 7 days' }, { Id: 4, Name: 'This Month' }, { Id: 5, Name: 'Last Month' }]
  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private translationLoaderService: FuseTranslationLoaderService,
    public zone: NgZone,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private datePipe: DatePipe,
    private employerListsService: EmployerListingService) {
    super(commonService, router, routeParams, snackBar, zone);
    this.translationLoaderService.loadTranslations(english, guarati);
    this.studentSearchForm = this.formBuilder.group({ filterText: '' });
    this.studentFilterForm = this.createSchoolVtpSectorFilterForm();

  }

  ngOnInit(): void {
    this.employerListsService.getDropdownforSchool(this.UserModel).subscribe(results => {

      if (results[2].Success) {
        this.sectorList = results[2].Results;
      }
    });
    // this.onLoadSchoolsByCriteria();



    this.studentSearchForm = this.formBuilder.group({ filterText: '' });

    // Load initial schools data
    this.onLoadSchoolsByCriteria();

    this.studentSearchForm.get('filterText').valueChanges.pipe(
      // if character length greater then 2
      filter(res => {

        if (res.length == 0) {
          this.SearchBy.Name = '';
          this.onLoadSchoolsByCriteria();
          return false;
        }

        return res.length > 2
      }),

      // Time in milliseconds between key events
      debounceTime(650),

      // If previous query is diffent from current   
      distinctUntilChanged()

      // subscription for response
    ).subscribe((searchText: string) => {
      this.SearchBy.Name = searchText;
      this.onLoadSchoolsByCriteria();
    });
  }

  ngAfterViewInit() {
    this.tableDataSource.paginator = this.ListPaginator;
  }

  onPageIndexChanged(evt) {
    this.SearchBy.PageIndex = evt.pageIndex;
    this.SearchBy.PageSize = evt.pageSize;

    this.onLoadSchoolsByCriteria();
  }

  onLoadSchoolsByCriteria(): any {
    this.IsLoading = true;
    this.SearchBy.VTId = this.UserModel.UserTypeId;
    this.SearchBy.VTGuid = this.UserModel.UserTypeId;
    this.SearchBy.UserTypeId = null;
    this.SearchBy.RoleCode = this.UserModel.RoleCode;

    // this.SearchBy.SchoolGuid = (this.Constants.GlobalSchoolId == 'undefined' || this.Constants.GlobalSchoolId == 'null') ? null : this.Constants.GlobalSchoolId;
    // this.SearchBy.AcademicYearGuid = (this.Constants.GlobalAcademicYearId == 'undefined' || this.Constants.GlobalAcademicYearId == 'null') ? null : this.Constants.GlobalAcademicYearId;

    this.SearchBy.SchoolGuid = (this.Constants.GlobalSchoolId == 'undefined' || this.Constants.GlobalSchoolId == 'null') ? null : this.Constants.GlobalSchoolId;
    this.SearchBy.AcademicYearGuid = (this.Constants.GlobalAcademicYearId == 'undefined' || this.Constants.GlobalAcademicYearId == 'null') ? null : this.Constants.GlobalAcademicYearId;
    this.SearchBy.SectorGuid = this.studentFilterForm.controls['SectorId'].value;
    this.SearchBy.IsActive = this.studentFilterForm.controls['StatusId'].value;
    this.SearchBy.VTId = this.UserModel.UserTypeId;
    // this.SearchBy.SectorGuid = this.studentFilterForm.controls['SectorId'].value;
    this.SearchBy.StartDateTime = this.fromDate == 'undefined' ? null : this.fromDate;//"2020-01-08";
    this.SearchBy.EndDatetime = this.toDate == 'undefined' ? null : this.toDate;//"2022-08-08";
    this.employerListsService.GetEmployersByCriteria(this.SearchBy).subscribe(response => {
      this.displayedColumns = ['OrganizationName', 'EmployerName', 'BranchAddress', 'SectorName', 'POCName', 'POCMobile', 'POCEmail', 'District', 'PinCode', 'Actions'];
      this.tableDataSource.data = response.Results;
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;
      this.SearchBy.TotalResults = response.TotalResults;
      setTimeout(() => {
        this.ListPaginator.pageIndex = this.SearchBy.PageIndex;
        this.ListPaginator.length = this.SearchBy.TotalResults;
      });
      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }



  onDeleteEmployersById(StudentId: string) {
    this.dialogService
      .openConfirmDialog(this.Constants.Messages.DeleteConfirmationMessage)
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          this.employerListsService.DeleteEmployersById(StudentId)
            .subscribe((schoolResp: any) => {

              this.zone.run(() => {
                if (schoolResp.Success) {
                  this.showActionMessage(
                    this.Constants.Messages.RecordDeletedMessage,
                    this.Constants.Html.SuccessSnackbar
                  );
                }
                this.ngOnInit();
              }, error => {
                console.log('School deletion errors =>', error);
              });

            });
          this.ngOnInit();
        }
      });
  }

  exportExcel(): void {
    this.IsLoading = true;
    this.SearchBy.PageIndex = 0;
    this.SearchBy.PageSize = 10000;

    this.employerListsService.GetEmployersByCriteria(this.SearchBy).subscribe(response => {
      let employerList: any;
      employerList = response.Results;


      let arr = [] as any;

      for (let i = 0; i < employerList.length; i++) {
        let xxx = {
          ['Organization Name']: employerList[i].OrganizationName,
          // EmployerId: employerList[i].EmployerId,
          ['Employer Name']: employerList[i].EmployerName,
          // EmployerFirstName: employerList[i].EmployerFirstName,
          // EmployerLastName: employerList[i].EmployerLastName,
          Gender: employerList[i].EmployerGender,
          ['Employer Status']: employerList[i].IsActive == true ? 'Active' : 'InActive',
          ['Business Description']: employerList[i].BusinessDesc,
          ['No Of Employees']: employerList[i].NoOfEmployees,
          // EmpPhotoUrl: employerList[i].EmpPhotoUrl,
          ['Year of Foundation']: employerList[i].DateOfFoundation.split('/')[0],
          // EmployerBranchId: employerList[i].EmployerBranchId,
          ['Branch Name']: employerList[i].BranchName,
          ['Branch Address']: employerList[i].BranchAddress,
          ['POC Name']: employerList[i].POCName,
          ['POC Mobile']: employerList[i].POCMobile,
          ['POC EmailId']: employerList[i].POCEmailId,
          // ['District Code']: employerList[i].DistrictCode,
          ['District Name']: employerList[i].DistrictName,
          ['State Code']: employerList[i].StateCode,
          ['Pincode']: employerList[i].Pincode,
          ['Sector Name']: employerList[i].SectorName,
          IsActive: employerList[i].IsActive ? 'Yes' : 'No',
          ['Created Date']: employerList[i].CreatedDate,
          index: i
        }
        arr.push(xxx);
      }
      arr = arr.sort((a, b) => a.index - b.index);
      arr.forEach(
        function (obj) {
          // if (obj.hasOwnProperty('IsActive')) {
          //   obj.IsActive = obj.IsActive ? 'Yes' : 'No';
          // }
          // if (obj.hasOwnProperty('IsImplemented')) {
          //   obj.IsImplemented = obj.IsImplemented ? 'Yes' : 'No';
          // }
          // if (obj.hasOwnProperty('EmployerStatus')) {
          //   obj.BusinessType = obj.BusinessType ? 'Active' : 'Inactive';
          // }
          delete obj.index;
          // delete obj.CreatedDate;
          // delete obj.EmployerBranchId;
          // delete obj.TotalRows;
        });

      this.exportExcelFromTable(arr, "Employer");

      this.IsLoading = false;
      this.SearchBy.PageIndex = 0;
      this.SearchBy.PageSize = 10;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  onLoadSchoolVTPSectorsByFilters(): any {
    this.onLoadSchoolsByCriteria();
  }

  resetFilters(): void {
    this.studentFilterForm.reset();
    this.studentFilterForm.get('AcademicYearId').setValue(this.currentAcademicYearId);
    this.SearchBy.SectorGuid = ''
    this.onLoadSchoolsByCriteria();
  }
  //Create SchoolVtpSectorFilter form and returns {FormGroup}

  onChangeDuration(evt) {

    let duration = evt;
    switch (duration) {

      case 1:

        let todaysDate = this.datePipe.transform(this.today, 'yyyy-MM-dd');
        this.fromDate = todaysDate;
        this.toDate = todaysDate;
        console.log("Today", this.fromDate, this.toDate);
        break;
      case 2:
        var tdate = new Date();
        tdate.setDate(tdate.getDate() - 1);
        let yesterdaysDate = this.datePipe.transform(tdate, 'yyyy-MM-dd');
        this.fromDate = yesterdaysDate;
        this.toDate = yesterdaysDate;
        console.log("Yesterday", this.fromDate, this.toDate);
        break;
      case 3:
        let wdate: Date = new Date();
        wdate.setDate(wdate.getDate() - 6);
        // date.setHours(0);
        // date.setMinutes(0);
        // date.setSeconds(0);
        // date.setMilliseconds(0);
        // let datePipe: DatePipe = new DatePipe('en-US');
        // console.log(datePipe.transform(date, 'short'));
        this.fromDate = this.datePipe.transform(wdate, 'yyyy-MM-dd');
        this.toDate = this.datePipe.transform(this.today, 'yyyy-MM-dd');
        console.log("Last 7 days", this.fromDate, this.toDate);
        break;
      case 4:
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.fromDate = this.datePipe.transform(firstDay, 'yyyy-MM-dd');
        this.toDate = this.datePipe.transform(lastDay, 'yyyy-MM-dd');
        console.log("This Month", this.fromDate, this.toDate);
        break;
      case 5:
        const cdate = new Date();

        const firstDayPrevMonth = new Date(cdate.getFullYear(), cdate.getMonth() - 1, 1);
        const lastDayPrevMonth = new Date(cdate.getFullYear(), cdate.getMonth(), 0);
        this.fromDate = this.datePipe.transform(firstDayPrevMonth, 'yyyy-MM-dd');
        this.toDate = this.datePipe.transform(lastDayPrevMonth, 'yyyy-MM-dd');
        console.log("Last Month", this.fromDate, this.toDate);
        break;
      default:
        console.log("Invalid");
    }

  }
  //Create SchoolVtpSectorFilter form and returns {FormGroup}

  createSchoolVtpSectorFilterForm(): FormGroup {
    return this.formBuilder.group({
      SectorId: new FormControl(),
      DurationId: new FormControl(),
      StatusId: new FormControl(),

    });
  }

}
