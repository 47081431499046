<!-- HEADER -->
<div class="header1" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="space-between center" style="background: #3f51b5;height: 64px; height: 61px;
  padding: 0px 24px;
">
    <!-- APP TITLE -->
    <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center"
        [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
        <div class="h2 mt-8 mb-12  heading-text1" style="color:#fff;">
            User Confirmation
        </div>
    </div>
    <!-- / APP TITLE -->
    <!-- ADD BUTTON -->

    <button mat-flat-button [color]="'warn'" (click)="onClose()" class="mat-elevation-z1" style="background-color: #f44336 !important;
  color: #fff !important;">
        <mat-icon>clear</mat-icon>
    </button>
    <!-- / ADD BUTTON -->
</div>
<!-- / HEADER -->
<div id="staff" class="page-layout carded fullwidth inner-scroll">
    <!-- TOP BACKGROUND -->
    <!-- <div class="top-bg accent"></div> -->
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">
        <!-- CONTENT CARD -->
        <div class="content-card p-5 mb-12">
            <div class="content">
                <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start">
                    <div class='column' fxFlex="100" style="font-size: 15px; margin:1% 0%; line-height:inherit;">
                        <span>{{data.Title }}</span>
                        <!-- <mat-divider></mat-divider> -->
                        <span style="padding-left: 2%;">{{data.message }}</span>
                    </div>
                    <div class="button-row">
                        <button mat-raised-button color="primary" (click)="submitConfirmation(true)" type="button"
                            style="min-width: 50px; padding: 0px 25px;">
                            Submit
                        </button>
                        <button mat-raised-button color="warn" (click)="onClose()" type="button"
                            style="min-width: 50px; padding: 0px 25px;">
                            Cancel
                        </button>
                    </div>
                </div>

            </div>
            <!-- / CONTENT CARD -->
        </div>
        <!-- / CENTER -->
    </div>
</div>