import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { SchoolSectorjobroleClasssectionMappingService } from './school-sectorjobrole-classsection-mapping.service';
import { SchoolSectorjobroleClasssectionMappingModel } from './school-sectorjobrole-classsection-mapping.model';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DropdownModel } from 'app/models/dropdown.model';

@Component({
  selector: 'data-list-view',
  templateUrl: './school-sectorjobrole-classsection-mapping.component.html',
  styleUrls: ['./school-sectorjobrole-classsection-mapping.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class SchoolSectorjobroleClasssectionMappingComponent extends BaseListComponent<SchoolSectorjobroleClasssectionMappingModel> implements OnInit {
  schoolSjrClssctVtMappingFilterForm: FormGroup;
  roleList: [DropdownModel];
  schoolSjrClssctVtMapping: Element[];

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    public zone: NgZone,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private schoolSectorjobroleClasssectionMappingService: SchoolSectorjobroleClasssectionMappingService) {
    super(commonService, router, routeParams, snackBar, zone);
    this.schoolSjrClssctVtMappingFilterForm = this.createschoolSjrClssctVtMappingFilterForm();
  }

  ngOnInit(): void {

    this.onLoadSchoolSectorjobroleClasssection();

  }

  onLoadSchoolSectorjobroleClasssection(): any {
    this.SearchBy.IsActive = this.schoolSjrClssctVtMappingFilterForm.controls["Status"].value =='true'? true:this.schoolSjrClssctVtMappingFilterForm.controls["Status"].value=='false'?false:this.schoolSjrClssctVtMappingFilterForm.controls["Status"].value;

    this.schoolSectorjobroleClasssectionMappingService.GetAllByCriteria(this.SearchBy).subscribe(response => {
      //this.displayedColumns = ['AcademicYearName', 'SchoolSectorJobRoleName', 'ClassName', 'SectionName','UDISENo', 'VTName','VTPName','VCName', 'DateOfAllocation', 'DateOfDeAllocation', 'Remarks', 'IsActive', 'Actions'];
      this.displayedColumns = ['AcademicYearName', 'SchoolSectorJobRoleName', 'ClassName', 'SectionName', 'UDISENo', 'VTName', 'VTPName', 'VCName', 'IsActive', 'Actions'];
      console.log(response.Results)
      this.schoolSjrClssctVtMapping = response.Results;
      this.tableDataSource.data = response.Results;
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;

      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
    });
  }

  onDeleteSchoolVTMapping(schoolSecJobClassSecVTId: string) {
    this.dialogService
      .openConfirmDialog(this.Constants.Messages.DeleteConfirmationMessage)
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          this.schoolSectorjobroleClasssectionMappingService.deleteSchoolSecJobClassSecVTById(schoolSecJobClassSecVTId)
            .subscribe((schoolVTMappingResp: any) => {
              this.zone.run(() => {
                if (schoolVTMappingResp.Success == true) {
                  this.showActionMessage(
                    this.Constants.Messages.RecordDeletedMessage,
                    this.Constants.Html.SuccessSnackbar
                  );
                } else {
                  this.showActionMessage(
                    schoolVTMappingResp.Result,
                    this.Constants.Html.SuccessSnackbar
                  );
                  this.ngOnInit();

                }
                this.ngOnInit();
              }, error => {
                console.log('schoolSectorjobroleClasssectionMappingService deletion errors =>', error);
              });

            });
          this.ngOnInit();
        }
      });
  }

  onLoadSchoolSjrClssctMappingByFilters(): any {
    this.SearchBy.PageIndex = 0;
    this.onLoadSchoolSectorjobroleClasssection();
  }

  resetFilters(): void {
    this.SearchBy.PageIndex = 0;
    this.schoolSjrClssctVtMappingFilterForm.reset();
    //this.schoolFilterForm.get('AcademicYearId').setValue(this.AcademicYearId);

    this.onLoadSchoolSectorjobroleClasssection();
  }

  exportExcel(): void {
    this.IsLoading = true;
    this.SearchBy.PageIndex = 0;
    this.SearchBy.PageSize = 10000;

    this.schoolSectorjobroleClasssectionMappingService.GetAllByCriteria(this.SearchBy).subscribe(response => {
      response.Results.forEach(
        function (obj) {
          if (obj.hasOwnProperty('IsActive')) {
            obj.IsActive = obj.IsActive ? 'Yes' : 'No';
          }
          if (obj.hasOwnProperty('IsImplemented')) {
            obj.IsImplemented = obj.IsImplemented ? 'Yes' : 'No';
          }
          delete obj.SchoolId;
          delete obj.TotalRows;
        });

      this.exportExcelFromTable(response.Results, "SchoolSectorJobRoleClassSection");

      this.IsLoading = false;
      this.SearchBy.PageIndex = 0;
      this.SearchBy.PageSize = 10;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  //Create SchoolSectorJobRole form and returns {FormGroup}
  createschoolSjrClssctVtMappingFilterForm(): FormGroup {
    return this.formBuilder.group({
      // AcademicYearId: new FormControl(),
      Status: new FormControl()
    });
  }


}
