<div id="student-report" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    Employers Report
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>                   
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter (keyup)="applySearchFilter($event.target.value)"
                        placeholder="Search for a Employer Report">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">
                <a *ngIf="PageRights.IsExport" class="export-excel"
                    (click)="exporter.exportTable('xlsx', {fileName:'EmployerReport', sheet: 'EmployerReport', Props: {Author: 'Lighthouse'}})">
                    <mat-icon> cloud_download</mat-icon>
                </a>
            </div>
            <!-- / ADD BUTTON -->
        </div>
        <!-- / HEADER -->

        <div fxLayout="row" fxLayoutAlign="start center">
            <button mat-raised-button class="fuse-white filter-button" style="margin-bottom: 10px;"
                (click)="isShowFilterContainer=!isShowFilterContainer"><span>FILTERS</span></button>
        </div>

        <!-- Filter Section -->
        <div class="filter-content-card" *ngIf="isShowFilterContainer">
            <div class="filter-content">
                <form name="employerReportForm" [formGroup]="employerReportForm" class="vt-school-sector"
                    fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Employer Name</mat-label>

                            <mat-select formControlName="EmployerGuid" name="employerGuid" appearance="fill">
                                <mat-option [value]="Item.Id"
                                    *ngFor="let Item of empList">
                                    {{ Item.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                       

                        <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="filter-disabled">
                            <mat-label>State</mat-label>

                            <mat-select formControlName="StateCode" name="stateCode" appearance="fill"
                            (selectionChange)="onChangeStataByDistrict($event.value)">
                                <mat-option [value]="Item.Id" *ngFor="let Item of stateList">
                                    {{ Item.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="filter-disabled">
                            <mat-label>District</mat-label>
                            <mat-select formControlName="DistrictCode" name="districtCode" appearance="fill" >
                                <mat-option *ngFor="let districtItem of districtList" [value]="districtItem.Id">
                                    {{districtItem.Name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Sector</mat-label>

                            <mat-select formControlName="SectorGuid" name="sectorGuid" appearance="fill"
                                >
                                <mat-option [value]="sectorItem.Id" *ngFor="let sectorItem of sectorList">
                                    {{ sectorItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        



                        <div class="clear-filter pr-1" fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="getEmployerReports()"><span>SEARCH</span></button>
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="resetReportFilters()"><span>CLEAR</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- /Filter Section -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table class="vt-school-sector-report-table responsive-table" #table [dataSource]="tableDataSource"
                matTableExporter #exporter="matTableExporter" [hiddenColumns]="[0]" matSort fusePerfectScrollbar
                [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- SrNo Column -->
                <ng-container matColumnDef="SrNo">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Sr No</mat-header-cell>
                    <mat-cell *matCellDef="let employerReport; let RowIndex = index;">
                        <p class="text-truncate">{{RowIndex+1}}</p>
                    </mat-cell>
                </ng-container>

                <!-- AcademicYear Column -->
                <ng-container matColumnDef="AcademicYear" >
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Academic Year
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employerReport" >
                        <p class="text-truncate">{{employerReport.AcademicYear}}</p>
                    </mat-cell>
                </ng-container>

                <!-- EstablishmentName Column -->
                <ng-container matColumnDef="EstablishmentName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Establishment Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employerReport">
                        <p class="text-truncate">{{employerReport.EstablishmentName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- YearOfEstablishment Column -->
                <ng-container matColumnDef="YearOfEstablishment">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Year Of Establishment
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employerReport">
                        <p class="text-truncate">{{employerReport.EstablishmentYear}}</p>
                    </mat-cell>
                </ng-container>

                <!-- EmployerName Column -->
                <ng-container matColumnDef="EmployerName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Employer Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employerReport">
                        <p class="text-truncate">{{employerReport.EmployerFullName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Gender Column -->
                <ng-container matColumnDef="Gender">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Gender
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employerReport">
                        <p class="text-truncate">{{employerReport.Gender}}</p>
                    </mat-cell>
                </ng-container>

                <!-- EmployerAddress Column -->
                <ng-container matColumnDef="EmployerAddress">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Employer Address
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employerReport">
                        <p class="text-truncate">{{employerReport.BranchAddress}}</p>
                    </mat-cell>
                </ng-container>

                <!-- EmailId Column -->
                <ng-container matColumnDef="EmailId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>EmailId
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employerReport">
                        <p class="text-truncate">{{employerReport.EmployerEmialId}}</p>
                    </mat-cell>
                </ng-container>

                <!-- State Column -->
                <ng-container matColumnDef="State">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>State
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employerReport">
                        <p class="text-truncate">{{employerReport.StateName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- District Column -->
                <ng-container matColumnDef="District">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>District
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employerReport">
                        <p class="text-truncate">{{employerReport.DistrictName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Pincode Column -->
                <ng-container matColumnDef="Pincode">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Pincode
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employerReport">
                        <p class="text-truncate">{{employerReport.Pincode}}</p>
                    </mat-cell>
                </ng-container>

                <!-- PhoneNo Column -->
                <ng-container matColumnDef="PhoneNo">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Phone No
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employerReport">
                        <p class="text-truncate">{{employerReport.EmployerMobile}}</p>
                    </mat-cell>
                </ng-container>

                <!-- EmployerWhatsApp Column -->
                <ng-container matColumnDef="EmployerWhatsApp">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Employer WhatsApp
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employerReport">
                        <p class="text-truncate">{{employerReport.VTEmail}}</p>
                    </mat-cell>
                </ng-container>

                <!-- EmployerEmialId Column -->
                <ng-container matColumnDef="EmployerEmialId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Email
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employerReport">
                        <p class="text-truncate">{{employerReport.EmployerEmialId}}</p>
                    </mat-cell>
                </ng-container>

                <!-- EmployerCount Column -->
                <ng-container matColumnDef="EmployerCount">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Employer Count
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employerReport">
                        <p class="text-truncate">{{employerReport.EmployeeCount}}</p>
                    </mat-cell>
                </ng-container>
                <!-- Branch Column -->
                <ng-container matColumnDef="Branch">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Branch
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employerReport">
                        <p class="text-truncate">{{employerReport.BranchName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- TotalInternshipStart-G Column -->
                <ng-container matColumnDef="TotalInternshipStart-G">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Total Internship Start-G
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employerReport">
                        <p class="text-truncate">{{employerReport.InternshipYetTostartGirl}}</p>
                    </mat-cell>
                </ng-container>

                <!-- TotalInternshipStart-B Column -->
                <ng-container matColumnDef="TotalInternshipStart-B">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Total Internship Start-B
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employerReport">
                        <p class="text-truncate">{{employerReport.InternshipYetTostartBoy}}</p>
                    </mat-cell>
                </ng-container>

                <!-- TotalInternshipSProg-G Column -->
                <ng-container matColumnDef="TotalInternshipSProg-G">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Total InternshipS Prog-G
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employerReport">
                        <p class="text-truncate">{{employerReport.InternshipProgressGirls}}</p>
                    </mat-cell>
                </ng-container>

                <!-- TotalInternshipSProg-B Column -->
                <ng-container matColumnDef="TotalInternshipSProg-B">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>TotalInternshipSProg-B
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employerReport">
                        <p class="text-truncate">{{employerReport.InternshipProgressBoys}}</p>
                    </mat-cell>
                </ng-container>
                <!-- IntCompleted-G Column -->
                <ng-container matColumnDef="IntCompleted-G">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Int Completed G
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employerReport">
                        <p class="text-truncate">{{employerReport.InternshipCompletedGirls}}</p>
                    </mat-cell>
                </ng-container>

                <!-- IntCompleted-B Column -->
                <ng-container matColumnDef="IntCompleted-B">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Int Completed B
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employerReport">
                        <p class="text-truncate">{{employerReport.InternshipCompletedBoys}}</p>
                    </mat-cell>
                </ng-container>

                <!-- TotalIntDrop-G Column -->
                <!-- <ng-container matColumnDef="TotalIntDrop-G">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Total Int Drop G
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employerReport">
                        <p class="text-truncate">{{employerReport.VTEmail}}</p>
                    </mat-cell>
                </ng-container> -->

                <!-- TotalIntDrop-B Column -->
                <!-- <ng-container matColumnDef="TotalIntDrop-B">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Total Int Drop-B
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employerReport">
                        <p class="text-truncate">{{employerReport.VTEmail}}</p>
                    </mat-cell>
                </ng-container> -->



              

                <!-- AvgHrsGirls Column -->
                <ng-container matColumnDef="AvgHrsGirls">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>AVGHrs-G
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employerReport">
                        <p class="text-truncate">{{employerReport.AvgHrsGirls}}</p>
                    </mat-cell>
                </ng-container>

                 <!-- AvgHrsBoysColumn -->
                 <ng-container matColumnDef="AvgHrsBoys">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>AVGHrs-B
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employerReport">
                        <p class="text-truncate">{{employerReport.AvgHrsBoys}}</p>
                    </mat-cell>
                </ng-container>

                <!-- MaxSimulInterns Column -->
                <!-- <ng-container matColumnDef="MaxSimulInterns">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>MaxSimulInterns
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employerReport">
                        <p class="text-truncate">{{employerReport.VTEmail}}</p>
                    </mat-cell>
                </ng-container> -->

                <!-- MinSimulInterns Column -->
                <!-- <ng-container matColumnDef="MinSimulInterns">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>MinSimulInterns
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employerReport">
                        <p class="text-truncate">{{employerReport.VTEmail}}</p>
                    </mat-cell>
                </ng-container> -->

                <!-- SaftyConcernRaised Column -->
                <ng-container matColumnDef="SaftyConcernRaised">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Safty Concern Raised
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employerReport">
                        <p class="text-truncate">{{employerReport.SaftyConcernRaised}}</p>
                    </mat-cell>
                </ng-container>

                <!-- TotalApplications Column -->
                <ng-container matColumnDef="TotalApplications">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Total Applications
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employerReport">
                        <p class="text-truncate">{{employerReport.TotalApplications}}</p>
                    </mat-cell>
                </ng-container>

                 <!-- CreatedDate Column -->
                 <ng-container matColumnDef="CreatedDate" fxHide.lt-md fxHide.lt-sm fxHide.gt-md>
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide.lt-sm fxHide.gt-md >Created Date
                    </mat-header-cell>
                    <mat-cell *matCellDef="let employerReport" fxHide.lt-md fxHide.lt-sm fxHide.gt-md>
                        <p class="text-truncate">{{employerReport.CreatedDate}}</p>
                    </mat-cell>
                </ng-container>

               


                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let employerReport; columns: displayedColumns;" class="student-report" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>
            <!-- <mat-paginator #ListPaginator [length]="SearchBy.TotalResults" [pageIndex]="SearchBy.PageIndex"
            [pageSize]="SearchBy.PageSize" [pageSizeOptions]="SearchBy.PageSizeOptions"
            [showFirstLastButtons]="SearchBy.ShowFirstLastButtons" (page)="onPageIndexChanged($event)">
        </mat-paginator> -->

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>