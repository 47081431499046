import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { RouteConstants } from 'app/constants/route.constant'
import { EmployerCertificatesService } from '../employer-certificates.service';
import { EmployerCertificatesModel } from '../employer-certificates.model';
import { DropdownModel } from 'app/models/dropdown.model';
import { FileUploadModel } from 'app/models/file.upload.model';

@Component({
  selector: 'employer-certificates',
  templateUrl: './create-employer-certificates.component.html',
  styleUrls: ['./create-employer-certificates.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CreateEmployerCertificatesComponent extends BaseComponent<EmployerCertificatesModel> implements OnInit {
  employerCertificatesForm: FormGroup;
  employerCertificatesModel: EmployerCertificatesModel;
  schoolsList: [DropdownModel];
  certificateList: [DropdownModel];
  empList: [DropdownModel];

  jobRoleList: [DropdownModel];
  filteredSchoolItems: any;
  filteredEmpItems: any;
  fileUploadModel: FileUploadModel;
  uploadedFileUrl: any;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private route: ActivatedRoute,
    private employerCertificatesService: EmployerCertificatesService,
    private dialogService: DialogService,
    private formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Set the default sectorJobRole Model
    this.employerCertificatesModel = new EmployerCertificatesModel();
    this.fileUploadModel = new FileUploadModel();
  }

  ngOnInit(): void {

    this.employerCertificatesService.getDropdownforHeadMaster(this.UserModel).subscribe((results) => {
      if (results[0].Success) {
        this.schoolsList = results[0].Results;
        this.filteredSchoolItems = this.schoolsList.slice();
      }
      if (results[1].Success) {
        this.certificateList = results[1].Results;
      }
      if (results[2].Success) {
           this.empList = results[2].Results;
        this.filteredEmpItems = this.empList.slice();
      }
      this.route.paramMap.subscribe(params => {
        if (params.keys.length > 0) {
          this.PageRights.ActionType = params.get('actionType');

          if (this.PageRights.ActionType == this.Constants.Actions.New) {
            this.employerCertificatesModel = new EmployerCertificatesModel();

          } else {
            var CertificateId: string = params.get('CertificateId')

            this.employerCertificatesService.GetCertificateById(CertificateId)
              .subscribe((response: any) => {
                this.employerCertificatesModel = response.Result;

                if (this.PageRights.ActionType == this.Constants.Actions.Edit)
                  this.employerCertificatesModel.RequestType = this.Constants.PageType.Edit;
                else if (this.PageRights.ActionType == this.Constants.Actions.View) {
                  this.employerCertificatesModel.RequestType = this.Constants.PageType.View;
                  this.PageRights.IsReadOnly = true;
                }

                this.employerCertificatesForm = this.createEmployerCertificateForm();
              });
          }
        }
      });
    });
    this.employerCertificatesForm = this.createEmployerCertificateForm();
  }

  saveOrUpdateEmployerCertificates() {
    this.employerCertificatesForm.get('CertificateImage').setValue('');
    // this.employerCertificatesForm.SignatureImage=this.uploadedFileUrl
    this.setValueFromFormGroup(this.employerCertificatesForm, this.employerCertificatesModel);
    // let employerParam = {
    //   // EmployersBranchesId: this.UserModel.EmpBranchID,
    //   // POCName: this.empProfileDetailsForm.controls['POCName'].value,
    //   // POCMobile: this.empProfileDetailsForm.controls['POCMobile'].value,
    //   // BranchAddress: this.empProfileDetailsForm.controls['BranchAddress'].value,
    //   // LoginId: this.empProfileDetails.POCEmailId,
    //   // DistrictCode: this.empProfileDetails.DistrictCode,
    //   // DistrictName: this.empProfileDetails.DistrictName,
    //   // Pincode: this.empProfileDetails.Pincode,                              
    //   // StateCode: this.empProfileDetails.StateCode,
    //   // //PhotoFileURL: (this.FileName == undefined ? this.PhotoUrl : this.FileName)
    //   // PhotoFileURL: companyLogoImgResp.Result
    //   EmployerId: this.employerCertificatesForm.value.EmployerId,
    //   CertificateType: this.employerCertificatesModel.CertificateType,
    //   CertificateIssueDate: this.employerCertificatesForm.value.CertificateIssueDate,
    //   OrganizationName: this.employerCertificatesForm.value.OrganizationName,
    //   CertificateContent: this.employerCertificatesForm.value.CertificateContent,
    //   IsActive: this.employerCertificatesModel.IsActive,
    //   RequestType: this.employerCertificatesModel.RequestType,
    //   CertificateId: this.employerCertificatesModel.CertificateId,
    //   StudentId : this.employerCertificatesModel.StudentId,
    //   TargetAudienceType: this.employerCertificatesModel.TargetAudienceType,
    //   CertificateTitle: this.employerCertificatesForm.value.CertificateTitle,
    //   CertificateImage:this.employerCertificatesModel.TargetAudienceType,
    //   SignatureImage:this.uploadedFileUrl,
    // }
    // console.log(employerParam)
    this.employerCertificatesService.CreateOrUpdateCertificate(this.employerCertificatesModel)
      .subscribe((sectorJobRoleResp: any) => {

        if (sectorJobRoleResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );

            this.router.navigate([RouteConstants.EmployerCertificates.List]);
          });
        }
        else {
          var errorMessages = this.getHtmlMessage(sectorJobRoleResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('SectorJobRole deletion errors =>', error);
      });
  }





  //Create sectorJobRole form and returns {FormGroup}
  createEmployerCertificateForm(): FormGroup {
    return this.formBuilder.group({
      EmployerId: new FormControl({ value: this.employerCertificatesModel.EmployerId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      OrganizationName: new FormControl({ value: this.employerCertificatesModel.OrganizationName, disabled: this.PageRights.IsReadOnly }, Validators.required, ),
      CertificateType: new FormControl({ value: this.employerCertificatesModel.CertificateType, disabled: this.PageRights.IsReadOnly }, Validators.required),
      CertificateTitle: new FormControl({ value: this.employerCertificatesModel.CertificateTitle, disabled: this.PageRights.IsReadOnly }, Validators.required),

      CertificateIssueDate: new FormControl({ value: this.getDateValue(this.employerCertificatesModel.CertificateIssueDate), disabled: this.PageRights.IsReadOnly }, Validators.required),
      CertificateContent: new FormControl({ value: this.employerCertificatesModel.CertificateContent, disabled: this.PageRights.IsReadOnly },  Validators.required),
      SignatureImage: new FormControl({ value: this.employerCertificatesModel.SignatureImage, disabled: this.PageRights.IsReadOnly }),
      CertificateImage: new FormControl({ value: this.employerCertificatesModel.CertificateImage, disabled: this.PageRights.IsReadOnly }),
      IsActive: new FormControl({ value: this.employerCertificatesModel.IsActive, disabled: this.PageRights.IsReadOnly }),
    });
  }

  onChangeOrganizationName(evt) {

    var eItem = this.empList.find(x => x.Id == this.employerCertificatesForm.get('EmployerId').value);
    var oText = (eItem == null ? '' : eItem.Name ) 
    this.employerCertificatesForm.get('OrganizationName').setValue(oText);
  }

  uploadedFile(event) {

    if (event.target.files.length > 0) {
      // var fileExtn = event.target.files[0].name.split('.').pop().toLowerCase();

      // if (this.AllowedExcelExtensions.indexOf(fileExtn) == -1) {
      //   this.dialogService.openShowDialog("Please upload excel file only.");
      //   return;
      // }

      this.getUploadedFileCertifition(event, this.Constants.DocumentType.CertificateSignatureImage).then((response: FileUploadModel) => {
        this.fileUploadModel = response;
        this.uploadExcelData();
      });

      // this.isAvailableUploadedExcel = false;
    }
  }

  uploadExcelData(): void {


    // if (this.fileUploadModel.FileName === "") {
    //   this.dialogService.openShowDialog("Please upload excel template data first !!!");
    //   return;
    // }

     var uploadImg={
      CertificateId: "00000000-0000-0000-0000-000000000000",
      PhotoFile:this.fileUploadModel
     }
    this.employerCertificatesService.UploadSignatureImage(uploadImg).subscribe(response => {
      if (response.Success) {
        this.uploadedFileUrl = response.Result
        console.log( this.uploadedFileUrl)
        this.employerCertificatesForm.get('SignatureImage').setValue(this.uploadedFileUrl);

        this.dialogService.openShowDialog("Upload Signature Image  successfully uploaded "  );
      }
      else {
        this.dialogService.openShowDialog("Upload Signature Image  "  + " " + response.Errors.pop());
      }
    });
  }
}
