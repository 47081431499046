<!-- HEADER -->
<div class="header1" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="space-between center" style="background: #3f51b5;height: 64px; height: 61px;
  padding: 0px 24px;
">
    <!-- APP TITLE -->
    <div [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
        <div class="h2 mt-4 mb-12  heading-text1" style="color:#fff;">
            Review Final Internship Report
        </div>
    </div>
    <!-- / APP TITLE -->
    <!-- ADD BUTTON -->

    <!-- <button mat-flat-button [color]="'warn'" (click)="onClose()" class="mat-elevation-z1" style="background-color: #f44336 !important;
  color: #fff !important;">
        <mat-icon>clear</mat-icon>
    </button> -->
    <!-- / ADD BUTTON -->
</div>
<!-- / HEADER -->
<div id="staff" class="page-layout carded fullwidth inner-scroll" style="height: 500px;">
    <!-- TOP BACKGROUND -->
    <!-- <div class="top-bg accent"></div> -->
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">
        <!-- CONTENT CARD -->
        <div class="content-card mat-elevation-z2 p-5 mb-12">
            <div class="content">
                <!-- <form name="reviewInternshipReportForm" [formGroup]="reviewInternshipReportForm" -->
                <form name="reviewInternshipReportForm" class="internship w-100-p p-24 mr-24" fxLayout="column"
                    fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex fusePerfectScrollbar>

                    <!-- <div class="required-mark">
                                            <span><span class="note">Note: </span>All fields marked with an asterisk (<span
                                                    class="asterisk-color">*</span>) are required.</span>
                                        </div> -->

                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start"
                        style="height: auto !important;" id="displayContent">


                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start"
                            style="width: 100%;height: auto !important;border-bottom: 5px dotted lightgray;margin-bottom: 1%;"
                            id="cretificate-best-picture">
                            <div id="internship-cretificate" class="card" style="float:left;border: 1px solid gray;"
                                *ngIf="certificate !=undefined">
                                <header>Internship Certificate</header>
                                <figure>
                                    <a (click)="downloadImage(urlC)" target="_blank" download="certificate.png"
                                        class="thumb truncate pointer">
                                        <img src="{{url+certificate}}" alt="Internship Certificate" />
                                    </a>
                                    <!-- <img src="{{certificate}}" /> -->
                                </figure>
                            </div>
                            <div id="best-picture" class="card" style="float:left;border: 1px solid gray;"
                                *ngIf="bestPicture !=undefined">
                                <header>Internship Best Picture</header>
                                <figure>
                                    <!-- <a href="{{url+bestPicture}}" target="_blank" download='bestPicture'
                                        class="thumb truncate pointer"> -->
                                    <a (click)="downloadImage(urlB)" download='photo' class="thumb truncate pointer">
                                        <img src="{{url+bestPicture}}" alt="Best Picture" />
                                    </a>

                                </figure>
                            </div>
                            <span *ngIf="isReviewReport==false" style="color:red;">{{errorMessage}}</span>
                        </div>
                        <!-- <mat-divide style="border-bottom: 5px dotted lightgray;"></mat-divider> -->
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start"
                            style="height: auto !important;" class="report-photos">
                            <!-- <section> -->
                            <div *ngFor="let photo of reportPhotos;let RowIndex = index;" class="card">
                                <header>Report Photo - {{RowIndex+1}}</header>
                                <figure>
                                    <a (click)="downloadImage(photo)" target="_self" download='photo'
                                        class="thumb truncate pointer">
                                        <!-- <a  (click)="downloadImage(photo)" target="_blank" download='photo.jpg' class="thumb truncate pointer"> -->
                                        <!-- <img [src]="photo" alt="Report Photo" /> -->
                                        <img [src]="photo" target="" alt="Report Photo" />

                                    </a>
                                </figure>
                                <!-- <p>{{fruit.description}}</p> -->
                            </div>
                            <!-- </section> -->
                        </div>
                    </div>
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start"
                        style="height: auto !important;" id="reportDetails">
                        <p *ngIf="reportData !=undefined && errorMessage==undefined"><span><b>ReportDetail : </b>
                            </span>
                            <span
                                ngClass="{{reportData.ReportDetail=='Report details are not Available' ? 'error-msg' : ''}}">{{reportData.ReportDetail}}</span>
                        </p>

                        <a *ngIf="reportData !=undefined && errorMessage==undefined"
                            style="margin: 1.6%;text-decoration: none;color:#f44336;cursor: pointer;" [href]="fileUrl"
                            download="ReportDetails.txt" class="thumb truncate pointer">Download Report Details</a>
                    </div>
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start">
                        <button mat-raised-button class="add-internship-button btn-warning mls"
                            [disabled]="isCompleted==true && isIRReviewed == true"
                            (click)="onSubmit(reviewInternshipReportForm)" fxFlex="0 1 calc(47%)"
                            fxFlex.lt-md="0 1 calc(47%)" fxFlex.lt-sm="50%">
                            <span>Acknowledge </span>
                        </button>

                        <button mat-raised-button class="add-internship-button btn-cancel" (click)="onClose()"
                            fxFlex="0 1 calc(47%)" fxFlex.lt-md="0 1 calc(47%)" fxFlex.lt-sm="50%" sty>
                            <span>Cancel </span>
                        </button>
                    </div>
                </form>
            </div>
            <!-- / CONTENT CARD -->
        </div>
        <!-- / CENTER -->
    </div>