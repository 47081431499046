import { FuseUtils } from '@fuse/utils';

export class StudentCertificatesModel {
    EmployerId: string;
    CertificateType: string;
    CertificateIssueDate: string;
    OrganizationName: string;
    CertificateContent: string;  
    IsActive: boolean;
    RequestType: any;


    CertificateId: string;
    StudentId : string; 
    TargetAudienceType: string;
    CertificateTitle: string;
    CertificateImage:string;
    SignatureImage:string;
    constructor(studentCertificatesItem?: any) {
        studentCertificatesItem = studentCertificatesItem || {};

        this.EmployerId = studentCertificatesItem.EmployerId || '';
        this.CertificateType = studentCertificatesItem.CertificateType || '';
        this.CertificateIssueDate = studentCertificatesItem.CertificateIssueDate || '';
        this.OrganizationName = studentCertificatesItem.OrganizationName || '';
        this.CertificateContent = studentCertificatesItem.CertificateContent || '';
        this.IsActive = studentCertificatesItem.IsActive || true;
        this.RequestType = 0; // New


        this.CertificateId = "00000000-0000-0000-0000-000000000000"; // New
        this.StudentId = studentCertificatesItem.StudentId || '';// New
        this.TargetAudienceType = 'Student';// New
        this.CertificateTitle = studentCertificatesItem.CertificateTitle || '';// New
        this.CertificateImage = studentCertificatesItem.CertificateImage || '';// New
        this.SignatureImage = studentCertificatesItem.SignatureImage || '';// New


    }
}
