<div id="products" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <!-- {{ 'Internship Opportunity' | translate }} -->
                    Daily Diary Entries
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}" style="color: #a4ef13;">
                            refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <form [formGroup]="diaryEntryVTForm">
                    <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon>search</mat-icon>
                        <input formControlName="filterText" placeholder="Search for a Internship Opportunity">
                    </div>
                </form>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">
                <!-- <button mat-raised-button [routerLink]="['./','new']" skipLocationChange="true" replaceUrl="false"
                    class="add-internship-opportunity-button fuse-white mt-24 mt-md-0 btn-warning">
                    <span>Add New Opportunity</span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="ms-2" width="20" height="20" viewBox="0 0 24 24"
                        stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="12" y1="5" x2="12" y2="19" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                    </svg>
                </button> -->

                <!--<a *ngIf="PageRights.IsExport" class="export-excel"
                   (click)="exporter.exportTable('xlsx', {fileName:'Schools', sheet: 'Schools', Props: {Author: 'Lighthouse'}})">
                    <mat-icon> cloud_download</mat-icon>
                </a>-->

                <a *ngIf="PageRights.IsExport" class="export-excel" (click)="exportExcel()">
                    <mat-icon> cloud_download</mat-icon>
                </a>
            </div>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- Filter Section -->
        <div class="filter-content-card">
            <div class="filter-content">
                <form name="diaryEntryVTFilterForm" [formGroup]="diaryEntryVTFilterForm" class="vc-issue-report"
                    fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Sector</mat-label>
                            <mat-select formControlName="SectorId" name="sectorId" appearance="outline">
                                <mat-option [value]="sectorItem.Id" *ngFor="let sectorItem of sectorList">
                                    {{ sectorItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label> Employer</mat-label>

                            <mat-select formControlName="EmployerId" name="employerId" appearance="outline"
                                #SelectEmployer>
                                <mat-select-filter class="select-filter" *ngIf="SelectEmployer.focused"
                                    [array]="employerList" (filteredReturn)="this.filteredEmployerItems = $event"
                                    [displayMember]="'Name'">
                                </mat-select-filter>

                                <mat-option [value]="employerItem.Id"
                                    *ngFor="let employerItem of filteredEmployerItems">
                                    {{ employerItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Duration</mat-label>
                            <mat-select formControlName="DurationId" name="durationId" appearance="outline"
                                (selectionChange)="onChangeDuration($event.value)">
                                <mat-option [value]="item.Id" *ngFor="let item of durationList">
                                    {{ item.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="clear-filter pr-1" fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="onLoadSchoolVTPSectorsByFilters()"><span>SEARCH</span></button>
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="resetFilters()"><span>CLEAR</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- /Filter Section -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table class="xxx diary-entry" [dataSource]="tableDataSource" matSort fusePerfectScrollbar
                [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- StudentName Column -->
                <ng-container matColumnDef="StudentName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>
                        <!-- Student Name -->
                        Intern’s Name
                        <!--  StudentName -->
                    </mat-header-cell>
                    <mat-cell *matCellDef="let diaryEntry">
                        <div [routerLink]="['/vt-student-details', diaryEntry.StudentID]">
                            <p>
                                <span class="stud-img">
                                    <img *ngIf="diaryEntry.StudentProfilePhoto!=''"
                                        [src]="ProfileImageBaseURL + diaryEntry.StudentProfilePhoto"
                                        class="stud-profile-pic" style="max-width: 50px !important;
                                    height: 50px !important;
                                    vertical-align: top;
                                    border: none; border-radius: 50%;" />

                                    <mat-icon *ngIf="diaryEntry.StudentProfilePhoto==''">face</mat-icon>
                                    <!-- <img 
                                    [src]="ProfileImageBaseURL + (diaryEntry.StudentProfilePhoto ==''?'no-image':diaryEntry.StudentProfilePhoto)"
                                    class="stud-profile-pic" /> -->
                                </span>

                                <b>{{diaryEntry.StudentName}}</b><br>
                                {{diaryEntry.StudentMobile}}

                            </p>
                        </div>
                    </mat-cell>
                </ng-container>

                <!-- LessonsLearnt Column -->
                <ng-container matColumnDef="LessonsLearnt">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
                        Today’s Learning
                    </mat-header-cell>
                    <mat-cell *matCellDef="let diaryEntry" fxHide fxShow.gt-xs>
                        <!-- <div *ngFor="let attItem of diaryEntry.Attachments">
                            <a href="{{ imageBasePath + attItem.AttachmentPath }}"><img src="{{ imageBasePath + attItem.AttachmentPath }}">PDF</a>
                            <a href="{{ imageBasePath + attItem.AttachmentPath }}">
                                <mat-icon>picture_as_pdf</mat-icon>
                            </a>
                        </div> -->
                        <p *ngFor="let attItem of diaryEntry.Attachments" style="display: inline-block;margin: 0px;">
                            <span *ngIf="attItem.AttachmentPath.split(':')[0]!='https'">
                                <a *ngIf="attItem.AttachmentType=='PDF'"
                                    href="{{ imageBasePath + attItem.AttachmentPath }}" target="_blank">
                                    <mat-icon>picture_as_pdf</mat-icon>
                                </a>
                                <a *ngIf="attItem.AttachmentType=='Image'"
                                    href="{{ imageBasePath + attItem.AttachmentPath }}" target="_blank">
                                    <mat-icon>image</mat-icon>
                                </a>
                                <a *ngIf="attItem.AttachmentType== 'Document'"
                                    href="{{ imageBasePath + attItem.AttachmentPath }}" target="_blank">
                                    <mat-icon>cloud_download</mat-icon>
                                </a>
                                <a *ngIf="attItem.AttachmentType== 'PPT'"
                                    href="{{ imageBasePath + attItem.AttachmentPath }}" target="_blank">
                                    <mat-icon>view_comfy</mat-icon>


                                </a>
                                <a *ngIf="attItem.AttachmentType=='Word'"
                                    href="{{ imageBasePath + attItem.AttachmentPath }}" target="_blank">
                                    <mat-icon>cloud_circle</mat-icon>

                                </a>
                            </span>
                            <span *ngIf="attItem.AttachmentPath !=null && attItem.AttachmentPath.includes('https://')">
                                <a *ngIf="attItem.AttachmentType=='PDF'"
                                    href="{{ imageBasePath + attItem.AttachmentPath }}" target="_blank">
                                    <mat-icon>picture_as_pdf</mat-icon>
                                </a>
                                <a *ngIf="attItem.AttachmentType=='Image'"
                                    href="{{ imageBasePath + attItem.AttachmentPath }}" target="_blank">
                                    <mat-icon>image</mat-icon>
                                </a>
                                <a *ngIf="attItem.AttachmentType== 'Document'"
                                    href="{{ imageBasePath + attItem.AttachmentPath }}" target="_blank">
                                    <mat-icon>cloud_download</mat-icon>
                                </a>
                                <a *ngIf="attItem.AttachmentType=='PPT'"
                                    href="{{ imageBasePath + attItem.AttachmentPath }}" target="_blank">
                                    <mat-icon>view_comfy</mat-icon>

                                </a>
                                <a *ngIf="attItem.AttachmentType=='Word'"
                                    href="{{ imageBasePath + attItem.AttachmentPath }}" target="_blank">
                                    <mat-icon>cloud_circle</mat-icon>

                                </a>
                            </span>

                            <!-- <span
                            
                                *ngIf="(attItem.AttachmentPath!=null)&& (fileEextensionsList.includes(attItem.AttachmentPath.split('.')[1]))">
                                <a *ngIf="attItem.AttachmentPath.split(':')[0]!='https'"
                                    href="{{ imageBasePath + attItem.AttachmentPath }}" target="_blank">
                                    <mat-icon *ngIf="attItem.AttachmentPath.split('.')[1]=='pdf'" >picture_as_pdf</mat-icon>
                                   
                                </a>
                                <a *ngIf="attItem.AttachmentPath.split(':')[0]!='https'"
                                    href="{{ imageBasePath + attItem.AttachmentPath }}" target="_blank">
                                    <mat-icon *ngIf="attItem.AttachmentPath.split('.')[1]=='jpg' || attItem.AttachmentPath.split('.')[1]=='jpeg' || attItem.AttachmentPath.split('.')[1]=='iso' 
                                    ||attItem.AttachmentPath.split('.')[1]=='png' ||attItem.AttachmentPath.split('.')[1]=='gif'">image</mat-icon>
                                </a>
                                <a *ngIf="attItem.AttachmentPath.split(':')[0]!='https'"
                                    href="{{ imageBasePath + attItem.AttachmentPath }}" target="_blank">
                                    
                                    <mat-icon  *ngIf="attItem.AttachmentPath.split('.')[1]=='xlsx'">cloud_download</mat-icon>
                                </a>

                            </span>

                            <span *ngIf="attItem.AttachmentPath !=null && attItem.AttachmentPath.includes('https://')">
                                <a href="{{attItem.AttachmentPath }}" target="_blank">
                                    <mat-icon *ngIf="attItem.AttachmentPath.split('.')[1]=='pdf'" >picture_as_pdf</mat-icon>

                                   

                                </a>
                                <a href="{{attItem.AttachmentPath }}" target="_blank">
                                    <mat-icon *ngIf="attItem.AttachmentPath.split('.')[1]=='jpg' || attItem.AttachmentPath.split('.')[1]=='jpeg' || attItem.AttachmentPath.split('.')[1]=='iso' 
                                    ||attItem.AttachmentPath.split('.')[1]=='png' ||attItem.AttachmentPath.split('.')[1]=='gif'">image</mat-icon>

                                </a>
                                <a href="{{attItem.AttachmentPath }}" target="_blank">
                                   
                                    <mat-icon  *ngIf="attItem.AttachmentPath.split('.')[1]=='xlsx'">cloud_download</mat-icon>

                                </a>
                            </span> -->

                        </p>

                        <!-- <p>{{diaryEntry.LessonsLearnt}}</p> -->
                        <p style="word-wrap: break-word;width: 100%;margin: 0px;">
                            {{ diaryEntry.show ?
                            diaryEntry.LessonsLearnt:(diaryEntry.LessonsLearnt |
                            slice:0:100)}}
                            <span *ngIf="diaryEntry.LessonsLearnt !=null || diaryEntry.LessonsLearnt!= undefined">
                                <button class="show-less-button" type="button"
                                    *ngIf="diaryEntry.LessonsLearnt.length > 50;"
                                    (click)="diaryEntry.show = !diaryEntry.show"
                                    style="border: none;background: #fff;color:#00f">
                                    {{ ((diaryEntry.show)) ? 'Read less':'Read more' }}
                                </button>
                            </span>


                        </p>

                    </mat-cell>
                </ng-container>

                <!-- Hours Column -->
                <ng-container matColumnDef="Hours">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Hours Spent
                    </mat-header-cell>
                    <mat-cell *matCellDef="let diaryEntry">
                        <p class="text-truncate"><b>{{diaryEntry.Hours}} H </b></p>
                    </mat-cell>
                </ng-container>

                <!-- Highlight Column -->
                <ng-container matColumnDef="Highlight">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
                        Highlight
                    </mat-header-cell>
                    <mat-cell *matCellDef="let diaryEntry" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{diaryEntry.Highlight}}</p>
                    </mat-cell>
                </ng-container>



                <mat-header-row *matHeaderRowDef="displayedColumns; "></mat-header-row>

                <mat-row *matRowDef="let diaryEntry; columns: displayedColumns;" class="diaryEntry">
                </mat-row>

                <!-- Group header -->
                <ng-container matColumnDef="groupHeader">
                    <td colspan="999" mat-cell *matCellDef="let groupBy"><strong>
                            {{groupBy.date | date}}</strong></td>
                </ng-container>

                <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"> </tr>
            </mat-table>

            <!-- <mat-paginator #ListPaginator [length]="SearchBy.TotalResults" [pageIndex]="SearchBy.PageIndex"
                [pageSize]="SearchBy.PageSize" [pageSizeOptions]="SearchBy.PageSizeOptions"
                [showFirstLastButtons]="SearchBy.ShowFirstLastButtons" (page)="onPageIndexChanged($event)">
            </mat-paginator> -->

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>