<div id="school" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    Final Assignment
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0" style="width: 100%;
            max-width: 480px;
            border-radius: 28px;
            overflow: hidden; background-color: white;">
                <form [formGroup]="internshipAssignmentSearchForm">
                    <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center" style="width: 100%;
                    height: 48px;
                    line-height: 48px;
                    padding: 0 18px;">
                        <mat-icon>search</mat-icon>
                        <input formControlName="filterText" placeholder="Search for a school" style="width: 100%;
                        height: 48px;
                        min-height: 48px;
                        max-height: 48px;
                        padding: 0 16px;
                        border: none;
                        outline: none;">
                    </div>
                </form>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">


                <!-- <button mat-raised-button [routerLink]="['./','new']" skipLocationChange="true" replaceUrl="false"
                    class="add-school-button fuse-white mt-24 mt-md-0 btn-warning mls">
                    <span>New Notice</span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="ms-2" width="20" height="20" viewBox="0 0 24 24"
                        stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <line x1="12" y1="5" x2="12" y2="19"></line>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                </button> -->

                <!--<a *ngIf="PageRights.IsExport" class="export-excel"
                   (click)="exporter.exportTable('xlsx', {fileName:'Schools', sheet: 'Schools', Props: {Author: 'Lighthouse'}})">
                    <mat-icon> cloud_download</mat-icon>
                </a>-->

                <!-- <a *ngIf="PageRights.IsExport" class="export-excel" (click)="exportExcel()">
                    <mat-icon> cloud_download</mat-icon>
                </a> -->
            </div>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- Filter Section -->
        <div class="filter-content-card">
            <div class="filter-content">
                <form name="internshipAssignmentFilterForm" [formGroup]="internshipAssignmentFilterForm"
                    class="vc-issue-report" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Filter1 </mat-label>

                            <mat-select formControlName="Class" name="class" appearance="outline"
                                (selectionChange)="onChangeSection($event.value)">
                                <!-- <mat-option [value]="ctem.Id" *ngFor="let ctem of classList">
                                    {{ ctem.Name }}
                                </mat-option> -->
                                <mat-option value="Today">Today</mat-option>
                                <mat-option value="Yesterday">Yesterday</mat-option>
                                <mat-option value="Last 7 days">Last 7 days</mat-option>
                                <mat-option value="This Month">This Month</mat-option>
                                <mat-option value="LAst Month">LAst Month</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Filter2 </mat-label>

                            <mat-select formControlName="Class" name="class" appearance="outline"
                                (selectionChange)="onChangeSection($event.value)">
                                <!-- <mat-option [value]="ctem.Id" *ngFor="let ctem of classList">
                                    {{ ctem.Name }}
                                </mat-option> -->
                                <mat-option value="Today">Today</mat-option>
                                <mat-option value="Yesterday">Yesterday</mat-option>
                                <mat-option value="Last 7 days">Last 7 days</mat-option>
                                <mat-option value="This Month">This Month</mat-option>
                                <mat-option value="LAst Month">LAst Month</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Filter3 </mat-label>

                            <mat-select formControlName="Class" name="class" appearance="outline"
                                (selectionChange)="onChangeSection($event.value)">
                                <!-- <mat-option [value]="ctem.Id" *ngFor="let ctem of classList">
                                    {{ ctem.Name }}
                                </mat-option> -->
                                <mat-option value="Today">Today</mat-option>
                                <mat-option value="Yesterday">Yesterday</mat-option>
                                <mat-option value="Last 7 days">Last 7 days</mat-option>
                                <mat-option value="This Month">This Month</mat-option>
                                <mat-option value="LAst Month">LAst Month</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="clear-filter pr-1" fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="onLoadInternshipAssignmentByFilters()"><span>SEARCH</span></button>
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="resetFilters()"><span>CLEAR</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- /Filter Section -->


        <!-- CONTENT CARD -->
        <div class="content-card rounded-12 shadow-dark-80">
            <mat-table class="school-table responsive-table" [dataSource]="tableDataSource" matSort fusePerfectScrollbar
                [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">
                <!-- <table mat-table [dataSource]="tableDataSource" class="school-table responsive-table"
                [dataSource]="tableDataSource" matSort fusePerfectScrollbar
                [fusePerfectScrollbarOptions]="{'suppressScrollX': false}"> -->
                <!-- ReportDate Column -->


                <!-- StudentName Column -->
                <ng-container matColumnDef="StudentName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        STUDENT NAME
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipAssignment">
                        <div [routerLink]="['/vt-student-details', internshipAssignment.StudentID]">
                            <p class="text-truncate">
                                <span *ngIf="internshipAssignment.StudentPhoto !== ''  ">
                                    <span *ngIf="internshipAssignment.StudentPhoto !== null "><img
                                            [src]="ApiBaseUrlImage+internshipAssignment.StudentPhoto" width="50"
                                            height="50" style="border-radius: 50%;"></span></span>
                                <span>{{internshipAssignment.StudentName}}</span>
                            </p>
                        </div>
                    </mat-cell>
                </ng-container>

                <!-- InternshipImage Column -->
                <ng-container matColumnDef="InternshipImage">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        INTERNSHIP IMAGE
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipAssignment">
                        <p class="text-truncate">
                            <span *ngFor="let bestPictureItem of internshipAssignment.listBestPicture">
                                <img [src]="FinalInternshipImageBaseURL+bestPictureItem.AttachmentPath"
                                    style="width:50px;height:50px; margin:1px; padding: 1px;"
                                    class="d-block mb-10 border border-gray-200 p-1">
                            </span>
                        </p>
                    </mat-cell>
                </ng-container>

                <!-- CertificatePicture Column -->
                <ng-container matColumnDef="CertificatePicture">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        CERTIFICATE PICTURE
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipAssignment" colspan="1">
                        <p class="text-truncate">
                            <span *ngFor="let certificateItem of internshipAssignment.listCertificate">
                                <img [src]="FinalInternshipImageBaseURL+certificateItem.AttachmentPath"
                                    style="width:50px;height:50px; margin:1px; padding: 1px;"
                                    class="d-block mb-10 border border-gray-200 p-1">
                            </span>
                        </p>
                    </mat-cell>
                </ng-container>

                <!-- ProjectReport Column -->
                <ng-container matColumnDef="ProjectReport">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>PROJECT REPORT
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipAssignment">
                        <p class="text-truncate">

                            <span *ngFor="let reportPhotoItem of internshipAssignment.listReportPhoto">
                                <img [src]="FinalInternshipImageBaseURL+reportPhotoItem.AttachmentPath"
                                    style="width:50px;height:50px; margin:1px; padding: 1px;"
                                    class="d-block mb-10 border border-gray-200 p-1">
                            </span>

                        </p>
                    </mat-cell>
                </ng-container>

                <!-- ReportDetail Column -->
                <ng-container matColumnDef="ReportDetail">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>DETAILS
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipAssignment">
                        <p class="text-truncate">{{internshipAssignment.ReportDetail}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Actions" stickyEnd>
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let internshipAssignment" fxShow.gt-xs>

                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item
                                (click)="onClickReviewReport(internshipAssignment.StudentID,internshipAssignment.InternshipId,internshipAssignment.IsCompleted,internshipAssignment.IsIRReviewed)">
                                <mat-icon style="color: #1088e4;">library_books</mat-icon>
                                <span>Review report</span>
                            </button>
                            <!-- <button mat-menu-item>
                                <span>Another action</span>
                            </button>
                            <button mat-menu-item>
                                <span>Something else here</span>
                            </button> -->
                        </mat-menu>
                    </mat-cell>
                </ng-container>

                <!-- <ng-container matColumnDef="Date">
                    <th mat-header-cell *matHeaderCellDef [attr.colspan]="5" >Date</mat-header-cell>
                    <td mat-cell *matCellDef [attr.colspan]="5" >{{internshipAssignment.ReportDate}}</mat-cell>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="['Date']"></tr>
                <td mat-cell *matCellDef="['Date']"></mat-cell> -->

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>


                <mat-row *matRowDef="let internshipAssignment; columns: displayedColumns;" class="school" matRipple>
                </mat-row>
                <!-- Group header -->
                <ng-container matColumnDef="groupHeader">
                    <td colspan="6" mat-cell *matCellDef="let groupBy"><strong>
                            {{groupBy.date | date}}</strong></td>
                </ng-container>

                <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"> </tr>
            </mat-table>
            <!-- </table> -->

            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

            <!-- <mat-paginator #ListPaginator [length]="SearchBy.TotalResults" [pageIndex]="SearchBy.PageIndex"
                [pageSize]="SearchBy.PageSize" [pageSizeOptions]="SearchBy.PageSizeOptions"
                [showFirstLastButtons]="SearchBy.ShowFirstLastButtons" (page)="onPageIndexChanged($event)">
            </mat-paginator> -->

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>