import { FuseUtils } from '@fuse/utils';

export class EmployerStudentMappingModel {
    ConfigId: string;
    AcademicYearId: string;
    StateCode: string; // New Parameters
    IsDirectMappingEnabled: boolean;
    IsActive: boolean;
    RequestType: any;

    constructor(employerStudentMappingItem?: any) {
        employerStudentMappingItem = employerStudentMappingItem || {};
        this.ConfigId = employerStudentMappingItem.ConfigId || FuseUtils.NewGuid();
        this.AcademicYearId = employerStudentMappingItem.AcademicYearId || '';
        this.StateCode = employerStudentMappingItem.StateCode || '';
        this.IsDirectMappingEnabled = employerStudentMappingItem.IsActive || true;
        this.IsActive = employerStudentMappingItem.IsActive || true;
        this.RequestType = 0; // New
    }
}
