import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SchoolVTMappingModel } from './school-vt-mapping.model';
import { SchoolVTMappingService } from './school-vt-mapping.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DropdownModel } from 'app/models/dropdown.model';

@Component({
  selector: 'data-list-view',
  templateUrl: './school-vt-mapping.component.html',
  styleUrls: ['./school-vt-mapping.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class SchoolVTMappingComponent extends BaseListComponent<SchoolVTMappingModel> implements OnInit {
  schoolVTMappingFilterForm: FormGroup;
  roleList: [DropdownModel];
  schoolVtMapping: Element[];
  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    public zone: NgZone,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private schoolVTMappingService: SchoolVTMappingService) {
    super(commonService, router, routeParams, snackBar, zone);
    this.schoolVTMappingFilterForm = this.createSchoolVTMappingFilterForm();

  }

  ngOnInit(): void {
    this.onLoadSchoolVTMappingCriteria();
    // this.schoolVTMappingService.GetAllByCriteria(this.SearchBy).subscribe(response => {
    //   this.displayedColumns = ['SchoolName', 'VTFullName', 'YearName', 'DateOfAllocation', 'DateOfDeAllocation', 'Remarks', 'IsActive', 'Actions'];
    //   console.log(response.Results)
    //   this.schoolVtMapping = response.Results;
    //   this.tableDataSource.data = response.Results;
    //   this.tableDataSource.sort = this.ListSort;
    //   this.tableDataSource.paginator = this.ListPaginator;
    //   this.tableDataSource.filteredData = this.tableDataSource.data;

    //   this.zone.run(() => {
    //     if (this.tableDataSource.data.length == 0) {
    //       this.showNoDataFoundSnackBar();
    //     }
    //   });
    //   this.IsLoading = false;
    // }, error => {
    //   console.log(error);
    // });
  }

  onLoadSchoolVTMappingCriteria(): any {
    this.SearchBy.IsActive = this.schoolVTMappingFilterForm.controls["Status"].value=='true'?true: this.schoolVTMappingFilterForm.controls["Status"].value=='false'?false: this.schoolVTMappingFilterForm.controls["Status"].value;
    this.IsLoading = true;
    this.schoolVTMappingService.GetAllByCriteria(this.SearchBy).subscribe(response => {
      this.displayedColumns = ['SchoolName', 'VTFullName', 'YearName', 'DateOfAllocation', 'DateOfDeAllocation', 'Remarks', 'IsActive', 'Actions'];
      
      this.schoolVtMapping = response.Results;
      this.tableDataSource.data = response.Results;
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;

      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
    });
  }

  exportExcel(): void {
    this.IsLoading = true;
    this.SearchBy.PageIndex = 0;
    this.SearchBy.PageSize = 10000;
  
    this.schoolVTMappingService.GetAllByCriteria(this.SearchBy).subscribe(response => {
      response.Results.forEach(
        function (obj) {
          if (obj.hasOwnProperty('IsActive')) {
            obj.IsActive = obj.IsActive ? 'Yes' : 'No';
          }
          if (obj.hasOwnProperty('IsImplemented')) {
            obj.IsImplemented = obj.IsImplemented ? 'Yes' : 'No';
          }
          delete obj.SchoolId;
          delete obj.SchoolVTPVCId;
          delete obj.SchoolSectorJobRolesVTPId;
          delete obj.VTId;
          delete obj.AcademicYearId;
          delete obj.StateCode;
          delete obj.StateName;
          delete obj.Remarks;
          delete obj.TotalRows;
        });
  
      this.exportExcelFromTable(response.Results, "SchoolVTMapping");
  
      this.IsLoading = false;
      this.SearchBy.PageIndex = 0;
      this.SearchBy.PageSize = 10;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  //Create SchoolVTMapping form and returns {FormGroup}
  createSchoolVTMappingFilterForm(): FormGroup {
    return this.formBuilder.group({
      //AcademicYearId: new FormControl(),
      Status: new FormControl()
    });
  }

  onLoadSchoolVTMappingByFilters(): any {
    this.SearchBy.PageIndex = 0;
    this.onLoadSchoolVTMappingCriteria();
  }

  resetFilters(): void {
    this.SearchBy.PageIndex = 0;
    this.schoolVTMappingFilterForm.reset();
    //this.schoolFilterForm.get('AcademicYearId').setValue(this.AcademicYearId);

    this.onLoadSchoolVTMappingCriteria();
  }
}


