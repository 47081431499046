import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';
import { UserModel } from "app/models/user.model";

@Injectable()
export class SchoolSectorJobRoleVTPService {
    constructor(private http: BaseService) { }

    getSectorJobRoles(): Observable<any> {
        return this.http
            .HttpGet(this.http.Services.SchoolSectorJobRolesVTP.GetAll)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetAllByCriteria(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.SchoolSectorJobRolesVTP.GetAllByCriteria,filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    getSchoolSectorJobRoleVTPById(SchoolSectorJobRolesVTPId: string) {
        let requestParams = {
            DataId: SchoolSectorJobRolesVTPId
        };

        return this.http
            .HttpPost(this.http.Services.SchoolSectorJobRolesVTP.GetById, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    createOrUpdateSchoolSectorJobRole(formData: any) {
        return this.http
            .HttpPost(this.http.Services.SchoolSectorJobRolesVTP.CreateOrUpdate, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    deleteSchoolSectorJobRoleVTPById(SchoolSectorJobRolesVTPId: string) {
        var sectorJobRoleParams = {
            DataId: SchoolSectorJobRolesVTPId
        };

        return this.http
            .HttpPost(this.http.Services.SchoolSectorJobRolesVTP.Delete, sectorJobRoleParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }
    getAllRoles(): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.RoleTransaction.GetAllRoles, {})
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }
    
    getDropdownforSectorJobRole(userModel: UserModel): Observable<any[]> {
        let sectorRequest = this.http.GetMasterDataByType({ DataType: 'Sectors', SelectTitle: 'Sector' });
        let jobRoleRequest = this.getAllRoles();
        let schoolRequest = this.http.GetMasterDataByType({ DataType: 'SchoolSectorJobRole', ParentId: userModel.UserTypeId, SelectTitle: 'School' });
        let academicYearRequest = this.http.GetMasterDataByType({ DataType: 'AcademicYearsByState', UserId:userModel.UserTypeId,SelectTitle: 'Academic Year' });
        let vtpRequest = this.http.GetMasterDataByType({DataType: 'VocationalTrainingProvidersByVTP', ParentId: null, SelectTitle: 'VTP' });
        let statecodeRequest = this.http.GetMasterDataByType({ DataType: 'StatesByUserId', UserId:userModel.UserTypeId, SelectTitle: 'StateCode' });


        
        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        return forkJoin([sectorRequest, jobRoleRequest,schoolRequest,academicYearRequest,vtpRequest,statecodeRequest]);
    }
}
