<div id="role" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    Employer Details

                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->
            <!-- <div fxLayout="row" class="add-edit-button" fxLayoutAlign="flex-start" fxFlex="0 1 calc(50%)"
                fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="100%">

                <button mat-raised-button class="add-school-button btn-outline-dark" [routerLink]="'/employer-listing'"
                    fxFlex="0 1 calc(33%)" fxFlex.lt-md="0 1 calc(33%)" fxFlex.lt-sm="50%">
                    <span>Manage Empployer </span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="ms-2" width="20" height="20" viewBox="0 0 24 24"
                        stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"></path>
                        <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3"></path>
                        <line x1="16" y1="5" x2="19" y2="8"></line>
                    </svg>
                </button>

                <button mat-raised-button class="add-school-button btn-warning mls" fxFlex="0 1 calc(33%)"
                    fxFlex.lt-md="0 1 calc(33%)" fxFlex.lt-sm="50%" [routerLink]="['/employer-listing','new']">
                    <span>Add New Empployer </span><svg xmlns="http://www.w3.org/2000/svg" class="ms-2" width="20"
                        height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="12" y1="5" x2="12" y2="19" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                    </svg>
                </button>



            </div> -->



        </div>
        <!-- / HEADER -->


        <!-- CONTENT CARD -->
        <div class="content-card" style="overflow: scroll;">

            <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="displayContent">

                <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                    appearance="outline" fxFlex="0 1 calc(29%)" fxFlex.lt-md="0 1 calc(29%)" fxFlex.lt-sm="29%"
                    style="height: auto;margin-left: 1%;margin-top: 1%;">

                    <mat-card class="example-card main-box" appearance="outline" fxFlex="100" fxFlex.lt-md="100"
                        fxFlex.lt-sm="100" *ngIf="empDetailsData" style="height: 41%;">
                        <mat-card-header style="padding: 2rem;
                        text-align: center;
                        display: flex!important;">
                            <div mat-card-avatar class="example-header-image">
                                <img [src]="img+(empDetailsData.EmpPhotoUrl ==''?'no-image':empDetailsData.EmpPhotoUrl)"
                                    alt="" width="50" height="50" style="border-radius: 50%;">
                            </div>
                            <mat-card-title>{{empDetailsData.EmployerName}}</mat-card-title>
                            <mat-card-subtitle>{{empDetailsData.BusinessDesc}}</mat-card-subtitle>
                        </mat-card-header>
                        <mat-divider inset></mat-divider>
                        <mat-card-actions>
                            <button mat-button >Internships</button>
                        </mat-card-actions>
                        <mat-divider inset></mat-divider>
                        <!-- <mat-card-actions>
                            <button mat-button (click)="scrollPoint1(point_0)">Gratitude Corner</button>
                        </mat-card-actions>
                        <mat-divider inset></mat-divider>
                        <mat-card-actions>
                            <button mat-button (click)="scrollPoint1(point_1)">Certificates</button>
                        </mat-card-actions> -->
                        <mat-divider inset></mat-divider>
                        <mat-card-actions>
                            <button mat-button (click)="scrollPoint1(point_2)">Employer Details</button>
                        </mat-card-actions>
                        <mat-divider inset></mat-divider>

                        <mat-divider inset></mat-divider>

                        <mat-card-actions>
                            <button type="button" mat-button (click)="onDeleteSchool(empDetailsData.StudentId)">Delete
                                Employer</button>
                        </mat-card-actions>

                    </mat-card>

                </div>



                <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                    appearance="outline" fxFlex="0 1 calc(68%)" fxFlex.lt-md="0 1 calc(68%)" fxFlex.lt-sm="68%"
                    style="height: auto;margin-left: 1%;margin-top: 1%;"
                    class="position-relative rounded-12 border border-gray-300 ">
                    <div class="padding-bottom" style="width:100% ;">

                        <mat-tab-group selectedIndex="0" (selectedTabChange)="changeTab($event)">
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <span>Current Internship</span>
                                </ng-template>
                                <div class="content-card rounded-12 shadow-dark-80" >
                                    <mat-card style="width:100% ;">
                                        <table *ngIf="InternshipsByEmployerData">
                                            <tr *ngFor="let item of InternshipsByEmployerData" >
                                                <td *ngIf="item.IsCompleted===false">
                                                    <span
                                                        style="font-weight: 900;width: 100%;">{{empDetailsData.EmployerName}}</span><br>
                                                    <span
                                                        style="font-weight: 900;width: 100%;">{{item.BranchName}}</span><br>
                                                    <span>{{item.Title}}</span>
                                                </td>
                                                <td *ngIf="item.IsCompleted===false">
                                                    <span style="width: 100%;">Start Date</span><br>
                                                    <span style="font-weight: 900">{{item.StartDate
                                                        |date:'mediumDate'}}</span>
                                                </td>
                                                <td *ngIf="item.IsCompleted===false">
                                                    <span style="width: 100%;">Open Position</span><br>
                                                    <span style="font-weight: 900">{{item.InternshipOpenPositions}}</span>
                                                </td>
                                                <td *ngIf="item.IsCompleted===false">
                                                    <span style="font-weight: 900"><button mat-raised-button
                                                            type="button"
                                                            style="    background-color: #e6f0ff; color: #0d6efd;"
                                                            [routerLink]="['/internship-opportunity', 'view', item.InternshipId]">
                                                            View
                                                        </button></span>
                                                </td>

                                            </tr>
                                        </table>
                                    </mat-card>

                                    <mat-card style="width:100% ;font-size: 2.25rem;">Intern</mat-card>
                                    <mat-divider inset></mat-divider>
                                    <mat-table class="school-table responsive-table" [dataSource]="tableDataSource"
                                        matSort fusePerfectScrollbar
                                        [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                                        <!-- STUDENT Column -->
                                        <ng-container matColumnDef="STUDENT">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm
                                                fxShow.gt-md>
                                                STUDENT
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let student"
                                                
                                                [routerLink]="['/vt-student-details', student.StudentId]">
                                                <p class="text-truncate">
                                                    <span *ngIf="student.ProfilePhoto != null"><img [src]="img+student.ProfilePhoto" width="50" height="50" style="border-radius: 50%;"></span>
                                                    {{student.StudentName}}
                                                </p>
                                            </mat-cell>
                                        </ng-container>

                                        <!-- ATTENDANCE NAME Column -->
                                        <ng-container matColumnDef="ATTENDANCE">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
                                                ATTENDANCE
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let student" fxHide fxShow.gt-xs>
                                                <p class="text-truncate">{{student.TotalAttendance}}</p>
                                            </mat-cell>
                                        </ng-container>



                                        <!-- HOURSSPENT Column -->
                                        <ng-container matColumnDef="HOURSSPENT">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm
                                                fxShow.gt-md>HOURS SPENT
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let student">
                                                <p class="text-truncate">{{student.Totalhours}}</p>
                                            </mat-cell>
                                        </ng-container>











                                        <ng-container matColumnDef="Actions" stickyEnd>
                                            <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                                            <mat-cell *matCellDef="let student" fxShow.gt-xs>
                                                <button *ngIf="PageRights.IsView" mat-icon-button
                                                    
                                                    [routerLink]="['/vt-student', 'view', student.StudentId]"
                                                    skipLocationChange="true" replaceUrl="false">
                                                    <mat-icon color="primary">visibility</mat-icon>
                                                </button>

                                            </mat-cell>
                                        </ng-container>

                                        <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true">
                                        </mat-header-row>

                                        <mat-row *matRowDef="let student; columns: displayedColumns;" class="school"
                                            matRipple>
                                        </mat-row>
                                    </mat-table>

                                    <mat-paginator #ListPaginator [length]="SearchBy.TotalResults"
                                        [pageIndex]="SearchBy.PageIndex" [pageSize]="SearchBy.PageSize"
                                        [pageSizeOptions]="SearchBy.PageSizeOptions"
                                        [showFirstLastButtons]="SearchBy.ShowFirstLastButtons"
                                        (page)="onPageIndexChanged($event)">
                                    </mat-paginator>

                                </div>

                            </mat-tab>
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <span>Past Internship</span>
                                </ng-template>
                                <div class="content-card rounded-12 shadow-dark-80">
                                    <mat-card style="width:100% ;">
                                        <table *ngIf="InternshipsByEmployerData">
                                            <tr *ngFor="let item of InternshipsByEmployerData">
                                                <td *ngIf="item.IsCompleted===true">
                                                    <span
                                                        style="font-weight: 900;width: 100%;">{{empDetailsData.EmployerName}}</span><br>
                                                    <span
                                                        style="font-weight: 900;width: 100%;">{{item.BranchName}}</span><br>
                                                    <span>{{item.Title}}</span>
                                                </td>
                                                <td *ngIf="item.IsCompleted===true">
                                                    <span style="width: 100%;">Start Date</span><br>
                                                    <span style="font-weight: 900">{{item.StartDate
                                                        |date:'mediumDate'}}</span>
                                                </td>
                                                <td *ngIf="item.IsCompleted===true">
                                                    <span style="width: 100%;">Open Position</span><br>
                                                    <span style="font-weight: 900">{{item.NoOfOpenings}}</span>
                                                </td>
                                                <td *ngIf="item.IsCompleted===true">
                                                    <span style="font-weight: 900"><button mat-raised-button
                                                            type="button"
                                                            style="    background-color: #e6f0ff; color: #0d6efd;"
                                                            [routerLink]="['/internship-opportunity', 'view', item.InternshipId]">
                                                            View
                                                        </button></span>
                                                </td>

                                            </tr>
                                        </table>
                                    </mat-card>

                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>



                </div>
                <!-- <div #point_0 fxLayout="row wrap" fxLayout.lt-sm="column" appearance="outline" fxFlex="100"
                    fxFlex.lt-md="100" fxFlex.lt-sm="100">
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                        appearance="outline" fxFlex="0 1 calc(29%)" fxFlex.lt-md="0 1 calc(29%)" fxFlex.lt-sm="29%"
                        style="height: auto;margin-left: 1%;margin-top: 1%;" *ngIf="empDetailsData">


                    </div>
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                        appearance="outline" fxFlex="0 1 calc(68%)" fxFlex.lt-md="0 1 calc(68%)" fxFlex.lt-sm="68%"
                        style="height: auto;margin-left: 1%;margin-top: 1%;"
                        class="position-relative rounded-12 border border-gray-300 " *ngIf="empDetailsData">
                        <mat-card style="width:100% ;font-size: 2.25rem;">Gratitude Corner</mat-card>
                        <span>Coming Soon</span>

                    </div>
                </div> -->
                <!-- <div #point_1 fxLayout="row wrap" fxLayout.lt-sm="column" appearance="outline" fxFlex="100"
                    fxFlex.lt-md="100" fxFlex.lt-sm="100">
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                        appearance="outline" fxFlex="0 1 calc(29%)" fxFlex.lt-md="0 1 calc(29%)" fxFlex.lt-sm="29%"
                        style="height: auto;margin-left: 1%;margin-top: 1%;" *ngIf="empDetailsData">


                    </div>
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                        appearance="outline" fxFlex="0 1 calc(68%)" fxFlex.lt-md="0 1 calc(68%)" fxFlex.lt-sm="68%"
                        style="height: auto;margin-left: 1%;margin-top: 1%;"
                        class="position-relative rounded-12 border border-gray-300 " *ngIf="empDetailsData">
                        <mat-card style="width:100% ;font-size: 2.25rem;">Certificates</mat-card>
                        <span>Coming Soon</span>
                    </div>
                </div> -->


                <!-- / Student Details -->
                <div #point_2 fxLayout="row wrap" fxLayout.lt-sm="column" appearance="outline" fxFlex="100"
                    fxFlex.lt-md="100" fxFlex.lt-sm="100">
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                        appearance="outline" fxFlex="0 1 calc(29%)" fxFlex.lt-md="0 1 calc(29%)" fxFlex.lt-sm="29%"
                        style="height: auto;margin-left: 1%;margin-top: 1%;" *ngIf="empDetailsData">


                    </div>
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                        appearance="outline" fxFlex="0 1 calc(68%)" fxFlex.lt-md="0 1 calc(68%)" fxFlex.lt-sm="68%"
                        style="height: auto;margin-left: 1%;margin-top: 1%;"
                        class="position-relative rounded-12 border border-gray-300 " *ngIf="empDetailsData">
                        <mat-card style="width:100% ;" class="fontW">
                          
                           <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(93%)" fxFlex.lt-md="0 1 calc(93%)" fxFlex.lt-sm="93%"
                            style="height: auto;margin-left: 1%;margin-top: 1%;">
                            <span>Employer Details </span> 
                            </div>
                            <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(5%)" fxFlex.lt-md="0 1 calc(5%)" fxFlex.lt-sm="5%"
                            style="height: auto;margin-left: 1%;margin-top: 1%;">
                            <button mat-raised-button type="button"
                            style="    background-color: #e6f0ff; color: #0d6efd;"
                            [routerLink]="['/employer-listing', 'edit', empDetailsData.EmployerId]">
                            Edit
                        </button>
                            </div>
                           
                          
                            
                        </mat-card>
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(49%)" fxFlex.lt-md="0 1 calc(49%)" fxFlex.lt-sm="49%"
                            style="height: auto;margin-left: 1%;margin-top: 1%;">
                            <span class="wi">Full Name</span><br>
                            <span class="fontW">
                                {{empDetailsData.EmployerFirstName}}{{empDetailsData.EmployerLastName}}</span>
                        </div>
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(49%)" fxFlex.lt-md="0 1 calc(49%)" fxFlex.lt-sm="49%"
                            style="height: auto;margin-left: 1%;margin-top: 1%;">
                            <span class="wi">Gender</span><br>
                            <span class="fontW"> {{empDetailsData.GenderName}}</span>

                        </div>
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(49%)" fxFlex.lt-md="0 1 calc(49%)" fxFlex.lt-sm="49%"
                            style="height: auto;margin-left: 1%;margin-top: 1%;">
                            <span class="wi"> Mobile</span><br>
                            <span class="fontW"> {{empDetailsData.POCMobile}}</span>
                        </div>
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(49%)" fxFlex.lt-md="0 1 calc(49%)" fxFlex.lt-sm="49%"
                            style="height: auto;margin-left: 1%;margin-top: 1%;">
                            <span class="wi"> Email ID
                            </span><br>
                            <span class="fontW"> {{empDetailsData.POCEmailId}}</span>

                        </div>







                    </div>
                </div>

                <!-- / Academic Details -->
                <div #point_1 fxLayout="row wrap" fxLayout.lt-sm="column" appearance="outline" fxFlex="100"
                    fxFlex.lt-md="100" fxFlex.lt-sm="100">
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                        appearance="outline" fxFlex="0 1 calc(29%)" fxFlex.lt-md="0 1 calc(29%)" fxFlex.lt-sm="29%"
                        style="height: auto;margin-left: 1%;margin-top: 1%;" *ngIf="empDetailsData">


                    </div>
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                        appearance="outline" fxFlex="0 1 calc(68%)" fxFlex.lt-md="0 1 calc(68%)" fxFlex.lt-sm="68%"
                        style="height: auto;margin-left: 1%;margin-top: 1%;"
                        class="position-relative rounded-12 border border-gray-300 " *ngIf="empDetailsData">
                        <mat-card style="width:100% ;" class="fontW">Business Details </mat-card>
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(32%)" fxFlex.lt-md="0 1 calc(32%)" fxFlex.lt-sm="32%"
                            style="height: auto;margin-left: 1%;margin-top: 1%;">


                            <img [src]="img+empDetailsData.EmpPhotoUrl" alt="" height="50" width="50" style="border-radius: 50%;">


                        </div>
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(63%)" fxFlex.lt-md="0 1 calc(63%)" fxFlex.lt-sm="63%"
                            style="height: auto;margin-left: 1%;margin-top: 1%;">
                            <span class="wi">Shop/ Organization's Name</span><br>
                            <span class="fontW wi"> {{empDetailsData.OrganizationName}}</span><br>
                        </div>
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(99%)" fxFlex.lt-md="0 1 calc(99%)" fxFlex.lt-sm="99%"
                            style="height: auto;margin-left: 1%;margin-top: 1%;">
                            <span class=" wi"> Business Description </span><br>
                            <span class=" fontW"> {{empDetailsData.BusinessDesc}}</span>

                        </div>
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(48%)" fxFlex.lt-md="0 1 calc(48%)" fxFlex.lt-sm="48%"
                            style="height: auto;margin-left: 1%;margin-top: 1%;">
                            <span class=" wi">  Sector</span>

                            <br>
                            <span class=" fontW" *ngFor="let item of empDetailsData.EmployersSectors;"> {{item.SectorName}},</span>
                        </div>
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(48%)" fxFlex.lt-md="0 1 calc(48%)" fxFlex.lt-sm="48%"
                            style="height: auto;margin-left: 1%;margin-top: 1%;">
                            <span class=" wi"> No. of Employees</span>

                            <br>
                            <span class=" fontW"> {{empDetailsData.NoOfEmployees}}</span>
                        </div>

                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(99%)" fxFlex.lt-md="0 1 calc(99%)" fxFlex.lt-sm="99%"
                            style="height: auto;margin-left: 1%;margin-top: 1%;">
                            <span class=" wi"> Office Address</span>
                            <br>
                            <!-- <span class=" wi"> Full Addres</span><br> -->
                            <span class=" fontW"> {{empDetailsData.BranchAddress}}</span>

                        </div>




                    </div>
                </div>
            </div>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>