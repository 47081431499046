import { DatePipe } from '@angular/common';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Component, Input, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { DropdownModel } from 'app/models/dropdown.model';
import { CommonService } from 'app/services/common.service';
import { environment } from 'environments/environment';
import { duration } from 'moment';
// import { groupBy } from 'lodash';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { DropOutForInternshipComponent } from './drop-out-for-internship/drop-out-for-internship.component';
import { StudentInternshipDateChangeComponent } from './student-internship-date-change/student-internship-date-change.component';
import { InternshipApproval } from './internship-approval.model';
import { InternshipApprovalService } from './internship-approval.service';
import { MarkInternshipCompletedComponent } from './mark-internship-completed/mark-internship-completed.component';
import { ReviewInternshipReportComponent } from './review-internship-report/review-internship-report.component';
import { ViewInternshipApprovalComponent } from './view-internship-approval/view-internship-approval.component';
import { InternshipOpportunityService } from '../internship-opportunity/internship-opportunity.service';

@Component({
  selector: 'app-internship-approval',
  templateUrl: './internship-approval.component.html',
  styleUrls: ['./internship-approval.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class InternshipApprovalComponent extends BaseListComponent<InternshipApproval> implements OnInit {
  InternshipApprovalForm: FormGroup;
  InternshipApprovalFilterForm: FormGroup;

  @Input() IsAllowedGloablSearch: boolean;
  tabIndex = 0;
  currentAcademicYearId: string;
  sectorList: [DropdownModel];
  vtpList: [DropdownModel];
  filteredVtpSectorItems: any;
  employerList: [DropdownModel];
  filteredEmployerItems: any;

  hire: boolean = true;
  droppedOut: boolean = true;
  reviewed: boolean = true;
  completed: boolean = true;
  pending: boolean = true;
  durationList = [{ Id: 1, Name: 'Today' }, { Id: 2, Name: 'Yesterday' }, { Id: 3, Name: 'Next 7 days' }, { Id: 4, Name: 'This Month' }, { Id: 5, Name: 'Next Month' }, { Id: 6, Name: 'Next 15 days' }]
  fromDate: any;
  toDate: any;
  today = new Date();
  Constants: any;
  SearchBy: any;
  tableDataSource: any;
  @ViewChild(MatPaginator, { static: true })
  ListPaginator: MatPaginator;
  IsLoading: boolean;
  displayedColumns: string[];
  ListSort: any;
  imageBasePath: string;

  dataxxxx: any;

  InternshipApplicationData: any;
  ApplicationStatusData: any;
  IsDirectMappingEnabled: any;
  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private translationLoaderService: FuseTranslationLoaderService,
    public zone: NgZone,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private service: InternshipApprovalService,
    private internshiOpportunityService: InternshipOpportunityService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
  ) {
    super(commonService, router, routeParams, snackBar, zone);
    // this.translationLoaderService.loadTranslations(english, guarati);
    this.InternshipApprovalForm = this.formBuilder.group({ filterText: '' });
    if (this.UserModel.IsDirectMappingEnabled == true) {
      this.tabIndex = 1
    }

  }
  changeTab(event) {

    console.log(event.index)
    this.tabIndex = event.index == undefined ? event : event.index;
    this.onLoadInternshipApprovalByCriteria();
  }

  refreshPage() {
    this.tabIndex;
    this.changeTab(this.tabIndex);
  }
  ngOnInit(): void {
    this.getEmployersByCriteria();
    // this.trigger.openMenu();
    this.IsDirectMappingEnabled = this.UserModel.IsDirectMappingEnabled;
    this.imageBasePath = this.Constants.CommonImageUrl.Account;
    // this.imageBasePath = environment.ImgBaseUrl;
    // show hide gloabl search 
    this.IsAllowedGloablSearch = this.Constants.PageArray.includes(window.location.pathname);

    this.service.getDropdownforInternshipOpportunity(this.UserModel).subscribe(results => {
      if (results[0].Success) {
        this.sectorList = results[0].Results;
      }

      // if (results[1].Success) {
      //   this.employerList = results[1].Results;
      //   this.filteredEmployerItems = this.employerList.slice();
      // }
      // this.SearchBy.PageIndex = 0; // delete after script changed
      // this.SearchBy.PageSize = 10; // delete after script changed

      // this.InternshipApprovalForm = this.formBuilder.group({ filterText: '' });

      //Load initial schools data
      this.onLoadInternshipApprovalByCriteria();

      this.InternshipApprovalForm.get('filterText').valueChanges.pipe(
        // if character length greater then 2
        filter(res => {
          // this.SearchBy.PageIndex = 0;

          if (res.length == 0) {
            this.SearchBy.Name = '';
            this.onLoadInternshipApprovalByCriteria();
            return false;
          }

          return res.length > 2
        }),

        // Time in milliseconds between key events
        debounceTime(650),

        // If previous query is diffent from current   
        distinctUntilChanged()

        // subscription for response
      ).subscribe((searchText: string) => {
        this.SearchBy.Name = searchText;
        this.onLoadInternshipApprovalByCriteria();
      });
    });
    this.InternshipApprovalFilterForm = this.ceateInternshipOpportunityFilterForm();
  }

  ngAfterViewInit() {
    this.tableDataSource.paginator = this.ListPaginator;
  }

  onPageIndexChanged(evt) {
    this.SearchBy.PageIndex = evt.pageIndex;
    this.SearchBy.PageSize = evt.pageSize;

    this.onLoadInternshipApprovalByCriteria();
  }

  onLoadInternshipApprovalByCriteria(): any {
    this.IsLoading = true;
    this.SearchBy.RoleCode = this.UserModel.RoleCode;
    this.SearchBy.SchoolGuid = (this.Constants.GlobalSchoolId == 'undefined' || this.Constants.GlobalSchoolId == 'null') ? null : this.Constants.GlobalSchoolId;
    this.SearchBy.AcademicYearGuid = (this.Constants.GlobalAcademicYearId == 'undefined' || this.Constants.GlobalAcademicYearId == 'null') ? null : this.Constants.GlobalAcademicYearId;
    this.SearchBy.SectorGuid = this.InternshipApprovalFilterForm.controls['SectorId'].value;
    this.SearchBy.EmployerGuid = this.InternshipApprovalFilterForm.controls['EmployerId'].value;
    this.SearchBy.StartDateTime = this.fromDate == 'undefined' ? '' : this.fromDate;//"2020-01-08";
    this.SearchBy.EndDatetime = this.toDate == 'undefined' ? '' : this.toDate;//"2022-08-08";

    // this.SearchBy.PageIndex = 1;
    console.log('search by StartDateTime =' + this.SearchBy.StartDateTime + ' search by EndDatetime =' + this.SearchBy.EndDatetime);
    this.service.GetAllByCriteria(this.SearchBy).subscribe(response => {
      for (let i = 0; response.Results.length > i; i++) {
        if (response.Results[i].hasOwnProperty("DateOfDropout")) {
          response.Results[i].DateOfDropout = response.Results[i].DateOfDropout;
        }
        else {
          response.Results[i].DateOfDropout = null;
        }

        if (response.Results[i].hasOwnProperty("IsCompleted")) {
          response.Results[i].IsCompleted = response.Results[i].IsCompleted;
        }
        else {
          response.Results[i].IsCompleted = null;
        }

        if (response.Results[i].hasOwnProperty("IsIRReviewed")) {
          response.Results[i].IsIRReviewed = response.Results[i].IsIRReviewed;
        }
        else {
          response.Results[i].IsIRReviewed = null;
        }
      }
      if (this.tabIndex == 0) {
        this.ApplicationStatusData = [];
        this.displayedColumns = ['StudentName', 'InternshipTitle', 'Actions'];
        this.dataxxxx = [];
        this.InternshipApplicationData = response.Results.filter(x => x.IsApplied == true && x.IsPlaced == false && x.IsApprovedByVT == false && x.IsApprovedByEmployer == false);
        this.dataxxxx = response.Results.filter(x => x.IsApplied == true && x.IsPlaced == false && x.IsApprovedByVT == false && x.IsApprovedByEmployer == false);

        const dateArray: GroupByDateArray[] = [];
        for (let index = 0; index < this.InternshipApplicationData.length; index++) {
          let xxx = dateArray.filter(x => x.date == this.InternshipApplicationData[index].InternshipStartDate);
          if (xxx == null || xxx.length == 0) {
            dateArray.push({
              date: this.InternshipApplicationData[index].InternshipStartDate,
              isGroupBy: true
            })
          }
        }
        const finalArrayInternshipApplication: (InternshipApprovalArray | GroupByDateArray)[] = [];
        finalArrayInternshipApplication.length = 0;
        for (let i = 0; i < dateArray.length; i++) {
          let yyd = dateArray.filter(x => x.date == dateArray[i].date);

          if (yyd != null) {
            yyd.forEach(element => {
              finalArrayInternshipApplication.push({
                date: element.date,
                isGroupBy: true
              });
            });
          }
          let asd = this.InternshipApplicationData.filter(x => x.InternshipStartDate == dateArray[i].date);

          if (asd != null || asd != '') {
            asd.forEach(element => {
              finalArrayInternshipApplication.push(element);
            });
          }
        }

        console.log(finalArrayInternshipApplication);
        this.tableDataSource.data = finalArrayInternshipApplication;
        //------------------------------------------
        this.tableDataSource.sort = this.ListSort;
        this.tableDataSource.paginator = this.ListPaginator;
        this.tableDataSource.filteredData = this.tableDataSource.data;
        this.SearchBy.TotalResults = response.TotalResults;

        // setTimeout(() => {
        //   this.ListPaginator.pageIndex = this.SearchBy.PageIndex;
        //   this.ListPaginator.length = this.SearchBy.TotalResults;
        // });

        this.zone.run(() => {
          if (this.tableDataSource.data.length == 0) {
            this.showNoDataFoundSnackBar();
          }
        });
        this.IsLoading = false;
        //------------------------------------------
      } else if (this.tabIndex == 1) {
        this.InternshipApplicationData = [];
        this.dataxxxx = [];
        this.ApplicationStatusData = [];

        this.displayedColumns = ['StudentName', 'InternshipTitle', 'Status', 'Actions'];
        this.ApplicationStatusData = response.Results.filter(x => x.IsApplied == true && x.IsApprovedByVT == true);
        this.dataxxxx = response.Results.filter(x => x.IsApplied == true && x.IsApprovedByVT == true);
        const dateArray: GroupByDateArray[] = [];
        for (let index = 0; index < this.ApplicationStatusData.length; index++) {
          let xxx = dateArray.filter(x => x.date == this.ApplicationStatusData[index].InternshipStartDate);
          if (xxx == null || xxx.length == 0) {
            dateArray.push({
              date: this.ApplicationStatusData[index].InternshipStartDate,
              isGroupBy: true
            })
          }
        }
        console.log(dateArray);
        const finalArrayApplicationStatus: (InternshipApprovalArray | GroupByDateArray)[] = [];
        finalArrayApplicationStatus.length = 0;
        for (let i = 0; i < dateArray.length; i++) {
          let yyd = dateArray.filter(x => x.date == dateArray[i].date);
          if (yyd != null) {
            yyd.forEach(element => {
              finalArrayApplicationStatus.push({
                date: element.date,
                isGroupBy: true
              });
            });
          }
          let asd = this.ApplicationStatusData.filter(x => x.InternshipStartDate == dateArray[i].date);
          if (asd != null || asd != '') {
            asd.forEach(element => {
              finalArrayApplicationStatus.push(element);
            });
          }
        }

        console.log(finalArrayApplicationStatus);
        this.tableDataSource.data = finalArrayApplicationStatus;
        //------------------------------------------
        this.tableDataSource.sort = this.ListSort;
        this.tableDataSource.paginator = this.ListPaginator;
        this.tableDataSource.filteredData = this.tableDataSource.data;
        this.SearchBy.TotalResults = response.TotalResults;

        // setTimeout(() => {
        //   this.ListPaginator.pageIndex = this.SearchBy.PageIndex;
        //   this.ListPaginator.length = this.SearchBy.TotalResults;
        // });

        this.zone.run(() => {
          if (this.tableDataSource.data.length == 0) {
            this.showNoDataFoundSnackBar();
          }
        });
        this.IsLoading = false;
        //------------------------------------------
      }


      // this.tableDataSource.data = response.Results.sort((a, b) => a.InternshipStartDate > b.InternshipStartDate ? -1 : 1);

      // this.tableDataSource.data = response.Results;
      // this.tableDataSource.sort = this.ListSort;
      // this.tableDataSource.paginator = this.ListPaginator;
      // this.tableDataSource.filteredData = this.tableDataSource.data;
      // this.SearchBy.TotalResults = response.TotalResults;

      // setTimeout(() => {
      //   this.ListPaginator.pageIndex = this.SearchBy.PageIndex;
      //   this.ListPaginator.length = this.SearchBy.TotalResults;
      // });

      // this.zone.run(() => {
      //   if (this.tableDataSource.data.length == 0) {
      //     this.showNoDataFoundSnackBar();
      //   }
      // });
      // this.IsLoading = false;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });

    // group by 

  }
  showNoDataFoundSnackBar() {
    throw new Error('Method not implemented.');
  }

  // onDeleteSchool(internshipId: string) {
  //   this.dialogService
  //     .openConfirmDialog(this.Constants.Messages.DeleteConfirmationMessage)
  //     .afterClosed()
  //     .subscribe(confResp => {
  //       if (confResp) {
  //         this.service.deleteSchoolById(internshipId)
  //           .subscribe((schoolResp: any) => {

  //             this.zone.run(() => {
  //               if (schoolResp.Success) {
  //                 this.showActionMessage(
  //                   this.Constants.Messages.RecordDeletedMessage,
  //                   this.Constants.Html.SuccessSnackbar
  //                 );
  //               }
  //               this.ngOnInit();
  //             }, error => {
  //               console.log('Internship deletion errors =>', error);
  //             });

  //           });
  //         this.ngOnInit();
  //       }
  //     });
  // }
  // showActionMessage(RecordDeletedMessage: any, SuccessSnackbar: any) {
  //   throw new Error('Method not implemented.');
  // }

  onClickHireStudent(studentId: string, internshipId: string) {
    this.service.hireStudent(studentId, internshipId).subscribe(response => {
      if (response.Success) {
        this.zone.run(() => {
          this.showActionMessage(
            response.Messages[0],
            this.Constants.Html.SuccessSnackbar
          );
          this.onLoadInternshipApprovalByCriteria();
          // this.router.navigate([RouteConstants.Student.List]);
        });
      } else {
        this.zone.run(() => {
          this.showActionMessage(
            response.Messages[0],
            this.Constants.Html.ErrorSnackbar,
          );
          this.onLoadInternshipApprovalByCriteria();
        });
      }

    });
  }

  onClickSendToEmployer(studentId: string, internshipId: string) {
    this.service.sendToEmployer(studentId, internshipId).subscribe(response => {
      if (response.Success) {
        this.zone.run(() => {
          this.showActionMessage(
            response.Messages[0],
            this.Constants.Html.SuccessSnackbar
          );
          this.onLoadInternshipApprovalByCriteria();
          // this.router.navigate([RouteConstants.Student.List]);
        });
      } else {
        this.zone.run(() => {
          this.showActionMessage(
            response.Messages[0],
            this.Constants.Html.ErrorSnackbar,
          );
          this.onLoadInternshipApprovalByCriteria();
        });
      }

    });
  }
  exportExcel(): void {
    this.IsLoading = true;
    this.SearchBy.PageIndex = 0;
    this.SearchBy.PageSize = 10000;

    this.service.GetAllByCriteria(this.SearchBy).subscribe(response => {
      response.Results.forEach(
        function (obj) {
          if (obj.hasOwnProperty('IsActive')) {
            obj.IsActive = obj.IsActive ? 'Yes' : 'No';
          }
          if (obj.hasOwnProperty('IsImplemented')) {
            obj.IsImplemented = obj.IsImplemented ? 'Yes' : 'No';
          }
          delete obj.SchoolId;
          delete obj.TotalRows;
        });

      this.exportExcelFromTable(response.Results, "Schools");

      this.IsLoading = false;
      // this.SearchBy.PageIndex = 0;
      // this.SearchBy.PageSize = 10;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }
  exportExcelFromTable(Results: any, arg1: string) {
    throw new Error('Method not implemented.');
  }

  onLoadSchoolVTPSectorsByFilters(): any {
    // this.SearchBy.PageIndex = 0;
    this.onLoadInternshipApprovalByCriteria();
  }

  resetFilters(): void {
    this.InternshipApprovalFilterForm.reset();
    // this.InternshipApprovalFilterForm.get('AcademicYearId').setValue(this.currentAcademicYearId);
    this.fromDate = undefined;
    this.toDate = undefined;
    this.onLoadInternshipApprovalByCriteria();
  }

  onChangeDuration(evt) {

    let duration = evt;
    switch (duration) {

      case 1:

        let todaysDate = this.datePipe.transform(this.today, 'yyyy-MM-dd');
        this.fromDate = todaysDate;
        this.toDate = todaysDate;
        console.log("Today", this.fromDate, this.toDate);
        break;
      case 2:
        var tdate = new Date();
        tdate.setDate(tdate.getDate() - 1);
        let yesterdaysDate = this.datePipe.transform(tdate, 'yyyy-MM-dd');
        this.fromDate = yesterdaysDate;
        this.toDate = yesterdaysDate;
        console.log("Yesterday", this.fromDate, this.toDate);
        break;
      case 3:
        let wdate: Date = new Date();
        wdate.setDate(wdate.getDate() + 6);
        // date.setHours(0);
        // date.setMinutes(0);
        // date.setSeconds(0);
        // date.setMilliseconds(0);
        // let datePipe: DatePipe = new DatePipe('en-US');
        // console.log(datePipe.transform(date, 'short'));
        this.fromDate = this.datePipe.transform(this.today, 'yyyy-MM-dd');
        this.toDate = this.datePipe.transform(wdate, 'yyyy-MM-dd');
        console.log("Next 7 days", this.fromDate, this.toDate);
        break;
      case 4:
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.fromDate = this.datePipe.transform(firstDay, 'yyyy-MM-dd');
        this.toDate = this.datePipe.transform(lastDay, 'yyyy-MM-dd');
        console.log("This Month", this.fromDate, this.toDate);
        break;

      case 5:
        const cdate = new Date();
        const firstDayPrevMonth = new Date(cdate.getFullYear(), cdate.getMonth() + 1, 1);
        cdate.setDate(cdate.getDate() + 30);
        const lastDayPrevMonth = new Date(cdate.getFullYear(), cdate.getMonth(), 1);
        this.fromDate = this.datePipe.transform(this.today, 'yyyy-MM-dd');
        this.toDate = this.datePipe.transform(cdate, 'yyyy-MM-dd');
        console.log("Next Month", this.fromDate, this.toDate);
        break;

      case 6:
        const nFdate = new Date();

        nFdate.setDate(nFdate.getDate() + 14);
        this.fromDate = this.datePipe.transform(this.today, 'yyyy-MM-dd');
        this.toDate = this.datePipe.transform(nFdate, 'yyyy-MM-dd');
        console.log("Next 15 days", this.fromDate, this.toDate);
        break;
      default:
        console.log("Invalid");
    }

  }
  //Create SchoolVtpSectorFilter form and returns {FormGroup}
  ceateInternshipOpportunityFilterForm(): FormGroup {
    return this.formBuilder.group({
      SectorId: new FormControl(),
      EmployerId: new FormControl(),
      DurationId: new FormControl(),
    });
  }

  onClickViewRecord(studentId: string, internshipId: string) {

    let viewData = this.dataxxxx.filter(x => x.StudentId == studentId && x.InternshipId == internshipId);

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.maxWidth = "80%";
    dialogConfig.width = "80%";
    //  dialogConfig.height="765px";
    dialogConfig.height = "auto";
    dialogConfig.data = viewData;//this.staffResulatData;//
    this.dialog.open(ViewInternshipApprovalComponent, dialogConfig);
  }

  onClickMarkInternshipApprovalCompleted(studentId: string, internshipId: string, actualEndDate: any) {
    let studentData: any = {};
    studentData.StudentId = studentId;
    studentData.InternshipId = internshipId;

    studentData.ActualEndDate = actualEndDate;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.maxWidth = "80%";
    dialogConfig.width = "80%";
    //  dialogConfig.height="765px";
    dialogConfig.height = "auto";
    dialogConfig.data = studentData;
    this.dialog.open(MarkInternshipCompletedComponent, dialogConfig).afterClosed()
      .subscribe(InternshipCompletionData => {
        this.service.setCompletionStatus(studentId, internshipId, InternshipCompletionData.CompletionStatus, InternshipCompletionData.Remarks, InternshipCompletionData.CompletionDate).subscribe(response => {
          if (response.Success) {
            this.zone.run(() => {
              this.showActionMessage(
                response.Messages[0],
                this.Constants.Html.SuccessSnackbar
              );
              this.onLoadInternshipApprovalByCriteria();
              //  this.changeTab(1);
            });
          } else {
            this.zone.run(() => {
              this.showActionMessage(
                response.Messages[0],
                this.Constants.Html.ErrorSnackbar,
              );
              this.changeTab(1);
            });
          }
        });

      });

  }
  onClickDroupoutInternship(studentId: string, internshipId: string) {
    let studentData: any = {};
    studentData.StudentId = studentId;
    studentData.InternshipId = internshipId;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.maxWidth = "50%";
    dialogConfig.width = "50%";
    //  dialogConfig.height="765px";
    dialogConfig.height = "auto";
    dialogConfig.data = studentData;
    this.dialog.open(DropOutForInternshipComponent, dialogConfig).afterClosed()
      .subscribe(dropOutData => {

        // console.log(dropOutData.DropOutDate, dropOutData.DropOutReason);

        this.service.setDropoutDateReason(studentId, internshipId, this.DateFormatPipe.transform(dropOutData.DropOutDate, this.Constants.ServerDateFormat), dropOutData.DropOutReason).subscribe((response) => {
          if (response.Success) {
            this.zone.run(() => {
              this.showActionMessage(
                response.Messages[0],
                this.Constants.Html.SuccessSnackbar
              );
              this.onLoadInternshipApprovalByCriteria();
              //this.changeTab(1);
            });
          } else {
            this.zone.run(() => {
              this.showActionMessage(
                response.Messages[0],
                this.Constants.Html.ErrorSnackbar,
              );
              this.changeTab(1);
            });
          }
        });

      });
  }

  onClickStudentInternshipDateChange(internshipApp: any,) {
    let studentData: any;
    studentData = internshipApp;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.maxWidth = "50%";
    dialogConfig.width = "50%";
    //  dialogConfig.height="765px";
    dialogConfig.height = "auto";
    dialogConfig.data = studentData;
    this.dialog.open(StudentInternshipDateChangeComponent, dialogConfig).afterClosed()
      .subscribe(dropOutData => {

        console.log(dropOutData);
        var data = {
          InternshipId: studentData.InternshipId,
          StudentId: studentData.StudentId,
          IsApplied: studentData.IsApplied,
          IsPlaced: studentData.IsPlaced,
          IsApprovedByVT: studentData.IsApprovedByVT,
          IsApprovedByEmployer: studentData.IsApprovedByEmployer,
          AuthUserId: this.UserModel.UserTypeId,
          ActualStartDate: this.DateFormatPipe.transform(dropOutData.ActualStartDate, this.Constants.ServerDateFormat)
        }
        console.log(data)
        this.service.UpdateStudentInternshipsStatus(data).subscribe((response) => {
          if (response.Success) {
            this.zone.run(() => {
              this.showActionMessage(
                response.Messages[0],
                this.Constants.Html.SuccessSnackbar
              );
              this.onLoadInternshipApprovalByCriteria();
              //this.changeTab(1);
            });
          } else {
            this.zone.run(() => {
              this.showActionMessage(
                response.Messages[0],
                this.Constants.Html.ErrorSnackbar,
              );
              this.changeTab(1);
            });
          }
        });

      });
  }
  onClickReviewReport(studentId: string, internshipId: string, isCompleted: string, isIRReviewed: string) {
    let studentData: any = {};
    studentData.StudentId = studentId;
    studentData.InternshipId = internshipId;
    studentData.IsCompleted = isCompleted;
    studentData.IsIRReviewed = isIRReviewed;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.maxWidth = "80%";
    dialogConfig.width = "80%";
    //  dialogConfig.height="765px";
    dialogConfig.height = "auto";
    dialogConfig.data = studentData;
    // debugger;
    this.dialog.open(ReviewInternshipReportComponent, dialogConfig).afterClosed()
      .subscribe(AcknowledgeData => {

        // console.log(AcknowledgeData.ReportViewed);
        // if (newCollegeAdded.AddedNewCollge == true) {
        // this.ngOnInit();
        if (AcknowledgeData.ReportViewed == 'true' && AcknowledgeData.IsReviewReport == true) {
          var currentDate = new Date();
          var xxx = 'Internship Report reviewed BY :' + this.UserModel.RoleCode + ' ' + this.UserModel.UserName + ', On Dated : ' + this.DateFormatPipe.transform(currentDate, this.Constants.ServerDateFormat);
          this.service.setIRReViewedStatus(studentId, internshipId, AcknowledgeData.ReportViewed, xxx).subscribe((response) => {
            if (response.Success) {
              this.zone.run(() => {
                this.showActionMessage(
                  response.Messages[0],
                  this.Constants.Html.SuccessSnackbar
                );
                this.onLoadInternshipApprovalByCriteria();
                //  this.changeTab(1);
              });
            } else {
              this.zone.run(() => {
                this.showActionMessage(
                  response.Messages[0],
                  this.Constants.Html.ErrorSnackbar,
                );
                this.changeTab(1);
              });
            }
          });

        } else {
          this.zone.run(() => {
            //@ts-ignore
            this.snackBar.open("No data found..", "Dismiss", {
              duration: 5000,
              verticalPosition: 'bottom',
              horizontalPosition: 'center',
              panelClass: ["error-snackbar"]
            });
          });
          this.showActionMessage(
            "Final Internship data is not available for report review so you can not mark it as Review Completd...!!",
            this.Constants.Html.ErrorSnackbar,
          );
          return false;
        }
      });
  }

  isGroup(index, item): boolean {
    return item.isGroupBy;
  }

  getEmployersByCriteria(): any {
    debugger;
    this.IsLoading = true;
    let data = {
      VTId: this.UserModel.UserTypeId,
      VTGuid: this.UserModel.UserTypeId,
      UserTypeId: null,
      RoleCode: this.UserModel.RoleCode,
      PageIndex: 0,
      PageSize: 10000,
      Name: null,
      CharBy: null,
      SectorGuid: null,
      StartDateTime: null,
      EndDatetime: null,
      IsActive: null
    }
    this.internshiOpportunityService.GetEmployersByCriteria(data).subscribe(response => {
      debugger;
      let empList = response.Results;
      let arr = [] as any;
      for (let i = 0; i < empList.length; i++) {
        let xxx = {
          Id: empList[i].EmployerId,
          Name: empList[i].OrganizationName,
          Description: '',
          IsSelected: false,
          IsDisabled: false,
          SequenceNo: 1
        }
        arr.push(xxx);
      }
      this.employerList = arr;
      this.filteredEmployerItems = this.employerList.slice()
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }
}

interface GroupByDateArray {
  date: string;
  isGroupBy: boolean;
}

interface InternshipApprovalArray {
  StudentId: string;
  StudentName: string;
  StudentProfilePhoto: string;
  Mobile: string;
  SectorId: string;
  SectorName: string;
  InternshipId: string;
  InternshipTitle: string;
  InternshipStartDate: string;
  EmployerName: string;
  EmpPhotoUrl: string;
  VTId: string;
  IsApplied: boolean;
  IsPlaced: boolean;
  IsApprovedByVT: boolean;
  IsApprovedByEmployer: boolean;
  ClassId: string;
  ClassName: string;
  Status: string;
  isGroupBy: boolean;
}