export const locale = {
    lang: 'en',
    data: {
        'VC': {
            'VC': 'VOCATIONAL COORDINATORS',
            'ADD_NEW_VC': 'ADD',
            'SEARCH_FOR_A_VC': 'Search for a vocational coordinator',
        }
    }
};

