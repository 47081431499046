<div id="employer-listing" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/employer-notice-board'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        Edit Notice
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        Add New Notice
                    </div>
                    <div class="subtitle secondary-text">
                        <span>Notice Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <div fxLayout="row" class="add-edit-button" fxLayoutAlign="flex-start" fxFlex="0 1 calc(30%)"
                fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="100%">
                <button mat-raised-button class="save-school-button btn-outline-dark" style="float:right;"
                    [routerLink]="'/employer-notice-board'"><span>Manage Notice</span><svg
                        xmlns="http://www.w3.org/2000/svg" class="ms-2" width="20" height="20" viewBox="0 0 24 24"
                        stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"></path>
                        <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3"></path>
                        <line x1="16" y1="5" x2="19" y2="8"></line>
                    </svg>
                </button>

                <button mat-raised-button class="add-school-button btn-warning mls"
                    *ngIf="PageRights.ActionType ==='new'" (click)="saveOrCreateOrUpdateEmployerNoticeBoard('addNB')">
                    <span>Add Notice</span>
                </button>

                <button mat-raised-button class="save-school-button btn-warning mls"
                    *ngIf="PageRights.ActionType ==='edit'" (click)="saveOrCreateOrUpdateEmployerNoticeBoard('editNB')">
                    <span>Save</span>
                </button>

                <button mat-raised-button class="add-edit-button "
                    (click)="saveAndAddAnotherEmployerNoticeBoard('addAnotherNB')" style="margin-left:10px"
                    *ngIf="PageRights.ActionType ==='new'">
                    <span>Save & Add Another</span>
                </button>
            </div>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">


                <form name="employerNoticeBoardForm" [formGroup]="employerNoticeBoardForm"
                    class="school w-100-p p-24 mr-24" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex
                    fusePerfectScrollbar>
                    <!-- <mat-tab label="Basic Info"> -->
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="25" fxFlex.lt-sm="100">
                            <mat-label>Publish Date</mat-label>
                            <input matInput [matDatepicker]="picker1" name="PublishDate" formControlName="PublishDate"
                                [min]="CurrentDate" [disabled]="PageRights.IsReadOnly" required (dateInput)="onChangePublishDate($event)">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                            <mat-error *ngIf="employerNoticeBoardForm.controls.PublishDate.invalid">
                                {{getErrorMessage(employerNoticeBoardForm, 'PublishDate')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Expiry Date</mat-label>
                            <input matInput [matDatepicker]="picker" name="ExpiryDate" [min]="CurrentDate"
                                formControlName="ExpiryDate" [disabled]="PageRights.IsReadOnly"  (dateInput)="onChangeExpDate($event)" required>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="employerNoticeBoardForm.controls.ExpiryDate.invalid">
                                {{getErrorMessage(employerNoticeBoardForm, 'ExpiryDate')}}
                            </mat-error>
                        </mat-form-field>
                    </div>



                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Notice Heading</mat-label>
                            <input matInput name="NoticeTitle" formControlName="NoticeTitle" required>
                            <mat-error *ngIf="employerNoticeBoardForm.controls.NoticeTitle.invalid">
                                <!-- {{getErrorMessage(studentNoticeBoardForm, 'NoticeTitle')}} -->Notice Heading is
                                required
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" class="pr-1" fxFlex="100" fxFlex.lt-md="75"
                            fxFlex.lt-sm="100">
                            <mat-label>Notice</mat-label>
                            <textarea matInput formControlName="NoticeDescription" required></textarea>
                            <mat-error *ngIf="employerNoticeBoardForm.controls.NoticeDescription.invalid">
                                <!-- {{getErrorMessage(studentNoticeBoardForm, 'NoticeDescription')}} -->Notice is
                                required
                            </mat-error>
                        </mat-form-field>
                    </div>


                    <!-- </mat-tab>
                    </mat-tab-group> -->
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>