export class EmployerNoticeBoardModel {
    NoticeId: String
    PublishDate: string;
    ExpiryDate: string;
    NoticeDescription: string;
    StudentNoticeBoardId: string;
    RequestType: any;
    AuthUserId: string; // New Parameters
    IsActive: boolean;
    NoticeType: string;
    NoticeTitle: string;

    constructor(employerNoticeNoticeBoardItem?: any) {
        employerNoticeNoticeBoardItem = employerNoticeNoticeBoardItem || {};
        //this.NoticeId = studentNoticeBoardItem.NoticeId || FuseUtils.NewGuid();
        this.NoticeId = employerNoticeNoticeBoardItem.NoticeId || '00000000-0000-0000-0000-000000000000';
        this.PublishDate = employerNoticeNoticeBoardItem.SectorId || '';
        this.ExpiryDate = employerNoticeNoticeBoardItem.AuthUserId || '';
        this.NoticeDescription = employerNoticeNoticeBoardItem.Notice || '';
        this.RequestType = 0; // New Parameters
        this.AuthUserId = employerNoticeNoticeBoardItem.AuthUserId || '';
        this.IsActive = employerNoticeNoticeBoardItem.IsActive || true;
        this.NoticeType = employerNoticeNoticeBoardItem.NoticeType || 'Employer';
        this.NoticeTitle = employerNoticeNoticeBoardItem.NoticeType || '';


    }
}



