<div id="products" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <!-- {{ 'Internship Opportunity' | translate }} -->
                    Gratitude for Employer
                    <!-- <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a> -->
                    <button mat-icon-button class="refresh-data mt-24 mt-md-0" (click)="refreshPage()">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}"
                            style="color: #a4ef13;margin-top: -15% !important;">
                            refresh</mat-icon>
                    </button>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <form [formGroup]="InternshipApprovalForm">
                    <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon>search</mat-icon>
                        <input formControlName="filterText" placeholder="Search for Gratitude">
                    </div>
                </form>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">
                <!-- <button mat-raised-button [routerLink]="['./','new']" skipLocationChange="true" replaceUrl="false"
                    class="add-internship-opportunity-button fuse-white mt-24 mt-md-0 btn-warning">
                    <span>Add New Opportunity</span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="ms-2" width="20" height="20" viewBox="0 0 24 24"
                        stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="12" y1="5" x2="12" y2="19" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                    </svg>
                </button> -->

            </div>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->
        <div class="padding-bottom">
            <mat-tab-group selectedIndex="0" (selectedTabChange)="changeTab($event)">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>New Request</span>
                    </ng-template>

                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Approved</span>
                    </ng-template>

                </mat-tab>
            </mat-tab-group>
        </div>
        <!-- Filter Section -->
        <!-- <div class="filter-content-card">
            <div class="filter-content">
                <form name="InternshipApprovalFilterForm" [formGroup]="InternshipApprovalFilterForm"
                    class="vc-issue-report" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Sector</mat-label>
                            <mat-select formControlName="SectorId" name="sectorId" appearance="outline">
                                <mat-option [value]="sectorItem.Id" *ngFor="let sectorItem of sectorList">
                                    {{ sectorItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label> Employer</mat-label>

                            <mat-select formControlName="EmployerId" name="employerId" appearance="outline"
                                #SelectEmployer>
                                <mat-select-filter class="select-filter" *ngIf="SelectEmployer.focused"
                                    [array]="employerList" (filteredReturn)="this.filteredEmployerItems = $event"
                                    [displayMember]="'Name'">
                                </mat-select-filter>

                                <mat-option [value]="employerItem.Id"
                                    *ngFor="let employerItem of filteredEmployerItems">
                                    {{ employerItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Duration</mat-label>
                            <mat-select formControlName="DurationId" name="durationId" appearance="outline">
                                <mat-option [value]="item.Id" *ngFor="let item of durationList">
                                    {{ item.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="clear-filter pr-1" fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="onLoadSchoolVTPSectorsByFilters()"><span>SEARCH</span></button>
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="resetFilters()"><span>CLEAR</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div> -->
        <!-- /Filter Section -->

        <!-- CONTENT CARD -->
        <div class="content-card">
            <div class="asd" *ngIf="tabIndex == 0" style="overflow-y: auto;">
                <mat-table class=" xxx " [dataSource]="tableDataSource" matSort fusePerfectScrollbar
                    [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                    <!-- StudentName Column -->
                    <ng-container matColumnDef="StudentName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>
                            STUDENT NAME
                            <!--  StudentName -->
                        </mat-header-cell>
                        <mat-cell *matCellDef="let newRequest">
                            <div [routerLink]="['/vt-student-details', newRequest.StudentId]">
                                <span>
                                    <img *ngIf="newRequest.StudentPhoto!=''"
                                        [src]="imageBasePath +newRequest.StudentPhoto" width="50" height="50"
                                        style="border-radius: 50%;margin-right: 10%;" />
                                    <mat-icon *ngIf="newRequest.StudentPhoto==''">face</mat-icon>
                                    <!-- <mat-icon>face</mat-icon> -->
                                </span>
                                <!-- style="margin-left: 10%;" -->
                                <p class="text-truncate" style="margin-left: 5%;">
                                    <b>{{newRequest.StudentName}}</b><br>
                                    {{newRequest.Mobile }}
                                    <!-- {{newRequest.Mobile | slice:0:4}}XXXXXX -->
                                </p>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <!-- Messages Column -->
                    <ng-container matColumnDef="Messages">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            MESSAGES
                        </mat-header-cell>
                        <mat-cell *matCellDef="let newRequest;let i = index;">
                            <!-- <p class="text-truncate">
                                <read-more [text]="newRequest.GratitudeDesc" [maxLength]="100"></read-more>
                            </p> -->


                            <p class="fs-13 text-black fw-400 read-more-less-text mb-0 text-wrap max-w-400 w-300-sm"
                                style="display: inline-block;margin: 0px;word-break: break-word;">
                                {{ newRequest.show ?
                                newRequest.GratitudeDesc:(newRequest.GratitudeDesc | slice:0:100)}}
                                <button class="show-less-button" type="button"
                                    *ngIf="newRequest.GratitudeDesc.length > 50;"
                                    (click)="newRequest.show = !newRequest.show"
                                    style="border: none;background: #fff;color:#00f">
                                    {{ ((newRequest.show)) ? 'Read less':'Read more' }}
                                </button>
                            </p>

                        </mat-cell>
                    </ng-container>

                    <!-- EmployerName Column -->
                    <ng-container matColumnDef="EmployerName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
                            EMPLOYER NAME
                        </mat-header-cell>
                        <mat-cell *matCellDef="let newRequest" fxHide fxShow.gt-xs>

                            <div style="margin-right: 20px;"><span class="empInfo" style="border-radius: 10px!important;background-color: #e8e8e8;font-size: 18px;font-weight: 800;
                           padding-left: 15px;
    padding-right: 15px;padding-top: 10px!important;
    padding-bottom: 10px!important;"
                                    [routerLink]="['/employer-details', newRequest.GratitudeEmployerBranchId]">{{newRequest.EmployerName
                                    | slice :0:1 | uppercase }}</span>
                            </div>
                            <div [routerLink]="['/employer-details', newRequest.GratitudeEmployerBranchId]">
                                <p class="text-p">{{newRequest.EmployerName}}</p>
                                <!-- <p class="text-p1"> <svg class="ml-1" data-name="Group 1" xmlns="http://www.w3.org/2000/svg"
                                    width="15" height="25.19" viewBox="0 0 24 23.25">
                                    <path
                                        d="M23.823,11.991a.466.466,0,0,0,0-.731L21.54,8.7c-.12-.122-.12-.243-.12-.486L21.779,4.8c0-.244-.121-.609-.478-.609L18.06,3.46c-.12,0-.36-.122-.36-.244L16.022.292a.682.682,0,0,0-.839-.244l-3,1.341a.361.361,0,0,1-.48,0L8.7.048a.735.735,0,0,0-.84.244L6.183,3.216c0,.122-.24.244-.36.244L2.58,4.191a.823.823,0,0,0-.48.731l.36,3.412a.74.74,0,0,1-.12.487L.18,11.381a.462.462,0,0,0,0,.732l2.16,2.437c.12.124.12.243.12.486L2.1,18.449c0,.244.12.609.48.609l3.24.735c.12,0,.36.122.36.241l1.68,2.924a.683.683,0,0,0,.84.244l3-1.341a.353.353,0,0,1,.48,0l3,1.341a.786.786,0,0,0,.839-.244L17.7,20.035c.122-.124.24-.243.36-.243l3.24-.734c.24,0,.48-.367.48-.609l-.361-3.413a.726.726,0,0,1,.121-.485Z"
                                        fill="#0D6EFD"></path>
                                    <path data-name="Path" d="M4.036,10,0,5.8,1.527,4.2,4.036,6.818,10.582,0,12,1.591Z"
                                        transform="translate(5.938 6.625)" fill="#fff"></path>
                                </svg>{{newRequest.EmployerName1}}</p> -->
                            </div>

                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Actions" stickyEnd *ngIf="tabIndex === 0">
                        <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                        <mat-cell *matCellDef="let newRequest" fxShow.gt-xs>
                            <span class="btn-approve-reject">
                                <button mat-raised-button *ngIf="tabIndex === 0"
                                    (click)="RejectGratitude(newRequest.GratitudeId)" class="btn-send-to-employer">
                                    Reject
                                </button>
                                <button mat-raised-button *ngIf="tabIndex === 0"
                                    (click)="AcceptGratitude(newRequest.GratitudeId)" class="btn-send-to-employer">
                                    Approve

                                </button>
                            </span>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                    <mat-row *matRowDef="let newRequest; columns: displayedColumns;" class="newRequest" matRipple>
                    </mat-row>

                    <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let newRequest; columns: displayedColumns;"></tr> -->

                    <!-- Group header -->
                    <ng-container matColumnDef="groupHeader">
                        <td colspan="999" mat-cell *matCellDef="let groupBy"><strong>
                                {{groupBy.date | date}}</strong></td>
                    </ng-container>

                    <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"> </tr>

                </mat-table>

                <!-- <mat-paginator #ListPaginator [length]="SearchBy.TotalResults" [pageIndex]="SearchBy.PageIndex"
                [pageSize]="SearchBy.PageSize" [pageSizeOptions]="SearchBy.PageSizeOptions"
                [showFirstLastButtons]="SearchBy.ShowFirstLastButtons" (page)="onPageIndexChanged($event)">
            </mat-paginator> -->
                <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0"
                    [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>
            </div>

            <div class="yyy" *ngIf="tabIndex == 1" style="overflow-y: auto;">
                <mat-table class=" xxx " [dataSource]="tableDataSource1" matSort fusePerfectScrollbar
                    [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                    <!-- StudentName Column -->
                    <ng-container matColumnDef="StudentName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>
                            STUDENT NAME
                            <!--  StudentName -->
                        </mat-header-cell>
                        <mat-cell *matCellDef="let gratitudeApprove">
                            <div [routerLink]="['/vt-student-details', gratitudeApprove.StudentId]">
                                <span>
                                    <img *ngIf="gratitudeApprove.StudentPhoto!=''"
                                        [src]="imageBasePath +gratitudeApprove.StudentPhoto" width="50" height="50"
                                        style="border-radius: 50%;margin-right: 10%;" />
                                    <mat-icon *ngIf="gratitudeApprove.StudentPhoto==''">face</mat-icon>
                                    <!-- <mat-icon>face</mat-icon> -->
                                </span>
                                <!-- style="margin-left: 10%;" -->
                                <p class="text-truncate" style="margin-left: 5%;">
                                    <b>{{gratitudeApprove.StudentName}}</b><br>
                                    {{gratitudeApprove.Mobile}}
                                </p>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <!-- Messages Column -->
                    <ng-container matColumnDef="Messages">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.gt-xs>
                            MESSAGES
                        </mat-header-cell>
                        <mat-cell *matCellDef="let gratitudeApprove;let i = index;" fxShow.gt-xs>
                            <p class="fs-13 text-black fw-400 read-more-less-text mb-0 text-wrap max-w-400 w-300-sm"
                                style="display: inline-block;margin: 0px;word-break: break-word;">
                                <!-- {{gratitudeApprove.GratitudeDesc}} -->
                                {{ gratitudeApprove.show ?
                                gratitudeApprove.GratitudeDesc:(gratitudeApprove.GratitudeDesc | slice:0:100)}}
                                <!-- <span *ngIf="gratitudeApprove.GratitudeDesc.length > 50;">... </span> -->
                                <button class="show-less-button" type="button"
                                    *ngIf="gratitudeApprove.GratitudeDesc.length > 50;"
                                    (click)="gratitudeApprove.show = !gratitudeApprove.show"
                                    style="border: none;background: #fff;color:#00f">
                                    {{ ((gratitudeApprove.show)) ? 'Read less':'Read more' }}
                                </button>
                            </p>

                        </mat-cell>
                    </ng-container>

                    <!-- EmployerName Column -->
                    <ng-container matColumnDef="EmployerName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
                            EMPLOYER NAME
                        </mat-header-cell>
                        <mat-cell *matCellDef="let gratitudeApprove" fxHide fxShow.gt-xs>
                            <div style="margin-right: 20px;"><span class="empInfo" style="border-radius: 10px!important;background-color: #e8e8e8;font-size: 18px;font-weight: 800;
                       padding-left: 15px;
padding-right: 15px;padding-top: 10px!important;
padding-bottom: 10px!important;">{{gratitudeApprove.EmployerName | slice :0:1 | uppercase }}</span>
                            </div>
                            <div>
                                <p class="text-p">{{gratitudeApprove.EmployerName}}</p>
                            </div>

                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                    <mat-row *matRowDef="let gratitudeApprove; columns: displayedColumns;" class="gratitudeApprove"
                        matRipple>
                    </mat-row>

                    <!-- Group header -->
                    <ng-container matColumnDef="groupHeader">
                        <td colspan="999" mat-cell *matCellDef="let groupBy"><strong>
                                {{groupBy.date | date}}</strong></td>
                    </ng-container>

                    <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"> </tr>
                </mat-table>

                <mat-paginator #ListPaginator [length]="tableDataSource1.filteredData.length" [pageIndex]="0"
                    [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>
            </div>
        </div>


        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>