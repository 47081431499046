import { FuseUtils } from '@fuse/utils';

export class HeadMasterModel {
    HMId: string;
    // VTId: string; //old
    SchoolId: string;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    FullName: string;
    Mobile: string;
    Mobile1: string;
    // Email: string; //old
    EmailId: string; //new
    Gender: string; 
    YearsInSchool: number; //old
    //DateOfJoiningSchool: Date; // old
    DateOfResignationFromSchool: Date;  // old
    AcademicYearId:string; //new
    DateOfJoiningSchool:Date; //new
    StateCode: string; // New Parameters
    
    IsActive: boolean;
    RequestType: any;

    constructor(headMasterItem?: any) {
        headMasterItem = headMasterItem || {};

        this.HMId = headMasterItem.HMId || FuseUtils.NewGuid();
        // this.VTId = headMasterItem.VTId || FuseUtils.NewGuid(); //old
        this.SchoolId = headMasterItem.VTSchoolSectorId || '';
        this.FirstName = headMasterItem.FirstName || '';
        this.MiddleName = headMasterItem.MiddleName || '';
        this.LastName = headMasterItem.LastName || '';
        this.FullName = headMasterItem.FullName || '';
        this.Mobile = headMasterItem.Mobile || '';
        this.Mobile1 = headMasterItem.Mobile1 || '';
        // this.Email = headMasterItem.Email || ''; //old
        this.EmailId = headMasterItem.EmailId || ''; //new
        this.Gender = headMasterItem.Gender || '';
        this.YearsInSchool = headMasterItem.YearsInSchool || ''; 
        // this.DateOfJoiningSchool = headMasterItem.DateOfJoiningSchool || '';  //old
        this.DateOfResignationFromSchool = headMasterItem.DateOfResignationFromSchool || ''; //old
          this.AcademicYearId = headMasterItem.AcademicYearId || '';  //new
        this.DateOfJoiningSchool = headMasterItem.DateOfJoiningSchool || ''; //new
        this.StateCode = headMasterItem.StateCode || ''; //new

        this.IsActive = headMasterItem.IsActive || true;
        this.RequestType = 0; // New
    }
}
