<div id="student-class-detail" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    STUDENT CLASS DETAILS
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <form [formGroup]="studentForm">
                    <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon>search</mat-icon>
                        <input formControlName="filterText" placeholder="Search for students">
                    </div>
                </form>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">
                <button mat-raised-button [routerLink]="['./','new']" skipLocationChange="true" replaceUrl="false"
                    class="add-student-class-detail-button fuse-white mt-24 mt-md-0">
                    <span>ADD</span>
                </button>

                <a *ngIf="PageRights.IsExport" class="export-excel" (click)="exportExcel()">
                    <mat-icon> cloud_download</mat-icon>
                </a>
            </div>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table class="student-class-detail-table responsive-table" #table [dataSource]="tableDataSource" matSort
                fusePerfectScrollbar [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- SchoolName Column -->
                <ng-container matColumnDef="SchoolName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>School Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.SchoolName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- ClassName Column -->
                <ng-container matColumnDef="ClassName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Class</mat-header-cell>
                    <mat-cell *matCellDef="let studentClass">
                        <p class="text-truncate">{{studentClass.ClassName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SectionName Column -->
                <ng-container matColumnDef="SectionName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Section
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.SectionName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VTName Column -->
                <ng-container matColumnDef="VTName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>VT Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.VTName}}</p>
                    </mat-cell>
                </ng-container>
                
                <!-- StudentId Column -->
                <ng-container matColumnDef="StudentName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Student Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClassDetail">
                        <p class="text-truncate">{{studentClassDetail.StudentName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- FatherName Column -->
                <ng-container matColumnDef="FatherName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Father Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClassDetail">
                        <p class="text-truncate">{{studentClassDetail.FatherName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- MotherName Column -->
                <ng-container matColumnDef="MotherName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Mother Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClassDetail" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClassDetail.MotherName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- GuardianName Column -->
                <ng-container matColumnDef="GuardianName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Guardian Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClassDetail" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClassDetail.GuardianName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- DateOfBirth Column -->
                <ng-container matColumnDef="DateOfBirth">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Date Of Birth
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClassDetail" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClassDetail.DateOfBirth | date: Constants.ShortDateFormat }}
                        </p>
                    </mat-cell>
                </ng-container>
 
                <ng-container matColumnDef="Actions" stickyEnd>
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let studentClassDetail" fxShow.gt-xs>
                        <button *ngIf="PageRights.IsView" mat-icon-button
                            [routerLink]="['/student-class-details', 'view', studentClassDetail.StudentId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">visibility</mat-icon>
                        </button>
                        <button *ngIf="PageRights.IsEdit" mat-icon-button
                            [routerLink]="['/student-class-details', 'edit', studentClassDetail.StudentId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">edit</mat-icon>
                        </button>
                        <button mat-icon-button color="warn"
                            (click)="onDeleteStudentClassDetail(studentClassDetail.StudentId)"
                            *ngIf="PageRights.IsDelete">
                            <mat-icon>delete_outline</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let studentClassDetail; columns: displayedColumns;" class="student-class-detail"
                    matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="SearchBy.TotalResults" [pageIndex]="SearchBy.PageIndex"
                [pageSize]="SearchBy.PageSize" [pageSizeOptions]="SearchBy.PageSizeOptions"
                [showFirstLastButtons]="SearchBy.ShowFirstLastButtons" (page)="onPageIndexChanged($event)">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>