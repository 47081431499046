// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  hmr: false,
  aws: true,
  lahi: false,
  gj: false,
  mh: false,
  jh: false,
  od: false,
  production: false,
  target: "aws",
  email: "support.aws.lighthouse@lend-a-hand-India.org",
  version: "3.00",



  //#region  DEV Deployment ----- Uncomment below the line of code before Building Package for DEV----------------

  ApiBaseUrl: "https://dev.pathways.net.in/LighthouseServices/",
  UserApplicationBaseUrl: "https://dev-user.pathways.net.in",
  AdminApplicationBaseUrl: "https://dev.pathways.net.in",
  //#endregion -----------------------------------------------------------------------------------------

  //#region UAT Deployment ----- Uncomment below line of code before Building Package for UAT --------------

  // ApiBaseUrl: "https://admin-uat.pathways.net.in/LighthouseServices/",
  // UserApplicationBaseUrl: "https://user-uat.pathways.net.in",
  // AdminApplicationBaseUrl: "https://admin-uat.pathways.net.in",

  //#endregion -----------------------------------------------------------------------------------------
  //#region PROD Deployment ---Uncomment below line of code before Building Package for PROD------------------

  // ApiBaseUrl: "https://admin.pathways.net.in/LighthouseServices/",
  // UserApplicationBaseUrl: "https://user.pathways.net.in",
  // AdminApplicationBaseUrl: "https://admin.pathways.net.in",
  
   //#region PROD Deployment ---Uncomment below line of code before Building Package for PROD------------------

  // ApiBaseUrl: "https://delhi-admin.pathways.net.in/LighthouseServices/",
  // UserApplicationBaseUrl: "https://delhi-user.pathways.net.in",
  // AdminApplicationBaseUrl: "https://delhi-admin.pathways.net.in",

  //#endregion ------------------------------------------------------------------------------------------
  //Build Project => ng build --prod --c/configuration=lahi  lahi | aws | gujarat | mh
  // ng build --prod --c/configuration=lahi
  // ng serve --c/configuration=lahi
  // node --max_old_space_size=8048 ./node_modules/@angular/cli/bin/ng serve --c=aws
  // node --max_old_space_size=8048 ./node_modules/@angular/cli/bin/ng build --prod --c=aws
};

// PMU: rakesh.gtmcs@gmail.com     VC: vc.aisect@email.com      VT: abhay.retaisectguj@gmail.com    HM: gpmschool123@gmail.com
// DRP: amulsonara@gmail.com

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

// Staging Lighthouse Urls : 15.206.8.219
// http://stg-mh.lighthouse.net.in
// http://stg-gj.lighthouse.net.in
// http://stg-jh.lighthouse.net.in
// http://stg-od.lighthouse.net.in

// This option can take one of the following sub-commands:
// app-shell, application, class, component, directive, enum, guard, interceptor, interface, library, module, pipe, resolver, service, service-worker, web-worker
// https://angular.io/cli/generate#component-command

// ng generate component <name> [options]
// ng generate component main/setting  --module=/src/app/main/igmite.module
