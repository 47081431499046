import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';
import { UserModel } from "app/models/user.model";

@Injectable()
export class EmployerListingService {
    constructor(private http: BaseService) { }

    getSchools(): Observable<any> {
        return this.http
            .HttpGet(this.http.Services.School.GetAll)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetAllByCriteria(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.School.GetAllByCriteria, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetEmployersByCriteria(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.EmployerList.GetEmployersByCriteria, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetEmployersById(requestParams: any) {
      

        return this.http.HttpPost(this.http.Services.EmployerList.GetEmployersById, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    GetInternshipsByEmployerId(requestParams: any) {
      

        return this.http.HttpPost(this.http.Services.EmployerList.GetInternshipsByEmployerId, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }
    GetActiveInternsByEmployerId(requestParams: any) {
      

        return this.http.HttpPost(this.http.Services.EmployerList.GetActiveInternsByEmployerId, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    CreateOrUpdateEmployersDetails(formData: any) {
        return this.http
            .HttpPost(this.http.Services.EmployerList.CreateOrUpdateEmployersDetails, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    DeleteEmployersById(Students: string) {
        let studentParams = {
            DataId: Students
        };

        return this.http
            .HttpPost(this.http.Services.EmployerList.DeleteEmployersById, studentParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    getDropdownforSchool(userModel: UserModel): Observable<any[]> {
        let stateRequest = this.http.GetMasterDataByType({ DataType: 'StatesByUserId', UserId:userModel.UserTypeId, SelectTitle: 'State' },false);
        let genderRequest = this.http.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'Gender', SelectTitle: 'Gender' });
        let sectorRequest = this.http.GetMasterDataByType({ DataType: 'Sectors', SelectTitle: 'Sector' }, false);

        

        
        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        return forkJoin([stateRequest,genderRequest,sectorRequest]);
    }
}
