import { Component, Input, ElementRef, OnChanges} from '@angular/core';

//@source: https://stackoverflow.com/questions/37819312/angular-2-read-more-directive
@Component({    
    selector: 'read-more',
    template: 
    `
    <div [innerHTML]="currentText" style="width:50px"> </div>
    <a [class.hidden]="hideToggle" (click)="toggleView()">Read {{isCollapsed? 'more':'less'}}</a>
    `,
    // styleUrls: [ './app.component.css' ]
})

export class ReadMoreComponent implements OnChanges {
    @Input() text: string;
    @Input() maxLength: number;
    @Input() query: string;
    currentText: string;
    hideToggle: boolean = true;

    public isCollapsed: boolean = true;

    constructor(private elementRef: ElementRef) {

    }
    toggleView() {
        this.isCollapsed = !this.isCollapsed;
        this.determineView();
    }

    determineView() {
        if (this.text.length <= this.maxLength) {
            this.currentText = this.text;
            this.isCollapsed = false;
            this.hideToggle = true;
            return;
        }
        this.hideToggle = false;
7
        if(this.query == undefined && this.isCollapsed == true){
          this.currentText = this.text.substring(0, this.maxLength) + "...";
          
        } else if(this.isCollapsed == true) {
             console.log(this.query);
            let indexOfMatch = this.text.toUpperCase().indexOf(this.query.toUpperCase());
            let k = 70
            let firstKCharBefore = this.text.substring(indexOfMatch - k, indexOfMatch); 
            let firstKCharAfter = this.text.substring(indexOfMatch, indexOfMatch + this.query.length + k); 
            this.currentText = "..." + firstKCharBefore + firstKCharAfter + "...";

        } else if(this.isCollapsed == false)  {
            this.currentText = this.text;
        }

    }
    ngOnChanges() {
        this.determineView();       
    }
}