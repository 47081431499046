import { FuseUtils } from "@fuse/utils";

export class ResourceVideosModel {
    IsActive: boolean;
    ResourceId: string;
    ResourceTitle: string;
    ResourceType: string;
    ResourceCategoryId: string;
    Category: string;
    ResourceSubcategoryId: string;
    SubCategory: string;
    ResourceDescription: string;
    ResourceLink: string;
    TargetAudienceType: string;
    ResourcesImageUrl: string;
    RequestType: any;
    PublishDate: Date;
    StateCode: string;
    CreatedBy:string;

    constructor(videoItem?: any) {
        videoItem = videoItem || {};
        this.IsActive = videoItem.IsActive || true;
        this.ResourceId = videoItem.ResourceId || FuseUtils.NewGuid();
        this.ResourceTitle = videoItem.ResourceTitle || '';
        this.ResourceType = videoItem.ResourceType || '';
        this.ResourceCategoryId = videoItem.ResourceCategoryId || '';
        this.Category = videoItem.Category || '';
        this.ResourceSubcategoryId = videoItem.ResourceSubcategoryId || '00000000-0000-0000-0000-000000000000';
        this.SubCategory = videoItem.SubCategory || '';
        this.ResourceDescription = videoItem.ResourceDescription || '';
        this.ResourceLink = videoItem.ResourceLink || '';
        this.TargetAudienceType = videoItem.TargetAudienceType || '';
        this.ResourcesImageUrl = videoItem.ResourcesImageUrl || '';
        this.PublishDate = videoItem.PublishDate || '';
        this.StateCode = videoItem.StateCode || '';
        this.CreatedBy = videoItem.CreatedBy || '';
    }
}
