<div id="products" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <!-- {{ 'Internship Opportunity' | translate }} -->
                    Internship Status
                    <!-- <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a> -->
                    <button mat-icon-button class="refresh-data mt-24 mt-md-0" (click)="refreshPage()">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}"
                            style="color: #a4ef13;margin-top: -15% !important;">
                            refresh</mat-icon>
                    </button>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <form [formGroup]="InternshipApprovalForm">
                    <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon>search</mat-icon>
                        <input formControlName="filterText" placeholder="Search for a Internship Opportunity">
                    </div>
                </form>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">
                <!-- <button mat-raised-button [routerLink]="['./','new']" skipLocationChange="true" replaceUrl="false"
                    class="add-internship-opportunity-button fuse-white mt-24 mt-md-0 btn-warning">
                    <span>Add New Opportunity</span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="ms-2" width="20" height="20" viewBox="0 0 24 24"
                        stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="12" y1="5" x2="12" y2="19" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                    </svg>
                </button> -->

            </div>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->
        <div class="padding-bottom" *ngIf="IsDirectMappingEnabled == true">
            <mat-tab-group selectedIndex="1">

                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Internship Status</span>
                    </ng-template>

                </mat-tab>
            </mat-tab-group>
        </div>
        <div class="padding-bottom" *ngIf="IsDirectMappingEnabled == false">
            <mat-tab-group selectedIndex="0" (selectedTabChange)="changeTab($event.index)">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Internship Applications</span>
                    </ng-template>

                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Internship Status</span>
                    </ng-template>

                </mat-tab>
            </mat-tab-group>
        </div>
        <!-- Filter Section -->
        <div class="filter-content-card">
            <div class="filter-content">
                <form name="InternshipApprovalFilterForm" [formGroup]="InternshipApprovalFilterForm"
                    class="vc-issue-report" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Sector</mat-label>
                            <mat-select formControlName="SectorId" name="sectorId" appearance="outline">
                                <mat-option [value]="sectorItem.Id" *ngFor="let sectorItem of sectorList">
                                    {{ sectorItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label> Employer</mat-label>

                            <mat-select formControlName="EmployerId" name="employerId" appearance="outline"
                                #SelectEmployer>
                                <mat-select-filter class="select-filter" *ngIf="SelectEmployer.focused"
                                    [array]="employerList" (filteredReturn)="this.filteredEmployerItems = $event"
                                    [displayMember]="'Name'">
                                </mat-select-filter>

                                <mat-option [value]="employerItem.Id"
                                    *ngFor="let employerItem of filteredEmployerItems">
                                    {{ employerItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Duration</mat-label>
                            <mat-select formControlName="DurationId" name="durationId" appearance="outline"
                                (selectionChange)="onChangeDuration($event.value)">
                                <mat-option [value]="item.Id" *ngFor="let item of durationList">
                                    {{ item.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="clear-filter pr-1" fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="onLoadSchoolVTPSectorsByFilters()"><span>SEARCH</span></button>
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="resetFilters()"><span>CLEAR</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- /Filter Section -->

        <!-- CONTENT CARD -->
        <div class="content-card">
            <div class="asd" *ngIf="tabIndex == 0">
                <mat-table class=" xxx " [dataSource]="tableDataSource" matSort fusePerfectScrollbar
                    [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                    <!-- StudentName Column -->
                    <ng-container matColumnDef="StudentName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>
                            Name
                            <!--  StudentName -->
                        </mat-header-cell>
                        <mat-cell *matCellDef="let internshipApp">

                            <span [routerLink]="['/vt-student-details', internshipApp.StudentId]">
                                <img *ngIf="internshipApp.StudentProfilePhoto!=''"
                                    [src]="imageBasePath +internshipApp.StudentProfilePhoto" width="50" height="50"
                                    style="border-radius: 50%;margin-right: 10%;" />
                                <mat-icon *ngIf="internshipApp.StudentProfilePhoto==''">face</mat-icon>
                            </span>
                            <!-- style="margin-left: 10%;" -->
                            <p class="text-truncate" style="margin-left: 5%;"
                                [routerLink]="['/vt-student-details', internshipApp.StudentId]">
                                <b>{{internshipApp.StudentName}}</b><br>
                                {{internshipApp.Mobile}}
                            </p>

                        </mat-cell>
                    </ng-container>

                    <!-- InternshipTitle Column -->
                    <ng-container matColumnDef="InternshipTitle">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
                            Opportunity
                        </mat-header-cell>
                        <mat-cell *matCellDef="let internshipApp" fxHide fxShow.gt-xs>
                            <div style="margin-right: 20px;"><span class="empInfo" style="border-radius: 10px!important;background-color: #e8e8e8;font-size: 18px;font-weight: 800;
                                padding-left: 15px;
         padding-right: 15px;padding-top: 10px!important;
         padding-bottom: 10px!important;">{{internshipApp.EmployerName
                                    | slice :0:1 | uppercase }}</span>
                            </div>
                            <div>
                                <p class="text-truncate">{{internshipApp.InternshipTitle}}
                                    <br>{{internshipApp.EmployerName}}
                                </p>

                            </div>

                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Actions" stickyEnd>
                        <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                        <mat-cell *matCellDef="let internshipApp" fxShow.gt-xs>

                            <button mat-icon-button
                                (click)="onClickViewRecord(internshipApp.StudentId,internshipApp.InternshipId)">
                                <mat-icon color="primary">visibility</mat-icon>
                            </button>
                            <button mat-raised-button matTooltip="Send to Employer" matTooltipPosition='above'
                                *ngIf="tabIndex === 0"
                                (click)="onClickSendToEmployer(internshipApp.StudentId,internshipApp.InternshipId)"
                                class="btn-send-to-employer"> Send to Employer

                            </button>

                            <button mat-raised-button *ngIf="tabIndex === 1"
                                [disabled]="internshipApp.Status==='Hired'|| internshipApp.Status==='RejectedByEmp'"
                                (click)="onClickHireStudent(internshipApp.StudentId,internshipApp.InternshipId)"
                                ngClass="{{internshipApp.Status==='Hired' || internshipApp.Status==='RejectedByEmp'? 'btn-disabled' : 'btn-send-to-employer'}}">
                                Hire
                            </button>

                            <button mat-icon-button [matMenuTriggerFor]="menu"
                                aria-label="Example icon-button with a menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item *ngIf="tabIndex === 0">
                                    <span>No Actions</span>
                                </button>
                                <button mat-menu-item *ngIf="tabIndex === 1"
                                    [disabled]="(internshipApp.IsIRReviewed===true || internshipApp.IsCompleted==true)||( internshipApp.DateOfDropout!==null)||(internshipApp.IsPlaced==false && internshipApp.IsApprovedByVT==true && internshipApp.IsApprovedByEmployer==true)"
                                    (click)="onClickDroupoutInternship(internshipApp.StudentId,internshipApp.InternshipId)">
                                    <mat-icon style="color: red;">cancel</mat-icon>
                                    <span>Droupout</span>
                                </button>

                                <button mat-menu-item *ngIf="tabIndex === 1"
                                    [disabled]="(internshipApp.IsIRReviewed===true || internshipApp.DateOfDropout!==null)||(internshipApp.IsIRReviewed===true)||(internshipApp.IsIRReviewed===true && internshipApp.IsCompleted==true )||(internshipApp.IsPlaced==false && internshipApp.IsApprovedByVT==true && internshipApp.IsApprovedByEmployer==true)"
                                    (click)="onClickReviewReport(internshipApp.StudentId,internshipApp.InternshipId)">
                                    <mat-icon style="color: #1088e4;">library_books</mat-icon>
                                    <span>Review report</span>
                                </button>
                                <button mat-menu-item *ngIf="tabIndex === 1"
                                    [disabled]="(internshipApp.IsIRReviewed===true && internshipApp.IsCompleted==true )||( internshipApp.DateOfDropout!==null)||(internshipApp.IsPlaced==false && internshipApp.IsApprovedByVT==true && internshipApp.IsApprovedByEmployer==true)"
                                    (click)="onClickMarkInternshipApprovalCompleted(internshipApp.StudentId,internshipApp.InternshipId,internshipApp.ActualEndDate)">
                                    <mat-icon style="color: #08c208;">done</mat-icon>
                                    <span>Complete</span>
                                </button>
                            </mat-menu>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                    <mat-row *matRowDef="let internshipApp; columns: displayedColumns;" class="internshipApp" matRipple>
                    </mat-row>

                    <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let internshipApp; columns: displayedColumns;"></tr> -->

                    <!-- Group header -->
                    <ng-container matColumnDef="groupHeader">
                        <td colspan="999" mat-cell *matCellDef="let groupBy"><strong>
                                {{groupBy.date | date}}</strong></td>
                    </ng-container>

                    <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"> </tr>


                </mat-table>

                <!-- <mat-paginator #ListPaginator [length]="SearchBy.TotalResults" [pageIndex]="SearchBy.PageIndex"
                [pageSize]="SearchBy.PageSize" [pageSizeOptions]="SearchBy.PageSizeOptions"
                [showFirstLastButtons]="SearchBy.ShowFirstLastButtons" (page)="onPageIndexChanged($event)">
            </mat-paginator> -->
                <!-- <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0"
                    [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator> -->
            </div>

            <div class="yyy" *ngIf="tabIndex == 1">
                <mat-table class=" xxx " [dataSource]="tableDataSource" matSort fusePerfectScrollbar
                    [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                    <!-- StudentName Column -->
                    <ng-container matColumnDef="StudentName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>
                            Name
                            <!--  StudentName -->
                        </mat-header-cell>
                        <mat-cell *matCellDef="let student; let internshipApp">

                            <span [routerLink]="['/vt-student-details', internshipApp.StudentId]">
                                <img *ngIf="internshipApp.StudentProfilePhoto!=''"
                                    [src]="imageBasePath +internshipApp.StudentProfilePhoto" width="50" height="50"
                                    style="border-radius: 50%;margin-right: 10%;" />
                                <mat-icon *ngIf="internshipApp.StudentProfilePhoto==''">face</mat-icon>
                            </span>
                            <!-- style="margin-left: 10%;" -->
                            <p class="text-truncate" style="margin-left: 5%;"
                                [routerLink]="['/vt-student-details', internshipApp.StudentId]">
                                <b>{{internshipApp.StudentName}}</b><br>
                                {{internshipApp.Mobile}}
                            </p>

                        </mat-cell>
                    </ng-container>

                    <!-- InternshipTitle Column -->
                    <ng-container matColumnDef="InternshipTitle">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
                            Opportunity
                        </mat-header-cell>
                        <mat-cell *matCellDef="let internshipApp" fxHide fxShow.gt-xs>
                            <div style="margin-right: 20px;"><span class="empInfo" style="border-radius: 10px!important;background-color: #e8e8e8;font-size: 18px;font-weight: 800;
                                padding-left: 15px;
         padding-right: 15px;padding-top: 10px!important;
         padding-bottom: 10px!important;">{{internshipApp.EmployerName
                                    | slice :0:1 | uppercase }}</span>
                            </div>
                            <div>
                                <p class="text-truncate">
                                    {{internshipApp.EmployerName}}
                                </p>

                            </div>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Status" *ngIf="tabIndex===1">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Status
                        </mat-header-cell>
                        <mat-cell *matCellDef="let internshipApp">
                            <p *ngIf="(internshipApp.Status=='Hired'&&internshipApp.DateOfDropout ===null && (internshipApp.IsIRReviewed ===null || internshipApp.IsIRReviewed===false) && internshipApp.IsCompleted ===null)"
                                class="text-truncate" [class.hire]="hire">
                                <b> {{internshipApp.Status}}</b> <span style="color:#b2b205;"
                                    *ngIf=" (internshipApp.IsPlaced==false && internshipApp.IsApprovedByVT==true && internshipApp.IsApprovedByEmployer==true)">
                                    / Student Acceptance Pending</span>
                            </p>
                            <P *ngIf="internshipApp.Status==''" [class.pending]="pending"><b>Pending</b></P>
                            <P *ngIf="internshipApp.DateOfDropout !==null && internshipApp.DateOfDropout !==''"
                                [class.droppedOut]="droppedOut">
                                <b>Dropped Out</b>
                            </P>
                            <P *ngIf="((internshipApp.IsIRReviewed ===true && internshipApp.IsIRReviewed!==false) && (internshipApp.IsCompleted !==true || internshipApp.IsCompleted ===null) )"
                                [class.reviewed]="reviewed">
                                <b>Report Reviewed</b>
                            </P>
                            <P *ngIf="( internshipApp.IsIRReviewed ===true && internshipApp.IsCompleted ===true)"
                                [class.completed]="completed">
                                <b>Completed</b>
                            </P>
                            <P *ngIf="( (internshipApp.IsIRReviewed ===false  || internshipApp.IsIRReviewed===null) && (internshipApp.IsCompleted ===true))"
                                [class.reviewed]="reviewed">
                                <b><span [class.completed]="completed">Completed</span> / Report Review Pending</b>
                            </P>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Actions" stickyEnd>
                        <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                        <mat-cell *matCellDef="let internshipApp" fxShow.gt-xs>

                            <button mat-icon-button
                                (click)="onClickViewRecord(internshipApp.StudentId,internshipApp.InternshipId)">
                                <mat-icon color="primary">visibility</mat-icon>
                            </button>
                            <button mat-raised-button matTooltip="Send to Employer" matTooltipPosition='above'
                                *ngIf="tabIndex === 0"
                                (click)="onClickSendToEmployer(internshipApp.StudentId,internshipApp.InternshipId)"
                                class="btn-send-to-employer"> Send to Employer

                            </button>

                            <button mat-raised-button *ngIf="tabIndex === 1"
                                [disabled]="internshipApp.Status==='Hired'|| internshipApp.Status==='RejectedByEmp'"
                                (click)="onClickHireStudent(internshipApp.StudentId,internshipApp.InternshipId)"
                                ngClass="{{internshipApp.Status==='Hired'|| internshipApp.Status==='RejectedByEmp' ? 'btn-disabled' : 'btn-send-to-employer'}}">
                                Hire
                            </button>

                            <button mat-icon-button [matMenuTriggerFor]="menu"
                                aria-label="Example icon-button with a menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item *ngIf="tabIndex === 0">
                                    <span>No Actions</span>
                                </button>
                                <button mat-menu-item *ngIf="tabIndex === 1"
                                    [disabled]="(internshipApp.IsIRReviewed===true || internshipApp.IsCompleted==true)||( internshipApp.DateOfDropout!==null)"
                                    (click)="onClickStudentInternshipDateChange(internshipApp)">
                                    <mat-icon style="color: #1088e4;">edit</mat-icon>
                                    <span> Change Start Date </span>
                                </button>
                                <button mat-menu-item *ngIf="tabIndex === 1"
                                    [disabled]="(internshipApp.IsIRReviewed===true || internshipApp.IsCompleted==true)||( internshipApp.DateOfDropout!==null)||(internshipApp.IsPlaced==false && internshipApp.IsApprovedByVT==true && internshipApp.IsApprovedByEmployer==true)||(internshipApp.IsRejectedByEmp==true)"
                                    (click)="onClickDroupoutInternship(internshipApp.StudentId,internshipApp.InternshipId)">
                                    <mat-icon style="color: red;">cancel</mat-icon>
                                    <span>Droupout</span>
                                </button>

                                <button mat-menu-item *ngIf="tabIndex === 1"
                                    [disabled]="(internshipApp.IsIRReviewed===true || internshipApp.DateOfDropout!==null)||(internshipApp.IsIRReviewed===true)||(internshipApp.IsIRReviewed===true && internshipApp.IsCompleted==true )||(internshipApp.IsPlaced==false && internshipApp.IsApprovedByVT==true && internshipApp.IsApprovedByEmployer==true)||(internshipApp.IsRejectedByEmp==true)"
                                    (click)="onClickReviewReport(internshipApp.StudentId,internshipApp.InternshipId,internshipApp.IsCompleted,internshipApp.IsIRReviewed)">
                                    <mat-icon style="color: #1088e4;">library_books</mat-icon>
                                    <span>Review report</span>
                                </button>
                                <button mat-menu-item *ngIf="tabIndex === 1"
                                    [disabled]="(internshipApp.IsIRReviewed===true && internshipApp.IsCompleted==true )||( internshipApp.DateOfDropout!==null)||(internshipApp.IsPlaced==false && internshipApp.IsApprovedByVT==true && internshipApp.IsApprovedByEmployer==true)||(internshipApp.IsRejectedByEmp==true)"
                                    (click)="onClickMarkInternshipApprovalCompleted(internshipApp.StudentId,internshipApp.InternshipId,internshipApp.ActualEndDate)">
                                    <mat-icon style="color: #08c208;">done</mat-icon>
                                    <span>Complete</span>
                                </button>
                            </mat-menu>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                    <mat-row *matRowDef="let internshipApp; columns: displayedColumns;" class="internshipApp" matRipple>
                    </mat-row>

                    <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let internshipApp; columns: displayedColumns;"></tr> -->

                    <!-- Group header -->
                    <ng-container matColumnDef="groupHeader">
                        <td colspan="999" mat-cell *matCellDef="let groupBy"><strong>
                                {{groupBy.date | date}}</strong></td>
                    </ng-container>

                    <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"> </tr>


                </mat-table>

                <!-- <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0"
                    [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator> -->

            </div>

        </div>


        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>