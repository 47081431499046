import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';
import { UserModel } from 'app/models/user.model';
import { Certificate } from "crypto";

@Injectable()
export class VTDashboardService {
    constructor(private http: BaseService) { }



    GetCertificateForEmployerByVTId(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.Certificate.GetCertificateForEmployerByVTId, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetCertificateById(sectorJobRoleId: string) {
        let requestParams = {
            DataId: sectorJobRoleId
        };

        return this.http
            .HttpPost(this.http.Services.Certificate.GetCertificateById, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    CreateOrUpdateCertificate(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Certificate.CreateOrUpdateCertificate, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    GetVTDashboardPending(formData: any) {
        return this.http
            .HttpPost(this.http.Services.VTDashboard.GetVTDashboardPending, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    GetVTDashboardDailyDiaryEntries(formData: any) {
        return this.http
            .HttpPost(this.http.Services.VTDashboard.GetVTDashboardDailyDiaryEntries, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }
    GetVTDashboardInternshipBarChart(formData: any) {
        return this.http
            .HttpPost(this.http.Services.VTDashboard.GetVTDashboardInternshipBarChart, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    GetVTDashboardInternshipConsolidated(formData: any) {
        return this.http
            .HttpPost(this.http.Services.VTDashboard.GetVTDashboardInternshipConsolidated, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    GetVTDashboardStudentsPieChart(formData: any) {
        return this.http
            .HttpPost(this.http.Services.VTDashboard.GetVTDashboardStudentsPieChart, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

  
    GetCertificatePdf(certificateId: string) {
        let requestParams = {
            DataId: certificateId,
        };

        return this.http.HttpPost(this.http.Services.Certificate.GetCertificatePdf, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }



    getDropdownfor(userModel: UserModel): Observable<any[]> {
      
        let genderRequest = this.http.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'StudentGender', SelectTitle: 'Gender' });
        let classRequest = this.http.GetMasterDataByType({ DataType: 'SchoolClasses', SelectTitle: 'School Class' });


        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        return forkJoin([genderRequest,classRequest]);
    }
}
