import { Component, OnInit, NgZone, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StudentModel } from './student.model';
import { StudentService } from './student.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as guarati } from './i18n/gj';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { DropdownModel } from 'app/models/dropdown.model';
import { environment } from 'environments/environment';
import { UserModel } from "app/models/user.model";

@Component({
  selector: 'data-list-view',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class StudentComponent extends BaseListComponent<StudentModel> implements OnInit {
  studentSearchForm: FormGroup;
  studentModel: StudentModel;
  currentAcademicYearId: string;
  academicYearList: [DropdownModel];
  sectorList: [DropdownModel];
  vtpList: [DropdownModel];
  filteredVtpSectorItems: any;
  schoolList: [DropdownModel];
  classList: [DropdownModel];
  sectionList: [DropdownModel];
  filteredSchoolItems: any;


  // schoolVTPSectorSearchForm: FormGroup;
  studentFilterForm: FormGroup;
  ApiBaseUrlImage =  this.Constants.CommonImageUrl.Account;
  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private translationLoaderService: FuseTranslationLoaderService,
    public zone: NgZone,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private studentService: StudentService) {
    super(commonService, router, routeParams, snackBar, zone);
    this.translationLoaderService.loadTranslations(english, guarati);
    this.studentSearchForm = this.formBuilder.group({ filterText: '' });
    this.studentModel = new StudentModel();
    this.studentFilterForm = this.createSchoolVtpSectorFilterForm();


  }

  ngOnInit(): void {
    this.PageRights.IsExport = true;
    this.studentService.getDropdownforSchool(this.UserModel).subscribe(results => {


      if (results[1].Success) {
        this.academicYearList = results[1].Results;
      }


      if (results[10].Success) {
        this.vtpList = results[10].Results;
      }
      if (results[11].Success) {
        this.sectorList = results[11].Results;
      }
    });
    this.onChangeClass()
    // this.onLoadSchoolsByCriteria();



    this.studentSearchForm = this.formBuilder.group({ filterText: '' });

    // Load initial schools data
    this.onLoadSchoolsByCriteria();

    this.studentSearchForm.get('filterText').valueChanges.pipe(
      // if character length greater then 2
      filter(res => {
        this.onLoadSchoolsByCriteria();

        if (res.length == 0) {
          this.SearchBy.Name = '';
          this.onLoadSchoolsByCriteria();
          return false;
        }

        return res.length > 2
      }),

      // Time in milliseconds between key events
      debounceTime(650),

      // If previous query is diffent from current   
      distinctUntilChanged()

      // subscription for response
    ).subscribe((searchText: string) => {
      this.SearchBy.Name = searchText;
      this.onLoadSchoolsByCriteria();
    });
  }

  ngAfterViewInit() {
    this.tableDataSource.paginator = this.ListPaginator;
  }

  onPageIndexChanged(evt) {
    // console.log(evt)
    // if(evt.pageIndex == 0){
    //   this.SearchBy.PageIndex = 1;
    // }else{
    //   this.SearchBy.PageIndex = evt.pageIndex;
    // }
    this.SearchBy.PageIndex = evt.pageIndex;

    this.SearchBy.PageSize = evt.pageSize;

    this.onLoadSchoolsByCriteria();
  }

  onLoadSchoolsByCriteria(): any {
    this.IsLoading = true;

    


    if (this.Constants.GlobalSchoolId != null && this.Constants.GlobalAcademicYearId != null) {
      if (this.Constants.GlobalSchoolId != "undefined" && this.Constants.GlobalAcademicYearId != "undefined") {
        this.SearchBy.VTId = this.UserModel.UserTypeId;

        this.SearchBy.UserTypeId = this.UserModel.UserTypeId;
        this.SearchBy.RoleCode = this.UserModel.RoleCode;
        this.SearchBy.SchoolGuid = this.Constants.GlobalSchoolId;
        this.SearchBy.AcademicYearGuid = this.Constants.GlobalAcademicYearId;
        this.SearchBy.SectorGuid = this.studentFilterForm.controls['SectorId'].value;
        this.SearchBy.ClassGuid = this.studentFilterForm.controls['Class'].value;
        this.SearchBy.SectionGuid = this.studentFilterForm.controls['Section'].value;
        this.studentService.GetStudentsByCriteria(this.SearchBy).subscribe(response => {
          this.displayedColumns = ['FullName', 'Gender', 'Mobile', 'EmailId', 'DOB', 'WhatsappNumber', 'ClassName', 'SectionName', 'Sector', 'Actions'];

          this.tableDataSource.data = response.Results;
          this.tableDataSource.sort = this.ListSort;
          this.tableDataSource.paginator = this.ListPaginator;
          this.tableDataSource.filteredData = this.tableDataSource.data;
          this.SearchBy.TotalResults = response.TotalResults;

          setTimeout(() => {
            this.ListPaginator.pageIndex = this.SearchBy.PageIndex;
            this.ListPaginator.length = this.SearchBy.TotalResults;
          });
          this.zone.run(() => {
            if (this.tableDataSource.data.length == 0) {
              // this.showNoDataFoundSnackBar();
            }
          });
          this.IsLoading = false;
        }, error => {
          console.log(error);
          this.IsLoading = false;
        });
      } else {
        this.zone.run(() => {
          this.showActionMessage(
            'Please Select  AcademicYear',
            this.Constants.Html.SuccessSnackbar
          );
        });
      }

    } else {
      this.zone.run(() => {
        this.showActionMessage(
          'Please Select School & AcademicYear',
          this.Constants.Html.SuccessSnackbar
        );
      });

    }


  }



  onDeleteSchool(StudentId: string) {
    this.dialogService
      .openConfirmDialog(this.Constants.Messages.DeleteConfirmationMessage)
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          this.studentService.DeleteStudentsById(StudentId)
            .subscribe((schoolResp: any) => {

              this.zone.run(() => {
                if (schoolResp.Success) {
                  this.showActionMessage(
                    this.Constants.Messages.RecordDeletedMessage,
                    this.Constants.Html.SuccessSnackbar
                  );
                }
                this.ngOnInit();
              }, error => {
                console.log('School deletion errors =>', error);
              });

            });
          this.ngOnInit();
        }
      });
  }

  exportExcel(): void {
    this.IsLoading = true;
    this.SearchBy.PageIndex = 0;
    this.SearchBy.PageSize = 10000;

    this.studentService.GetStudentsByCriteria(this.SearchBy).subscribe(response => {
      response.Results.forEach(
        function (obj) {
          if (obj.hasOwnProperty('IsActive')) {
            obj.IsActive = obj.IsActive ? 'Yes' : 'No';
          }
          if (obj.hasOwnProperty('IsImplemented')) {
            obj.IsImplemented = obj.IsImplemented ? 'Yes' : 'No';
          }
          delete obj.SchoolId;
          delete obj.StudentId;
          delete obj.AcademicYearId;
          delete obj.SectionId;
          delete obj.ClassId;
          delete obj.TotalRows;
        });

      this.exportExcelFromTable(response.Results, "Student");

      this.IsLoading = false;
      this.SearchBy.PageIndex = 0;
      this.SearchBy.PageSize = 10;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  onLoadSchoolVTPSectorsByFilters(): any {
    this.onLoadSchoolsByCriteria();
  }

  resetFilters(): void {
    this.studentFilterForm.reset();

    this.onLoadSchoolsByCriteria();

    this.studentFilterForm.reset();
    // this.studentFilterForm.get('AcademicYearId').setValue(this.currentAcademicYearId);
    // this.SearchBy.SectorGuid = ''
  }
  //Create SchoolVtpSectorFilter form and returns {FormGroup}
  createSchoolVtpSectorFilterForm(): FormGroup {
    return this.formBuilder.group({
      // AcademicYearId: new FormControl(),
      SectorId: new FormControl(),
      Class: new FormControl(),
      Section: new FormControl(),
      // SchoolId: new FormControl(),
    });
  }

  onChangeClass() {
    this.commonService.GetMasterDataByType({ DataType: 'ClassesByVT', ParentId: this.Constants.GlobalSchoolId, UserId: this.UserModel.UserTypeId, SelectTitle: 'Class' }).subscribe((response: any) => {
      this.classList = response.Results;
    });
  }
  onChangeSection(ClassId: any) {
    this.commonService.GetMasterDataByType({ DataType: 'Sections', ParentId: ClassId, SelectTitle: 'Sections' }).subscribe((response: any) => {
      this.sectionList = response.Results;
    });
  }

}
