export const locale = {
    lang: 'en',
    data: {
        'HEADMASTER': {
            'HEAD_MASTERS': 'HEAD MASTERS',
            'ADD_NEW_HEAD_MASTER': 'ADD',
            'SEARCH_FOR_A_HEAD_MASTER': 'Search for a head master',
        }
    }
};

