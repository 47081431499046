import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';
import { UserModel } from 'app/models/user.model';

@Injectable()
export class SchoolClassSectionsService {
    constructor(private http: BaseService) { }

    getSchoolClassSectionss(): Observable<any> {
        return this.http
            .HttpGet(this.http.Services.SchoolClassSections.GetAll)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetAllByCriteria(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.SchoolClassSections.GetAllByCriteria, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    getSchoolClassSectionsById(schoolClassSectionId: string) {
        let requestParams = {
            DataId: schoolClassSectionId
        };

        return this.http
            .HttpPost(this.http.Services.SchoolClassSections.GetById, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    createOrUpdateSchoolClassSections(formData: any) {
        return this.http
            .HttpPost(this.http.Services.SchoolClassSections.CreateOrUpdate, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    deleteSchoolClassSectionsById(hmId: string) {
        var SchoolClassSectionsParams = {
            DataId: hmId
        };

        return this.http
            .HttpPost(this.http.Services.SchoolClassSections.Delete, SchoolClassSectionsParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    getDropdownforSchoolClassSections(userModel: UserModel): Observable<any[]> {
        let schoolRequest = this.http.GetMasterDataByType({ DataType: 'Schools',UserId:userModel.UserTypeId, ParentId: null, SelectTitle: 'School' });
        let classRequest = this.http.GetMasterDataByType({ DataType: 'SchoolClasses', SelectTitle: 'School Classes' });
        let sectionRequest = this.http.GetMasterDataByType({ DataType: 'Sections', SelectTitle: 'Section' });
        let statecodeRequest = this.http.GetMasterDataByType({ DataType: 'StatesByUserId', UserId:userModel.UserTypeId, SelectTitle: 'StateCode' });
        let academicYearRequest = this.http.GetMasterDataByType({ DataType: 'AcademicYearsByState', UserId:userModel.UserTypeId, SelectTitle: 'Academic Year' });

        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        return forkJoin([schoolRequest,classRequest,sectionRequest,statecodeRequest,academicYearRequest]);
    }
}
 