import { DatePipe } from '@angular/common';
import { Component, Input, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { DropdownModel } from 'app/models/dropdown.model';
import { CommonService } from 'app/services/common.service';
import { environment } from 'environments/environment';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { DiaryEntry } from './diary-entry.model';
import { DiaryEntryService } from './diary-entry.service';
import { InternshipOpportunityService } from '../internship-opportunity/internship-opportunity.service';

@Component({
  selector: 'app-diary-entry',
  templateUrl: './diary-entry.component.html',
  styleUrls: ['./diary-entry.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class DiaryEntryComponent extends BaseListComponent<DiaryEntry> implements OnInit {
  diaryEntryVTForm: FormGroup;
  diaryEntryVTFilterForm: FormGroup;

  @Input() IsAllowedGloablSearch: boolean;
  currentAcademicYearId: string;
  sectorList: [DropdownModel];
  vtpList: [DropdownModel];
  filteredVtpSectorItems: any;
  employerList: [DropdownModel];
  filteredEmployerItems: any;
  imageBasePath: string;
  ProfileImageBaseURL: string;
  durationList = [{ Id: 1, Name: 'Today' }, { Id: 2, Name: 'Yesterday' }, { Id: 3, Name: 'Last 7 days' }, { Id: 4, Name: 'This Month' }, { Id: 5, Name: 'Last Month' }]
  fromDate: any = null;
  toDate: any = null;
  today = new Date();
  // Constants: any;
  // SearchBy: any;
  tableDataSource: any;
  ListPaginator: any;
  IsLoading: boolean;
  displayedColumns: string[];
  ListSort: any;

  // fileEextensionsList = [{ extension: 'jpg' },
  // { extension: 'jpeg' }, { extension: 'iso' }, { extension: 'png' }, { extension: 'gif' }, { extension: 'docx' },
  // { extension: 'doc' }, { extension: 'pdf' }, { extension: 'mp3' }, { extension: 'mp4' }, { extension: 'mpeg' },
  // { extension: 'pptx' }, { extension: 'JPG' }, { extension: 'JPEG' }, { extension: 'ISO' }, { extension: 'PNG' },
  // { extension: 'GIF' }, { extension: 'DOCX' }, { extension: 'DOC' }, { extension: 'PDF' }, { extension: 'MP3' },
  // { extension: 'MP4' }, { extension: 'MPEG' }, { extension: 'PPTX' }];
  fileEextensionsList = ['jpg',
    'jpeg', 'iso', 'png', 'gif', 'docx',
    'doc', 'pdf', 'mp3', 'mp4', 'mpeg',
    'pptx', 'JPG', 'JPEG', 'ISO', 'PNG',
    'GIF', 'DOCX', 'DOC', 'PDF', 'MP3',
    'MP4', 'MPEG', 'PPTX'];
  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private translationLoaderService: FuseTranslationLoaderService,
    private internshiOpportunityService: InternshipOpportunityService,
    public zone: NgZone,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private service: DiaryEntryService,
    private datePipe: DatePipe
  ) {
    super(commonService, router, routeParams, snackBar, zone);
    // this.translationLoaderService.loadTranslations(english, guarati);
    this.diaryEntryVTForm = this.formBuilder.group({ filterText: '' });
  }

  ngOnInit(): void {
    this.getEmployersByCriteria();
    this.imageBasePath = this.Constants.CommonImageUrl.Attachments;
    this.ProfileImageBaseURL = this.Constants.CommonImageUrl.Account;
    // this.trigger.openMenu();
    this.PageRights.IsExport = true;
    // show hide gloabl search 
    this.IsAllowedGloablSearch = this.Constants.PageArray.includes(window.location.pathname);

    this.service.getDropdownforInternshipOpportunity(this.UserModel).subscribe(results => {
      if (results[0].Success) {
        this.sectorList = results[0].Results;
      }

      // if (results[1].Success) {
      //   this.employerList = results[1].Results;
      //   this.filteredEmployerItems = this.employerList.slice();
      // }
      this.SearchBy.PageIndex = 0; // delete after script changed
      this.SearchBy.PageSize = 10000; // delete after script changed

      // this.diaryEntryVTForm = this.formBuilder.group({ filterText: '' });

      //Load initial Diary Entry data

      let stuName = localStorage.getItem('StudentName')
      if (localStorage.getItem('StudentName') != null && localStorage.getItem('StudentName') != undefined) {
        this.diaryEntryVTForm.controls['filterText'].setValue(stuName);
        this.SearchBy.Name = stuName;
        this.onLoadDiaryEnteryByCriteria();
      }
      this.onLoadDiaryEnteryByCriteria();
      this.diaryEntryVTForm.get('filterText').valueChanges.pipe(
        // if character length greater then 2
        filter(res => {
          this.SearchBy.PageIndex = 0;

          if (res.length == 0) {
            this.SearchBy.Name = '';
            this.onLoadDiaryEnteryByCriteria();
            return false;
          }

          return res.length > 2
        }),

        // Time in milliseconds between key events
        debounceTime(650),

        // If previous query is diffent from current   
        distinctUntilChanged()

        // subscription for response
      ).subscribe((searchText: string) => {
        this.SearchBy.Name = searchText;
        this.onLoadDiaryEnteryByCriteria();
      });
    });
    this.diaryEntryVTFilterForm = this.createDiaryEntryVTFilterForm();
  }

  ngAfterViewInit() {
    this.tableDataSource.paginator = this.ListPaginator;
  }

  onPageIndexChanged(evt) {
    this.SearchBy.PageIndex = evt.pageIndex;
    this.SearchBy.PageSize = evt.pageSize;

    this.onLoadDiaryEnteryByCriteria();
  }

  onLoadDiaryEnteryByCriteria(): any {
    let xx = (this.Constants.GlobalSchoolId == 'undefined' || this.Constants.GlobalSchoolId == 'null') ? null : this.Constants.GlobalSchoolId;
    let yy = (this.Constants.GlobalAcademicYearId == 'undefined' || this.Constants.GlobalAcademicYearId == 'null') ? null : this.Constants.GlobalAcademicYearId;

    this.IsLoading = true;
    this.SearchBy.SchoolGuid = (this.Constants.GlobalSchoolId == 'undefined' || this.Constants.GlobalSchoolId == 'null') ? null : this.Constants.GlobalSchoolId;
    this.SearchBy.AcademicYearGuid = (this.Constants.GlobalAcademicYearId == 'undefined' || this.Constants.GlobalAcademicYearId == 'null') ? null : this.Constants.GlobalAcademicYearId;
    this.SearchBy.SectorGuid = this.diaryEntryVTFilterForm.controls['SectorId'].value;
    this.SearchBy.EmployerGuid = this.diaryEntryVTFilterForm.controls['EmployerId'].value;
    //this.SearchBy.RoleCode = this.UserModel.RoleCode;
    this.SearchBy.StartDateTime = this.fromDate == 'undefined' ? null : this.fromDate;//"2020-01-08";
    this.SearchBy.EndDatetime = this.toDate == 'undefined' ? null : this.toDate;//"2022-08-08";
    this.SearchBy.PageIndex = 1;

    this.service.GetAllByCriteria(this.SearchBy).subscribe(response => {
      this.displayedColumns = ['StudentName', 'LessonsLearnt', 'Hours', 'Highlight'];
      //  this.tableDataSource.data = response.Results.filter(x => x.IsPublished == true && x.IsCompleted == false);
      let rowdata: any;
      rowdata = response.Results;

      let object = [] as any;
      if (rowdata != null && rowdata != '') {

        for (let i = 0; i < rowdata.length; i++) {
          if (rowdata[i].listEntries.length > 0) {
            rowdata[i].listEntries.forEach(element => {
              var attatchments = rowdata[i].listAttachments.filter(x => x.AccountId == element.StudentID);
              element.Attachments = attatchments;
              object.push(element);
            });

          } else {
            console.log('listEntries not available');
          }

        }

        const dateArray: GroupByDateArray[] = [];
        for (let k = 0; k < object.length; k++) {
          let xxx = dateArray.filter(x => x.date == object[k].EntryDate);
          if (xxx == null || xxx.length == 0) {
            dateArray.push({
              date: object[k].EntryDate,
              isGroupBy: true
            })
          }
        }

        let soretdDateArray = dateArray.sort((a, b) => a.date > b.date ? -1 : 1);
        const finalArray: (DiaryEntry | GroupByDateArray)[] = [];
        for (let i = 0; i < soretdDateArray.length; i++) {
          let yyd = soretdDateArray.filter(x => x.date == soretdDateArray[i].date);
          if (yyd != null) {
            yyd.forEach(element => {
              finalArray.push({
                date: element.date,
                isGroupBy: true
              });
            });
          }
          let asd = object.filter(x => x.EntryDate == soretdDateArray[i].date);


          if (asd != null || asd != '') {
            asd.forEach(element => {
              finalArray.push(element);
            });
          }
        }

        this.tableDataSource.data = finalArray;
        //this.tableDataSource.paginator = this.ListPaginator;
        this.SearchBy.TotalResults = finalArray.length;
        this.tableDataSource.sort = this.ListSort;
        this.tableDataSource.filteredData = this.tableDataSource.data;
      }
      localStorage.removeItem('StudentName')
      // this.tableDataSource.data = response.Results;
      //this.tableDataSource.sort = this.ListSort;
      //this.tableDataSource.paginator = this.ListPaginator;
      // this.tableDataSource.filteredData = this.tableDataSource.data;
      //this.SearchBy.TotalResults = response.TotalResults;

      // setTimeout(() => {
      //   this.ListPaginator.pageIndex = this.SearchBy.PageIndex;
      //   this.ListPaginator.length = this.SearchBy.TotalResults;
      // });

      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  onDeleteSchool(internshipId: string) {
    this.dialogService
      .openConfirmDialog(this.Constants.Messages.DeleteConfirmationMessage)
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          this.service.deleteSchoolById(internshipId)
            .subscribe((schoolResp: any) => {

              this.zone.run(() => {
                if (schoolResp.Success) {
                  this.showActionMessage(
                    this.Constants.Messages.RecordDeletedMessage,
                    this.Constants.Html.SuccessSnackbar
                  );
                }
                this.ngOnInit();
              }, error => {
                console.log('Internship deletion errors =>', error);
              });

            });
          this.ngOnInit();
        }
      });
  }

  onClickMarkInternshipCompleted(internshipId: string) {
    this.service.markIsCompleted(internshipId).subscribe(response => {
      if (response.Success) {
        this.zone.run(() => {
          this.showActionMessage(
            "Marked Intersnship Completed successfully...",
            this.Constants.Html.SuccessSnackbar
          );
          this.onLoadDiaryEnteryByCriteria();
          // this.router.navigate([RouteConstants.Student.List]);
        });
      }

    });
  }

  onClickPublishInternship(internshipId: string) {
    this.service.markPublishInternship(internshipId).subscribe(response => {
      if (response.Success) {
        this.zone.run(() => {
          this.showActionMessage(
            "Intersnship Published ...",
            this.Constants.Html.SuccessSnackbar
          );
          this.onLoadDiaryEnteryByCriteria();
          // this.router.navigate([RouteConstants.Student.List]);
        });
      }

    });
  }
  exportExcel(): void {
    this.IsLoading = true;
    // let params = { VTGuid: this.UserModel.UserTypeId, PageIndex: 0, PageSize: 10000, };
    // this.SearchBy.VTGuid = this.UserModel.UserTypeId;
    // this.SearchBy.PageIndex = 0;
    // this.SearchBy.PageSize = 10000;
    //"55f73adb-c01d-4ddf-aad6-0a9f401c1295",
    this.service.DownloadDailyDiaryForVT(this.UserModel.UserTypeId, 0, 10000).subscribe(response => {
      response.Results.forEach(
        function (obj) {
          if (obj.hasOwnProperty('IsActive')) {
            obj.IsActive = obj.IsActive ? 'Yes' : 'No';
          }
          if (obj.hasOwnProperty('IsImplemented')) {
            obj.IsImplemented = obj.IsImplemented ? 'Yes' : 'No';
          }
          if (obj.hasOwnProperty('Attachments')) {
            if (obj.Attachments != null && obj.Attachments != '') {
              let arr = obj.Attachments.split(',');
              //let iterator = arr.values();
              let xxx: string = '';
              for (let i = 0; i < arr.length; i++) {
                xxx += environment.UserApplicationBaseUrl + '/Documents/Attachments/' + arr[i] + ',';
              }
              console.log(xxx)
              obj.Attachments = xxx;
            }


            // obj.IsImplemented = obj.IsImplemented ? 'Yes' : 'No';
          }
          else {
            obj.Attachments = null;
          }

          delete obj.StudentID;
          // delete obj.TotalRows;
        });

      this.exportExcelFromTable(response.Results, "Diary Entry");

      this.IsLoading = false;
      this.SearchBy.PageIndex = 0;
      this.SearchBy.PageSize = 10;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  onLoadSchoolVTPSectorsByFilters(): any {
    this.SearchBy.PageIndex = 0;
    this.onLoadDiaryEnteryByCriteria();
  }

  resetFilters(): void {
    this.diaryEntryVTFilterForm.reset();
    // this.diaryEntryVTFilterForm.get('AcademicYearId').setValue(this.currentAcademicYearId);
    this.fromDate = undefined;
    this.toDate = undefined;
    this.onLoadDiaryEnteryByCriteria();
  }

  onChangeDuration(evt) {

    let duration = evt;
    switch (duration) {

      case 1:

        let todaysDate = this.datePipe.transform(this.today, 'yyyy-MM-dd');
        this.fromDate = todaysDate;
        this.toDate = todaysDate;

        break;
      case 2:
        var tdate = new Date();
        tdate.setDate(tdate.getDate() - 1);
        let yesterdaysDate = this.datePipe.transform(tdate, 'yyyy-MM-dd');
        this.fromDate = yesterdaysDate;
        this.toDate = yesterdaysDate;

        break;
      case 3:
        let wdate: Date = new Date();
        wdate.setDate(wdate.getDate() - 6);
        // date.setHours(0);
        // date.setMinutes(0);
        // date.setSeconds(0);
        // date.setMilliseconds(0);
        // let datePipe: DatePipe = new DatePipe('en-US')
        // console.log(datePipe.transform(date, 'short'));
        this.fromDate = this.datePipe.transform(wdate, 'yyyy-MM-dd');
        this.toDate = this.datePipe.transform(this.today, 'yyyy-MM-dd');

        break;
      case 4:
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.fromDate = this.datePipe.transform(firstDay, 'yyyy-MM-dd');
        this.toDate = this.datePipe.transform(lastDay, 'yyyy-MM-dd');

        break;
      case 5:
        const cdate = new Date();

        const firstDayPrevMonth = new Date(cdate.getFullYear(), cdate.getMonth() - 1, 1);
        const lastDayPrevMonth = new Date(cdate.getFullYear(), cdate.getMonth(), 0);
        this.fromDate = this.datePipe.transform(firstDayPrevMonth, 'yyyy-MM-dd');
        this.toDate = this.datePipe.transform(lastDayPrevMonth, 'yyyy-MM-dd');

        break;
      default:

    }

  }
  //Create SchoolVtpSectorFilter form and returns {FormGroup}
  createDiaryEntryVTFilterForm(): FormGroup {
    return this.formBuilder.group({
      SectorId: new FormControl(),
      EmployerId: new FormControl(),
      DurationId: new FormControl(),
    });
  }

  isGroup(index, item): boolean {
    return item.isGroupBy;
  }

  getEmployersByCriteria(): any {
    debugger;
    this.IsLoading = true;
    let data = {
      VTId: this.UserModel.UserTypeId,
      VTGuid: this.UserModel.UserTypeId,
      UserTypeId: null,
      RoleCode: this.UserModel.RoleCode,
      PageIndex: 0,
      PageSize: 10000,
      Name: null,
      CharBy: null,
      SectorGuid: null,
      StartDateTime: null,
      EndDatetime: null,
      IsActive: null
    }
    this.internshiOpportunityService.GetEmployersByCriteria(data).subscribe(response => {
      debugger;
      let empList = response.Results;
      let arr = [] as any;
      for (let i = 0; i < empList.length; i++) {
        let xxx = {
          Id: empList[i].EmployerId,
          Name: empList[i].OrganizationName,
          Description: '',
          IsSelected: false,
          IsDisabled: false,
          SequenceNo: 1
        }
        arr.push(xxx);
      }
      this.employerList = arr;
      this.filteredEmployerItems = this.employerList.slice()
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }
}

interface GroupByDateArray {
  date: string;
  isGroupBy: boolean;
}