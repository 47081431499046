<div id="profile-change-request" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row wrap" fxLayoutAlign="space-between center">


            <!-- APP TITLE -->
            <div fxLayout="row" class="screen-heading" fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)"
                fxFlex.lt-sm="100%">
                <div fxLayout="row" fxLayoutAlign="start center">

                  

                    <div fxLayout="column" fxLayoutAlign="start start"
                        [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                        <div class="h1" *ngIf="PageRights.PageType ==='Edit'">
                            <!-- {{ schoolModel.SchoolName }} -->
                            <h1> Profile Change Requests</h1>
                            
                        </div>
                      
                    </div>
                </div>
            </div>

            <!-- / APP TITLE -->

            <div fxLayout="row" class="add-edit-button" fxLayoutAlign="flex-start" fxFlex="0 1 calc(50%)"
                fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="100%">

                <button mat-raised-button class="add-school-button btn-outline-dark" [routerLink]="'/profile-change-request'"
                    fxFlex="0 1 calc(33%)" fxFlex.lt-md="0 1 calc(33%)" fxFlex.lt-sm="50%">
                    <span>Change Profile Detail Request </span>
                    
                </button>

               

                <button mat-raised-button class="save-school-button" *ngIf="PageRights.ActionType ==='edit'"
                    (click)="saveOrUpdateStudentDetails()">
                    <span>SAVE</span>
                </button>

            </div>

        </div>
        <!-- / HEADER -->
        


        <!-- CONTENT CARD -->
        <div class="content-card" >


            <!-- CONTENT -->
            <div class="content">

                <form name="studentForm" [formGroup]="studentForm" class="school w-100-p p-24 mr-24" fxLayout="column"
                    fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex fusePerfectScrollbar>

                    <div class="required-mark">
                        <span><span class="note">Note: </span>All fields marked with an asterisk (<span
                                class="asterisk-color">*</span>) are required.</span>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <p class="medium text-black-600 font-weight-semibold mt-3">Student Details</p>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Student's First Name</mat-label>
                            <input matInput required formControlName="FirstName" placeholder="Ex. Rakesh" matTooltip="First Name should be typed in Full, first letter of each word in 
                                Capital followed by small letters. Please avoid commas and dots in name."
                                matTooltipPosition="above">
                            <mat-error *ngIf="studentForm.controls.FirstName.invalid">
                                {{getErrorMessage(studentForm, 'FirstName')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Student's Middle Name</mat-label>
                            <input matInput name="middleName" formControlName="MiddleName" placeholder="Ex. Kumar">
                            <mat-error *ngIf="studentForm.controls.MiddleName.invalid">
                                {{getErrorMessage(studentForm, 'MiddleName')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Student's Last Name</mat-label>
                            <input matInput name="lastName" required formControlName="LastName" placeholder="Ex. Patel"
                                matTooltip="Last Name should be typed in Full, first letter of each word in 
                                Capital followed by small letters. Please avoid commas and dots in name."
                                matTooltipPosition="above">
                            <mat-error *ngIf="studentForm.controls.LastName.invalid">
                                {{getErrorMessage(studentForm, 'LastName')}}
                            </mat-error>
                        </mat-form-field>


                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Student's Mobile Number</mat-label>
                            <input matInput name="mobile" required formControlName="Mobile" digitOnly minlength="10"
                                maxlength="10">
                            <mat-error *ngIf="studentForm.controls.Mobile.invalid">
                                {{getErrorMessage(studentForm, 'Mobile')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>WhatsApp Mobile No.
                            </mat-label>
                            <input matInput name="mobile1" formControlName="Mobile1" digitOnly minlength="10"
                                maxlength="10">
                            <mat-error *ngIf="studentForm.controls.Mobile1.invalid">
                                {{getErrorMessage(studentForm, 'Mobile1')}}
                            </mat-error>
                        </mat-form-field>


                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Student's Gender</mat-label>
                            <mat-select name="gender" formControlName="Gender" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="genderItem.Id" *ngFor="let genderItem of genderList">
                                    {{ genderItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="studentForm.controls.Gender.invalid">
                                {{getErrorMessage(studentForm, 'Gender')}}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Student's Email ID</mat-label>
                            <input matInput name="emailId" required formControlName="EmailId">
                            <mat-error *ngIf="studentForm.controls.EmailId.invalid">
                                {{getErrorMessage(studentForm, 'EmailId')}}
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">


                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Father's Name</mat-label>
                            <input matInput name="fatherName" required formControlName="FatherName"
                                placeholder="Ex. Rakesh" matTooltip="Father's  Name should be typed in Full, first letter of each word in 
                                Capital followed by small letters. Please avoid commas and dots in name."
                                matTooltipPosition="above">
                            <mat-error *ngIf="studentForm.controls.FatherName.invalid">
                                {{getErrorMessage(studentForm, 'FatherName')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Father's Occupation</mat-label>
                            <input matInput name="fatherOccupation" required formControlName="FatherOccupation"
                                placeholder="Ex. FatherOccupation">
                            <mat-error *ngIf="studentForm.controls.FatherOccupation.invalid">
                                {{getErrorMessage(studentForm, 'FatherOccupation')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Father's Number</mat-label>
                            <input matInput name="fatherMobile" required formControlName="FatherMobile" digitOnly
                                minlength="10" maxlength="10">
                            <mat-error *ngIf="studentForm.controls.FatherMobile.invalid">
                                {{getErrorMessage(studentForm, 'FatherMobile')}}
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">


                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Mother's Name</mat-label>
                            <input matInput name="motherName" required formControlName="MotherName"
                                placeholder="Ex. Mother" matTooltip="Mother Name should be typed in Full, first letter of each word in 
                                Capital followed by small letters. Please avoid commas and dots in name."
                                matTooltipPosition="above">
                            <mat-error *ngIf="studentForm.controls.MotherName.invalid">
                                {{getErrorMessage(studentForm, 'MotherName')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Mother's Occupation</mat-label>
                            <input matInput name="MotherOccupation" required formControlName="MotherOccupation"
                                placeholder="Ex. MotherOccupation">
                            <mat-error *ngIf="studentForm.controls.MotherOccupation.invalid">
                                {{getErrorMessage(studentForm, 'MotherOccupation')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Mother's Number</mat-label>
                            <input matInput name="motherMobile" required formControlName="MotherMobile" digitOnly
                                minlength="10" maxlength="10">
                            <mat-error *ngIf="studentForm.controls.MotherMobile.invalid">
                                {{getErrorMessage(studentForm, 'MotherMobile')}}
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Date Of Birth</mat-label>
                            <input matInput [matDatepicker]="picker1" name="dateOfBirth" formControlName="DateOfBirth"
                                [max]="CurrentDate" [disabled]="PageRights.IsReadOnly" required>
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                            <mat-error *ngIf="studentForm.controls.DateOfBirth.invalid">
                                {{getErrorMessage(studentForm, 'DateOfBirth')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Registration No.</mat-label>
                            <input matInput name="registrationNo" required formControlName="RegistrationNo"
                                placeholder="Ex. RegistrationNo">
                            <mat-error *ngIf="studentForm.controls.RegistrationNo.invalid">
                                {{getErrorMessage(studentForm, 'RegistrationNo')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Guardian's Name</mat-label>
                            <input matInput name="guardianName" required formControlName="GuardianName"
                                placeholder="Ex. GuardianName">
                            <mat-error *ngIf="studentForm.controls.GuardianName.invalid">
                                {{getErrorMessage(studentForm, 'GuardianName')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <!-- <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>AadhaarNumber</mat-label>
                            <input matInput name="aadhaarNumber"  formControlName="AadhaarNumber"
                                placeholder="Ex. AadhaarNumber">
                            <mat-error *ngIf="studentForm.controls.AadhaarNumber.invalid">
                                {{getErrorMessage(studentForm, 'AadhaarNumber')}}
                            </mat-error>
                        </mat-form-field> -->

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Social Category
                            </mat-label>

                            <mat-select formControlName="SocialCategory" name="SocialCategory" required
                                appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="SocialCategory.Id"
                                    *ngFor="let SocialCategory of socialCategoryList">
                                    {{ SocialCategory.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="studentForm.controls.SocialCategory.invalid">
                                {{getErrorMessage(studentForm, 'SocialCategory')}}
                            </mat-error>
                        </mat-form-field>


                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Religion</mat-label>

                            <mat-select formControlName="Religion" name="Religion" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="Religion.Id" *ngFor="let Religion of religionList">
                                    {{ Religion.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="studentForm.controls.Religion.invalid">
                                {{getErrorMessage(studentForm, 'Religion')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Address Line 1
                            </mat-label>
                            <input matInput name="address1" required formControlName="Address1"
                                placeholder="Ex. Address1">
                            <mat-error *ngIf="studentForm.controls.Address1.invalid">
                                {{getErrorMessage(studentForm, 'Address1')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Address Line 2
                            </mat-label>
                            <input matInput name="address2" formControlName="Address2" placeholder="Ex. Address2">
                            <mat-error *ngIf="studentForm.controls.Address2.invalid">
                                {{getErrorMessage(studentForm, 'Address2')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Address Line 3
                            </mat-label>
                            <input matInput name="address3" formControlName="Address3" placeholder="Ex. Address3">
                            <mat-error *ngIf="studentForm.controls.Address3.invalid">
                                {{getErrorMessage(studentForm, 'Address3')}}
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="25" fxFlex.lt-sm="100">
                            <mat-label>State</mat-label>
                            <mat-select name="stateName" formControlName="Statecode" required
                                [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeState($event.value)">
                                <mat-option *ngFor="let stateItem of stateList" [value]="stateItem.Id">
                                    {{stateItem.Name}} ({{ stateItem.Id }})
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="studentForm.controls.Statecode.invalid">
                                {{getErrorMessage(studentForm, 'Statecode')}}
                            </mat-error>
                        </mat-form-field>


                        <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="25" fxFlex.lt-sm="100">
                            <mat-label>Division</mat-label>

                            <mat-select formControlName="DivisionId" name="divisionId" appearance="outline"
                                (selectionChange)="onChangeDivision($event.value)" [disabled]="PageRights.IsReadOnly"
                                required>
                                <mat-option [value]="DivisionItem.Id" *ngFor="let DivisionItem of divisionList">
                                    {{ DivisionItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="studentForm.controls.DivisionId.invalid">
                                {{getErrorMessage(studentForm, 'DivisionId')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="25" fxFlex.lt-sm="100">
                            <mat-label>District</mat-label>
                            <mat-select name="districtCode" formControlName="DistrictCode" required
                                [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeDistrict($event.value)">
                                <mat-option *ngFor="let districtItem of districtList" [value]="districtItem.Id">
                                    {{districtItem.Name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="studentForm.controls.DistrictCode.invalid">
                                {{getErrorMessage(studentForm, 'DistrictCode')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="25" fxFlex.lt-sm="100">
                            <mat-label>Block </mat-label>

                            <mat-select formControlName="BlockId" name="blockId" appearance="outline"
                                [disabled]="PageRights.IsReadOnly"
                                required>
                                <mat-option [value]="bItem.Id" *ngFor="let bItem of blockList">
                                    {{ bItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="studentForm.controls.BlockId.invalid">
                                {{getErrorMessage(studentForm, 'BlockId')}}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="25" fxFlex.lt-sm="100">
                            <mat-label>City</mat-label>
                            <input matInput name="City"  formControlName="City"
                                placeholder="Ex. City">
                            <!-- <mat-error *ngIf="studentForm.controls.City.invalid">
                                {{getErrorMessage(studentForm, 'City')}}
                            </mat-error> -->
                        </mat-form-field>
                       
                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="25" fxFlex.lt-sm="100">
                            <mat-label>Pincode</mat-label>
                            <input matInput name="pincode" required formControlName="Pincode" digitOnly minlength="6"
                                maxlength="6" matTooltip="Enter the 6 digit pincode." matTooltipPosition="above">
                            <mat-error *ngIf="studentForm.controls.Pincode.invalid">
                                {{getErrorMessage(studentForm, 'Pincode')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <p class="medium text-black-600 font-weight-semibold mt-3">Academic Details</p>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Select School</mat-label>

                            <mat-select formControlName="SchoolId" appearance="outline"
                                [disabled]="PageRights.IsReadOnly" required
                                (selectionChange)="onChangeClass($event.value)">
                                <mat-option [value]="schoolItem.Id" *ngFor="let schoolItem of schoolsList">
                                    {{ schoolItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="studentForm.controls.SchoolId.invalid">
                                {{getErrorMessage(studentForm, 'SchoolId')}}
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Select Class</mat-label>

                            <mat-select formControlName="ClassId" appearance="outline"
                                [disabled]="PageRights.IsReadOnly" required
                                (selectionChange)="onChangeSection($event.value)">
                                <mat-option [value]="class.Id" *ngFor="let class of classList">
                                    {{ class.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="studentForm.controls.ClassId.invalid">
                                {{getErrorMessage(studentForm, 'ClassId')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Select Section</mat-label>

                            <mat-select formControlName="SectionId" appearance="outline"
                                [disabled]="PageRights.IsReadOnly" required>
                                <mat-option [value]="section.Id" *ngFor="let section of sectionList">
                                    {{ section.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="studentForm.controls.SectionId.invalid">
                                {{getErrorMessage(studentForm, 'SectionId')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Select Academic Year</mat-label>

                            <mat-select formControlName="AcademicYearId" name="academicYearId" required
                                appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="academicYearItem.Id"
                                    *ngFor="let academicYearItem of academicyearList">
                                    {{ academicYearItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="studentForm.controls.AcademicYearId.invalid">
                                {{getErrorMessage(studentForm, 'AcademicYearId')}}
                            </mat-error>
                        </mat-form-field>


                    </div>

                


                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">


                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="25" fxFlex.lt-sm="100">
                            <mat-label>Select Subject</mat-label>
                            <mat-select name="subject" formControlName="Stream" required
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option *ngFor="let sItem of subjectList" [value]="sItem.Id">
                                    {{ sItem.Name }} 
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="studentForm.controls.Stream.invalid">
                                {{getErrorMessage(studentForm, 'Stream')}}
                            </mat-error>
                        </mat-form-field>



                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Student Roll No.
                            </mat-label>
                            <input matInput name="StudentRollNumber" required formControlName="StudentRollNumber"
                                placeholder="Ex. StudentRollNumber">
                            <mat-error *ngIf="studentForm.controls.StudentRollNumber.invalid">
                                {{getErrorMessage(studentForm, 'StudentRollNumber')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Vocational Subject
                            </mat-label>
                           
                            <mat-select name="VocationalSubject" formControlName="VocationalSubject" required
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option *ngFor="let vocationalSubjectItem of vocationalSubjectList"
                                    [value]="vocationalSubjectItem.Id">
                                    {{ vocationalSubjectItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="studentForm.controls.VocationalSubject.invalid">
                                {{getErrorMessage(studentForm, 'VocationalSubject')}}
                            </mat-error>
                        </mat-form-field>
                        <!-- <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>VocationalSubject</mat-label>
                            <input matInput name="VocationalSubject" required formControlName="VocationalSubject"
                                placeholder="Ex. VocationalSubject">
                            <mat-error *ngIf="studentForm.controls.VocationalSubject.invalid">
                                {{getErrorMessage(studentForm, 'VocationalSubject')}}
                            </mat-error>
                        </mat-form-field> -->
                        <!-- <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>CWSNStatus</mat-label>
                            <input matInput name="CWSNStatus" required formControlName="CWSNStatus"
                                placeholder="Ex. CWSNStatus">
                            <mat-error *ngIf="studentForm.controls.CWSNStatus.invalid">
                                {{getErrorMessage(studentForm, 'CWSNStatus')}}
                            </mat-error>
                        </mat-form-field> -->
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="25" fxFlex.lt-sm="100">
                            <mat-label>CWSN Status</mat-label>
                            <mat-select name="cWSNStatus" formControlName="CWSNStatus" required
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option *ngFor="let sItem of cwList" [value]="sItem.Id">
                                    {{ sItem.Name }} 
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="studentForm.controls.CWSNStatus.invalid">
                                {{getErrorMessage(studentForm, 'CWSNStatus')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <span class="checkbox-space">Is Active?</span>
                        <mat-checkbox matInput formControlName="IsActive" [disabled]="PageRights.IsReadOnly">
                        </mat-checkbox>
                    </div>

                    <!-- </mat-tab>
                    </mat-tab-group> -->
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->


    

    </div>
    <!-- / CENTER -->

</div>