import { Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { BaseComponent } from 'app/common/base/base.component';
import { environment } from 'environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from 'app/services/common.service';
import { RouteConstants } from 'app/constants/route.constant';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { ChangePasswordService } from 'app/auth/change-password/change-password.service';
import { FuseUtils } from '@fuse/utils';
import { HttpUrlEncodingCodec } from '@angular/common/http';

@Component({
  selector: 'forgot-password-otp',
  templateUrl: './forgot-password-otp.component.html',
  styleUrls: ['./forgot-password-otp.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ForgotPasswordOTPComponent extends BaseComponent<any> implements OnInit {
  EnteredOtpForm: FormGroup;
  codec = new HttpUrlEncodingCodec;
  display: any;
  ResetEmailID:any
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private dialogService: DialogService,
    private accountService: ChangePasswordService,
    private fuseConfigService: FuseConfigService,
    private formBuilder: FormBuilder
  ) {
    super(commonService, router, routeParams, snackBar);

    // Configure the layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
  }

  ngOnInit(): void {
    this.EnteredOtpForm = this.createForgotPasswordForm();
    this.ResetEmailID=JSON.parse(localStorage.getItem('ResetEmailID'));
    let otpData=JSON.parse(localStorage.getItem('logResp'));
    let OtpExpirationDate = new Date(otpData);
    let currentDate = new Date();

    if (OtpExpirationDate < currentDate) {
      this.router.navigateByUrl(RouteConstants.Account.ForgotPassword);



    }else{
      this.timer(5);

    }
  }

  forgotPasswordLink(): void {
    let EnteredOtp = this.EnteredOtpForm.get('EnteredOtp').value;

    this.accountService.VerifyOtpEmail({EmailID : this.ResetEmailID, EnteredOtp: EnteredOtp })
      .subscribe((passwordResp: any) => {

        if (passwordResp.Success) {
          this.zone.run(() => {
            // this.showActionMessage(
            //   this.Constants.Messages.EmailSentForgotPasswordMessage,
            //   this.Constants.Html.SuccessSnackbar
            // );
            var Data={
              UserTypeId:passwordResp.Result.UserTypeId,
              AuthToken: 'Bearer '+ passwordResp.Result.AuthToken,
            }
            localStorage.setItem('UserTypeId', JSON.stringify(Data));

            this.router.navigateByUrl(RouteConstants.Account.ForgotPasswordNewPass);

          })
        }
        else {
          var errorMessages = this.getHtmlMessage(passwordResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('Forgot password errors =>', error);
      });
  }

  //Create forgetPassword form and returns {FormGroup}
  createForgotPasswordForm(): FormGroup {
    return this.formBuilder.group({
      // EmailId: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.pattern(this.Constants.Regex.Email)])
      EnteredOtp: new FormControl({ value: '', disabled: false }, [Validators.required,Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),

    });
  }

  Resend(): void {
    this.accountService.forgotPassword({ EmailId: this.ResetEmailID,})
      .subscribe((passwordResp: any) => {
        if (passwordResp.Success) {
          this.zone.run(() => {
            localStorage.setItem('ResetEmailID', JSON.stringify(this.ResetEmailID));
            localStorage.setItem('logResp', JSON.stringify(passwordResp.Result.OtpExpirationDate));
            this.router.navigateByUrl(RouteConstants.Account.ForgotPasswordOTP);
          })
        }
        else {
          var errorMessages = this.getHtmlMessage(passwordResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('Forgot password errors =>', error);
      });
  }
  timer(minute) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = minute < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        console.log("finished");
        clearInterval(timer);
      }
    }, 1000);
  }
}
