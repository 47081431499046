import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';
import { UserModel } from "app/models/user.model";

@Injectable()
export class GratitudeService {
    constructor(private http: BaseService) { }

    getSchools(): Observable<any> {
        return this.http
            .HttpGet(this.http.Services.School.GetAll)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetAllByCriteria(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.Gratitude.GetVTGratitudeByCriteria, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    ApprovedEmployerGratitude(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.Gratitude.GetVTApprovedEmployerGratitudeByCriteria, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    NewEmployerGratitude(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.Gratitude.GetNewEmployerGratitudeByCriteria, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetStudentsById(requestParams: any) {


        return this.http.HttpPost(this.http.Services.Students.GetStudentsByStudentIdAY, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }


    AcceptGratitude(GratitudeId: string) {
        let gratitudeParams = {
            DataId: GratitudeId
        };

        return this.http
            .HttpPost(this.http.Services.Gratitude.AcceptGratitude, gratitudeParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Result;
                })
            );
    }

    RejectGratitude(GratitudeId: string) {
        let gratitudeParams = {
            DataId: GratitudeId
        };

        return this.http
            .HttpPost(this.http.Services.Gratitude.RejectGratitude, gratitudeParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Result;
                })
            );
    }

    getDropdownforSchool(userModel: UserModel): Observable<any[]> {
        let schoolCategoryRequest = this.http.GetMasterDataByType({ DataType: 'SchoolCategories', SelectTitle: 'School Category' });
        let academicYearRequest = this.http.GetMasterDataByType({ DataType: 'AcademicYearsByState', UserId: userModel.UserTypeId, SelectTitle: 'Academic Year' });
        let schoolTypeRequest = this.http.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'SchoolType', SelectTitle: 'School Type' });
        let stateRequest = this.http.GetMasterDataByType({ DataType: 'StatesByUserId', UserId: userModel.UserTypeId, SelectTitle: 'StateCode' });
        let schoolManagementRequest = this.http.GetMasterDataByType({ DataType: 'BasicList', ParentId: 'SchoolManagement', SelectTitle: 'School Management' });
        let statecodeRequest = this.http.GetMasterDataByType({ DataType: 'StatesByUserId', UserId: userModel.UserTypeId, SelectTitle: 'StateCode' });
        let genderRequest = this.http.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'StudentGender', SelectTitle: 'Gender' });
        let schoolRequest = this.http.GetMasterDataByType({ DataType: userModel.RoleCode === 'VT' ? 'SchoolsByVT' : 'Schools', UserId: userModel.UserTypeId, ParentId: userModel.RoleCode === 'VT' ? userModel.UserTypeId : null, SelectTitle: 'School' });
        let socialCategoryRequest = this.http.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'SocialCategory', SelectTitle: 'Social Category' });
        let religionRequest = this.http.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'Religion', SelectTitle: 'Religion' });
        let vtpRequest = this.http.GetMasterDataByType({ DataType: 'VocationalTrainers', SelectTitle: 'VT' });
        let sectorRequest = this.http.GetMasterDataByType({ DataType: 'Sectors', SelectTitle: 'Sector' });
        let streamRequest = this.http.GetMasterDataByType({ DataType: 'Stream', SelectTitle: 'Stream' },
            false);




        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        return forkJoin([schoolCategoryRequest, academicYearRequest, schoolTypeRequest, stateRequest, schoolManagementRequest, statecodeRequest, genderRequest, schoolRequest, socialCategoryRequest, religionRequest, vtpRequest, sectorRequest, streamRequest]);
    }

    getDropdownforInternshipOpportunity(userModel: UserModel): Observable<any[]> {
        let sectorRequest = this.http.GetMasterDataByType({ DataType: 'Sectors', SelectTitle: 'Sector' }, false);
        let employerRequest = this.http.GetMasterDataByType({ DataType: 'Employers', SelectTitle: 'Employer' });
        let districtRequest = this.http.GetMasterDataByType({ DataType: 'Districts', SelectTitle: 'District' });
        let academicYearRequest = this.http.GetMasterDataByType({ DataType: 'AcademicYearsByState', UserId: userModel.UserTypeId, SelectTitle: 'Academic Year' });
        let branchRequest = this.http.GetMasterDataByType({ DataType: 'EmpBranch', ParentId: 'EmployeerId', SelectTitle: 'Branch Name' })
        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        return forkJoin([sectorRequest, employerRequest, districtRequest, academicYearRequest, branchRequest]);
    }
}
