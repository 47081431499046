import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { RouteConstants } from 'app/constants/route.constant'
import { ToolEquipmentService } from '../tool-equipment.service';
import { ToolEquipmentModel } from '../tool-equipment.model';
import { DropdownModel } from 'app/models/dropdown.model';

@Component({
  selector: 'tool-equipment',
  templateUrl: './create-tool-equipment.component.html',
  styleUrls: ['./create-tool-equipment.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CreateToolEquipmentComponent extends BaseComponent<ToolEquipmentModel> implements OnInit {
  toolEquipmentForm: FormGroup;
  toolEquipmentModel: ToolEquipmentModel;

  academicYearList: [DropdownModel];
  sectorList: [DropdownModel];
  jobRoleList: [DropdownModel];

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private route: ActivatedRoute,
    private toolEquipmentService: ToolEquipmentService,
    private dialogService: DialogService,
    private formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Set the default toolEquipment Model
    this.toolEquipmentModel = new ToolEquipmentModel();
  }

  ngOnInit(): void {

    this.toolEquipmentService.getAcademicYearClass(this.UserModel).subscribe(results => {
      if (results[0].Success) {
        this.academicYearList = results[0].Results;
      }

      if (results[1].Success) {
        this.sectorList = results[1].Results;
      }

      this.route.paramMap.subscribe(params => {
        if (params.keys.length > 0) {
          this.PageRights.ActionType = params.get('actionType');

          if (this.PageRights.ActionType == this.Constants.Actions.New) {
            this.toolEquipmentModel = new ToolEquipmentModel();

          } else {
            var toolEquipmentId: string = params.get('toolEquipmentId')

            this.toolEquipmentService.getToolEquipmentById(toolEquipmentId)
              .subscribe((response: any) => {
                this.toolEquipmentModel = response.Result;

                if (this.PageRights.ActionType == this.Constants.Actions.Edit)
                  this.toolEquipmentModel.RequestType = this.Constants.PageType.Edit;
                else if (this.PageRights.ActionType == this.Constants.Actions.View) {
                  this.toolEquipmentModel.RequestType = this.Constants.PageType.View;
                  this.PageRights.IsReadOnly = true;
                }

                this.onChangeSector(this.toolEquipmentModel.SectorId);
                this.toolEquipmentForm = this.createToolEquipmentForm();
              });
          }
        }
      });
    });

    this.toolEquipmentForm = this.createToolEquipmentForm();
  }

  onChangeSector(sectorId: string) {
    this.commonService.GetMasterDataByType({ DataType: 'JobRolesForVT', ParentId: sectorId, SelectTitle: 'Job Role' }).subscribe(response => {
      if (response.Success) {
        this.jobRoleList = response.Results;
      }
    });
  }

  saveOrUpdateToolEquipmentDetails() {
    if (!this.toolEquipmentForm.valid) {
      this.validateAllFormFields(this.toolEquipmentForm);
      return;
    }
    this.setValueFromFormGroup(this.toolEquipmentForm, this.toolEquipmentModel);
    this.toolEquipmentModel.VTId = this.UserModel.UserTypeId;

    this.toolEquipmentService.createOrUpdateToolEquipment(this.toolEquipmentModel)
      .subscribe((toolEquipmentResp: any) => {

        if (toolEquipmentResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );

            this.router.navigate([RouteConstants.ToolEquipment.List]);
          });
        }
        else {
          var errorMessages = this.getHtmlMessage(toolEquipmentResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('ToolEquipment deletion errors =>', error);
      });
  }

  //Create toolEquipment form and returns {FormGroup}
  createToolEquipmentForm(): FormGroup {
    return this.formBuilder.group({
      ToolEquipmentId: new FormControl(this.toolEquipmentModel.ToolEquipmentId),
      AcademicYearId: new FormControl({ value: this.toolEquipmentModel.AcademicYearId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      SectorId: new FormControl({ value: this.toolEquipmentModel.SectorId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      JobRoleId: new FormControl({ value: this.toolEquipmentModel.JobRoleId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      ReceiptDate: new FormControl({ value: this.getDateValue(this.toolEquipmentModel.ReceiptDate), disabled: this.PageRights.IsReadOnly }),
      TEReceiveStatus: new FormControl({ value: this.toolEquipmentModel.TEReceiveStatus, disabled: this.PageRights.IsReadOnly }, Validators.required),
      TEStatus: new FormControl({ value: this.toolEquipmentModel.TEStatus, disabled: this.PageRights.IsReadOnly }),
      RMStatus: new FormControl({ value: this.toolEquipmentModel.RMStatus, disabled: this.PageRights.IsReadOnly }, Validators.required),
      RMFundStatus: new FormControl({ value: this.toolEquipmentModel.RMFundStatus, disabled: this.PageRights.IsReadOnly }, Validators.required),
      Details: new FormControl({ value: this.toolEquipmentModel.Details, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(350)),
    });
  }

  onChangeOnTEReceiveStatusType(chk) {    
    if (chk.value == "No") {
      this.toolEquipmentForm.controls["ReceiptDate"].clearValidators();
      this.toolEquipmentForm.controls["TEStatus"].clearValidators();
    }
    else {
      this.toolEquipmentForm.controls["ReceiptDate"].setValidators([Validators.required]);
      this.toolEquipmentForm.controls["TEStatus"].setValidators([Validators.required]);
    }

    this.toolEquipmentForm.controls["ReceiptDate"].updateValueAndValidity();
    this.toolEquipmentForm.controls["TEStatus"].updateValueAndValidity();
  }
}
