import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { RouteConstants } from 'app/constants/route.constant'
import { VTStudentExitSurveyDetailService } from '../vt-student-exit-survey-detail.service';
import { VTStudentExitSurveyDetailModel } from '../vt-student-exit-survey-detail.model';
import { VTStudentDetailModel } from '../vt-student-detail.model';
import { DropdownModel } from 'app/models/dropdown.model';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from "moment";

@Component({
  selector: 'vt-student-exit-survey-detail',
  templateUrl: './create-vt-student-exit-survey-detail.component.html',
  styleUrls: ['./create-vt-student-exit-survey-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class CreateVTStudentExitSurveyDetailComponent extends BaseComponent<VTStudentExitSurveyDetailModel> implements OnInit {
  vtStudentExitSurveyDetailForm: FormGroup;
  vtStudentDetailForm: FormGroup;
  vtStudentExitSurveyDetailModel: VTStudentExitSurveyDetailModel;
  vtStudentDetailModel: VTStudentDetailModel;
  studentList: [DropdownModel];
  genderList: any;
  migrationReasonList: any;
  parentsOccupationList: any;
  currentEmployementStatusList: any;
  courseList: any;
  courseSelectionReasonList: any;
  postCourseCpmletionPlanningList: any;
  educationDiscontinueReasonList: any;
  plansList: any;
  age: number;
  districtList: any;
  classList: any;
  socialCategoryList: any;
  sectorList: any;
  sectorFilteredOptions: any;
  jobRoleList: any;
  vtpList: any;
  blockList: any;
  natureOfWorkList: any;
  sectorOfEmployementList: any;
  courseToBePursueList: any;
  streamOfEducationList: any;
  veNotContinuingReasonsList: any;
  topicsOfJobInterestList: any;
  preferredLocationForEmploymentList: any;
  divisionId: string;
  StudentDetail: any;
  studentFullName: any;
  IsDisabled: boolean;
  ExitStudentId: any;
  YesNoDropdown: any = [{ "key": "Yes", "value": "1" }, { "key": "No", "value": "0" }];
  InterestedInJob: any = [{ "key": "Yes", "value": "1" }, { "key": "No", "value": "0" }, { "key": "Undecided", "value": "2" }];
  className: any;
  divisionList: any;
  stateCode: string;
  schoolList: any;
  studentslist: any;
  StudentModel: any;
  IsSectorDisabled: boolean = true;
  finalClassId: string;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private route: ActivatedRoute,
    private vtStudentExitSurveyDetailService: VTStudentExitSurveyDetailService,
    private dialogService: DialogService,
    private formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Set the default vtStudentExitSurveyDetail Model
    this.vtStudentExitSurveyDetailModel = new VTStudentExitSurveyDetailModel();
  }

  ngOnInit(): void {

    let today = new Date(Date.now());
    let currentYear = today.getFullYear();
    let lastYear = today.getFullYear() - 1;
    let academicYr = lastYear + '-' + currentYear;


    this.stateCode = this.UserModel.DefaultStateId;

    this.commonService.GetMasterDataByType({ DataType: 'DistrictsBySateCode', ParentId: this.stateCode, SelectTitle: 'District' }).subscribe((response: any) => {
      this.districtList = response.Results;
    });

    this.commonService.GetMasterDataByType({ DataType: 'Sectors', SelectTitle: 'Sector' }).subscribe((response: any) => {
      this.sectorList = response.Results;
    });

    this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'NatureOfWork', SelectTitle: 'Nature Of Work' }).subscribe((response: any) => {
      this.natureOfWorkList = response.Results
    });

    this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'SectorsOfEmployment', SelectTitle: 'Sector Of Employment' }).subscribe((response: any) => {
      this.sectorOfEmployementList = response.Results
    });

    this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'VENotContinuingReasons', SelectTitle: 'The Reason' }).subscribe((response: any) => {
      this.veNotContinuingReasonsList = response.Results
    });

    this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'TopicsOfJobInterest', SelectTitle: 'Your Interest' }).subscribe((response: any) => {
      this.topicsOfJobInterestList = response.Results
    });

    this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'PreferredLocationForEmployment', SelectTitle: 'Preferred Location For Employment' }).subscribe((response: any) => {
      this.preferredLocationForEmploymentList = response.Results

    });

    this.route.paramMap.subscribe(params => {
      if (params.keys.length > 0) {
        this.PageRights.ActionType = params.get('actionType');

        if (this.PageRights.ActionType == this.Constants.Actions.New) {
          this.vtStudentExitSurveyDetailModel = new VTStudentExitSurveyDetailModel();

        }
        if (this.PageRights.ActionType == this.Constants.Actions.Add) {
          var exitStudentId: string = params.get('exitStudentId');
          var academicYearId: string = params.get('academicYear');
          var classId: string = params.get('classId');

          if(classId == 'Class 10')
          {
             this.finalClassId = "3d99b3d3-f955-4e8f-9f2e-ec697a774bbc";
          }
          if(classId == 'Class 12')
          {
            this.finalClassId = "e0302e36-a8a7-49a0-b621-21d48986c43e";
          }

          if (academicYearId == '2020-2021') {
            this.IsSectorDisabled = false;
            let ReqObj = {
              "UserId": this.UserModel.UserTypeId,
              "UserType": this.UserModel.RoleCode,
              "AcademicYear": academicYearId
            };
            this.vtStudentExitSurveyDetailService.GetAllByCriteria(ReqObj).subscribe(response => {
              this.studentslist = response.Results;
              if (response.Results != null) {
                this.StudentModel = this.studentslist.find(student => student.ExitStudentId == exitStudentId);

              }
              this.IsLoading = false;
            }, error => {
              console.log(error);
            });
          }

          let ReqObj = {
            "UserId": this.UserModel.UserTypeId,
            "UserType": this.UserModel.RoleCode,
            "AcademicYear": academicYearId,
            "StudentId": exitStudentId,
            "ClassId": this.finalClassId,
            "PageIndex": 0,
            "PageSize": 1
          };

          this.vtStudentExitSurveyDetailService.getVTStudentExitSurveyReportById(ReqObj).subscribe((response: any) => {
            if (response.Success) {
              this.StudentDetail = response.Results[0];
              this.ExitStudentId = this.StudentDetail.ExitStudentId;
              this.IsDisabled = true;
            }

            this.className = this.StudentDetail.Class;

            if (this.className == 'Class 10') {
              this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'CourseToBePursueFor10th', SelectTitle: 'The Course' }).subscribe((response: any) => {
                this.courseToBePursueList = response.Results;
              });

              this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'StreamOfEducation', SelectTitle: 'Stream Of Education' }).subscribe((response: any) => {
                this.streamOfEducationList = response.Results;
              });
            }

            if (this.className == 'Class 12') {
              this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'CourseToBePursueFor12th', SelectTitle: 'The Course' }).subscribe((response: any) => {
                this.courseToBePursueList = response.Results;
              });

              this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'StreamOfEducationFor12th', SelectTitle: 'Stream Of Education' }).subscribe((response: any) => {
                this.streamOfEducationList = response.Results;
              });
            }
            this.vtStudentExitSurveyDetailForm = this.createVTStudentExitSurveyDetailForm();
            this.vtStudentExitSurveyDetailForm.controls.studentDetailGroup.get("StudentFullName").setValue(this.StudentDetail.StudentFullName);
            this.vtStudentExitSurveyDetailForm.controls.studentDetailGroup.get("FatherName").setValue(this.StudentDetail.FatherName);
            this.vtStudentExitSurveyDetailForm.controls.studentDetailGroup.get("StudentUniqueId").setValue(this.StudentDetail.StudentUniqueId);
            this.vtStudentExitSurveyDetailForm.controls.studentDetailGroup.get("NameOfSchool").setValue(this.StudentDetail.NameOfSchool);
            this.vtStudentExitSurveyDetailForm.controls.studentDetailGroup.get("UdiseCode").setValue(this.StudentDetail.UdiseCode);
            this.vtStudentExitSurveyDetailForm.controls.studentDetailGroup.get("District").setValue(this.StudentDetail.District);
            this.vtStudentExitSurveyDetailForm.controls.studentDetailGroup.get("Class").setValue(this.StudentDetail.Class);
            this.vtStudentExitSurveyDetailForm.controls.studentDetailGroup.get("Gender").setValue(this.StudentDetail.Gender);
            if (this.StudentDetail.DOB != null) {
              this.vtStudentExitSurveyDetailForm.controls.studentDetailGroup.get("DOB").setValue(new Date(this.StudentDetail.DOB));
            }
            else {
              this.vtStudentExitSurveyDetailForm.controls.studentDetailGroup.get("DOB").setValue(this.StudentDetail.DOB);
            }

            this.vtStudentExitSurveyDetailForm.controls.studentDetailGroup.get("Category").setValue(this.StudentDetail.Category);

            let sector = this.sectorList.find(s => s.Name.toLowerCase() == this.StudentDetail.Sector.toLowerCase());
            this.vtStudentExitSurveyDetailForm.controls.studentDetailGroup.get("Sector").setValue(sector.Name);

            this.onChangeSector(sector.Name).then(response => {
              let jobRole = this.jobRoleList.find(s => s.Name.toLowerCase() == this.StudentDetail.JobRole.toLowerCase());
              this.vtStudentExitSurveyDetailForm.controls.studentDetailGroup.get("JobRole").setValue(jobRole.Name);
            });

            this.vtStudentExitSurveyDetailForm.controls.studentDetailGroup.get("VTPId").setValue(this.StudentDetail.VTPName);
          });
        }
        if (this.PageRights.ActionType == this.Constants.Actions.Edit) {
          var exitStudentId: string = params.get('exitStudentId');
          var academicYearId: string = params.get('academicYear');
          var classId : string = params.get('classId');

          if(classId == 'Class 10')
          {
            this.finalClassId = "3d99b3d3-f955-4e8f-9f2e-ec697a774bbc";
          }
          if(classId == 'Class 12')
          {
            this.finalClassId = "e0302e36-a8a7-49a0-b621-21d48986c43e";
          }

          if (academicYearId == '2020-2021') {
            this.IsSectorDisabled = false;
            let ReqObj = {
              "UserId": this.UserModel.UserTypeId,
              "UserType": this.UserModel.RoleCode,
              "AcademicYear": academicYearId

            };
            this.vtStudentExitSurveyDetailService.GetAllByCriteria(ReqObj).subscribe(response => {
              this.studentslist = response.Results;
              if (response.Results != null) {
                this.StudentModel = this.studentslist.find(student => student.ExitStudentId == exitStudentId);

              }
              this.IsLoading = false;
            }, error => {
              console.log(error);
            });
          }

          let ReqObj = {
            "UserId": this.UserModel.UserTypeId,
            "UserType": this.UserModel.RoleCode,
            "AcademicYear": academicYearId,
            "StudentId": exitStudentId,
            "ClassId": this.finalClassId,
            "PageIndex": 0,
            "PageSize": 1
          };

          this.vtStudentExitSurveyDetailService.getVTStudentExitSurveyReportById(ReqObj).subscribe((response: any) => {
            if (response.Success) {

              this.StudentDetail = response.Results[0];
              this.ExitStudentId = this.StudentDetail.ExitStudentId;
              this.IsDisabled = true;
            }
            this.className = this.StudentDetail.Class;
            if (this.className == 'Class 10') {
              this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'CourseToBePursueFor10th', SelectTitle: 'The Course' }).subscribe((response: any) => {
                this.courseToBePursueList = response.Results;
              });

              this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'StreamOfEducation', SelectTitle: 'Stream Of Education' }).subscribe((response: any) => {
                this.streamOfEducationList = response.Results;

              });
            }

            if (this.className == 'Class 12') {
              this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'CourseToBePursueFor12th', SelectTitle: 'The Course' }).subscribe((response: any) => {
                this.courseToBePursueList = response.Results
              });
              this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'StreamOfEducationFor12th', SelectTitle: 'Stream Of Education' }).subscribe((response: any) => {
                this.streamOfEducationList = response.Results

              });
            }
      

              this.commonService.GetMasterDataByType({ DataType: 'DistrictsBySateCode', ParentId: this.stateCode, SelectTitle: 'District' }).subscribe((response: any) => {
                this.districtList = response.Results;
                this.onChangeDistrict(this.StudentDetail.DistrictOfResidence);
            });

            this.StudentDetail.SectorsInterestedIn = this.StudentDetail.SectorsInterestedIn.split(',');
            this.StudentDetail.TopicsOfInterest = this.StudentDetail.TopicsOfInterest.split(',');
            this.vtStudentExitSurveyDetailForm = this.createVTStudentExitSurveyDetailForm();
            this.setValueToVTStudentExitSurveyDetails();

          });
        }

        if (this.PageRights.ActionType == this.Constants.Actions.View) {
          this.PageRights.IsReadOnly = true;
          var exitStudentId: string = params.get('exitStudentId');
          var academicYearId: string = params.get('academicYear');
          var classId: string = params.get('classId');

          if(classId == 'Class 10')
          {
             this.finalClassId = "3d99b3d3-f955-4e8f-9f2e-ec697a774bbc";
          }
          if(classId == 'Class 12')
          {
            this.finalClassId = "e0302e36-a8a7-49a0-b621-21d48986c43e";
          }

          let ReqObj = {
            "UserId": this.UserModel.UserTypeId,
            "UserType": this.UserModel.RoleCode,
            "AcademicYear": academicYearId,
            "StudentId": exitStudentId,
            "ClassId": this.finalClassId,
            "PageIndex": 0,
            "PageSize": 1
          };

          this.vtStudentExitSurveyDetailService.getVTStudentExitSurveyReportById(ReqObj).subscribe((response: any) => {
            if (response.Success) {

              this.StudentDetail = response.Results[0];
              this.ExitStudentId = this.StudentDetail.ExitStudentId;
              this.IsDisabled = true;
            }
            this.className = this.StudentDetail.Class;
            if (this.className == 'Class 10') {
              this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'CourseToBePursueFor10th', SelectTitle: 'The Course' }).subscribe((response: any) => {
                this.courseToBePursueList = response.Results;
              });

              this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'StreamOfEducation', SelectTitle: 'Stream Of Education' }).subscribe((response: any) => {
                this.streamOfEducationList = response.Results;

              });
            }

            if (this.className == 'Class 12') {
              this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'CourseToBePursueFor12th', SelectTitle: 'The Course' }).subscribe((response: any) => {
                this.courseToBePursueList = response.Results
              });
              this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'StreamOfEducationFor12th', SelectTitle: 'Stream Of Education' }).subscribe((response: any) => {
                this.streamOfEducationList = response.Results

              });
            }
      
              this.commonService.GetMasterDataByType({ DataType: 'DistrictsBySateCode', ParentId: this.stateCode, SelectTitle: 'District' }).subscribe((response: any) => {
                this.districtList = response.Results;
                this.onChangeDistrict(this.StudentDetail.DistrictOfResidence);
              
            });
            this.StudentDetail.SectorsInterestedIn = this.StudentDetail.SectorsInterestedIn.split(',');
            this.StudentDetail.TopicsOfInterest = this.StudentDetail.TopicsOfInterest.split(',');
            this.vtStudentExitSurveyDetailForm = this.createVTStudentExitSurveyDetailForm();
            this.setValueToVTStudentExitSurveyDetails();

          });
        }
      }
    });

    this.vtStudentExitSurveyDetailForm = this.createVTStudentExitSurveyDetailForm();

  }

  onChangeDistrict(districtId: any) {
    let district = this.districtList.find(d => d.Name == districtId);
    this.commonService.GetMasterDataByType({ DataType: 'Blocks', UserId: this.UserModel.DefaultStateId, ParentId: district.Id, SelectTitle: 'Block' }).subscribe((response: any) => {
      this.blockList = response.Results;
    });
  }

  onChangeSector(sectorId): Promise<any> {
    return new Promise((resolve, reject) => {
      let sectorItem = this.sectorList.find(s => s.Name == sectorId);
      this.commonService.GetMasterDataByType({ DataType: 'JobRoles', ParentId: sectorItem.Id, SelectTitle: "Job Role" }).subscribe((response) => {
        if (response.Success) {
          this.jobRoleList = response.Results;
        }

        resolve(true);
      });
    });
  }

  onChangeCurrentlyEmployed(currentlyEmployed) {
    if (currentlyEmployed == 0) {
      this.vtStudentExitSurveyDetailForm.get("DetailsOfEmployment").setValue("");
      this.vtStudentExitSurveyDetailForm.get("IsFullTime").setValue("");
      this.vtStudentExitSurveyDetailForm.get("SectorsOfEmployment").setValue("");
      this.vtStudentExitSurveyDetailForm.get("IsRelevantToVocCourse").setValue("");
    }
  }

  onChangeHigherStudies(higherStudies) {
    if (higherStudies == 0) {
      this.vtStudentExitSurveyDetailForm.get("WillBeFullTime").setValue("");
      this.vtStudentExitSurveyDetailForm.get("CourseToPursue").setValue("");
      this.vtStudentExitSurveyDetailForm.get("OtherCourse").setValue("");
      this.vtStudentExitSurveyDetailForm.get("StreamOfEducation").setValue("");
    }
  }
  onChangeCourseToPursue(course) {
    if (course !== 'Other') {
      this.vtStudentExitSurveyDetailForm.get("OtherCourse").setValue("");
    }
  }
  onChangeWillContVocEdu(VocEdu) {
    if (VocEdu == 0) {
      this.vtStudentExitSurveyDetailForm.get("WillContSameSector").setValue("");
    }
    if (VocEdu == 1) {
      this.vtStudentExitSurveyDetailForm.get("ReasonsNOTToContinue").setValue("");
      this.vtStudentExitSurveyDetailForm.get("OtherReasons").setValue("");
    }
  }

  onChangeInterestedInSkillDevelopmentPgms(isIntInSkillsDevlpmnt) {
    if (isIntInSkillsDevlpmnt == 0) {
      this.vtStudentExitSurveyDetailForm.get("SectorsInterestedIn").setValue("");
    }
  }

  onChangeInterestedInJobOrSE(isInterestedInJob) {
    if (isInterestedInJob == 0) {
      this.vtStudentExitSurveyDetailForm.get("TopicsOfInterest").setValue("");
    }
  }

  onChangeAnyPreferredLoc(loc) {
    if (loc !== '348') {
      this.vtStudentExitSurveyDetailForm.get("PreferredLocations").setValue("");
    }
  }

  saveOrUpdateVTStudentExitSurveyDetailDetails() {
    if (!this.vtStudentExitSurveyDetailForm.valid) {
      this.validateAllFormFields(this.vtStudentExitSurveyDetailForm);
      return;
    }

    if (this.IsSectorDisabled == false) {
      this.StudentModel.Sector = this.vtStudentExitSurveyDetailForm.controls.studentDetailGroup.get("Sector").value;
      this.StudentModel.JobRole = this.vtStudentExitSurveyDetailForm.controls.studentDetailGroup.get("JobRole").value;
      this.vtStudentExitSurveyDetailService.createOrUpdateExitStudentDetail(this.StudentModel)
        .subscribe((vtStudentExitSurveyDetailResp: any) => {

          if (vtStudentExitSurveyDetailResp.Success) {
            this.zone.run(() => {
              this.showActionMessage(
                this.Constants.Messages.RecordSavedMessage,
                this.Constants.Html.SuccessSnackbar
              );

              this.router.navigate([RouteConstants.VTStudentExitSurveyDetail.List]);
            });
          }
          else {
            var errorMessages = this.getHtmlMessage(vtStudentExitSurveyDetailResp.Errors)
            this.dialogService.openShowDialog(errorMessages);
          }
        }, error => {
          console.log('VTStudentExitSurveyDetail deletion errors =>', error);
        });
    }

    this.vtStudentExitSurveyDetailForm.removeControl('studentDetailGroup');

    let topicOfInterest = this.vtStudentExitSurveyDetailForm.get('TopicsOfInterest').value;
    let sectorsInterestedIn = this.vtStudentExitSurveyDetailForm.get('SectorsInterestedIn').value;
    this.setValueFromFormGroup(this.vtStudentExitSurveyDetailForm, this.vtStudentExitSurveyDetailModel);
    if (topicOfInterest !== null && topicOfInterest !== "") {
      this.vtStudentExitSurveyDetailModel.TopicsOfInterest = topicOfInterest.join(',');

    }
    if (sectorsInterestedIn !== null && sectorsInterestedIn !== "") {
      this.vtStudentExitSurveyDetailModel.SectorsInterestedIn = sectorsInterestedIn.join(',');
    }
    this.vtStudentExitSurveyDetailModel.VTId = this.UserModel.UserTypeId;
    this.vtStudentExitSurveyDetailModel.ExitStudentId = this.ExitStudentId;

    this.vtStudentExitSurveyDetailService.createOrUpdateVTStudentExitSurveyDetail(this.vtStudentExitSurveyDetailModel)
      .subscribe((vtStudentExitSurveyDetailResp: any) => {

        if (vtStudentExitSurveyDetailResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );

            this.router.navigate([RouteConstants.VTStudentExitSurveyDetail.List]);
          });
        }
        else {
          var errorMessages = this.getHtmlMessage(vtStudentExitSurveyDetailResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('VTStudentExitSurveyDetail deletion errors =>', error);
      });

  }

  setValueToVTStudentExitSurveyDetails() {
    this.vtStudentExitSurveyDetailForm.controls.studentDetailGroup.get("StudentFullName").setValue(this.StudentDetail.StudentFullName);
    this.vtStudentExitSurveyDetailForm.controls.studentDetailGroup.get("FatherName").setValue(this.StudentDetail.FatherName);
    this.vtStudentExitSurveyDetailForm.controls.studentDetailGroup.get("StudentUniqueId").setValue(this.StudentDetail.StudentUniqueId);
    this.vtStudentExitSurveyDetailForm.controls.studentDetailGroup.get("NameOfSchool").setValue(this.StudentDetail.NameOfSchool);
    this.vtStudentExitSurveyDetailForm.controls.studentDetailGroup.get("UdiseCode").setValue(this.StudentDetail.UdiseCode);
    this.vtStudentExitSurveyDetailForm.controls.studentDetailGroup.get("District").setValue(this.StudentDetail.District);
    this.vtStudentExitSurveyDetailForm.controls.studentDetailGroup.get("Class").setValue(this.StudentDetail.Class);
    this.vtStudentExitSurveyDetailForm.controls.studentDetailGroup.get("Gender").setValue(this.StudentDetail.Gender);
    if (this.StudentDetail.DOB != null) {
      this.vtStudentExitSurveyDetailForm.controls.studentDetailGroup.get("DOB").setValue(new Date(this.StudentDetail.DOB));
    }
    else {
      this.vtStudentExitSurveyDetailForm.controls.studentDetailGroup.get("DOB").setValue(this.StudentDetail.DOB);
    }
    this.vtStudentExitSurveyDetailForm.controls.studentDetailGroup.get("Category").setValue(this.StudentDetail.Category);
    let sector = this.sectorList.find(s => s.Name.toLowerCase() == this.StudentDetail.Sector.toLowerCase());
            this.vtStudentExitSurveyDetailForm.controls.studentDetailGroup.get("Sector").setValue(sector.Name);

            this.onChangeSector(sector.Name).then(response => {
              let jobRole = this.jobRoleList.find(s => s.Name.toLowerCase() == this.StudentDetail.JobRole.toLowerCase());
              this.vtStudentExitSurveyDetailForm.controls.studentDetailGroup.get("JobRole").setValue(jobRole.Name);
            });
    this.vtStudentExitSurveyDetailForm.controls.studentDetailGroup.get("VTPId").setValue(this.StudentDetail.VTPName);

    this.vtStudentExitSurveyDetailForm.get("DateOfIntv").setValue(new Date(this.StudentDetail.DateOfIntv));
    this.vtStudentExitSurveyDetailForm.get("CityOfResidence").setValue(this.StudentDetail.CityOfResidence);
    this.vtStudentExitSurveyDetailForm.get("DistrictOfResidence").setValue(this.StudentDetail.DistrictOfResidence);
    this.vtStudentExitSurveyDetailForm.get("BlockOfResidence").setValue(this.StudentDetail.BlockOfResidence);
    this.vtStudentExitSurveyDetailForm.get("PinCode").setValue(this.StudentDetail.PinCode);
    this.vtStudentExitSurveyDetailForm.get("StudentMobileNo").setValue(this.StudentDetail.StudentMobileNo);
    this.vtStudentExitSurveyDetailForm.get("ParentMobileNo").setValue(this.StudentDetail.ParentMobileNo);
    this.vtStudentExitSurveyDetailForm.get("ParentName").setValue(this.StudentDetail.ParentName);
    this.vtStudentExitSurveyDetailForm.get("DoneInternship").setValue(this.StudentDetail.DoneInternship);
    this.vtStudentExitSurveyDetailForm.get("CurrentlyEmployed").setValue(this.StudentDetail.CurrentlyEmployed);
    this.vtStudentExitSurveyDetailForm.get("DetailsOfEmployment").setValue(this.StudentDetail.DetailsOfEmployment);
    this.vtStudentExitSurveyDetailForm.get("IsFullTime").setValue(this.StudentDetail.IsFullTime);
    this.vtStudentExitSurveyDetailForm.get("SectorsOfEmployment").setValue(this.StudentDetail.SectorsOfEmployment);
    this.vtStudentExitSurveyDetailForm.get("IsRelevantToVocCourse").setValue(this.StudentDetail.IsRelevantToVocCourse);
    this.vtStudentExitSurveyDetailForm.get("WillContHigherStudies").setValue(this.StudentDetail.WillContHigherStudies);
    this.vtStudentExitSurveyDetailForm.get("WillBeFullTime").setValue(this.StudentDetail.WillBeFullTime);
    this.vtStudentExitSurveyDetailForm.get("CourseToPursue").setValue(this.StudentDetail.CourseToPursue);
    this.vtStudentExitSurveyDetailForm.get("OtherCourse").setValue(this.StudentDetail.OtherCourse);
    this.vtStudentExitSurveyDetailForm.get("StreamOfEducation").setValue(this.StudentDetail.StreamOfEducation);
    this.vtStudentExitSurveyDetailForm.get("WillContVocEdu").setValue(this.StudentDetail.WillContVocEdu);
    this.vtStudentExitSurveyDetailForm.get("ReasonsNOTToContinue").setValue(this.StudentDetail.ReasonsNOTToContinue);
    this.vtStudentExitSurveyDetailForm.get("OtherReasons").setValue(this.StudentDetail.OtherReasons);
    this.vtStudentExitSurveyDetailForm.get("WillContSameSector").setValue(this.StudentDetail.WillContSameSector);
    this.vtStudentExitSurveyDetailForm.get("DoesFieldStudyHveVocSub").setValue(this.StudentDetail.DoesFieldStudyHveVocSub);
    this.vtStudentExitSurveyDetailForm.get("HveRegisteredOnEmploymentPortal").setValue(this.StudentDetail.HveRegisteredOnEmploymentPortal);
    this.vtStudentExitSurveyDetailForm.get("WillingToGetRegisteredOnNAPS").setValue(this.StudentDetail.WillingToGetRegisteredOnNAPS);
    this.vtStudentExitSurveyDetailForm.get("InterestedInSkillDevelopmentPgms").setValue(this.StudentDetail.InterestedInSkillDevelopmentPgms);
    this.vtStudentExitSurveyDetailForm.get("SectorsInterestedIn").setValue(this.StudentDetail.SectorsInterestedIn);
    this.vtStudentExitSurveyDetailForm.get("InterestedInJobOrSelfEmployment").setValue(this.StudentDetail.InterestedInJobOrSelfEmployment);
    this.vtStudentExitSurveyDetailForm.get("TopicsOfInterest").setValue(this.StudentDetail.TopicsOfInterest);
    this.vtStudentExitSurveyDetailForm.get("AnyPreferredLocForEmployment").setValue(this.StudentDetail.AnyPreferredLocForEmployment);
    this.vtStudentExitSurveyDetailForm.get("PreferredLocations").setValue(this.StudentDetail.PreferredLocations);
    this.vtStudentExitSurveyDetailForm.get("CanSendTheUpdates").setValue(this.StudentDetail.CanSendTheUpdates);

  }

  createVTStudentExitSurveyDetailForm(): FormGroup {
    return this.formBuilder.group({
      VTStudentExitSurveyDetailId: new FormControl(this.vtStudentExitSurveyDetailModel.VTStudentExitSurveyDetailId),
      ExitStudentId: new FormControl(this.vtStudentExitSurveyDetailModel.ExitStudentId),
      DateOfIntv: new FormControl({ value: new Date(this.vtStudentExitSurveyDetailModel.DateOfIntv), disabled: this.PageRights.IsReadOnly }, Validators.required),
      CityOfResidence: new FormControl({ value: this.vtStudentExitSurveyDetailModel.CityOfResidence, disabled: this.PageRights.IsReadOnly }, Validators.required),
      DistrictOfResidence: new FormControl({ value: this.vtStudentExitSurveyDetailModel.DistrictOfResidence, disabled: this.PageRights.IsReadOnly }),
      BlockOfResidence: new FormControl({ value: this.vtStudentExitSurveyDetailModel.BlockOfResidence, disabled: this.PageRights.IsReadOnly }),
      PinCode: new FormControl({ value: this.vtStudentExitSurveyDetailModel.PinCode, disabled: this.PageRights.IsReadOnly },),
      StudentMobileNo: new FormControl({ value: this.vtStudentExitSurveyDetailModel.StudentMobileNo, disabled: this.PageRights.IsReadOnly }, [Validators.pattern(this.Constants.Regex.Number), Validators.required]),
      ParentMobileNo: new FormControl({ value: this.vtStudentExitSurveyDetailModel.ParentMobileNo, disabled: this.PageRights.IsReadOnly }, [Validators.pattern(this.Constants.Regex.Number), Validators.required]),
      // ParentFirstName: new FormControl({ value: this.vtStudentExitSurveyDetailModel.ParentFirstName, disabled: this.PageRights.IsReadOnly }),
      // ParentMiddleName: new FormControl({ value: this.vtStudentExitSurveyDetailModel.ParentMiddleName, disabled: this.PageRights.IsReadOnly }),
      // ParentLastName: new FormControl({ value: this.vtStudentExitSurveyDetailModel.ParentLastName, disabled: this.PageRights.IsReadOnly }),
      ParentName: new FormControl({ value: this.vtStudentExitSurveyDetailModel.ParentName, disabled: this.PageRights.IsReadOnly }),
      DoneInternship: new FormControl({ value: this.vtStudentExitSurveyDetailModel.DoneInternship, disabled: this.PageRights.IsReadOnly }),
      CurrentlyEmployed: new FormControl({ value: this.vtStudentExitSurveyDetailModel.CurrentlyEmployed, disabled: this.PageRights.IsReadOnly }),
      DetailsOfEmployment: new FormControl({ value: this.vtStudentExitSurveyDetailModel.DetailsOfEmployment, disabled: this.PageRights.IsReadOnly }),
      IsFullTime: new FormControl({ value: this.vtStudentExitSurveyDetailModel.IsFullTime, disabled: this.PageRights.IsReadOnly }),
      SectorsOfEmployment: new FormControl({ value: this.vtStudentExitSurveyDetailModel.SectorsOfEmployment, disabled: this.PageRights.IsReadOnly }),
      IsRelevantToVocCourse: new FormControl({ value: this.vtStudentExitSurveyDetailModel.IsRelevantToVocCourse, disabled: this.PageRights.IsReadOnly }),
      WillContHigherStudies: new FormControl({ value: this.vtStudentExitSurveyDetailModel.WillContHigherStudies, disabled: this.PageRights.IsReadOnly }),
      WillBeFullTime: new FormControl({ value: this.vtStudentExitSurveyDetailModel.WillBeFullTime, disabled: this.PageRights.IsReadOnly }),
      CourseToPursue: new FormControl({ value: this.vtStudentExitSurveyDetailModel.CourseToPursue, disabled: this.PageRights.IsReadOnly }),
      OtherCourse: new FormControl({ value: this.vtStudentExitSurveyDetailModel.OtherCourse, disabled: this.PageRights.IsReadOnly }),
      StreamOfEducation: new FormControl({ value: this.vtStudentExitSurveyDetailModel.StreamOfEducation, disabled: this.PageRights.IsReadOnly }),
      WillContVocEdu: new FormControl({ value: this.vtStudentExitSurveyDetailModel.WillContVocEdu, disabled: this.PageRights.IsReadOnly }),
      ReasonsNOTToContinue: new FormControl({ value: this.vtStudentExitSurveyDetailModel.ReasonsNOTToContinue, disabled: this.PageRights.IsReadOnly }),
      OtherReasons: new FormControl({ value: this.vtStudentExitSurveyDetailModel.OtherReasons, disabled: this.PageRights.IsReadOnly }),
      WillContSameSector: new FormControl({ value: this.vtStudentExitSurveyDetailModel.WillContSameSector, disabled: this.PageRights.IsReadOnly }),
      DoesFieldStudyHveVocSub: new FormControl({ value: this.vtStudentExitSurveyDetailModel.DoesFieldStudyHveVocSub, disabled: this.PageRights.IsReadOnly }),
      HveRegisteredOnEmploymentPortal: new FormControl({ value: this.vtStudentExitSurveyDetailModel.HveRegisteredOnEmploymentPortal, disabled: this.PageRights.IsReadOnly }),
      WillingToGetRegisteredOnNAPS: new FormControl({ value: this.vtStudentExitSurveyDetailModel.WillingToGetRegisteredOnNAPS, disabled: this.PageRights.IsReadOnly }),
      InterestedInSkillDevelopmentPgms: new FormControl({ value: this.vtStudentExitSurveyDetailModel.InterestedInSkillDevelopmentPgms, disabled: this.PageRights.IsReadOnly }),
      SectorsInterestedIn: new FormControl({ value: this.vtStudentExitSurveyDetailModel.SectorsInterestedIn, disabled: this.PageRights.IsReadOnly }),
      InterestedInJobOrSelfEmployment: new FormControl({ value: this.vtStudentExitSurveyDetailModel.InterestedInJobOrSelfEmployment, disabled: this.PageRights.IsReadOnly }),
      TopicsOfInterest: new FormControl({ value: this.vtStudentExitSurveyDetailModel.TopicsOfInterest, disabled: this.PageRights.IsReadOnly }),
      AnyPreferredLocForEmployment: new FormControl({ value: this.vtStudentExitSurveyDetailModel.AnyPreferredLocForEmployment, disabled: this.PageRights.IsReadOnly }),
      PreferredLocations: new FormControl({ value: this.vtStudentExitSurveyDetailModel.PreferredLocations, disabled: this.PageRights.IsReadOnly }),
      CanSendTheUpdates: new FormControl({ value: this.vtStudentExitSurveyDetailModel.CanSendTheUpdates, disabled: this.PageRights.IsReadOnly }),


      IsActive: new FormControl({ value: this.vtStudentExitSurveyDetailModel.IsActive, disabled: this.PageRights.IsReadOnly }),

      studentDetailGroup: this.formBuilder.group({
        StudentFullName: new FormControl({ value: "", disabled: true }),
        FatherName: new FormControl({ value: "", disabled: true }),
        StudentUniqueId: new FormControl({ value: "", disabled: true }),
        NameOfSchool: new FormControl({ value: "", disabled: true }),
        UdiseCode: new FormControl({ value: "", disabled: true }),
        Gender: new FormControl({ value: "", disabled: true }),
        DOB: new FormControl({ value: "", disabled: true }),
        District: new FormControl({ value: "", disabled: true }),
        Class: new FormControl({ value: "", disabled: true }),
        Category: new FormControl({ value: "", disabled: true }),
        Sector: new FormControl({ value: "" }),
        JobRole: new FormControl({ value: "" }),
        VTPId: new FormControl({ value: "", disabled: true }),
      })
    });
  }
}
