import { Component, OnInit, NgZone, ViewEncapsulation, ElementRef } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { FuseUtils } from '@fuse/utils';
import { RouteConstants } from 'app/constants/route.constant'

import { DropdownModel } from 'app/models/dropdown.model';
import { FileUploadModel } from 'app/models/file.upload.model';
import { ReportService } from 'app/reports/report.service';
import { duration } from 'moment';
import { ResourceStudyMaterialModel } from '../resource-study-material.model';
import { ResourceStudyMaterialService } from '../resource-study-material.service';


@Component({
  selector: 'app-create-resource-study-material',
  templateUrl: './create-resource-study-material.component.html',
  styleUrls: ['./create-resource-study-material.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CreateResourceStudyMaterialComponent extends BaseComponent<ResourceStudyMaterialModel> implements OnInit {
  resourceStudyMaterialForm: FormGroup;
  resourceStudyMaterialModel: ResourceStudyMaterialModel;
  id: string;
  title = 'angular-ckeditor-test';
  sectorList: [DropdownModel];
  filteredSectorListItems: any;
  filteredSubCategoryItems: any;
  RSMDocFile: FileUploadModel;
  stateCodeList: [DropdownModel];
  uploadedFile: any;
  imageShow=true
  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    public zone: NgZone,
    private route: ActivatedRoute,
    private reportService: ReportService,
    private resourceStudyMaterialService: ResourceStudyMaterialService,
    private dialogService: DialogService,
    private elRef: ElementRef,
    private formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Set the default account Model

    this.resourceStudyMaterialModel = new ResourceStudyMaterialModel();
  }

  ngOnInit(): void {
    this.resourceStudyMaterialService.getDropdownforResourceVideos(this.UserModel).subscribe((results) => {
      if (results[0].Success) {
        this.sectorList = results[0].Results;
        this.filteredSectorListItems = this.sectorList.slice();
      }
      if (results[6].Success) {
        this.stateCodeList = results[6].Results;
        for (const i of results[6].Results) {
          this.resourceStudyMaterialForm.get('StateCode').setValue(i.Id);
        }
      }

      // if (results[1].Success) {
      //   this.genderList = results[1].Results;
      // }
      // if (results[2].Success) {
      //   this.academicYearList = results[2].Results;
      // }
      // if (results[3].Success) {
      //   this.stateCodeList = results[3].Results;
      //   for (const i of results[3].Results) {
      //     this.resourceVideosForm.get('StateCode').setValue(i.Id);
      //   }
      // }

      this.route.paramMap.subscribe(params => {
        if (params.keys.length > 0) {
          this.PageRights.ActionType = params.get('actionType');

          if (this.PageRights.ActionType == this.Constants.Actions.New) {
            this.resourceStudyMaterialModel = new ResourceStudyMaterialModel();

          } else {
            var resourceId: string = params.get('ResourceId')

            this.resourceStudyMaterialService.GetResourceStudyMaterialById(resourceId)
              .subscribe((response: any) => {
                this.resourceStudyMaterialModel = response.Result;
                this.resourceStudyMaterialForm.get("ResourceCategoryId").patchValue(response.Result.ResourceCategoryId);
                if (this.PageRights.ActionType == this.Constants.Actions.Edit)
                  this.resourceStudyMaterialModel.RequestType = this.Constants.PageType.Edit;
                else if (this.PageRights.ActionType == this.Constants.Actions.View) {
                  this.resourceStudyMaterialModel.RequestType = this.Constants.PageType.View;
                  this.PageRights.IsReadOnly = true;
                }

                this.uploadedFile= response.Result.ResourceLink;
                
                this.resourceStudyMaterialForm = this.createResourceStudyMaterialForm();
                // this.resourceVideosForm.controls['ResourceCategoryId'].patchValue(response.Result.ResourceCategoryId);
                this.resourceStudyMaterialForm.get("ResourceCategoryId").patchValue(response.Result.ResourceCategoryId);

                
              });
          }
        }
      });
    });

    this.resourceStudyMaterialForm = this.createResourceStudyMaterialForm();
  }

  uploadStudyMaterialFilePath(evtTLImg) {
    if (evtTLImg.target.files.length > 0) {
      var fileExtn = evtTLImg.target.files[0].name.split('.').pop().toLowerCase();
        this.imageShow=false
      if (this.AllowedAllDocExtensions.indexOf(fileExtn) == -1) {
        this.resourceStudyMaterialForm.get('ResourceLink').setValue(null);
        this.dialogService.openShowDialog("Please upload Document file only.");
        return;
      }
      
      this.getUploadedFileData(evtTLImg, this.Constants.DocumentType.RsmPhoto).then((response: FileUploadModel) => {
        this.RSMDocFile = response;
      });
    }
  }

  saveFile() {
    if (this.stateCodeList) {
      for (const i of this.stateCodeList) {
        this.resourceStudyMaterialForm.get('StateCode').setValue(i.Id);
        this.resourceStudyMaterialForm.get('CreatedBy').setValue(this.UserModel.UserId);

      }
    }
    if (this.PageRights.ActionType == this.Constants.Actions.Edit) {
      if (this.RSMDocFile != null) {
        let uploadFileRequestParams = {
          "ResourceId": this.resourceStudyMaterialModel.ResourceId,
          "PhotoFile": this.RSMDocFile
        }

        this.resourceStudyMaterialService.uploadStudyMaterial(uploadFileRequestParams)
          .subscribe((uploadFileResp: any) => {

            if (!this.resourceStudyMaterialForm.valid) {
              this.validateAllFormFields(this.resourceStudyMaterialForm);
              return;
            }
            this.setValueFromFormGroup(this.resourceStudyMaterialForm, this.resourceStudyMaterialModel);
            this.resourceStudyMaterialModel.ResourceType = 'StudyMaterial'
            this.resourceStudyMaterialModel.TargetAudienceType = 'Student';
            
            let categoryName: any = this.sectorList.filter(x => x.Id == this.resourceStudyMaterialModel.ResourceCategoryId);
            this.resourceStudyMaterialModel.Category = categoryName[0].Name;
            this.resourceStudyMaterialModel.ResourceLink = uploadFileResp.Result;

            this.resourceStudyMaterialService.CreateStudyMaterialResources(this.resourceStudyMaterialModel)
              .subscribe((headMasterResp: any) => {
                if (headMasterResp.Success) {
                  this.zone.run(() => {
                    this.showActionMessage(
                      this.Constants.Messages.RecordSavedMessage,
                      this.Constants.Html.SuccessSnackbar
                    );

                    this.router.navigate([RouteConstants.ResourceStudyMaterial.List]);
                  });
                }
                else {
                  var errorMessages = this.getHtmlMessage(headMasterResp.Errors)
                  this.dialogService.openShowDialog(errorMessages);
                }
              }, error => {
                console.log('Resource Videos deletion errors =>', error);
              });
          });

      } else {
        if (!this.resourceStudyMaterialForm.valid) {
          this.validateAllFormFields(this.resourceStudyMaterialForm);
          return;
        }
        this.setValueFromFormGroup(this.resourceStudyMaterialForm, this.resourceStudyMaterialModel);
        this.resourceStudyMaterialModel.ResourceType = 'StudyMaterial'
        this.resourceStudyMaterialModel.TargetAudienceType = 'Student';
        let categoryName: any = this.sectorList.filter(x => x.Id == this.resourceStudyMaterialModel.ResourceCategoryId);
        this.resourceStudyMaterialModel.Category = categoryName[0].Name;
        this.resourceStudyMaterialModel.ResourceLink = this.resourceStudyMaterialModel.ResourceLink;

        this.resourceStudyMaterialService.CreateStudyMaterialResources(this.resourceStudyMaterialModel)
          .subscribe((headMasterResp: any) => {
            if (headMasterResp.Success) {
              this.zone.run(() => {
                this.showActionMessage(
                  this.Constants.Messages.RecordSavedMessage,
                  this.Constants.Html.SuccessSnackbar
                );

                this.router.navigate([RouteConstants.ResourceStudyMaterial.List]);
              });
            }
            else {
              var errorMessages = this.getHtmlMessage(headMasterResp.Errors)
              this.dialogService.openShowDialog(errorMessages);
            }
          }, error => {
            console.log('Resource Videos deletion errors =>', error);
          });
      }
    }
    else {
      if (this.RSMDocFile != null) {
        let uploadFileRequestParams = {
          "ResourceId": this.resourceStudyMaterialModel.ResourceId,
          "PhotoFile": this.RSMDocFile
        }

        this.resourceStudyMaterialService.uploadStudyMaterial(uploadFileRequestParams)
          .subscribe((uploadFileResp: any) => {

            if (!this.resourceStudyMaterialForm.valid) {
              this.validateAllFormFields(this.resourceStudyMaterialForm);
              return;
            }
            this.setValueFromFormGroup(this.resourceStudyMaterialForm, this.resourceStudyMaterialModel);
            this.resourceStudyMaterialModel.ResourceType = 'StudyMaterial'
            this.resourceStudyMaterialModel.TargetAudienceType = 'Student';
            let categoryName: any = this.sectorList.filter(x => x.Id == this.resourceStudyMaterialModel.ResourceCategoryId);
            this.resourceStudyMaterialModel.Category = categoryName[0].Name;
            this.resourceStudyMaterialModel.ResourceLink = uploadFileResp.Result;

            this.resourceStudyMaterialService.CreateStudyMaterialResources(this.resourceStudyMaterialModel)
              .subscribe((headMasterResp: any) => {
                if (headMasterResp.Success) {
                  this.zone.run(() => {
                    this.showActionMessage(
                      this.Constants.Messages.RecordSavedMessage,
                      this.Constants.Html.SuccessSnackbar
                    );

                    this.router.navigate([RouteConstants.ResourceStudyMaterial.List]);
                  });
                }
                else {
                  var errorMessages = this.getHtmlMessage(headMasterResp.Errors)
                  this.dialogService.openShowDialog(errorMessages);
                }
              }, error => {
                console.log('Resource Videos deletion errors =>', error);
              });
          });

      }
    }


  }


  saveOrUpdateResourceStudyMaterialDetails() {
    this.saveFile();
  }

  //Create headMaster form and returns {FormGroup}
  createResourceStudyMaterialForm(): FormGroup {
    return this.formBuilder.group({
      ResourceId: new FormControl(this.resourceStudyMaterialModel.ResourceId),
      ResourceTitle: new FormControl({ value: this.resourceStudyMaterialModel.ResourceTitle, disabled: this.PageRights.IsReadOnly }, Validators.required),
      ResourceType: new FormControl({ value: this.resourceStudyMaterialModel.ResourceType, disabled: this.PageRights.IsReadOnly }),
      ResourceCategoryId: new FormControl({ value: this.resourceStudyMaterialModel.ResourceCategoryId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      Category: new FormControl({ value: this.resourceStudyMaterialModel.Category, disabled: this.PageRights.IsReadOnly }),
      ResourceSubcategoryId: new FormControl({ value: this.resourceStudyMaterialModel.ResourceSubcategoryId, disabled: this.PageRights.IsReadOnly }),
      SubCategory: new FormControl({ value: this.resourceStudyMaterialModel.SubCategory, disabled: this.PageRights.IsReadOnly }),
      ResourceDescription: new FormControl({ value: this.resourceStudyMaterialModel.ResourceDescription, disabled: (this.PageRights.IsReadOnly) }, Validators.required),
      ResourceLink: new FormControl({ value: this.resourceStudyMaterialModel.ResourceLink, disabled: this.PageRights.IsReadOnly }, Validators.required),
      TargetAudienceType: new FormControl({ value: this.resourceStudyMaterialModel.TargetAudienceType, disabled: true }),
      ResourcesImageUrl: new FormControl({ value: this.resourceStudyMaterialModel.ResourcesImageUrl, disabled: this.PageRights.IsReadOnly }),
      IsActive: new FormControl({ value: this.resourceStudyMaterialModel.IsActive, disabled: this.PageRights.IsReadOnly }),
      PublishDate: new FormControl({ value: this.getDateValue(this.resourceStudyMaterialModel.PublishDate), disabled: this.PageRights.IsReadOnly }, Validators.required),
      StateCode: new FormControl({ value: this.resourceStudyMaterialModel.StateCode, }),
      CreatedBy: new FormControl({ value: this.resourceStudyMaterialModel.CreatedBy, }),

    });
  }

}
