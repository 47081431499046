import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { EmployerListingModel } from '../employer-listing.model';

@Component({
  selector: 'app-confirm-add-main-branch',
  templateUrl: './confirm-add-main-branch.component.html',
  styleUrls: ['./confirm-add-main-branch.component.scss']
})
export class ConfirmAddMainBranchComponent extends BaseComponent<EmployerListingModel> implements OnInit {

  passDatatoChild: any[];
  isLoading: boolean = true;
  selectedIndex: number;
  selectedObjects: any = {};
  errorMessage: any;
  studentSearchData = [] as any;
  studentFeeId: number;
  receiptType: number;
  receiptNo: number;
  studentInternshipDataById = [] as any;
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    // public service: ModifyArrearsService,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    // public confirmDialogService: ConfirmDialogService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ConfirmAddMainBranchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(commonService, router, routeParams, snackBar);
    // dialogRef.disableClose = true;
  }

  setTab(tabname: string) {
    this.router.navigate([`/${tabname}`]);
  }
  ngOnInit() {
    console.log(this.data);
    this.passDatatoChild = this.data;
    this.studentInternshipDataById = this.data[0];
    console.log(this.passDatatoChild);
  }

  submitConfirmation(confirmation: boolean) {
    debugger
    this.dialogRef.close({ UserConfirmation: confirmation });
  }

  onClose() {
    this.dialog.closeAll();
  }

}
