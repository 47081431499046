import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivityModel } from './activity.model';
import { ActivityService } from './activity.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'data-list-view',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class ActivityComponent extends BaseListComponent<ActivityModel> implements OnInit {
  tabIndex = 0;
  tabName: string = 'All';
  activityResponse: any;
  ApiBaseUrlImage = this.Constants.CommonImageUrl.Account;
  NoDataMessage: boolean = false;
  NoDataMessageChangeRequest: boolean = false;
  NoDataMessageDailyDiary: boolean = false;
  NoDataMessageNoticeBoard: boolean = false;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    public zone: NgZone,
    private dialogService: DialogService,
    private activityService: ActivityService) {
    super(commonService, router, routeParams, snackBar, zone);
  }

  ngOnInit(): void {
    this.onLoadActivity();

  }

  onLoadActivity() {
    this.SearchBy.Name = this.tabName;
    this.SearchBy.VTGuid = this.UserModel.UserTypeId;
    
    this.activityService.GetAllByCriteria(this.SearchBy).subscribe(response => {
      this.activityResponse = response.Results;

      const dateArray = response.Results.reduce((groups, Arrayactivity) => {
        const date = Arrayactivity.ActivityDateTime.split(' ')[0];
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(Arrayactivity);
        return groups;
      }, {});
      console.log(dateArray);
      const finalArrayactivity = Object.keys(dateArray).map((date) => {
        return {
          date,
          activity: dateArray[date]
        };
      });

      console.log(finalArrayactivity);
      this.activityResponse = finalArrayactivity;

      this.IsLoading = false;
    }, error => {
      console.log(error);
    });

  }


  changeTab(event) {

    console.log(event.index)
    this.tabIndex = event.index == undefined ? event : event.index;
    if (event.index == 0) {
      this.tabName = 'All';
      this.onLoadActivity();
    }
    else if (event.index == 1) {
      this.tabName = 'DailyDiary';
      this.onLoadActivity();
    }
    else if (event.index == 2) {
      this.tabName = 'ChangeRequest';
      this.onLoadActivity();
    }
    else if (event.index == 3) {
      this.tabName = 'Approval';
      this.onLoadActivity();
    }
  }


}

