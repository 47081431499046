import { FuseUtils } from '@fuse/utils';
import { FileUploadModel } from 'app/models/file.upload.model';

export class VocationalTrainingProviderModel {
    VTPId: string;
    VTPShortName: string;
    VTPName: string;
    ApprovalYear: string;
    CertificationNo: string;
    CertificationAgency: string;
    VTPMobileNo: string;
    VTPEmailId: string;
    VTPAddress: string;
    PrimaryContactPerson: string;
    PrimaryMobileNumber: string;
    PrimaryContactEmail: string;
    VTPStateCoordinator: string;
    VTPStateCoordinatorMobile: string;
    VTPStateCoordinatorEmail: string;
    ContractApprovalDate: Date;
    ContractEndDate: Date;
    // MOUDocumentFile: any; //old
    MOUDocUpload: any;
    StateCode: string; // New Parameters
    
    IsActive: boolean;
    RequestType: any;
    AcademicYearId:string;
    DateOfResignation:Date// New Parameters

    constructor(vocationalTrainingProviderItem?: any) {
        vocationalTrainingProviderItem = vocationalTrainingProviderItem || {};

        this.VTPId = vocationalTrainingProviderItem.VTPId || FuseUtils.NewGuid();
        this.VTPShortName = vocationalTrainingProviderItem.VTPShortName || '';
        this.VTPName = vocationalTrainingProviderItem.VTPName || '';
        this.ApprovalYear = vocationalTrainingProviderItem.ApprovalYear || '';
        this.CertificationNo = vocationalTrainingProviderItem.CertificationNo || '';
        this.CertificationAgency = vocationalTrainingProviderItem.CertificationAgency || '';
        this.VTPMobileNo = vocationalTrainingProviderItem.VTPMobileNo || '';
        this.VTPEmailId = vocationalTrainingProviderItem.VTPEmailId || '';
        this.VTPAddress = vocationalTrainingProviderItem.VTPAddress || '';
        this.PrimaryContactPerson = vocationalTrainingProviderItem.PrimaryContactPerson || '';
        this.PrimaryMobileNumber = vocationalTrainingProviderItem.PrimaryMobileNumber || '';
        this.PrimaryContactEmail = vocationalTrainingProviderItem.PrimaryContactEmail || '';
        this.VTPStateCoordinator = vocationalTrainingProviderItem.VTPStateCoordinator || '';
        this.VTPStateCoordinatorMobile = vocationalTrainingProviderItem.VTPStateCoordinatorMobile || '';
        this.VTPStateCoordinatorEmail = vocationalTrainingProviderItem.VTPStateCoordinatorEmail || '';
        this.ContractApprovalDate = vocationalTrainingProviderItem.ContractApprovalDate || '';
        this.ContractEndDate = vocationalTrainingProviderItem.ContractEndDate || '';
        this.StateCode = vocationalTrainingProviderItem.StateCode || '';
        this.AcademicYearId = vocationalTrainingProviderItem.AcademicYearId || '';
        this.DateOfResignation = vocationalTrainingProviderItem.DateOfResignation || '';

        // this.MOUDocumentFile = vocationalTrainingProviderItem.MOUDocumentFile || ''; //old
        this.MOUDocUpload = vocationalTrainingProviderItem.MOUDocUpload || ''; //new

        this.IsActive = vocationalTrainingProviderItem.IsActive || true;
        this.RequestType = 0; // New
    }
}
