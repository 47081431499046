import { Injectable } from '@angular/core';
import { UserModel } from 'app/models/user.model';
import { BaseService } from 'app/services/base.service';
import { Observable, forkJoin } from 'rxjs';
import { tap, retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InternshipApprovalService {
  constructor(private http: BaseService) { }

  getSchools(): Observable<any> {
    return this.http
      .HttpGet(this.http.Services.InternshipApproval.GetAll)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(response => {
          return response.Results;
        })
      );
  }

  GetAllByCriteria(filters: any): Observable<any> {
    return this.http
      .HttpPost(this.http.Services.InternshipApproval.GetAllByCriteria, filters)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(response => {
          return response.Results;
        })
      );
  }

  hireStudent(studentId: string, internshipId: string) {
    let requestParams = {
      DataId: studentId,
      DataId1: internshipId
    };

    return this.http.HttpPost(this.http.Services.InternshipApproval.HireStudent, requestParams)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(function (response: any) {
          return response.Results;
        })
      );
  }

  sendToEmployer(studentId: string, internshipId: string) {
    let requestParams = {
      DataId: studentId,
      DataId1: internshipId
    };

    return this.http.HttpPost(this.http.Services.InternshipApproval.SendToEmployer, requestParams)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(function (response: any) {
          return response.Results;
        })
      );
  }

  setIRReViewedStatus(studentId: string, internshipId: string, isIReViewedStatus: string, iRReviewRemarks: string) {
    let requestParams = {
      DataId: studentId,
      DataId1: internshipId,
      DataId2: isIReViewedStatus,
      DataId3: iRReviewRemarks
    };
    return this.http.HttpPost(this.http.Services.InternshipApproval.SetIRReViewedStatus, requestParams)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(function (response: any) {
          return response.Results;
        })
      );
  }

  UpdateStudentInternshipsStatus(requestParams: any) {
   
    return this.http.HttpPost(this.http.Services.InternshipApproval.UpdateStudentInternshipsStatus, requestParams)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(function (response: any) {
          return response.Results;
        })
      );
  }

  setDropoutDateReason(studentId: string, internshipId: string, dateOfDropout: string, dropoutReason: string) {
    let requestParams = {
      DataId: studentId,
      DataId1: internshipId,
      DataId2: dateOfDropout,
      DataId3: dropoutReason
    };
    return this.http.HttpPost(this.http.Services.InternshipApproval.SetDropoutDateReason, requestParams)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(function (response: any) {
          return response.Results;
        })
      );
  }

  setCompletionStatus(studentId: string, internshipId: string, isCompletedStatus: boolean, completionRemarks: string, completionDate: string) {
    let requestParams = {
      DataId: studentId,
      DataId1: internshipId,
      DataId2: isCompletedStatus,
      DataId3: completionRemarks,
      DataId4: completionDate,
    };
    return this.http.HttpPost(this.http.Services.InternshipApproval.SetCompletionStatus, requestParams)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(function (response: any) {
          return response.Results;
        })
      );
  }


  getFinalInternshipReport(studentId: string, internshipId: string) {
    let requestParams = {
      DataId: studentId,
      DataId1: internshipId,
    };
    return this.http.HttpPost(this.http.Services.InternshipApproval.GetFinalInternshipReport, requestParams)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(function (response: any) {
          return response.Results;
        })
      );
  }

  GetBase64(imgUrl: string) {
    let requestParams = {
      AttachmentPath: imgUrl
      
    };
    return this.http.HttpPost(this.http.Services.InternshipApproval.GetBase64, requestParams)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(function (response: any) {
          return response.Results;
        })
      );
  }

  getDropdownforInternshipOpportunity(userModel: UserModel): Observable<any[]> {
    let sectorRequest = this.http.GetMasterDataByType({ DataType: 'Sectors', SelectTitle: 'Sector' }, false);
    let employerRequest = this.http.GetMasterDataByType({ DataType: 'Employers', SelectTitle: 'Employer' });
    let districtRequest = this.http.GetMasterDataByType({ DataType: 'Districts', SelectTitle: 'District' });
    let academicYearRequest = this.http.GetMasterDataByType({ DataType: 'AcademicYearsByState', UserId: userModel.UserTypeId, SelectTitle: 'Academic Year' });
    let branchRequest = this.http.GetMasterDataByType({ DataType: 'EmpBranch', ParentId: 'EmployeerId', SelectTitle: 'Branch Name' })
    // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
    return forkJoin([sectorRequest, employerRequest, districtRequest, academicYearRequest, branchRequest]);
  }
}
