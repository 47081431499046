import { Component, OnInit, NgZone, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { DropdownModel } from 'app/models/dropdown.model';
import { environment } from 'environments/environment';
import { GratitudeModel } from '../gratitude.model';
import { GratitudeService } from '../gratitude.service';



@Component({
  selector: 'app-gratitude',
  templateUrl: './gratitude.component.html',
  styleUrls: ['./gratitude.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class GratitudeComponent extends BaseListComponent<GratitudeModel> implements OnInit {
  StudentNoticeBoardSearchForm: FormGroup;
  gratitudeModel: GratitudeModel;
  currentAcademicYearId: string;
  academicYearList: [DropdownModel];
  sectorList: [DropdownModel];
  vtpList: [DropdownModel];
  filteredVtpSectorItems: any;
  schoolList: [DropdownModel];
  classList: [DropdownModel];
  sectionList: [DropdownModel];
  filteredSchoolItems: any;
  StudentNoticeBoardFilterForm: FormGroup;
  ApiBaseUrlImage = this.Constants.CommonImageUrl.Account;
  studentGratitudeData: any;
  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private translationLoaderService: FuseTranslationLoaderService,
    public zone: NgZone,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private gratitudeService: GratitudeService) {
    super(commonService, router, routeParams, snackBar, zone);
    // this.translationLoaderService.loadTranslations(english, guarati);
    this.StudentNoticeBoardSearchForm = this.formBuilder.group({ filterText: '' });
    this.gratitudeModel = new GratitudeModel();
    this.StudentNoticeBoardFilterForm = this.createSchoolVtpSectorFilterForm();


  }

  ngOnInit(): void {
    this.gratitudeService.getDropdownforSchool(this.UserModel).subscribe(results => {


      if (results[1].Success) {
        this.academicYearList = results[1].Results;
      }


      if (results[10].Success) {
        this.vtpList = results[10].Results;
      }
      if (results[11].Success) {
        this.sectorList = results[11].Results;
      }
    });
    this.onChangeClass()
    // this.onLoadStudentNoticeBoardByCriteria();



    this.StudentNoticeBoardSearchForm = this.formBuilder.group({ filterText: '' });

    // Load initial schools data
    this.onLoadStudentNoticeBoardByCriteria();

    this.StudentNoticeBoardSearchForm.get('filterText').valueChanges.pipe(
      // if character length greater then 2
      filter(res => {
        this.onLoadStudentNoticeBoardByCriteria();

        if (res.length == 0) {
          this.SearchBy.Name = '';
          this.onLoadStudentNoticeBoardByCriteria();
          return false;
        }

        return res.length > 2
      }),

      // Time in milliseconds between key events
      debounceTime(650),

      // If previous query is diffent from current   
      distinctUntilChanged()

      // subscription for response
    ).subscribe((searchText: string) => {
      this.SearchBy.Name = searchText;
      this.onLoadStudentNoticeBoardByCriteria();
    });
  }

  ngAfterViewInit() {
    this.tableDataSource.paginator = this.ListPaginator;
  }

  onPageIndexChanged(evt) {
    // console.log(evt)
    // if(evt.pageIndex == 0){
    //   this.SearchBy.PageIndex = 1;
    // }else{
    //   this.SearchBy.PageIndex = evt.pageIndex;
    // }
    this.SearchBy.PageIndex = evt.pageIndex;

    this.SearchBy.PageSize = evt.pageSize;

    this.onLoadStudentNoticeBoardByCriteria();
  }

  onLoadStudentNoticeBoardByCriteria(): any {
    let requestParams = {
      VTGuid: this.UserModel.UserTypeId,
      PageIndex: 0,
      PageSize: 10000
    }

    this.gratitudeService.GetAllByCriteria(requestParams).subscribe(response => {
   
      this.studentGratitudeData = response.Results;
      // this.displayedColumns = ['FullName', 'Gender', 'Mobile', 'Actions'];

      // this.tableDataSource.data = response.Results;
      // this.tableDataSource.sort = this.ListSort;
      // this.tableDataSource.paginator = this.ListPaginator;
      // this.tableDataSource.filteredData = this.tableDataSource.data;
      // this.SearchBy.TotalResults = response.TotalResults;

      // setTimeout(() => {
      //   this.ListPaginator.pageIndex = this.SearchBy.PageIndex;
      //   this.ListPaginator.length = this.SearchBy.TotalResults;
      // });
      // this.zone.run(() => {
      //   if (this.tableDataSource.data.length == 0) {
      //     // this.showNoDataFoundSnackBar();
      //   }
      // });
      this.IsLoading = false;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  exportExcel(): void {
    this.IsLoading = true;
    this.SearchBy.PageIndex = 0;
    this.SearchBy.PageSize = 10000;

    this.gratitudeService.ApprovedEmployerGratitude(this.SearchBy).subscribe(response => {
      response.Results.forEach(
        function (obj) {
          if (obj.hasOwnProperty('IsActive')) {
            obj.IsActive = obj.IsActive ? 'Yes' : 'No';
          }
          if (obj.hasOwnProperty('IsImplemented')) {
            obj.IsImplemented = obj.IsImplemented ? 'Yes' : 'No';
          }
          delete obj.SchoolId;
          delete obj.StudentId;
          delete obj.AcademicYearId;
          delete obj.SectionId;
          delete obj.ClassId;
          delete obj.TotalRows;
        });

      this.exportExcelFromTable(response.Results, "Student");

      this.IsLoading = false;
      this.SearchBy.PageIndex = 0;
      this.SearchBy.PageSize = 10;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  onLoadSchoolVTPSectorsByFilters(): any {
    this.onLoadStudentNoticeBoardByCriteria();
  }

  resetFilters(): void {
    this.StudentNoticeBoardFilterForm.reset();

    this.onLoadStudentNoticeBoardByCriteria();

    this.StudentNoticeBoardFilterForm.reset();
    // this.StudentNoticeBoardFilterForm.get('AcademicYearId').setValue(this.currentAcademicYearId);
    // this.SearchBy.SectorGuid = ''
  }
  //Create SchoolVtpSectorFilter form and returns {FormGroup}
  createSchoolVtpSectorFilterForm(): FormGroup {
    return this.formBuilder.group({
      // AcademicYearId: new FormControl(),
      SectorId: new FormControl(),
      Class: new FormControl(),
      Section: new FormControl(),
      // SchoolId: new FormControl(),
    });
  }

  onChangeClass() {
    this.commonService.GetMasterDataByType({ DataType: 'ClassesByVT', ParentId: this.Constants.GlobalSchoolId, UserId: this.UserModel.UserTypeId, SelectTitle: 'Class' }).subscribe((response: any) => {
      this.classList = response.Results;
    });
  }
  onChangeSection(ClassId: any) {
    this.commonService.GetMasterDataByType({ DataType: 'Sections', ParentId: ClassId, SelectTitle: 'Sections' }).subscribe((response: any) => {
      this.sectionList = response.Results;
    });
  }

}
