<div id="school-class-sections" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/school-class-sectiions'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        {{ schoolClassSectionsModel.FirstName }}
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        New School Class Sections
                    </div>
                    <div class="subtitle secondary-text">
                        <span>New School Class Sections Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-head-master-button" *ngIf="PageRights.ActionType ==='new'"
                (click)="saveOrUpdateSchoolClassSectionDetails()">
                <span>ADD</span>
            </button>

            <button mat-raised-button class="save-head-master-button" *ngIf="PageRights.ActionType ==='edit'"
                (click)="saveOrUpdateSchoolClassSectionDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="schoolClassSectionForm" [formGroup]="schoolClassSectionForm"
                    class="head-master w-100-p p-24 mr-24" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto"
                    fxFlex fusePerfectScrollbar>
                    <div class="required-mark">
                        <span><span class="note">Note: </span>All fields marked with an asterisk (<span
                                class="asterisk-color">*</span>) are required.</span>
                    </div>
                    <!-- <mat-tab label="Basic Info"> -->
                    <!-- <div class="tab-content p-24" fusePerfectScrollbar> -->
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>State Code</mat-label>

                            <mat-select formControlName="StateCode" appearance="outline"
                                [disabled]="PageRights.IsReadOnly" required>
                                <mat-option [value]="stateItem.Id" *ngFor="let stateItem of statecodeList">
                                    {{ stateItem.Name }} ({{ stateItem.Id }})
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="schoolClassSectionForm.controls.StateCode.invalid">
                                {{getErrorMessage(schoolClassSectionForm, 'StateCode')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Academic Year</mat-label>

                            <mat-select name="academicYearId" formControlName="AcademicYearId" required
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="academicYearItem.Id"
                                    *ngFor="let academicYearItem of academicyearList">
                                    {{ academicYearItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="schoolClassSectionForm.controls.AcademicYearId.invalid">
                                {{getErrorMessage(schoolClassSectionForm, 'AcademicYearId')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Select School</mat-label>

                            <mat-select formControlName="SchoolId" appearance="outline"
                                [disabled]="PageRights.IsReadOnly" required #SelectSchool>
                                <mat-select-filter class="select-filter" *ngIf="SelectSchool.focused"
                                    [array]="schoolsList" (filteredReturn)="this.filteredSchoolItems = $event"
                                    [displayMember]="'Name'"></mat-select-filter>
                                <mat-option [value]="schoolItem.Id" *ngFor="let schoolItem of filteredSchoolItems">
                                    {{ schoolItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="schoolClassSectionForm.controls.SchoolId.invalid">
                                {{getErrorMessage(schoolClassSectionForm, 'SchoolId')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Select School Class</mat-label>

                            <mat-select formControlName="ClassId" appearance="outline"
                                [disabled]="PageRights.IsReadOnly" required>
                                <mat-option [value]="classItem.Id" *ngFor="let classItem of classList">
                                    {{ classItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="schoolClassSectionForm.controls.ClassId.invalid">
                                {{getErrorMessage(schoolClassSectionForm, 'ClassId')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Select School Class Section</mat-label>

                            <mat-select formControlName="SectionId" appearance="outline"
                                [disabled]="PageRights.IsReadOnly" required>
                                <mat-option [value]="sectionItem.Id" *ngFor="let sectionItem of sectionList">
                                    {{ sectionItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="schoolClassSectionForm.controls.SectionId.invalid">
                                {{getErrorMessage(schoolClassSectionForm, 'SectionId')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">


                        <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Remarks</mat-label>
                            <input matInput name="remarks" formControlName="Remarks" placeholder="Ex. Kumar"
                                matTooltip="First letter should be capital." matTooltipPosition="above">
                            <mat-error *ngIf="schoolClassSectionForm.controls.Remarks.invalid">
                                {{getErrorMessage(schoolClassSectionForm, 'Remarks')}}
                            </mat-error>
                        </mat-form-field>


                    </div>



                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <span class="checkbox-space">Is Active?</span>
                        <mat-checkbox matInput formControlName="IsActive" [disabled]="PageRights.IsReadOnly">
                        </mat-checkbox>
                    </div>
                    <!-- </div> -->
                    <!-- </mat-tab>
                    </mat-tab-group> -->
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>