import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';

@Injectable()
export class MessageForVTService {
    constructor(private http: BaseService) { }

   

    GetUnReadMessagesForVT(filters:any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.MessagesForVT.GetUnReadMessagesForVT, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetReadMessagesForVT(filters:any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.MessagesForVT.GetReadMessagesForVT, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    SetMessageRead(RequestId:any): Observable<any> {
        let requestParams = {
            DataId: RequestId
        };
        return this.http
            .HttpPost(this.http.Services.MessagesForVT.SetMessageRead, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }
    




}
