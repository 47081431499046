<!-- HEADER -->
<div class="header1" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="space-between center" style="background: #3f51b5;height: 64px; height: 61px;
  padding: 0px 24px;
">
    <!-- APP TITLE -->
    <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center"
        [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
        <div class="h2 mt-8 mb-12  heading-text1" style="color:#fff;">
            Gallery
        </div>
    </div>
    <!-- / APP TITLE -->
    <!-- ADD BUTTON -->

    <button mat-flat-button [color]="'warn'" (click)="onClose()" class="mat-elevation-z1" style="background-color: #f44336 !important;
  color: #fff !important;">
        <mat-icon>clear</mat-icon>
    </button>
    <!-- / ADD BUTTON -->
</div>
<!-- / HEADER -->
<div id="staff" class="page-layout carded fullwidth inner-scroll">
    <!-- TOP BACKGROUND -->
    <!-- <div class="top-bg accent"></div> -->
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">
        <!-- CONTENT CARD -->
        <div class="content-card mat-elevation-z2 p-5 mb-12">
            <div class="content">


                <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start"
                    style="height: auto !important;" id="displayContent">
                    <!-- <div fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100"
                            *ngFor="let item of DailyDiaryImageAttachments;">
                            <div fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100" style="padding-left: 15px;
                        padding-top: 35px;color: #0d6efd;">
                                <span class="fontW">{{item.AttachmentDate | date:'mediumDate' }}</span><br>
                                <span *ngFor="let items of item.AttachmentPath;">

                                   
                                    <img [src]="imageAttachments + items " alt="" width="100" height="100" style="padding-right: 10px;">



                                
                                </span>

                                <mat-divider inset style="margin-top: 15px;-bottom: 15px;"></mat-divider>
                            </div>
                            
                            
                        </div> -->
                        <div fxFlex="100" fxLayout="row wrap" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                            <div fxLayout="row" fxFlex="100" class="fontW" *ngFor="let datewise of dateArray;">
                                {{datewise.date | date:'mediumDate'}} </div>

                            <div fxLayout="row wrap" style="padding-left: 15px; padding-top: 35px;color: #0d6efd;overflow-y:scroll;"
                                *ngFor="let imgGallery of ImageGalleryData;">

                                <!-- <img [src]="FinalInternshipImageBaseURL+imgGallery.AttachmentPath"
                                    style="width:350px;height:350px; float:left; margin:1px; padding: 1px;"
                                    class="d-block mb-10 border border-gray-200 p-1"> -->
                                    <img *ngIf="imgGallery.SectionType=='IRBestPicture'"
                                    [src]="FinalInternshipImageBaseURL+imgGallery.AttachmentPath"
                                    style="width:100px;height:100px; float:left; margin:1px; padding: 1px;"
                                    class="d-block mb-10 border border-gray-200 p-1">

                                <img *ngIf="imgGallery.SectionType=='IRCertificate'"
                                    [src]="FinalInternshipImageBaseURL+imgGallery.AttachmentPath"
                                    style="width:100px;height:100px; float:left; margin:1px; padding: 1px;"
                                    class="d-block mb-10 border border-gray-200 p-1">
                                <img *ngIf="imgGallery.SectionType=='IRRportPhotos'"
                                    [src]="FinalInternshipImageBaseURL+imgGallery.AttachmentPath"
                                    style="width:100px;height:100px; float:left; margin:1px; padding: 1px;"
                                    class="d-block mb-10 border border-gray-200 p-1">
                                <img *ngIf="imgGallery.SectionType=='DailyDiary'"
                                    [src]="imageAttachments+imgGallery.AttachmentPath"
                                    style="width:100px;height:100px; float:left; margin:1px; padding: 1px;"
                                    class="d-block mb-10 border border-gray-200 p-1">
                            </div>


                        </div>


                </div>

            </div>
            <!-- / CONTENT CARD -->
        </div>
        <!-- / CENTER -->
    </div>