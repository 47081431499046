import { UserModel } from 'app/models/user.model';

export class StudentRightsModel {
    ResourceId: String
    PublishDate: string;
    ResourceType: string;
    ResourceDescription: any;
    ResourceCategoryId: string;
    ResourceSubcategoryId: string;
    RequestType: any;
    AuthUserId: string; // New Parameters
    IsActive: boolean;
    ResourceTitle: string;
    TargetAudienceType: string;
    Category:string;
    StateCode: string;
    CreatedBy:string;

    constructor(studentRightsItem?: any) {
        studentRightsItem = studentRightsItem || {};
        //this.NoticeId = studentRightsItem.NoticeId || FuseUtils.NewGuid();
        this.ResourceId = studentRightsItem.ResourceId || '00000000-0000-0000-0000-000000000000';
        this.PublishDate = studentRightsItem.PublishDate || '';
        this.ResourceType = studentRightsItem.ResourceType || '';
        this.ResourceDescription = studentRightsItem.ResourceDescription || '';
        this.RequestType = 0; // New Parameters
        this.AuthUserId = studentRightsItem.AuthUserId || '';
        this.IsActive = studentRightsItem.IsActive || true;
        this.ResourceCategoryId = studentRightsItem.ResourceCategoryId || '00000000-0000-0000-0000-000000000000';
        this.ResourceSubcategoryId = studentRightsItem.ResourceSubcategoryId || '00000000-0000-0000-0000-000000000000';
        this.ResourceTitle = studentRightsItem.ResourceTitle || '';
        this.TargetAudienceType=studentRightsItem.TargetAudienceType || 'Student';
        this.Category=studentRightsItem.Category || 'Student Rights';
        this.StateCode = studentRightsItem.StateCode || '';
        this.CreatedBy = studentRightsItem.CreatedBy || '';


    }
}
