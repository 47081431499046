import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VocationalTrainingProviderModel } from './vocational-training-provider.model';
import { VocationalTrainingProviderService } from './vocational-training-provider.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as guarati } from './i18n/gj';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

@Component({
  selector: 'data-list-view',
  templateUrl: './vocational-training-provider.component.html',
  styleUrls: ['./vocational-training-provider.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class VocationalTrainingProviderComponent extends BaseListComponent<VocationalTrainingProviderModel> implements OnInit {
  vocationalTrainingProviderFilterForm: FormGroup;
  vocationalTrainingProviderForm: FormGroup;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private translationLoaderService: FuseTranslationLoaderService,
    public zone: NgZone,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private vocationalTrainingProviderService: VocationalTrainingProviderService) {
    super(commonService, router, routeParams, snackBar, zone);
    this.translationLoaderService.loadTranslations(english, guarati);
    this.vocationalTrainingProviderFilterForm = this.createVocationalTrainingProviderFilterForm();
  }

  ngOnInit(): void {
    this.onLoadVocationalTrainingProviderCriteria();
    this.vocationalTrainingProviderForm = this.formBuilder.group({ filterText: '' });
    this.vocationalTrainingProviderForm.get('filterText').valueChanges.pipe(
      // if character length greater then 2
      filter(res => {
        this.SearchBy.PageIndex = 0;

        if (res.length == 0) {
          this.SearchBy.Name = '';
          this.onLoadVocationalTrainingProviderCriteria();
          return false;
        }

        return res.length > 2
      }),

      // Time in milliseconds between key events
      debounceTime(650),

      // If previous query is diffent from current   
      distinctUntilChanged()

      // subscription for response
    ).subscribe((searchText: string) => {
      this.SearchBy.Name = searchText;
      this.onLoadVocationalTrainingProviderCriteria();
    });
  }

  ngAfterViewInit() {
    this.tableDataSource.paginator = this.ListPaginator;
  }

  onPageIndexChanged(evt) {
    this.SearchBy.PageIndex = evt.pageIndex;
    this.SearchBy.PageSize = evt.pageSize;

    this.onLoadVocationalTrainingProviderCriteria();
  }

  onLoadVocationalTrainingProviderCriteria(): any {
    this.SearchBy.IsActive = this.vocationalTrainingProviderFilterForm.controls["Status"].value =='true'? true:this.vocationalTrainingProviderFilterForm.controls["Status"].value=='false'? false:this.vocationalTrainingProviderFilterForm.controls["Status"].value;
    this.IsLoading = true;
    this.vocationalTrainingProviderService.GetAllByCriteria(this.SearchBy).subscribe(response => {
      this.displayedColumns = ['VTPShortName', 'VTPName', 'ContactPerson', 'MobileNumber', 'Email', 'ApprovalYear', 'CertificationNo',
        //'VTPOnBoardingDate',
        'VTPOffboardingDate', 'IsActive', 'Actions'];

      this.tableDataSource.data = response.Results;
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;

      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
    });
  }

  onDeleteVocationalTrainingProvider(vtpId: string) {
    this.dialogService
      .openConfirmDialog(this.Constants.Messages.DeleteConfirmationMessage)
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          this.vocationalTrainingProviderService.deleteVocationalTrainingProviderById(vtpId)
            .subscribe((vocationalTrainingProviderResp: any) => {

              this.zone.run(() => {
                if (vocationalTrainingProviderResp.Success == true) {
                  this.showActionMessage(
                    this.Constants.Messages.RecordDeletedMessage,
                    this.Constants.Html.SuccessSnackbar
                  );
                } else {
                  this.showActionMessage(
                    vocationalTrainingProviderResp.Result,
                    this.Constants.Html.SuccessSnackbar
                  );
                  this.ngOnInit();

                }
                this.ngOnInit();
              }, error => {
                console.log('VocationalTrainingProvider deletion errors =>', error);
              });

            });
          this.ngOnInit();
        }
      });
  }

  exportExcel(): void {
    this.IsLoading = true;
    this.SearchBy.PageIndex = 0;
    this.SearchBy.PageSize = 10000;

    this.vocationalTrainingProviderService.GetAllByCriteria(this.SearchBy).subscribe(response => {
      response.Results.forEach(
        function (obj) {
          if (obj.hasOwnProperty('IsActive')) {
            obj.IsActive = obj.IsActive ? 'Yes' : 'No';
          }
          if (obj.hasOwnProperty('IsImplemented')) {
            obj.IsImplemented = obj.IsImplemented ? 'Yes' : 'No';
          }
          delete obj.VTPId;
          delete obj.CertificationAgency;
          delete obj.StateCode;
          delete obj.StateName;
          delete obj.TotalRows;
        });

      this.exportExcelFromTable(response.Results, "VocationalTrainingProviders");

      this.IsLoading = false;
      this.SearchBy.PageIndex = 0;
      this.SearchBy.PageSize = 10;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  //Create VocationalTrainingProvider form and returns {FormGroup}
  createVocationalTrainingProviderFilterForm(): FormGroup {
    return this.formBuilder.group({
      //AcademicYearId: new FormControl(),
      Status: new FormControl()
    });
  }

  onLoadVocationalTrainingProviderByFilters(): any {
    this.SearchBy.PageIndex = 0;
    this.onLoadVocationalTrainingProviderCriteria();
  }

  resetFilters(): void {
    this.SearchBy.PageIndex = 0;
    this.vocationalTrainingProviderFilterForm.reset();
    //this.schoolFilterForm.get('AcademicYearId').setValue(this.AcademicYearId);

    this.onLoadVocationalTrainingProviderCriteria();
  }
}
