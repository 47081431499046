import { Injectable } from '@angular/core';
import { UserModel } from 'app/models/user.model';
import { BaseService } from 'app/services/base.service';
import { Observable, forkJoin } from 'rxjs';
import { tap, retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DiaryEntryService {


  constructor(private http: BaseService) { }

  getSchools(): Observable<any> {
    return this.http
      .HttpGet(this.http.Services.DiaryEntryVT.GetAll)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(response => {
          return response.Results;
        })
      );
  }

  GetAllByCriteria(filters: any): Observable<any> {
    return this.http
      .HttpPost(this.http.Services.DiaryEntryVT.GetAllByCriteria, filters)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(response => {
          return response.Results;
        })
      );
  }


  DownloadDailyDiaryForVT(vtid: string, pageIndex: number, pageSize: number): Observable<any> {

    let requestParams = {
      VTGuid: vtid,
      PageIndex: pageIndex,
      PageSize: pageSize
    };
    return this.http
      .HttpPost(this.http.Services.DiaryEntryVT.DownloadDailyDiaryForVT, requestParams)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(response => {
          return response.Results;
        })
      );
  }

  getInternshipById(internshipId: string) {
    let requestParams = {
      DataId: internshipId
    };

    return this.http.HttpPost(this.http.Services.DiaryEntryVT.GetById, requestParams)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(function (response: any) {
          return response.Results;
        })
      );
  }

  createOrUpdateInternship(formData: any) {
    return this.http
      .HttpPost(this.http.Services.DiaryEntryVT.CreateOrUpdate, formData)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(response => {
          return response;
        })
      );
  }

  deleteSchoolById(internshipId: string) {
    let internshipParams = {
      DataId: internshipId
    };

    return this.http
      .HttpPost(this.http.Services.DiaryEntryVT.Delete, internshipParams)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(response => {
          return response;
        })
      );
  }

  markIsCompleted(internshipId: string) {
    let requestParams = {
      DataId: internshipId
    };

    return this.http.HttpPost(this.http.Services.DiaryEntryVT.IsCompleted, requestParams)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(function (response: any) {
          return response.Results;
        })
      );
  }

  markPublishInternship(internshipId: string) {
    let requestParams = {
      DataId: internshipId
    };

    return this.http.HttpPost(this.http.Services.DiaryEntryVT.PublishInternship, requestParams)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(function (response: any) {
          return response.Results;
        })
      );
  }

  getDropdownforInternshipOpportunity(userModel: UserModel): Observable<any[]> {
    let sectorRequest = this.http.GetMasterDataByType({ DataType: 'Sectors', SelectTitle: 'Sector' }, false);
    let employerRequest = this.http.GetMasterDataByType({ DataType: 'Employers', SelectTitle: 'Employer' });
    let districtRequest = this.http.GetMasterDataByType({ DataType: 'Districts', SelectTitle: 'District' });
    let academicYearRequest = this.http.GetMasterDataByType({ DataType: 'AcademicYearsByState', UserId: userModel.UserTypeId, SelectTitle: 'Academic Year' });
    let branchRequest = this.http.GetMasterDataByType({ DataType: 'EmpBranch', ParentId: 'EmployeerId', SelectTitle: 'Branch Name' })
    // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
    return forkJoin([sectorRequest, employerRequest, districtRequest, academicYearRequest, branchRequest]);
  }
}
