import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VocationalCoordinatorModel } from './vocational-coordinator.model';
import { VocationalCoordinatorService } from './vocational-coordinator.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as guarati } from './i18n/gj';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

@Component({
  selector: 'data-list-view',
  templateUrl: './vocational-coordinator.component.html',
  styleUrls: ['./vocational-coordinator.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class VocationalCoordinatorComponent extends BaseListComponent<VocationalCoordinatorModel> implements OnInit {
  vocationalCoordinatorFilterForm:FormGroup;
  vocationalCoordinatorForm:FormGroup;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private translationLoaderService: FuseTranslationLoaderService,
    public zone: NgZone,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private vocationalCoordinatorService: VocationalCoordinatorService) {
    super(commonService, router, routeParams, snackBar, zone);
    this.translationLoaderService.loadTranslations(english, guarati);
    this.vocationalCoordinatorFilterForm = this.createVocationalCoordinatorFilterForm();
  }

  ngOnInit(): void {
    this.onLoadVocationalCoordinatorCriteria();
    this.vocationalCoordinatorForm = this.formBuilder.group({ filterText: '' });
    this.vocationalCoordinatorForm.get('filterText').valueChanges.pipe(
      // if character length greater then 2
      filter(res => {
        this.SearchBy.PageIndex = 0;

        if (res.length == 0) {
          this.SearchBy.Name = '';
          this.onLoadVocationalCoordinatorCriteria();
          return false;
        }

        return res.length > 2
      }),

      // Time in milliseconds between key events
      debounceTime(650),

      // If previous query is diffent from current   
      distinctUntilChanged()

      // subscription for response
    ).subscribe((searchText: string) => {
      this.SearchBy.Name = searchText;
      this.onLoadVocationalCoordinatorCriteria();
    });
  }

  ngAfterViewInit() {
    this.tableDataSource.paginator = this.ListPaginator;
  }

  onPageIndexChanged(evt) {
    this.SearchBy.PageIndex = evt.pageIndex;
    this.SearchBy.PageSize = evt.pageSize;

    this.onLoadVocationalCoordinatorCriteria();
  }

  onLoadVocationalCoordinatorCriteria(): any {
    this.SearchBy.IsActive = this.vocationalCoordinatorFilterForm.controls["Status"].value =='true'? true:this.vocationalCoordinatorFilterForm.controls["Status"].value=='false'?false:this.vocationalCoordinatorFilterForm.controls["Status"].value;
    this.IsLoading = true;
    this.vocationalCoordinatorService.GetAllByCriteria(this.SearchBy).subscribe(response => {
      this.displayedColumns = ['NatureOfAppointment', 'VTPName', 'FullName', 'Mobile', 'EmailId', 'Gender', 'DateOfJoining', 'IsActive', 'Actions'];

      this.tableDataSource.data = response.Results;
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;

      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
    });
  }

  onDeleteVocationalCoordinator(vcId: string, vtpId: string) {
    this.dialogService
      .openConfirmDialog(this.Constants.Messages.DeleteConfirmationMessage)
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          this.vocationalCoordinatorService.deleteVocationalCoordinatorById(vcId, vtpId)
            .subscribe((vocationalCoordinatorResp: any) => {

              this.zone.run(() => {
                if (vocationalCoordinatorResp.Success == true) {
                  this.showActionMessage(
                    this.Constants.Messages.RecordDeletedMessage,
                    this.Constants.Html.SuccessSnackbar
                  );
                } else {
                  this.showActionMessage(
                    vocationalCoordinatorResp.Result,
                    this.Constants.Html.SuccessSnackbar
                  );
                  this.ngOnInit();

                }
                this.ngOnInit();
              }, error => {
                console.log('VocationalCoordinator deletion errors =>', error);
              });

            });
          this.ngOnInit();
        }
      });
  }

  exportExcel(): void {
    this.IsLoading = true;
    this.SearchBy.PageIndex = 0;
    this.SearchBy.PageSize = 10000;

    this.vocationalCoordinatorService.GetAllByCriteria(this.SearchBy).subscribe(response => {
      response.Results.forEach(
        function (obj) {
          if (obj.hasOwnProperty('IsActive')) {
            obj.IsActive = obj.IsActive ? 'Yes' : 'No';
          }
          if (obj.hasOwnProperty('IsImplemented')) {
            obj.IsImplemented = obj.IsImplemented ? 'Yes' : 'No';
          }
          delete obj.VTPId;
          delete obj.VCId;
          delete obj.StateCode;
          delete obj.StateName;
          delete obj.AcademicYearId;
        
          delete obj.TotalRows;
        });

      this.exportExcelFromTable(response.Results, "VocationalCoordinators");

      this.IsLoading = false;
      this.SearchBy.PageIndex = 0;
      this.SearchBy.PageSize = 10;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

   //Create VocationalCoordinator form and returns {FormGroup}
   createVocationalCoordinatorFilterForm(): FormGroup {
    return this.formBuilder.group({
      //AcademicYearId: new FormControl(),
      Status: new FormControl()
    });
  }

  onLoadVocationalCoordinatorByFilters(): any {
    this.SearchBy.PageIndex = 0;
    this.onLoadVocationalCoordinatorCriteria();
  }

  resetFilters(): void {
    this.SearchBy.PageIndex = 0;
    this.vocationalCoordinatorFilterForm.reset();
    //this.schoolFilterForm.get('AcademicYearId').setValue(this.AcademicYearId);

    this.onLoadVocationalCoordinatorCriteria();
  }
}
