import { Component, Inject, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { environment } from 'environments/environment';
import { DropdownModel } from 'app/models/dropdown.model';
import { SafetyConcernModel } from '../safety-concern.model';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';

@Component({
  selector: 'app-remark-safety',
  templateUrl: './remark-safety.component.html',
  styleUrls: ['./remark-safety.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class RemarkSafetyComponent extends BaseComponent<SafetyConcernModel> implements OnInit {
  passDatatoChild: any[];
  isLoading: boolean = true;
  errorMessage: any;
  studentInternshipDataById = [] as any;
  studentId: any;
  internshipId: any;
  reportData: any;
  btnHide: boolean = false;
  internData: any;
 
 
  myDate:any;

  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    public dialog: MatDialog,
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<RemarkSafetyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(commonService, router, routeParams, snackBar);
    // dialogRef.disableClose = true;
  }
  ngOnInit() {
    console.log(this.data);
    
  }

  onClose() {
    this.dialog.closeAll();
  }

  onSubmit(reviewInternshipReportForm) {
    this.dialogRef.close({allData: this.data,data:reviewInternshipReportForm});
  }

 
  reviewInternshipReportForm: FormGroup = new FormGroup({
    Remark: new FormControl("", Validators.required),
  });

}
