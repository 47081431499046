import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SchoolVTPVCMappingModel } from './school-vtp-vc-mapping.model';
import { SchoolVTPVCMappingService } from './school-vtp-vc-mapping.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DropdownModel } from 'app/models/dropdown.model';

@Component({
  selector: 'data-list-view',
  templateUrl: './school-vtp-vc-mapping.component.html',
  styleUrls: ['./school-vtp-vc-mapping.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class SchoolVTPVCMappingComponent extends BaseListComponent<SchoolVTPVCMappingModel> implements OnInit {
  schoolsList: [DropdownModel];
  schoolVTPVCMappingFilterForm: FormGroup;
  schoolVtpVcMapping: Element[];
  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    public zone: NgZone,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private schoolVTPVCMappingService: SchoolVTPVCMappingService) {
    super(commonService, router, routeParams, snackBar, zone);
    this.schoolVTPVCMappingFilterForm = this.createSchoolVTPVCMappingFilterForm();

  }

  ngOnInit(): void {
    this.onLoadSchoolVTPVCMappingByFilters();
    // this.schoolVTPVCMappingService.GetAllByCriteria(this.SearchBy).subscribe(response => {
    //   this.displayedColumns = ['SchoolName', 'VCFullName', 'YearName','VTPName', 'DateOfAllocation', 'DateOfDeAllocation', 'Remarks', 'IsActive', 'Actions'];
    //   console.log(response.Results)
    //   debugger;
    //   this.schoolVtpVcMapping = response.Results;
    //   this.tableDataSource.data = response.Results;
    //   this.tableDataSource.sort = this.ListSort;
    //   this.tableDataSource.paginator = this.ListPaginator;
    //   this.tableDataSource.filteredData = this.tableDataSource.data;

    //   this.zone.run(() => {
    //     if (this.tableDataSource.data.length == 0) {
    //       this.showNoDataFoundSnackBar();
    //     }
    //   });
    //   this.IsLoading = false;
    // }, error => {
    //   console.log(error);
    // });
  }

  onLoadSchoolVTPVCMappingCriteria(): any {
    this.SearchBy.IsActive = this.schoolVTPVCMappingFilterForm.controls["Status"].value == 'true' ? true : this.schoolVTPVCMappingFilterForm.controls["Status"].value == 'false' ? false : this.schoolVTPVCMappingFilterForm.controls["Status"].value;
    this.IsLoading = true;
    this.schoolVTPVCMappingService.GetAllByCriteria(this.SearchBy).subscribe(response => {
      // this.displayedColumns = ['SchoolName', 'VCFullName', 'YearName','VTPName', 'DateOfAllocation', 'DateOfDeAllocation', 'Remarks', 'IsActive', 'Actions'];
      this.displayedColumns = ['SchoolName', 'VTPName', 'VCFullName', 'YearName', 'IsActive', 'Actions'];
      console.log(response.Results)
      this.schoolVtpVcMapping = response.Results;
      this.tableDataSource.data = response.Results;
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;

      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
    });
  }

  onDeleteSchoolVTPVCMapping(schoolVTPVCId: string) {
    this.dialogService
      .openConfirmDialog(this.Constants.Messages.DeleteConfirmationMessage)
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          this.schoolVTPVCMappingService.deleteSchoolVTPVCMappingById(schoolVTPVCId)
            .subscribe((schoolVTPVCMappingResp: any) => {

              this.zone.run(() => {
                if (schoolVTPVCMappingResp.Success == true) {
                  this.showActionMessage(
                    this.Constants.Messages.RecordDeletedMessage,
                    this.Constants.Html.SuccessSnackbar
                  );
                } else {
                  this.showActionMessage(
                    schoolVTPVCMappingResp.Result,
                    this.Constants.Html.SuccessSnackbar
                  );
                  this.ngOnInit();

                }
                this.ngOnInit();
              }, error => {
                console.log('SchoolVTPVCMapping deletion errors =>', error);
              });

            });
          this.ngOnInit();
        }
      });
  }


  exportExcel(): void {
    this.IsLoading = true;
    this.SearchBy.PageIndex = 0;
    this.SearchBy.PageSize = 10000;

    this.schoolVTPVCMappingService.GetAllByCriteria(this.SearchBy).subscribe(response => {
      response.Results.forEach(
        function (obj) {
          if (obj.hasOwnProperty('IsActive')) {
            obj.IsActive = obj.IsActive ? 'Yes' : 'No';
          }
          if (obj.hasOwnProperty('IsImplemented')) {
            obj.IsImplemented = obj.IsImplemented ? 'Yes' : 'No';
          }
          delete obj.SchoolId;
          delete obj.SchoolVTPVCId;
          delete obj.SchoolSectorJobRolesVTPId;
          delete obj.VCId;
          delete obj.AcademicYearId;
          delete obj.TotalRows;
        });

      this.exportExcelFromTable(response.Results, "SchoolVTVCMapping");

      this.IsLoading = false;
      this.SearchBy.PageIndex = 0;
      this.SearchBy.PageSize = 10;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  //Create SchoolVTPVCMapping form and returns {FormGroup}
  createSchoolVTPVCMappingFilterForm(): FormGroup {
    return this.formBuilder.group({
      //AcademicYearId: new FormControl(),
      Status: new FormControl()
    });
  }

  onLoadSchoolVTPVCMappingByFilters(): any {
    this.SearchBy.PageIndex = 0;
    this.onLoadSchoolVTPVCMappingCriteria();
  }

  resetFilters(): void {
    this.SearchBy.PageIndex = 0;
    this.schoolVTPVCMappingFilterForm.reset();
    //this.schoolFilterForm.get('AcademicYearId').setValue(this.AcademicYearId);

    this.onLoadSchoolVTPVCMappingCriteria();
  }

}
