import { UserModel } from "./user.model";

export class SearchFilterModel {
    UserId: string;
    UserTypeId: string;
    Name: string;
    CharBy: string;
    Filter: any;
    SortOrder: string;
    PageIndex: number;
    PageSize: number;
    TotalResults: number;
    PageSizeOptions: any;
    ShowFirstLastButtons: boolean;
    IgnoreCriteria: boolean;
    SchoolGuid: string;
    AcademicYearGuid: string;
    IsActive: boolean;
    IsImplemented: boolean;
    SectorGuid: string;
    EmployerGuid: string;
    StartDateTime: any;
    EndDatetime: any;
    VTId: any;
    TypeofRequest: any;
    InternshipGuid: string;
    RequestFrom: any;
    ClassGuid: string;
    SectionGuid: string;
    StudentGuid: string;
    StateCode: string;
    DistrictCode: string;
    VTPGuid: string;
    VTGuid: string;
    IsActionTaken: boolean;
    RoleCode: string;
    IsCriterion: boolean;
    UserGuid: string;
    SchoolManagementId: string;
    SchoolCategoryId: string;
    StudentStateCode: string;
    DivisionGuid: string;
    BlockGuid: string;
    JobRoleGuid: string;
    VCGuid: string;
    ClassName: string;
    SectionName: string;
    Gender: string;
    IsMapped: boolean;
    Sectors: string;
    VTP: string;
    constructor(userModel?: UserModel) {

        this.UserId = userModel.UserId || null;
        this.UserTypeId = userModel.UserTypeId || null;
        this.VTId = null;
        this.Name = null;
        this.CharBy = null;
        this.Filter = {};
        this.SchoolGuid = null;
        this.AcademicYearGuid = null;
        this.SectorGuid = null;
        this.TypeofRequest = '';
        this.EmployerGuid = null;
        this.StartDateTime = null;
        this.EndDatetime = null;
        this.SortOrder = 'asc';
        this.PageIndex = 0;
        this.PageSize = 10000;
        this.TotalResults = 0;
        this.PageSizeOptions = [5, 10, 25, 50, 100, 200];
        this.ShowFirstLastButtons = true;
        this.InternshipGuid = null;
        this.ClassGuid = null;
        this.SectionGuid = null;
        this.StudentGuid = null;
        this.StateCode = '';
        this.DistrictCode = '';
        this.VTPGuid = null;
        this.VTGuid = null;
        RequestFrom: null;
        this.IsActionTaken = false;
        this.IsActive = null;
        this.IsImplemented = null;
        this.RoleCode = '';
        this.IsCriterion = true;
        this.UserGuid = null;
        this.SchoolManagementId = null;
        this.SchoolCategoryId = null;
        this.StudentStateCode = '';
        this.DivisionGuid = null;
        this.BlockGuid = null;
        this.JobRoleGuid = null;
        this.VCGuid = null;
        this.ClassName = '';
        this.SectionName = '';
        this.Gender = '';
        this.IsMapped = false;
        this.Sectors = '';
        this.VTP = '';
    }
}

