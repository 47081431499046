<div id="forgot-password-new-pass" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">

    <div id="forgot-password-intro" fxFlex fxHide fxShow.gt-xs
        *ngIf="appInfo.target == 'gj' || appInfo.target == 'lahi'">
        <div class="lahi-left-logo" [@animate]="{value:'*',params:{scale:'0.2'}}">
            <a class="link" [routerLink]="['/login']" skipLocationChange="true" replaceUrl="false">
                <img src="assets/images/logos/samagra-shiksha-abhiyan-{{appInfo.target}}.png">
            </a>
        </div>

        <div class="forgot-password-desc-content">
            <div class="title" [@animate]="{value:'*',params:{delay:'50ms',y:'25px'}}">
                <h3>વ્યવસાયલક્ષી શિક્ષણ</h3>
            </div>

            <div class="description" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
                યુવાનોની અભિપ્સાઓ અને સમાજમાં કૌશલ્યવાન યુવાધનની માંગને પહોંચી વળવા માટે, ભારત સરકાર દ્રારા વ્યક્તિગત
                ક્ષમતાઓ મુજબ અનુભવ અને પ્રેકટીકલ મહાવરા દ્રારા વ્યવસાયિક સજ્જતા દ્રારા વિદ્યાર્થીઓ માટેની વ્યવસાયિક
                તકોને
                વધારવા માટે ગુજરાત રાજયની માધ્યમિક અને ઉચ્ચતર માધ્યમિક શાળાઓમાં વ્યવસાયલક્ષી શિક્ષણ યોજના (વોકેશનલાઇઝેશન
                ઓફ
                સેકન્ડરી એન્ડ હાયર સેકન્ડરી સ્કીમ) દાખલ કરવામાં આવી છે.<br />
            </div>
            <div class="description" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
                ગુજરાત રાજયના 33 જિલ્લાઓમાં વોકેશનલ એજયુકેશન અંતર્ગત હાલ 260 માધ્યમિક અને ઉચ્ચતર માધ્યમિક શાળાઓમાં
                વ્યવસાયલક્ષી શિક્ષણને દાખલ કરેલ જેમાં (1) Retail (2) Tourism (3) Beauty & Wellness (4) Agriculture (5)
                Electronics & Hardware (6) Automotive (7) Apparel ટ્રેડ દાખલ કરવામાં આવ્યા. વોકેશનલ એજયુકેશનના અસરકારક
                અમલીકરણ માટે લાઈટહાઉસ પોર્ટલ શરૂ કરવામાં આવેલ છે.
            </div>
        </div>
    </div>

    <div id="forgot-password-new-pass" fxFlex fxHide fxShow.gt-sm *ngIf="appInfo.target == 'aws'"
    style="background-image: url('https://lendahandindia.org/wp-content/uploads/2021/11/img-4.jpg'); background-position: center bottom; padding: 0 !important;">

        <div class="jh-two-logo" style="padding: 15px !important; height: 240px;"
            [@animate]="{value:'*',params:{scale:'0.2'}}">
            <a style="float: left;" class="link" [routerLink]="['/login']" skipLocationChange="true" replaceUrl="false">
                <img style="width: 190px;" src="assets/images/logos/pathways-logo200x500.png">
            </a>
            
        </div>
    </div>

    <div id="forgot-password-intro" fxFlex fxHide fxShow.gt-sm *ngIf="appInfo.target == 'jh'"
        style="background-image: url('assets/images/logos/samagra-shiksha-abhiyan-jh-login.png'); background-position: center bottom; padding: 0 !important;">

        <div class="jh-two-logo" style="padding: 15px !important; height: 240px;"
            [@animate]="{value:'*',params:{scale:'0.2'}}">
            <a style="float: left;" class="link" [routerLink]="['/login']" skipLocationChange="true" replaceUrl="false">
                <img style="width: 190px;" src="assets/images/logos/samagra-shiksha-abhiyan-jh-left.png">
            </a>
            <a style="float: right;" class="link" [routerLink]="['/login']" skipLocationChange="true"
                replaceUrl="false">
                <img style="width: 190px;" src="assets/images/logos/samagra-shiksha-abhiyan-jh-right.png">
            </a>
        </div>

        <div class="forgot-password-desc-content" style="padding-left: 65px; padding-right: 20px;">
            <div class="description" style="max-width: fit-content;"
                [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
                Government of Jharkhand is committed to enable growth of the state through skilling the youth.
                A skilled and high productivity manpower base is a critical enabler in the growth of manufacturing,
                services and agriculture. Vocational Education in higher secondary schools is an intervention to make
                the youth skilled and employable at school level.
            </div>
        </div>
    </div>

    <div id="forgot-password-intro" fxFlex fxHide fxShow.gt-sm
        *ngIf="appInfo.target != 'jh' && appInfo.target != 'gj' && appInfo.target != 'aws' && appInfo.target != 'lahi'">
        <div class="lahi-left-logo" [@animate]="{value:'*',params:{scale:'0.2'}}">
            <a class="link" [routerLink]="['/login']" skipLocationChange="true" replaceUrl="false">
                <img src="assets/images/logos/samagra-shiksha-abhiyan-{{appInfo.target}}.png">
            </a>
        </div>

        <div class="forgot-password-desc-content">
            <div class="description" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
                Ministry of Education is implementing the Vocationalization of Secondary and Higher Secondary
                Education
                under Samagra Shiksha. Its main objectives are to enhance the employability of youth through
                demand-driven
                competency based, modular vocational courses and to bridge the divide between academic and learning.
            </div>
        </div>
    </div>

    <div id="forgot-password-form-wrapper" fusePerfectScrollbar
        [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">

        <div id="forgot-password-form">

            <div class="logo">
                <img src="assets/images/logos/pathways-logo.png">
            </div>

            <div class="title">You can reset your passwod</div>

            <form name="changePasswordForm" [formGroup]="changePasswordForm" novalidate>

                <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                    <mat-label>New Password</mat-label>
                    <!-- <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility> -->
                    <input matInput #password type="password" name="newPassword"
                        formControlName="NewPassword" required 
                        matTooltip="1) Your password must be between 8 and 50 characters.
                        2) Your password must contain at least one uppercase, or capital, letter (ex: A, B, etc.)
                        3) Your password must contain at least one lowercase letter.
                        4) Your password must contain at least one number digit (ex: 0, 1, 2, 3, etc.)
                        5) Your password must contain at least one special character -for example: @ # $ % ^ & + ="
                        matTooltipPosition='above' matTooltipClass="allow-cr">
                    <!-- <mat-hint align="end" aria-live="polite">
                        {{changePasswordForm.controls.NewPassword.value.length}} / {{passwordComponent.max}}
                    </mat-hint> -->
                    <mat-error *ngIf="changePasswordForm.controls.NewPassword.invalid">
                        {{getErrorMessage(changePasswordForm, 'NewPassword')}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                    <mat-label>Confirm Password</mat-label>
                    <input matInput [type]="hideConfirmPassword ? 'password' : 'text'" name="confirmPassword"
                        formControlName="ConfirmPassword" required 
                        matTooltip="1) Your password must be between 8 and 50 characters.
                        2) Your password must contain at least one uppercase, or capital, letter (ex: A, B, etc.)
                        3) Your password must contain at least one lowercase letter.
                        4) Your password must contain at least one number digit (ex: 0, 1, 2, 3, etc.)
                        5) Your password must contain at least one special character -for example: @ # $ % ^ & + ="
                        matTooltipPosition='above' matTooltipClass="allow-cr">                             
                    <mat-icon matSuffix (click)="setVisibleConfirmPassword()">{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}
                    </mat-icon>

                    <mat-error *ngIf="changePasswordForm.controls.ConfirmPassword.invalid">
                        {{getErrorMessage(changePasswordForm, 'ConfirmPassword')}}
                    </mat-error>
                    <mat-error *ngIf="changePasswordForm.controls.ConfirmPassword.errors">Password Not
                        Matched</mat-error>
                </mat-form-field>

                <button mat-raised-button class="submit-button" color="accent" aria-label="SUMIT"
                [disabled]="changePasswordForm.invalid"
                (click)="saveOrUpdateChangePasswordDetails()">
                    Sumit
                </button>
            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/login'">Go back to login</a>
            </div>
        </div>
    </div>
</div>