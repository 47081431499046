import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';
import { UserModel } from 'app/models/user.model';


@Injectable()
export class StudentCertificatesService {
    constructor(private http: BaseService) { }



    GetCertificateForStudentByVTId(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.Certificate.GetCertificateForStudentByVTId, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetCertificateById(sectorJobRoleId: string) {
        let requestParams = {
            DataId: sectorJobRoleId
        };

        return this.http
            .HttpPost(this.http.Services.Certificate.GetCertificateById, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    CreateOrUpdateCertificate(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Certificate.CreateOrUpdateCertificate, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    UploadSignatureImage(formData: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.Certificate.UploadCertificateImage, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    deleteSectorJobRoleById(sectorJobRoleId: string) {
        var sectorJobRoleParams = {
            DataId: sectorJobRoleId
        };

        return this.http
            .HttpPost(this.http.Services.SectorJobRole.Delete, sectorJobRoleParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }
    GetCertificatePdf(certificateId: string) {
        let requestParams = {
            DataId: certificateId,
        };

        return this.http.HttpPost(this.http.Services.Certificate.GetCertificatePdf, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    getDropdownforSectorJobRole(userModel: UserModel): Observable<any[]> {
        let studentRequest = this.http.GetMasterDataByType({ DataType: 'StudentsByUserId',UserId:userModel.UserTypeId, ParentId: null, SelectTitle: 'StudentName' });
        let certificateType = this.http.GetMasterDataByType({ DataType: 'DataValues', UserId:null,  ParentId: 'StudentCertificateType',SelectTitle: 'StudentCertificateType' });
        let employerRequest = this.http.GetMasterDataByType({ DataType: 'Employers', SelectTitle: 'Employer' });


        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        return forkJoin([studentRequest,certificateType,employerRequest,]);
    }
}
