<div id="vt-student-exit-survey-detail" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    VT STUDENT EXIT SURVEY DETAILS
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter (keyup)="applySearchFilter($event.target.value)"
                        placeholder="Search for a VT Student Exit Survey Details">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <button mat-raised-button [routerLink]="['/vt-student-details','new']" skipLocationChange="true"
                replaceUrl="false" class="add-vt-student-exit-survey-detail-button fuse-white mt-24 mt-md-0">
                <span>ADD</span>
            </button>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">
            <form name="vtExitSurveyDetailsForm" [formGroup]="vtExitSurveyDetailsForm" class="summary-dashboard-view"
                fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex>
                <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                    fxLayoutGap.lt-md="0px">
                    <mat-form-field fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100" appearance="outline">
                        <mat-label> Academic Year</mat-label>

                        <mat-select formControlName="AcademicYearId" name="academicYearId" appearance="outline"
                            (selectionChange)="onChangeAcademicYear($event.value)">
                            <mat-option [value]="academicYearItem.Name"
                                *ngFor="let academicYearItem of academicyearList">
                                {{ academicYearItem.Name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100" appearance="outline">
                        <mat-label> Class</mat-label>

                        <mat-select formControlName="ClassId" name="classId" required appearance="outline"
                            (selectionChange)="onChangeClass()">
                            <mat-option [value]="classItem.Name" *ngFor="let classItem of classList">
                                {{ classItem.Name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="34" fxFlex.lt-sm="100">
                        <mat-label>Upload Excel File</mat-label>
                        <ngx-mat-file-input name="UploadFile" formControlName="UploadFile" required
                            [accept]="AllowedExcelExtensions" (change)="uploadedFile($event)"
                            placeholder="Select excel template file" matTooltip="Select excel template file"
                            matTooltipPosition="above"></ngx-mat-file-input>

                        <mat-error *ngIf="vtExitSurveyDetailsForm.controls.UploadFile.invalid">
                            {{getErrorMessage(vtExitSurveyDetailsForm, 'UploadFile')}}
                        </mat-error>
                    </mat-form-field>
                    <!-- (change)="uploadedFile($event)" -->
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="10">

                        <span class="material-icons action-btn" (click)="uploadExcelData()"
                            matTooltip="Upload bulk data" matTooltipPosition="above">cloud_upload</span>
                        <span class="material-icons action-btn" *ngIf="isAvailableUploadedExcel"
                            (click)="downloadUploadedExcelResults()" matTooltip="Download Uploaded Excel Results"
                            matTooltipPosition="above">get_app</span>

                    </div>
                </div>
            </form>

            <mat-table class="vt-student-exit-survey-detail-table responsive-table" #table [dataSource]="tableDataSource" matSort
                fusePerfectScrollbar [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- AcademicYear Column -->
                <ng-container matColumnDef="AcademicYear">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Academic Year
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtExitStudentDetail">
                        <p class="text-truncate">{{vtExitStudentDetail.AcademicYear}}</p>
                    </mat-cell>
                </ng-container>

                <!-- StudentFullName Column -->
                <ng-container matColumnDef="StudentFullName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Student Full Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtExitStudentDetail">
                        <p class="text-truncate">{{vtExitStudentDetail.StudentFullName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- NameOfSchool Column -->
                <ng-container matColumnDef="NameOfSchool">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Name Of School
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtExitStudentDetail" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vtExitStudentDetail.NameOfSchool}}</p>
                    </mat-cell>
                </ng-container>

                <!-- UdiseCode Column -->
                <ng-container matColumnDef="UdiseCode">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Udise Code</mat-header-cell>
                    <mat-cell *matCellDef="let vtExitStudentDetail" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vtExitStudentDetail.UdiseCode}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Class Column -->
                <ng-container matColumnDef="Class">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Class</mat-header-cell>
                    <mat-cell *matCellDef="let vtExitStudentDetail" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vtExitStudentDetail.Class}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Sector Column -->
                <ng-container matColumnDef="Sector">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Sector</mat-header-cell>
                    <mat-cell *matCellDef="let vtExitStudentDetail" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vtExitStudentDetail.Sector}}</p>
                    </mat-cell>
                </ng-container>

                <!-- JobRole Column -->
                <ng-container matColumnDef="JobRole">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>JobRole</mat-header-cell>
                    <mat-cell *matCellDef="let vtExitStudentDetail" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vtExitStudentDetail.JobRole}}</p>
                    </mat-cell>
                </ng-container>

                <!-- IsActive Column -->
                <!-- <ng-container matColumnDef="IsActive">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Is Active?</mat-header-cell>
                    <mat-cell *matCellDef="let vtExitStudentDetail" fxHide fxShow.gt-xs>
                        <mat-icon *ngIf="vtExitStudentDetail.IsActive" class="active-icon green-600 s-16">check</mat-icon>
                        <mat-icon *ngIf="!vtExitStudentDetail.IsActive" class="active-icon red-500 s-16">close</mat-icon>
                    </mat-cell>
                </ng-container> -->

                <ng-container matColumnDef="Actions">
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let vtExitStudentDetail" fxShow.gt-xs>
                        <button *ngIf="PageRights.IsView && vtExitStudentDetail.isExitSurveyFilled == 1" mat-icon-button
                            [routerLink]="['/vt-student-exit-survey-details', 'view', vtExitStudentDetail.ExitStudentId, vtExitStudentDetail.AcademicYear, vtExitStudentDetail.Class]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">visibility</mat-icon>
                        </button>
                        <button *ngIf="PageRights.IsEdit && vtExitStudentDetail.isExitSurveyFilled == 0" mat-icon-button
                            [routerLink]="['/vt-student-exit-survey-details', 'add', vtExitStudentDetail.ExitStudentId, vtExitStudentDetail.AcademicYear, vtExitStudentDetail.Class]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">assessment</mat-icon>
                        </button>
                        <button *ngIf="PageRights.IsEdit && vtExitStudentDetail.isExitSurveyFilled == 1" mat-icon-button
                            [routerLink]="['/vt-student-exit-survey-details', 'edit', vtExitStudentDetail.ExitStudentId, vtExitStudentDetail.AcademicYear, vtExitStudentDetail.Class]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">edit</mat-icon>
                        </button>
                        <button mat-icon-button color="warn"
                            (click)="onDeleteVTStudentPlacementDetail(vtExitStudentDetail.ExitStudentId)"
                            *ngIf="PageRights.IsDelete">
                            <mat-icon>delete_outline</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let vtExitStudentDetail; columns: displayedColumns;"
                    class="vt-student-exit-survey-detail" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>