import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { RouteConstants } from 'app/constants/route.constant'
import { SchoolSectorJobRoleVTPService } from '../school-sector-job-role-vtp.service';
import { SchoolSectorJobRoleVTPModel } from '../school-sector-job-role-vtp.model';
import { DropdownModel } from 'app/models/dropdown.model';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'school-sector-job-role',
  templateUrl: './create-school-sector-job-role-vtp.component.html',
  styleUrls: ['./create-school-sector-job-role-vtp.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CreateSchoolSectorJobRoleVTPComponent extends BaseComponent<SchoolSectorJobRoleVTPModel> implements OnInit {
  schoolSectorJobRoleVTPForm: FormGroup;
  schoolSectorJobRoleVTPModel: SchoolSectorJobRoleVTPModel;
  academicyearList: [DropdownModel];
  phaseList: [DropdownModel];
  sectorList: [DropdownModel];
  jobRoleList: [DropdownModel];
  schoolsList: [DropdownModel];
  roleList: [DropdownModel];
  vtpList: [DropdownModel];
  remarkText: string;
  statecodeList: [DropdownModel];
  filteredSchoolJobRoleItems: any;
  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private route: ActivatedRoute,
    private schoolSectorJobRoleVTPService: SchoolSectorJobRoleVTPService,
    private dialogService: DialogService,
    private formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Set the default sectorJobRole Model
    this.schoolSectorJobRoleVTPModel = new SchoolSectorJobRoleVTPModel();
  }

  ngOnInit(): void {
    this.commonService.GetMasterDataByType({ DataType: 'VocationalTrainingProviders', UserId: this.UserModel.LoginId, ParentId: this.UserModel.UserTypeId, SelectTitle: 'Vocational Training Provider' }).subscribe((response) => {
      if (response.Success) {
        this.vtpList = response.Results;
      }
    });
    this.schoolSectorJobRoleVTPService.getDropdownforSectorJobRole(this.UserModel).subscribe((response: any) => {
    
      if (response[0].Success) {
        this.sectorList = response[0].Results;
      }
      if (response[1].Success) {
        this.roleList = response[1].Results;
        
      }
      if (response[2].Success) {
        this.schoolsList = response[2].Results;
        this.filteredSchoolJobRoleItems = this.schoolsList.slice();
      }
      if (response[3].Success) {
        this.academicyearList = response[3].Results;
      }
      if (response[5].Success) {
        this.statecodeList = response[5].Results;
        for (const i of response[5].Results) {
          this.schoolSectorJobRoleVTPForm.get('StateCode').setValue(i.Id);
        }
      }
    
      
      this.route.paramMap.subscribe(params => {
        if (params.keys.length > 0) {
          this.PageRights.ActionType = params.get('actionType');

          if (this.PageRights.ActionType == this.Constants.Actions.New) {
            this.schoolSectorJobRoleVTPModel = new SchoolSectorJobRoleVTPModel();

          } else {
            var schoolSectorJobRolesVTPId: string = params.get('SchoolSectorJobRolesVTPId')

            this.schoolSectorJobRoleVTPService.getSchoolSectorJobRoleVTPById(schoolSectorJobRolesVTPId)
              .subscribe((response: any) => {
                this.schoolSectorJobRoleVTPModel = response.Result;

                if (this.PageRights.ActionType == this.Constants.Actions.Edit)
                  this.schoolSectorJobRoleVTPModel.RequestType = this.Constants.PageType.Edit;
                else if (this.PageRights.ActionType == this.Constants.Actions.View) {
                  this.schoolSectorJobRoleVTPModel.RequestType = this.Constants.PageType.View;
                  this.PageRights.IsReadOnly = true;
                }
                if (this.schoolSectorJobRoleVTPModel.DateOfDeAllocation != null) {
                  this.schoolSectorJobRoleVTPForm.get("DateOfDeAllocation").setValue(this.getDateValue(this.schoolSectorJobRoleVTPModel.DateOfDeAllocation));
                  let dateOfResignationCtrl = this.schoolSectorJobRoleVTPForm.get("DateOfDeAllocation");
                  this.onChangeDateEnableDisableCheckBox(this.schoolSectorJobRoleVTPForm, dateOfResignationCtrl);
                }
                this.schoolSectorJobRoleVTPForm = this.createSchoolSectorJobRoleVTPForm();
              });
          }
        }
      });
    });
    this.schoolSectorJobRoleVTPForm = this.createSchoolSectorJobRoleVTPForm();
  }
 
 
  saveOrUpdateSectorJobRoleVTPDetails() {
    this.setValueFromFormGroup(this.schoolSectorJobRoleVTPForm, this.schoolSectorJobRoleVTPModel);
    this.schoolSectorJobRoleVTPModel.Remarks = this.remarkText;

    this.schoolSectorJobRoleVTPService.createOrUpdateSchoolSectorJobRole(this.schoolSectorJobRoleVTPModel)
      .subscribe((sectorJobRoleResp: any) => {

        if (sectorJobRoleResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );

            this.router.navigate([RouteConstants.SchoolSectorJobRoleVTP.List]);
          });
        }
        else {
          var errorMessages = this.getHtmlMessage(sectorJobRoleResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('SectorJobRole deletion errors =>', error);
      });
  }

  onChangeSector(evt) {

    this.commonService
      .GetMasterDataByType({ DataType: 'JobRoles', ParentId: evt.value })
      .subscribe((response: any) => {

        if (response.Success) {
          this.jobRoleList = response.Results;
          var sectorItem = this.sectorList.find(x => x.Id == evt.value);
          var jobRoleItem = this.jobRoleList.find(x => x.Id == this.schoolSectorJobRoleVTPForm.get('JobRoleId').value);
          this.remarkText = sectorItem.Name + (jobRoleItem == null ? '' : '-' + jobRoleItem.Name);
          this.schoolSectorJobRoleVTPForm.get('Remarks').setValue(this.remarkText);
        }
      });
  }

  onChangeJobRole(evt) {
    var sectorItem = this.sectorList.find(x => x.Id == this.schoolSectorJobRoleVTPForm.get('SectorId').value);
    var jobRoleItem = this.jobRoleList.find(x => x.Id == evt.value);
    this.remarkText = (sectorItem == null ? '' : sectorItem.Name + '-') + jobRoleItem.Name;
    this.schoolSectorJobRoleVTPForm.get('Remarks').setValue(this.remarkText);
  }

  showOptions(event:MatCheckboxChange): void {
    if(event.checked == false){
      this.dialogService
      .openConfirmDialog(this.Constants.Messages.IsactiveConfirmationMessage)
      .afterClosed()
      .subscribe(confResp => {
        if (confResp == true) {
          console.log(event.checked);
          if (event.checked == false) {
            var currentDate = new Date();
            this.schoolSectorJobRoleVTPForm.get('DateOfDeAllocation').setValue(this.getDateValue(currentDate));
  
          } else {
            this.schoolSectorJobRoleVTPForm.get('DateOfDeAllocation').setValue('');
          }
        } else {
          this.onChangeDateEnableDisableCheckBox(this.schoolSectorJobRoleVTPForm, event.checked);
          this.schoolSectorJobRoleVTPForm.get('DateOfDeAllocation').setValue('');
  
        }
      });
    }else{
      this.schoolSectorJobRoleVTPForm.get('DateOfDeAllocation').setValue('');
      
    }


  }

  //Create sectorJobRole form and returns {FormGroup}
  createSchoolSectorJobRoleVTPForm(): FormGroup {
    return this.formBuilder.group({
      SchoolSectorJobRolesId: new FormControl(this.schoolSectorJobRoleVTPModel.SchoolSectorJobRolesId),
      SchoolSectorJobRolesVTPId: new FormControl({ value: this.schoolSectorJobRoleVTPModel.SchoolSectorJobRolesVTPId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      VTPId: new FormControl({ value: this.schoolSectorJobRoleVTPModel.VTPId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      AcademicYearId: new FormControl({ value: this.schoolSectorJobRoleVTPModel.AcademicYearId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      DateOfAllocation: new FormControl({ value: new Date(this.schoolSectorJobRoleVTPModel.DateOfAllocation), disabled: this.PageRights.IsReadOnly }, Validators.required), 
      DateOfDeAllocation: new FormControl({ value: this.getDateValue(this.schoolSectorJobRoleVTPModel.DateOfDeAllocation), disabled: this.PageRights.IsReadOnly }), 
      Remarks: new FormControl({ value: this.schoolSectorJobRoleVTPModel.Remarks, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(350)),
      IsActive: new FormControl({ value: this.schoolSectorJobRoleVTPModel.IsActive, disabled: this.PageRights.IsReadOnly }),
      StateCode: new FormControl({ value: this.schoolSectorJobRoleVTPModel.StateCode, disabled: true }, Validators.required),

    });
  }
}
