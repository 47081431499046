<div id="internship-opportunity" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row wrap" fxLayoutAlign="space-between center">


            <!-- APP TITLE -->
            <div fxLayout="row" class="screen-heading" fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)"
                fxFlex.lt-sm="100%">
                <div fxLayout="row" fxLayoutAlign="start center">

                    <!-- <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/vt-student'">
                        <mat-icon>arrow_back</mat-icon>
                    </button> -->

                    <div fxLayout="column" fxLayoutAlign="start start"
                        [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                        <div class="h1" *ngIf="PageRights.PageType ==='Edit'">
                            <!-- {{ schoolModel.SchoolName }} -->
                            <h1> Edit Internship Opportunity</h1>
                        </div>
                        <div class="h1" *ngIf="PageRights.PageType ==='New'">
                            <h1>Create Internship Opportunities</h1>
                        </div>
                        <div class="subtitle secondary-text">
                            <h1><span>Create Internship Opportunities</span></h1>
                        </div>
                    </div>
                </div>
            </div>

            <!-- / APP TITLE -->

            <div fxLayout="row" class="add-edit-button" fxLayoutAlign="flex-start" fxFlex="0 1 calc(50%)"
                fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="100%">

                <button mat-raised-button class="add-internship-button btn-outline-dark"
                    [routerLink]="'/internship-opportunity'" fxFlex="0 1 calc(33%)" fxFlex.lt-md="0 1 calc(33%)"
                    fxFlex.lt-sm="50%">
                    <span>Manage Internship </span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="ms-2" width="20" height="20" viewBox="0 0 24 24"
                        stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"></path>
                        <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3"></path>
                        <line x1="16" y1="5" x2="19" y2="8"></line>
                    </svg>
                </button>

                <button mat-raised-button class="add-internship-button btn-warning mls"
                    *ngIf="PageRights.ActionType ==='new'" (click)="saveOrUpdateInternshipOpportunityDetails('Publish')"
                    fxFlex="0 1 calc(33%)" fxFlex.lt-md="0 1 calc(33%)" fxFlex.lt-sm="50%">
                    <span>Publish</span>
                </button>

                <button mat-raised-button class="add-internship-button btn-warning mls"
                    *ngIf="PageRights.ActionType ==='new'" (click)="saveOrUpdateInternshipOpportunityDetails('draft')"
                    fxFlex="0 1 calc(33%)" fxFlex.lt-md="0 1 calc(33%)" fxFlex.lt-sm="50%">
                    <span>Draft </span>
                </button>

                <button mat-raised-button class="save-internship-button btn-success"
                    *ngIf="PageRights.ActionType ==='edit'" (click)="saveOrUpdateInternshipOpportunityDetails()">
                    <span>SAVE</span>
                </button>

            </div>

        </div>
        <!-- / HEADER -->
        <!-- <div class="padding-bottom">
            <mat-tab-group selectedIndex="0" (selectedTabChange)="changeTab($event)">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Add A Student</span>
                    </ng-template>

                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Add Multiple Student</span>
                    </ng-template>

                </mat-tab>
            </mat-tab-group>
        </div> -->



        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="internshipOpportunityForm" [formGroup]="internshipOpportunityForm"
                    class="internship w-100-p p-24 mr-24" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto"
                    fxFlex fusePerfectScrollbar>

                    <div class="required-mark">
                        <span><span class="note">Note: </span>All fields marked with an asterisk (<span
                                class="asterisk-color">*</span>) are required.</span>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <p class="medium text-black-600 font-weight-semibold mt-3">Add an Internship</p>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label> Employer</mat-label>

                            <mat-select formControlName="EmployerId" required name="employerId" appearance="outline"
                                #SelectEmployer (selectionChange)="onChangeEmployeer($event.value)">
                                <mat-select-filter class="select-filter" *ngIf="SelectEmployer.focused"
                                    [array]="employerList" (filteredReturn)="this.filteredEmployerItems = $event"
                                    [displayMember]="'Name'">
                                </mat-select-filter>
                                <mat-option [value]="employerItem.Id"
                                    *ngFor="let employerItem of filteredEmployerItems">
                                    {{ employerItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Internship Title</mat-label>
                            <input matInput name="title" required formControlName="Title"
                                placeholder="Ex. Machine Workshop Helper" matTooltip="Internship Title should be typed in Full, first letter of each word in 
                                Capital followed by small letters. Please avoid commas and dots in name."
                                matTooltipPosition="above">
                            <mat-error *ngIf="internshipOpportunityForm.controls.Title.invalid">
                                {{getErrorMessage(internshipOpportunityForm, 'Title')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Date of Publishing</mat-label>
                            <input matInput [matDatepicker]="picker1" name="publishdDate" formControlName="PublishDate"
                                [max]="CurrentDate" [disabled]="PageRights.IsReadOnly" required>
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                            <mat-error *ngIf="internshipOpportunityForm.controls.PublishDate.invalid">
                                {{getErrorMessage(internshipOpportunityForm, 'PublishDate')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Internship Type</mat-label>
                            <mat-select name="internshipType" formControlName="InternshipType" required
                                appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="internshipTypeItem.Id"
                                    *ngFor="let internshipTypeItem of internshipTypeList">
                                    {{ internshipTypeItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="internshipOpportunityForm.controls.InternshipType.invalid">
                                {{getErrorMessage(internshipOpportunityForm, 'InternshipType')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="35" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Interest</mat-label>
                            <mat-select name="interestId" formControlName="InterestId" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="interestItem.Id" *ngFor="let interestItem of interestList">
                                    {{ interestItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="internshipOpportunityForm.controls.InterestId.invalid">
                                {{getErrorMessage(internshipOpportunityForm, 'InterestId')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Sector</mat-label>
                            <mat-select formControlName="SectorId" name="sectorId">
                                <mat-option [value]="sectorItem.Id" *ngFor="let sectorItem of sectorList">
                                    {{ sectorItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="internshipOpportunityForm.controls.SectorId.invalid">
                                {{getErrorMessage(internshipOpportunityForm, 'SectorId')}}
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="25" fxFlex.lt-md="25" fxFlex.lt-sm="100">
                            <mat-label>Start Date</mat-label>
                            <input matInput [matDatepicker]="picker2" name="startDate" formControlName="StartDate"
                                [disabled]="PageRights.IsReadOnly" required>
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                            <mat-error *ngIf="internshipOpportunityForm.controls.StartDate.invalid">
                                {{getErrorMessage(internshipOpportunityForm, 'StartDate')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="25" fxFlex.lt-md="25" fxFlex.lt-sm="100">
                            <mat-label>Duration</mat-label>
                            <input matInput name="duration" required formControlName="Duration">
                            <mat-error *ngIf="internshipOpportunityForm.controls.Duration.invalid">
                                {{getErrorMessage(internshipOpportunityForm, 'Duration')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="25" fxFlex.lt-md="25" fxFlex.lt-sm="100">
                            <mat-label>Stipend ( <span>&#8377;</span> )</mat-label>
                            <input matInput name="stipend" formControlName="Stipend" required>
                            <mat-error *ngIf="internshipOpportunityForm.controls.Stipend.invalid">
                                {{getErrorMessage(internshipOpportunityForm, 'Stipend')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="25" fxFlex.lt-md="25" fxFlex.lt-sm="100">
                            <mat-label>No. of Openings</mat-label>
                            <input matInput name="noOfOpenings" formControlName="NoOfOpenings" required>
                            <mat-error *ngIf="internshipOpportunityForm.controls.NoOfOpenings.invalid">
                                {{getErrorMessage(internshipOpportunityForm, 'NoOfOpenings')}}
                            </mat-error>
                        </mat-form-field>

                    </div>


                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Perks (Optional)</mat-label>
                            <input matInput name="perks" formControlName="Perks" placeholder="Perks" matTooltip="Perks"
                                matTooltipPosition="above">
                            <mat-error *ngIf="internshipOpportunityForm.controls.Perks.invalid">
                                {{getErrorMessage(internshipOpportunityForm, 'Perks')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Job Description (Optional)</mat-label>
                            <input matInput name="jobDescription" formControlName="JobDescription"
                                placeholder="Ex. JobDescription" matTooltip="Job Description"
                                matTooltipPosition="above">
                            <mat-error *ngIf="internshipOpportunityForm.controls.JobDescription.invalid">
                                {{getErrorMessage(internshipOpportunityForm, 'JobDescription')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Skills Required (Optional)</mat-label>
                            <!-- <input matInput name="requiredSkills" formControlName="RequiredSkills"
                                placeholder="Ex. RequiredSkills" matTooltip="Skills Required "
                                matTooltipPosition="above"> -->

                            <textarea matInput name="requiredSkills" formControlName="RequiredSkills"
                                placeholder="Ex. RequiredSkills" matTooltip="Skills Required "
                                matTooltipPosition="above"></textarea>


                            <mat-error *ngIf="internshipOpportunityForm.controls.RequiredSkills.invalid">
                                {{getErrorMessage(internshipOpportunityForm, 'RequiredSkills')}}
                            </mat-error>
                        </mat-form-field>
                    </div>



                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>District Name</mat-label>

                            <mat-select formControlName="DistrictCode" name="districtCode" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly" #SelectDistrict>

                                <mat-select-filter class="select-filter" *ngIf="SelectDistrict.focused"
                                    [array]="distList" (filteredReturn)="this.filteredDistrictItems = $event"
                                    [displayMember]="'Name'">
                                </mat-select-filter>

                                <mat-option [value]="distItem.Id" *ngFor="let distItem of filteredDistrictItems">
                                    {{ distItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="internshipOpportunityForm.controls.DistrictCode.invalid">
                                {{getErrorMessage(internshipOpportunityForm, 'DistrictCode')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Pincode</mat-label>
                            <input matInput name="pincode" required formControlName="Pincode" digitOnly minlength="6"
                                maxlength="6" matTooltip="Enter the 6 digit pincode." matTooltipPosition="above">
                            <mat-error *ngIf="internshipOpportunityForm.controls.Pincode.invalid">
                                {{getErrorMessage(internshipOpportunityForm, 'Pincode')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Branch Name</mat-label>
                            <mat-select formControlName="BranchId" name="branchId" required>
                                <mat-option [value]="branchItem.Id" *ngFor="let branchItem of branchList">
                                    {{ branchItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="internshipOpportunityForm.controls.BranchId.invalid">
                                {{getErrorMessage(internshipOpportunityForm, 'BranchId')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Person of Contact for Internship (Optional)</mat-label>
                            <input matInput name="pOCForInternship" formControlName="POCForInternship"
                                placeholder="Person of Contact for Internship"
                                matTooltip="Person of Contact for Internship" matTooltipPosition="above">
                            <mat-error *ngIf="internshipOpportunityForm.controls.POCForInternship.invalid">
                                {{getErrorMessage(internshipOpportunityForm, 'POCForInternship')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Email ID of Person of Contact (Optional)</mat-label>
                            <input matInput name="pOCEmail" formControlName="POCEmail" placeholder="Ex. POCEmail"
                                matTooltip="Email ID of Person of Contact" matTooltipPosition="above">
                            <mat-error *ngIf="internshipOpportunityForm.controls.POCEmail.invalid">
                                {{getErrorMessage(internshipOpportunityForm, 'POCEmail')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Mobile No. of Person of Contact (Optional)</mat-label>
                            <input matInput name="pOCMobile" formControlName="POCMobile" placeholder="Ex. POCMobile"
                                matTooltip="Mobile No. of Person of Contact" matTooltipPosition="above"
                                digitOnly minlength="10" maxlength="10">
                            <mat-error *ngIf="internshipOpportunityForm.controls.POCMobile.invalid">
                                {{getErrorMessage(internshipOpportunityForm, 'POCMobile')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-label>Work Type</mat-label>
                        <mat-radio-group aria-labelledby="Select WorkingType" name="workingType"
                            formControlName="WorkingType" appearance="outline" fxFlex="70" fxFlex.lt-md="75"
                            fxFlex.lt-sm="100" fxLayoutGap="30px" class="demography-space"
                            [disabled]="PageRights.IsReadOnly">
                            <mat-radio-button value="FullTime">Full Time</mat-radio-button>
                            <mat-radio-button value="PartTime">Part Time</mat-radio-button>
                        </mat-radio-group>
                        <mat-error *ngIf="internshipOpportunityForm.controls.WorkingType.invalid">
                            {{getErrorMessage(internshipOpportunityForm, 'WorkingType')}}
                        </mat-error>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <span class="checkbox-space">Is Active?</span>
                        <mat-checkbox matInput formControlName="IsActive" [disabled]="PageRights.IsReadOnly">
                        </mat-checkbox>
                    </div>

                    <!-- </mat-tab>
                    </mat-tab-group> -->
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>