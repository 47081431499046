<div id="reset-password" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">

    <div id="reset-password-intro" fxFlex fxHide fxShow.gt-sm
        *ngIf="appInfo.target == 'gj' || appInfo.target == 'lahi'">
        <div class="lahi-left-logo" [@animate]="{value:'*',params:{scale:'0.2'}}">
            <a class="link" [routerLink]="['/login']" skipLocationChange="true" replaceUrl="false">
                <img src="assets/images/logos/samagra-shiksha-abhiyan-{{appInfo.target}}.png">
            </a>
        </div>

        <div class="reset-password-desc-content">
            <div class="title" [@animate]="{value:'*',params:{delay:'50ms',y:'25px'}}">
                <h3>વ્યવસાયલક્ષી શિક્ષણ</h3>
            </div>

            <div class="description" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
                યુવાનોની અભિપ્સાઓ અને સમાજમાં કૌશલ્યવાન યુવાધનની માંગને પહોંચી વળવા માટે, ભારત સરકાર દ્રારા વ્યક્તિગત
                ક્ષમતાઓ મુજબ અનુભવ અને પ્રેકટીકલ મહાવરા દ્રારા વ્યવસાયિક સજ્જતા દ્રારા વિદ્યાર્થીઓ માટેની વ્યવસાયિક
                તકોને
                વધારવા માટે ગુજરાત રાજયની માધ્યમિક અને ઉચ્ચતર માધ્યમિક શાળાઓમાં વ્યવસાયલક્ષી શિક્ષણ યોજના (વોકેશનલાઇઝેશન
                ઓફ
                સેકન્ડરી એન્ડ હાયર સેકન્ડરી સ્કીમ) દાખલ કરવામાં આવી છે.<br />
            </div>
            <div class="description" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
                ગુજરાત રાજયના 33 જિલ્લાઓમાં વોકેશનલ એજયુકેશન અંતર્ગત હાલ 260 માધ્યમિક અને ઉચ્ચતર માધ્યમિક શાળાઓમાં
                વ્યવસાયલક્ષી શિક્ષણને દાખલ કરેલ જેમાં (1) Retail (2) Tourism (3) Beauty & Wellness (4) Agriculture (5)
                Electronics & Hardware (6) Automotive (7) Apparel ટ્રેડ દાખલ કરવામાં આવ્યા. વોકેશનલ એજયુકેશનના અસરકારક
                અમલીકરણ માટે લાઈટહાઉસ પોર્ટલ શરૂ કરવામાં આવેલ છે.
            </div>
        </div>
    </div>

    <div id="login-intro" fxFlex fxHide fxShow.gt-sm *ngIf="appInfo.target == 'aws'">
        <div class="lahi-left-logo" [@animate]="{value:'*',params:{scale:'0.2'}}">
            <a class="link" [routerLink]="['/login']" skipLocationChange="true" replaceUrl="false">
                <!-- <img src="assets/images/logos/samagra-shiksha-abhiyan-aws.png"> -->
                <img src="assets/images/logos/pathways-logo200x500.png">
            </a>
        </div>

        <div class="login-desc-content">
            <div class="title" [@animate]="{value:'*',params:{delay:'50ms',y:'25px'}}">
                <h3>Welcome To Pathways Application</h3>
            </div>

            <div class="description" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
                <!-- This is Management Information System for M&E - Lighthouse -->
            </div>
        </div>
    </div>

    <div id="reset-password-intro" fxFlex fxHide fxShow.gt-sm *ngIf="appInfo.target == 'jh'"
        style="background-image: url('assets/images/logos/samagra-shiksha-abhiyan-jh-login.png'); background-position: center bottom; padding: 0 !important;">

        <div class="jh-two-logo" style="padding: 15px !important; height: 240px;"
            [@animate]="{value:'*',params:{scale:'0.2'}}">
            <a style="float: left;" class="link" [routerLink]="['/login']" skipLocationChange="true" replaceUrl="false">
                <img style="width: 190px;" src="assets/images/logos/samagra-shiksha-abhiyan-jh-left.png">
            </a>
            <a style="float: right;" class="link" [routerLink]="['/login']" skipLocationChange="true"
                replaceUrl="false">
                <img style="width: 190px;" src="assets/images/logos/samagra-shiksha-abhiyan-jh-right.png">
            </a>
        </div>

        <div class="reset-password-desc-content" style="padding-left: 65px; padding-right: 20px;">
            <div class="description" style="max-width: fit-content;"
                [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
                Government of Jharkhand is committed to enable growth of the state through skilling the youth.
                A skilled and high productivity manpower base is a critical enabler in the growth of manufacturing,
                services and agriculture. Vocational Education in higher secondary schools is an intervention to make
                the youth skilled and employable at school level.
            </div>
        </div>
    </div>

    <div id="reset-password-intro" fxFlex fxHide fxShow.gt-sm
        *ngIf="appInfo.target != 'jh' && appInfo.target != 'gj' && appInfo.target != 'aws' && appInfo.target != 'lahi'">
        <div class="lahi-left-logo" [@animate]="{value:'*',params:{scale:'0.2'}}">
            <a class="link" [routerLink]="['/login']" skipLocationChange="true" replaceUrl="false">
                <img src="assets/images/logos/samagra-shiksha-abhiyan-{{appInfo.target}}.png">
            </a>
        </div>

        <div class="reset-password-desc-content">
            <div class="description" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
                Ministry of Education is implementing the Vocationalization of Secondary and Higher Secondary
                Education
                under Samagra Shiksha. Its main objectives are to enhance the employability of youth through
                demand-driven
                competency based, modular vocational courses and to bridge the divide between academic and learning.
            </div>
        </div>
    </div>

    <div id="reset-password-form-wrapper" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">

        <div id="reset-password-form">

            <div class="logo">
                <img src="assets/images/logos/pathways-logo.png">
            </div>

            <div class="title">RESET YOUR PASSWORD</div>

            <form name="resetPasswordForm" [formGroup]="resetPasswordForm" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput name="userId" formControlName="UserId" required>
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>

                    <mat-error *ngIf="resetPasswordForm.controls.UserId.invalid">{{getErrorMessage(resetPasswordForm,
                        'UserId')}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <!-- <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility> -->
                    <input matInput type="password" name="newPassword" formControlName="NewPassword" required
                        matTooltip="1) Your password must be between 8 and 50 characters.
                    2) Your password must contain at least one uppercase, or capital, letter (ex: A, B, etc.)
                    3) Your password must contain at least one lowercase letter.
                    4) Your password must contain at least one number digit (ex: 0, 1, 2, 3, etc.)
                    5) Your password must contain at least one special character -for example: @ # $ % ^ & + ="
                        matTooltipPosition='above' matTooltipClass="allow-cr">
                    <!-- <mat-hint align="end" aria-live="polite">
                        {{resetPasswordForm.controls.NewPassword.value.length}} / {{passwordComponent.max}}
                    </mat-hint> -->
                    <mat-error *ngIf="resetPasswordForm.controls.NewPassword.invalid">
                        {{getErrorMessage(resetPasswordForm, 'NewPassword')}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Confirm Password</mat-label>
                    <input matInput type="password" name="confirmPassword" formControlName="ConfirmPassword" required
                        matTooltip="1) Your password must be between 8 and 50 characters.
                    2) Your password must contain at least one uppercase, or capital, letter (ex: A, B, etc.)
                    3) Your password must contain at least one lowercase letter.
                    4) Your password must contain at least one number digit (ex: 0, 1, 2, 3, etc.)
                    5) Your password must contain at least one special character -for example: @ # $ % ^ & + ="
                        matTooltipPosition='above' matTooltipClass="allow-cr">
                    <mat-error *ngIf="resetPasswordForm.controls.ConfirmPassword.invalid">
                        {{getErrorMessage(resetPasswordForm, 'ConfirmPassword')}}
                    </mat-error>
                    <mat-error *ngIf="resetPasswordForm.controls.ConfirmPassword.errors">Password Not
                        Matched
                    </mat-error>
                </mat-form-field>

                <button mat-raised-button class="submit-button" color="accent" aria-label="RESET MY PASSWORD"
                    (click)="resetPassword()">
                    RESET MY PASSWORD
                </button>
            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/login'">Go back to login</a>
            </div>

        </div>
    </div>
</div>