import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';
import { UserModel } from "app/models/user.model";

@Injectable()
export class SchoolVTMappingService {
    constructor(private http: BaseService) { }

    getSectorJobRoles(): Observable<any> {
        return this.http
            .HttpGet(this.http.Services.SchoolVTMapping.GetAll)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetAllByCriteria(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.SchoolVTMapping.GetAllByCriteria,filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    getSchoolVTMappingById(schoolVTPVCId: string) {
        let requestParams = {
            DataId: schoolVTPVCId
        };

        return this.http
            .HttpPost(this.http.Services.SchoolVTMapping.GetById, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    createOrUpdateSchoolVTMapping(formData: any) {
        return this.http
            .HttpPost(this.http.Services.SchoolVTMapping.CreateOrUpdate, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    deleteSchoolVTMappingById(schoolVTPVCId: string) {
        var sectorJobRoleParams = {
            DataId: schoolVTPVCId
        };

        return this.http
            .HttpPost(this.http.Services.SchoolVTMapping.Delete, sectorJobRoleParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }
    getAllRoles(): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.RoleTransaction.GetAllRoles, {})
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    getDropdownforSectorJobRole(userModel: UserModel): Observable<any[]> {
        let academicYearRequest = this.http.GetMasterDataByType({ DataType: 'AcademicYearsByState', UserId:userModel.UserTypeId, SelectTitle: 'Academic Year' });
        let jobRoleRequest = this.http.GetMasterDataByType({ DataType: 'SchoolSectorJobRole', SelectTitle: 'School' });
        let vtRequest = this.http.GetMasterDataByType({DataType: "VocationalTrainers",UserId: null,ParentId: null });
        let vtpRequest = this.http.GetMasterDataByType({DataType: 'VTPForSchoolSectorJobRoles', ParentId: '', SelectTitle: 'VTP' });
        let vcRequest = this.http.GetMasterDataByType({DataType: 'VCForSchoolSectorJobRolesVTP', ParentId: '', SelectTitle: 'VC' });
        let statecodeRequest = this.http.GetMasterDataByType({ DataType: 'StatesByUserId', UserId:userModel.UserTypeId, SelectTitle: 'StateCode' });
        


        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        return forkJoin([academicYearRequest, jobRoleRequest,vtRequest,vtpRequest,vcRequest,statecodeRequest]);
    }
}
