<div id="resource-videos" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <!-- {{ 'videos.HEAD_MASTERS' | translate}} -->
                    Resource Study Material 
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            

            <div class="search-wrapper mx-32 mx-md-0">
                <form [formGroup]="resourceStudyMaterialForm">
                    <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon>search</mat-icon>
                        <input  name="filterText" formControlName="filterText" placeholder="Search for resource videos">
                    </div>
                </form>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">
                <!-- <button mat-raised-button class="fuse-white filter-button"
                    (click)="IsShowFilter=!IsShowFilter"><span>FILTERS</span>
                </button> -->

                <button mat-raised-button [routerLink]="['./','new']" skipLocationChange="true" replaceUrl="false"
                    class="add-resource-videos-button fuse-white mt-24 mt-md-0">
                    <span>ADD</span>
                </button>

                <!-- <a *ngIf="PageRights.IsExport" class="export-excel"
                    (click)="exporter.exportTable('xlsx', {fileName:'videos', sheet: 'videos', Props: {Author: 'Lighthouse'}})">
                    <mat-icon> cloud_download</mat-icon>
                </a> -->
                <a *ngIf="PageRights.IsExport" class="export-excel" (click)="exportExcel()">
                    <mat-icon> cloud_download</mat-icon>
                </a>
            </div>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- Filter Section -->
        <!-- <div class="filter-content-card" *ngIf="IsShowFilter">
            <div class="filter-content">
                <form name="resourceStudyMaterialFilterForm" [formGroup]="resourceStudyMaterialFilterForm" class="vc-issue-report"
                    fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field fxFlex="30" fxFlex.lt-md="10" fxFlex.lt-sm="100">
                            <mat-label>Status</mat-label>
                            <mat-select name="status" formControlName="Status" appearance="fill">
                                <mat-option value="">Status</mat-option>
                                <mat-option value="true">Active</mat-option>
                                <mat-option value="false">Inactive</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="clear-filter pr-1" fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="onLoadvideosByFilters()"><span>SEARCH</span></button>
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="resetFilters()"><span>CLEAR</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div> -->
        <!-- /Filter Section -->


        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table  class="resource-videos-table responsive-table" #table
                [dataSource]="tableDataSource" matSort
                fusePerfectScrollbar [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">
                <!-- ResourceTitle Column -->
                <ng-container matColumnDef="ResourceTitle">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Title
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studyMaterial">
                        <p class="text-truncate">{{studyMaterial.ResourceTitle}}</p>
                    </mat-cell>
                </ng-container>

                <!-- ResourceType Column -->
                <ng-container matColumnDef="ResourceType">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Resource Type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studyMaterial">
                        <p class="text-truncate">{{studyMaterial.ResourceType}}</p>
                    </mat-cell>
                </ng-container>


                <!-- Category Column -->
                <ng-container matColumnDef="Category">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Category</mat-header-cell>
                    <mat-cell *matCellDef="let studyMaterial" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studyMaterial.Category}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SubCategory Column -->
                <ng-container matColumnDef="SubCategory">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Sub
                        Category</mat-header-cell>
                    <mat-cell *matCellDef="let studyMaterial" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studyMaterial.EmailID}}</p>
                    </mat-cell>
                </ng-container>

                <!-- ResourceDescription Column -->
                <ng-container matColumnDef="ResourceDescription">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Description
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studyMaterial">
                        <p class="text-truncate">{{studyMaterial.ResourceDescription}}</p>
                    </mat-cell>
                </ng-container>

                <!-- ResourceLink Column -->
                <ng-container matColumnDef="ResourceLink">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Resource
                        Link</mat-header-cell>
                    <mat-cell *matCellDef="let studyMaterial" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studyMaterial.ResourceLink}}</p>
                    </mat-cell>
                </ng-container>

                <!-- PublishDate Column -->
                <ng-container matColumnDef="PublishDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Publish
                        Date</mat-header-cell>
                    <mat-cell *matCellDef="let studyMaterial" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studyMaterial.PublishDate | slice :0:10}}</p>
                    </mat-cell>
                </ng-container>

                <!-- IsActive Column -->
                <ng-container matColumnDef="IsActive">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Is Active?</mat-header-cell>
                    <mat-cell *matCellDef="let studyMaterial" fxHide fxShow.gt-xs>
                        <mat-icon *ngIf="studyMaterial.IsActive" class="active-icon green-600 s-16">check</mat-icon>
                        <mat-icon *ngIf="!studyMaterial.IsActive" class="active-icon red-500 s-16">close</mat-icon>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Actions" stickyEnd>
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let studyMaterial" fxShow.gt-xs>
                        <button *ngIf="PageRights.IsView" mat-icon-button
                            [routerLink]="['/resource-study-material', 'view', studyMaterial.ResourceId]" skipLocationChange="true"
                            replaceUrl="false">
                            <mat-icon color="primary">visibility</mat-icon>
                        </button>
                        <!-- <button *ngIf="PageRights.IsEdit && studyMaterial.CreatedBy == UserModel.UserId" mat-icon-button
                            [routerLink]="['/resource-study-material', 'edit', studyMaterial.ResourceId]" skipLocationChange="true"
                            replaceUrl="false"> -->
                            <button *ngIf="PageRights.IsEdit" mat-icon-button
                            [routerLink]="['/resource-study-material', 'edit', studyMaterial.ResourceId]" skipLocationChange="true"
                            replaceUrl="false">
                            <mat-icon color="primary">edit</mat-icon>
                        </button>
                        <button mat-icon-button color="warn" (click)="onDeleteResourceStudyMaterial(studyMaterial.ResourceId)"
                            *ngIf="PageRights.IsDelete && studyMaterial.CreatedBy == UserModel.UserId">
                            <mat-icon>delete_outline</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let videos; columns: displayedColumns;" class="resource-videos" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>