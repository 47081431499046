import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';
import { UserModel } from "app/models/user.model";
import { HttpParams } from "@angular/common/http";

@Injectable()
export class EmployerStudentMappingService {
    constructor(private http: BaseService) { }

    getEmployerStudentMappings(): Observable<any> {
        return this.http
            .HttpGet(this.http.Services.EmployerStudentMapping.GetAll)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetAllEmployerStudentMapp(statecode: any): Observable<any> {
        // let testUrl = "https://dev-user.pathways.net.in/LighthouseServices/Lighthouse/GetCoreMasterDataForMobile";
        const url = 'http://dev.pathways.net.in/LighthouseServices/DirectMappingConfig/GetDirectMappingConfigDetails';
        let queryParams = new HttpParams();
        queryParams = queryParams.append('StateCode', 'OD');
        return this.http.get(url, { params: queryParams });

        // return this.http
        //     .get(url, { params: queryParams })
        //     .pipe(
        //         retry(this.http.Services.RetryServieNo),
        //         catchError(this.http.HandleError),
        //         tap(response => {
        //             return response.Results;
        //         })
        //     );
    }

    GetDirectMapping(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.EmployerStudentMapping.GetDirectMapping, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetAllByCriteria(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.EmployerStudentMapping.GetAll, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    getEmployerStudentMappingById(configId: string) {
        let requestParams = {
            DataId: configId
        };

        return this.http
            .HttpPost(this.http.Services.EmployerStudentMapping.GetById, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    createOrUpdateEmployerStudentMapping(formData: any) {
        return this.http
            .HttpPost(this.http.Services.EmployerStudentMapping.CreateOrUpdate, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    deleteEmployerStudentMappingById(configId: string) {
        var requestParams = {
            DataId: configId

        };
        return this.http
            .HttpPost(this.http.Services.EmployerStudentMapping.Delete, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    getDropdownforEmployerStudentMappings(userModel: UserModel): Observable<any[]> {
        let academicYearRequest = this.http.GetMasterDataByType({ DataType: 'AcademicYearsByState', UserId: userModel.UserTypeId, SelectTitle: 'Academic Year' });
        let statecodeRequest = this.http.GetMasterDataByType({ DataType: 'StatesByUserId', UserId: userModel.UserTypeId, SelectTitle: 'StateCode' });
        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        return forkJoin([academicYearRequest, statecodeRequest]);
    }
}
