import { Component, OnInit, NgZone, ViewEncapsulation, ViewChild } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StudentReportModel, StudentReportViewModel } from './student-report.model';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { ReportService } from '../report.service';
import { DropdownModel } from 'app/models/dropdown.model';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { environment } from 'environments/environment';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'data-list-view',
  templateUrl: './student-report.component.html',
  styleUrls: ['./student-report.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class StudentReportComponent extends BaseListComponent<StudentReportModel> implements OnInit {
  studentReportForm: FormGroup;
  StudentReportViewModel = new StudentReportViewModel();
  academicyearList: [DropdownModel];
  divisionList: [DropdownModel];
  districtList: DropdownModel[];
  sectorList: [DropdownModel];
  jobRoleList: DropdownModel[];
  vtpList: [DropdownModel];
  classList: [DropdownModel];
  stateList: [DropdownModel];
  schoolList: [DropdownModel];
  studentList: [DropdownModel];
  schtList: [DropdownModel];
  sCode: any;

  currentAcademicYearId: string;
  isShowFilterContainer = false;
  @ViewChild('districtMatSelect') districtSelections: MatSelect;
  @ViewChild('sectorMatSelect') sectorSelections: MatSelect;
  @ViewChild('vtpMatSelect') vtpSelections: MatSelect;
  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    public zone: NgZone,
    public formBuilder: FormBuilder,
    private reportService: ReportService) {
    super(commonService, router, routeParams, snackBar, zone);
  }


  ngOnInit(): void {
    this.PageRights.IsExport = true;
    this.reportService.GetDropdownForReports(this.UserModel).subscribe(results => {
      if (results[0].Success) {
        this.academicyearList = results[0].Results;
      }

      if (results[1].Success) {
        this.divisionList = results[1].Results;
      }

      if (results[2].Success) {
        this.sectorList = results[2].Results.filter(x => x.Id != null);
      }

      if (results[3].Success) {
        this.vtpList = results[3].Results.filter(x => x.Id != null);
      }

      if (results[4].Success) {
        this.classList = results[4].Results;
      }

      if (results[6].Success) {
        this.schoolList = results[6].Results;
      }


      if (results[7].Success) {
        this.stateList = results[7].Results;
      }

      if (results[8].Success) {
        this.studentList = results[8].Results;
      }
      if (results[10].Success) {
        this.schtList = results[10].Results;
      }
      if (results[11].Success) {
        this.sCode = results[11].Results;
      }
      this.getStudentReports();



      // let currentYearItem = this.academicyearList.find(ay => ay.IsSelected == true)
      // if (currentYearItem != null) {
      //   this.currentAcademicYearId = currentYearItem.Id;
      //   this.studentReportForm.get('AcademicYearId').setValue(this.currentAcademicYearId);

      //   if (this.UserModel.RoleCode === 'DivEO') {
      //     this.studentReportForm.controls["DivisionId"].setValue(this.UserModel.DivisionId);
      //     this.studentReportForm.controls["DivisionId"].disable();

      //     this.onChangeDivision(this.UserModel.DivisionId).then(response => {
      //       this.getStudentReports();
      //     });
      //   }
      //   else if (this.UserModel.RoleCode === 'DisEO' || this.UserModel.RoleCode === 'DisRP') {
      //     this.studentReportForm.controls["DivisionId"].setValue(this.UserModel.DivisionId);
      //     this.studentReportForm.controls["DivisionId"].disable();

      //     this.studentReportForm.controls["DistrictId"].setValue(this.UserModel.DistrictId);
      //     this.studentReportForm.controls["DistrictId"].disable();

      //     this.onChangeDivision(this.UserModel.DivisionId).then(response => {
      //       this.getStudentReports();
      //     });
      //   }
      //   else if (this.UserModel.RoleCode === 'BEO' || this.UserModel.RoleCode === 'BRP') {
      //     this.studentReportForm.controls["DivisionId"].setValue(this.UserModel.DivisionId);
      //     this.studentReportForm.controls["DivisionId"].disable();

      //     this.studentReportForm.controls["DistrictId"].setValue(this.UserModel.DistrictId);
      //     this.studentReportForm.controls["DistrictId"].disable();

      //     this.onChangeDivision(this.UserModel.DivisionId).then(response => {
      //       this.getStudentReports();
      //     });
      //   }
      //   else {
      //     this.getStudentReports();
      //   }
      // }
    });

    this.studentReportForm = this.createStudentReportForm();
  }
  onChangeState(Statecode: any) {
    this.commonService.GetMasterDataByType({ DataType: 'Divisions', ParentId: Statecode, SelectTitle: 'Division' }).subscribe((response: any) => {
      this.divisionList = response.Results;
    });
  }

  onChangeDivision(divisionId: any) {
    debugger;
    this.commonService.GetMasterDataByType({ DataType: 'Districts', ParentId: divisionId, SelectTitle: 'District' }).subscribe((response: any) => {
      debugger;
      this.districtList = response.Results.filter(x => x.Id != null);
    });
  }

  // onChangeDivision(divisionId: string): Promise<any> {
  //   debugger;
  //   let promise = new Promise((resolve, reject) => {

  //     this.commonService.GetMasterDataByType({ DataType: 'Districts',  ParentId: divisionId, SelectTitle: 'District' }, false)
  //       .subscribe((response: any) => {
  //         debugger;
  //         this.districtList = response.Results;
  //         this.studentReportForm.controls['DistrictId'].setValue(null);
  //         resolve(response.Results);
  //       }, err => {

  //         reject(err);
  //       });
  //   });

  //   return promise;
  // }

  resetReportFilters(): void {
    this.studentReportForm.reset();
    this.studentReportForm.get('AcademicYearId').setValue(this.currentAcademicYearId);
    this.districtList = <DropdownModel[]>[];
    this.jobRoleList = <DropdownModel[]>[];

    if (this.UserModel.RoleCode === 'DisRP') {
      this.studentReportForm.controls["DivisionId"].setValue(this.UserModel.DivisionId);
      this.studentReportForm.controls["DistrictId"].setValue(this.UserModel.DistrictId);
    }

    this.getStudentReports();
  }

  // onChangeDivision(divisionId: string): Promise<any> {
  //   let promise = new Promise((resolve, reject) => {

  //     this.commonService.GetMasterDataByType({ DataType: 'Districts', UserId: this.Constants.DefaultStateId, ParentId: divisionId, SelectTitle: 'District' })
  //       .subscribe((response: any) => {

  //         this.districtList = response.Results;
  //         resolve(response.Results);
  //       }, err => {

  //         reject(err);
  //       });
  //   });

  //   return promise;
  // }

  onChangeSector(sectorId: string): void {
    this.commonService.GetMasterDataByType({ DataType: 'JobRoles', ParentId: sectorId, SelectTitle: 'Job Role' }).subscribe((response: any) => {
      this.jobRoleList = response.Results;
    });
  }


  toggleDistrictSelections(evt) {
    //To control select-unselect all
    let isAllSelected = (evt.currentTarget.classList.toString().indexOf('mat-active') > 0)

    if (isAllSelected) {
      this.districtSelections.options.forEach((item: MatOption) => item.select());
      this.districtSelections.options.first.deselect();
    } else {
      this.districtSelections.options.forEach((item: MatOption) => { item.deselect() });
    }
    setTimeout(() => { this.districtSelections.close(); }, 200);
  }

  toggleSectorSelections(evt) {
    //To control select-unselect all
    let isAllSelected = (evt.currentTarget.classList.toString().indexOf('mat-active') > 0)

    if (isAllSelected) {
      this.sectorSelections.options.forEach((item: MatOption) => item.select());
      this.sectorSelections.options.first.deselect();
    } else {
      this.sectorSelections.options.forEach((item: MatOption) => { item.deselect() });
    }
    setTimeout(() => { this.sectorSelections.close(); }, 200);
  }

  toggleVTPSelections(evt) {
    //To control select-unselect all
    let isAllSelected = (evt.currentTarget.classList.toString().indexOf('mat-active') > 0)

    if (isAllSelected) {
      this.vtpSelections.options.forEach((item: MatOption) => item.select());
      this.vtpSelections.options.first.deselect();
    } else {
      this.vtpSelections.options.forEach((item: MatOption) => { item.deselect() });
    }
    setTimeout(() => { this.vtpSelections.close(); }, 200);
  }

  //Create VTSchoolSector form and returns {FormGroup}
  createStudentReportForm(): FormGroup {
    return this.formBuilder.group({
      AcademicYearId: new FormControl(),
      StudentGuid: new FormControl(),
      // StateCode: new FormControl(),
      DivisionCode: new FormControl(),
      DistrictCode: new FormControl(),
      SchoolGuid: new FormControl(),
      ClassGuid: new FormControl(),
      SectorGuid: new FormControl(),
      VTPGuid: new FormControl(),
    });
  }
  onPageIndexChanged(evt) {

    this.SearchBy.PageIndex = evt.pageIndex;
    this.SearchBy.PageSize = evt.pageSize;

    this.getStudentReports();
  }
  getStudentReports(): void {
    this.SearchBy.AcademicYearGuid = this.studentReportForm.get('AcademicYearId').value;
    this.SearchBy.StudentGuid = this.studentReportForm.get('StudentGuid').value;
    // this.SearchBy.StateCode = this.studentReportForm.get('StateCode').value;
    this.SearchBy.DistrictCode = this.studentReportForm.get('DistrictCode').value;
    this.SearchBy.SchoolGuid = this.studentReportForm.get('SchoolGuid').value;
    this.SearchBy.ClassGuid = this.studentReportForm.get('ClassGuid').value;
    this.SearchBy.Sectors = this.studentReportForm.get('SectorGuid').value;
    this.SearchBy.VTP = this.studentReportForm.get('VTPGuid').value;
    this.SearchBy.VTGuid = this.UserModel.RoleCode == 'VT' ? this.UserModel.UserTypeId : null;
    this.SearchBy.UserId = this.UserModel.LoginId;
    this.SearchBy.UserTypeId = null;
    this.SearchBy.StateCode = this.sCode[1].Id;
    this.SearchBy.SectorGuid = null;
    this.SearchBy.VTPGuid = null;
    // var reportParams = {
    //   UserId: this.UserModel.LoginId,
    //   UserTypeId: null,
    //   AcademicYearGuid: this.studentReportForm.get('AcademicYearId').value,
    //   StudentGuid: this.studentReportForm.get('StudentGuid').value,
    //   StateCode: this.studentReportForm.get('StateCode').value,
    //   DistrictCode: this.studentReportForm.get('DistrictCode').value,
    //   SchoolGuid: this.studentReportForm.get('SchoolGuid').value,
    //   ClassGuid: this.studentReportForm.get('ClassGuid').value,
    //   SectorGuid: this.studentReportForm.get('SectorGuid').value,
    //   VTPGuid: this.studentReportForm.get('VTPGuid').value,
    //   pageIndex: 1,
    //   pageSize: 10
    this.SearchBy.DistrictCode = (this.SearchBy.DistrictCode != null && this.SearchBy.DistrictCode.length > 0) ? this.SearchBy.DistrictCode.toString() : null;
    this.SearchBy.Sectors = (this.SearchBy.Sectors != null && this.SearchBy.Sectors.length > 0) ? this.SearchBy.Sectors.toString() : null;
    this.SearchBy.VTP = (this.SearchBy.VTP != null && this.SearchBy.VTP.length > 0) ? this.SearchBy.VTP.toString() : null;
    // };



    this.SearchBy.DistrictCode = this.SearchBy.DistrictCode != null && this.SearchBy.DistrictCode[0] == ',' ? this.SearchBy.DistrictCode.slice(1) : this.SearchBy.DistrictCode;
    this.SearchBy.Sectors = this.SearchBy.Sectors != null && this.SearchBy.Sectors[0] == ',' ? this.SearchBy.Sectors.slice(1) : this.SearchBy.Sectors;
    this.SearchBy.VTP = this.SearchBy.VTP != null && this.SearchBy.VTP[0] == ',' ? this.SearchBy.VTP.slice(1) : this.SearchBy.VTP;

    this.reportService.GetStudentReportsByCriteria(this.SearchBy).subscribe(response => {
      this.displayedColumns = ['SrNo', 'AcademicYear', 'FullName', 'Gender', 'DOB', 'PhoneNO', 'WhatsApp', 'EmailId', 'State', 'District', 'Block', 'Pincode',
        'SchoolName', 'UDISE', 'Class', 'Section', 'Stream', 'JobRole', 'Sector', 'VillageSchool', 'VTPName', 'VCName', 'VTName', 'EmployerName', 'EstablishmentName', 'Title', 'IntershipType', 'DateComm',
        'DateComplete', 'InternshipStatus', 'TotalDaysStd', 'HrsPerDayStd', 'TotalInternshipHrsStd', 'IsInternshipCompleted', 'DailyDiaryAvailable', 'IsSafetyConcernRaised', 'SafetyConcernDetails', 'Attachment1', 'Attachment2', 'Attachment3', 'Attachment4', 'Attachment5'];

      let dataArray = [];

      let studentList: any = response.Results;//.sort((a, b) => a.RowIndex > b.RowIndex ? 1 : -1);
      for (let i = 0; i < studentList.length; i++) {
        this.StudentReportViewModel = new StudentReportViewModel();
        this.StudentReportViewModel.RowIndex = studentList[i].RowIndex;
        this.StudentReportViewModel.StudentId = studentList[i].StudentId;
        this.StudentReportViewModel.YearName = studentList[i].YearName;
        this.StudentReportViewModel.AcademicYearId = studentList[i].AcademicYearId;
        this.StudentReportViewModel.FirstName = studentList[i].FirstName;
        this.StudentReportViewModel.MiddleName = studentList[i].MiddleName;
        this.StudentReportViewModel.LastName = studentList[i].LastName;
        this.StudentReportViewModel.FullName = studentList[i].FullName;
        this.StudentReportViewModel.Gender = studentList[i].Gender;
        this.StudentReportViewModel.DateOfBirth = studentList[i].DateOfBirth;
        this.StudentReportViewModel.Mobile = studentList[i].Mobile;
        this.StudentReportViewModel.Whatsapp = studentList[i].Whatsapp;
        this.StudentReportViewModel.EmailId = studentList[i].EmailId;
        this.StudentReportViewModel.MotherName = studentList[i].MotherName;
        this.StudentReportViewModel.FatherName = studentList[i].FatherName;
        this.StudentReportViewModel.StateCode = studentList[i].StateCode;
        this.StudentReportViewModel.StateName = studentList[i].StateName;
        this.StudentReportViewModel.DistrictCode = studentList[i].DistrictCode;
        this.StudentReportViewModel.DistrictName = studentList[i].DistrictName;
        this.StudentReportViewModel.BlockId = studentList[i].BlockId;
        this.StudentReportViewModel.BlockName = studentList[i].BlockName;
        this.StudentReportViewModel.Pincode = studentList[i].Pincode;
        this.StudentReportViewModel.FullAddress = studentList[i].FullAddress;
        this.StudentReportViewModel.SchoolVillage = studentList[i].SchoolVillage;
        this.StudentReportViewModel.SchoolId = studentList[i].SchoolId;
        this.StudentReportViewModel.SchoolName = studentList[i].SchoolName;
        this.StudentReportViewModel.UDISE = studentList[i].UDISE;
        this.StudentReportViewModel.Stream = studentList[i].Stream;
        this.StudentReportViewModel.ClassId = studentList[i].ClassId;
        this.StudentReportViewModel.ClassName = studentList[i].ClassName;
        this.StudentReportViewModel.SectionName = studentList[i].SectionName;
        this.StudentReportViewModel.JobRoleName = studentList[i].JobRoleName;
        this.StudentReportViewModel.SectorName = studentList[i].SectorName;
        this.StudentReportViewModel.VTPId = studentList[i].VTPId;
        this.StudentReportViewModel.VTPName = studentList[i].VTPName;
        this.StudentReportViewModel.VCFullName = studentList[i].VCFullName;
        this.StudentReportViewModel.VTFullName = studentList[i].VTFullName;
        this.StudentReportViewModel.EmployerName = studentList[i].EmployerName;
        this.StudentReportViewModel.EstablishmentName = studentList[i].EstablishmentName;
        this.StudentReportViewModel.Title = studentList[i].Title;
        this.StudentReportViewModel.InternshipType = studentList[i].InternshipType;
        this.StudentReportViewModel.DateCommenced = studentList[i].DateCommenced;
        this.StudentReportViewModel.DateCompleted = studentList[i].DateCompleted;
        this.StudentReportViewModel.InternshipStatus = studentList[i].InternshipStatus;
        this.StudentReportViewModel.TotalDaysStd = studentList[i].TotalDaysStd;
        //var HrsPerDay = Number(studentList[i].HrsPerDayStd.toString().split('.')[0]);
        var HrsPerDay = Number(studentList[i].HrsPerDayStd.toString().split('.')[0] == undefined ? '0' : studentList[i].HrsPerDayStd.toString().split('.')[0])
        var MMPerDay = studentList[i].HrsPerDayStd.toString().split('.')[1] == undefined ? '00' : studentList[i].HrsPerDayStd.toString().split('.')[1] * 6;
        this.StudentReportViewModel.HrsPerDayStd = HrsPerDay + ':' + MMPerDay;

        // this.StudentReportViewModel.HrsPerDayStd = studentList[i].HrsPerDayStd;

        var TotalInternshipHrs = Number(studentList[i].TotalInternshipHrsStd.toString().split('.')[0] == undefined ? '0' : studentList[i].TotalInternshipHrsStd.toString().split('.')[0]);
        var TotalInternshipMM = studentList[i].TotalInternshipHrsStd.toString().split('.')[1] == undefined ? '00' : TotalInternshipMM = studentList[i].TotalInternshipHrsStd.toString().split('.')[1] * 6;;
        // if (studentList[i].TotalInternshipHrsStd.toString().split('.')[1] == undefined) {
        //   TotalInternshipMM = '00';
        // } else { TotalInternshipMM = studentList[i].TotalInternshipHrsStd.toString().split('.')[1] * 6; }

        this.StudentReportViewModel.TotalInternshipHrsStd = TotalInternshipHrs + ':' + TotalInternshipMM;
        // this.StudentReportViewModel.TotalInternshipHrsStd = studentList[i].TotalInternshipHrsStd;
        this.StudentReportViewModel.IsInternshipCompleted = studentList[i].IsInternshipCompleted;
        this.StudentReportViewModel.DailyDiaryAvailable = studentList[i].DailyDiaryAvailable;
        this.StudentReportViewModel.IsSafetyConcernRaised = studentList[i].IsSafetyConcernRaised;
        this.StudentReportViewModel.SafetyConcernDetails = studentList[i].SafetyConcernDetails;

        this.StudentReportViewModel.Attachments = studentList[i].Attachments;

        // this.StudentReportViewModel.Attachment1 = studentList[i].Attachment1 == null ? '' : this.Constants.CommonImageUrl.Attachments + studentList[i].Attachment1;
        // this.StudentReportViewModel.Attachment2 = studentList[i].Attachment2 == null ? '' : this.Constants.CommonImageUrl.Attachments + studentList[i].Attachment2;
        // this.StudentReportViewModel.Attachment3 = studentList[i].Attachment3 == null ? '' : this.Constants.CommonImageUrl.Attachments + studentList[i].Attachment3;
        // // this.StudentReportViewModel.Attachment4 = studentList[i].Attachment4==null?'':"https://user-uat.pathways.net.in//Documents/Attachments/" + studentList[i].Attachment4;
        // this.StudentReportViewModel.Attachment4 = studentList[i].Attachment4 == null ? '' : this.Constants.CommonImageUrl.Attachments + studentList[i].Attachment4;
        // this.StudentReportViewModel.Attachment5 = studentList[i].Attachment5 == null ? '' : this.Constants.CommonImageUrl.Attachments + studentList[i].Attachment5;
        this.StudentReportViewModel.Attachment1 = studentList[i].Attachment1 == null ? '' : (studentList[i].Attachment1.includes('https') ? studentList[i].Attachment1 : studentList[i].Attachment1 == '' ? '' : this.Constants.CommonImageUrl.Attachments + studentList[i].Attachment1);
        this.StudentReportViewModel.Attachment2 = studentList[i].Attachment2 == null ? '' : (studentList[i].Attachment2.includes('https') ? studentList[i].Attachment2 : studentList[i].Attachment2 == '' ? '' : this.Constants.CommonImageUrl.Attachments + studentList[i].Attachment2);
        this.StudentReportViewModel.Attachment3 = studentList[i].Attachment3 == null ? '' : (studentList[i].Attachment3.includes('https') ? studentList[i].Attachment3 : studentList[i].Attachment3 == '' ? '' : this.Constants.CommonImageUrl.Attachments + studentList[i].Attachment3);
        this.StudentReportViewModel.Attachment4 = studentList[i].Attachment4 == null ? '' : (studentList[i].Attachment4.includes('https') ? studentList[i].Attachment4 : studentList[i].Attachment4 == '' ? '' : this.Constants.CommonImageUrl.Attachments + studentList[i].Attachment4);
        this.StudentReportViewModel.Attachment5 = studentList[i].Attachment5 == null ? '' : (studentList[i].Attachment5.includes('https') ? studentList[i].Attachment5 : studentList[i].Attachment5 == '' ? '' : this.Constants.CommonImageUrl.Attachments + studentList[i].Attachment5);
        dataArray.push(this.StudentReportViewModel);
      }

      this.tableDataSource.data = dataArray;
      // this.tableDataSource.data = response.Results;

      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;
      this.SearchBy.TotalResults = response.TotalResults;

      setTimeout(() => {
        this.ListPaginator.pageIndex = this.SearchBy.PageIndex;
        this.ListPaginator.length = this.SearchBy.TotalResults;
      });

      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
    });
  }

  exportExcel(): void {
    this.IsLoading = true;
    this.SearchBy.PageIndex = 1;
    this.SearchBy.PageSize = 10000;

    this.reportService.GetStudentReportsByCriteria(this.SearchBy).subscribe(response => {
      response.Results.forEach(
        function (obj) {
          if (obj.hasOwnProperty('IsActive')) {
            obj.IsActive = obj.IsActive ? 'Yes' : 'No';
          }
          if (obj.hasOwnProperty('IsImplemented')) {
            obj.IsImplemented = obj.IsImplemented ? 'Yes' : 'No';
          }
          delete obj.SchoolId;
          delete obj.TotalRows;
        });

      this.exportExcelFromTable(response.Results, "Interns Report");

      this.IsLoading = false;
      this.SearchBy.PageIndex = 0;
      this.SearchBy.PageSize = 10;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

}
