import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'app/common/base/base.component';
import { InternshipOpportunity } from 'app/main/internship-opportunity/internship-opportunity.model';
import { CommonService } from 'app/services/common.service';
import { InternshipApprovalService } from '../internship-approval.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-student-internship-date-change',
  templateUrl: './student-internship-date-change.component.html',
  styleUrls: ['./student-internship-date-change.component.scss']
})
export class StudentInternshipDateChangeComponent extends BaseComponent<InternshipOpportunity> implements OnInit {
  passDatatoChild: any[];
  isLoading: boolean = true;
  errorMessage: any;
  // studentInternshipDataById = [] as any;
  // studentId: any;
  InternshipStartDate: any;
  complitionData: any;
  public dateForm: FormGroup;

  startDate: any;
  constructor(
    public datepipe: DatePipe,public formBuilder: FormBuilder,
    public service: InternshipApprovalService,
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<StudentInternshipDateChangeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(commonService, router, routeParams, snackBar);
    // dialogRef.disableClose = true;
  }
  ngOnInit() {
    this.dateForm = this.createdateForm();

    console.log(this.data);
    this.InternshipStartDate = this.data.InternshipStartDate;
    // this.internshipId = this.data.InternshipId;
    // this.studentInternshipDataById = this.data[0];
    this.startDate = this.datepipe.transform(this.InternshipStartDate, 'yyyy-MM-dd');
    this.dateForm.controls['ActualStartDate'].patchValue(this.startDate);
  }

  onClose() {
    this.dialog.closeAll();
  }

  onSubmit(dateForm) {

    this.dialogRef.close({ ActualStartDate: dateForm.value.ActualStartDate });
 
  }

  // actualStartDateForm: FormGroup = new FormGroup({
  //   ActualStartDate: new FormControl("", Validators.required)
  //   // Reason: new FormControl(""),
  // });
  createdateForm(): FormGroup {
    return this.formBuilder.group({
      ActualStartDate: new FormControl( '', Validators.required ),
    });
  }
}
