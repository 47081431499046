<div id="vt-dashboard" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    Dashboard
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <!-- <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter (keyup)="applySearchFilter($event.target.value)"
                        placeholder="Search for a dashboard">
                </div>
            </div> -->
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <!-- <button mat-raised-button [routerLink]="['./','new']" skipLocationChange="true" replaceUrl="false"
                class="add-sector-job-role-button fuse-white mt-24 mt-md-0">
                <span>ADD</span>
            </button> -->
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- Filter Section -->
        <div class="filter-content-card">
            <div class="filter-content">
                <form name="vtDashboardFilterForm" [formGroup]="vtDashboardFilterForm" class="vc-issue-report"
                    fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field fxFlex="15" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Internship Type </mat-label>

                            <mat-select formControlName="InternshipType" name="internshipType" appearance="outline">
                                <mat-option [value]="internshipTypeItem.Id"
                                    *ngFor="let internshipTypeItem of internshipTypeList">
                                    {{ internshipTypeItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="15" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Work Type </mat-label>

                            <mat-select formControlName="WorkType" name="workType" appearance="outline">
                                <mat-option [value]="WorkTypeT.Id" *ngFor="let WorkTypeT of workTypeList">
                                    {{ WorkTypeT.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field fxFlex="15" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Gender </mat-label>

                            <mat-select formControlName="Gender" name="gender" appearance="outline">
                                <mat-option [value]="genderItem.Id" *ngFor="let genderItem of genderList">
                                    {{ genderItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="15" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Grade</mat-label>

                            <mat-select formControlName="Grade" name="grade" appearance="outline">
                                <mat-option [value]="class.Id" *ngFor="let class of classList">
                                    {{ class.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="clear-filter pr-1" fxFlex="15" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="GetVTDashboardPending();GetVTDashboardDailyDiaryEntries();GetVTDashboardInternshipConsolidated();GetVTDashboardStudentsPieChart();GetVTDashboardInternshipBarChart('Active')"><span>SEARCH</span></button>
                            <button mat-raised-button class="fuse-white filter-button" (click)="resetFilters()"><span>CLEAR</span></button>
                        </div>

                        <div class="clear-filter pr-1" fxFlex="25" fxFlex.lt-md="50" fxFlex.lt-sm="100">

                            <mat-form-field fxFlex="50" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>From</mat-label>
                                <input [max]="maxDate" matInput formControlName="StartDateTime"
                                    [matDatepicker]="picker1" name="StartDateTime"
                                    (dateInput)="addEventS('input', $event)" (dateChange)="addEventS('change', $event)">
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>

                            </mat-form-field>
                            <mat-form-field fxFlex="50" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                <mat-label>To</mat-label>
                                <input matInput formControlName="EndDateTime" [matDatepicker]="picker2"
                                    name="EndDateTime" (dateInput)="addEventE('input', $event)"
                                    (dateChange)="addEventE('change', $event)" [max]="maxDate">
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>

                            </mat-form-field>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- /Filter Section -->

        <!-- CONTENT CARD -->
        <div class="content-card" style="overflow: auto;;">
            <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" *ngIf="VTDashboardPending">
                <div fxFlex="30%" fxFlex.lt-md="31%" fxFlex.lt-sm="100%"
                    style="border-radius: 18px;border: 1px solid #dee2e6;padding: 20px;margin: 15px;float: left;">
                    <div style="width:100%;" [routerLink]="['/internship-approval']">
                        <div style="width:70%;float:left;">
                            <div class="caption text-gray-600 d-block mb-1" style="color:#6c757d!important">Pending
                            </div>
                            <div class="h3 mb-0">
                                <h2 style="font-weight: 700;margin: 0!important;">Approvals</h2>
                            </div>
                            <div class="d-block fs-11 mt-2 font-weight-semibold d-flex align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-point"
                                    width="23" height="23" viewBox="0 0 24 24" stroke-width="1.5" stroke="#008FFB"
                                    fill="#008FFB" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <circle cx="12" cy="12" r="4" />
                                </svg><span style="margin-bottom:10px;">{{VTDashboardPending.ApprovalCount}}</span>
                            </div>
                        </div>
                        <div style="width:30%;float:left;">
                            <img src="assets/images/approvals.png" style="width:100%;" alt="" srcset="">
                        </div>
                    </div>

                </div>
                <div fxFlex="30%" fxFlex.lt-md="30%" fxFlex.lt-sm="100%"
                    style="border-radius: 18px;border: 1px solid #dee2e6;padding: 20px;margin: 15px;float: left;">
                    <div style="width:100%;" [routerLink]="['/safety-concern-request']">
                        <div style="width:70%;float:left;">
                            <div class="caption text-gray-600 d-block mb-1" style="color:#6c757d!important">Pending
                            </div>
                            <div class="h3 mb-0">
                                <h2 style="font-weight: 700;margin: 0!important;">Safety Concern</h2>
                            </div>
                            <div class="d-block fs-11 mt-2 font-weight-semibold d-flex align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-point"
                                    width="23" height="23" viewBox="0 0 24 24" stroke-width="1.5" stroke="#D42B05"
                                    fill="#D42B05" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <circle cx="12" cy="12" r="4" />
                                </svg> <span
                                    style="margin-bottom:10px;">{{VTDashboardPending.SafetyRequestCount}}</span>
                            </div>
                        </div>
                        <div style="width:30%;float:left;">
                            <img src="assets/images/safety-concern.png" style="width:100%;" alt="" srcset="">
                        </div>
                    </div>


                </div>
                <div fxFlex="30%" fxFlex.lt-md="30%" fxFlex.lt-sm="100%"
                    style="border-radius: 18px;border: 1px solid #dee2e6;padding: 20px;margin: 15px;float: left;">
                    <div style="width:100%;" [routerLink]="['/profile-change-request']">
                        <div style="width:70%;float:left;">
                            <div class="caption text-gray-600 d-block mb-1" style="color:#6c757d!important">Pending
                            </div>
                            <div class="h3 mb-0">
                                <h2 style="font-weight: 700;margin: 0!important;">Edit Profile Request</h2>
                            </div>
                            <div class="d-block fs-11 mt-2 font-weight-semibold d-flex align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-point"
                                    width="23" height="23" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FBB500"
                                    fill="#FBB500" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <circle cx="12" cy="12" r="4" />
                                </svg><span
                                    style="margin-bottom:10px;">{{VTDashboardPending.ProfileRequestCount}}</span>
                            </div>
                        </div>
                        <div style="width:30%;float:left;">
                            <img src="assets/images/edit-profile-request.png" style="width:100%;" alt="" srcset="">
                        </div>
                    </div>

                </div>
            </div>
            <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start"
                style="height: auto !important;">
                <div fxFlex="0 1 calc(98%)" fxFlex.lt-md="0 1 calc(98%)" fxFlex.lt-sm="100%"
                    style="border-radius: 18px;border: 1px solid #dee2e6;padding: 20px;margin: 15px;float: left;">
                    <div fxFlex="0 1 calc(98%)" fxFlex.lt-md="0 1 calc(98%)" fxFlex.lt-sm="100%">
                        <div
                            style="border-radius: 18px;border: 1px solid #dee2e6;padding: 20px;margin: 5px;float: left;width: 98%;">
                            <div mat-card-avatar class="example-header-image" style="width:80%;float:left;">

                                <div style="width:100%;float:left;">
                                    <p class="fs-16 fw-700 mb-0 line-h-20 text-black">InternShip</p>


                                </div>
                            </div>
                            <div style="width:10%;float:right;">
                                <button mat-icon-button [matMenuTriggerFor]="menu" style="width:50px;float:right;"
                                    aria-label="Example icon-button with a menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>

                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="GetVTDashboardInternshipBarChart('Active')">

                                        <span> Current Year
                                            Internship</span>
                                    </button>
                                    <button mat-menu-item
                                        (click)="GetVTDashboardInternshipBarChart('PrevActiveInternship')">

                                        <span>Prev Year Internship</span>
                                    </button>

                                    <!-- <button mat-menu-item>

                                        <span>Something else here</span>
                                    </button> -->
                                </mat-menu>
                            </div>

                        </div>
                        <div *ngIf="type == 'Active'"
                            style="border-radius: 18px;border: 1px solid #dee2e6;float: left;width: 100% !important;">
                            <ngx-charts-bar-vertical-2d [view]="view" (window:resize)="onResize($event)" [scheme]="colorScheme" [results]="multi"
                                [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend"
                                [legendTitle]="legendTitle" [legendPosition]="'below'" [showXAxisLabel]="showXAxisLabel"
                                [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
                                [animations]="animations" (select)="onSelect($event)">
                            </ngx-charts-bar-vertical-2d>
                        </div>

                        <div *ngIf="type == 'PrevActiveInternship'"
                            style="border-radius: 18px;border: 1px solid #dee2e6;float: left;width: 100% !important;">
                            <ngx-charts-bar-vertical-stacked [view]="view" (window:resize)="onResize($event)" [scheme]="colorScheme1" [results]="multi"
                                [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend"
                                [legendTitle]="legendTitle" [legendPosition]="'below'" [showXAxisLabel]="showXAxisLabel"
                                [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
                                [animations]="animations" (select)="onSelect($event)">
                            </ngx-charts-bar-vertical-stacked>
                        </div>

                    </div>

                </div>

            </div>


            <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start"
                style="height: auto !important;">
                <!-- <div fxFlex="0 1 calc(65%)" fxFlex.lt-md="0 1 calc(65%)" fxFlex.lt-sm="100%"
                    style="border-radius: 18px;border: 1px solid #dee2e6;padding: 20px;margin: 15px;float: left;"> -->
                <div fxFlex="65%" fxFlex.lt-md="66%" fxFlex.lt-sm="100%" *ngIf="VTDashboardInternshipConsolidated">
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start"
                        style="height: auto !important;">
                        <div fxFlex="45%" fxFlex.lt-md="45%" fxFlex.lt-sm="100%"
                            style="border-radius: 18px;border: 1px solid #dee2e6;padding: 20px;margin:0 15px 15px  15px;float: left;">
                            <div style="width:100%;">
                                <div style="width:28%;float:left;">
                                    <img src="assets/images/tottal-employed.png" style="width:100%;" alt="" srcset="">
                                </div>
                                <div style="width:70%;float:left;margin-top:1% !important; margin-left:1% !important;">
                                    <div class="caption text-gray-600 d-block mb-1" style="color:#6c757d!important">
                                        Total Employer onboarded
                                    </div>
                                    <div class="d-block fs-11 mt-2 font-weight-semibold d-flex align-items-center"
                                        style="margin-top:1% !important;">
                                        {{VTDashboardInternshipConsolidated.EmployerOnboardedVar}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div fxFlex="45%" fxFlex.lt-md="45%" fxFlex.lt-sm="100%"
                            style="border-radius: 18px;border: 1px solid #dee2e6;padding: 20px;margin:0 15px 15px  15px;float: left;">
                            <div style="width:100%;">
                                <div style="width:28%;float:left;">
                                    <img src="assets/images/employers-mapped.png" style="width:100%;" alt="" srcset="">
                                </div>
                                <div style="width:70%;float:left;margin-top:1% !important; margin-left:1% !important;">
                                    <div class="caption text-gray-600 d-block mb-1" style="color:#6c757d!important">
                                        Employers Mapped
                                    </div>
                                    <div class="d-block fs-11 mt-2 font-weight-semibold d-flex align-items-center"
                                        style="margin-top:1% !important;">
                                        {{VTDashboardInternshipConsolidated.EmployerMappedVar}}

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div fxFlex="45%" fxFlex.lt-md="45%" fxFlex.lt-sm="100%"
                            style="border-radius: 18px;border: 1px solid #dee2e6;padding: 20px;margin: 15px;float: left;">
                            <div style="width:100%;">
                                <div style="width:28%;float:left;">
                                    <img src="assets/images/ongoing-internship.png" style="width:100%;" alt=""
                                        srcset="">
                                </div>
                                <div style="width:70%;float:left;margin-top:1% !important; margin-left:1% !important;">
                                    <div class="caption text-gray-600 d-block mb-1" style="color:#6c757d!important">
                                        Ongoing Internships Opportunities 
                                    </div>
                                    <div class="d-block fs-11 mt-2 font-weight-semibold d-flex align-items-center"
                                        style="margin-top:1% !important;">
                                        {{VTDashboardInternshipConsolidated.OngoingInternshipVar}}

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div fxFlex="45%" fxFlex.lt-md="45%" fxFlex.lt-sm="100%"
                            style="border-radius: 18px;border: 1px solid #dee2e6;padding: 20px;margin: 15px;float: left;">
                            <div style="width:100%;">
                                <div style="width:28%;float:left;">
                                    <img src="assets/images/completed-internship.png" style="width:100%;" alt=""
                                        srcset="">
                                </div>
                                <div style="width:70%;float:left;margin-top:1% !important; margin-left:1% !important;">
                                    <div class="caption text-gray-600 d-block mb-1" style="color:#6c757d!important">
                                        Completed Internships Opportunities
                                    </div>
                                    <div class="d-block fs-11 mt-2 font-weight-semibold d-flex align-items-center"
                                        style="margin-top:1% !important;">
                                        {{VTDashboardInternshipConsolidated.CompletedInternshipVar}}

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div fxFlex="45%" fxFlex.lt-md="45%" fxFlex.lt-sm="100%"
                            style="border-radius: 18px;border: 1px solid #dee2e6;padding: 20px;margin: 15px;float: left;">
                            <div style="width:100%;">
                                <div style="width:28%;float:left;">
                                    <img src="assets/images/notice-board.png" style="width:100%;" alt="" srcset="">
                                </div>
                                <div style="width:70%;float:left;margin-top:1% !important; margin-left:1% !important;">
                                    <div class="caption text-gray-600 d-block mb-1" style="color:#6c757d!important">
                                        Notice Board
                                    </div>
                                    <div class="d-block fs-11 mt-2 font-weight-semibold d-flex align-items-center"
                                        style="margin-top:1% !important;">
                                        {{VTDashboardInternshipConsolidated.NoticeBoardVar}}

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div fxFlex="45%" fxFlex.lt-md="45%" fxFlex.lt-sm="100%"
                            style="border-radius: 18px;border: 1px solid #dee2e6;padding: 20px;margin: 15px;float: left;">
                            <div style="width:100%;">
                                <div style="width:28%;float:left;">
                                    <img src="assets/images/field-visit-cmp.png" style="width:100%;" alt="" srcset="">
                                </div>
                                <div style="width:70%;float:left;margin-top:1% !important; margin-left:1% !important;">
                                    <div class="caption text-gray-600 d-block mb-1" style="color:#6c757d!important">
                                        Field Visits Completed
                                    </div>
                                    <div class="d-block fs-11 mt-2 font-weight-semibold d-flex align-items-center"
                                        style="margin-top:1% !important;">
                                        {{VTDashboardInternshipConsolidated.FieldVisitCompletedVar}}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div fxFlex="33%" fxFlex.lt-md="33%" fxFlex.lt-sm="100%"
                    style="border-radius: 18px;border: 1px solid #dee2e6;padding: 20px;float: left;">
                    <div fxFlex="0 1 calc(98%)" fxFlex.lt-md="0 1 calc(98%)" fxFlex.lt-sm="100%" style="width:100% !important;">
                        <div
                            style="border-radius: 18px;border: 1px solid #dee2e6;padding: 20px;margin: 5px;float: left;width: 98%;">
                            <div mat-card-avatar class="example-header-image" style="width:80%;float:left;">

                                <div style="width:100%;float:left;">
                                    <p class="fs-16 fw-700 mb-0 line-h-20 text-black">Student</p>


                                </div>
                            </div>
                            <div style="width:10%;float:right;">
                                <button mat-icon-button [matMenuTriggerFor]="menu" style="width:50px;float:right;"
                                    aria-label="Example icon-button with a menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>

                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item>

                                        <span>Action</span>
                                    </button>
                                    <button mat-menu-item>

                                        <span> Another action</span>
                                    </button>
                                    <button mat-menu-item>

                                        <span>Something else here</span>
                                    </button>
                                </mat-menu>
                            </div>
                        </div>
                        <div
                            style="border-radius: 18px;border: 1px solid #dee2e6;float: left;width: 100% !important;">
                            <ngx-charts-pie-chart [view]="view1" 
                                [scheme]="{domain: ['#3485FD', '#81B4FE', '#E6F0FF']}" [results]="series"
                                [legend]="true" [labels]="true" [doughnut]="true" [legendPosition]="'bottom'"
                                [legendTitle]="''">
                            </ngx-charts-pie-chart>
                        </div>
                    </div>



                </div>

            </div>

            <div fxFlex="0 1 calc(98%)" fxFlex.lt-md="0 1 calc(98%)" fxFlex.lt-sm="100%"
                style="border-radius: 18px;border: 1px solid #dee2e6;padding: 20px;margin: 15px;float: left;">
                <div>
                    <mat-table class="vt-dashboard-table" #table [dataSource]="tableDataSource" matSort
                        fusePerfectScrollbar [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                        <!-- STUDENT NAME Column -->
                        <ng-container matColumnDef="StudentName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header  fxShow.gt-xs>STUDENT
                                NAME</mat-header-cell>
                            <mat-cell *matCellDef="let DailyDiaryEntries"  fxShow.gt-xs>
                                <p class="text-truncate" (click)="studentLink(DailyDiaryEntries.StudentId)">
                                    <span class="stud-img">
                                        <img *ngIf="DailyDiaryEntries.StudentPhoto!=''"
                                            [src]="ProfileImageBase + DailyDiaryEntries.StudentPhoto"
                                            class="stud-profile-pic" style="max-width: 30px !important;
                                    height: 30px !important;
                                    vertical-align: top;
                                    border: none; border-radius: 50%;" />

                                        <mat-icon *ngIf="DailyDiaryEntries.StudentPhoto==''">face</mat-icon>

                                    </span>
                                    <span>{{DailyDiaryEntries.StudentName}}</span><br>
                                </p>
                            </mat-cell>
                        </ng-container>

                        <!-- STATUS Column -->
                        <ng-container matColumnDef="Status">
                            <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm
                                fxShow.gt-md>STATUS</mat-header-cell>
                            <mat-cell *matCellDef="let DailyDiaryEntries">

                                <p class="text-truncate">
                                    <span style="color: #0d6efd!important;"
                                        *ngIf="DailyDiaryEntries.Status =='Applied'">
                                        {{DailyDiaryEntries.Status}}
                                    </span>
                                    <span style="color: #ffc107!important;"
                                        *ngIf="DailyDiaryEntries.Status =='In Progress'">
                                        {{DailyDiaryEntries.Status}}
                                    </span>
                                    <span style="color: #e35d6a!important"
                                        *ngIf="DailyDiaryEntries.Status =='Withdrawn'">
                                        {{DailyDiaryEntries.Status}}
                                    </span>
                                    <span *ngIf="DailyDiaryEntries.Status =='Not Mapped'">
                                        {{DailyDiaryEntries.Status}}
                                    </span>
                                    <span *ngIf="DailyDiaryEntries.Status =='Not Started'">
                                        {{DailyDiaryEntries.Status}}
                                    </span>
                                    <span *ngIf="DailyDiaryEntries.Status =='Completed'">
                                        {{DailyDiaryEntries.Status}}
                                    </span>

                                </p>
                            </mat-cell>
                        </ng-container>

                        <!-- EMPLOYER  Column -->
                        <ng-container matColumnDef="OrganizationName">
                            <mat-header-cell *matHeaderCellDef mat-sort-header  fxShow.gt-xs>EMPLOYER
                            </mat-header-cell>
                            <mat-cell *matCellDef="let DailyDiaryEntries"  fxShow.gt-xs>

                                <span class="stud-img" style="padding-right: 10px;"
                                    [routerLink]="['/employer-details', DailyDiaryEntries.EmployerId]"
                                    skipLocationChange="false" replaceUrl="true">
                                    <img *ngIf="DailyDiaryEntries.EmployerPhoto!=''"
                                        [src]="ProfileImageBaseURL + DailyDiaryEntries.EmployerPhoto"
                                        class="stud-profile-pic" style="max-width: 30px !important;
                                    height: 30px !important;
                                    vertical-align: top;
                                    border: none; border-radius: 50%;" />

                                    <mat-icon *ngIf="DailyDiaryEntries.EmployerPhoto==''">face</mat-icon>
                                </span>
                                <span [routerLink]="['/employer-details', DailyDiaryEntries.EmployerId]"
                                    skipLocationChange="false" replaceUrl="true">
                                    <span>{{DailyDiaryEntries.OrganizationName}}</span><br>
                                    <span *ngIf="DailyDiaryEntries.IsPreferred == true">
                                        <svg class="ml-1" data-name="Group 1" xmlns="http://www.w3.org/2000/svg"
                                            width="15" height="25.19" viewBox="0 0 24 23.25">
                                            <path
                                                d="M23.823,11.991a.466.466,0,0,0,0-.731L21.54,8.7c-.12-.122-.12-.243-.12-.486L21.779,4.8c0-.244-.121-.609-.478-.609L18.06,3.46c-.12,0-.36-.122-.36-.244L16.022.292a.682.682,0,0,0-.839-.244l-3,1.341a.361.361,0,0,1-.48,0L8.7.048a.735.735,0,0,0-.84.244L6.183,3.216c0,.122-.24.244-.36.244L2.58,4.191a.823.823,0,0,0-.48.731l.36,3.412a.74.74,0,0,1-.12.487L.18,11.381a.462.462,0,0,0,0,.732l2.16,2.437c.12.124.12.243.12.486L2.1,18.449c0,.244.12.609.48.609l3.24.735c.12,0,.36.122.36.241l1.68,2.924a.683.683,0,0,0,.84.244l3-1.341a.353.353,0,0,1,.48,0l3,1.341a.786.786,0,0,0,.839-.244L17.7,20.035c.122-.124.24-.243.36-.243l3.24-.734c.24,0,.48-.367.48-.609l-.361-3.413a.726.726,0,0,1,.121-.485Z"
                                                fill="#0D6EFD"></path>
                                            <path data-name="Path"
                                                d="M4.036,10,0,5.8,1.527,4.2,4.036,6.818,10.582,0,12,1.591Z"
                                                transform="translate(5.938 6.625)" fill="#fff"></path>
                                        </svg>
                                        Preferred Partner
                                    </span>
                                </span>


                            </mat-cell>
                        </ng-container>
                        <!--DIARY ENTRY Column -->
                        <ng-container matColumnDef="DiaryEntryCount">
                            <mat-header-cell *matHeaderCellDef mat-sort-header  fxShow.gt-xs>DIARY
                                ENTRY</mat-header-cell>
                            <mat-cell *matCellDef="let DailyDiaryEntries"  fxShow.gt-xs>
                                <p class="text-truncate">{{DailyDiaryEntries.DiaryEntryCount }}</p>
                            </mat-cell>
                        </ng-container>

                        <!--HOUR SPENT  Column -->
                        <ng-container matColumnDef="HoursSpent">
                            <mat-header-cell *matHeaderCellDef mat-sort-header  fxShow.gt-xs>HOUR SPENT
                            </mat-header-cell>
                            <mat-cell *matCellDef="let DailyDiaryEntries"  fxShow.gt-xs>
                                <p class="text-truncate">{{DailyDiaryEntries.HoursSpent }}</p>
                            </mat-cell>
                        </ng-container>





                        <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                        <mat-row *matRowDef="let DailyDiaryEntries; columns: displayedColumns;" class="sector-job-role"
                            matRipple>
                        </mat-row>
                    </mat-table>

                    <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0"
                        [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" (page)="onPageIndexChanged($event)">
                    </mat-paginator>
                </div>


            </div>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>