import { Injectable } from '@angular/core';
import { BaseService } from 'app/services/base.service';
import { Observable, forkJoin } from 'rxjs';
import { tap, retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InternshipDetailsService {


  constructor(private http: BaseService) { }

  getSchools(): Observable<any> {
    return this.http
      .HttpGet(this.http.Services.InternshipOpportunity.GetAll)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(response => {
          return response.Results;
        })
      );
  }



  getInternshipById(InternshipId: string) {
    let requestParams = {
      DataId: InternshipId
    };

    return this.http.HttpPost(this.http.Services.InternshipDetails.GetInternshipById, requestParams)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(function (response: any) {
          return response.Results;
        })
      );
  }

  getActiveInterns(filters: any): Observable<any> {
    return this.http
      .HttpPost(this.http.Services.InternshipDetails.GetActiveInterns, filters)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(response => {
          return response.Results;
        })
      );
  }

  getInternProgressDashBoard(StudentId: string, InternshipId: string) {
    let requestParams = {

      DataId: StudentId,
      DataId1: InternshipId
    };

    return this.http.HttpPost(this.http.Services.InternshipDetails.GetInternProgressDashBoard, requestParams)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(function (response: any) {
          return response.Results;
        })
      );
  }

  // getDailyDiary(StudentId: string, InternshipId: string) {
  //   let requestParams = {

  //     DataId: StudentId,
  //     DataId1: InternshipId
  //   };

  //   return this.http.HttpPost(this.http.Services.InternshipDetails.GetDailyDiary, requestParams)
  //     .pipe(
  //       retry(this.http.Services.RetryServieNo),
  //       catchError(this.http.HandleError),
  //       tap(function (response: any) {
  //         return response.Results;
  //       })
  //     );
  // }

  getDailyDiary(filters: any): Observable<any> {
    return this.http
      .HttpPost(this.http.Services.InternshipDetails.GetDailyDiary, filters)
      .pipe(
        retry(this.http.Services.RetryServieNo),
        catchError(this.http.HandleError),
        tap(response => {
          return response.Results;
        })
      );
  }




}

