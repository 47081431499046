<div id="role" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    Student Details

                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->
            <div fxLayout="row" class="add-edit-button" fxLayoutAlign="flex-start" fxFlex="0 1 calc(50%)"
                fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="100%">

                <button mat-raised-button class="add-school-button btn-outline-dark" [routerLink]="'/vt-student'"
                    fxFlex="0 1 calc(33%)" fxFlex.lt-md="0 1 calc(33%)" fxFlex.lt-sm="50%">
                    <span>Manage Students </span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="ms-2" width="20" height="20" viewBox="0 0 24 24"
                        stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"></path>
                        <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3"></path>
                        <line x1="16" y1="5" x2="19" y2="8"></line>
                    </svg>
                </button>

                <button mat-raised-button class="add-school-button btn-warning mls" fxFlex="0 1 calc(33%)"
                    fxFlex.lt-md="0 1 calc(33%)" fxFlex.lt-sm="50%" [routerLink]="['/vt-student','new']">
                    <span>Add New Student </span><svg xmlns="http://www.w3.org/2000/svg" class="ms-2" width="20"
                        height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="12" y1="5" x2="12" y2="19" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                    </svg>
                </button>



            </div>



        </div>
        <!-- / HEADER -->


        <!-- CONTENT CARD -->
        <div class="content-card" style="overflow: auto;" *ngIf="studentDetailsData">

            <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="displayContent">

                <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                    appearance="outline" fxFlex="0 1 calc(29%)" fxFlex.lt-md="0 1 calc(29%)" fxFlex.lt-sm="29%"
                    style="height: auto;margin-left: 1%;margin-top: 1%;">

                    <mat-card class="example-card main-box" appearance="outline" fxFlex="100" fxFlex.lt-md="100"
                        fxFlex.lt-sm="100" *ngIf="studentDetailsData">
                        <mat-card-header style="padding: 2rem;
                        text-align: center;
                        display: flex!important;">
                            <div mat-card-avatar class="example-header-image">
                                <img [src]="img+studentDetailsData.ProfilePhoto" alt="">
                            </div>
                            <mat-card-title>{{studentDetailsData.FullName}}</mat-card-title>
                            <mat-card-subtitle>{{studentDetailsData.Mobile}}</mat-card-subtitle>
                        </mat-card-header>
                        <mat-divider inset></mat-divider>
                        <mat-card-actions>
                            <button mat-button>Requests</button>
                        </mat-card-actions>
                        <mat-divider inset></mat-divider>
                        <mat-card-actions>
                            <button mat-button (click)="scrollPoint1(point_5)">Internships</button>
                        </mat-card-actions>
                        <mat-divider inset></mat-divider>
                        <mat-card-actions>
                            <button mat-button (click)="scrollPoint1(point_4)">Diary Entry</button>
                        </mat-card-actions>
                        <mat-divider inset></mat-divider>
                        <mat-card-actions>
                            <button mat-button (click)="scrollPoint1(point_3)">Gallery</button>
                        </mat-card-actions>
                        <mat-divider inset></mat-divider>
                        <mat-card-actions>
                            <button mat-button (click)="scrollPoint1(point_6)">Final Assignment</button>
                        </mat-card-actions>
                        <mat-divider inset></mat-divider>
                        <mat-card-actions>
                            <button mat-button (click)="scrollPoint1(point_2)">Student Details</button>
                        </mat-card-actions>
                        <mat-divider inset></mat-divider>
                        <mat-card-actions>
                            <button type="button" mat-button (click)="scrollPoint1(point_1)">Academic Details</button>
                        </mat-card-actions>
                        <mat-divider inset></mat-divider>

                        <mat-card-actions>
                            <button type="button" mat-button
                                (click)="onDeleteSchool(studentDetailsData.StudentId)">Delete</button>
                        </mat-card-actions>

                    </mat-card>

                </div>

                <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                    appearance="outline" fxFlex="0 1 calc(68%)" fxFlex.lt-md="0 1 calc(68%)" fxFlex.lt-sm="68%"
                    style="height: auto;margin-left: 1%;margin-top: 1%;"
                    class="position-relative rounded-12 border border-gray-300">
                    <div class="example-card" appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100"
                        style="height: 41%;">
                        <!-- <mat-card-header>
                            <mat-card-title>Requests</mat-card-title>
                        </mat-card-header> -->
                        <div class="d-flex align-items-center px-3 px-md-4 py-3 border-bottom border-gray-200">
                            <h5 class="card-header-title my-2 font-weight-semibold fontSize">Requests</h5>
                        </div>
                        <!-- <mat-divider inset></mat-divider> -->
                        <div class="my-4 px-10 px-lg-4">
                            <!-- <span class="d-block fs-13 mb-10 text-gray-600" style="margin-bottom: 2%;">01 jun 22</span> -->


                            <div fxLayout="row" *ngIf="StudentInternshipsByCriteria">
                                <div class="position-relative rounded-12 border border-gray-300 " style="width: 100%;">
                                    <div
                                        class="px-20 d-flex flex-wrap justify-content-center justify-content-lg-between align-items-center py-20 border-bottom mb-30 border-gray-300 ">
                                        <p class="fs-20 fw-700 mb-15  mb-lg-0 text-black mb-10" style="width: 60%;">
                                            Internship Approval Requests
                                        </p>
                                        <div style="width: 40%;">
                                            <!-- <button mat-raised-button type="button"
                                                class="btn btn-soft-primary mr-10 btn-send-to-employer">Reject</button>
                                            <button mat-raised-button type="button"
                                                class="btn btn-primary btn-send-to-employer">Approve</button> -->
                                        </div>
                                    </div>
                                    <div class="d-flex flex-lg-row flex-column justify-content-lg-between justify-content-center align-items-center px-1 mb-20 px-lg-20"
                                        *ngIf="StudentInternshipsByCriteria.length == 0">
                                        <span>No Data Found</span>

                                    </div>
                                    <div class="d-flex flex-lg-row flex-column justify-content-lg-between justify-content-center align-items-center px-1 mb-20 px-lg-20"
                                        *ngIf="StudentInternshipsByCriteria">
                                        <table>
                                            <tr *ngFor="let item of StudentInternshipsByCriteria;">
                                                <td>{{StudentDashboardInternshipData.OrganizationName}}<br />{{item.InternshipBranchName}}<br>
                                                    {{StudentDashboardInternshipData.InternshipTitle}}</td>
                                                <td>{{item.SectorName}}</td>
                                                <td>{{item.OpenPositions}}</td>
                                                <td>
                                                    <button mat-raised-button type="button"
                                                        [ngClass]="item.IsApplied == true ? 'disable' : ''"
                                                        [disabled]="item.IsApplied == true"
                                                        style="background-color: #e6f0ff; color: #0d6efd;    margin-right: 12px;"
                                                        (click)="onRejectInternshipApplication(item.InterestId)">
                                                        Reject
                                                    </button>
                                                    <!-- <button mat-raised-button type="button"
                                                        [ngClass]="item.IsPlaced == true ? 'disable' : ''"
                                                        [disabled]="item.IsPlaced == true || item.IsApprovedByVT == true"
                                                        style="color: #fff;
                                                background-color: #0b5ed7;
                                                border-color: #0a58ca;"
                                                        (click)="onClickHireStudent(item.InternshipId)">Approve
                                                    </button> -->
                                                    <button mat-raised-button type="button"
                                                    ngClass="{{item.IsApprovedByVT == true ? 'btn-disabled' : 'btn-send-to-employer'}}"
                                                        [disabled]="item.IsApprovedByVT == true"
                                                       
                                                        (click)="onClickHireStudent(item.InternshipId)">Approve
                                                    </button>
                                                </td>
                                                <td>
                                                    <button mat-raised-button type="button"
                                                        style="    background-color: #e6f0ff; color: #0d6efd;"
                                                        [routerLink]="['/internship-opportunity', 'edit', item.InternshipId]">
                                                        Edit
                                                    </button>
                                                </td>
                                            </tr>
                                        </table>


                                    </div>

                                </div>


                            </div>
                            <div fxLayout="row" style="margin-top: 5%;margin-bottom:5%;"
                                *ngIf="RequestsByStudentIdRequestType">
                                <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start"
                                    id="left-content" appearance="outline" fxFlex="0 1 calc(32%)"
                                    fxFlex.lt-md="0 1 calc(32%)" fxFlex.lt-sm="32%"
                                    style="height: auto;margin-left: 1%;margin-top: 1%;"
                                    *ngFor="let item of RequestsByStudentIdRequestType;">
                                    <div class="" style="width: 100%;float: left;padding-right: 1%;">
                                        <div class="">
                                            <span class="d-block fs-13 mb-5px text-gray-600">{{item.RequestedDate |
                                                date:'mediumDate' }}</span>
                                            <div
                                                class="position-relative rounded-12 border border-gray-300 px-15 py-20 pb-0">
                                                <p class="fs-16 fw-600 mb-0 ">
                                                    {{item.TypeOfRequest}}
                                                </p>
                                                <p class="d-block fs-13 mb-10 text-gray-600 mb-0 line-h-20 mt-10" style="word-break: break-all;">
                                                    {{item.RequestDesc}}</p>


                                                <div
                                                    class=" d-flex align-items-center justify-content-between mt-20 py-20 border-top border-gray-300">

                                                    <div fxLayout="row wrap" fxLayout.lt-sm="column"
                                                        fxLayoutAlign="flex-start" id="left-content"
                                                        appearance="outline" fxFlex="0 1 calc(90%)"
                                                        fxFlex.lt-md="0 1 calc(90%)" fxFlex.lt-sm="90%"
                                                        style="height: auto;margin-left: 1%;margin-top: 1%;">
                                                        <span class="" *ngIf="item.StudentProfilePhoto != ''">
                                                            <span *ngIf="item.StudentProfilePhoto != null">
                                                                <img class="rounded-circle"
                                                                    [src]="img+item.StudentProfilePhoto" alt="Avatars"
                                                                    width="40px" height="40px">
                                                            </span>


                                                        </span>
                                                        <div class="ps-2 ps-md-3">
                                                            <p class="mb-0 fs-16 fw-600 text-black">{{item.StudentName}}
                                                            </p>
                                                            <p class="fs-14 text-gray-600 mb-0 lh-base">
                                                                {{item.StudentMobile}}</p>
                                                        </div>
                                                    </div>
                                                    <div fxLayout="row wrap" fxLayout.lt-sm="column"
                                                        fxLayoutAlign="flex-start" id="left-content"
                                                        appearance="outline" fxFlex="0 1 calc(8%)"
                                                        fxFlex.lt-md="0 1 calc(8%)" fxFlex.lt-sm="8%"
                                                        style="height: auto;margin-left: 1%;margin-top: 1%;">
                                                        <button type="button" class="btn "
                                                            (click)="onChangeStudent(item.RequestId)"
                                                            style="    background-color: #e6f0ff; color: #0d6efd;border: aliceblue;">Action</button>

                                                    </div>



                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                </div>



                            </div>
                        </div>

                        <div class="my-4 px-10 px-lg-4">
                            <div
                                class="position-relative rounded-12 border border-gray-300 px-15 py-20 d-lg-flex align-items-center">
                                <div class="mr-30">
                                    <p class="fs-16 fw-700 mb-0">
                                        Profile Change Requests
                                    </p>



                                </div>

                                <div class="mr-30" *ngIf="RequestsByStudentIdRequestTypeProfile.length == 0">
                                    <span>No Data Found</span>
                                </div>
                                <div class="mr-30" *ngFor="let item of RequestsByStudentIdRequestTypeProfile;">

                                    <span class="d-block fs-13 mb-5px text-gray-600 fontW">{{item.RequestedDate |
                                        date:'mediumDate'}}</span>

                                    <p class="d-block fs-13 mb-10 text-gray-600 mb-0 line-h-20 mt-10" style="word-break: break-all;">
                                        {{item.RequestDesc}}</p>
                                    <button [disabled]="item.IsActionTaken == true "
                                        [ngClass]="item.IsActionTaken == true ? 'disable' : ''" type="button"
                                        class="btn btn-primary mt-lg-0 mt-20"
                                        [routerLink]="['/profile-change-request', 'edit', item.StudentId]"
                                        style="    background-color: #e6f0ff; color: #0d6efd;border: aliceblue;">Edit</button>
                                    <mat-divider inset style="margin-top: 15px;
                                    margin-bottom: 15px;"></mat-divider>
                                </div>

                            </div>
                        </div>

                    </div>


                </div>
                <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                    appearance="outline" fxFlex="0 1 calc(29%)" fxFlex.lt-md="0 1 calc(29%)" fxFlex.lt-sm="29%"
                    style="height: auto;margin-left: 1%;margin-top: 1%;">



                </div>

                <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                    appearance="outline" fxFlex="0 1 calc(68%)" fxFlex.lt-md="0 1 calc(68%)" fxFlex.lt-sm="68%"
                    style="height: auto;margin-left: 1%;margin-top: 1%;"
                    class="position-relative rounded-12 border border-gray-300 ">
                    <div class="padding-bottom">
                        <mat-tab-group selectedIndex="0" (selectedTabChange)="changeTab($event)">
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <span>Current Internship</span>
                                </ng-template>

                            </mat-tab>
                            <mat-tab>
                                <ng-template mat-tab-label>
                                    <span>Past Internship</span>
                                </ng-template>

                            </mat-tab>
                        </mat-tab-group>
                    </div>



                </div>
                <!-- / Internship Entry Details -->
                <div #point_5 fxLayout="row wrap" fxLayout.lt-sm="column" appearance="outline" fxFlex="100"
                    fxFlex.lt-md="100" fxFlex.lt-sm="100">
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                        appearance="outline" fxFlex="0 1 calc(29%)" fxFlex.lt-md="0 1 calc(29%)" fxFlex.lt-sm="29%"
                        style="height: auto;margin-left: 1%;margin-top: 1%;">


                    </div>
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                        appearance="outline" fxFlex="0 1 calc(68%)" fxFlex.lt-md="0 1 calc(68%)" fxFlex.lt-sm="68%"
                        style="height: auto;margin-left: 1%;margin-top: 1%;"
                        class="position-relative rounded-12 border border-gray-300 ">
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">

                            <mat-card style="width:100% ;font-weight: 900;">Internship in Progress</mat-card>
                        </div>
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(45%)" fxFlex.lt-md="0 1 calc(45%)" fxFlex.lt-sm="45%"
                            style="height: auto;margin-left: 1%;margin-top: 1%;"
                            *ngIf="!StudentDashboardInternshipData">
                            No Data Found
                        </div>
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="100%" fxFlex.lt-sm="100%"
                            *ngIf="StudentDashboardInternshipData">
                            <div fxLayout="row" fxFlex="0 1 calc(100%)" class="px-20" style="margin-top:5px;">
                                <div fxFlex="0 1 calc(10%)">
                                    <span>
                                        <img [src]="img+StudentDashboardInternshipData.EmpPhotoUrl" width="70"
                                            height="70">

                                    </span>
                                </div>
                                <div fxFlex="0 1 calc(85%)">
                                    <span
                                        class="fontW wi">{{StudentDashboardInternshipData.OrganizationName}}</span><br>
                                    <span>{{StudentDashboardInternshipData.InternshipTitle}}</span>
                                </div>
                                <div fxFlex="0 1 calc(5%)">
                                    <!-- <mat-cell fxShow.gt-xs> -->
                                    <button mat-icon-button [matMenuTriggerFor]="menu"
                                        aria-label="Example icon-button with a menu">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item>
                                            <mat-icon>done</mat-icon>
                                            <span>Marks Complete</span>
                                        </button>

                                        <button mat-menu-item (click)="onClickDropDetails()">
                                            <mat-icon>publish</mat-icon>
                                            <span>Drop</span>
                                        </button>
                                    </mat-menu>
                                    <!-- </mat-cell> -->
                                </div>

                            </div>

                            <div fxLayout="row" fxFlex="0 1 calc(100%)" class="px-20">
                                <span style="width: 100%;">
                                    <h3 style="font-size: 16px; font-weight: 600;">Intern's Progress</h3>
                                </span>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="flex-start" fxFlex="0 1 calc(100%)"
                                *ngIf="progressData !=undefined" style="padding-left: 20px;
                            padding-right: 20px;
                        ">
                                <div fxFlex="0 1 calc(33.33%)">
                                    <p>Points<br> <b>{{progressData.Points}}</b></p>
                                </div>
                                <div fxFlex="0 1 calc(33.33%)">
                                    <p>Attendance<br> <b>{{progressData.Attendance}} D</b></p>
                                </div>
                                <div fxFlex="0 1 calc(33.33%)">
                                    <p>Internship<br> <b>{{progressData.Hours | slice:0:5}} H</b></p>
                                </div>
                            </div>
                            <ngx-charts-advanced-pie-chart [view]="view" [scheme]="colorScheme" [results]="single"
                                [gradient]="gradient" (select)="onSelect($event)" (activate)="onActivate($event)"
                                (deactivate)="onDeactivate($event)">
                            </ngx-charts-advanced-pie-chart>
                        </div>

                    </div>
                </div>
                <!-- / Diary Entry Details -->
                <div #point_4 fxLayout="row wrap" fxLayout.lt-sm="column" appearance="outline" fxFlex="100"
                    fxFlex.lt-md="100" fxFlex.lt-sm="100">
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                        appearance="outline" fxFlex="0 1 calc(29%)" fxFlex.lt-md="0 1 calc(29%)" fxFlex.lt-sm="29%"
                        style="height: auto;margin-left: 1%;margin-top: 1%;">


                    </div>
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                        appearance="outline" fxFlex="0 1 calc(68%)" fxFlex.lt-md="0 1 calc(68%)" fxFlex.lt-sm="68%"
                        style="height: auto;margin-left: 1%;margin-top: 1%;"
                        class="position-relative rounded-12 border border-gray-300 ">
                        <div class="content-card rounded-12 shadow-dark-80" style="width: 100%;">


                            <div class="content-card">

                                <mat-table class="xxx" [dataSource]="tableDataSource" matSort fusePerfectScrollbar
                                    [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                                    <!-- LessonsLearnt Column -->
                                    <ng-container matColumnDef="LessonsLearnt">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
                                            Diary Entries
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let diaryEntry" fxHide fxShow.gt-xs>

                                            <p class="text-truncate">
                                                <span *ngFor="let attItem of diaryEntry.Attachments">
                                                    <a target="_blank"
                                                        href="{{ imageAttachments + attItem.AttachmentPath }}">
                                                        <mat-icon *ngIf="attItem.AttachmentType=='PDF||pdf'">
                                                            picture_as_pdf</mat-icon>
                                                        <mat-icon *ngIf="attItem.AttachmentType=='Image'">image
                                                        </mat-icon>
                                                    </a>

                                                </span><br>
                                                {{diaryEntry.LessonsLearnt}}
                                            </p>
                                        </mat-cell>
                                    </ng-container>

                                    <!-- Hours Column -->
                                    <ng-container matColumnDef="Hours">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>
                                            Hours Spent
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let diaryEntry">
                                            <p class="text-truncate"><b>{{diaryEntry.Hours}} H </b></p>
                                        </mat-cell>
                                    </ng-container>

                                    <!-- Highlight Column -->
                                    <ng-container matColumnDef="Highlight">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
                                            Highlight
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let diaryEntry" fxHide fxShow.gt-xs>
                                            <p class="text-truncate">{{diaryEntry.Highlight}}</p>
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="Actions" stickyEnd>
                                        <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                                        <mat-cell *matCellDef="let diaryEntry" fxShow.gt-xs>
                                            <button mat-icon-button [matMenuTriggerFor]="menu"
                                                aria-label="Example icon-button with a menu">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu">
                                                <!-- <button mat-menu-item >
                                                <mat-icon>done</mat-icon>
                                                <span>Complete</span>
                                            </button> -->
                                                <button mat-menu-item>
                                                    <span>No Actions</span>
                                                </button>
                                                <!-- <button mat-menu-item >
                                                <mat-icon>publish</mat-icon>
                                                <span>Publish</span>
                                            </button> -->
                                            </mat-menu>
                                        </mat-cell>
                                    </ng-container>

                                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                                    <mat-row *matRowDef="let diaryEntry; columns: displayedColumns;" class="diaryEntry">
                                    </mat-row>
                                    <ng-container matColumnDef="groupHeader">
                                        <td colspan="999" mat-cell *matCellDef="let groupBy"><strong>
                                                {{groupBy.date | date}}</strong></td>
                                    </ng-container>

                                    <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"> </tr>

                                </mat-table>

                                <mat-paginator #ListPaginator [length]="SearchBy.TotalResults"
                                    [pageIndex]="SearchBy.PageIndex" [pageSize]="SearchBy.PageSize"
                                    [pageSizeOptions]="SearchBy.PageSizeOptions"
                                    [showFirstLastButtons]="SearchBy.ShowFirstLastButtons"
                                    (page)="onPageIndexChanged($event)">
                                </mat-paginator>

                            </div>

                        </div>

                    </div>
                </div>
                <!-- / Diary Entry Details -->
                <div #point_3 fxLayout="row wrap" fxLayout.lt-sm="column" appearance="outline" fxFlex="100"
                    fxFlex.lt-md="100" fxFlex.lt-sm="100">
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                        appearance="outline" fxFlex="0 1 calc(29%)" fxFlex.lt-md="0 1 calc(29%)" fxFlex.lt-sm="29%"
                        style="height: auto;margin-left: 1%;margin-top: 1%;" *ngIf="DailyDiaryImageAttachments">


                    </div>
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                        appearance="outline" fxFlex="0 1 calc(68%)" fxFlex.lt-md="0 1 calc(68%)" fxFlex.lt-sm="68%"
                        style="height: auto;margin-left: 1%;margin-top: 1%;"
                        class="position-relative rounded-12 border border-gray-300 " *ngIf="DailyDiaryImageAttachments">
                        <mat-card style="width:100% ;font-weight: 900;">Gallery</mat-card>
                        <div fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100"
                            *ngIf="DailyDiaryImageAttachments.length == 0">
                            <span>No Data Found</span>
                        </div>
                        <div fxFlex="100" fxLayout="row wrap" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                            <div fxLayout="row" fxFlex="100" class="fontW" *ngFor="let datewise of dateArray;">
                                {{datewise.date | date:'mediumDate'}} </div>

                            <div fxLayout="row wrap" style="padding-left: 15px; padding-top: 35px;color: #0d6efd;"
                                *ngFor="let imgGallery of ImageGalleryData;">

                                <img *ngIf="imgGallery.SectionType=='IRBestPicture'"
                                    [src]="FinalInternshipImageBaseURL+imgGallery.AttachmentPath"
                                    style="width:50px;height:50px; float:left; margin:1px; padding: 1px;"
                                    class="d-block mb-10 border border-gray-200 p-1">

                                <img *ngIf="imgGallery.SectionType=='IRCetificate'"
                                    [src]="FinalInternshipImageBaseURL+imgGallery.AttachmentPath"
                                    style="width:50px;height:50px; float:left; margin:1px; padding: 1px;"
                                    class="d-block mb-10 border border-gray-200 p-1">
                                <img *ngIf="imgGallery.SectionType=='IRRportPhotos'"
                                    [src]="FinalInternshipImageBaseURL+imgGallery.AttachmentPath"
                                    style="width:50px;height:50px; float:left; margin:1px; padding: 1px;"
                                    class="d-block mb-10 border border-gray-200 p-1">
                                <img *ngIf="imgGallery.SectionType=='DailyDiary'"
                                    [src]="imageAttachments+imgGallery.AttachmentPath"
                                    style="width:50px;height:50px; float:left; margin:1px; padding: 1px;"
                                    class="d-block mb-10 border border-gray-200 p-1">
                            </div>


                        </div>

                        <mat-divider inset style="margin-top: 15px;-bottom: 15px;"></mat-divider>
                        <div fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100"
                            style="padding-left: 15px;padding-top: 15px; padding-bottom: 15px;   text-align: center;color: #0d6efd;cursor: pointer;font-size: 18px;"
                            (click)="onClickMarkDetails()">View All</div>

                    </div>
                </div>

                <!-- / Final Assignment Details -->
                <div #point_6 fxLayout="row wrap" fxLayout.lt-sm="column" appearance="outline" fxFlex="100"
                    fxFlex.lt-md="100" fxFlex.lt-sm="100">
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                        appearance="outline" fxFlex="0 1 calc(29%)" fxFlex.lt-md="0 1 calc(29%)" fxFlex.lt-sm="29%"
                        style="height: auto;margin-left: 1%;margin-top: 1%;" *ngIf="DailyDiaryImageAttachments">


                    </div>
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                        appearance="outline" fxFlex="0 1 calc(68%)" fxFlex.lt-md="0 1 calc(68%)" fxFlex.lt-sm="68%"
                        style="height: auto;margin-left: 1%;margin-top: 1%;"
                        class="position-relative rounded-12 border border-gray-300 " *ngIf="DailyDiaryImageAttachments">
                        <mat-card style="width:100% ;font-weight: 900;">Final Assignment</mat-card>

                        <div fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                            <div class="content-card">

                                <mat-table class="xxx" [dataSource]="tableDataSourceFinalAssignment" matSort
                                    fusePerfectScrollbar [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">
                                    <ng-container matColumnDef="InternshipImage">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            INTERNSHIP IMAGE
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let internshipAssignment">
                                            <p class="text-truncate">
                                                <span style="width:50px; height:50px"
                                                    *ngFor="let bestPictureItem of internshipAssignment.listAttachments">
                                                    <img *ngIf="bestPictureItem.SectionType=='IRBestPicture'"
                                                        [src]="FinalInternshipImageBaseURL+bestPictureItem.AttachmentPath"
                                                        style="width:50px;height:50px; margin:1px; padding: 1px;"
                                                        class="d-block mb-10 border border-gray-200 p-1">
                                                </span>
                                            </p>
                                        </mat-cell>
                                    </ng-container>


                                    <ng-container matColumnDef="CertificatePicture">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            CERTIFICATE PICTURE
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let internshipAssignment" colspan="1">
                                            <p class="text-truncate">
                                                <span
                                                    *ngFor="let certificateItem of internshipAssignment.listAttachments">
                                                    <img *ngIf="certificateItem.SectionType=='IRCetificate'"
                                                        [src]="FinalInternshipImageBaseURL+certificateItem.AttachmentPath"
                                                        style="width:50px;height:50px;  padding: 1px;margin:1px;"
                                                        class="d-block mb-10 border border-gray-200 p-1">
                                                </span>
                                            </p>
                                        </mat-cell>
                                    </ng-container>


                                    <ng-container matColumnDef="ProjectReport">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>PROJECT REPORT
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let internshipAssignment">
                                            <p class="text-truncate">

                                                <span
                                                    *ngFor="let reportPhotoItem of internshipAssignment.listAttachments">
                                                    <img *ngIf="reportPhotoItem.SectionType=='IRRportPhotos'"
                                                        [src]="FinalInternshipImageBaseURL+reportPhotoItem.AttachmentPath"
                                                        style="width:50px;height:50px; padding: 1px;margin:1px;"
                                                        class="venobox auto-play col-4 just-open vbox-item d-block mb-10 border border-gray-200 p-1">
                                                </span>

                                            </p>
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="ReportDetail">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>DETAILS
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let internshipAssignment">
                                            <p class="text-truncate">{{internshipAssignment.ReportDetail}}</p>
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="Actions" stickyEnd>
                                        <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                                        <mat-cell *matCellDef="let internshipAssignment" fxShow.gt-xs>
                                            <button mat-icon-button [matMenuTriggerFor]="menu"
                                                aria-label="Example icon-button with a menu">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu">
                                                <button mat-menu-item
                                                    (click)="onClickReviewReport(internshipAssignment.StudentID,internshipAssignment.InternshipId)">
                                                    <mat-icon style="color: #1088e4;">library_books</mat-icon>
                                                    <span>Review report</span>
                                                </button>
                                            </mat-menu>
                                        </mat-cell>
                                    </ng-container>

                                    <mat-header-row
                                        *matHeaderRowDef="displayedColumnsFinalInternship; sticky:true"></mat-header-row>

                                    <mat-row *matRowDef="let diaryEntry; columns: displayedColumnsFinalInternship;"
                                        class="diaryEntry">
                                    </mat-row>
                                    <ng-container matColumnDef="groupHeader1">
                                        <td colspan="999" mat-cell *matCellDef="let groupBy"><strong>
                                                {{groupBy.date | date}}</strong></td>
                                    </ng-container>

                                    <tr mat-row *matRowDef="let row; columns: ['groupHeader1']; when: isGroupFA"> </tr>

                                </mat-table>

                                <mat-paginator #ListPaginator [length]="SearchBy.TotalResults"
                                    [pageIndex]="SearchBy.PageIndex" [pageSize]="SearchBy.PageSize"
                                    [pageSizeOptions]="SearchBy.PageSizeOptions"
                                    [showFirstLastButtons]="SearchBy.ShowFirstLastButtons"
                                    (page)="onPageIndexChanged($event)">
                                </mat-paginator>

                            </div>
                        </div>
                    </div>
                </div>

                <!-- / Student Details -->
                <div #point_2 fxLayout="row wrap" fxLayout.lt-sm="column" appearance="outline" fxFlex="100"
                    fxFlex.lt-md="100" fxFlex.lt-sm="100">
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                        appearance="outline" fxFlex="0 1 calc(29%)" fxFlex.lt-md="0 1 calc(29%)" fxFlex.lt-sm="29%"
                        style="height: auto;margin-left: 1%;margin-top: 1%;" *ngIf="studentDetailsData">


                    </div>
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                        appearance="outline" fxFlex="0 1 calc(68%)" fxFlex.lt-md="0 1 calc(68%)" fxFlex.lt-sm="68%"
                        style="height: auto;margin-left: 1%;margin-top: 1%;"
                        class="position-relative rounded-12 border border-gray-300 " *ngIf="studentDetailsData">
                        <mat-card style="width:100% ;font-weight: 900;">
                            <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start"
                                id="left-content" appearance="outline" fxFlex="0 1 calc(93%)"
                                fxFlex.lt-md="0 1 calc(93%)" fxFlex.lt-sm="93%"
                                style="height: auto;margin-left: 1%;margin-top: 1%;">
                                <span>Student Details</span>
                            </div>
                            <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start"
                                id="left-content" appearance="outline" fxFlex="0 1 calc(5%)" fxFlex.lt-md="0 1 calc(5%)"
                                fxFlex.lt-sm="5%" style="height: auto;margin-left: 1%;margin-top: 1%;">
                                <button mat-raised-button type="button"
                                    style="    background-color: #e6f0ff; color: #0d6efd;"
                                    [routerLink]="['/vt-student', 'edit', studentDetailsData.StudentId]">
                                    Edit
                                </button>
                            </div>
                        </mat-card>
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(49%)" fxFlex.lt-md="0 1 calc(49%)" fxFlex.lt-sm="49%"
                            style="height: auto;margin-left: 1%;margin-top: 1%;">
                            <span class="wi">Student Full Name</span><br>
                            <span class="fontW">{{studentDetailsData.FullName}}</span>
                        </div>
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(49%)" fxFlex.lt-md="0 1 calc(49%)" fxFlex.lt-sm="49%"
                            style="height: auto;margin-left: 1%;margin-top: 1%;">
                            <span class="wi">Student Gender</span> <br>
                            <span class="fontW"> {{studentDetailsData.GenderName}}</span>

                        </div>
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(49%)" fxFlex.lt-md="0 1 calc(49%)" fxFlex.lt-sm="49%"
                            style="height: auto;margin-left: 1%;margin-top: 1%;">
                            <span class="wi"> Student Mobile </span><br>
                            <span class="fontW"> {{studentDetailsData.Mobile}}</span>
                        </div>
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(49%)" fxFlex.lt-md="0 1 calc(49%)" fxFlex.lt-sm="49%"
                            style="height: auto;margin-left: 1%;margin-top: 1%;">
                            <span class="wi">Email Id</span><br>
                            <span class="fontW">{{studentDetailsData.EmailId}}</span>

                        </div>
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(49%)" fxFlex.lt-md="0 1 calc(49%)" fxFlex.lt-sm="49%"
                            style="height: auto;margin-left: 1%;margin-top: 1%;">
                            <span class="wi">Father's Name</span><br>
                            <span class="fontW">{{studentDetailsData.FatherName}}</span>

                        </div>
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(49%)" fxFlex.lt-md="0 1 calc(49%)" fxFlex.lt-sm="49%"
                            style="height: auto;margin-left: 1%;margin-top: 1%;">
                            <span class="wi">Father's Mobile No.</span> <br>
                            <span class="fontW">{{studentDetailsData.FatherMobile}}</span>

                        </div>
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(49%)" fxFlex.lt-md="0 1 calc(49%)" fxFlex.lt-sm="49%"
                            style="height: auto;margin-left: 1%;margin-top: 1%;">
                            <span class="wi">Mother's Name</span><br>
                            <span class="fontW">{{studentDetailsData.MotherName}}</span>

                        </div>
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(49%)" fxFlex.lt-md="0 1 calc(49%)" fxFlex.lt-sm="49%"
                            style="height: auto;margin-left: 1%;margin-top: 1%;">
                            <span class="wi">Mother's Mobile No.</span><br>
                            <span class="fontW"> {{studentDetailsData.MotherMobile}}</span>

                        </div>
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(99%)" fxFlex.lt-md="0 1 calc(99%)" fxFlex.lt-sm="99%"
                            style="height: auto;margin-left: 1%;margin-top: 1%;">
                            <span class="wi"> Address</span><br>
                            <span class="fontW">{{studentDetailsData.Address1}}{{studentDetailsData.Address2}}
                                {{studentDetailsData.Address3}}</span>

                        </div>


                    </div>
                </div>

                <!-- / Academic Details -->
                <div #point_1 fxLayout="row wrap" fxLayout.lt-sm="column" appearance="outline" fxFlex="100"
                    fxFlex.lt-md="100" fxFlex.lt-sm="100">
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                        appearance="outline" fxFlex="0 1 calc(29%)" fxFlex.lt-md="0 1 calc(29%)" fxFlex.lt-sm="29%"
                        style="height: auto;margin-left: 1%;margin-top: 1%;" *ngIf="studentDetailsData">


                    </div>
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                        appearance="outline" fxFlex="0 1 calc(68%)" fxFlex.lt-md="0 1 calc(68%)" fxFlex.lt-sm="68%"
                        style="height: auto;margin-left: 1%;margin-top: 1%;"
                        class="position-relative rounded-12 border border-gray-300 " *ngIf="studentDetailsData">
                        <mat-card style="width:100% ;font-weight: 900;">Academic Details </mat-card>
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(99%)" fxFlex.lt-md="0 1 calc(99%)" fxFlex.lt-sm="99%"
                            style="height: auto;margin-left: 1%;margin-top: 1%;">
                            <span class="wi">School's Name</span><br>
                            <span class="fontW">{{studentDetailsData.SchoolName}}</span>

                        </div>
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(32%)" fxFlex.lt-md="0 1 calc(32%)" fxFlex.lt-sm="32%"
                            style="height: auto;margin-left: 1%;margin-top: 1%;">
                            <span class="wi">Class</span>

                            <br>
                            <span class="fontW">{{studentDetailsData.ClassName}}</span>
                        </div>
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(32%)" fxFlex.lt-md="0 1 calc(32%)" fxFlex.lt-sm="32%"
                            style="height: auto;margin-left: 1%;margin-top: 1%;">
                            <span class="wi">Vocational Subject
                            </span><br>
                            <span class="fontW">{{studentDetailsData.VocationalSubject}}</span>

                        </div>
                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(32%)" fxFlex.lt-md="0 1 calc(32%)" fxFlex.lt-sm="32%"
                            style="height: auto;margin-left: 1%;margin-top: 1%;">



                            <span class="wi"> School's Contact No.</span><br>
                            <span class="fontW"> {{studentDetailsData.SchoolContact}}</span>
                        </div>

                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(99%)" fxFlex.lt-md="0 1 calc(99%)" fxFlex.lt-sm="99%"
                            style="height: auto;margin-left: 1%;margin-top: 1%;">
                            <span class="wi">School's Address</span><br>
                            <span class="fontW">{{studentDetailsData.SchoolAddress}}</span>

                        </div>



                    </div>
                </div>
            </div>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>