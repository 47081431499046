import { Component, OnInit, NgZone, ViewEncapsulation, ViewChild, Inject } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmployerListingService } from '../employer-listing.service';

import { EmployerListingModel, EmployerListingBranchModel, EmployerListingSectorsModel } from '../employer-listing.model';

import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';


import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { environment } from 'environments/environment';


@Component({
  selector: 'student',
  templateUrl: './employer-details.component.html',
  styleUrls: ['./employer-details.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class EmployerDetailsComponent extends BaseListComponent<EmployerListingModel> implements OnInit {
  InternshipsByEmployerData: any;
  empDetailsData: any;
  DailyDiaryImageAttachments: any;
  StudentDashboardInternshipData: any;
  DailyDiaryEntryByStudentId: any;
  RequestsByStudentIdRequestType: any;
  StudentInternshipsByCriteria: any;
  RejectInternshipApplication: any;
  studentFilterForm: FormGroup;
  tabIndex = 0;
  img = this.Constants.CommonImageUrl.Account;
  empId: any

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private translationLoaderService: FuseTranslationLoaderService,
    public zone: NgZone,
    private dialogService: DialogService,
    private formBuilder: FormBuilder,
    private employerListingService: EmployerListingService,
    @Inject(DOCUMENT) document: Document) {
    super(commonService, router, routeParams, snackBar, zone);
  }

  ngOnInit(): void {
    var key = true;
    sessionStorage.setItem('key', JSON.stringify(key));
    this.routeParams.paramMap.subscribe(params => {
      if (params.keys.length > 0) {
        this.empId = params.get('EmployerId');
      }
    });


    this.GetInternshipsByEmployerId();
    this.GetEmployersById();
    this.GetActiveInternsByEmployerId()
  }

  GetInternshipsByEmployerId() {
    let data = {
      "DataId": this.empId //DataId is EmployerId (selected employerId)

    }

    this.employerListingService.GetInternshipsByEmployerId(data).subscribe(response => {
      this.InternshipsByEmployerData = response.Results;
      console.log(this.InternshipsByEmployerData)
      this.IsLoading = false;
    }, error => {
      console.log(error);
    });
  }

  GetEmployersById() {
    let data = {
      DataId : this.empId //DataId is EmployerId (selected employerId)
    }

    this.employerListingService.GetEmployersById(data).subscribe(response => {
      
      this.empDetailsData = response.Result;
      console.log(this.empDetailsData)
      this.empDetailsData.EmployersBranches.map(item =>{
        if(item.IsMainBranch === true){
          this.empDetailsData.BranchAddress=item.BranchAddress;
          this.empDetailsData.POCEmailId=item.POCEmailId;
          this.empDetailsData.POCMobile=item.POCMobile;
        }
      })
      this.IsLoading = false;
    }, error => {
      console.log(error);
    });
  }

  GetActiveInternsByEmployerId() {

    // let data = {
    //   "UserId": "rakesh.gtmcs",
    //   "UserTypeId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //   "Name": null,
    //   "CharBy": null,
    //   "RequestFrom": null,
    //   "PageIndex": 0,
    //   "PageSize": 10,
    //   "SchoolGuid": null,
    //   "AcademicYearGuid": null,
    //   "InternshipGuid": null,
    //   "SectorGuid": null,
    //   "EmployerGuid": "eeeeeeee-140b-4af8-bade-679404fd3790",
    //   "ClassGuid": null,
    //   "StartDateTime": null,
    //   "EndDatetime": null,
    //   "IgnoreCriteria": true
    // }
    this.SearchBy.EmployerGuid = this.empId
    this.employerListingService.GetActiveInternsByEmployerId(this.SearchBy).subscribe(response => {
      this.displayedColumns = ['STUDENT', 'ATTENDANCE', 'HOURSSPENT', 'Actions'];
      this.tableDataSource.data = response.Results;
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;
      this.SearchBy.TotalResults = response.TotalResults;
      setTimeout(() => {
        this.ListPaginator.pageIndex = this.SearchBy.PageIndex;
        this.ListPaginator.length = this.SearchBy.TotalResults;
      });
      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }
  onPageIndexChanged(evt) {
    this.SearchBy.PageIndex = evt.pageIndex;
    this.SearchBy.PageSize = evt.pageSize;

    this.GetActiveInternsByEmployerId();
  }

  onDeleteSchool(StudentId: string) {
    this.dialogService
      .openConfirmDialog(this.Constants.Messages.DeleteConfirmationMessage)
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          this.employerListingService.DeleteEmployersById(StudentId)
            .subscribe((schoolResp: any) => {

              this.zone.run(() => {
                if (schoolResp.Success) {
                  this.showActionMessage(
                    this.Constants.Messages.RecordDeletedMessage,
                    this.Constants.Html.SuccessSnackbar
                  );
                }
                this.ngOnInit();
              }, error => {
                console.log('School deletion errors =>', error);
              });

            });
          this.ngOnInit();
        }
      });
  }

  changeTab(event) {
    console.log(event.index)
    this.tabIndex = event.index;
  }

  scrollPoint1(el: HTMLElement) {
    console.log(el)
    // el.scrollIntoView({behavior: "smooth"});
    el.scrollIntoView();
  }



}
