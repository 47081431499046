import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { RouteConstants } from 'app/constants/route.constant'
import { EmployerStudentMappingService } from '../employer-student-mapping.service';
import { EmployerStudentMappingModel } from '../employer-student-mapping.model';
import { DropdownModel } from 'app/models/dropdown.model';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'employer-student-mapping',
  templateUrl: './create-employer-student-mapping.component.html',
  styleUrls: ['./create-employer-student-mapping.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CreateEmployerStudentMappingComponent extends BaseComponent<EmployerStudentMappingModel> implements OnInit {
  employerStudentMappingForm: FormGroup;
  employerStudentMappingModel: EmployerStudentMappingModel;
  vtpList: [DropdownModel];
  natureOfAppointmentList: [DropdownModel];
  genderList: [DropdownModel];
  academicyearList: [DropdownModel];
  stateCodeList: [DropdownModel];
  vtpId: string;
  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private route: ActivatedRoute,
    private employerStudentMappingService: EmployerStudentMappingService,
    private dialogService: DialogService,
    private formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Set the default employerStudentMapping Model
    this.employerStudentMappingModel = new EmployerStudentMappingModel();
  }

  ngOnInit(): void {
    if (this.PageRights.IsAdd) {
      this.PageRights.IsEdit = false;
    }
    this.employerStudentMappingService.getDropdownforEmployerStudentMappings(this.UserModel).subscribe((results: any) => {
      if (results[0].Success) {
        this.academicyearList = results[0].Results;
      }
      if (results[1].Success) {
        this.stateCodeList = results[1].Results;
        for (const i of results[1].Results) {
          this.employerStudentMappingForm.get('StateCode').setValue(i.Id);
        }
      }

      this.route.paramMap.subscribe(params => {
        if (params.keys.length > 0) {
          this.PageRights.ActionType = params.get('actionType');

          if (this.PageRights.ActionType == this.Constants.Actions.New) {
            this.employerStudentMappingModel = new EmployerStudentMappingModel();

          } else {
            var configId: string = params.get('configId');
            // var vtpId: string = params.get('vtpId');
            // this.vtpId = params.get('vtpId');

            this.employerStudentMappingService.getEmployerStudentMappingById(configId)
              .subscribe((response: any) => {

                this.employerStudentMappingModel = response.Result;
                if (this.PageRights.ActionType == this.Constants.Actions.Edit) {
                  this.PageRights.IsEdit = true;
                  // this.employerStudentMappingForm.controls['AcademicYearId'].disable();
                  this.employerStudentMappingModel.RequestType = this.Constants.PageType.Edit;
                }
                else if (this.PageRights.ActionType == this.Constants.Actions.View) {
                  this.employerStudentMappingModel.RequestType = this.Constants.PageType.View;
                  this.PageRights.IsReadOnly = true;
                }

                this.employerStudentMappingForm = this.createEmployerStudentMappingForm();
              });
          }
        }
      });
    });

    this.employerStudentMappingForm = this.createEmployerStudentMappingForm();
  }

  saveOrUpdateEmployerStudentMappingDetails() {
    if (!this.employerStudentMappingForm.valid) {
      this.validateAllFormFields(this.employerStudentMappingForm);
      return;
    }

    this.setValueFromFormGroup(this.employerStudentMappingForm, this.employerStudentMappingModel);

    // this.employerStudentMappingModel.AcademicYearId = this.employerStudentMappingForm.getRawValue().AcademicYearId;
    this.employerStudentMappingService.createOrUpdateEmployerStudentMapping(this.employerStudentMappingModel)
      .subscribe((employerStudentMappingResp: any) => {

        if (employerStudentMappingResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );

            this.router.navigate([RouteConstants.EmployerStudentMapping.List]);
          });
        }
        else {
          var errorMessages = this.getHtmlMessage(employerStudentMappingResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('Error in employer student mapping =>', error);
      });
  }

  //Create employerStudentMapping form and returns {FormGroup}
  createEmployerStudentMappingForm(): FormGroup {
    return this.formBuilder.group({
      ConfigId: new FormControl(this.employerStudentMappingModel.ConfigId),
      AcademicYearId: new FormControl({ value: this.employerStudentMappingModel.AcademicYearId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      StateCode: new FormControl({ value: this.employerStudentMappingModel.StateCode, disabled: true }, Validators.required),
      IsActive: new FormControl({ value: this.employerStudentMappingModel.IsActive, disabled: this.PageRights.IsReadOnly }),
      IsDirectMappingEnabled: new FormControl({ value: this.employerStudentMappingModel.IsDirectMappingEnabled, disabled: this.PageRights.IsReadOnly }),
    });
  }
}
