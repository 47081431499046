import { Component, Inject, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { BaseComponent } from 'app/common/base/base.component';
import { InternshipOpportunity } from 'app/main/internship-opportunity/internship-opportunity.model';
import { CommonService } from 'app/services/common.service';
import { environment } from 'environments/environment';
import { InternshipAssignmentsRequestService } from '../internship-assignments.service';
import { DropdownModel } from 'app/models/dropdown.model';

@Component({
  selector: 'app-edit-internship-assignments',
  templateUrl: './edit-internship-assignments.component.html',
  styleUrls: ['./edit-internship-assignments.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class EditInternshipAssignmentsComponent extends BaseComponent<InternshipOpportunity> implements OnInit {
  passDatatoChild: any[];
  isLoading: boolean = true;
  errorMessage: any;
  studentInternshipDataById = [] as any;
  studentId: any;
  internshipId: any;
  reportData: any;
  certificate: any;
  bestPicture: any;
  reportPhotos = [] as any;
  btnHide: boolean = false;
  url = this.Constants.CommonImageUrl.FinalInternshipReports;
  isReviewReport: boolean = false;
  isIRReviewed: any;
  isCompleted: any;
  interList: [DropdownModel];
  sectorList: [DropdownModel];
  filteredEmpItems: any;

  myDate:any;
  internshipTypeList = [{ Id: 'Online', Name: 'Online' }, { Id: 'Offline', Name: 'Offline' }, { Id: 'Hybrid', Name: 'Hybrid' }, { Id: 'Internship@School', Name: 'Internship @School' }, { Id: 'Internship @Home', Name: 'Internship @Home' }];
  timeList = [{ Id: '7:00AM', Name: '7:00AM' }, { Id: '7:30AM', Name: '7:30AM' }, { Id: '8AM', Name: '8:00AM' }, { Id: '8:30AM', Name: '8:30AM' }, { Id: '9:00AM', Name: '9:00AM' }, { Id: '9:30AM', Name: '9:30AM' },{ Id: '10:00AM', Name: '10:00AM' },{ Id: '10:30AM', Name: '10:30AM' },{ Id: '11:00AM', Name: '11:00AM' },{ Id: '11:30AM', Name: '11:30AM' },{ Id: '12:00PM', Name: '12:00PM' },{ Id: '12:30PM', Name: '12:30PM' },{ Id: '1:00PM', Name: '1:00PM' },{ Id: '1:30PM', Name: '1:30PM' },{ Id: '2:00PM', Name: '2:00PM' },{ Id: '2:30PM', Name: '2:30PM' },{ Id: '3:00PM', Name: '3:00PM' },{ Id: '3:30PM', Name: '3:30PM' },{ Id: '4:00PM', Name: '4:00PM' },{ Id: '4:30PM', Name: '4:30PM' },{ Id: '5:00PM', Name: '5:00PM' },{ Id: '5:30PM', Name: '5:30PM' },{ Id: '6:00PM', Name: '6:00PM' },{ Id: '6:30PM', Name: '6:30PM' },{ Id: '7:00PM', Name: '7:00PM' }, { Id: '7:30PM', Name: '7:30PM' }, { Id: '8PM', Name: '8:00PM' }, { Id: '8:30PM', Name: '8:30PM' }, { Id: '9:00PM', Name: '9:00PM' }, { Id: '9:30PM', Name: '9:30PM' },{ Id: '10:00PM', Name: '10:00PM' },{ Id: '10:30PM', Name: '10:30PM' },{ Id: '11:00PM', Name: '11:00PM' },{ Id: '11:30PM', Name: '11:30PM' },{ Id: '12:00PM', Name: '12:00PM' }];


  
  constructor(
    public internshipAssignmentsRequestService: InternshipAssignmentsRequestService,
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditInternshipAssignmentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(commonService, router, routeParams, snackBar);
    // dialogRef.disableClose = true;
  }
  ngOnInit() {
    // var data={
    //   "UserTypeId": this.UserModel.UserTypeId,
    //   "SchoolGuid": (this.Constants.GlobalSchoolId == 'undefined' || this.Constants.GlobalSchoolId == 'null') ? null : this.Constants.GlobalSchoolId,
    //   "AcademicYearGuid": (this.Constants.GlobalAcademicYearId == 'undefined' || this.Constants.GlobalAcademicYearId == 'null') ? null : this.Constants.GlobalAcademicYearId,
    //   "SectorGuid": null,
    //   "RoleCode": this.UserModel.RoleCode,
    //   "EmployerGuid": null,
    //   "PageIndex": 0,
    //   "PageSize": 10000,
    //   "IsCriterion": true
    // }
    //   this.internshipAssignmentsRequestService.GetInternshipByActiveCriteria(data).subscribe(response => {
    //     this.interList=response.Results
    //     this.IsLoading = false;
    //   }, error => {
    //     console.log(error);
    //     this.IsLoading = false;
    //   });
    this.commonService.GetMasterDataByType({ DataType: 'EmployersForVT', UserId: this.UserModel.UserTypeId, ParentId: null,  }).subscribe((response: any) => {
      this.interList = response.Results;
      this.filteredEmpItems = this.interList.slice();

      console.log( this.interList);
    });
    console.log(this.data);
    this.myDate=this.getDateValue(this.data.InternshipStartDate)
    // this.reviewInternshipReportForm.controls['EmployerId'].patchValue(this.data.EmployerId);
    this.reviewInternshipReportForm.controls['EmployerId'].patchValue(this.data.EmployerName);
    // this.onChange(this.data.EmployerId)
    // this.reviewInternshipReportForm.controls['Sector'].patchValue(this.data.SectorId);
    this.reviewInternshipReportForm.controls['Sector'].patchValue(this.data.SectorName);
    this.reviewInternshipReportForm.controls['InternshipType'].patchValue(this.data.InternshipType);
    this.reviewInternshipReportForm.controls['CommencementDate'].patchValue(this.getDateValue(this.data.CommencementDate));
    this.reviewInternshipReportForm.controls['StartDate'].patchValue(this.data.StartTime);
    this.reviewInternshipReportForm.controls['EndDate'].patchValue(this.data.EndTime);
   
  
  }
 
  onClose() {
    this.dialog.closeAll();
  }

  onSubmit(reviewInternshipReportForm) {
    this.dialogRef.close({Interns:this.data, CommencementDate:reviewInternshipReportForm.controls['CommencementDate'].value ,data:reviewInternshipReportForm});
  }

  onChange(data: any) {
    console.log(data);
   this.commonService.GetMasterDataByType({ DataType: 'SectorsForEmp', UserId: this.UserModel.UserTypeId, ParentId: data,  }).subscribe((response: any) => {
     this.sectorList = response.Results;
     for (let i = 0; i < this.sectorList.length; i++) {
      if (this.sectorList[i].Id ) { 
        this.reviewInternshipReportForm.controls['Sector'].patchValue(this.sectorList[i].Id);
      }
    }
     console.log( this.interList);
   });
  }
  reviewInternshipReportForm: FormGroup = new FormGroup({
    CommencementDate: new FormControl("", Validators.required),
    EmployerId: new FormControl("", Validators.required),
    Sector : new FormControl("", Validators.required),
    StartDate: new FormControl("", [ Validators.pattern(this.Constants.Regex.Number)]),
    EndDate: new FormControl("", [ Validators.pattern(this.Constants.Regex.Number)]),
    InternshipType: new FormControl("", Validators.required),

  });

  

}
