import { Component, OnInit, NgZone, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SchoolModel } from './school.model';
import { SchoolService } from './school.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as guarati } from './i18n/gj';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

@Component({
  selector: 'data-list-view',
  templateUrl: './school.component.html',
  styleUrls: ['./school.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class SchoolComponent extends BaseListComponent<SchoolModel> implements OnInit {
  schoolForm: FormGroup;
  schoolFilterForm: FormGroup;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private translationLoaderService: FuseTranslationLoaderService,
    public zone: NgZone,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private schoolService: SchoolService) {
    super(commonService, router, routeParams, snackBar, zone);
    this.translationLoaderService.loadTranslations(english, guarati);
    this.schoolFilterForm = this.createSchoolFilterForm();
  }

  ngOnInit(): void {
    this.SearchBy.PageIndex = 0; // delete after script changed
    this.SearchBy.PageSize = 100000; // delete after script changed

    this.schoolForm = this.formBuilder.group({ filterText: '' });

    //Load initial schools data
    this.onLoadSchoolsByCriteria();

    this.schoolForm.get('filterText').valueChanges.pipe(
      // if character length greater then 2
      filter(res => {
        this.SearchBy.PageIndex = 0;

        if (res.length == 0) {
          this.SearchBy.Name = '';
          this.onLoadSchoolsByCriteria();
          return false;
        }

        return res.length > 2
      }),

      // Time in milliseconds between key events
      debounceTime(650),

      // If previous query is diffent from current   
      distinctUntilChanged()

      // subscription for response
    ).subscribe((searchText: string) => {
      this.SearchBy.Name = searchText;
      this.onLoadSchoolsByCriteria();
    });
  }

  ngAfterViewInit() {
    this.tableDataSource.paginator = this.ListPaginator;
  }



  onPageIndexChanged(evt) {
    this.SearchBy.PageIndex = evt.pageIndex;
    this.SearchBy.PageSize = evt.pageSize;

    this.onLoadSchoolsByCriteria();
  }

  onLoadSchoolsByCriteria(): any {
    this.IsLoading = true;
    
    this.SearchBy.IsActive =this.schoolFilterForm.controls["Status"].value == 'true' ? true : this.schoolFilterForm.controls["Status"].value == 'false' ? false : this.schoolFilterForm.controls["Status"].value;
    this.SearchBy.IsImplemented = this.schoolFilterForm.controls["StatusOfImplemented"].value=='true'?true:this.schoolFilterForm.controls["StatusOfImplemented"].value=='false'?false:this.schoolFilterForm.controls["StatusOfImplemented"].value;
  

    this.schoolService.GetAllByCriteria(this.SearchBy).subscribe(response => {
      this.displayedColumns = ['SchoolName', 'CategoryName', 'Udise', 'DivisionName', 'DistrictName', 'BlockName', 'IsImplemented', 'IsActive', 'Actions'];

      this.tableDataSource.data = response.Results;
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;
      this.SearchBy.TotalResults = response.TotalResults;

      setTimeout(() => {
        this.ListPaginator.pageIndex = this.SearchBy.PageIndex;
        this.ListPaginator.length = this.SearchBy.TotalResults;
      });

      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  onLoadSchoolByFilters(): any {
    this.SearchBy.PageIndex = 0;
    this.onLoadSchoolsByCriteria();
  }

  resetFilters(): void {
    this.SearchBy.PageIndex = 0;
    this.schoolFilterForm.reset();
    //this.schoolFilterForm.get('AcademicYearId').setValue(this.AcademicYearId);

    this.onLoadSchoolsByCriteria();
  }

  onDeleteSchool(schoolId: string) {
    this.dialogService
      .openConfirmDialog(this.Constants.Messages.DeleteConfirmationMessage)
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          this.schoolService.deleteSchoolById(schoolId)
            .subscribe((schoolResp: any) => {

              this.zone.run(() => {
                if (schoolResp.Success == true) {
                  this.showActionMessage(
                    //this.Constants.Messages.RecordDeletedMessage,
                    schoolResp.Messages,
                    this.Constants.Html.SuccessSnackbar
                  );
                } else {
                  this.showActionMessage(
                    schoolResp.Result,
                    this.Constants.Html.SuccessSnackbar
                  );
                  this.ngOnInit();

                }
                this.ngOnInit();
              }, error => {
                console.log('School deletion errors =>', error);
              });

            });
          this.ngOnInit();
        }
      });
  }

  exportExcel(): void {
    this.IsLoading = true;
    this.SearchBy.PageIndex = 0;
    this.SearchBy.PageSize = 10000;

    this.schoolService.GetAllByCriteria(this.SearchBy).subscribe(response => {
      response.Results.forEach(
        function (obj) {
          if (obj.hasOwnProperty('IsActive')) {
            obj.IsActive = obj.IsActive ? 'Yes' : 'No';
          }
          if (obj.hasOwnProperty('IsImplemented')) {
            obj.IsImplemented = obj.IsImplemented ? 'Yes' : 'No';
          }
          delete obj.SchoolId;
          delete obj.TotalRows;
        });

      this.exportExcelFromTable(response.Results, "Schools");

      this.IsLoading = false;
      this.SearchBy.PageIndex = 0;
      this.SearchBy.PageSize = 10;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  //Create School form and returns {FormGroup}
  createSchoolFilterForm(): FormGroup {
    return this.formBuilder.group({
      StatusOfImplemented: new FormControl(),
      Status: new FormControl()
    });
  }

}
