import { Component, OnInit, NgZone, ViewEncapsulation, ElementRef } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { FuseUtils } from '@fuse/utils';
import { RouteConstants } from 'app/constants/route.constant'

import { DropdownModel } from 'app/models/dropdown.model';
import { FileUploadModel } from 'app/models/file.upload.model';
import { ReportService } from 'app/reports/report.service';
import { duration } from 'moment';
import { StudentRightsService } from '../student-rights.service';
import { StudentRightsModel } from '../student-rights.model';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import BasicStyles from '@ckeditor/ckeditor5-basic-styles';
import MyCustomUploadAdapterPlugin from 'app/main/test';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageResizeEditing from '@ckeditor/ckeditor5-image/src/imageresize/imageresizeediting';
import ImageResizeHandles from '@ckeditor/ckeditor5-image/src/imageresize/imageresizehandles';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Code from '@ckeditor/ckeditor5-basic-styles/src/code';
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript';
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript';

// DecoupledEditor
//     .create( document.querySelector( '#editor' ), {
//         plugins: [ Image, ImageResizeEditing, ImageResizeHandles, /* ... */ ],
//         // More of editor's config.
//         // ...
//     } )
//     .then( /* ... */ )
//     .catch( /* ... */ );

@Component({
  selector: 'app-create-student-rights',
  templateUrl: './create-student-rights.component.html',
  styleUrls: ['./create-student-rights.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CreateStudentRightsComponent extends BaseComponent<StudentRightsModel> implements OnInit {
  studentRightsForm: FormGroup;
  studentRightsModel: StudentRightsModel;
  id: string;
  categoryList: any = [];
  stateCodeList: [DropdownModel];
  title = 'angular-ckeditor-test';
  //public editor = DecoupledEditor;
  //public editor = ClassicEditor;
  public editor = DecoupledEditor;
  config = { extraPlugins: [MyCustomUploadAdapterPlugin] }
  public onReady(editor) {
  
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }

  

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    public zone: NgZone,
    private route: ActivatedRoute,
    private reportService: ReportService,
    private studentRightsService: StudentRightsService,
    private dialogService: DialogService,
    private elRef: ElementRef,
    private formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Set the default account Model

    this.studentRightsModel = new StudentRightsModel();
  }

  ngOnInit(): void {
    // DecoupledEditor
    // .create( document.querySelector( '#editor' ), {
    //     plugins: [ Image, ImageResizeEditing, ImageResizeHandles , Bold, Italic, Underline, Strikethrough, Code, Subscript, Superscript ],
        
    //   toolbar: [
    //       'heading', 'bulletedList', 'numberedList', 'fontFamily', 'undo', 'redo'
    //   ]
    //     // More of editor's config.
    //     // ...
    // } )
    // .then( /* ... */ )
    // .catch( /* ... */ );

    this.studentRightsService.getDropdownforSchool(this.UserModel).subscribe((results) => {
   
      if (results[13].Success) {
        this.categoryList = results[13].Results;
      }
      if (results[5].Success) {
        this.stateCodeList = results[5].Results;
       
      }

      this.route.paramMap.subscribe(params => {

        if (params.keys.length > 0) {
          this.PageRights.ActionType = params.get('actionType');

          if (this.PageRights.ActionType == this.Constants.Actions.New) {
            this.studentRightsForm = this.createStudentRightsForm();
            this.studentRightsModel = new StudentRightsModel();

          } else {
            var resourceId: string = params.get('ResourceId');

            this.studentRightsService.GetResourceStudentRightsById(resourceId)
              .subscribe((response: any) => {
                this.studentRightsModel = response.Result;
                if (this.PageRights.ActionType == this.Constants.Actions.Edit)
                  this.studentRightsModel.RequestType = this.Constants.PageType.Edit;
                else if (this.PageRights.ActionType == this.Constants.Actions.View) {
                  this.studentRightsModel.RequestType = this.Constants.PageType.View;
                  this.PageRights.IsReadOnly = true;
                }
                this.studentRightsForm = this.createStudentRightsForm();
              });
          }
        }
      });
    });
    this.studentRightsForm = this.createStudentRightsForm();

    
  }

  saveOrCreateOrUpdateStudentRights() {
    if (this.stateCodeList) {
      for (const i of this.stateCodeList) {
        this.studentRightsForm.get('StateCode').setValue(i.Id);
        this.studentRightsForm.get('CreatedBy').setValue(this.UserModel.UserId);

      }
    }
    

    if (!this.studentRightsForm.valid) {
      this.validateAllFormFields(this.studentRightsForm);
      return;
    }

    this.setValueFromFormGroup(this.studentRightsForm, this.studentRightsModel);
    this.studentRightsModel.AuthUserId = this.UserModel.UserId;
    this.studentRightsModel.ResourceType = 'StudentRights'
    let categoryName: any = this.categoryList.filter(x => x.Id == this.studentRightsModel.ResourceCategoryId);
    this.studentRightsModel.Category = categoryName[0].Name;

    this.studentRightsService.CreateStudentRightsResources(this.studentRightsModel)
      .subscribe((accountResp: any) => {

        if (accountResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );
            this.router.navigate([RouteConstants.StudentRights.List]);

          });
        }
        else {
          var errorMessages = this.getHtmlMessage(accountResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('StudentNoticeBoard deletion errors =>', error);
      });
  }

  //Create schoolClass form and returns {FormGroup}
  createStudentRightsForm(): FormGroup {
    return this.formBuilder.group({
      PublishDate: new FormControl({ value: new Date(this.studentRightsModel.PublishDate), disabled: this.PageRights.IsReadOnly }, Validators.required),
      IsActive: new FormControl({ value: this.studentRightsModel.IsActive, disabled: this.PageRights.IsReadOnly }),
      ResourceTitle: new FormControl({ value: this.studentRightsModel.ResourceTitle, disabled: this.PageRights.IsReadOnly }),
      Sources: new FormControl(),
      SubCategoery: new FormControl(),
      ResourceDescription: new FormControl({ value: this.studentRightsModel.ResourceDescription, disabled: this.PageRights.IsReadOnly }),
      ResourceType: new FormControl({ value: this.studentRightsModel.ResourceType, disabled: this.PageRights.IsReadOnly }),
      ResourceCategoryId: new FormControl({ value: this.studentRightsModel.ResourceCategoryId, disabled: this.PageRights.IsReadOnly }),
      StateCode: new FormControl({ value: this.studentRightsModel.StateCode }),
      CreatedBy: new FormControl({ value: this.studentRightsModel.CreatedBy }),


    });
  }





}


