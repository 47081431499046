<div id="products" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <!-- {{ 'Internship Opportunity' | translate }} -->
                    Internship Opportunity
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}"
                            style="color: #a4ef13;">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <form [formGroup]="internshipOpportunityForm">
                    <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon>search</mat-icon>
                        <input #filter (keyup)="applySearchFilter($event.target.value)"
                        placeholder="Search for a Internship Opportunity">
                        <!-- <input formControlName="filterText" placeholder="Search for a Internship Opportunity"> -->
                    </div>
                </form>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">
                <button mat-raised-button [routerLink]="['./','new']" skipLocationChange="true" replaceUrl="false"
                    class="add-internship-opportunity-button fuse-white mt-24 mt-md-0 btn-warning" *ngIf="UserModel.RoleCode != 'VC'">
                    <!-- <span>{{'SCHOOL.ADD_NEW_SCHOOL' | translate}}</span> -->
                    <span>Add New Opportunity</span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="ms-2" width="20" height="20" viewBox="0 0 24 24"
                        stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="12" y1="5" x2="12" y2="19" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                    </svg>
                </button>

                <!--<a *ngIf="PageRights.IsExport" class="export-excel"
                   (click)="exporter.exportTable('xlsx', {fileName:'Schools', sheet: 'Schools', Props: {Author: 'Lighthouse'}})">
                    <mat-icon> cloud_download</mat-icon>
                </a>-->

                <!-- <a *ngIf="PageRights.IsExport" class="export-excel" (click)="exportExcel()">
                    <mat-icon> cloud_download</mat-icon>
                </a> -->
            </div>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->
        <div class="padding-bottom">
            <mat-tab-group selectedIndex="0" (selectedTabChange)="changeTab($event)">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Active</span>
                    </ng-template>

                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Completed</span>
                    </ng-template>

                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Unpublished</span>
                    </ng-template>

                </mat-tab>
            </mat-tab-group>
        </div>
        <!-- Filter Section -->
        <div class="filter-content-card">
            <div class="filter-content">
                <form name="internshipOpportunityFilterForm" [formGroup]="internshipOpportunityFilterForm"
                    class="vc-issue-report" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Sector</mat-label>
                            <mat-select formControlName="SectorId" name="sectorId" appearance="outline">
                                <mat-option [value]="sectorItem.Id" *ngFor="let sectorItem of sectorList">
                                    {{ sectorItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label> Employer</mat-label>

                            <mat-select formControlName="EmployerId" name="employerId" appearance="outline"
                                #SelectEmployer>
                                <mat-select-filter class="select-filter" *ngIf="SelectEmployer.focused"
                                    [array]="employerList" (filteredReturn)="this.filteredEmployerItems = $event"
                                    [displayMember]="'Name'">
                                </mat-select-filter>

                                <mat-option [value]="employerItem.Id"
                                    *ngFor="let employerItem of filteredEmployerItems">
                                    {{ employerItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Duration</mat-label>
                            <mat-select formControlName="DurationId" name="durationId" appearance="outline"
                                (selectionChange)="onChangeDuration($event.value)">
                                <mat-option [value]="item.Id" *ngFor="let item of durationList">
                                    {{ item.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        
                        <div class="clear-filter pr-1" fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="onLoadSchoolVTPSectorsByFilters()"><span>SEARCH</span></button>
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="resetFilters()"><span>CLEAR</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- /Filter Section -->

        <!-- CONTENT CARD -->
        <div class="content-card" *ngIf="tabIndex === 0">

            <!--<mat-table matTableExporter class="internship-opportunity-table responsive-table" [dataSource]="tableDataSource"
                    #exporter="matTableExporter" [hiddenColumns]="[8]" matSort fusePerfectScrollbar
                    [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">-->

            <mat-table class="internship-opportunity-table responsive-table" [dataSource]="tableDataSource" matSort
                fusePerfectScrollbar [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- Title Column -->
                <ng-container matColumnDef="Title">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Post
                        <!--  Title -->
                    </mat-header-cell>
                    <!-- <mat-cell *matCellDef="let internshipOpp" [routerLink]="['/internship-deatils', internshipOpp.InternshipId]" skipLocationChange="true"
                    replaceUrl="true">
                        <p class="text-truncate"> <b>{{internshipOpp.Title}}</b></p> <br>
                        {{internshipOpp.BranchName}}
                    </mat-cell> -->
                    <mat-cell *matCellDef="let internshipOpp"
                        [routerLink]="['/internship-deatils', internshipOpp.InternshipId]">
                        <p class="text-truncate"> <b>{{internshipOpp.Title}}</b></p> <br>
                        {{internshipOpp.BranchName}}
                    </mat-cell>
                </ng-container>

                <!-- EmployerName Column -->
                <ng-container matColumnDef="EmployerName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Employer
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipOpp">
                        <p class="text-truncate">{{internshipOpp.EmployerName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- StartDate Column -->
                <ng-container matColumnDef="StartDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Start Date
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipOpp">
                        <p class="text-truncate">{{internshipOpp.StartDate |date}}</p>
                    </mat-cell>
                </ng-container>

                <!-- InternshipType Column -->
                <ng-container matColumnDef="InternshipType">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Internship Type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipOpp">
                        <p class="text-truncate">{{internshipOpp.InternshipType}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SectorName Column -->
                <ng-container matColumnDef="SectorName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Sector
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipOpp">
                        <p class="text-truncate">{{internshipOpp.SectorName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- WorkType Column -->
                <ng-container matColumnDef="WorkType">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Work Type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipOpp">
                        <p class="text-truncate">{{internshipOpp.WorkType}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Stipend Column -->
                <ng-container matColumnDef="Stipend">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Stipend
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipOpp">
                        <p class="text-truncate">{{internshipOpp.Stipend}}</p>
                    </mat-cell>
                </ng-container>


                <!-- IsActive Column -->
                <!-- <ng-container matColumnDef="IsActive">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
                        Is Active?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipOpp" fxHide fxShow.gt-xs>
                        <mat-icon *ngIf="internshipOpp.IsActive" class="active-icon green-600 s-16">check</mat-icon>
                        <mat-icon *ngIf="!internshipOpp.IsActive" class="active-icon red-500 s-16">close</mat-icon>
                    </mat-cell>
                </ng-container> -->

                <ng-container matColumnDef="Actions" stickyEnd>
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let internshipOpp" fxShow.gt-xs>
                        <!-- <button *ngIf="PageRights.IsView" mat-icon-button
                            [routerLink]="['/internship-opportunity', 'view', internshipOpp.InternshipId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">visibility</mat-icon>
                        </button> -->
                        <button *ngIf="PageRights.IsEdit || UserModel.RoleCode != 'VC'" mat-icon-button 
                            [routerLink]="['/internship-opportunity', 'edit', internshipOpp.InternshipId]">
                            <!-- <mat-icon color="primary" style="color: rgb(0, 101, 254);">edit</mat-icon> -->
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit" width="30"
                                height="30" viewBox="0 0 24 24" stroke-width="1.5" stroke="#0065fe" fill="none"
                                stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"></path>
                                <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3"></path>
                                <line x1="16" y1="5" x2="19" y2="8"></line>
                            </svg>
                        </button>
                        <button mat-icon-button color="warn" (click)="onDeleteInternship(internshipOpp.InternshipId)"
                            *ngIf="PageRights.IsDelete || UserModel.RoleCode != 'VC'">
                            <mat-icon>delete_outline</mat-icon>
                        </button>

                        <!-- <button *ngIf="PageRights.IsView" mat-icon-button
                        [routerLink]="['/internship-opportunity', 'view', internshipOpp.InternshipId]" skipLocationChange="true"
                        replaceUrl="false">
                        <mat-icon color="primary">visibility</mat-icon>
                    </button> -->

                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" *ngIf="UserModel.RoleCode != 'VC'">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item *ngIf="tabIndex === 0"
                                (click)="onClickMarkInternshipCompleted(internshipOpp.InternshipId)">
                                <!-- <mat-icon>dialpad</mat-icon> -->
                                <mat-icon>done</mat-icon>
                                <span>Complete</span>
                            </button>
                            <button mat-menu-item *ngIf="tabIndex === 1">
                                <!-- <mat-icon>voicemail</mat-icon> -->
                                <span>No Actions</span>
                            </button>
                            <button mat-menu-item *ngIf="tabIndex === 2"
                                (click)="onClickPublishInternship(internshipOpp.InternshipId)">
                                <mat-icon>publish</mat-icon>
                                <span>Publish</span>
                            </button>
                        </mat-menu>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <!-- <mat-row *matRowDef="let internshipOpp; columns: displayedColumns;"
                    (click)="navigateIntershipDetails(internshipOpp.InternshipId)" skipLocationChange="true" replaceUrl="true" class="internshipOpp" matRipple>
                </mat-row> -->
                <mat-row *matRowDef="let internshipOpp; columns: displayedColumns;" class="internshipOpp" matRipple>
                </mat-row>
            </mat-table>

            <!-- <mat-paginator #ListPaginator [length]="SearchBy.TotalResults" [pageIndex]="SearchBy.PageIndex"
                [pageSize]="SearchBy.PageSize" [pageSizeOptions]="SearchBy.PageSizeOptions"
                [showFirstLastButtons]="SearchBy.ShowFirstLastButtons" (page)="onPageIndexChanged($event)">
            </mat-paginator> -->
            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100,200]">
            </mat-paginator>

        </div>

        <div class="content-card" *ngIf="tabIndex === 1">

            <!--<mat-table matTableExporter class="internship-opportunity-table responsive-table" [dataSource]="tableDataSource"
                    #exporter="matTableExporter" [hiddenColumns]="[8]" matSort fusePerfectScrollbar
                    [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">-->

            <mat-table class="internship-opportunity-table responsive-table" [dataSource]="tableDataSource" matSort
                fusePerfectScrollbar [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- Title Column -->
                <ng-container matColumnDef="Title">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Post
                        <!--  Title -->
                    </mat-header-cell>
                    <!-- <mat-cell *matCellDef="let internshipOpp" [routerLink]="['/internship-deatils', internshipOpp.InternshipId]" skipLocationChange="true"
                    replaceUrl="true">
                        <p class="text-truncate"> <b>{{internshipOpp.Title}}</b></p> <br>
                        {{internshipOpp.BranchName}}
                    </mat-cell> -->
                    <mat-cell *matCellDef="let internshipOpp"
                        [routerLink]="['/internship-deatils', internshipOpp.InternshipId]">
                        <p class="text-truncate"> <b>{{internshipOpp.Title}}</b></p> <br>
                        {{internshipOpp.BranchName}}
                    </mat-cell>
                </ng-container>


                <!-- EmployerName Column -->
                <ng-container matColumnDef="EmployerName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Employer
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipOpp">
                        <p class="text-truncate">{{internshipOpp.EmployerName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- StartDate Column -->
                <ng-container matColumnDef="StartDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Start Date
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipOpp">
                        <p class="text-truncate">{{internshipOpp.StartDate |date}}</p>
                    </mat-cell>
                </ng-container>

                <!-- InternshipType Column -->
                <ng-container matColumnDef="InternshipType">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Internship Type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipOpp">
                        <p class="text-truncate">{{internshipOpp.InternshipType}}</p>
                    </mat-cell>
                </ng-container>
                <!-- SectorName Column -->
                <ng-container matColumnDef="SectorName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Sector
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipOpp">
                        <p class="text-truncate">{{internshipOpp.SectorName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- WorkType Column -->
                <ng-container matColumnDef="WorkType">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Work Type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipOpp">
                        <p class="text-truncate">{{internshipOpp.WorkType}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Stipend Column -->
                <ng-container matColumnDef="Stipend">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Stipend
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipOpp">
                        <p class="text-truncate">{{internshipOpp.Stipend}}</p>
                    </mat-cell>
                </ng-container>


                <!-- IsActive Column -->
                <!-- <ng-container matColumnDef="IsActive">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
                        Is Active?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipOpp" fxHide fxShow.gt-xs>
                        <mat-icon *ngIf="internshipOpp.IsActive" class="active-icon green-600 s-16">check</mat-icon>
                        <mat-icon *ngIf="!internshipOpp.IsActive" class="active-icon red-500 s-16">close</mat-icon>
                    </mat-cell>
                </ng-container> -->

                <ng-container matColumnDef="Actions" stickyEnd>
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let internshipOpp" fxShow.gt-xs>
                        <!-- <button *ngIf="PageRights.IsView" mat-icon-button
                            [routerLink]="['/internship-opportunity', 'view', internshipOpp.InternshipId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">visibility</mat-icon>
                        </button> -->
                        <button *ngIf="PageRights.IsEdit || UserModel.RoleCode != 'VC'" mat-icon-button 
                            [routerLink]="['/internship-opportunity', 'edit', internshipOpp.InternshipId]">
                            <!-- <mat-icon color="primary" style="color: rgb(0, 101, 254);">edit</mat-icon> -->
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit" width="30"
                                height="30" viewBox="0 0 24 24" stroke-width="1.5" stroke="#0065fe" fill="none"
                                stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"></path>
                                <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3"></path>
                                <line x1="16" y1="5" x2="19" y2="8"></line>
                            </svg>
                        </button>
                        <button mat-icon-button color="warn" (click)="onDeleteInternship(internshipOpp.InternshipId)"
                            *ngIf="PageRights.IsDelete || UserModel.RoleCode != 'VC'">
                            <mat-icon>delete_outline</mat-icon>
                        </button>

                        <!-- <button *ngIf="PageRights.IsView" mat-icon-button
                        [routerLink]="['/internship-opportunity', 'view', internshipOpp.InternshipId]" skipLocationChange="true"
                        replaceUrl="false">
                        <mat-icon color="primary">visibility</mat-icon>
                    </button> -->

                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" *ngIf="UserModel.RoleCode != 'VC'">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item *ngIf="tabIndex === 0"
                                (click)="onClickMarkInternshipCompleted(internshipOpp.InternshipId)">
                                <!-- <mat-icon>dialpad</mat-icon> -->
                                <mat-icon>done</mat-icon>
                                <span>Complete</span>
                            </button>
                            <button mat-menu-item *ngIf="tabIndex === 1">
                                <!-- <mat-icon>voicemail</mat-icon> -->
                                <span>No Actions</span>
                            </button>
                            <button mat-menu-item *ngIf="tabIndex === 2"
                                (click)="onClickPublishInternship(internshipOpp.InternshipId)">
                                <mat-icon>publish</mat-icon>
                                <span>Publish</span>
                            </button>
                        </mat-menu>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <!-- <mat-row *matRowDef="let internshipOpp; columns: displayedColumns;"
                    (click)="navigateIntershipDetails(internshipOpp.InternshipId)" skipLocationChange="true" replaceUrl="true" class="internshipOpp" matRipple>
                </mat-row> -->
                <mat-row *matRowDef="let internshipOpp; columns: displayedColumns;" class="internshipOpp" matRipple>
                </mat-row>
            </mat-table>

            <!-- <mat-paginator #ListPaginator [length]="SearchBy.TotalResults" [pageIndex]="SearchBy.PageIndex"
                [pageSize]="SearchBy.PageSize" [pageSizeOptions]="SearchBy.PageSizeOptions"
                [showFirstLastButtons]="SearchBy.ShowFirstLastButtons" (page)="onPageIndexChanged($event)">
            </mat-paginator> -->
            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100,200]">
            </mat-paginator>

        </div>

        <div class="content-card" *ngIf="tabIndex === 2">

            <!--<mat-table matTableExporter class="internship-opportunity-table responsive-table" [dataSource]="tableDataSource"
                    #exporter="matTableExporter" [hiddenColumns]="[8]" matSort fusePerfectScrollbar
                    [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">-->

            <mat-table class="internship-opportunity-table responsive-table" [dataSource]="tableDataSource" matSort
                fusePerfectScrollbar [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- Title Column -->
                <ng-container matColumnDef="Title">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Post
                        <!--  Title -->
                    </mat-header-cell>
                    <!-- <mat-cell *matCellDef="let internshipOpp" [routerLink]="['/internship-deatils', internshipOpp.InternshipId]" skipLocationChange="true"
                    replaceUrl="true">
                        <p class="text-truncate"> <b>{{internshipOpp.Title}}</b></p> <br>
                        {{internshipOpp.BranchName}}
                    </mat-cell> -->
                    <mat-cell *matCellDef="let internshipOpp"
                        [routerLink]="['/internship-deatils', internshipOpp.InternshipId]">
                        <p class="text-truncate"> <b>{{internshipOpp.Title}}</b></p> <br>
                        {{internshipOpp.BranchName}}
                    </mat-cell>
                </ng-container>

                <!-- EmployerName Column -->
                <ng-container matColumnDef="EmployerName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Employer
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipOpp">
                        <p class="text-truncate">{{internshipOpp.EmployerName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- StartDate Column -->
                <ng-container matColumnDef="StartDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Start Date
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipOpp">
                        <p class="text-truncate">{{internshipOpp.StartDate |date}}</p>
                    </mat-cell>
                </ng-container>

                <!-- InternshipType Column -->
                <ng-container matColumnDef="InternshipType">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Internship Type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipOpp">
                        <p class="text-truncate">{{internshipOpp.InternshipType}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SectorName Column -->
                <ng-container matColumnDef="SectorName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Sector
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipOpp">
                        <p class="text-truncate">{{internshipOpp.SectorName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- WorkType Column -->
                <ng-container matColumnDef="WorkType">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Work Type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipOpp">
                        <p class="text-truncate">{{internshipOpp.WorkType}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Stipend Column -->
                <ng-container matColumnDef="Stipend">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Stipend
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipOpp">
                        <p class="text-truncate">{{internshipOpp.Stipend}}</p>
                    </mat-cell>
                </ng-container>



                <!-- IsActive Column -->
                <!-- <ng-container matColumnDef="IsActive">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
                        Is Active?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let internshipOpp" fxHide fxShow.gt-xs>
                        <mat-icon *ngIf="internshipOpp.IsActive" class="active-icon green-600 s-16">check</mat-icon>
                        <mat-icon *ngIf="!internshipOpp.IsActive" class="active-icon red-500 s-16">close</mat-icon>
                    </mat-cell>
                </ng-container> -->

                <ng-container matColumnDef="Actions" stickyEnd>
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let internshipOpp" fxShow.gt-xs>
                        <!-- <button *ngIf="PageRights.IsView" mat-icon-button
                            [routerLink]="['/internship-opportunity', 'view', internshipOpp.InternshipId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">visibility</mat-icon>
                        </button> -->
                        <button *ngIf="PageRights.IsEdit || UserModel.RoleCode != 'VC'" mat-icon-button 
                            [routerLink]="['/internship-opportunity', 'edit', internshipOpp.InternshipId]">
                            <!-- <mat-icon color="primary" style="color: rgb(0, 101, 254);">edit</mat-icon> -->
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit" width="30"
                                height="30" viewBox="0 0 24 24" stroke-width="1.5" stroke="#0065fe" fill="none"
                                stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"></path>
                                <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3"></path>
                                <line x1="16" y1="5" x2="19" y2="8"></line>
                            </svg>
                        </button>
                        <button mat-icon-button color="warn" (click)="onDeleteInternship(internshipOpp.InternshipId)"
                            *ngIf="PageRights.IsDelete || UserModel.RoleCode != 'VC'">
                            <mat-icon>delete_outline</mat-icon>
                        </button>

                        <!-- <button *ngIf="PageRights.IsView" mat-icon-button
                        [routerLink]="['/internship-opportunity', 'view', internshipOpp.InternshipId]" skipLocationChange="true"
                        replaceUrl="false">
                        <mat-icon color="primary">visibility</mat-icon>
                    </button> -->

                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" *ngIf="UserModel.RoleCode != 'VC'">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item *ngIf="tabIndex === 0"
                                (click)="onClickMarkInternshipCompleted(internshipOpp.InternshipId)">
                                <!-- <mat-icon>dialpad</mat-icon> -->
                                <mat-icon>done</mat-icon>
                                <span>Complete</span>
                            </button>
                            <button mat-menu-item *ngIf="tabIndex === 1">
                                <!-- <mat-icon>voicemail</mat-icon> -->
                                <span>No Actions</span>
                            </button>
                            <button mat-menu-item *ngIf="tabIndex === 2"
                                (click)="onClickPublishInternship(internshipOpp.InternshipId)">
                                <mat-icon>publish</mat-icon>
                                <span>Publish</span>
                            </button>
                        </mat-menu>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <!-- <mat-row *matRowDef="let internshipOpp; columns: displayedColumns;"
                    (click)="navigateIntershipDetails(internshipOpp.InternshipId)" skipLocationChange="true" replaceUrl="true" class="internshipOpp" matRipple>
                </mat-row> -->
                <mat-row *matRowDef="let internshipOpp; columns: displayedColumns;" class="internshipOpp" matRipple>
                </mat-row>
            </mat-table>

            <!-- <mat-paginator #ListPaginator [length]="SearchBy.TotalResults" [pageIndex]="SearchBy.PageIndex"
                [pageSize]="SearchBy.PageSize" [pageSizeOptions]="SearchBy.PageSizeOptions"
                [showFirstLastButtons]="SearchBy.ShowFirstLastButtons" (page)="onPageIndexChanged($event)">
            </mat-paginator> -->
            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100,200]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>