import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';

@Injectable()
export class SafetyConcernService {
    constructor(private http: BaseService) { }

   

    GetRequestsByVTIdRequestTypeIsActionTaken(filters:any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.Requests.GetRequestsByVTIdRequestTypeIsActionTaken, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }
    
    SetActionTaken(data: any) {
      

        return this.http
            .HttpPost(this.http.Services.Requests.SetActionTaken, data)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }



}
