import { FuseUtils } from '@fuse/utils';

export class SchoolVTPVCMappingModel {
    SchoolSectorJobRolesVTPId: string;
    SchoolSectorJobRolesId:string;
    SchoolVTPVCId:string;
    VTPId: string;
    VCId: string;
    AcademicYearId: string;
    DateOfAllocation: Date;
    DateOfDeAllocation:Date
    Remarks: string;    
    IsActive: boolean;
    RequestType: any;
    StateCode: string; // New Parameters
    DateOfResignation:Date// New Parameters
   
    constructor(schoolVTPVCMappingItem?: any) {
        schoolVTPVCMappingItem = schoolVTPVCMappingItem || {};

        this.SchoolVTPVCId = schoolVTPVCMappingItem.SchoolVTPVCId || FuseUtils.NewGuid();
        this.SchoolSectorJobRolesVTPId = schoolVTPVCMappingItem.SchoolSectorJobRolesVTPId || '';
        this.VTPId = schoolVTPVCMappingItem.VTPId || '';
        this.VCId = schoolVTPVCMappingItem.VCId || '';
        this.AcademicYearId = schoolVTPVCMappingItem.AcademicYearId || '';
        this.DateOfAllocation = schoolVTPVCMappingItem.DateOfAllocation || '';
        this.DateOfDeAllocation = schoolVTPVCMappingItem.DateOfDeAllocation || '';
        this.Remarks = schoolVTPVCMappingItem.Remarks || '';
        this.StateCode = schoolVTPVCMappingItem.StateCode || '';
        this.DateOfResignation = schoolVTPVCMappingItem.DateOfResignation || '';

        this.IsActive = schoolVTPVCMappingItem.IsActive || true;
        this.RequestType = 0; // New
    }
}
