import { Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { CommonService } from 'app/services/common.service';
import { InternshipOpportunity } from './internship-opportunity.model';
import { InternshipOpportunityService } from './internship-opportunity.service';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { DropdownModel } from 'app/models/dropdown.model';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { MatMenuTrigger } from '@angular/material/menu';
import { RouteConstants } from 'app/constants/route.constant';
// import { locale as english } from './i18n/en';
// import { locale as guarati } from './i18n/gj';
@Component({
  selector: 'app-internship-opportunity',
  templateUrl: './internship-opportunity.component.html',
  styleUrls: ['./internship-opportunity.component.scss'],
  providers: [DatePipe],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class InternshipOpportunityComponent extends BaseListComponent<InternshipOpportunity> implements OnInit {
  internshipOpportunityForm: FormGroup;
  internshipOpportunityFilterForm: FormGroup;

  @Input() IsAllowedGloablSearch: boolean;
  tabIndex = 0;
  currentAcademicYearId: string;
  sectorList: [DropdownModel];
  vtpList: [DropdownModel];
  filteredVtpSectorItems: any;
  employerList: [DropdownModel];
  filteredEmployerItems: any;

  durationList = [{ Id: 1, Name: 'Today' }, { Id: 2, Name: 'Yesterday' }, { Id: 3, Name: 'Last 7 days' }, { Id: 4, Name: 'This Month' }, { Id: 5, Name: 'Last Month' }]
  fromDate: any;
  toDate: any;
  today = new Date();
  // toDate = false;
  minDate: Date;
  maxDate: Date;
  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private translationLoaderService: FuseTranslationLoaderService,
    public zone: NgZone,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private service: InternshipOpportunityService,
    private datePipe: DatePipe,
  ) {
    super(commonService, router, routeParams, snackBar, zone);
    // this.translationLoaderService.loadTranslations(english, guarati);
    this.internshipOpportunityForm = this.formBuilder.group({ filterText: '' });
  }
  changeTab(event) {

    console.log(event.index)
    this.tabIndex = event.index;
    if (this.tabIndex === 0) {
      this.onLoadInternshipByCriteriaActive();
    } else if (this.tabIndex === 1) {
      this.onLoadInternshipByCriteriaCompleted();
    } else if (this.tabIndex === 2) {
      this.onLoadInternshipByCriteriaUnpublished();
    }
  }
  ngOnInit(): void {
    this.getEmployersByCriteria();
    // this.trigger.openMenu();
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 1, 11, 31);
    // this.minDate = new Date(this.maxDate.getDate() - 90);
    // this.maxDate = new Date();
    // var s = this.datepipe.transform(this.minDate, 'yyyy-MM-dd');
    // var e = this.datepipe.transform(this.maxDate, 'yyyy-MM-dd');
    // show hide gloabl search 
    this.IsAllowedGloablSearch = this.Constants.PageArray.includes(window.location.pathname);

    this.service.getDropdownforInternshipOpportunity(this.UserModel).subscribe(results => {
      if (results[0].Success) {
        this.sectorList = results[0].Results;
      }

      if (results[1].Success) {
        const currentYear = new Date().getFullYear();
        // this.employerList = results[1].Results;
        // this.filteredEmployerItems = this.employerList.slice();
      }
      // this.SearchBy.PageIndex = 0; // delete after script changed
      // this.SearchBy.PageSize = 1000; // delete after script changed

      // this.internshipOpportunityForm = this.formBuilder.group({ filterText: '' });

      //Load initial schools data
      // this.onLoadInternshipByCriteria();
      if (this.tabIndex === 0) {
        this.onLoadInternshipByCriteriaActive();
      } else if (this.tabIndex === 1) {
        this.onLoadInternshipByCriteriaCompleted();
      } else if (this.tabIndex === 2) {
        this.onLoadInternshipByCriteriaUnpublished();
      }

      this.internshipOpportunityForm.get('filterText').valueChanges.pipe(
        // if character length greater then 2
        filter(res => {
          this.SearchBy.PageIndex = 0;

          if (res.length == 0) {
            this.SearchBy.Name = '';
            // this.onLoadInternshipByCriteria();
            if (this.tabIndex === 0) {
              this.onLoadInternshipByCriteriaActive();
            } else if (this.tabIndex === 1) {
              this.onLoadInternshipByCriteriaCompleted();
            } else if (this.tabIndex === 2) {
              this.onLoadInternshipByCriteriaUnpublished();
            }
            return false;
          }

          return res.length > 2
        }),

        // Time in milliseconds between key events
        debounceTime(650),

        // If previous query is diffent from current   
        distinctUntilChanged()

        // subscription for response
      ).subscribe((searchText: string) => {
        this.SearchBy.Name = searchText;
        // this.onLoadInternshipByCriteria();
        if (this.tabIndex === 0) {
          this.onLoadInternshipByCriteriaActive();
        } else if (this.tabIndex === 1) {
          this.onLoadInternshipByCriteriaCompleted();
        } else if (this.tabIndex === 2) {
          this.onLoadInternshipByCriteriaUnpublished();
        }
      });
    });
    this.internshipOpportunityFilterForm = this.ceateInternshipOpportunityFilterForm();
  }

  ngAfterViewInit() {
    this.tableDataSource.paginator = this.ListPaginator;
  }

  onPageIndexChanged(evt) {
    this.SearchBy.PageIndex = evt.pageIndex;
    this.SearchBy.PageSize = evt.pageSize;

    // this.onLoadInternshipByCriteria();
    if (this.tabIndex === 0) {
      this.onLoadInternshipByCriteriaActive();
    } else if (this.tabIndex === 1) {
      this.onLoadInternshipByCriteriaCompleted();
    } else if (this.tabIndex === 2) {
      this.onLoadInternshipByCriteriaUnpublished();
    }
  }

  onLoadInternshipByCriteriaActive(): any {
    this.IsLoading = true;
    this.SearchBy.SchoolGuid = (this.Constants.GlobalSchoolId == 'undefined' || this.Constants.GlobalSchoolId == 'null') ? null : this.Constants.GlobalSchoolId;
    this.SearchBy.AcademicYearGuid = (this.Constants.GlobalAcademicYearId == 'undefined' || this.Constants.GlobalAcademicYearId == 'null') ? null : this.Constants.GlobalAcademicYearId;
    this.SearchBy.SectorGuid = this.internshipOpportunityFilterForm.controls['SectorId'].value;
    this.SearchBy.RoleCode = this.UserModel.RoleCode;
    this.SearchBy.EmployerGuid = this.internshipOpportunityFilterForm.controls['EmployerId'].value;
    this.SearchBy.IsCriterion = true;
    this.service.GetInternshipByActiveCriteria(this.SearchBy).subscribe(response => {
      this.displayedColumns = ['Title', 'EmployerName', 'StartDate', 'Stipend', 'SectorName', 'InternshipType', 'WorkType', 'Actions'];
      // if (this.tabIndex == 0) {
      //   this.tableDataSource.data = response.Results.filter(x => x.IsPublished == true && x.IsCompleted == false);
      // } else if (this.tabIndex == 1) {
      //   this.tableDataSource.data = response.Results.filter(x => x.IsCompleted == true);
      // } else {
      //   this.tableDataSource.data = response.Results.filter(x => x.IsPublished == false && x.IsCompleted == false);
      // }
      this.tableDataSource.data = response.Results;
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;
      this.SearchBy.TotalResults = response.TotalResults;

      setTimeout(() => {
        this.ListPaginator.pageIndex = this.SearchBy.PageIndex;
        this.ListPaginator.length = this.SearchBy.TotalResults;
      });

      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  onLoadInternshipByCriteriaCompleted(): any {
    this.IsLoading = true;
    this.SearchBy.SchoolGuid = (this.Constants.GlobalSchoolId == 'undefined' || this.Constants.GlobalSchoolId == 'null') ? null : this.Constants.GlobalSchoolId;
    this.SearchBy.AcademicYearGuid = (this.Constants.GlobalAcademicYearId == 'undefined' || this.Constants.GlobalAcademicYearId == 'null') ? null : this.Constants.GlobalAcademicYearId;
    this.SearchBy.SectorGuid = this.internshipOpportunityFilterForm.controls['SectorId'].value;
    this.SearchBy.RoleCode = this.UserModel.RoleCode;
    this.SearchBy.EmployerGuid = this.internshipOpportunityFilterForm.controls['EmployerId'].value;
    this.SearchBy.IsCriterion = true;
    this.service.GetInternshipByCompletedCriteria(this.SearchBy).subscribe(response => {
      this.displayedColumns = ['Title', 'EmployerName', 'StartDate', 'Stipend', 'InternshipType', 'SectorName', 'WorkType', 'Actions'];
      // if (this.tabIndex == 0) {
      //   this.tableDataSource.data = response.Results.filter(x => x.IsPublished == true && x.IsCompleted == false);
      // } else if (this.tabIndex == 1) {
      //   this.tableDataSource.data = response.Results.filter(x => x.IsCompleted == true);
      // } else {
      //   this.tableDataSource.data = response.Results.filter(x => x.IsPublished == false && x.IsCompleted == false);
      // }
      this.tableDataSource.data = response.Results;
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;
      this.SearchBy.TotalResults = response.TotalResults;

      setTimeout(() => {
        this.ListPaginator.pageIndex = this.SearchBy.PageIndex;
        this.ListPaginator.length = this.SearchBy.TotalResults;
      });

      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  onLoadInternshipByCriteriaUnpublished(): any {
    this.IsLoading = true;
    this.SearchBy.SchoolGuid = (this.Constants.GlobalSchoolId == 'undefined' || this.Constants.GlobalSchoolId == 'null') ? null : this.Constants.GlobalSchoolId;
    this.SearchBy.AcademicYearGuid = (this.Constants.GlobalAcademicYearId == 'undefined' || this.Constants.GlobalAcademicYearId == 'null') ? null : this.Constants.GlobalAcademicYearId;
    this.SearchBy.SectorGuid = this.internshipOpportunityFilterForm.controls['SectorId'].value;
    this.SearchBy.RoleCode = this.UserModel.RoleCode;
    this.SearchBy.EmployerGuid = this.internshipOpportunityFilterForm.controls['EmployerId'].value;
    this.SearchBy.IsCriterion = false;
    this.service.GetInternshipByUnpublishedCriteria(this.SearchBy).subscribe(response => {
      this.displayedColumns = ['Title', 'EmployerName', 'StartDate', 'Stipend', 'SectorName', 'InternshipType', 'WorkType', 'Actions'];
      // if (this.tabIndex == 0) {
      //   this.tableDataSource.data = response.Results.filter(x => x.IsPublished == true && x.IsCompleted == false);
      // } else if (this.tabIndex == 1) {
      //   this.tableDataSource.data = response.Results.filter(x => x.IsCompleted == true);
      // } else {
      //   this.tableDataSource.data = response.Results.filter(x => x.IsPublished == false && x.IsCompleted == false);
      // }
      this.tableDataSource.data = response.Results;
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;
      this.SearchBy.TotalResults = response.TotalResults;

      setTimeout(() => {
        this.ListPaginator.pageIndex = this.SearchBy.PageIndex;
        this.ListPaginator.length = this.SearchBy.TotalResults;
      });

      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  onDeleteInternship(internshipId: string) {
    this.dialogService
      .openConfirmDialog(this.Constants.Messages.DeleteConfirmationMessage)
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          this.service.deleteSchoolById(internshipId)
            .subscribe((schoolResp: any) => {

              this.zone.run(() => {
                if (schoolResp.Success) {
                  this.showActionMessage(
                    this.Constants.Messages.RecordDeletedMessage,
                    this.Constants.Html.SuccessSnackbar
                  );
                }
                this.ngOnInit();
              }, error => {
                console.log('Internship deletion errors =>', error);
              });

            });
          this.ngOnInit();
        }
      });
  }

  onClickMarkInternshipCompleted(internshipId: string) {
    this.service.markIsCompleted(internshipId).subscribe(response => {
      if (response.Success) {
        this.zone.run(() => {
          this.showActionMessage(
            "Marked Intersnship Completed successfully...",
            this.Constants.Html.SuccessSnackbar
          );
          // this.onLoadInternshipByCriteria();
          if (this.tabIndex === 0) {
            this.onLoadInternshipByCriteriaActive();
          } else if (this.tabIndex === 1) {
            this.onLoadInternshipByCriteriaCompleted();
          } else if (this.tabIndex === 2) {
            this.onLoadInternshipByCriteriaUnpublished();
          }
          // this.router.navigate([RouteConstants.Student.List]);
        });
      }

    });
  }

  onClickPublishInternship(internshipId: string) {
    this.service.markPublishInternship(internshipId).subscribe(response => {
      if (response.Success) {
        this.zone.run(() => {
          this.showActionMessage(
            "Intersnship Published ...",
            this.Constants.Html.SuccessSnackbar
          );
          // this.onLoadInternshipByCriteria();
          if (this.tabIndex === 0) {
            this.onLoadInternshipByCriteriaActive();
          } else if (this.tabIndex === 1) {
            this.onLoadInternshipByCriteriaCompleted();
          } else if (this.tabIndex === 2) {
            this.onLoadInternshipByCriteriaUnpublished();
          }
          // this.router.navigate([RouteConstants.Student.List]);
        });
      }

    });
  }
  exportExcel(): void {
    this.IsLoading = true;
    this.SearchBy.PageIndex = 0;
    this.SearchBy.PageSize = 10000;

    this.service.GetAllByCriteria(this.SearchBy).subscribe(response => {
      response.Results.forEach(
        function (obj) {
          if (obj.hasOwnProperty('IsActive')) {
            obj.IsActive = obj.IsActive ? 'Yes' : 'No';
          }
          if (obj.hasOwnProperty('IsImplemented')) {
            obj.IsImplemented = obj.IsImplemented ? 'Yes' : 'No';
          }
          delete obj.SchoolId;
          delete obj.TotalRows;
        });

      this.exportExcelFromTable(response.Results, "Schools");

      this.IsLoading = false;
      this.SearchBy.PageIndex = 0;
      this.SearchBy.PageSize = 10;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  onLoadSchoolVTPSectorsByFilters(): any {
    this.SearchBy.PageIndex = 0;
    // this.onLoadInternshipByCriteria();
    if (this.tabIndex === 0) {
      this.onLoadInternshipByCriteriaActive();
    } else if (this.tabIndex === 1) {
      this.onLoadInternshipByCriteriaCompleted();
    } else if (this.tabIndex === 2) {
      this.onLoadInternshipByCriteriaUnpublished();
    }
  }

  resetFilters(): void {
    this.internshipOpportunityFilterForm.reset();
    // this.internshipOpportunityFilterForm.get('AcademicYearId').setValue(this.currentAcademicYearId);

    // this.onLoadInternshipByCriteria();
    if (this.tabIndex === 0) {
      this.onLoadInternshipByCriteriaActive();
    } else if (this.tabIndex === 1) {
      this.onLoadInternshipByCriteriaCompleted();
    } else if (this.tabIndex === 2) {
      this.onLoadInternshipByCriteriaUnpublished();
    }
  }

  onChangeDuration(evt) {

    let duration = evt;
    switch (duration) {

      case 1:

        let todaysDate = this.datePipe.transform(this.today, 'yyyy-MM-dd');
        this.fromDate = todaysDate;
        this.toDate = todaysDate;
        console.log("Today", this.fromDate, this.toDate);
        break;
      case 2:
        var tdate = new Date();
        tdate.setDate(tdate.getDate() - 1);
        let yesterdaysDate = this.datePipe.transform(tdate, 'yyyy-MM-dd');
        this.fromDate = yesterdaysDate;
        this.toDate = yesterdaysDate;
        console.log("Yesterday", this.fromDate, this.toDate);
        break;
      case 3:
        let wdate: Date = new Date();
        wdate.setDate(wdate.getDate() - 6);
        // date.setHours(0);
        // date.setMinutes(0);
        // date.setSeconds(0);
        // date.setMilliseconds(0);
        // let datePipe: DatePipe = new DatePipe('en-US');
        // console.log(datePipe.transform(date, 'short'));
        this.fromDate = this.datePipe.transform(wdate, 'yyyy-MM-dd');
        this.toDate = this.datePipe.transform(this.today, 'yyyy-MM-dd');
        console.log("Last 7 days", this.fromDate, this.toDate);
        break;
      case 4:
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.fromDate = this.datePipe.transform(firstDay, 'yyyy-MM-dd');
        this.toDate = this.datePipe.transform(lastDay, 'yyyy-MM-dd');
        console.log("This Month", this.fromDate, this.toDate);
        break;
      case 5:
        const cdate = new Date();

        const firstDayPrevMonth = new Date(cdate.getFullYear(), cdate.getMonth() - 1, 1);
        const lastDayPrevMonth = new Date(cdate.getFullYear(), cdate.getMonth(), 0);
        this.fromDate = this.datePipe.transform(firstDayPrevMonth, 'yyyy-MM-dd');
        this.toDate = this.datePipe.transform(lastDayPrevMonth, 'yyyy-MM-dd');
        console.log("Last Month", this.fromDate, this.toDate);
        break;
      default:
        console.log("Invalid");
    }

  }
  //Create SchoolVtpSectorFilter form and returns {FormGroup}
  ceateInternshipOpportunityFilterForm(): FormGroup {
    return this.formBuilder.group({
      SectorId: new FormControl(),
      EmployerId: new FormControl(),
      DurationId: new FormControl(),
    });
  }

  // [routerLink]="['/internship-deatils', internshipOpp.InternshipId]"
  navigateIntershipDetails(internshipId: any) {
    this.router.navigate(['internship-deatils/' + internshipId]);
    // this.router.navigate(['internship-deatils/'], { queryParams: internshipId, skipLocationChange: true });
  }


  getEmployersByCriteria(): any {
    debugger;
    this.IsLoading = true;
    let data = {
      VTId: this.UserModel.UserTypeId,
      VTGuid: this.UserModel.UserTypeId,
      UserTypeId: null,
      RoleCode: this.UserModel.RoleCode,
      PageIndex: 0,
      PageSize: 10000,
      Name: null,
      CharBy: null,
      SectorGuid: null,
      StartDateTime: null,
      EndDatetime: null,
      IsActive: null
    }
    this.service.GetEmployersByCriteria(data).subscribe(response => {
      debugger;
      let empList = response.Results;
      let arr = [] as any;
      for (let i = 0; i < empList.length; i++) {
        let xxx = {
          Id: empList[i].EmployerId,
          Name: empList[i].OrganizationName,
          Description: '',
          IsSelected: false,
          IsDisabled: false,
          SequenceNo: 1
        }
        arr.push(xxx);
      }
      this.employerList = arr;
      this.filteredEmployerItems = this.employerList.slice()
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }
}
