import { FuseUtils } from '@fuse/utils';



export class ProfileChangeRequestModel {

    
    StudentId: string;
    AuthUserId:string; // New Parameters
    IsActive: boolean;
    RequestType: any;
    FirstName: string;
    FullName: string;
    MiddleName: string;
    LastName: string;
    Gender: string;
    Mobile: string;
    Mobile1: string;
    FatherName: string;
    FatherOccupation: string;
    FatherMobile: string;
    MotherName: string;
    MotherOccupation: string;
    MotherMobile: string;
    ProfilePhoto: string;
    DateOfBirth: Date;
    RegistrationNo: string;
    GuardianName: string;
    AadhaarNumber: string;
    SocialCategory: string;
    Religion:string; // New Parameters
    CWSNStatus:string; // New Parameters
    Address3: string; // New Parameters
    Address1:string; // New Parameters
    Address2:string; // New Parameters
    Longitude:string; //New Parameters
    Latitude:string; // New Parameters
    Statecode: string; // New Parameters
    Pincode:string; // New Parameters
    SchoolId:string; // New Parameters
    ClassId:string; // New Parameters
    SectionId:string; // New Parameters
    AcademicYearId:string; // New Parameters
    StudentRollNumber:string; // New Parameters
    DateOfEnrollment:Date; // New Parameters
    VocationalSubject:string; // New Parameters
    VTId:string; // New Parameters
    DateOfVTAllocation:Date; // New Parameters
    StateName:string; // New Parameters
    DivisionId:string; // New Parameters
    DistrictCode:string; // New Parameters
    EmailId: string; // New Parameters
    UpdatedBy:string;
    CreatedBy:string;
    SectorId:string;
    StudentProfilePhoto: string;
    BlockId: string;
    Stream: string;
   City: string;
   IsActionTaken:boolean;
    constructor(studentItem?: any) {
        studentItem = studentItem || {};

        this.StudentId = studentItem.StudentId || FuseUtils.NewGuid();
        this.FirstName = studentItem.FirstName || '';
        this.FullName = studentItem.FullName || '';
        this.MiddleName = studentItem.MiddleName || '';
        this.LastName = studentItem.LastName || '';
        this.Gender = studentItem.Gender || '';
        this.Mobile = studentItem.Mobile || '';
        this.Mobile1 = studentItem.Mobile1 || '';
        this.FatherName = studentItem.FatherName || '';
        this.FatherOccupation = studentItem.FatherOccupation || '';
        this.FatherMobile = studentItem.FatherMobile || '';
        this.MotherName = studentItem.MotherName || '';
        this.MotherOccupation = studentItem.MotherOccupation || '';
        this.MotherMobile = studentItem.MotherMobile || '';
        this.ProfilePhoto = studentItem.ProfilePhoto || '';
        this.DateOfBirth = studentItem.DateOfBirth || '';
        this.RegistrationNo = studentItem.RegistrationNo || '';
        this.GuardianName = studentItem.GuardianName || '';
        this.AadhaarNumber = studentItem.AadhaarNumber || '';
        this.SocialCategory = studentItem.SocialCategory || '';
        this.Religion = studentItem.Religion || '';
        this.CWSNStatus = studentItem.CWSNStatus || '';
        this.Address1 = studentItem.Address1 || '';
        this.Address2 = studentItem.Address2 || '';
        this.StudentProfilePhoto = studentItem.StudentProfilePhoto || '';
        this.IsActive = studentItem.IsActive || true;
        this.RequestType = 0; // New Parameters
        this.AuthUserId = studentItem.AuthUserId || ''; // New Parameters

        this.Longitude = studentItem.Longitude || ''; // New Parameters 
        this.Latitude = studentItem.Latitude || ''; // New Parameters
        this.Statecode = studentItem.Statecode || ''; // New Parameters
        this.EmailId = studentItem.EmailId || ''; // New Parameters
        this.Address3 = studentItem.Address3 || ''; // New Parameters
        this.Pincode = studentItem.Pincode || ''; // New Parameters
        this.SchoolId = studentItem.SchoolId || ''; // New Parameters
        this.ClassId = studentItem.ClassId || ''; // New Parameters
        this.SectionId = studentItem.SectionId || ''; // New Parameters
        this.AcademicYearId = studentItem.AcademicYearId || ''; // New Parameters
        this.StudentRollNumber = studentItem.StudentRollNumber || ''; // New Parameters
        this.DateOfEnrollment = studentItem.DateOfEnrollment ; // New Parameters
        this.VocationalSubject = studentItem.VocationalSubject || ''; // New Parameters
        this.VTId = studentItem.VTId || ''; // New Parameters
        this.DateOfVTAllocation = studentItem.DateOfVTAllocation || ''; // New Parameters

        this.StateName = studentItem.StateName || ''; // New Parameters
        this.DivisionId = studentItem.DivisionId || ''; // New Parameters
        this.DistrictCode = studentItem.DistrictCode || ''; // New Parameters
        this.UpdatedBy = studentItem.UpdatedBy || ''; // New Parameters
        this.CreatedBy = studentItem.CreatedBy || ''; // New Parameters
        this.SectorId = studentItem.SectorId || ''; // New Parameters
        this.BlockId = studentItem.BlockId || ''; // New Parameters
        this.Stream = studentItem.Stream || ''; // New Parameters
        this.City = studentItem.City || ''; // New Parameters
        this.IsActionTaken = studentItem.IsActionTaken || true;



    
    }
}



