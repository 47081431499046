<div id="school" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    Student Rights
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0" style="width: 100%;
            max-width: 380px;
            border-radius: 28px;
            overflow: hidden; background-color: white;">
                <form [formGroup]="StudentRightsSearchForm">
                    <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center" style="width: 100%;
                    height: 48px;
                    line-height: 48px;
                    padding: 0 18px;">
                        <mat-icon>search</mat-icon>
                        <input formControlName="filterText" placeholder="Search for a school" style="width: 100%;
                        height: 48px;
                        min-height: 48px;
                        max-height: 48px;
                        padding: 0 16px;
                        border: none;
                        outline: none;">
                    </div>
                </form>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">


                <button mat-raised-button [routerLink]="['./','new']" skipLocationChange="true" replaceUrl="false"
                    class="add-school-button fuse-white mt-24 mt-md-0 btn-warning mls">
                    <span>New Student Rights</span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="ms-2" width="20" height="20" viewBox="0 0 24 24"
                        stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <line x1="12" y1="5" x2="12" y2="19"></line>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                </button>

                <!--<a *ngIf="PageRights.IsExport" class="export-excel"
                   (click)="exporter.exportTable('xlsx', {fileName:'Schools', sheet: 'Schools', Props: {Author: 'Lighthouse'}})">
                    <mat-icon> cloud_download</mat-icon>
                </a>-->

                <!-- <a *ngIf="PageRights.IsExport" class="export-excel" (click)="exportExcel()">
                    <mat-icon> cloud_download</mat-icon>
                </a> -->
            </div>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- Filter Section -->
        <div class="filter-content-card">
            <div class="filter-content">
                <form name="StudentRightsFilterForm" [formGroup]="StudentRightsFilterForm" class="vc-issue-report"
                    fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Filter1 </mat-label>

                            <mat-select formControlName="Class" name="class" appearance="outline"
                                (selectionChange)="onChangeSection($event.value)">
                                <!-- <mat-option [value]="ctem.Id" *ngFor="let ctem of classList">
                                    {{ ctem.Name }}
                                </mat-option> -->
                                <mat-option value="Today">Today</mat-option>
                                <mat-option value="Yesterday">Yesterday</mat-option>
                                <mat-option value="Last 7 days">Last 7 days</mat-option>
                                <mat-option value="This Month">This Month</mat-option>
                                <mat-option value="LAst Month">LAst Month</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Filter2 </mat-label>

                            <mat-select formControlName="Class" name="class" appearance="outline"
                                (selectionChange)="onChangeSection($event.value)">
                                <!-- <mat-option [value]="ctem.Id" *ngFor="let ctem of classList">
                                    {{ ctem.Name }}
                                </mat-option> -->
                                <mat-option value="Today">Today</mat-option>
                                <mat-option value="Yesterday">Yesterday</mat-option>
                                <mat-option value="Last 7 days">Last 7 days</mat-option>
                                <mat-option value="This Month">This Month</mat-option>
                                <mat-option value="LAst Month">LAst Month</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Filter3 </mat-label>

                            <mat-select formControlName="Class" name="class" appearance="outline"
                                (selectionChange)="onChangeSection($event.value)">
                                <!-- <mat-option [value]="ctem.Id" *ngFor="let ctem of classList">
                                    {{ ctem.Name }}
                                </mat-option> -->
                                <mat-option value="Today">Today</mat-option>
                                <mat-option value="Yesterday">Yesterday</mat-option>
                                <mat-option value="Last 7 days">Last 7 days</mat-option>
                                <mat-option value="This Month">This Month</mat-option>
                                <mat-option value="LAst Month">LAst Month</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="clear-filter pr-1" fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="onLoadStudentRightsByCriteria()"><span>SEARCH</span></button>
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="resetFilters()"><span>CLEAR</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- /Filter Section -->


        <!-- CONTENT CARD -->
        <div class="content-card rounded-12 shadow-dark-80">
            <mat-table class="xxx responsive-table" [dataSource]="tableDataSource" matSort fusePerfectScrollbar
                [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- ResourceTitle Column -->
                <ng-container matColumnDef="ResourceTitle">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Title
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentRights">
                        <p class="text-truncate">{{studentRights.ResourceTitle}}</p>
                    </mat-cell>
                </ng-container>

                <!-- ResourceType Column -->
                <ng-container matColumnDef="ResourceType">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Resource Type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentRights">
                        <p class="text-truncate">{{studentRights.ResourceType}}</p>
                    </mat-cell>
                </ng-container>


                <!-- Category Column -->
                <ng-container matColumnDef="Category">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Category</mat-header-cell>
                    <mat-cell *matCellDef="let studentRights" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentRights.Category}}</p>
                    </mat-cell>
                </ng-container>

                <!-- PublishDate Column -->
                <ng-container matColumnDef="PublishDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Publish
                        Date</mat-header-cell>
                    <mat-cell *matCellDef="let studentRights" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentRights.PublishDate | slice :0:10}}</p>
                    </mat-cell>
                </ng-container>

                <!-- IsActive Column -->
                <ng-container matColumnDef="IsActive">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Is Active?</mat-header-cell>
                    <mat-cell *matCellDef="let studentRights" fxHide fxShow.gt-xs>
                        <mat-icon *ngIf="studentRights.IsActive" class="active-icon green-600 s-16">check</mat-icon>
                        <mat-icon *ngIf="!studentRights.IsActive" class="active-icon red-500 s-16">close</mat-icon>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Actions" stickyEnd>
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let studentRights" fxShow.gt-xs>
                        <button *ngIf="PageRights.IsView" mat-icon-button
                            [routerLink]="['/student-rights', 'view', studentRights.ResourceId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">visibility</mat-icon>
                        </button>
                        <!-- <button *ngIf="PageRights.IsEdit && studentRights.CreatedBy == UserModel.UserId" mat-icon-button
                            [routerLink]="['/student-rights', 'edit', studentRights.ResourceId]"
                            skipLocationChange="true" replaceUrl="false"> -->
                        <button *ngIf="PageRights.IsEdit" mat-icon-button
                            [routerLink]="['/student-rights', 'edit', studentRights.ResourceId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">edit</mat-icon>
                        </button>
                        <button mat-icon-button color="warn"
                            (click)="onDeleteResourcestudentRights(studentRights.ResourceId)"
                            *ngIf="PageRights.IsDelete && studentRights.CreatedBy == UserModel.UserId">
                            <mat-icon>delete_outline</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <!-- <mat-row *matRowDef="let student; columns: displayedColumns;" class="school" matRipple>
                </mat-row> -->
                <!-- <mat-row *matRowDef="let student; columns: displayedColumns;"
                    [routerLink]="['/vt-student-details', student.StudentId]" skipLocationChange="true"
                    replaceUrl="true" class="school" matRipple>
                </mat-row> -->
                <!-- <mat-row *matRowDef="let StudentRights; columns: displayedColumns;"
                    [routerLink]="['/student-notice-board', StudentRights.NoticeId]" class="school" matRipple>
                </mat-row> -->
                <mat-row *matRowDef="let studentRights; columns: displayedColumns;" class="school" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

            <!-- <mat-paginator #ListPaginator [length]="SearchBy.TotalResults" [pageIndex]="SearchBy.PageIndex"
                [pageSize]="SearchBy.PageSize" [pageSizeOptions]="SearchBy.PageSizeOptions"
                [showFirstLastButtons]="SearchBy.ShowFirstLastButtons" (page)="onPageIndexChanged($event)">
            </mat-paginator> -->

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>