<div id="employer-listing" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/student-rights'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        Edit Student Rights
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        Add Student Rights
                    </div>
                    <div class="subtitle secondary-text">
                        <span>Student Rights</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->
            <div fxLayout="row" class="add-edit-button" fxLayoutAlign="flex-start" fxFlex="0 1 calc(30%)"
                fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="100%">
                <button mat-raised-button class="save-school-button btn-outline-dark" style="float:right;"
                    [routerLink]="'/student-rights'"><span>Manage Student Rights</span><svg
                        xmlns="http://www.w3.org/2000/svg" class="ms-2" width="20" height="20" viewBox="0 0 24 24"
                        stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"></path>
                        <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3"></path>
                        <line x1="16" y1="5" x2="19" y2="8"></line>
                    </svg>
                </button>

                <button mat-raised-button class="add-school-button btn-warning mls"
                    *ngIf="PageRights.ActionType ==='new'" (click)="saveOrCreateOrUpdateStudentRights()">
                    <span>Add Student Rights</span>
                </button>

                <button mat-raised-button class="save-school-button btn-warning mls"
                    *ngIf="PageRights.ActionType ==='edit'" (click)="saveOrCreateOrUpdateStudentRights()">
                    <span>Save</span>
                </button>

            </div>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">


                <form name="studentRightsForm" [formGroup]="studentRightsForm" class="school w-100-p p-24 mr-24"
                    fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex fusePerfectScrollbar>
                    <!-- <mat-tab label="Basic Info"> -->
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Resource Title</mat-label>
                            <input matInput name="ResourceTitle" formControlName="ResourceTitle" required>
                            <mat-error *ngIf="studentRightsForm.controls.ResourceTitle.invalid">
                                <!-- {{getErrorMessage(studentRightsForm, 'NoticeTitle')}} -->Resource Title is
                                required
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="25" fxFlex.lt-sm="100">
                            <mat-label>Publish Date</mat-label>
                            <input matInput [matDatepicker]="picker1" name="PublishDate" formControlName="PublishDate"
                                [min]="CurrentDate" [disabled]="PageRights.IsReadOnly" required>
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                            <mat-error *ngIf="studentRightsForm.controls.PublishDate.invalid">
                                {{getErrorMessage(studentRightsForm, 'PublishDate')}}
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <!-- <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="25" fxFlex.lt-sm="100">
                            <mat-label>Resource Type</mat-label>
                            <mat-select formControlName="ResourceType" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option value="Student Rights">Student Rights</mat-option>
                            </mat-select>
                        </mat-form-field> -->

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="25" fxFlex.lt-sm="100">
                            <mat-label>Category</mat-label>
                            <mat-select formControlName="ResourceCategoryId" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="categoryListItem.Id" *ngFor="let categoryListItem of categoryList">
                                        {{ categoryListItem.Name }}
                                    </mat-option>
                                
                            </mat-select>
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px" style="border:1px soild #000 !important;">
                        <!-- <ckeditor [editor]="Editor" [config]="config"  (ready)="onReady($event)"  formControlName="Description" (change)="onChange($event)" style="height:200px;" ></ckeditor> -->
                        <ckeditor [editor]="editor" [config]="config"  id="editor" (ready)="onReady($event)" formControlName="ResourceDescription"></ckeditor>

                    </div>

                    <!-- </mat-tab>
                    </mat-tab-group> -->
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>