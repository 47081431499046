<!-- <mat-toolbar class="mat-elevation-z1">
  <span>Staff Master (Adding New)</span>

  <span class="fill-remaining-space"></span>

  <button mat-stroked-button color="warn" (click)="onClose()" type="button">
    <mat-icon>clear</mat-icon>
  </button>
</mat-toolbar> -->
<!-- HEADER -->
<div class="header1" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="space-between center" style="background: #3f51b5;height: 64px; height: 61px;
  padding: 0px 24px;
">
    <!-- APP TITLE -->
    <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center"
        [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
        <div class="h2 mt-8 mb-12  heading-text1" style="color:#fff;">
            Internship Details
        </div>
    </div>
    <!-- / APP TITLE -->
    <!-- ADD BUTTON -->

    <button mat-flat-button [color]="'warn'" (click)="onClose()" class="mat-elevation-z1" style="background-color: #f44336 !important;
  color: #fff !important;">
        <!-- <mat-icon svgIcon="heroicons_outline:arrow-narrow-left"></mat-icon> -->
        <mat-icon>clear</mat-icon>
    </button>
    <!-- / ADD BUTTON -->
</div>
<!-- / HEADER -->
<div id="staff" class="page-layout carded fullwidth inner-scroll">
    <!-- TOP BACKGROUND -->
    <!-- <div class="top-bg accent"></div> -->
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">
        <!-- CONTENT CARD -->
        <div class="content-card mat-elevation-z2 p-5 mb-12">
            <div class="content">
                <!-- <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" class="staff-name">
                    <div class='column' fxFlex="50">
                        <span class="lbl-bold">Staff Name : </span> {{data.FirstName }} {{data.LastName}}
                    </div>
                    <div class='column' fxFlex="50">
                        <span class="lbl-bold">Staff Designation : </span>{{data.StaffDesignation }}
                    </div>
                </div> -->

                <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start"
                    style="height: auto !important;" id="displayContent">
                    <!-- <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                        appearance="outline" fxFlex="0 1 calc(20%)" fxFlex.lt-md="0 1 calc(20%)" fxFlex.lt-sm="100%"
                        style="height: auto;">
                        <mat-card class="example-card">

                            <img mat-card-image src="{{internshipData.StudentProfilePhoto}}"
                                alt="Photo of a Student">

                        </mat-card>
                    </div> -->

                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                        appearance="outline" fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="100%"
                        style="height: auto;">
                        <mat-list style="padding-top:0px !important;" fxFlex="0 1 calc(90%)"
                            fxFlex.lt-md="0 1 calc(90%)" fxFlex.lt-sm="100%">


                            <mat-list-item><span fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="70%"
                                    class="lbl-bold">Employer Name</span>
                                <span fxFlex="0 1 calc(5%)" fxFlex.lt-md="0 1 calc(5%)" fxFlex.lt-sm="5%">:</span>
                                <span>{{
                                    internshipData.EmployerName
                                    }}</span>
                            </mat-list-item>

                            <mat-list-item><span fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="70%"
                                    class="lbl-bold">Job Description</span>
                                <span fxFlex="0 1 calc(5%)" fxFlex.lt-md="0 1 calc(5%)" fxFlex.lt-sm="5%">:</span>
                                <span>{{
                                    internshipData.JobDescription
                                    }}</span>
                            </mat-list-item>




                            <mat-list-item><span fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="70%"
                                    class="lbl-bold">No of Openings</span>
                                <span fxFlex="0 1 calc(5%)" fxFlex.lt-md="0 1 calc(5%)" fxFlex.lt-sm="5%">:</span>
                                <span>{{
                                    internshipData.NoOfOpenings
                                    }}</span>
                            </mat-list-item>



                            <mat-list-item><span fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="70%"
                                    class="lbl-bold">Branch</span>
                                <span fxFlex="0 1 calc(5%)" fxFlex.lt-md="0 1 calc(5%)" fxFlex.lt-sm="5%">:</span>
                                {{
                                internshipData.BranchName
                                }}
                            </mat-list-item>


                            <mat-list-item><span fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="70%"
                                    class="lbl-bold">Pincode</span>
                                <span fxFlex="0 1 calc(5%)" fxFlex.lt-md="0 1 calc(5%)" fxFlex.lt-sm="5%">:</span>
                                <span>{{
                                    internshipData.Pincode
                                    }}</span>
                            </mat-list-item>



                            <mat-list-item><span fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="70%"
                                    class="lbl-bold"> Publish Date</span>
                                <span fxFlex="0 1 calc(5%)" fxFlex.lt-md="0 1 calc(5%)" fxFlex.lt-sm="5%">:</span>
                                <span>{{
                                    internshipData.PublishDate | date
                                    }}</span>
                            </mat-list-item>

                            <mat-list-item><span fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="70%"
                                    class="lbl-bold">Contact Person</span>
                                <span fxFlex="0 1 calc(5%)" fxFlex.lt-md="0 1 calc(5%)" fxFlex.lt-sm="5%">:</span>
                                <span>{{
                                    internshipData.POCForInternship
                                    }}</span>
                            </mat-list-item>


                            <mat-list-item><span fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="70%"
                                    class="lbl-bold">Contact Person Mobile</span>
                                <span fxFlex="0 1 calc(5%)" fxFlex.lt-md="0 1 calc(5%)" fxFlex.lt-sm="5%">:</span>
                                <span>{{
                                    internshipData.POCMobile
                                    }}</span>
                            </mat-list-item>

                            <mat-list-item><span fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="70%"
                                    class="lbl-bold">Contact Person Email</span>
                                <span fxFlex="0 1 calc(5%)" fxFlex.lt-md="0 1 calc(5%)" fxFlex.lt-sm="5%">:</span>
                                <span>{{
                                    internshipData.POCEmail
                                    }}</span>
                            </mat-list-item>

                        </mat-list>
                    </div>
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                        appearance="outline" fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="100%"
                        style="height: auto;">
                        <mat-list style="padding-top:0px !important;" fxFlex="0 1 calc(90%)"
                            fxFlex.lt-md="0 1 calc(90%)" fxFlex.lt-sm="100%">

                            <mat-list-item><span fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="70%"
                                    class="lbl-bold">Internship Name</span>
                                <span fxFlex="0 1 calc(5%)" fxFlex.lt-md="0 1 calc(5%)" fxFlex.lt-sm="5%">:</span>
                                <span>{{
                                    internshipData.Title
                                    }}</span>
                            </mat-list-item>

                            <mat-list-item><span fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="70%"
                                    class="lbl-bold">Required Skills</span>
                                <span fxFlex="0 1 calc(5%)" fxFlex.lt-md="0 1 calc(5%)" fxFlex.lt-sm="5%">:</span>
                                <span>{{
                                    internshipData.RequiredSkills
                                    }}</span>
                            </mat-list-item>

                            <mat-list-item><span fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="70%"
                                    class="lbl-bold">Duration</span>
                                <span fxFlex="0 1 calc(5%)" fxFlex.lt-md="0 1 calc(5%)" fxFlex.lt-sm="5%">:</span>
                                <span>{{
                                    internshipData.Duration
                                    }}</span>
                            </mat-list-item>


                            <mat-list-item><span fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="70%"
                                    class="lbl-bold">District </span>
                                <span fxFlex="0 1 calc(5%)" fxFlex.lt-md="0 1 calc(5%)" fxFlex.lt-sm="5%">:</span>
                                <span>{{
                                    internshipData.DistrictName
                                    }}</span>
                            </mat-list-item>

                            <mat-list-item><span fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="70%"
                                    class="lbl-bold">Stipend</span>
                                <span fxFlex="0 1 calc(5%)" fxFlex.lt-md="0 1 calc(5%)" fxFlex.lt-sm="5%">:</span>
                                <span>{{
                                    internshipData.Stipend
                                    }}</span>
                            </mat-list-item>

                            <mat-list-item><span fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="70%"
                                    class="lbl-bold">Start Date</span>
                                <span fxFlex="0 1 calc(5%)" fxFlex.lt-md="0 1 calc(5%)" fxFlex.lt-sm="5%">:</span>
                                <span>{{
                                    internshipData.StartDate | date
                                    }}</span>
                            </mat-list-item>



                            <mat-list-item><span fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="70%"
                                    class="lbl-bold">Perks</span>
                                <span fxFlex="0 1 calc(5%)" fxFlex.lt-md="0 1 calc(5%)" fxFlex.lt-sm="5%">:</span>
                                <span>{{
                                    internshipData.Perks
                                    }}</span>
                            </mat-list-item>



                            <mat-list-item><span fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="70%"
                                    class="lbl-bold">IsActive</span>
                                <span fxFlex="0 1 calc(5%)" fxFlex.lt-md="0 1 calc(5%)" fxFlex.lt-sm="5%">:</span>
                                <span>{{
                                    internshipData.IsActive
                                    }}</span>
                            </mat-list-item>

                        </mat-list>
                    </div>

                </div>

            </div>
            <!-- / CONTENT CARD -->
        </div>
        <!-- / CENTER -->
    </div>