import { Component, OnInit, NgZone, ViewEncapsulation, ElementRef } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { FuseUtils } from '@fuse/utils';
import { RouteConstants } from 'app/constants/route.constant'

import { DropdownModel } from 'app/models/dropdown.model';
import { FileUploadModel } from 'app/models/file.upload.model';
import { ReportService } from 'app/reports/report.service';
import { duration } from 'moment';
import { StudentNoticeBoardService } from '../student-notice-board.service';
import { StudentModel } from 'app/main/student/student.model';
import { StudentNoticeBoardModel } from '../student-notice-board.model';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
//import { StudentModel, StudentNoticeBoardModel } from 'app/main/student/student.model';



@Component({
  selector: 'app-create-student-notice-board',
  templateUrl: './create-student-notice-board.component.html',
  styleUrls: ['./create-student-notice-board.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CreateStudentNoticeBoardComponent extends BaseComponent<StudentNoticeBoardModel> implements OnInit {
  studentNoticeBoardForm: FormGroup;

  studentModel: StudentModel;
  studentNoticeBoardModel: StudentNoticeBoardModel;
  id: string;
  eventExpDate: Date;
  eventPublishDate: Date;
  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    public zone: NgZone,
    private route: ActivatedRoute,
    private reportService: ReportService,
    private studentNoticeBoardService: StudentNoticeBoardService,
    private dialogService: DialogService,
    private elRef: ElementRef,
    private formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Set the default account Model
    this.studentModel = new StudentModel();
    this.studentNoticeBoardModel = new StudentNoticeBoardModel();
  }

  ngOnInit(): void {
    this.studentNoticeBoardService.getDropdownforSchool(this.UserModel).subscribe((results) => {
     


      this.route.paramMap.subscribe(params => {

        if (params.keys.length > 0) {
          this.PageRights.ActionType = params.get('actionType');

          if (this.PageRights.ActionType == this.Constants.Actions.New) {
            this.studentNoticeBoardForm = this.createStudentNoticeBoardForm();
            this.studentNoticeBoardModel = new StudentNoticeBoardModel();

          } else {
            var noticeId: string = params.get('noticeId');

            this.studentNoticeBoardService.GetNoticesById(noticeId)
              .subscribe((response: any) => {
                this.studentNoticeBoardModel = response.Result;

                if (this.PageRights.ActionType == this.Constants.Actions.Edit)
                  this.studentNoticeBoardModel.RequestType = this.Constants.PageType.Edit;
                else if (this.PageRights.ActionType == this.Constants.Actions.View) {
                  this.studentNoticeBoardModel.RequestType = this.Constants.PageType.View;
                  this.PageRights.IsReadOnly = true;
                }
                this.studentNoticeBoardForm = this.createStudentNoticeBoardForm();
              });
          }
        }
      });
    });
    this.studentNoticeBoardForm = this.createStudentNoticeBoardForm();
  }

  onChangePublishDate(event: MatDatepickerInputEvent<Date>) {
    //this.events.push(`${type}: ${event.value}`);
    this.eventPublishDate = event.value
    
  }

  onChangeExpDate(event: MatDatepickerInputEvent<Date>) {
    //this.events.push(`${type}: ${event.value}`);
    this.eventExpDate = event.value
    
    if (this.eventPublishDate > this.eventExpDate) {
      this.dialogService.openShowDialog("Expiry date less than Publish date");
      return;
    }
  }

  saveOrCreateOrUpdateStudentNoticeBoard(nB) {

    if (!this.studentNoticeBoardForm.valid) {
      this.validateAllFormFields(this.studentNoticeBoardForm);
      return;
    }
    
    if (this.eventPublishDate > this.eventExpDate) {
      this.dialogService.openShowDialog("Expiry date less than Publish date");
      return;
    }
    

    if (!this.studentNoticeBoardForm.valid) {
      this.validateAllFormFields(this.studentNoticeBoardForm);
      return;
    }

    this.setValueFromFormGroup(this.studentNoticeBoardForm, this.studentNoticeBoardModel);
    this.studentNoticeBoardModel.AuthUserId = this.UserModel.UserId;

    this.studentNoticeBoardService.CreateOrUpdateNotices(this.studentNoticeBoardModel)
      .subscribe((accountResp: any) => {

        if (accountResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );

            if (nB == 'addNB' || nB == 'editNB') {
              this.router.navigate([RouteConstants.StudentNoticeBoard.List]);
            }
            else if (nB == 'addAnotherNB') {
              if (this.PageRights.ActionType == this.Constants.Actions.New) {
                //this.router.navigate([RouteConstants.StudentNoticeBoard.New,'new']);
                // this.router.navigate([RouteConstants.StudentNoticeBoard.List]);
                //this.router.navigate(['./', 'new']);
                this.studentNoticeBoardForm.reset();
              }
              else {
                this.router.navigate([RouteConstants.StudentNoticeBoard.List]);
                //this.router.navigate([RouteConstants.StudentNoticeBoard.New]);
                //this.studentNoticeBoardForm.reset();
              }

            }
          });
        }
        else {
          var errorMessages = this.getHtmlMessage(accountResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('StudentNoticeBoard deletion errors =>', error);
      });
  }

  //Create schoolClass form and returns {FormGroup}
  createStudentNoticeBoardForm(): FormGroup {
    return this.formBuilder.group({
      PublishDate: new FormControl({ value: new Date(this.studentNoticeBoardModel.PublishDate), disabled: this.PageRights.IsReadOnly }, Validators.required),
      ExpiryDate: new FormControl({ value: new Date(this.studentNoticeBoardModel.ExpiryDate), disabled: this.PageRights.IsReadOnly }, Validators.required),
      NoticeDescription: new FormControl({ value: this.studentNoticeBoardModel.NoticeDescription, disabled: this.PageRights.IsReadOnly }),
      IsActive: new FormControl({ value: this.studentNoticeBoardModel.IsActive, disabled: this.PageRights.IsReadOnly }),
      AuthUserId: new FormControl({ value: this.studentNoticeBoardModel.AuthUserId, disabled: this.PageRights.IsReadOnly }),
      NoticeTitle: new FormControl({ value: this.studentNoticeBoardModel.NoticeTitle, disabled: this.PageRights.IsReadOnly }),
    });
  }

  saveAndAddAnotherStudentNoticeBoard(addAnotherNB) {
    if (!this.studentNoticeBoardForm.valid) {
      this.validateAllFormFields(this.studentNoticeBoardForm);
      return;
    }
    this.saveOrCreateOrUpdateStudentNoticeBoard(addAnotherNB);
    //this.router.navigate([RouteConstants.StudentNoticeBoard.New]);
  }

}
