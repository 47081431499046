import { Injectable } from "@angular/core";
import { forkJoin, Observable, interval } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';
import { UserModel } from "app/models/user.model";
import { switchMap } from 'rxjs/operators';

@Injectable()
export class InterfaceService {
    constructor(private http: BaseService) { }

    getApiDataPeriodically(filters: any): Observable<any> {
        return interval(30000) // 30,000 milliseconds (30 seconds)
            .pipe(
                switchMap(() => this.http.HttpPost(this.http.Services.Interface.GetCurrentImportInstanceForState, filters))
            );
    }

    StartSyncingData(requestParams: any) {
        return this.http.HttpPost(this.http.Services.Interface.StartSyncingData, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    GetIntermidiateSchools(requestParams: any) {
        return this.http.HttpPost(this.http.Services.Interface.GetIntermidiateSchools, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    GetSchoolRelatedObjects(requestParams: any) {
        return this.http.HttpPost(this.http.Services.Interface.GetSchoolRelatedObjects, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    getDropdownforInterfaces(userModel: UserModel): Observable<any[]> {

        let stateRequest = this.http.GetMasterDataByType({ DataType: 'StatesByUserId', UserId: userModel.UserTypeId, SelectTitle: 'StateCode' });

        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        return forkJoin([stateRequest]);
    }

}
