<div id="role" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    Messages
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <form [formGroup]="msgForVtSearchForm">
                    <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon>search</mat-icon>
                        <input formControlName="filterText" placeholder="Search Messages">
                        <!-- <input #filter (keyup)="applySearchFilter($event.target.value)" placeholder="Search for a clusters"> -->

                    </div>
                </form>

            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <!-- <button mat-raised-button [routerLink]="['./','new']" skipLocationChange="true" replaceUrl="false" class="add-role-button fuse-white mt-24 mt-md-0">
                <span>{{'ROLE.ADD_NEW_ROLES' | translate}}</span>
            </button> -->
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->
        <!-- Tab Section -->

        <div class="padding-bottom">
            <mat-tab-group selectedIndex="0" (selectedTabChange)="changeTab($event)">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>New Messages</span>
                    </ng-template>

                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Read Messages</span>
                    </ng-template>

                </mat-tab>
            </mat-tab-group>
        </div>
        <!-- /Tab Section -->
        <!-- Filter Section -->

        <!-- <div class="filter-content-card">
            <div class="filter-content">
                <form name="studentFilterForm" [formGroup]="studentFilterForm" class="vc-issue-report" fxLayout="column"
                    fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Academic Year</mat-label>
                            <mat-select formControlName="AcademicYearId" name="academicYearId" appearance="outline">
                                <mat-option [value]="academicYearItem.Id"
                                    *ngFor="let academicYearItem of academicYearList">
                                    {{ academicYearItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Vocational Training </mat-label>

                            <mat-select formControlName="VTPId" name="vtpId" appearance="outline">
                                <mat-option [value]="vItem.Id" *ngFor="let vItem of vtpList">
                                    {{ vItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Sector</mat-label>
                            <mat-select formControlName="SectorId" name="sectorId" appearance="outline">
                                <mat-option [value]="sectorItem.Id" *ngFor="let sectorItem of sectorList">
                                    {{ sectorItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>



                        <div class="clear-filter pr-1" fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <button mat-raised-button class="fuse-white filter-button"><span>SEARCH</span></button>
                            <button mat-raised-button class="fuse-button filter-button"
                                (click)="resetFilters()"><span>CLEAR</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div> -->
        <!-- /Filter Section -->

        <!-- CONTENT CARD -->
        <div class="content-card" style="overflow:auto ;" *ngIf="tabIndex === 0">

            <div *ngIf="newMessageData">
                <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start"
                    style="height: auto !important;">
                    <div fxFlex="0 1 calc(47%)" fxFlex.lt-md="0 1 calc(47%)" fxFlex.lt-sm="100%"
                        *ngFor="let message of newMessageData; index as i;"
                        style="border-radius: 18px;border: 1px solid #dee2e6;padding: 20px;margin: 15px;float: left;word-wrap: break-word;overflow-x: hidden;">
                        <div style="width:100%;float:left;">
                            <div mat-card-avatar class="example-header-image" style="width:80%;float:left;">
                                <div style="width:20%;float:left;">
                                    <img [src]="img+message.EmployerPhoto" alt=""
                                        style="width:50px;float:left;border-radius: 50%;height: 50px">
                                </div>
                                <div style="width:80%;float:left;">
                                    <p class="fs-16 fw-700 mb-0 line-h-20 text-black">{{message.OrganizationName}}</p>
                                </div>
                            </div>
                            <div style="width:10%;float:right;">
                                <button mat-icon-button [matMenuTriggerFor]="menu" style="width:50px;float:right;"
                                    aria-label="Example icon-button with a menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>

                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item>

                                        <span>Action</span>
                                    </button>
                                    <button mat-menu-item>

                                        <span> Another action</span>
                                    </button>
                                    <button mat-menu-item>

                                        <span>Something else here</span>
                                    </button>
                                </mat-menu>
                            </div>
                        </div>
                        <div style="width:100%;float:left; margin: 10px;">
                            <p>
                                {{message.RequestDesc}}
                            </p>
                            <hr />
                            <button mat-button class="fuse-button filter-button"
                                (click)="onDeleteJobRole(message.RequestId)"
                                style="color: blue;background-color: blue;float: right;"><span
                                    style="color: aliceblue;">Mark as
                                    Read</span></button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="newMessageData.length ==[]">


                <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start"
                    style="height: auto !important;">
                    <div fxFlex="0 1 calc(47%)" fxFlex.lt-md="0 1 calc(47%)" fxFlex.lt-sm="100%"
                        style="border-radius: 18px;border: 1px solid #dee2e6;padding: 20px;margin: 15px;float: left;word-wrap: break-word;overflow-x: hidden;">
                        <div>
                            <span>No Data Found</span>

                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="content-card" style="overflow:auto ;" *ngIf="tabIndex === 1">
            <div *ngIf="readMessageData.length ==[]">


                <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start"
                    style="height: auto !important;">
                    <div fxFlex="0 1 calc(47%)" fxFlex.lt-md="0 1 calc(47%)" fxFlex.lt-sm="100%"
                        style="border-radius: 18px;border: 1px solid #dee2e6;padding: 20px;margin: 15px;float: left;">
                        <div>
                            <span>No Data Found</span>

                        </div>

                    </div>
                </div>
            </div>
            <div *ngIf="readMessageData">




                <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start"
                    style="height: auto !important;">
                    <div fxFlex="0 1 calc(47%)" fxFlex.lt-md="0 1 calc(47%)" fxFlex.lt-sm="100%"
                        *ngFor="let message of readMessageData; index as i;"
                        style="border-radius: 18px;border: 1px solid #dee2e6;padding: 20px;margin: 15px;float: left;word-wrap: break-word;overflow-x: hidden;">
                        <div style="width:100%;float:left;">
                            <div mat-card-avatar class="example-header-image" style="width:80%;float:left;">
                                <div style="width:20%;float:left;">
                                    <img [src]="img+message.EmployerPhoto" alt=""
                                        style="width:50px;float:left;border-radius: 50%;">
                                </div>
                                <div style="width:80%;float:left;">
                                    <p class="fs-16 fw-700 mb-0 line-h-20 text-black">{{message.OrganizationName}}</p>
                                </div>
                            </div>
                            <div style="width:10%;float:right;">
                                <button mat-icon-button [matMenuTriggerFor]="menu" style="width:50px;float:right;"
                                    aria-label="Example icon-button with a menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>

                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item>

                                        <span>Action</span>
                                    </button>
                                    <button mat-menu-item>

                                        <span> Another action</span>
                                    </button>
                                    <button mat-menu-item>

                                        <span>Something else here</span>
                                    </button>
                                </mat-menu>
                            </div>
                        </div>
                        <div style="width:100%;float:left; margin: 10px;">
                            <p>
                                {{message.RequestDesc}}
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>