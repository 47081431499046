<div id="internship-details" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row wrap" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <!-- {{ 'Internship Opportunity' | translate }} -->
                    Internship Opportunity Details
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <div fxLayout="row" class="add-edit-button" fxLayoutAlign="flex-start" fxFlex="0 1 calc(50%)"
                fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="100%">

                <button mat-raised-button class="add-internship-button btn-outline-dark"
                    [routerLink]="'/internship-opportunity'">
                    <span>Manage Internship </span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="ms-2" width="20" height="20" viewBox="0 0 24 24"
                        stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"></path>
                        <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3"></path>
                        <line x1="16" y1="5" x2="19" y2="8"></line>
                    </svg>
                </button>

                <button mat-raised-button [routerLink]="['/internship-opportunity','new']"
                    class="add-internship-button btn-warning mls">
                    <span>Add New Opportunity </span><svg xmlns="http://www.w3.org/2000/svg" class="ms-2" width="20"
                        height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="12" y1="5" x2="12" y2="19" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                    </svg>
                </button>

            </div>

        </div>
        <!-- / HEADER -->
        <!-- <div class="padding-bottom">
            <mat-tab-group selectedIndex="0" (selectedTabChange)="changeTab($event)">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Add A Student</span>
                    </ng-template>

                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Add Multiple Student</span>
                    </ng-template>

                </mat-tab>
            </mat-tab-group>
        </div> -->



        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="internshipDetailsForm" [formGroup]="internshipDetailsForm"
                    class="internship w-100-p p-24 mr-24" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto"
                    fxFlex fusePerfectScrollbar>

                    <!-- <div class="required-mark">
                        <span><span class="note">Notes: </span>All fields marked with an asterisk (<span
                                class="asterisk-color">*</span>) are required.</span>
                    </div> -->

                    <!-- <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <p class="medium text-black-600 font-weight-semibold mt-3">Add A Internship</p>
                    </div> -->

                    <div class="row">
                        <div class="col-12 position-relative">
                            <div
                                class="card rounded-12 shadow-dark-80 border border-gray-50 mb-3 mb-xl-5 overflow-hidden position-relative">
                                <div class="position-absolute end-0 top-0 m-2">
                                    <button mat-raised-button type="button"
                                        class="btn btn-lg btn-soft-success cstm-bg-green" style="float: right;
                                        padding: 3%;
                                        font-size: 16px;
                                        width: 100px;
                                        height: 40px;">
                                        <span *ngIf="data.IsPublished==true&&data.IsCompleted==false">Active</span>
                                        <span *ngIf="data.IsCompleted==true">Completed</span>
                                        <span
                                            *ngIf="data.IsPublished==false&&data.IsCompleted==false">Unpublished</span>
                                    </button>
                                </div>
                                <!-- <img src="/src/assets/images/placeholder16.svg" alt="Placeholder"> -->
                                <div class="card-body p-4 pt-5 pt-lg-5 px-lg-3 pb-3 position-relative">
                                    <div class="p-md-3" style="padding: 2%;">
                                        <span
                                            class="avatar avatar-xxl d-flex justify-content-center align-items-center avatar-border-xxl avatar-circle position-absolute top-0 translate-middle-y bg-white border-grey-600 border"
                                            style="position: inherit;">
                                            <img [src]="ProfileImageBaseURL+(data.EmployerPhoto ==''?'no-image':data.EmployerPhoto)"
                                                width="50" height="50" style="border-radius: 50%;" />
                                        </span>
                                        <div class="row border-bottom border-gray-200 pb-3">

                                            <div class="col-12 col-sm-9 col-lg-9">
                                                <div class="d-flex align-items-center justify-content-lg-around justify-content-between flex-wrap"
                                                    *ngIf="data !=undefined">
                                                    <div class="col-12 col-sm-3 col-lg-3 mb-lg-0 mb-20"
                                                        style="width: 40%; float: left;">

                                                        <p class="text-gray-700 mb-1 lh-base fs-15">
                                                            <b>{{data.Title}}</b><br>
                                                            {{data.OrganizationName}}
                                                        </p>

                                                    </div>
                                                    <div class="col-12 col-sm-3 col-lg-3 mb-lg-0 mb-20"
                                                        style="width: 15%; float: left;">
                                                        <p class="text-gray-700 mb-1 lh-base fs-15">
                                                            Internship Type <br><b> {{data.InternshipType}}</b>
                                                        </p>
                                                    </div>

                                                    <div class="col-12 col-sm-3 col-lg-3 mb-lg-0 mb-20"
                                                        style="width: 15%; float: left;">
                                                        <p class="text-gray-700 mb-1 lh-base fs-15">Start
                                                            Date<br><b>{{data.StartDate
                                                                |date}}</b>
                                                        </p>
                                                    </div>

                                                    <div class="col-12 col-sm-3 col-lg-3 mb-lg-0 mb-20"
                                                        style="width: 10%; float: left;">

                                                        <p class="text-gray-700 mb-1 lh-base fs-15">
                                                            Duration<br><b>{{data.Duration}}</b>
                                                        </p>
                                                    </div>

                                                    <div class="col-12 col-sm-3 col-lg-3 mb-lg-0 mb-20"
                                                        style="width: 10%; float: left;">
                                                        <p class="text-gray-700 mb-1 lh-base fs-15">
                                                            Timing<br><b>Morning</b>
                                                        </p>
                                                    </div>

                                                    <div class="col-12 col-sm-3 col-lg-3 mb-lg-0 mb-20"
                                                        style="width: 10%; float: left;">
                                                        <p class="text-gray-700 mb-1 lh-base fs-15">
                                                            No. of Opening<br><b>{{data.NoOfOpenings}}</b>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <a type="button" (click)="onClickViewInternshipDetails()"
                                            class="nav-link fw-600 mb-0 text-center d-block mt-20 active">View
                                            Details</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start"
                        style="height: auto !important;">

                        <div class="row" style="width: 100%;">
                            <span>
                                <h1>Selected Students ({{SelectedStudents}})</h1>
                            </span>
                        </div>
                        <mat-accordion style="width: 100%;">

                            <mat-expansion-panel *ngFor="let value of activeInternData"
                                (click)="GetStudentDataforAccordian(value.StudentId)">
                                <mat-expansion-panel-header>
                                    <span *ngIf="value.StudentProfilePhoto != null">
                                        <img [src]="ProfileImageBaseURL +value.StudentProfilePhoto" width="50"
                                            height="50" style="border-radius: 50%; margin-right: 10%;" />
                                    </span>
                                    <span style="padding-top: 1%;
                                    padding-left: 1.5%;">{{value.StudentName}}-{{value.ClassName}}</span>

                                </mat-expansion-panel-header>

                                <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start"
                                    id="left-content" appearance="outline" fxFlex="32.33%" fxFlex.lt-sm="100%" style="height: auto;height: auto;margin-right: 1%; border: 1px solid #E2E2E2 ;
                                    border-radius: 2%;">
                                    <div fxLayout="row" fxFlex="0 1 calc(100%)"
                                        style="padding-left: 20px;
                                    padding-right: 20px;margin-bottom: 0!important;border-bottom: 1px solid #dee2e6!important;">
                                        <h2>Internship Progress</h2>
                                    </div>

                                    <div fxLayout="row" fxFlex="0 1 calc(100%)" class="px-20">
                                        <span style="width: 100%;">
                                            <h3 style="font-size: 16px; font-weight: 600;">Student Progress</h3>
                                        </span>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="flex-start" fxFlex="0 1 calc(100%)"
                                        *ngIf="progressData !=undefined" style="padding-left: 20px;
                                        padding-right: 20px;
                                    ">
                                        <div fxFlex="0 1 calc(33.33%)">
                                            <p>Points<br> <b>{{progressData.Points}}</b></p>
                                        </div>
                                        <div fxFlex="0 1 calc(33.33%)">
                                            <p>Attendance<br> <b>{{progressData.Attendance}} D</b></p>
                                        </div>
                                        <div fxFlex="0 1 calc(33.33%)">
                                            <p>Internship<br> <b>{{progressData.Hours | slice:0:5}} H</b></p>
                                        </div>
                                    </div>
                                    <ngx-charts-advanced-pie-chart [view]="view" [scheme]="colorScheme"
                                        [results]="single" [gradient]="gradient" (select)="onSelect($event)"
                                        (activate)="onActivate($event)" (deactivate)="onDeactivate($event)"
                                        style="height: 200px;">
                                    </ngx-charts-advanced-pie-chart>
                                </div>

                                <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start"
                                    id="left-content" appearance="outline" fxFlex="65.66%" fxFlex.lt-sm="100%" style="height: auto;height: auto;margin-right: 1%; border: 1px solid #E2E2E2;
                                   border-radius: 2%;">

                                    <!-- CONTENT CARD -->
                                    <div class="content-card" style="width: 100%;">

                                        <mat-table class="xxx" [dataSource]="dailyDiaryDataSource" matSort
                                            fusePerfectScrollbar
                                            [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                                            <!-- LessonsLearnt Column -->
                                            <ng-container matColumnDef="LessonsLearnt">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
                                                    Diary Entry
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let diaryEntry" fxHide fxShow.gt-xs>
                                                    <p class="text-truncate">
                                                        <span *ngFor="let attItem of diaryEntry.Attachments">
                                                            <a href="{{ imageBasePath + attItem.AttachmentPath }}">
                                                                <mat-icon *ngIf="attItem.AttachmentType=='PDF||pdf'">
                                                                    picture_as_pdf</mat-icon>
                                                                <mat-icon *ngIf="attItem.AttachmentType=='Image'">image
                                                                </mat-icon>
                                                            </a>
                                                        </span><br>
                                                        {{diaryEntry.LessonsLearnt}}
                                                    </p>
                                                </mat-cell>
                                            </ng-container>

                                            <!-- Hours Column -->
                                            <ng-container matColumnDef="Hours">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm
                                                    fxShow.gt-md>Hour Spent
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let diaryEntry">
                                                    <p class="text-truncate"><b>{{diaryEntry.Hours}} H </b></p>
                                                </mat-cell>
                                            </ng-container>

                                            <!-- Highlight Column -->
                                            <ng-container matColumnDef="Highlight">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
                                                    Highlight
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let diaryEntry" fxHide fxShow.gt-xs>
                                                    <p class="text-truncate">{{diaryEntry.Highlight}}</p>
                                                </mat-cell>
                                            </ng-container>

                                            <ng-container matColumnDef="Actions" stickyEnd>
                                                <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let diaryEntry" fxShow.gt-xs>
                                                    <button mat-icon-button
                                                        aria-label="Example icon-button with a menu">
                                                        <mat-icon>more_vert</mat-icon>
                                                    </button>
                                                    <!-- <button mat-icon-button [matMenuTriggerFor]="menu"
                                                        aria-label="Example icon-button with a menu">
                                                        <mat-icon>more_vert</mat-icon>
                                                    </button> -->
                                                    <!-- <mat-menu #menu="matMenu">
                                                        <button mat-menu-item
                                                            (click)="onClickMarkInternshipCompleted(diaryEntry.InternshipId)">
                                                            <mat-icon>done</mat-icon>
                                                            <span>Complete</span>
                                                        </button>
                                                        <button mat-menu-item>
                                                            <span>No Actions</span>
                                                        </button>
                                                        <button mat-menu-item
                                                            (click)="onClickPublishInternship(diaryEntry.InternshipId)">
                                                            <mat-icon>publish</mat-icon>
                                                            <span>Publish</span>
                                                        </button>
                                                    </mat-menu> -->
                                                </mat-cell>
                                            </ng-container>

                                            <mat-header-row *matHeaderRowDef="dailyDiaryDisplayedColumns; sticky:true">
                                            </mat-header-row>

                                            <mat-row *matRowDef="let diaryEntry; columns: dailyDiaryDisplayedColumns;"
                                                class="diaryEntry">
                                            </mat-row>

                                            <!-- Group header -->
                                            <ng-container matColumnDef="groupHeader">
                                                <td colspan="999" mat-cell *matCellDef="let groupBy"><strong>
                                                        {{groupBy.date | date}}</strong></td>
                                            </ng-container>

                                            <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup">
                                            </tr>
                                        </mat-table>

                                        <!-- <a mat-flat-button [routerLink]="['/diary-entry']" class="btn-soft-primary">View
                                            Details</a> -->
                                            <a mat-flat-button (click)="onClickViewStudentDiaryEnteryDetails(value.StudentName)" class="btn-soft-primary">View
                                                Details</a>
                                        <!-- <mat-paginator #ListPaginator [length]="SearchBy.TotalResults"
                                                                                    [pageIndex]="SearchBy.PageIndex" [pageSize]="SearchBy.PageSize"
                                                                                    [pageSizeOptions]="SearchBy.PageSizeOptions"
                                                                                    [showFirstLastButtons]="SearchBy.ShowFirstLastButtons"
                                                                                    (page)="onPageIndexChanged($event)">
                                                                                </mat-paginator> -->

                                    </div>
                                    <!-- / CONTENT CARD -->
                                </div>

                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>

                    <!-- </div> -->

                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start"
                        style="height: auto !important;">

                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                            appearance="outline" fxFlex="0 1 calc(49%)" fxFlex.lt-md="0 1 calc(49%)" fxFlex.lt-sm="100%"
                            style="height: auto;height: auto;margin-right: 1%;">
                            <div fxLayout="row" style="width: 100%;">
                                <h2>Internship Application</h2>
                            </div>

                            <!-- CONTENT CARD -->
                            <div class="content-card" style="width: 100%;">

                                <mat-table class=" xxx " [dataSource]="tableDataSource" matSort fusePerfectScrollbar
                                    [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                                    <!-- StudentName Column -->
                                    <ng-container matColumnDef="StudentName">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>
                                            Name
                                            <!--  StudentName -->
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let internshipApp">
                                            <span *ngIf="internshipApp.StudentProfilePhoto != null"><img
                                                    [src]="ProfileImageBaseURL +internshipApp.StudentProfilePhoto"
                                                    width="50" height="50"
                                                    style="border-radius: 50%;margin-right: 10%;" />

                                                <mat-icon *ngIf="internshipApp.StudentProfilePhoto==null">face
                                                </mat-icon>
                                            </span>
                                            <p class="text-truncate" style="margin-left: 10%;">
                                                <b>{{internshipApp.StudentName}}</b><br>
                                                {{internshipApp.Mobile}}
                                            </p>

                                        </mat-cell>
                                    </ng-container>

                                    <!-- InternshipStartDate Column -->
                                    <ng-container matColumnDef="InternshipStartDate">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
                                            Date
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let internshipApp" fxHide fxShow.gt-xs>
                                            <p class="text-truncate">{{internshipApp.InternshipStartDate |date}}
                                            </p>
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="Status">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>
                                            Status
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let internshipApp">
                                            <p class="text-truncate" [class.hire]="hire">
                                                {{internshipApp.Status}}</p>
                                            <P *ngIf="internshipApp.Status==''">Pending</P>
                                        </mat-cell>


                                    </ng-container>

                                    <ng-container matColumnDef="Actions" stickyEnd>
                                        <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                                        <mat-cell *matCellDef="let internshipApp" fxShow.gt-xs>

                                            <button mat-raised-button
                                                (click)="onClickSendToEmployer(internshipApp.StudentId,internshipApp.InternshipId)"
                                                class="btn-send-to-employer"> Send to Employer

                                            </button>

                                            <button mat-icon-button [matMenuTriggerFor]="menu"
                                                aria-label="Example icon-button with a menu">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu">
                                                <!-- <button mat-menu-item 
                                                    (click)="onClickMarkInternshipCompleted(internshipApp.InternshipId)">
                                                    <mat-icon>done</mat-icon>
                                                    <span>Complete</span>
                                                </button> -->
                                                <button mat-menu-item>
                                                    <span>No Actions</span>
                                                </button>
                                                <button mat-menu-item
                                                    (click)="onClickPublishInternship(internshipApp.InternshipId)">
                                                    <mat-icon>publish</mat-icon>
                                                    <span>Publish</span>
                                                </button>
                                            </mat-menu>
                                        </mat-cell>
                                    </ng-container>

                                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                                    <mat-row *matRowDef="let internshipApp; columns: displayedColumns;"
                                        class="internshipApp" matRipple>
                                    </mat-row>


                                    <!-- <ng-container matColumnDef="groupHeader">
                                        <td colspan="999" mat-cell *matCellDef="let groupBy"><strong>
                                                {{groupBy.date | date}}</strong></td>
                                    </ng-container>

                                    <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"> </tr> -->


                                </mat-table>

                                <mat-paginator #ListPaginator [length]="SearchBy.TotalResults"
                                    [pageIndex]="SearchBy.PageIndex" [pageSize]="SearchBy.PageSize"
                                    [pageSizeOptions]="SearchBy.PageSizeOptions"
                                    [showFirstLastButtons]="SearchBy.ShowFirstLastButtons"
                                    (page)="onPageIndexChanged($event)">
                                </mat-paginator>

                            </div>

                            <!-- / CONTENT CARD -->

                        </div>

                        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="right-content"
                            appearance="outline" fxFlex="0 1 calc(49%)" fxFlex.lt-md="0 1 calc(49%)" fxFlex.lt-sm="100%"
                            style="height: auto;height: auto;margin-left: 1%;">
                            <div fxLayout="row" style="width: 100%;">
                                <h2>Application Status</h2>
                            </div>

                            <!-- CONTENT CARD -->
                            <div class=" content-card" style="width: 100%;">

                                <mat-table class=" xxx " [dataSource]="dataSource" matSort fusePerfectScrollbar
                                    [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                                    <!-- StudentName Column -->
                                    <ng-container matColumnDef="StudentName">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>
                                            Name
                                            <!--  StudentName -->
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let internshipApp">
                                            <span *ngIf="internshipApp.StudentProfilePhoto!=null"><img
                                                    [src]="ProfileImageBaseURL +internshipApp.StudentProfilePhoto"
                                                    width="50" height="50"
                                                    style="border-radius: 50%;margin-right: 10%;" />
                                                <mat-icon *ngIf="internshipApp.StudentProfilePhoto==null">face
                                                </mat-icon>
                                            </span>
                                            <p class="text-truncate" style="margin-left: 10%;">
                                                <b>{{internshipApp.StudentName}}</b><br>
                                                {{internshipApp.Mobile}}
                                            </p>

                                        </mat-cell>
                                    </ng-container>

                                    <!-- InternshipTitle Column -->
                                    <!-- <ng-container matColumnDef="InternshipTitle">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
                                            Opportunity
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let internshipApp" fxHide fxShow.gt-xs>
                                            <p class="text-truncate">{{internshipApp.InternshipTitle}}
                                                <br>{{internshipApp.EmployerName}}
                                            </p>
                                        </mat-cell>
                                    </ng-container> -->

                                    <ng-container matColumnDef="Status">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>
                                            Status
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let internshipApp">
                                            <p class="text-truncate" [class.hire]="hire">
                                                {{internshipApp.Status}}</p>
                                            <P *ngIf="internshipApp.Status==''">Pending</P>
                                        </mat-cell>


                                    </ng-container>

                                    <ng-container matColumnDef="Actions" stickyEnd>
                                        <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                                        <mat-cell *matCellDef="let internshipApp" fxShow.gt-xs>

                                            <button mat-raised-button [disabled]="internshipApp.Status==='Hired'"
                                                (click)="onClickHireStudent(internshipApp.StudentId,internshipApp.InternshipId)"
                                                ngClass="{{internshipApp.Status==='Hired' ? 'btn-disabled' : 'btn-send-to-employer'}}">
                                                Hire
                                            </button>

                                            <button mat-icon-button [matMenuTriggerFor]="menu"
                                                aria-label="Example icon-button with a menu">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu">
                                                <!-- <button mat-menu-item 
                                                    (click)="onClickMarkInternshipCompleted(internshipApp.InternshipId)">
                                                    <mat-icon>done</mat-icon>
                                                    <span>Complete</span>
                                                </button> -->
                                                <button mat-menu-item>
                                                    <span>No Actions</span>
                                                </button>
                                                <button mat-menu-item
                                                    (click)="onClickPublishInternship(internshipApp.InternshipId)">
                                                    <mat-icon>publish</mat-icon>
                                                    <span>Publish</span>
                                                </button>
                                            </mat-menu>
                                        </mat-cell>
                                    </ng-container>

                                    <mat-header-row *matHeaderRowDef="displayedColumns1; sticky:true"></mat-header-row>

                                    <mat-row *matRowDef="let internshipApp; columns: displayedColumns1;"
                                        class="internshipApp" matRipple>
                                    </mat-row>


                                    <!-- <ng-container matColumnDef="groupHeader">
                                        <td colspan="999" mat-cell *matCellDef="let groupBy"><strong>
                                                {{groupBy.date | date}}</strong></td>
                                    </ng-container>

                                    <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"> </tr> -->


                                </mat-table>

                                <mat-paginator #ListPaginator [length]="SearchBy.TotalResults"
                                    [pageIndex]="SearchBy.PageIndex" [pageSize]="SearchBy.PageSize"
                                    [pageSizeOptions]="SearchBy.PageSizeOptions"
                                    [showFirstLastButtons]="SearchBy.ShowFirstLastButtons"
                                    (page)="onPageIndexChanged($event)">
                                </mat-paginator>

                            </div>

                            <!-- / CONTENT CARD -->
                        </div>
                    </div>




                    <!-- </mat-tab>
                    </mat-tab-group> -->
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>