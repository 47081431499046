import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';
import { UserModel } from "app/models/user.model";

@Injectable()
export class StudentService {
    constructor(private http: BaseService) { }

    getSchools(): Observable<any> {
        return this.http
            .HttpGet(this.http.Services.School.GetAll)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetAllByCriteria(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.School.GetAllByCriteria, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetStudentsByCriteria(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.Students.GetStudentsByCriteria, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetStudentsById(requestParams: any) {


        return this.http.HttpPost(this.http.Services.Students.GetStudentsByStudentIdAY, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    getFinalInternshipReport(studentId: string) {
        let requestParams = {
            DataId: studentId,
        };
        return this.http.HttpPost(this.http.Services.Students.GetFinalInternshipReport, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    hireStudent(studentId: string, internshipId: string) {
        let requestParams = {
          DataId: studentId,
          DataId1: internshipId
        };
    
        return this.http.HttpPost(this.http.Services.InternshipApproval.HireStudent, requestParams)
          .pipe(
            retry(this.http.Services.RetryServieNo),
            catchError(this.http.HandleError),
            tap(function (response: any) {
              return response.Results;
            })
          );
      }

    GetDailyDiaryImageAttachments(requestParams: any) {


        return this.http.HttpPost(this.http.Services.Students.GetDailyDiaryImageAttachments, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }
    GetAttachmentsByAccountId(requestParams: any) {


        return this.http.HttpPost(this.http.Services.Students.GetAttachmentsByAccountId, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    GetStudentDashboardInternshipData(requestParams: any) {
        return this.http.HttpPost(this.http.Services.Students.GetStudentDashboardInternshipData, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    GetDailyDiaryEntryByStudentId(requestParams: any) {
        return this.http.HttpPost(this.http.Services.Students.GetDailyDiaryEntryByStudentId, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    GetRequestsByStudentIdRequestType(requestParams: any) {
        return this.http.HttpPost(this.http.Services.Students.GetRequestsByStudentIdRequestType, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }
    GetStudentInternshipsByCriteria(requestParams: any) {
        return this.http.HttpPost(this.http.Services.Students.GetStudentInternshipsByCriteria, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    RejectInternshipApplication(requestParams: any) {
        return this.http.HttpPost(this.http.Services.Students.RejectInternshipApplication, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    CreateStudents(formData: any) {
        return this.http
            .HttpPost(this.http.Services.Students.CreateStudents, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    DeleteStudentsById(Students: string) {
        let studentParams = {
            DataId: Students
        };

        return this.http
            .HttpPost(this.http.Services.Students.DeleteStudentsById, studentParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }
    SetActionTaken(RequestId: string) {
        let studentParams = {
            DataId: RequestId
        };

        return this.http
            .HttpPost(this.http.Services.Requests.SetActionTaken, studentParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    getInternProgressDashBoard(StudentId: string, InternshipId: string) {
        let requestParams = {

            DataId: StudentId,
            DataId1: InternshipId
        };

        return this.http.HttpPost(this.http.Services.InternshipDetails.GetInternProgressDashBoard, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    getDropdownforSchool(userModel: UserModel): Observable<any[]> {
        let schoolCategoryRequest = this.http.GetMasterDataByType({ DataType: 'SchoolCategories', SelectTitle: 'School Category' });
        let academicYearRequest = this.http.GetMasterDataByType({ DataType: 'AcademicYearsByState', UserId: userModel.UserTypeId, SelectTitle: 'Academic Year' });
        let schoolTypeRequest = this.http.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'SchoolType', SelectTitle: 'School Type' });
        let stateRequest = this.http.GetMasterDataByType({ DataType: 'StatesByUserId', UserId: userModel.UserTypeId, SelectTitle: 'StateCode' });
        let schoolManagementRequest = this.http.GetMasterDataByType({ DataType: 'BasicList', ParentId: 'SchoolManagement', SelectTitle: 'School Management' });
        let statecodeRequest = this.http.GetMasterDataByType({ DataType: 'StatesByUserId', UserId: userModel.UserTypeId, SelectTitle: 'StateCode' });
        let genderRequest = this.http.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'StudentGender', SelectTitle: 'Gender' });
        let schoolRequest = this.http.GetMasterDataByType({ DataType: userModel.RoleCode === 'VT' ? 'SchoolsByVT' : 'Schools', UserId: userModel.UserTypeId, ParentId: userModel.RoleCode === 'VT' ? userModel.UserTypeId : null, SelectTitle: 'School' });
        let socialCategoryRequest = this.http.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'SocialCategory', SelectTitle: 'Social Category' });
        let religionRequest = this.http.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'Religion', SelectTitle: 'Religion' });
        let vtpRequest = this.http.GetMasterDataByType({ DataType: 'VocationalTrainers', SelectTitle: 'VT' });
        let sectorRequest = this.http.GetMasterDataByType({ DataType: 'Sectors', SelectTitle: 'Sector' });
        let streamRequest = this.http.GetMasterDataByType({ DataType:'DataValues', ParentId: 'StreamOfEducation', SelectTitle: 'Stream' },
            false);




        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        return forkJoin([schoolCategoryRequest, academicYearRequest, schoolTypeRequest, stateRequest, schoolManagementRequest, statecodeRequest, genderRequest, schoolRequest, socialCategoryRequest, religionRequest, vtpRequest, sectorRequest, streamRequest]);
    }
}
