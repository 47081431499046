import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';
import { UserModel } from "app/models/user.model";

@Injectable()
export class SchoolVTPVCMappingService {
    constructor(private http: BaseService) { }

    getSectorJobRoles(): Observable<any> {
        return this.http
            .HttpGet(this.http.Services.SchoolVTPVCMapping.GetAll)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetAllByCriteria(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.SchoolVTPVCMapping.GetAllByCriteria,filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    getSchoolVTPVCMappingById(schoolVTPVCId: string) {
        let requestParams = {
            DataId: schoolVTPVCId
        };

        return this.http
            .HttpPost(this.http.Services.SchoolVTPVCMapping.GetById, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    createOrUpdateSchoolVTPVCMapping(formData: any) {
        return this.http
            .HttpPost(this.http.Services.SchoolVTPVCMapping.CreateOrUpdate, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    deleteSchoolVTPVCMappingById(schoolVTPVCId: string) {
        var schoolVTPVCIdParams = {
            DataId: schoolVTPVCId
        };

        return this.http
            .HttpPost(this.http.Services.SchoolVTPVCMapping.Delete, schoolVTPVCIdParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }
    getAllRoles(): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.RoleTransaction.GetAllRoles, {})
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    getDropdownforSectorJobRole(userModel: UserModel): Observable<any[]> {
        let sectorRequest = this.http.GetMasterDataByType({ DataType: 'Sectors', SelectTitle: 'Sector' });
        let jobRoleRequest = this.getAllRoles();
        let schoolRequest = this.http.GetMasterDataByType({ DataType: 'SchoolSectorJobRole', ParentId: userModel.UserTypeId, SelectTitle: 'School' });
        let academicYearRequest = this.http.GetMasterDataByType({ DataType: 'AcademicYearsByState', UserId:userModel.UserTypeId,SelectTitle: 'Academic Year' });
        let vtpRequest = this.http.GetMasterDataByType({ DataType: 'VocationalTrainingProviders',UserId: userModel.LoginId,ParentId:userModel.UserTypeId, SelectTitle: 'VTP' });
        let statecodeRequest = this.http.GetMasterDataByType({ DataType: 'StatesByUserId', UserId:userModel.UserTypeId, SelectTitle: 'StateCode' });
        let vcRequest = this.http.GetMasterDataByType({ DataType: 'VocationalCoordinators', ParentId: null, SelectTitle: 'Vocational Coordinator' });
        
        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        return forkJoin([sectorRequest, jobRoleRequest,schoolRequest,academicYearRequest,vtpRequest,statecodeRequest]);
    }
}
