<div id="vt-school-sector" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    {{'VT_SCHOOL_SECTOR.VT_SCHOOL_SECTORS' | translate}}
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <form [formGroup]="vtSchoolSectorForm">
                    <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon>search</mat-icon>
                        <input formControlName="filterText" placeholder="Search for a schools">
                    </div>
                </form>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">
                <button *ngIf="PageRights.IsAdd" mat-raised-button [routerLink]="['./','new']" skipLocationChange="true"
                    replaceUrl="false" class="add-vt-school-sector-button fuse-white mt-24 mt-md-0">
                    <span>{{'VT_SCHOOL_SECTOR.ADD_NEW_VT_SCHOOL_SECTORS' | translate}}</span>
                </button>

                <a *ngIf="PageRights.IsExport" class="export-excel" (click)="exportExcel()">
                    <mat-icon> cloud_download</mat-icon>
                </a>
            </div>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table class="vt-school-sector-table responsive-table" #table [dataSource]="tableDataSource" matSort
                fusePerfectScrollbar [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- AcademicYear Column -->
                <ng-container matColumnDef="AcademicYear">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Academic Year
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtSchoolSector" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vtSchoolSector.AcademicYear}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VCName Column -->
                <ng-container matColumnDef="VCName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VC Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtSchoolSector">
                        <p class="text-truncate">{{vtSchoolSector.VCName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VTName Column -->
                <ng-container matColumnDef="VTName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VT Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtSchoolSector">
                        <p class="text-truncate">{{vtSchoolSector.VTName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VTEmailId Column -->
                <ng-container matColumnDef="VTEmailId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VT Email ID
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtSchoolSector">
                        <p class="text-truncate">{{vtSchoolSector.VTEmailId}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SchoolName Column -->
                <ng-container matColumnDef="SchoolName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>School
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtSchoolSector">
                        <p class="text-truncate">{{vtSchoolSector.SchoolName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- UDISE Column -->
                <ng-container matColumnDef="UDISE">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>UDISE
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtSchoolSector">
                        <p class="text-truncate">{{vtSchoolSector.UDISE}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SectorName Column -->
                <ng-container matColumnDef="SectorName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Sector
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtSchoolSector" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vtSchoolSector.SectorName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- DateOfAllocation Column -->
                <ng-container matColumnDef="DateOfAllocation">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Date Of Allocation
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtSchoolSector" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vtSchoolSector.DateOfAllocation | date: Constants.ShortDateFormat}}
                        </p>
                    </mat-cell>
                </ng-container>

                <!-- IsActive Column -->
                <ng-container matColumnDef="IsActive">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Is Active?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vtSchoolSector" fxHide fxShow.gt-xs>
                        <mat-icon *ngIf="vtSchoolSector.IsActive" class="active-icon green-600 s-16">check</mat-icon>
                        <mat-icon *ngIf="!vtSchoolSector.IsActive" class="active-icon red-500 s-16">close</mat-icon>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Actions" stickyEnd>
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let vtSchoolSector" fxShow.gt-xs>
                        <button *ngIf="PageRights.IsView" mat-icon-button
                            [routerLink]="['/vt-school-sectors', 'view', vtSchoolSector.VTSchoolSectorId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">visibility</mat-icon>
                        </button>
                        <button *ngIf="PageRights.IsEdit" mat-icon-button
                            [routerLink]="['/vt-school-sectors', 'edit', vtSchoolSector.VTSchoolSectorId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">edit</mat-icon>
                        </button>
                        <button mat-icon-button color="warn"
                            (click)="onDeleteVTSchoolSector(vtSchoolSector.VTSchoolSectorId)"
                            *ngIf="PageRights.IsDelete">
                            <mat-icon>delete_outline</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let vtSchoolSector; columns: displayedColumns;" class="vt-school-sector" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="SearchBy.TotalResults" [pageIndex]="SearchBy.PageIndex"
                [pageSize]="SearchBy.PageSize" [pageSizeOptions]="SearchBy.PageSizeOptions"
                [showFirstLastButtons]="SearchBy.ShowFirstLastButtons" (page)="onPageIndexChanged($event)">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>