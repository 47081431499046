import { FuseUtils } from '@fuse/utils';

export class SchoolSectorJobRoleVTPModel {
    SchoolSectorJobRolesVTPId: string;
    SchoolSectorJobRolesId:string;
    VTPId: string;
    AcademicYearId: string;
    DateOfAllocation: Date;
    DateOfDeAllocation:Date
    Remarks: string;    
    IsActive: boolean;
    RequestType: any;
    StateCode: string; // New Parameters
    DateOfResignation:Date// New Parameters



    constructor(schoolSectorJobRoleVTPModelItem?: any) {
        schoolSectorJobRoleVTPModelItem = schoolSectorJobRoleVTPModelItem || {};

        this.SchoolSectorJobRolesVTPId = schoolSectorJobRoleVTPModelItem.SchoolSectorJobRolesVTPId || FuseUtils.NewGuid();
        this.SchoolSectorJobRolesId = schoolSectorJobRoleVTPModelItem.SchoolSectorJobRolesId || '';
        this.VTPId = schoolSectorJobRoleVTPModelItem.VTPId || '';
        this.AcademicYearId = schoolSectorJobRoleVTPModelItem.AcademicYearId || '';
        this.DateOfAllocation = schoolSectorJobRoleVTPModelItem.DateOfAllocation || '';
        this.DateOfDeAllocation = schoolSectorJobRoleVTPModelItem.DateOfDeAllocation || '';
        this.Remarks = schoolSectorJobRoleVTPModelItem.Remarks || '';
        this.IsActive = schoolSectorJobRoleVTPModelItem.IsActive || true;
        this.StateCode = schoolSectorJobRoleVTPModelItem.StateCode || '';
        this.DateOfResignation = schoolSectorJobRoleVTPModelItem.DateOfResignation || '';

        this.RequestType = 0; // New
    }
}
