import { FuseUtils } from '@fuse/utils';

export class SchoolClassSectionsModel {
    HMId: string;
    SchoolClassSectionId: string;
    SchoolId: string;
    ClassId: string;
    SectionId: string;
    Remarks: string;
    StateCode: string; // New Parameters
    AcademicYearId:string;
    
    IsActive: boolean;
    RequestType: any;

    constructor(schoolClassSectionsItem?: any) {
        schoolClassSectionsItem = schoolClassSectionsItem || {};

        this.HMId = schoolClassSectionsItem.HMId || FuseUtils.NewGuid();
        this.SchoolClassSectionId = schoolClassSectionsItem.SchoolClassSectionId || FuseUtils.NewGuid();
        this.SchoolId = schoolClassSectionsItem.SchoolId || '';
        this.ClassId = schoolClassSectionsItem.ClassId || '';
        this.SectionId = schoolClassSectionsItem.SectionId || '';
        this.Remarks = schoolClassSectionsItem.Remarks || '';
        this.StateCode = schoolClassSectionsItem.StateCode || '';

     
        this.IsActive = schoolClassSectionsItem.IsActive || true;
        this.RequestType = 0; // New
    }
}
