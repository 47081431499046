import { FuseUtils } from "@fuse/utils";

export class ResourceStudyMaterialModel {
    IsActive: boolean;
    ResourceId: string;
    ResourceTitle: string;
    ResourceType: string;
    ResourceCategoryId: string;
    Category: string;
    ResourceSubcategoryId: string;
    SubCategory: string;
    ResourceDescription: string;
    ResourceLink: any;
    TargetAudienceType: string;
    ResourcesImageUrl: string;
    RequestType: any;
    PublishDate:Date;
    StateCode: string;
    CreatedBy:string;

    constructor(resourceStudyMaterialItem?: any) {
        resourceStudyMaterialItem = resourceStudyMaterialItem || {};
        this.IsActive = resourceStudyMaterialItem.IsActive || false;
        this.ResourceId = resourceStudyMaterialItem.ResourceId || '00000000-0000-0000-0000-000000000000';
        this.ResourceTitle = resourceStudyMaterialItem.ResourceTitle || '';
        this.ResourceType = resourceStudyMaterialItem.ResourceType || '';
        this.ResourceCategoryId = resourceStudyMaterialItem.ResourceCategoryId || FuseUtils.NewGuid();
        this.Category = resourceStudyMaterialItem.Category || '';
        this.ResourceSubcategoryId = resourceStudyMaterialItem.ResourceSubcategoryId || null;
        this.SubCategory = resourceStudyMaterialItem.SubCategory || '';
        this.ResourceDescription = resourceStudyMaterialItem.ResourceDescription || '';
        this.ResourceLink = resourceStudyMaterialItem.ResourceLink || '';
        this.TargetAudienceType = resourceStudyMaterialItem.TargetAudienceType || '';
        this.ResourcesImageUrl = resourceStudyMaterialItem.ResourcesImageUrl || '';
        this.PublishDate = resourceStudyMaterialItem.PublishDate || '';
        this.RequestType = 0; // New Parameters
        this.StateCode = resourceStudyMaterialItem.StateCode || '';
        this.CreatedBy = resourceStudyMaterialItem.CreatedBy || '';
    }
}
