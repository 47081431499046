export class InternshipAssignmentModel {
    InternshipId: String
    InternshipTitle: string;
    OrganizationName: string;
    ReportDate: string;
    ReportDetail: string;
    RequestType: any;
    AuthUserId: string; // New Parameters
    StudentID: boolean;
    StudentMobile: string;
    StudentName: string;
    StudentPhoto: string;
    listBestPicture: any;
    listCertificate: any;
    listReportPhoto: any;
    constructor(studentNoticeBoardItem?: any) {
        studentNoticeBoardItem = studentNoticeBoardItem || {};
        //this.NoticeId = studentNoticeBoardItem.NoticeId || FuseUtils.NewGuid();
        this.InternshipId = studentNoticeBoardItem.NoticeId || '00000000-0000-0000-0000-000000000000';
        this.InternshipTitle = studentNoticeBoardItem.SectorId || '';
        this.OrganizationName = studentNoticeBoardItem.OrganizationName || '';
        this.ReportDate = studentNoticeBoardItem.ReportDate || '';
        this.RequestType = 0; // New Parameters
        this.AuthUserId = studentNoticeBoardItem.AuthUserId || '';
        this.ReportDetail = studentNoticeBoardItem.ReportDetail || '';
        this.StudentID = studentNoticeBoardItem.StudentID || '';
        this.StudentName = studentNoticeBoardItem.NoticeType || '';
        this.StudentPhoto= studentNoticeBoardItem.NoticeType || '';
        this.listBestPicture= studentNoticeBoardItem.NoticeType || [];
        this.listCertificate= studentNoticeBoardItem.NoticeType || [];
        this.listReportPhoto= studentNoticeBoardItem.NoticeType || [];

    }
}



