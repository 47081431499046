<div id="vt-student-exit-survey-detail" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/vt-student-exit-survey-details'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        {{ vtStudentExitSurveyDetailModel.Name }}
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        New VT Student Exit Survey Detail
                    </div>
                    <div class="subtitle secondary-text">
                        <span>VT Student Exit Survey Detail Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-vt-student-exit-survey-detail-button"
                *ngIf="PageRights.ActionType ==='add'" (click)="saveOrUpdateVTStudentExitSurveyDetailDetails()">
                <span>ADD</span>
            </button>

            <button mat-raised-button class="save-vt-student-exit-survey-detail-button"
                *ngIf="PageRights.ActionType ==='edit'" (click)="saveOrUpdateVTStudentExitSurveyDetailDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="vtStudentExitSurveyDetailForm" [formGroup]="vtStudentExitSurveyDetailForm"
                    class="vt-student-exit-survey-detail w-100-p p-24 mr-24" fxLayout="column" fxLayoutAlign="start"
                    fxFlex="1 0 auto" fxFlex fusePerfectScrollbar>
                    <!-- <mat-tab label="Basic Info"> -->
                    <!-- formGroupName="vtStudentDetailForm" -->
                    <div>
                        <div class="vtStudentDetailForm" formGroupName="studentDetailGroup">
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                    <mat-label>Student Full Name</mat-label>
                                    <input matInput name="studentFullName" formControlName="StudentFullName">
                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">


                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <mat-label>Father Name</mat-label>
                                    <input matInput name="fatherName" formControlName="FatherName">
                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Roll No./ Seat No. of the Student provided by Board</mat-label>
                                    <input matInput name="StudentUniqueId" formControlName="StudentUniqueId" digitOnly
                                        >
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Name of the School</mat-label>
                                    <input matInput name="NameOfSchool" formControlName="NameOfSchool">
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>School UDISE</mat-label>
                                    <input matInput name="UdiseCode" formControlName="UdiseCode">
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <mat-label>District</mat-label>
                                    <input matInput name="District" formControlName="District">
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <mat-label>Select Class</mat-label>
                                    <input matInput name="Class" formControlName="Class">
                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Gender</mat-label>
                                    <input matInput name="Gender" formControlName="Gender">
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Date Of Birth</mat-label>
                                    <input matInput [matDatepicker]="dateOfBirth" name="DOB"
                                        formControlName="DOB" [disabled]="IsDisabled">
                                    <mat-datepicker-toggle matSuffix [for]="dateOfBirth"></mat-datepicker-toggle>
                                    <mat-datepicker #dateOfBirth></mat-datepicker>
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <mat-label>Select Social Category</mat-label>
                                    <input matInput name="Category" formControlName="Category">
                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Select Sector</mat-label>
                                    <mat-select formControlName="Sector" required appearance="outline"
                                    (selectionChange)="onChangeSector($event.value)" [disabled]="IsSectorDisabled">
                                    <mat-option [value]="sectorItem.Name" *ngFor="let sectorItem of sectorList">
                                        {{ sectorItem.Name }}
                                    </mat-option>
                                </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Select Job Role</mat-label>
                                    <mat-select name="JobRole" formControlName="JobRole" required appearance="outline"
                                    [disabled]="IsSectorDisabled">
                                    <mat-option [value]="jobRoleItem.Name" *ngFor="let jobRoleItem of jobRoleList">
                                        {{ jobRoleItem.Name }}
                                    </mat-option>
                                </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Select VTP</mat-label>
                                    <input matInput name="VTPId" formControlName="VTPId">
                              
                                </mat-form-field>
                            </div>
                                  <!-- <mat-select formControlName="VTPId" appearance="outline" [disabled]="IsDisabled">
                                        <mat-option [value]="vtpItem.Id" *ngFor="let vtpItem of vtpList">
                                            {{ vtpItem.Name }}
                                        </mat-option>
                                    </mat-select> -->
                        </div>
                    </div>
                    <!-- Actual Survey Form -->

                    <!-- <div class="ExitSurveyForm" *ngIf="PageRights.ActionType !=='new'"> -->

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Date Of Interview</mat-label>
                            <input matInput name="dateOfIntv" formControlName="DateOfIntv" [matDatepicker]="date"
                                required [disabled]="PageRights.IsReadOnly" [max]="CurrentDate">
                            <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                            <mat-datepicker #date></mat-datepicker>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.DateOfIntv.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'DateOfIntv')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Enter Village/City/Town of Residence</mat-label>
                            <input matInput name="CityOfResidence" formControlName="CityOfResidence"
                                [disabled]="PageRights.IsReadOnly">
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.CityOfResidence.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'CityOfResidence')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>District Of Residence</mat-label>
                            <mat-select name="districtCode" formControlName="DistrictOfResidence" required
                                    appearance="outline" [disabled]="PageRights.IsReadOnly"
                                    (selectionChange)="onChangeDistrict($event.value)">
                                    <mat-option *ngFor="let districtItem of districtList" [value]="districtItem.Name">
                                        {{districtItem.Name}}
                                    </mat-option>
                                </mat-select>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.DistrictOfResidence.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'DistrictOfResidence')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Block Of Residence</mat-label>
                            <mat-select name="blockOfResidence" formControlName="BlockOfResidence" required
                                    appearance="outline" [disabled]="PageRights.IsReadOnly">
                                    <mat-option *ngFor="let blockItem of blockList" [value]="blockItem.Name">
                                        {{blockItem.Name}}
                                    </mat-option>
                                </mat-select>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.BlockOfResidence.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'BlockOfResidence')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Pincode</mat-label>
                            <input matInput name="pinCode" formControlName="PinCode" [disabled]="PageRights.IsReadOnly">
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.PinCode.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'PinCode')}}
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Mobile No of Student</mat-label>
                            <input matInput name="studentMobileNo" formControlName="StudentMobileNo" digitOnly
                                minlength="10" maxlength="10" placeholder="Ex. 9800098000" required>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.StudentMobileNo.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'StudentMobileNo')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Enter Parent/Guardian/Relative's Mobile Number</mat-label>
                            <input matInput name="parentMobileNo" formControlName="ParentMobileNo" digitOnly
                                minlength="10" maxlength="10" placeholder="Ex. 9800098000" required>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.ParentMobileNo.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'ParentMobileNo')}}
                            </mat-error>
                        </mat-form-field>
                    </div>


                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Parent/Guardian/Relative's Name</mat-label>
                            <input matInput name="ParentName" formControlName="ParentName" required
                                placeholder="Ex. Mohan Jitendra Patil" matTooltip="First letter should be capital."
                                matTooltipPosition="above">
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.ParentName.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'ParentName')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!-- Status Of Employement -->
                    <!-- For 12th class students -->

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                        *ngIf="className == 'Class 12'">
                            <mat-label>Did You Do Internship in Class 11th or 12th?</mat-label>
                            <mat-select name="DoneInternship" formControlName="DoneInternship" required
                                appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option *ngFor="let Item of YesNoDropdown" [value]="Item.value">
                                    {{Item.key}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.DoneInternship.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'DoneInternship')}}
                            </mat-error>
                        </mat-form-field>
                    </div>


                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Are You Currently Employed?</mat-label>
                            <mat-select name="CurrentlyEmployed" formControlName="CurrentlyEmployed" required
                                appearance="outline" [disabled]="PageRights.IsReadOnly"
                                (selectionChange)="onChangeCurrentlyEmployed($event.value)">
                                <mat-option *ngFor="let Item of YesNoDropdown" [value]="Item.value">
                                    {{Item.key}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.CurrentlyEmployed.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'CurrentlyEmployed')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                            *ngIf="vtStudentExitSurveyDetailForm.controls.CurrentlyEmployed.value == '1'">
                            <mat-label>Details Of Employement/Title </mat-label>
                            <input matInput name="detailsOfEmployment" formControlName="DetailsOfEmployment">
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.DetailsOfEmployment.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'DetailsOfEmployment')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                        *ngIf="vtStudentExitSurveyDetailForm.controls.CurrentlyEmployed.value == '1'">
                            <mat-label class="margin-left">Whether Full Time/Part Time?
                            </mat-label>
                            <mat-select name="IsFullTime" formControlName="IsFullTime" appearance="outline"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option *ngFor="let natureOfWorkItem of natureOfWorkList"
                                    [value]="natureOfWorkItem.Id">
                                    {{natureOfWorkItem.Name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.IsFullTime.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm,
                                'IsFullTime')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                        *ngIf="vtStudentExitSurveyDetailForm.controls.CurrentlyEmployed.value == '1'">
                            <mat-label>Sector Of Employement</mat-label>
                            <mat-select name="SectorsOfEmployment" formControlName="SectorsOfEmployment"
                                appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option *ngFor="let sectorOfEmployementItem of sectorOfEmployementList"
                                    [value]="sectorOfEmployementItem.Name">
                                    {{sectorOfEmployementItem.Name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.SectorsOfEmployment.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'SectorsOfEmployment')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                        *ngIf="vtStudentExitSurveyDetailForm.controls.CurrentlyEmployed.value == '1'">
                            <mat-label class="margin-left">Is your employment relevant to Vocational Skill Course
                                that
                                you completed?
                            </mat-label>
                            <mat-select name="IsRelevantToVocCourse" formControlName="IsRelevantToVocCourse"
                                appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option *ngFor="let Item of YesNoDropdown" [value]="Item.value">
                                    {{Item.key}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.IsRelevantToVocCourse.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm,
                                'IsRelevantToVocCourse')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label class="margin-left">Will you continue your higher studies?
                            </mat-label>
                            <mat-select name="WillContHigherStudies" formControlName="WillContHigherStudies" required
                                appearance="outline" [disabled]="PageRights.IsReadOnly"
                                (selectionChange)="onChangeHigherStudies($event.value)">
                                <mat-option *ngFor="let Item of YesNoDropdown" [value]="Item.value">
                                    {{Item.key}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.WillContHigherStudies.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm,
                                'WillContHigherStudies')}}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                            *ngIf="vtStudentExitSurveyDetailForm.controls.WillContHigherStudies.value == '1'">
                            <mat-label class="margin-left">Whether Full Time/Part Time?
                            </mat-label>
                            <mat-select name="WillBeFullTime" formControlName="WillBeFullTime"
                                appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option *ngFor="let natureOfWorkItem of natureOfWorkList"
                                    [value]="natureOfWorkItem.Id">
                                    {{natureOfWorkItem.Name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.WillBeFullTime.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm,
                                'WillBeFullTime')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                            *ngIf="vtStudentExitSurveyDetailForm.controls.WillContHigherStudies.value == '1'">
                            <mat-label>Which Course Will You Pursue? </mat-label>
                            <mat-select name="CourseToPursue" formControlName="CourseToPursue"
                                appearance="outline" [disabled]="PageRights.IsReadOnly"
                                (selectionChange)="onChangeCourseToPursue($event.value)">
                                <mat-option *ngFor="let courseToBePursueItem of courseToBePursueList"
                                    [value]="courseToBePursueItem.Name">
                                    {{courseToBePursueItem.Name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.CourseToPursue.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'CourseToPursue')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                            *ngIf="vtStudentExitSurveyDetailForm.controls.CourseToPursue.value == 'Other'">
                            <mat-label>Other Course Details </mat-label>
                            <input matInput name="otherCourse" formControlName="OtherCourse">
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.OtherCourse.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'OtherCourse')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                        *ngIf="vtStudentExitSurveyDetailForm.controls.WillContHigherStudies.value == '1'">
                            <mat-label>Stream Of Education </mat-label>
                            <mat-select name="StreamOfEducation" formControlName="StreamOfEducation"
                                appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option *ngFor="let streamOfEducationItem of streamOfEducationList"
                                    [value]="streamOfEducationItem.Name">
                                    {{streamOfEducationItem.Name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.StreamOfEducation.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'StreamOfEducation')}}
                            </mat-error>
                        </mat-form-field>


                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Would you like to continue vocational education in Class 11th? </mat-label>
                            <mat-select name="WillContVocEdu" formControlName="WillContVocEdu" required
                                appearance="outline" [disabled]="PageRights.IsReadOnly"
                                (selectionChange)="onChangeWillContVocEdu($event.value)">
                                <mat-option *ngFor="let Item of YesNoDropdown" [value]="Item.value">
                                    {{Item.key}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.WillContVocEdu.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'WillContVocEdu')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                            *ngIf="vtStudentExitSurveyDetailForm.controls.WillContVocEdu.value == '0'">
                            <mat-label>What are the reasons for not continuing the Vocational Eduacation
                            </mat-label>
                            <mat-select name="ReasonsNOTToContinue" formControlName="ReasonsNOTToContinue"
                                [disabled]="PageRights.IsReadOnly" appearance="outline">
                                <mat-option *ngFor="let veNotContinuingReasonsItem of veNotContinuingReasonsList"
                                    [value]="veNotContinuingReasonsItem.Name">
                                    {{veNotContinuingReasonsItem.Name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.ReasonsNOTToContinue.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'ReasonsNOTToContinue')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                            *ngIf="vtStudentExitSurveyDetailForm.controls.ReasonsNOTToContinue.value == 'Other'">
                            <mat-label>If other reasons, please mention </mat-label>
                            <input matInput name="OtherReasons" formControlName="OtherReasons">
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.OtherReasons.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'OtherReasons')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                            *ngIf="vtStudentExitSurveyDetailForm.controls.WillContVocEdu.value == '1'">
                            <mat-label>If willing to continue vocational education, Do you wish to continue Same
                                trade/Sector </mat-label>
                            <mat-select name="WillContSameSector" formControlName="WillContSameSector"
                                appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option *ngFor="let Item of YesNoDropdown" [value]="Item.value">
                                    {{Item.key}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.WillContSameSector.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'WillContSameSector')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!-- In case of ‘Yes’ and ‘Part Time Education’ -->
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Does your Field of Study include Vocational Subject? </mat-label>
                            <mat-select name="DoesFieldStudyHveVocSub" formControlName="DoesFieldStudyHveVocSub"
                                required appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option *ngFor="let Item of YesNoDropdown" [value]="Item.value">
                                    {{Item.key}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.DoesFieldStudyHveVocSub.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'DoesFieldStudyHveVocSub')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Have you registered on the Employment Portal?</mat-label>
                            <mat-select name="HveRegisteredOnEmploymentPortal"
                                formControlName="HveRegisteredOnEmploymentPortal" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option *ngFor="let Item of YesNoDropdown" [value]="Item.value">
                                    {{Item.key}}</mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="vtStudentExitSurveyDetailForm.controls.HveRegisteredOnEmploymentPortal.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm,
                                'HveRegisteredOnEmploymentPortal')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Are you willing to get registered on NAPS portal for Apprenticeship
                                Opportunities?
                            </mat-label>
                            <mat-select name="WillingToGetRegisteredOnNAPS"
                                formControlName="WillingToGetRegisteredOnNAPS" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option *ngFor="let Item of YesNoDropdown" [value]="Item.value">
                                    {{Item.key}}</mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="vtStudentExitSurveyDetailForm.controls.WillingToGetRegisteredOnNAPS.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'WillingToGetRegisteredOnNAPS')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Are you interested in participating in skill development programs
                                (PMKVY/State
                                SkillDevelopment Programs etc)</mat-label>
                            <mat-select name="InterestedInSkillDevelopmentPgms"
                                formControlName="InterestedInSkillDevelopmentPgms" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly"
                                (selectionChange)="onChangeInterestedInSkillDevelopmentPgms($event.value)">
                                <mat-option *ngFor="let Item of YesNoDropdown" [value]="Item.value">
                                    {{Item.key}}</mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="vtStudentExitSurveyDetailForm.controls.InterestedInSkillDevelopmentPgms.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm,
                                'InterestedInSkillDevelopmentPgms')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                            *ngIf="vtStudentExitSurveyDetailForm.controls.InterestedInSkillDevelopmentPgms.value == '1'">
                            <mat-label>Select Sectors in which you are interested?</mat-label>
                            <mat-select name="SectorsInterestedIn" formControlName="SectorsInterestedIn" multiple
                                appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option *ngFor="let sectorOfEmployementItem of sectorOfEmployementList"
                                    [value]="sectorOfEmployementItem.Name">
                                    {{sectorOfEmployementItem.Name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.SectorsInterestedIn.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'SectorsInterestedIn')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            
                            <mat-label>Are you interested in Job or Self Employment after 12th?</mat-label>
                            <mat-select name="InterestedInJobOrSelfEmployment" (selectionChange)="onChangeInterestedInJobOrSE($event.value)"
                                formControlName="InterestedInJobOrSelfEmployment" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option *ngFor="let Item of InterestedInJob" [value]="Item.value">
                                    {{Item.key}}</mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="vtStudentExitSurveyDetailForm.controls.InterestedInJobOrSelfEmployment.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm,
                                'InterestedInJobOrSelfEmployment')}}
                            </mat-error>
                        </mat-form-field>


                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                            *ngIf="vtStudentExitSurveyDetailForm.controls.InterestedInJobOrSelfEmployment.value == '1'">
                            <mat-label>What are you interested in?</mat-label>
                            <mat-select name="TopicsOfInterest" multiple formControlName="TopicsOfInterest"
                                appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option *ngFor="let topicsOfJobInterestItem of topicsOfJobInterestList"
                                    [value]="topicsOfJobInterestItem.Name">
                                    {{topicsOfJobInterestItem.Name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.TopicsOfInterest.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'TopicsOfInterest')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Preferred Location for Employment?</mat-label>
                            <mat-select name="AnyPreferredLocForEmployment"
                                formControlName="AnyPreferredLocForEmployment" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeAnyPreferredLoc($event.value)">
                                <mat-option
                                    *ngFor="let preferredLocationForEmploymentItem of preferredLocationForEmploymentList"
                                    [value]="preferredLocationForEmploymentItem.Id">
                                    {{preferredLocationForEmploymentItem.Name}}
                                </mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="vtStudentExitSurveyDetailForm.controls.AnyPreferredLocForEmployment.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'AnyPreferredLocForEmployment')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                            *ngIf="vtStudentExitSurveyDetailForm.controls.AnyPreferredLocForEmployment.value=='348'">
                            <mat-label>Specify Prefered Location </mat-label>
                            <input matInput name="PreferredLocations" formControlName="PreferredLocations">
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.PreferredLocations.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'PreferredLocations')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Would you like to receive updates regarding employment or higher education
                                programs of follow-up Survey in Future through call/whatsapp/sms/email from Lend A
                                Hand
                                India
                            </mat-label>
                            <mat-select name="CanSendTheUpdates" formControlName="CanSendTheUpdates" required
                                appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option *ngFor="let Item of YesNoDropdown" [value]="Item.value">
                                    {{Item.key}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.CanSendTheUpdates.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'CanSendTheUpdates')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!-- </div> -->

                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <span class="checkbox-space">Is Active?</span>
                        <mat-checkbox matInput formControlName="IsActive" [disabled]="PageRights.IsReadOnly">
                        </mat-checkbox>
                    </div>
                    <!-- </mat-tab>
    </mat-tab-group> -->
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>