import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SchoolSectorJobRoleVTPModel } from './school-sector-job-role-vtp.model';
import { SchoolSectorJobRoleVTPService } from './school-sector-job-role-vtp.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { DropdownModel } from 'app/models/dropdown.model';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'data-list-view',
  templateUrl: './school-sector-job-role-vtp.component.html',
  styleUrls: ['./school-sector-job-role-vtp.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class SchoolSectorJobRoleVTPComponent extends BaseListComponent<SchoolSectorJobRoleVTPModel> implements OnInit {
  schoolsList: [DropdownModel];
  schoolSectorJobRoleVTPFilterForm: FormGroup;
  schoolSectorJobVtp: Element[];
  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    public zone: NgZone,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private schoolSectorJobRoleVTPService: SchoolSectorJobRoleVTPService) {
    super(commonService, router, routeParams, snackBar, zone);
    this.schoolSectorJobRoleVTPFilterForm = this.createSectorJobRoleVTPFilterForm();
  }

  ngOnInit(): void {
    this.onLoadSectorJobRoleVTP();

  }

  onLoadSectorJobRoleVTP(): any {
    this.SearchBy.IsActive = this.schoolSectorJobRoleVTPFilterForm.controls["Status"].value == 'true' ? true : this.schoolSectorJobRoleVTPFilterForm.controls["Status"].value == 'false' ? false : this.schoolSectorJobRoleVTPFilterForm.controls["Status"].value;

    this.schoolSectorJobRoleVTPService.GetAllByCriteria(this.SearchBy).subscribe(response => {
      // this.displayedColumns = ['SchoolName', 'YearName','VTPName','JobRoleName', 'DateOfAllocation', 'DateOfDeAllocation', 'Remarks', 'IsActive', 'Actions'];
      this.displayedColumns = ['SchoolName', 'JobRoleName', 'VTPName', 'YearName', 'IsActive', 'Actions'];
      this.schoolSectorJobVtp = response.Results;
      this.tableDataSource.data = response.Results;
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;

      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
    });
  }

  onLoadSchoolSectorJobRoleVTPByFilters(): any {
    this.SearchBy.PageIndex = 0;
    this.onLoadSectorJobRoleVTP();
  }

  resetFilters(): void {
    this.SearchBy.PageIndex = 0;
    this.schoolSectorJobRoleVTPFilterForm.reset();
    //this.schoolFilterForm.get('AcademicYearId').setValue(this.AcademicYearId);

    this.onLoadSectorJobRoleVTP();
  }

  onDeleteSchoolSectorJobRoleVTP(schoolSectorJobRolesVTPId: string) {
    this.dialogService
      .openConfirmDialog(this.Constants.Messages.DeleteConfirmationMessage)
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          this.schoolSectorJobRoleVTPService.deleteSchoolSectorJobRoleVTPById(schoolSectorJobRolesVTPId)
            .subscribe((sectorJobRoleResp: any) => {

              this.zone.run(() => {
                if (sectorJobRoleResp.Success == true) {
                  this.showActionMessage(
                    this.Constants.Messages.RecordDeletedMessage,
                    this.Constants.Html.SuccessSnackbar
                  );
                } else {
                  this.showActionMessage(
                    sectorJobRoleResp.Result,
                    this.Constants.Html.SuccessSnackbar
                  );
                  this.ngOnInit();

                }
                this.ngOnInit();
              }, error => {
                console.log('SectorJobRole deletion errors =>', error);
              });

            });
          this.ngOnInit();
        }
      });
  }
  exportExcel(): void {
    this.IsLoading = true;
    this.SearchBy.PageIndex = 0;
    this.SearchBy.PageSize = 10000;

    this.schoolSectorJobRoleVTPService.GetAllByCriteria(this.SearchBy).subscribe(response => {
      response.Results.forEach(
        function (obj) {
          if (obj.hasOwnProperty('IsActive')) {
            obj.IsActive = obj.IsActive ? 'Yes' : 'No';
          }
          if (obj.hasOwnProperty('IsImplemented')) {
            obj.IsImplemented = obj.IsImplemented ? 'Yes' : 'No';
          }
          delete obj.SchoolId;
          delete obj.TotalRows;
        });

      this.exportExcelFromTable(response.Results, "SchoolVTMapping");

      this.IsLoading = false;
      this.SearchBy.PageIndex = 0;
      this.SearchBy.PageSize = 10;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  //Create SchoolSectorJobRole form and returns {FormGroup}
  createSectorJobRoleVTPFilterForm(): FormGroup {
    return this.formBuilder.group({
      // AcademicYearId: new FormControl(),
      Status: new FormControl()
    });
  }


}
