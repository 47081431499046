<div id="school-sectorjobrole-classsection-mapping" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16"
                    [routerLink]="'/school-sectorjobrole-classsection-mapping'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        {{ schoolVTMappingModel.Name }}
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        New School VT Mapping
                    </div>
                    <div class="subtitle secondary-text">
                        <span>School Sector Job Role and Class Section Mapping Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-sector-job-role-button" [disabled]="schoolSjrClssctMappingForm.invalid"
                *ngIf="PageRights.ActionType ==='new'" (click)="saveOrUpdateSchoolVTMappingDetails()">
                <span>ADD</span>
            </button>

            <button mat-raised-button class="save-sector-job-role-button" *ngIf="PageRights.ActionType ==='edit'"
                (click)="saveOrUpdateSchoolVTMappingDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="schoolSjrClssctMappingForm" [formGroup]="schoolSjrClssctMappingForm"
                    class="sector-job-role w-100-p" fxLayout="column" fxFlex>

                    <!-- <mat-tab label="Basic Info"> -->
                    <div class="tab-content p-24" fusePerfectScrollbar>
                        <div class="required-mark">
                            <span><span class="note">Note: </span>All fields marked with an asterisk (<span
                                    class="asterisk-color">*</span>) are required.</span>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                fxFlex>
                                <mat-label>Academic Year</mat-label>

                                <mat-select name="academicYearId" formControlName="AcademicYearId" required
                                    [disabled]="PageRights.IsReadOnly"
                                    (selectionChange)="onChangeSchoolAY($event.value)">
                                    <mat-option [value]="academicYearItem.Id"
                                        *ngFor="let academicYearItem of academicyearList">
                                        {{ academicYearItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="schoolSjrClssctMappingForm.controls.AcademicYearId.invalid">
                                    {{getErrorMessage(schoolSjrClssctMappingForm, 'AcademicYearId')}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>School Sector Job Roles</mat-label>

                                <mat-select name="SchoolSectorJobRoleId" formControlName="SchoolSectorJobRoleId"
                                    required [disabled]="PageRights.IsReadOnly"
                                    (selectionChange)="onChangeSchoolSectorJobRole($event.value);status('SchoolSectorJobRoles')"
                                    #SelectSchoolJobRole>
                                    <mat-select-filter class="select-filter" *ngIf="SelectSchoolJobRole.focused"
                                        [array]="roleList" (filteredReturn)="this.filteredSchoolJobRoleItems = $event"
                                        [displayMember]="'Name'"></mat-select-filter>
                                    <mat-option [value]="roleItem.Id"
                                        *ngFor="let roleItem of filteredSchoolJobRoleItems">
                                        {{ roleItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="schoolSjrClssctMappingForm.controls.SchoolSectorJobRoleId.invalid">
                                    {{getErrorMessage(schoolSjrClssctMappingForm, 'SchoolSectorJobRoleId')}}
                                </mat-error>
                            </mat-form-field>


                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                fxFlex>
                                <mat-label>Class</mat-label>

                                <mat-select name="ClassId" formControlName="ClassId" required
                                    [disabled]="PageRights.IsReadOnly"
                                    (selectionChange)="onChangeClass($event.value);status('Class')">
                                    <mat-option [value]="classItem.Id" *ngFor="let classItem of classList">
                                        {{ classItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="schoolSjrClssctMappingForm.controls.ClassId.invalid">
                                    {{getErrorMessage(schoolSjrClssctMappingForm, 'ClassId')}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                fxFlex>
                                <mat-label>Section</mat-label>

                                <mat-select name="SectionId" formControlName="SectionId" required
                                    [disabled]="PageRights.IsReadOnly">
                                    <mat-option [value]="sectionItem.Id" *ngFor="let sectionItem of sectionList">
                                        {{ sectionItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="schoolSjrClssctMappingForm.controls.SectionId.invalid">
                                    {{getErrorMessage(schoolSjrClssctMappingForm, 'SectionId')}}
                                </mat-error>
                            </mat-form-field>

                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                fxFlex>
                                <mat-label>State Code</mat-label>

                                <mat-select name="stateCode" formControlName="StateCode" required
                                    [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeState($event.value)">
                                    <mat-option [value]="statecodeItem.Id" *ngFor="let statecodeItem of statecodeList;">
                                        {{ statecodeItem.Name }} ({{ statecodeItem.Id }})
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="schoolSjrClssctMappingForm.controls.StateCode.invalid">
                                    {{getErrorMessage(schoolSjrClssctMappingForm, 'StateCode')}}
                                </mat-error>
                            </mat-form-field>



                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">

                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                fxFlex>
                                <mat-label>VTP</mat-label>

                                <mat-select name="vtpId" formControlName="VTPId" required
                                    [disabled]="PageRights.IsReadOnly"
                                    (selectionChange)="onChangeVTP($event.value);status('VTP')">
                                    <mat-option [value]="vtpItem.Id" *ngFor="let vtpItem of vtpList">
                                        {{ vtpItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="schoolSjrClssctMappingForm.controls.VTPId.invalid">
                                    {{getErrorMessage(schoolSjrClssctMappingForm, 'VTPId')}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                fxFlex>
                                <mat-label>VC</mat-label>

                                <mat-select name="vcId" formControlName="VCId" required
                                    [disabled]="PageRights.IsReadOnly"
                                    (selectionChange)="onChangeVC($event.value);status('VC')">
                                    <mat-option [value]="vcItem.Id" *ngFor="let vcItem of vocationalCoordinatorList">
                                        {{ vcItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="schoolSjrClssctMappingForm.controls.VCId.invalid">
                                    {{getErrorMessage(schoolSjrClssctMappingForm, 'VCId')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                fxFlex>
                                <mat-label>VT</mat-label>

                                <mat-select name="vTId" formControlName="VTId" required
                                    [disabled]="PageRights.IsReadOnly">
                                    <mat-option [value]="vtItem.Id" *ngFor="let vtItem of vocationList">
                                        {{ vtItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="schoolSjrClssctMappingForm.controls.VTId.invalid">
                                    {{getErrorMessage(schoolSjrClssctMappingForm, 'VTId')}}
                                </mat-error>
                            </mat-form-field>

                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Date Of Allocation</mat-label>
                                <input matInput name="dateOfAllocation" formControlName="DateOfAllocation" required
                                    [matDatepicker]="dateOfAllocation" [disabled]="PageRights.IsReadOnly"
                                    [max]="CurrentDate">
                                <mat-datepicker-toggle matSuffix [for]="dateOfAllocation"></mat-datepicker-toggle>
                                <mat-datepicker #dateOfAllocation></mat-datepicker>

                                <mat-error *ngIf="schoolSjrClssctMappingForm.controls.DateOfAllocation.invalid">
                                    {{getErrorMessage(schoolSjrClssctMappingForm, 'DateOfAllocation')}}
                                </mat-error>
                            </mat-form-field>


                            <mat-icon matDatepickerToggleIcon
                                (click)="clearDateValueInFormControl(schoolSjrClssctMappingForm, 'DateOfAllocation', $event)"
                                *ngIf="schoolSjrClssctMappingForm.controls.DateOfAllocation.value && PageRights.ActionType !='view'">
                                clear
                            </mat-icon>
                            <!-- <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                *ngIf="PageRights.ActionType ==='edit' || PageRights.ActionType ==='view'"> -->
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                *ngIf="PageRights.ActionType ==='edit' || PageRights.ActionType ==='view'">
                                <mat-label>Date Of DeAllocation</mat-label>
                                <input matInput name="dateOfDeAllocation" formControlName="DateOfDeAllocation"
                                    [matDatepicker]="dateOfDeAllocation" [disabled]="PageRights.IsReadOnly"
                                    (dateChange)="onChangeDateEnableDisableCheckBox(schoolSjrClssctMappingForm, $event)"
                                    [max]="CurrentDate"
                                    [min]="schoolSjrClssctMappingForm.controls.DateOfAllocation.value">
                                <mat-datepicker-toggle matSuffix [for]="dateOfDeAllocation"></mat-datepicker-toggle>
                                <mat-datepicker #dateOfDeAllocation></mat-datepicker>

                                <mat-error *ngIf="schoolSjrClssctMappingForm.controls.DateOfDeAllocation.invalid">
                                    {{getErrorMessage(schoolSjrClssctMappingForm, 'DateOfDeAllocation')}}
                                </mat-error>

                            </mat-form-field>
                            <mat-icon matDatepickerToggleIcon
                                (click)="clearDateValueInFormControl(schoolSjrClssctMappingForm, 'DateOfDeAllocation', $event)"
                                *ngIf="schoolSjrClssctMappingForm.controls.DateOfDeAllocation.value &&  PageRights.ActionType !='view'">
                                clear
                            </mat-icon>


                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="0 1 calc(35%)" fxFlex.lt-md="0 1 calc(70%)"
                                fxFlex.lt-sm="100%">
                                <mat-label>Remarks</mat-label>
                                <input matInput name="remarks" formControlName="Remarks" placeholder="Ex. remarks">
                                <mat-error *ngIf="schoolSjrClssctMappingForm.controls.Remarks.invalid">
                                    {{getErrorMessage(schoolSjrClssctMappingForm, 'Remarks')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start start">
                            <span class="checkbox-space">Is Active?</span>
                            <mat-checkbox matInput formControlName="IsActive" (change)="showOptions($event)" [disabled]="PageRights.IsReadOnly">
                            </mat-checkbox>
                        </div>
                    </div>
                    <!-- </mat-tab>
                    </mat-tab-group> -->
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>