import { FuseUtils } from "@fuse/utils";

export class InternshipOpportunity {
    InternshipId: string;
    Title: string;
    InternshipType: string;
    EmployerId: string;
    BranchId: string;
    BranchName: string;
    SectorId: string;
    StartDate: Date;
    Duration: string;
    Stipend: string;
    NoOfOpenings: string;
    JobDescription: string;
    Perks: string;
    RequiredSkills: string;
    DistrictCode: string;
    DistrictName: string;
    Pincode: number;
    IsCompleted: boolean;
    IsPublished: boolean;
    PublishDate: Date;
    AcademicYearId: string;
    POCForInternship: string;
    POCEmail: string;
    POCMobile: string;
    IsActive: boolean;
    AuthUserId: string;
    RequestType: any;
    WorkingType: any;
    InterestId:string;

    constructor(internshipItem?: any) {
        internshipItem = internshipItem || {};

        this.InternshipId = internshipItem.InternshipId || FuseUtils.NewGuid();
        this.Title = internshipItem.Title || '';
        this.InternshipType = internshipItem.InternshipType || '';
        this.EmployerId = internshipItem.EmployerId || '';
        this.BranchId = internshipItem.BranchId || '';
        this.BranchName = internshipItem.BranchName || '';
        this.SectorId = internshipItem.SectorId || '';
        this.StartDate = internshipItem.StartDate || '';
        this.Duration = internshipItem.Duration || '';
        this.Stipend = internshipItem.Stipend || '';
        this.NoOfOpenings = internshipItem.NoOfOpenings || '';
        this.JobDescription = internshipItem.JobDescription || '';
        this.Perks = internshipItem.Perks || '';
        this.RequiredSkills = internshipItem.RequiredSkills || '';
        this.DistrictCode = internshipItem.DistrictCode || '';
        this.DistrictName = internshipItem.DistrictName || '';
        this.Pincode = internshipItem.Pincode || '';
        this.IsCompleted = false;
        this.IsPublished = internshipItem.IsPublished || false;
        this.PublishDate = internshipItem.PublishDate || '';
        this.AcademicYearId = internshipItem.AcademicYearId || '';
        this.POCForInternship = internshipItem.POCForInternship || '';
        this.POCEmail = internshipItem.POCEmail || '';
        this.POCMobile = internshipItem.POCMobile || '';
        this.IsActive = internshipItem.IsActive || true;
        this.AuthUserId = internshipItem.AuthUserId || '';
        this.WorkingType = internshipItem.WorkingType || '';
        this.InterestId = internshipItem.InterestId || '';
        this.RequestType = 0; // New 
    }
}
