import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'app/common/base/base.component';
import { InternshipOpportunity } from 'app/main/internship-opportunity/internship-opportunity.model';
import { CommonService } from 'app/services/common.service';

@Component({
  selector: 'app-view-internship-approval',
  templateUrl: './view-internship-approval.component.html',
  styleUrls: ['./view-internship-approval.component.scss']
})
export class ViewInternshipApprovalComponent extends BaseComponent<InternshipOpportunity> implements OnInit {

  passDatatoChild: any[];
  isLoading: boolean = true;
  selectedIndex: number;
  selectedObjects: any = {};
  errorMessage: any;
  studentSearchData = [] as any;
  studentFeeId: number;
  receiptType: number;
  receiptNo: number;
  studentInternshipDataById = [] as any;
  constructor(
    public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    // public service: ModifyArrearsService,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    // public confirmDialogService: ConfirmDialogService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ViewInternshipApprovalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(commonService, router, routeParams, snackBar);
    // dialogRef.disableClose = true;
  }

  setTab(tabname: string) {
    this.router.navigate([`/${tabname}`]);
  }
  ngOnInit() {
    console.log(this.data);
    this.passDatatoChild = this.data;
    this.studentInternshipDataById = this.data[0];
    console.log(this.passDatatoChild);
  }

  onClose() {
    this.dialog.closeAll();
  }

}
