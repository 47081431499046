export const locale = {
    lang: 'en',
    data: {
        'VT': {
            'VTP': 'VOCATIONAL TRAINING PROVIDERS',
            'ADD_NEW_VTP': 'ADD',
            'SEARCH_FOR_A_VTP': 'Search for a vocational training provider',
        }
    }
};

