export const locale = {
    lang: 'en',
    data: {
        'VT': {
            'VT': 'VOCATIONAL TRAINERS',
            'ADD_NEW_VT': 'ADD',
            'SEARCH_FOR_A_VT': 'Search for a vocational trainer',
        }
    }
};

