import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmployerReportModel, EmployerReportViewModel } from './employer-report.model';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { ReportService } from '../report.service';
import { DropdownModel } from 'app/models/dropdown.model';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'data-list-view',
  templateUrl: './employer-report.component.html',
  styleUrls: ['./employer-report.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class EmployerReportComponent extends BaseListComponent<EmployerReportModel> implements OnInit {
  employerReportForm: FormGroup;

  academicyearList: [DropdownModel];
  divisionList: [DropdownModel];
  districtList: DropdownModel[];
  sectorList: [DropdownModel];
  jobRoleList: DropdownModel[];
  vtpList: [DropdownModel];
  classList: [DropdownModel];
  monthList: [DropdownModel];
  schoolManagementList: [DropdownModel];
  empList: [DropdownModel];
  stateList: [DropdownModel];

  currentAcademicYearId: string;
  isShowFilterContainer = false;
  employerReportViewModel = new EmployerReportViewModel();
  isShow = false;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    public zone: NgZone,
    public formBuilder: FormBuilder,
    private reportService: ReportService) {
    super(commonService, router, routeParams, snackBar, zone);
  }


  ngOnInit(): void {
    this.PageRights.IsExport = true;
    this.reportService.GetDropdownForReports(this.UserModel).subscribe(results => {
      if (results[0].Success) {
        this.academicyearList = results[0].Results;
      }

      if (results[1].Success) {
        this.divisionList = results[1].Results;
      }

      if (results[2].Success) {
        this.sectorList = results[2].Results;
      }

      if (results[3].Success) {
        this.vtpList = results[3].Results;
      }

      if (results[4].Success) {
        this.classList = results[4].Results;
      }

      if (results[5].Success) {
        this.monthList = results[5].Results;
      }

      if (results[6].Success) {
        this.schoolManagementList = results[6].Results;
      }
      if (results[7].Success) {
        this.stateList = results[7].Results;
      }

      if (results[9].Success) {
        this.empList = results[9].Results;
      }
      this.getEmployerReports();

      // let currentYearItem = this.academicyearList.find(ay => ay.IsSelected == true)
      // if (currentYearItem != null) {
      //   this.currentAcademicYearId = currentYearItem.Id;
      //   this.employerReportForm.get('AcademicYearId').setValue(this.currentAcademicYearId);

      //   if (this.UserModel.RoleCode === 'DivEO') {
      //     this.employerReportForm.controls["DivisionId"].setValue(this.UserModel.DivisionId);
      //     this.employerReportForm.controls["DivisionId"].disable();

      //     this.onChangeDivision(this.UserModel.DivisionId).then(response => {
      //       this.getEmployerReports();
      //     });
      //   }
      //   else if (this.UserModel.RoleCode === 'DisEO' || this.UserModel.RoleCode === 'DisRP') {
      //     this.employerReportForm.controls["DivisionId"].setValue(this.UserModel.DivisionId);
      //     this.employerReportForm.controls["DivisionId"].disable();

      //     this.employerReportForm.controls["DistrictId"].setValue(this.UserModel.DistrictId);
      //     this.employerReportForm.controls["DistrictId"].disable();

      //     this.onChangeDivision(this.UserModel.DivisionId).then(response => {
      //       this.getEmployerReports();
      //     });
      //   }
      //   else if (this.UserModel.RoleCode === 'BEO' || this.UserModel.RoleCode === 'BRP') {
      //     this.employerReportForm.controls["DivisionId"].setValue(this.UserModel.DivisionId);
      //     this.employerReportForm.controls["DivisionId"].disable();

      //     this.employerReportForm.controls["DistrictId"].setValue(this.UserModel.DistrictId);
      //     this.employerReportForm.controls["DistrictId"].disable();

      //     this.onChangeDivision(this.UserModel.DivisionId).then(response => {
      //       this.getEmployerReports();
      //     });
      //   }
      //   else {
      //     this.getEmployerReports();
      //   }
      // }
    });

    this.employerReportForm = this.createEmployerReportForm();
  }

  resetReportFilters(): void {
    this.employerReportForm.reset();
    this.districtList = <DropdownModel[]>[];
    this.jobRoleList = <DropdownModel[]>[];
    this.getEmployerReports();
  }

  // onChangeDivision(divisionId: string): Promise<any> {
  //   let promise = new Promise((resolve, reject) => {

  //     this.commonService.GetMasterDataByType({ DataType: 'Districts', UserId: this.Constants.DefaultStateId, ParentId: divisionId, SelectTitle: 'District' })
  //       .subscribe((response: any) => {

  //         this.districtList = response.Results;
  //         resolve(response.Results);
  //       }, err => {

  //         reject(err);
  //       });
  //   });

  //   return promise;
  // }

  onChangeSector(sectorId: string): void {
    this.commonService.GetMasterDataByType({ DataType: 'JobRoles', ParentId: sectorId, SelectTitle: 'Job Role' }).subscribe((response: any) => {
      this.jobRoleList = response.Results;
    });
  }

  onChangeStataByDistrict(StateCode: string): void {
    this.commonService.GetMasterDataByType({ DataType: 'DistrictsByStateCode', ParentId: StateCode, SelectTitle: 'District' }).subscribe((response: any) => {
      this.districtList = response.Results;
    });
  }

  //Create VTSchoolSector form and returns {FormGroup}
  createEmployerReportForm(): FormGroup {
    return this.formBuilder.group({
      EmployerGuid: new FormControl(),
      StateCode: new FormControl(),
      DistrictCode: new FormControl(),
      SectorGuid: new FormControl(),

    });
  }
  onPageIndexChanged(evt) {

    this.SearchBy.PageIndex = evt.pageIndex;
    this.SearchBy.PageSize = evt.pageSize;

    this.getEmployerReports();
  }

  getEmployerReports(): void {
    // var reportParams = {
    //   UserId: this.UserModel.LoginId,
    //   UserTypeId: null,
    //   EmployerGuid:this.employerReportForm.get('EmployerGuid').value,
    //   StateCode:this.employerReportForm.get('StateCode').value,
    //   DistrictCode:this.employerReportForm.get('DistrictCode').value,
    //   SectorGuid:this.employerReportForm.get('SectorGuid').value,
    //   pageIndex :0,
    //   pageSize:10
    // };
    this.SearchBy.EmployerGuid = this.employerReportForm.get('EmployerGuid').value;
    this.SearchBy.StateCode = this.employerReportForm.get('StateCode').value;
    this.SearchBy.DistrictCode = this.employerReportForm.get('DistrictCode').value;
    this.SearchBy.SectorGuid = this.employerReportForm.get('SectorGuid').value;
    this.SearchBy.UserId = this.UserModel.LoginId;
    this.SearchBy.UserTypeId = null;

    this.reportService.GetEmployerReportsByCriteria(this.SearchBy).subscribe(response => {
      // this.displayedColumns = ['SrNo', 'AcademicYear', 'EstablishmentName', 'YearOfEstablishment', 'EmployerName', 'Gender', 'EmployerAddress', 'State', 'District', 'Pincode','PhoneNo','EmployerWhatsApp','Email','EmployerCount','Branch','TotalInternshipStart-G','TotalInternshipStart-B','TotalInternshipSProg-G','TotalInternshipSProg-B','IntCompleted-G','IntCompleted-B','TotalIntDrop-G','TotalIntDrop-B','AVGHrs-G','AVGHrs-B','MaxSimulInterns','MinSimulInterns','TotalApplications'];
      this.displayedColumns = ['SrNo', 'AcademicYear', 'EstablishmentName', 'YearOfEstablishment', 'EmployerName', 'Gender', 'EmployerAddress', 'State', 'District', 'Pincode', 'PhoneNo', 'EmployerWhatsApp', 'EmployerEmialId', 'EmployerCount', 'Branch', 'TotalInternshipStart-G', 'TotalInternshipStart-B', 'TotalInternshipSProg-G', 'TotalInternshipSProg-B', 'IntCompleted-G', 'IntCompleted-B', 'AvgHrsGirls', 'AvgHrsBoys', 'SaftyConcernRaised', 'TotalApplications' ,'CreatedDate'];

      let dataArray = [];

      let EmployerList: any = response.Results;
      for (let i = 0; i < EmployerList.length; i++) {
        this.employerReportViewModel = new EmployerReportViewModel();
        this.employerReportViewModel.EmployerId = EmployerList[i].EmployerId;
        this.employerReportViewModel.EstablishmentName = EmployerList[i].EstablishmentName;
        this.employerReportViewModel.EstablishmentYear = EmployerList[i].EstablishmentYear;
        this.employerReportViewModel.EmployerFirstName = EmployerList[i].EmployerFirstName;
        this.employerReportViewModel.EmployerLastName = EmployerList[i].EmployerLastName;
        this.employerReportViewModel.EmployerFullName = EmployerList[i].EmployerFullName;
        this.employerReportViewModel.Gender = EmployerList[i].EmployerList;
        this.employerReportViewModel.BranchName = EmployerList[i].BranchName;
        this.employerReportViewModel.BranchAddress = EmployerList[i].BranchAddress;
        this.employerReportViewModel.StateCode = EmployerList[i].StateCode;
        this.employerReportViewModel.StateName = EmployerList[i].StateName;
        this.employerReportViewModel.DistrictCode = EmployerList[i].DistrictCode;
        this.employerReportViewModel.DistrictName = EmployerList[i].DistrictName;
        this.employerReportViewModel.Pincode = EmployerList[i].Pincode;
        this.employerReportViewModel.IsMainBranch = EmployerList[i].IsMainBranch;
        this.employerReportViewModel.SectorName = EmployerList[i].SectorName;
        this.employerReportViewModel.EmployeeCount = EmployerList[i].EmployeeCount;
        this.employerReportViewModel.ContcatPerson = EmployerList[i].ContcatPerson;
        this.employerReportViewModel.EmployerEmialId = EmployerList[i].EmployerEmialId;
        this.employerReportViewModel.EmployerMobile = EmployerList[i].EmployerMobile;
        this.employerReportViewModel.EmployerOnBoardingDate = EmployerList[i].EmployerOnBoardingDate;
        this.employerReportViewModel.TotalRows = EmployerList[i].TotalRows;
        this.employerReportViewModel.InternshipYetTostartBoy = EmployerList[i].InternshipYetTostartBoy;
        this.employerReportViewModel.InternshipYetTostartGirl = EmployerList[i].InternshipYetTostartGirl;
        this.employerReportViewModel.InternshipProgressBoys = EmployerList[i].InternshipProgressBoys;
        this.employerReportViewModel.InternshipProgressGirls = EmployerList[i].InternshipProgressGirls;
        this.employerReportViewModel.InternshipCompletedBoys = EmployerList[i].InternshipCompletedBoys;
        this.employerReportViewModel.InternshipCompletedGirls = EmployerList[i].InternshipCompletedGirls;
        //this.employerReportViewModel.AvgHrsBoys = EmployerList[i].AvgHrsBoys;
        var BoysHrs = Number(EmployerList[i].AvgHrsBoys.toString().split('.')[0] == undefined ? '0' : EmployerList[i].AvgHrsBoys.toString().split('.')[0])
        var BoysMM = EmployerList[i].AvgHrsBoys.toString().split('.')[1] == undefined ? '00' : EmployerList[i].AvgHrsBoys.toString().split('.')[1] * 6;
        this.employerReportViewModel.AvgHrsBoys = BoysHrs + ':' + BoysMM;

        //this.employerReportViewModel.AvgHrsGirls = EmployerList[i].AvgHrsGirls;

        var GirlsHrs = Number(EmployerList[i].AvgHrsGirls.toString().split('.')[0] == undefined ? '0' : EmployerList[i].AvgHrsGirls.toString().split('.')[0])
        var GirlsMM = EmployerList[i].AvgHrsGirls.toString().split('.')[1] == undefined ? '00' : EmployerList[i].AvgHrsGirls.toString().split('.')[1] * 6;
        this.employerReportViewModel.AvgHrsGirls = GirlsHrs + ':' + GirlsMM;

        this.employerReportViewModel.SaftyConcernRaised = EmployerList[i].SaftyConcernRaised;
        this.employerReportViewModel.TotalApplications = EmployerList[i].TotalApplications;
        this.employerReportViewModel.CreatedDate = EmployerList[i].CreatedDate;

        dataArray.push(this.employerReportViewModel);
      }
      this.tableDataSource.data = dataArray;
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;
      this.SearchBy.TotalResults = response.TotalResults;

      setTimeout(() => {
        this.ListPaginator.pageIndex = this.SearchBy.PageIndex;
        this.ListPaginator.length = this.SearchBy.TotalResults;
      });

      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
    });
  }

  exportExcel(): void {
    this.IsLoading = true;
    this.SearchBy.PageIndex = 1;
    this.SearchBy.PageSize = 10000;

    this.reportService.GetEmployerReportsByCriteria(this.SearchBy).subscribe(response => {
      response.Results.forEach(
        function (obj) {
          if (obj.hasOwnProperty('IsActive')) {
            obj.IsActive = obj.IsActive ? 'Yes' : 'No';
          }
          if (obj.hasOwnProperty('IsImplemented')) {
            obj.IsImplemented = obj.IsImplemented ? 'Yes' : 'No';
          }
          delete obj.SchoolId;
          delete obj.TotalRows;
        });

      this.exportExcelFromTable(response.Results, "EmployerReport");

      this.IsLoading = false;
      this.SearchBy.PageIndex = 0;
      this.SearchBy.PageSize = 10;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }
}
