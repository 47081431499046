import { FuseUtils } from '@fuse/utils';

export class EmployerReportModel {
    SrNo: number;
    VCSchoolSectorId: string;
    AcademicYearId: string;
    VCId: string;
    AcademicYearSchoolVTPSectorJobRoleId: string;
    DateOfAllocation: Date;
    VCSchoolStatus: string;
    DateOfRemoval: Date;
    VCSchoolSectorName: string;
    IsActive: boolean;
    RequestType: any;

    constructor() { }
}

export class EmployerReportViewModel {
    EmployerId: string;
    EstablishmentName:string;
    EstablishmentYear: string;
    EmployerFirstName: string;
    EmployerLastName: string;
    EmployerFullName: string;
    Gender: string;
    BranchName: string;
    BranchAddress: string;
    StateCode: string;
    StateName: string;
    DistrictCode: string;
    DistrictName: string;
    Pincode: string;
    IsMainBranch: boolean;
    SectorName: string;
    EmployeeCount: number;
    ContcatPerson: string;
    EmployerEmialId: string;
    EmployerMobile: string;
    EmployerOnBoardingDate: string;
    TotalRows: number;
    InternshipYetTostartBoy: number;
    InternshipYetTostartGirl: number;
    InternshipProgressBoys: number;
    InternshipProgressGirls: number;
    InternshipCompletedBoys: number;
    InternshipCompletedGirls: number;
    AvgHrsBoys: string;
    AvgHrsGirls: string;
    SaftyConcernRaised: number;
    TotalApplications: number;
    CreatedDate: Date;
}