import { FuseUtils } from '@fuse/utils';

export class SchoolSectorjobroleClasssectionMappingModel {
    SchoolSectorJobRoleId: string;
    SchoolVTPVCId: string;
    ClassId: string;
    SectionId: string;
    VTId: string;
    StateCode: string; // New Parameters
    AcademicYearId: string;
    DateOfAllocation: any;
    DateOfDeAllocation: any;
    Remarks: string;
    IsActive: boolean;
    RequestType: any;
    VCId: string;
    VTPId: string;
    SchoolSecJobClassSecVTId: string;
    AuthUserId: string;
    DateOfResignation: any// New Parameters


    constructor(schoolSectorjobroleClasssectionMappingItem?: any) {
        schoolSectorjobroleClasssectionMappingItem = schoolSectorjobroleClasssectionMappingItem || {};
        this.SchoolSecJobClassSecVTId = schoolSectorjobroleClasssectionMappingItem.SchoolSectorJobRolesId || FuseUtils.NewGuid();
        this.SchoolSectorJobRoleId = schoolSectorjobroleClasssectionMappingItem.SchoolSectorJobRoleId || '';
        this.ClassId = schoolSectorjobroleClasssectionMappingItem.ClassId || '';
        this.SectionId = schoolSectorjobroleClasssectionMappingItem.SectionId || '';
        this.VTPId = schoolSectorjobroleClasssectionMappingItem.VTPId || '';
        this.VCId = schoolSectorjobroleClasssectionMappingItem.VCId || '';
        this.VTId = schoolSectorjobroleClasssectionMappingItem.VTId || '';
        this.AcademicYearId = schoolSectorjobroleClasssectionMappingItem.AcademicYearId || '';
        this.DateOfAllocation = schoolSectorjobroleClasssectionMappingItem.DateOfAllocation || null;
        this.DateOfDeAllocation = schoolSectorjobroleClasssectionMappingItem.DateOfDeAllocation || null;
        this.Remarks = schoolSectorjobroleClasssectionMappingItem.Remarks || '';
        this.StateCode = schoolSectorjobroleClasssectionMappingItem.StateCode || '';
        this.IsActive = schoolSectorjobroleClasssectionMappingItem.IsActive || true;
        this.AuthUserId = schoolSectorjobroleClasssectionMappingItem.AuthUserId || '';
        this.DateOfResignation = schoolSectorjobroleClasssectionMappingItem.DateOfResignation || null;
        this.RequestType = 0; // New
    }
}
