import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';
import { UserModel } from 'app/models/user.model';

@Injectable()
export class HeadMasterService {
    constructor(private http: BaseService) { }

    getHeadMasters(): Observable<any> {
        return this.http
            .HttpGet(this.http.Services.HeadMaster.GetAll)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetAllByCriteria(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.HeadMaster.GetAllByCriteria, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    getHeadMasterById(hmId: string) {
        let requestParams = {
            DataId: hmId
        };

        return this.http
            .HttpPost(this.http.Services.HeadMaster.GetById, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    createOrUpdateHeadMaster(formData: any) {
        return this.http
            .HttpPost(this.http.Services.HeadMaster.CreateOrUpdate, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    deleteHeadMasterById(hmId: string) {
        var headMasterParams = {
            DataId: hmId
        };

        return this.http
            .HttpPost(this.http.Services.HeadMaster.Delete, headMasterParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    getDropdownforHeadMaster(userModel: UserModel): Observable<any[]> {
        // let schoolRequest = this.http.GetMasterDataByType({ DataType: 'SchoolsByVT', ParentId: userModel.UserTypeId, SelectTitle: 'School' }); //old
        let schoolRequest = this.http.GetMasterDataByType({ DataType: 'Schools',UserId:userModel.UserTypeId, ParentId: null, SelectTitle: 'School' });
        let genderRequest = this.http.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'Gender', SelectTitle: 'Gender' });
        let academicYearRequest = this.http.GetMasterDataByType({  DataType: 'AcademicYearsByState', UserId:userModel.UserTypeId, SelectTitle: 'Academic Year' });
        let statecodeRequest = this.http.GetMasterDataByType({ DataType: 'StatesByUserId', UserId:userModel.UserTypeId, SelectTitle: 'StateCode' });

        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        return forkJoin([schoolRequest, genderRequest,academicYearRequest,statecodeRequest]);
    }
}
