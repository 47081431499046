<div id="employer-student-mapping" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/employer-student-mapping'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        {{ employerStudentMappingModel.Name }}
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        New Employer Student Mapping
                    </div>
                    <div class="subtitle secondary-text">
                        <span>Employer Student Mapping Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-vocational-coordinator-button" *ngIf="PageRights.ActionType ==='new'"
                (click)="saveOrUpdateEmployerStudentMappingDetails()">
                <span>ADD</span>
            </button>

            <button mat-raised-button class="save-vocational-coordinator-button" *ngIf="PageRights.ActionType ==='edit'"
                (click)="saveOrUpdateEmployerStudentMappingDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="employerStudentMappingForm" [formGroup]="employerStudentMappingForm"
                    class="vocational-coordinator w-100-p p-24 mr-24" fxLayout="column" fxLayoutAlign="start"
                    fxFlex="1 0 auto" fxFlex fusePerfectScrollbar>
                    <!-- <mat-tab label="Basic Info"> -->
                    <div class="required-mark">
                        <span><span class="note">Note: </span>All fields marked with an asterisk (<span
                                class="asterisk-color">*</span>) are required.</span>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>State Code</mat-label>
                            <mat-select name="stateCode" formControlName="StateCode" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="item.Id" *ngFor="let item of stateCodeList">
                                    {{ item.Name }} ( {{ item.Id }} )
                                </mat-option>
                                <!-- <mat-option [value]="statecodeItem.Id" *ngFor="let statecodeItem of stateCodeList;">
                                    {{ statecodeItem.Name }} ({{ statecodeItem.Id }})
                                </mat-option> -->
                            </mat-select>
                            <mat-error *ngIf="employerStudentMappingForm.controls.StateCode.invalid">
                                {{getErrorMessage(employerStudentMappingForm, 'StateCode')}}
                            </mat-error>
                        </mat-form-field>


                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Approval Year</mat-label>

                            <mat-select name="academicYearId" formControlName="AcademicYearId" required
                                [disabled]="PageRights.IsReadOnly  || PageRights.IsEdit">
                                <mat-option [value]="academicYearItem.Id"
                                    *ngFor="let academicYearItem of academicyearList">
                                    {{ academicYearItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="employerStudentMappingForm.controls.AcademicYearId.invalid">
                                {{getErrorMessage(employerStudentMappingForm, 'AcademicYearId')}}
                            </mat-error>
                        </mat-form-field>

                        <span class="checkbox-space">Is Direct Mapping Enabled?</span>
                        <mat-checkbox matInput formControlName="IsDirectMappingEnabled"
                            [disabled]="PageRights.IsReadOnly">
                        </mat-checkbox>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">


                        <span class="checkbox-space">Is Active?</span>
                        <mat-checkbox matInput formControlName="IsActive" [disabled]="PageRights.IsReadOnly">
                        </mat-checkbox>
                    </div>
                    <!-- </mat-tab>
                    </mat-tab-group> -->
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>