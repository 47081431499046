import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { RouteConstants } from 'app/constants/route.constant'
import { CourseMaterialService } from '../course-material.service';
import { CourseMaterialModel } from '../course-material.model';
import { DropdownModel } from 'app/models/dropdown.model';

@Component({
  selector: 'course-material',
  templateUrl: './create-course-material.component.html',
  styleUrls: ['./create-course-material.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CreateCourseMaterialComponent extends BaseComponent<CourseMaterialModel> implements OnInit {
  courseMaterialForm: FormGroup;
  courseMaterialModel: CourseMaterialModel;

  academicYearList: [DropdownModel];
  classList: [DropdownModel];

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private route: ActivatedRoute,
    private courseMaterialService: CourseMaterialService,
    private dialogService: DialogService,
    private formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Set the default courseMaterial Model
    this.courseMaterialModel = new CourseMaterialModel();
  }

  ngOnInit(): void {

    this.courseMaterialService.getAcademicYearClass(this.UserModel).subscribe(results => {
      if (results[0].Success) {
        this.academicYearList = results[0].Results;
      }

      this.route.paramMap.subscribe(params => {
        if (params.keys.length > 0) {
          this.PageRights.ActionType = params.get('actionType');

          if (this.PageRights.ActionType == this.Constants.Actions.New) {
            this.courseMaterialModel = new CourseMaterialModel();

          } else {
            var courseMaterialId: string = params.get('courseMaterialId')

            this.courseMaterialService.getCourseMaterialById(courseMaterialId)
              .subscribe((response: any) => {
                this.courseMaterialModel = response.Result;

                if (this.PageRights.ActionType == this.Constants.Actions.Edit)
                  this.courseMaterialModel.RequestType = this.Constants.PageType.Edit;
                else if (this.PageRights.ActionType == this.Constants.Actions.View) {
                  this.courseMaterialModel.RequestType = this.Constants.PageType.View;
                  this.PageRights.IsReadOnly = true;
                }

                this.onChangeAcademicYear(this.courseMaterialModel.AcademicYearId);
                this.courseMaterialForm = this.createCourseMaterialForm();
              });
          }
        }
      });

      this.courseMaterialForm = this.createCourseMaterialForm();
    });

    this.courseMaterialForm = this.createCourseMaterialForm();
  }

  onChangeAcademicYear(academicYearId) {
    this.commonService.GetMasterDataByType({ DataType: 'ClassesByVTForCM', ParentId: academicYearId, UserId: this.UserModel.UserTypeId, SelectTitle: 'Class' }).subscribe((response) => {
      if (response.Success) {
        this.classList = response.Results;
      }
    });
  }

  saveOrUpdateCourseMaterialDetails() {
    if (!this.courseMaterialForm.valid) {
      this.validateAllFormFields(this.courseMaterialForm);
      return;
    }
    this.setValueFromFormGroup(this.courseMaterialForm, this.courseMaterialModel);
    this.courseMaterialModel.VTId = this.UserModel.UserTypeId;

    this.courseMaterialService.createOrUpdateCourseMaterial(this.courseMaterialModel)
      .subscribe((courseMaterialResp: any) => {

        if (courseMaterialResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );

            this.router.navigate([RouteConstants.CourseMaterial.List]);
          });
        }
        else {
          var errorMessages = this.getHtmlMessage(courseMaterialResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('CourseMaterial deletion errors =>', error);
      });
  }

  //Create courseMaterial form and returns {FormGroup}
  createCourseMaterialForm(): FormGroup {
    return this.formBuilder.group({
      CourseMaterialId: new FormControl(this.courseMaterialModel.CourseMaterialId),
      AcademicYearId: new FormControl({ value: this.courseMaterialModel.AcademicYearId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      ClassId: new FormControl({ value: this.courseMaterialModel.ClassId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      ReceiptDate: new FormControl({ value: this.getDateValue(this.courseMaterialModel.ReceiptDate), disabled: this.PageRights.IsReadOnly }),
      CMStatus: new FormControl({ value: this.courseMaterialModel.CMStatus, disabled: this.PageRights.IsReadOnly }, Validators.required),
      Details: new FormControl({ value: this.courseMaterialModel.Details, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(350))
    });
  }

  onChangeOnCMStatusType(chk) {    
    if (chk.value == "No") {
      this.courseMaterialForm.controls["ReceiptDate"].clearValidators();
    }
    else {
      this.courseMaterialForm.controls["ReceiptDate"].setValidators([Validators.required]);
    }

    this.courseMaterialForm.controls["ReceiptDate"].updateValueAndValidity();
  }
}
