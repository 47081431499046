import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VocationalTrainerModel } from './vocational-trainer.model';
import { VocationalTrainerService } from './vocational-trainer.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as guarati } from './i18n/gj';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

@Component({
  selector: 'data-list-view',
  templateUrl: './vocational-trainer.component.html',
  styleUrls: ['./vocational-trainer.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class VocationalTrainerComponent extends BaseListComponent<VocationalTrainerModel> implements OnInit {
  vocationalTrainerFilterForm:FormGroup;
  vocationalTrainerForm:FormGroup;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private translationLoaderService: FuseTranslationLoaderService,
    public zone: NgZone,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private vocationalTrainerService: VocationalTrainerService) {
    super(commonService, router, routeParams, snackBar, zone);
    this.translationLoaderService.loadTranslations(english, guarati);
    this.vocationalTrainerFilterForm = this.createVocationalTrainerFilterForm();
  }

  ngOnInit(): void {
    this.onLoadVocationalTrainerCriteria();
    this.vocationalTrainerForm = this.formBuilder.group({ filterText: '' });
    this.vocationalTrainerForm.get('filterText').valueChanges.pipe(
      // if character length greater then 2
      filter(res => {
        this.SearchBy.PageIndex = 0;

        if (res.length == 0) {
          this.SearchBy.Name = '';
          this.onLoadVocationalTrainerCriteria();
          return false;
        }

        return res.length > 2
      }),

      // Time in milliseconds between key events
      debounceTime(650),

      // If previous query is diffent from current   
      distinctUntilChanged()

      // subscription for response
    ).subscribe((searchText: string) => {
      this.SearchBy.Name = searchText;
      this.onLoadVocationalTrainerCriteria();
    });
  }

  ngAfterViewInit() {
    this.tableDataSource.paginator = this.ListPaginator;
  }

  onPageIndexChanged(evt) {
    this.SearchBy.PageIndex = evt.pageIndex;
    this.SearchBy.PageSize = evt.pageSize;

    this.onLoadVocationalTrainerCriteria();
  }

  onLoadVocationalTrainerCriteria(): any {
    this.SearchBy.IsActive = this.vocationalTrainerFilterForm.controls["Status"].value == 'true'? true:this.vocationalTrainerFilterForm.controls["Status"].value=='false'?false:this.vocationalTrainerFilterForm.controls["Status"].value;
    this.IsLoading = true;
    this.vocationalTrainerService.GetAllByCriteria(this.SearchBy).subscribe(response => {
      // this.displayedColumns = ['VTPName','VCName','FullName', 'Mobile', 'Email', 'Gender', 'SocialCategory', 'VCMoblie','VCEmail', 'IsActive', 'Actions'];
      this.displayedColumns = ['FullName', 'Mobile', 'Email', 'Gender','VCName', 'VCMoblie','VCEmail','VTPName',  'IsActive', 'Actions'];
  
      this.tableDataSource.data = response.Results;
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;

      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
    });
  }

  onDeleteVocationalTrainer(vtId: string,vcId: string,vtpId: string,acYear: string) {
    this.dialogService
      .openConfirmDialog(this.Constants.Messages.DeleteConfirmationMessage)
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          this.vocationalTrainerService.deleteVocationalTrainerById(vtId,vcId,vtpId,acYear)
            .subscribe((vocationalTrainerResp: any) => {

              this.zone.run(() => {
                if (vocationalTrainerResp.Success == true) {
                  this.showActionMessage(
                    this.Constants.Messages.RecordDeletedMessage,
                    this.Constants.Html.SuccessSnackbar
                  );
                }else{
                  this.showActionMessage(
                    vocationalTrainerResp.Result,
                    this.Constants.Html.SuccessSnackbar
                  );
                   this.ngOnInit();

                }
                this.ngOnInit();
              }, error => {
                console.log('VocationalTrainer deletion errors =>', error);
              });

            });
          this.ngOnInit();
        }
      });
  }

  exportExcel(): void {
    this.IsLoading = true;
    this.SearchBy.PageIndex = 0;
    this.SearchBy.PageSize = 10000;

    this.vocationalTrainerService.GetAllByCriteria(this.SearchBy).subscribe(response => {
      response.Results.forEach(
        function (obj) {
          if (obj.hasOwnProperty('IsActive')) {
            obj.IsActive = obj.IsActive ? 'Yes' : 'No';
          }
          if (obj.hasOwnProperty('IsImplemented')) {
            obj.IsImplemented = obj.IsImplemented ? 'Yes' : 'No';
          }
          delete obj.VTPId;
          delete obj.VTPName;
          delete obj.VCId;
          delete obj.VTId;
          delete obj.AcademicYearId;
          delete obj.StateName;
          delete obj.IsResigned;
          delete obj.StateCode;
        
          delete obj.TotalRows;
        });

      this.exportExcelFromTable(response.Results, "VocationalTrainers");

      this.IsLoading = false;
      this.SearchBy.PageIndex = 0;
      this.SearchBy.PageSize = 10;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  //Create VocationalTrainer form and returns {FormGroup}
  createVocationalTrainerFilterForm(): FormGroup {
    return this.formBuilder.group({
      //AcademicYearId: new FormControl(),
      Status: new FormControl()
    });
  }

  onLoadVocationalTrainerByFilters(): any {
    this.SearchBy.PageIndex = 0;
    this.onLoadVocationalTrainerCriteria();
  }

  resetFilters(): void {
    this.SearchBy.PageIndex = 0;
    this.vocationalTrainerFilterForm.reset();
    //this.schoolFilterForm.get('AcademicYearId').setValue(this.AcademicYearId);

    this.onLoadVocationalTrainerCriteria();
  }
}
