import { FuseUtils } from '@fuse/utils';

export class EmployerListingModel {

    AuthUserId: string;
    RequestType: any;
    UpdatedBy: string;
    CreatedBy: string;
    IsActive: boolean;
    EmployerId: string;
    EmployerFirstName: string;
    EmployerLastName: string;
    EmployerGender: string;
    EmployerName: string;
    BusinessType: string;
    BusinessDesc: string;
    OrganizationName: string;
    NoOfEmployees: string;
    EmpPhotoUrl: string;
    IsPreferred: boolean;
    IsFreelancer: boolean;
    DateOfFoundation: Date;
    EmployersBranches: any;
    EmployersSectors: any;
    IsSingleBranch: boolean;
    constructor(employerItem?: any) {
        employerItem = employerItem || {};
        this.AuthUserId = employerItem.AuthUserId || '';
        this.EmployerId = employerItem.EmployerId || '00000000-0000-0000-0000-000000000000';
        this.EmployerFirstName = employerItem.EmployerFirstName || '';
        this.EmployerName = employerItem.EmployerName || '';
        this.EmployerLastName = employerItem.EmployerLastName || '';
        this.EmployerGender = employerItem.EmployerGender || '';
        this.BusinessType = employerItem.BusinessType || '';
        this.BusinessDesc = employerItem.BusinessDesc || '';
        this.OrganizationName = employerItem.OrganizationName || '';
        this.NoOfEmployees = employerItem.NoOfEmployees || '';
        this.EmpPhotoUrl = employerItem.EmpPhotoUrl || '';
        this.DateOfFoundation = employerItem.DateOfFoundation || '';
        this.UpdatedBy = employerItem.UpdatedBy || '';
        this.CreatedBy = employerItem.CreatedBy || '';
        this.IsActive = employerItem.IsActive || true;
        this.IsPreferred = employerItem.IsPreferred || true;
        this.IsFreelancer = employerItem.IsFreelancer || true;
        this.IsSingleBranch = employerItem.IsSingleBranch || null;
        this.RequestType = 0;
        this.EmployersBranches = employerItem.EmployersBranches || [];
        this.EmployersSectors = employerItem.EmployersSectors || [];

    }
}

export class EmployerListingBranchModel {

    EmployerId: string;
    EmployerBranchId: string;
    BranchName: string;
    BranchAddress: string;
    DistrictCode: string;
    DistrictName: string;
    Pincode: string;
    StateCode: string;
    Longitude: string;
    Lattitude: string;
    IsMainBranch: boolean;
    POCName: string;
    POCEmailId: string;
    POCMobile: string;
    IsActive: boolean;
    RequestType: any;
    AuthUserId: string;
    StateName: string
    // AccountWorkLocationId:string;



    constructor(employerBranchItem?: any) {
        employerBranchItem = employerBranchItem || {};
        // this.AccountWorkLocationId = employerBranchItem.AccountWorkLocationId || FuseUtils.NewGuid();
        this.EmployerId = employerBranchItem.EmployerId || '00000000-0000-0000-0000-000000000000';
        this.AuthUserId = employerBranchItem.AuthUserId || '';
        this.IsActive = employerBranchItem.IsActive || true;
        this.RequestType = 0;
        this.EmployerBranchId = employerBranchItem.EmployerBranchId || '00000000-0000-0000-0000-000000000000';
        this.BranchName = employerBranchItem.BranchName || '';
        this.StateName = employerBranchItem.StateName || '';
        this.BranchAddress = employerBranchItem.BranchAddress || '';
        this.DistrictCode = employerBranchItem.DistrictCode || '';
        this.DistrictName = employerBranchItem.DistrictName || '';
        this.Pincode = employerBranchItem.Pincode || '';
        this.StateCode = employerBranchItem.StateCode || '';
        this.Longitude = employerBranchItem.Longitude || '';
        this.Lattitude = employerBranchItem.Lattitude || '';
        this.IsMainBranch = employerBranchItem.IsMainBranch || false;
        this.POCName = employerBranchItem.POCName || '';
        this.POCEmailId = employerBranchItem.POCEmailId || '';
        this.POCMobile = employerBranchItem.POCMobile || '';

    }
}

export class EmployerListingSectorsModel {
    EmpLoyerSectorId: string;
    EmployerId: string;
    SectorId: string;
    IsActive: boolean;
    RequestType: any;
    AuthUserId: string;
    // AccountLocationId:string;
    SectorName: string;

    constructor(employerBranchItem?: any) {
        employerBranchItem = employerBranchItem || {};
        // this.AccountLocationId = employerBranchItem.AccountLocationId || FuseUtils.NewGuid();
        this.EmployerId = employerBranchItem.EmployerId || '00000000-0000-0000-0000-000000000000';
        this.SectorId = employerBranchItem.SectorId || '';
        this.AuthUserId = employerBranchItem.AuthUserId || '';
        this.IsActive = employerBranchItem.IsActive || true;
        this.RequestType = 0;
        this.EmpLoyerSectorId = employerBranchItem.EmpLoyerSectorId || '00000000-0000-0000-0000-000000000000';
        this.SectorName = employerBranchItem.SectorName || '';

    }
}












