import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { RouteConstants } from 'app/constants/route.constant'
import { SchoolVTMappingService } from '../school-vt-mapping.service';
import { SchoolVTMappingModel } from '../school-vt-mapping.model';
import { DropdownModel } from 'app/models/dropdown.model';

@Component({
  selector: 'school-vt-mapping',
  templateUrl: './create-school-vt-mapping.component.html',
  styleUrls: ['./create-school-vt-mapping.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CreateSchoolVTMappingComponent extends BaseComponent<SchoolVTMappingModel> implements OnInit {
  schoolVTMappingForm: FormGroup;
  schoolVTMappingModel: SchoolVTMappingModel;
  academicyearList: [DropdownModel];
  phaseList: [DropdownModel];
  sectorList: [DropdownModel];
  jobRoleList: [DropdownModel];
  schoolsList: [DropdownModel];
  roleList: [DropdownModel];
  vtpList: [DropdownModel];
  vocationalCoordinatorList: [DropdownModel];
  vocationList: [DropdownModel];
  remarkText: string;
  statecodeList: [DropdownModel];
  filteredSchoolJobRoleItems: any;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private route: ActivatedRoute,
    private schoolVTMappingService: SchoolVTMappingService,
    private dialogService: DialogService,
    private formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Set the default sectorJobRole Model
    this.schoolVTMappingModel = new SchoolVTMappingModel();
  }

  ngOnInit(): void {

    this.schoolVTMappingService.getDropdownforSectorJobRole(this.UserModel).subscribe((response: any) => {
      if (response[0].Success) {
        this.academicyearList = response[0].Results;
      }
      if (response[1].Success) {
        this.roleList = response[1].Results;
        this.filteredSchoolJobRoleItems = this.roleList.slice();
      }

      if (response[2].Success) {
        this.vocationList = response[2].Results;
      }
      if (response[3].Success) {
        this.vtpList = response[3].Results;
      }
      if (response[4].Success) {
        this.vocationalCoordinatorList = response[4].Results;
      }
      if (response[5].Success) {
        this.statecodeList = response[5].Results;
        for (const i of response[5].Results) {
          this.schoolVTMappingForm.get('StateCode').setValue(i.Id);
        }
      }


      this.route.paramMap.subscribe(params => {
        if (params.keys.length > 0) {
          this.PageRights.ActionType = params.get('actionType');

          if (this.PageRights.ActionType == this.Constants.Actions.New) {
            this.schoolVTMappingModel = new SchoolVTMappingModel();

          } else {
            var schoolVTPVCId: string = params.get('SchoolVTPVCId')

            this.schoolVTMappingService.getSchoolVTMappingById(schoolVTPVCId)
              .subscribe((response: any) => {
                this.schoolVTMappingModel = response.Result;

                if (this.PageRights.ActionType == this.Constants.Actions.Edit)
                  this.schoolVTMappingModel.RequestType = this.Constants.PageType.Edit;
                else if (this.PageRights.ActionType == this.Constants.Actions.View) {
                  this.schoolVTMappingModel.RequestType = this.Constants.PageType.View;
                  this.PageRights.IsReadOnly = true;
                }
                if (this.schoolVTMappingModel.DateOfDeAllocation != null) {
                  this.schoolVTMappingForm.get("DateOfDeAllocation").setValue(this.getDateValue(this.schoolVTMappingModel.DateOfDeAllocation));
                  let dateOfResignationCtrl = this.schoolVTMappingForm.get("DateOfDeAllocation");
                  this.onChangeDateEnableDisableCheckBox(this.schoolVTMappingForm, dateOfResignationCtrl);
                }
                this.onChangeVT(this.schoolVTMappingModel.SchoolSectorJobRolesId);
                this.onChangeVTP(this.schoolVTMappingModel.SchoolSectorJobRolesVTPId);
                this.onChangeVC(this.schoolVTMappingModel.VCId);

                this.schoolVTMappingForm = this.createSchoolVTMappingForm();
              });
          }
        }
      });
    });
    this.schoolVTMappingForm = this.createSchoolVTMappingForm();
  }
  onChangeVT(ssjbId) {
    this.commonService.GetMasterDataByType({ DataType: 'VTPForSchoolSectorJobRoles', ParentId: ssjbId, SelectTitle: 'Vocational Training Provider' }).subscribe((response) => {
      if (response.Success) {
        this.vtpList = response.Results;
      }
    })
  }
  onChangeVTP(vtpId) {
    this.commonService.GetMasterDataByType({ DataType: 'VCForSchoolSectorJobRolesVTP', ParentId: vtpId, SelectTitle: 'Vocational Coordinator' }).subscribe((response) => {
      if (response.Success) {
        this.vocationalCoordinatorList = response.Results;
      }
    });

  }
  onChangeVC(vcId) {


  }

  status(item:any){
    if(item == 'SchoolSectorJobRoles'){
      this.schoolVTMappingForm.patchValue( {'SchoolSectorJobRolesVTPId':null} );
    }else if(item == 'VTP'){
      this.schoolVTMappingForm.patchValue( {'SchoolVTPVCId':null} );
    }
  }
  saveOrUpdateSchoolVTMappingDetails() {
    if (!this.schoolVTMappingForm.valid) {
      this.validateAllFormFields(this.schoolVTMappingForm);
      return;
    }
    this.setValueFromFormGroup(this.schoolVTMappingForm, this.schoolVTMappingModel);

    this.schoolVTMappingService.createOrUpdateSchoolVTMapping(this.schoolVTMappingModel)
      .subscribe((schoolVTMappingResp: any) => {

        if (schoolVTMappingResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );

            this.router.navigate([RouteConstants.SchoolVTMapping.List]);
          });
        }
        else {
          var errorMessages = this.getHtmlMessage(schoolVTMappingResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('SectorJobRole deletion errors =>', error);
      });
  }

  onChangeSector(evt) {

    this.commonService
      .GetMasterDataByType({ DataType: 'JobRoles', ParentId: evt.value })
      .subscribe((response: any) => {

        if (response.Success) {
          this.jobRoleList = response.Results;
        }
      });
  }



  //Create sectorJobRole form and returns {FormGroup}
  createSchoolVTMappingForm(): FormGroup {
    return this.formBuilder.group({
      SchoolVTPVCId: new FormControl({ value: this.schoolVTMappingModel.SchoolVTPVCId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      SchoolSectorJobRolesId: new FormControl({ value: this.schoolVTMappingModel.SchoolSectorJobRolesId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      VTId: new FormControl({ value: this.schoolVTMappingModel.VTId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      SchoolSectorJobRolesVTPId: new FormControl({ value: this.schoolVTMappingModel.SchoolSectorJobRolesVTPId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      AcademicYearId: new FormControl({ value: this.schoolVTMappingModel.AcademicYearId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      DateOfAllocation: new FormControl({ value: new Date(this.schoolVTMappingModel.DateOfAllocation), disabled: this.PageRights.IsReadOnly }, Validators.required),
      DateOfDeAllocation: new FormControl({ value: this.getDateValue(this.schoolVTMappingModel.DateOfDeAllocation), disabled: this.PageRights.IsReadOnly }),
      Remarks: new FormControl({ value: this.schoolVTMappingModel.Remarks, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(350)),
      IsActive: new FormControl({ value: this.schoolVTMappingModel.IsActive, disabled: this.PageRights.IsReadOnly }),
      StateCode: new FormControl({ value: this.schoolVTMappingModel.StateCode, disabled: true }, Validators.required),

    });
  }
}
