import { DatePipe } from '@angular/common';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Component, Input, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { DropdownModel } from 'app/models/dropdown.model';
import { CommonService } from 'app/services/common.service';
import { environment } from 'environments/environment';
import { duration } from 'moment';
import { filter } from 'rxjs/internal/operators/filter';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GratitudeModel } from '../gratitude.model';
import { GratitudeService } from '../gratitude.service';
// import { groupBy } from 'lodash';

@Component({
  selector: 'app-gratitude-approved-vt',
  templateUrl: './gratitude-approved-vt.component.html',
  styleUrls: ['./gratitude-approved-vt.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class GratitudeApprovedVtComponent extends BaseListComponent<GratitudeModel> implements OnInit {

  InternshipApprovalForm: FormGroup;
  InternshipApprovalFilterForm: FormGroup;

  @Input() IsAllowedGloablSearch: boolean;
  tabIndex = 0;
  currentAcademicYearId: string;
  sectorList: [DropdownModel];
  vtpList: [DropdownModel];
  filteredVtpSectorItems: any;
  employerList: [DropdownModel];
  filteredEmployerItems: any;

  hire: boolean = true;
  droppedOut: boolean = true;
  reviewed: boolean = true;
  completed: boolean = true;
  pending: boolean = true;
  durationList = [{ Id: 1, Name: 'Today' }, { Id: 2, Name: 'Yesterday' }, { Id: 3, Name: 'Last 7 days' }, { Id: 4, Name: 'This Month' }, { Id: 5, Name: 'Last Month' }]
  fromDate: any;
  toDate: any;
  today = new Date();
  Constants: any;
  SearchBy: any;
  tableDataSource: any;
  tableDataSource1: any;
  ListPaginator: any;
  IsLoading: boolean;
  displayedColumns: string[];
  ListSort: any;
  imageBasePath: string;

  dataxxxx: any;

  fileEextensionsList = ['jpg',
    'jpeg', 'iso', 'png', 'gif', 'docx',
    'doc', 'pdf', 'mp3', 'mp4', 'mpeg',
    'pptx', 'JPG', 'JPEG', 'ISO', 'PNG',
    'GIF', 'DOCX', 'DOC', 'PDF', 'MP3',
    'MP4', 'MPEG', 'PPTX'];
  gratituteListArray = [];
  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private translationLoaderService: FuseTranslationLoaderService,
    public zone: NgZone,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private service: GratitudeService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
  ) {
    super(commonService, router, routeParams, snackBar, zone);
    // this.translationLoaderService.loadTranslations(english, guarati);
    this.InternshipApprovalForm = this.formBuilder.group({ filterText: '' });
  }

  changeTab(event) {

    console.log(event.index)
    this.tabIndex = event.index == undefined ? event : event.index;
    // if (this.tabIndex == 0) {
    this.getVTApprovedEmployerGratitudeByCriteria();
    // } else {
    //   this.GetNewEmployerGratitudeByCriteria();
    // }

  }

  refreshPage() {
    this.tabIndex;
    this.changeTab(this.tabIndex);
  }

  ngOnInit(): void {
    // this.trigger.openMenu();

    this.imageBasePath = this.Constants.CommonImageUrl.Account;
    // this.imageBasePath = environment.ImgBaseUrl;
    // this.imageBasePath = this.Constants.imageBasePath;
    // show hide gloabl search 
    this.IsAllowedGloablSearch = this.Constants.PageArray.includes(window.location.pathname);

    this.service.getDropdownforInternshipOpportunity(this.UserModel).subscribe(results => {
      if (results[0].Success) {
        this.sectorList = results[0].Results;
      }

      if (results[1].Success) {
        this.employerList = results[1].Results;
        this.filteredEmployerItems = this.employerList.slice();
      }
      // this.SearchBy.PageIndex = 0; // delete after script changed
      // this.SearchBy.PageSize = 10; // delete after script changed

      // this.InternshipApprovalForm = this.formBuilder.group({ filterText: '' });

      //Load initial schools data
      this.getVTApprovedEmployerGratitudeByCriteria();

      this.InternshipApprovalForm.get('filterText').valueChanges.pipe(
        // if character length greater then 2
        filter(res => {
          // this.SearchBy.PageIndex = 0;

          if (res.length == 0) {
            this.SearchBy.Name = '';
            this.getVTApprovedEmployerGratitudeByCriteria();
            return false;
          }

          return res.length > 2
        }),

        // Time in milliseconds between key events
        debounceTime(650),

        // If previous query is diffent from current   
        distinctUntilChanged()

        // subscription for response
      ).subscribe((searchText: string) => {
        this.SearchBy.Name = searchText;
        this.getVTApprovedEmployerGratitudeByCriteria();
      });
    });
    this.InternshipApprovalFilterForm = this.createGratitudeApprovedVTFilterForm();
  }

  ngAfterViewInit() {
    this.tableDataSource.paginator = this.ListPaginator;
  }

  onPageIndexChanged(evt) {
    this.SearchBy.PageIndex = evt.pageIndex;
    this.SearchBy.PageSize = evt.pageSize;

    this.getVTApprovedEmployerGratitudeByCriteria();
  }

  getVTApprovedEmployerGratitudeByCriteria(): any {

    if (this.tabIndex == 0) {
      this.SearchBy.VTGuid = this.UserModel.UserTypeId;//"605ddab9-24bf-4c9c-aa7f-d0b8244dc3fd";//
      this.service.NewEmployerGratitude(this.SearchBy).subscribe(response => {

        this.displayedColumns = ['StudentName', 'Messages', 'EmployerName', 'Actions'];
        let gratitudeNewRequestData: any;
        gratitudeNewRequestData = response.Results;

        if (gratitudeNewRequestData != null && gratitudeNewRequestData != '') {


          // for (let i = 0; i < 4; i++) {
          //   gratitudeNewRequestData[0].GratitudeDate = "2023/02/06"
          //   this.gratituteListArray.push(gratitudeNewRequestData[0]);

          // }

          // console.log(this.gratituteListArray);

          const dateArray: GroupByDateArray[] = [];
          for (let k = 0; k < gratitudeNewRequestData.length; k++) {
            let xxx = dateArray.filter(x => x.date == gratitudeNewRequestData[k].GratitudeDate);
            if (xxx == null || xxx.length == 0) {
              dateArray.push({
                date: gratitudeNewRequestData[k].GratitudeDate,
                isGroupBy: true
              })
            }
          }

          console.log(dateArray);
          let soretdDateArray = dateArray.sort((a, b) => a.date > b.date ? -1 : 1);
          const finalArray: (GratitudeModel | GroupByDateArray)[] = [];
          for (let i = 0; i < soretdDateArray.length; i++) {
            let yyd = soretdDateArray.filter(x => x.date == soretdDateArray[i].date);
            if (yyd != null) {
              yyd.forEach(element => {
                finalArray.push({
                  date: element.date,
                  isGroupBy: true
                });
              });
            }


            let asd = gratitudeNewRequestData.filter(x => x.GratitudeDate == soretdDateArray[i].date);


            if (asd != null || asd.length > 0) {
              asd.forEach(element => {
                finalArray.push(element);
              });
            }
          }
          console.log('File Extenshion =', this.fileEextensionsList);
          console.log(finalArray);
          this.tableDataSource.data = finalArray;
          this.tableDataSource.paginator = this.ListPaginator;
          this.SearchBy.TotalResults = finalArray.length;
          this.tableDataSource.sort = this.ListSort;
          this.tableDataSource.filteredData = this.tableDataSource.data;
        }
        this.zone.run(() => {
          if (this.tableDataSource.data.length == 0) {
            this.showNoDataFoundSnackBar();
          }
        });
        this.IsLoading = false;
      }, error => {
        console.log(error);
        this.IsLoading = false;
      });

    }
    else if (this.tabIndex == 1) {
      // this.displayedColumns = ['StudentName', 'Messages', 'EmployerName', 'Actions'];
      // this.tableDataSource.data = ELEMENT_DATA;
      this.SearchBy.VTGuid = this.UserModel.UserTypeId;//"605ddab9-24bf-4c9c-aa7f-d0b8244dc3fd";//
      this.service.ApprovedEmployerGratitude(this.SearchBy).subscribe(response => {

        this.displayedColumns = ['StudentName', 'Messages', 'EmployerName'];
        let gratitudeApprovedEmployerData: any;
        gratitudeApprovedEmployerData = response.Results;


        if (gratitudeApprovedEmployerData != null && gratitudeApprovedEmployerData != '') {


          // for (let i = 0; i < 4; i++) {
          //   gratitudeApprovedEmployerData[0].GratitudeDate = "2023/02/06"
          //   this.gratituteListArray.push(gratitudeApprovedEmployerData[0]);

          // }

          // console.log(this.gratituteListArray);
    
          const dateArray: GroupByDateArray[] = [];
          for (let k = 0; k < gratitudeApprovedEmployerData.length; k++) {
            let xxx = dateArray.filter(x => x.date == gratitudeApprovedEmployerData[k].GratitudeDate);
            if (xxx == null || xxx.length == 0) {
              dateArray.push({
                date: gratitudeApprovedEmployerData[k].GratitudeDate,
                isGroupBy: true
              })
            }
          }

          console.log(dateArray);

          let soretdDateArray = dateArray.sort((a, b) => a.date > b.date ? -1 : 1);
          const finalArray: (GratitudeModel | GroupByDateArray)[] = [];
          for (let i = 0; i < soretdDateArray.length; i++) {
            let yyd = soretdDateArray.filter(x => x.date == soretdDateArray[i].date);
            if (yyd != null) {
              yyd.forEach(element => {
                finalArray.push({
                  date: element.date,
                  isGroupBy: true
                });
              });
            }

            let asd = gratitudeApprovedEmployerData.filter(x => x.GratitudeDate == soretdDateArray[i].date);


            if (asd != null || asd.length > 0) {
              asd.forEach(element => {
                finalArray.push(element);
              });
            }
          }
          console.log('File Extenshion =', this.fileEextensionsList);
          console.log(finalArray);
          this.tableDataSource1.data = finalArray;
          this.tableDataSource1.paginator = this.ListPaginator;
          this.SearchBy.TotalResults = finalArray.length;
          this.tableDataSource1.sort = this.ListSort;
          this.tableDataSource1.filteredData = this.tableDataSource1.data;
        }
        this.zone.run(() => {
          if (this.tableDataSource1.data.length == 0) {
            this.showNoDataFoundSnackBar();
          }
        });
        this.IsLoading = false;
      }, error => {
        console.log(error);
        this.IsLoading = false;
      });

    }



    // group by 

  }


  GetNewEmployerGratitudeByCriteria(): any { }
  showNoDataFoundSnackBar() {
    throw new Error('Method not implemented.');
  }


  AcceptGratitude(gratitudeId: string) {
    this.service.AcceptGratitude(gratitudeId).subscribe(response => {
      if (response.Success) {
        this.zone.run(() => {
          this.showActionMessage(
            response.Messages[0],
            this.Constants.Html.SuccessSnackbar
          );
          this.getVTApprovedEmployerGratitudeByCriteria();
          this.ngOnInit();
          // this.router.navigate([RouteConstants.Student.List]);
        });
      } else {
        this.zone.run(() => {
          this.showActionMessage(
            response.Messages[0],
            this.Constants.Html.ErrorSnackbar,
          );
          this.getVTApprovedEmployerGratitudeByCriteria();
          this.ngOnInit();
        });
      }

    });
  }

  RejectGratitude(gratitudeId: string) {

    this.service.RejectGratitude(gratitudeId).subscribe(response => {
      if (response.Success) {
        this.zone.run(() => {
          this.showActionMessage(
            response.Messages[0],
            this.Constants.Html.SuccessSnackbar
          );
          this.getVTApprovedEmployerGratitudeByCriteria();
          this.ngOnInit();
          // this.router.navigate([RouteConstants.Student.List]);
        });
      } else {
        this.zone.run(() => {
          this.showActionMessage(
            response.Messages[0],
            this.Constants.Html.ErrorSnackbar,
          );
          this.getVTApprovedEmployerGratitudeByCriteria();
        });
      }

    });
  }


  onLoadSchoolVTPSectorsByFilters(): any {
    // this.SearchBy.PageIndex = 0;
    this.getVTApprovedEmployerGratitudeByCriteria();
  }

  resetFilters(): void {
    this.InternshipApprovalFilterForm.reset();
    // this.InternshipApprovalFilterForm.get('AcademicYearId').setValue(this.currentAcademicYearId);

    this.getVTApprovedEmployerGratitudeByCriteria();
  }

  createGratitudeApprovedVTFilterForm(): FormGroup {
    return this.formBuilder.group({
      SectorId: new FormControl(),
      EmployerId: new FormControl(),
      DurationId: new FormControl(),
    });
  }

  isGroup(index, item): boolean {
    return item.isGroupBy;
  }


}

interface GroupByDateArray {
  date: string;
  isGroupBy: boolean;
}


// export interface PeriodicElement {
//   Messages: string;
//   StudentName: string;
//   EmployerName: string;
//   Actions: string;
//   EmployerName1: string;
// }

// const ELEMENT_DATA: PeriodicElement[] = [
//   { StudentName: "Sakshi Singh", Messages: 'Lorem ipsum dolor sit amet, conse Ctetur am ipsum dolor.Lorem ipsum dolor sit amet, conse Ctetur am ipsum dolor.', EmployerName: "Nordic Mechanical Services", EmployerName1: "Preferred Partner", Actions: 'H' },
//   { StudentName: "Sakshi Singh", Messages: 'Lorem ipsum dolor sit amet, conse Ctetur am ipsum dolor.Lorem ipsum dolor sit amet, conse Ctetur am ipsum dolor.', EmployerName: "Nordic Mechanical Services", EmployerName1: "Preferred Partner", Actions: 'H' },
//   { StudentName: "Sakshi Singh", Messages: 'Lorem ipsum dolor sit amet, conse Ctetur am ipsum dolor.Lorem ipsum dolor sit amet, conse Ctetur am ipsum dolor.', EmployerName: "Nordic Mechanical Services", EmployerName1: "Preferred Partner", Actions: 'H' },
// ];

interface GroupByDateArray {
  date: string;
  isGroupBy: boolean;
}