<!-- <mat-toolbar class="mat-elevation-z1">
  <span>Staff Master (Adding New)</span>

  <span class="fill-remaining-space"></span>

  <button mat-stroked-button color="warn" (click)="onClose()" type="button">
    <mat-icon>clear</mat-icon>
  </button>
</mat-toolbar> -->
<!-- HEADER -->
<div class="header1" fxLayout="column" fxLayoutAlign="start center" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="space-between center" style="background: #3f51b5;height: 64px; height: 61px;
  padding: 0px 24px;
">
    <!-- APP TITLE -->
    <div [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
        <div class="h2 mt-4 mb-12  heading-text1" style="color:#fff;">
            Student Internship Approval Details
        </div>
    </div>
    <!-- / APP TITLE -->
    <!-- ADD BUTTON -->

    <button mat-flat-button [color]="'warn'" (click)="onClose()" class="mat-elevation-z1" style="background-color: #f44336 !important;
  color: #fff !important;">
        <!-- <mat-icon svgIcon="heroicons_outline:arrow-narrow-left"></mat-icon> -->
        <mat-icon>clear</mat-icon>
    </button>
    <!-- / ADD BUTTON -->
</div>
<!-- / HEADER -->
<div id="staff" class="page-layout carded fullwidth inner-scroll">
    <!-- TOP BACKGROUND -->
    <!-- <div class="top-bg accent"></div> -->
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">
        <!-- CONTENT CARD -->
        <div class="content-card mat-elevation-z2 p-5 mb-12">
            <div class="content">
                <!-- <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" class="staff-name">
                    <div class='column' fxFlex="50">
                        <span class="lbl-bold">Staff Name : </span> {{data.FirstName }} {{data.LastName}}
                    </div>
                    <div class='column' fxFlex="50">
                        <span class="lbl-bold">Staff Designation : </span>{{data.StaffDesignation }}
                    </div>
                </div> -->

                <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start"
                    style="height: auto !important;" id="displayContent">
                    <!-- <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                        appearance="outline" fxFlex="0 1 calc(20%)" fxFlex.lt-md="0 1 calc(20%)" fxFlex.lt-sm="100%"
                        style="height: auto;">
                        <mat-card class="example-card">

                            <img mat-card-image src="{{studentInternshipDataById.StudentProfilePhoto}}"
                                alt="Photo of a Student">

                        </mat-card>
                    </div> -->

                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                        appearance="outline" fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="100%"
                        style="height: auto;">
                        <mat-list style="padding-top:0px !important;" fxFlex="0 1 calc(90%)"
                            fxFlex.lt-md="0 1 calc(90%)" fxFlex.lt-sm="100%">

                            <!-- <mat-list-item>
                                <span fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)"
                                    fxFlex.lt-sm="70%">Status</span>
                                <span fxFlex="0 1 calc(5%)" fxFlex.lt-md="0 1 calc(5%)" fxFlex.lt-sm="5%">:</span>
                                <span
                                    *ngIf="studentInternshipDataById !=undefined && studentInternshipDataById.AdmStatus==5"
                                    ngClass="{{studentInternshipDataById.AdmStatus==5 ? 'no' : 'yes'}}">
                                    {{studentInternshipDataById.AdmStatus==5?'Passed Out': ''}} </span>
                                <span
                                    *ngIf="studentInternshipDataById !=undefined && studentInternshipDataById.AdmStatus==3"
                                    ngClass="{{studentInternshipDataById.AdmStatus==3 ? 'no' : 'yes'}}">
                                    {{studentInternshipDataById.AdmStatus==3?'Cancelled': ''}} </span>
                                <span
                                    *ngIf="studentInternshipDataById !=undefined && studentInternshipDataById.AdmStatus==1"
                                    ngClass="{{studentInternshipDataById.AdmStatus==1 ? 'textgreen' : 'no'}}">
                                    {{studentInternshipDataById.AdmStatus==1?'Live': ''}} </span>

                            </mat-list-item> -->
                            <mat-list-item><span fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="70%"
                                    class="lbl-bold">Student Name</span>
                                <span fxFlex="0 1 calc(5%)" fxFlex.lt-md="0 1 calc(5%)" fxFlex.lt-sm="5%">:</span>
                                <span>{{
                                    studentInternshipDataById.StudentName
                                    }}</span>
                            </mat-list-item>

                            <mat-list-item><span fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="70%"
                                    class="lbl-bold">Mobile</span>
                                <span fxFlex="0 1 calc(5%)" fxFlex.lt-md="0 1 calc(5%)" fxFlex.lt-sm="5%">:</span>
                                {{
                                studentInternshipDataById.Mobile
                                }}
                            </mat-list-item>
                            <mat-list-item><span fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="70%"
                                    class="lbl-bold">Applied Internship</span>
                                <span fxFlex="0 1 calc(5%)" fxFlex.lt-md="0 1 calc(5%)" fxFlex.lt-sm="5%">:</span>
                                <span>{{
                                    studentInternshipDataById.InternshipTitle
                                    }}</span>
                            </mat-list-item>
                            <mat-list-item><span fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="70%"
                                    class="lbl-bold">Internship Start Date</span>
                                <span fxFlex="0 1 calc(5%)" fxFlex.lt-md="0 1 calc(5%)" fxFlex.lt-sm="5%">:</span>
                                <span>{{
                                    studentInternshipDataById.InternshipStartDate | date
                                    }}</span>
                            </mat-list-item>

                            <mat-list-item><span fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="70%"
                                    class="lbl-bold">Class Name</span>
                                <span fxFlex="0 1 calc(5%)" fxFlex.lt-md="0 1 calc(5%)" fxFlex.lt-sm="5%">:</span>
                                <span>{{
                                    studentInternshipDataById.ClassName
                                    }}</span>
                            </mat-list-item>



                        </mat-list>
                    </div>
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="flex-start" id="left-content"
                        appearance="outline" fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="100%"
                        style="height: auto;">
                        <mat-list style="padding-top:0px !important;" fxFlex="0 1 calc(90%)"
                            fxFlex.lt-md="0 1 calc(90%)" fxFlex.lt-sm="100%">
                            <mat-list-item><span fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="70%"
                                    class="lbl-bold">Employer Name</span>
                                <span fxFlex="0 1 calc(5%)" fxFlex.lt-md="0 1 calc(5%)" fxFlex.lt-sm="5%">:</span>
                                <span>{{
                                    studentInternshipDataById.EmployerName
                                    }}</span>
                            </mat-list-item>

                            <mat-list-item><span fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="70%"
                                    class="lbl-bold">IsApplied</span>
                                <span fxFlex="0 1 calc(5%)" fxFlex.lt-md="0 1 calc(5%)" fxFlex.lt-sm="5%">:</span>
                                <!-- <span>{{
                                    studentInternshipDataById.IsApplied
                                    }}</span> -->
                                <span *ngIf="studentInternshipDataById.IsApplied" class="truncate">Yes</span>
                                <span *ngIf="!studentInternshipDataById.IsApplied" class="truncate">No </span>
                            </mat-list-item>

                            <mat-list-item><span fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="70%"
                                    class="lbl-bold">IsApproved ByEmployer</span>
                                <span fxFlex="0 1 calc(5%)" fxFlex.lt-md="0 1 calc(5%)" fxFlex.lt-sm="5%">:</span>
                                <!-- <span>{{
                                    studentInternshipDataById.IsApprovedByEmployer
                                    }}</span> -->
                                <span *ngIf="studentInternshipDataById.IsApprovedByEmployer" class="truncate">Yes</span>
                                <span *ngIf="!studentInternshipDataById.IsApprovedByEmployer" class="truncate">No
                                </span>
                            </mat-list-item>

                            <mat-list-item><span fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="70%"
                                    class="lbl-bold">IsApproved ByVT</span>
                                <span fxFlex="0 1 calc(5%)" fxFlex.lt-md="0 1 calc(5%)" fxFlex.lt-sm="5%">:</span>
                                <!-- <span>{{
                                    studentInternshipDataById.IsApprovedByVT
                                    }}</span> -->
                                <span *ngIf="studentInternshipDataById.IsApprovedByVT" class="truncate">Yes</span>
                                <span *ngIf="!studentInternshipDataById.IsApprovedByVT" class="truncate">No </span>
                            </mat-list-item>

                            <mat-list-item><span fxFlex="0 1 calc(50%)" fxFlex.lt-md="0 1 calc(50%)" fxFlex.lt-sm="70%"
                                    class="lbl-bold">IsPlaced</span>
                                <span fxFlex="0 1 calc(5%)" fxFlex.lt-md="0 1 calc(5%)" fxFlex.lt-sm="5%">:</span>
                                <!-- <span>{{
                                    studentInternshipDataById.IsPlaced
                                    }} -->

                                <span *ngIf="studentInternshipDataById.IsPlaced" class="truncate">Yes</span>
                                <span *ngIf="!studentInternshipDataById.IsPlaced" class="truncate">No </span>
                            </mat-list-item>
                        </mat-list>
                    </div>

                </div>

            </div>
            <!-- / CONTENT CARD -->
        </div>
        <!-- / CENTER -->
    </div>