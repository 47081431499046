import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';
import { UserModel } from "app/models/user.model";

@Injectable()
export class SchoolSectorjobroleClasssectionMappingService {
    constructor(private http: BaseService) { }

    getSectorJobRoles(): Observable<any> {
        return this.http
            .HttpGet(this.http.Services.SchoolVTMapping.GetAll)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetAllByCriteria(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.SchoolSecJobClassSecVTMapping.GetAllByCriteria, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    getSchoolSecJobClassSecVTMappingById(schoolVTPVCId: string) {
        let requestParams = {
            DataId: schoolVTPVCId
        };

        return this.http
            .HttpPost(this.http.Services.SchoolSecJobClassSecVTMapping.GetById, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    createOrUpdateSchoolSecJobClassSecVTMapping(formData: any) {
        return this.http
            .HttpPost(this.http.Services.SchoolSecJobClassSecVTMapping.CreateOrUpdate, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    deleteSchoolSecJobClassSecVTById(schoolSecJobClassSecVTMappingId: string) {
        var schoolSecJobClassSecVTMappingParams = {
            DataId: schoolSecJobClassSecVTMappingId
        };

        return this.http
            .HttpPost(this.http.Services.SchoolSecJobClassSecVTMapping.Delete, schoolSecJobClassSecVTMappingParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }
    getAllRoles(): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.RoleTransaction.GetAllRoles, {})
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    getDropdownforSectorJobRole(userModel: UserModel): Observable<any[]> {
        let academicYearRequest = this.http.GetMasterDataByType({ DataType: 'AcademicYearsByState', UserId:userModel.UserTypeId, SelectTitle: 'Academic Year' });
        let jobRoleRequest = this.http.GetMasterDataByType({ DataType: 'SchoolSectorJobRolesByAY', ParentId:userModel.UserTypeId, SelectTitle: 'School' });
        let vtRequest = this.http.GetMasterDataByType({ DataType: "VocationalTrainers", UserId: null, ParentId: null });
        let vtpRequest = this.http.GetMasterDataByType({ DataType: 'VTPForSchoolSectorJobRoles', ParentId: '', SelectTitle: 'VTP' });
        let vcRequest = this.http.GetMasterDataByType({ DataType: 'VCForSchoolSectorJobRolesVTP', ParentId: '', SelectTitle: 'VC' });
        let statecodeRequest = this.http.GetMasterDataByType({ DataType: 'StatesByUserId', UserId: userModel.UserTypeId, SelectTitle: 'StateCode' });
        let sectorRequest = this.http.GetMasterDataByType({ DataType: 'Sectors', SelectTitle: 'Sector' });
        let classRequest = this.http.GetMasterDataByType({ DataType: 'SchoolClasses', SelectTitle: 'School Class' });
        let sectionRequest = this.http.GetMasterDataByType({ DataType: 'Sections', SelectTitle: 'Section' });


        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        return forkJoin([academicYearRequest, jobRoleRequest, vtRequest, vtpRequest, vcRequest, statecodeRequest, sectorRequest, classRequest, sectionRequest]);
    }
}
