import { Component, OnInit, NgZone, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProfileChangeRequestModel } from './profile-change-request.model';
import { ProfileChangeRequestService } from './profile-change-request.service';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as guarati } from './i18n/gj';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { DropdownModel } from 'app/models/dropdown.model';
import { environment } from 'environments/environment';
import { UserModel } from "app/models/user.model";

@Component({
  selector: 'data-list-view',
  templateUrl: './profile-change-request.component.html',
  styleUrls: ['./profile-change-request.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class ProfileChangeRequestComponent extends BaseListComponent<ProfileChangeRequestModel> implements OnInit {
  studentSearchForm: FormGroup;

  currentAcademicYearId: string;
  academicYearList: [DropdownModel];
  sectorList: [DropdownModel];
  vtpList: [DropdownModel];
  filteredVtpSectorItems: any;
  schoolList: [DropdownModel];
  filteredSchoolItems: any;

  // schoolVTPSectorSearchForm: FormGroup;
  studentFilterForm: FormGroup;

  tabIndex = 0;
  isChecked = false
  ApiBaseUrlImage = this.Constants.CommonImageUrl.Account;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private translationLoaderService: FuseTranslationLoaderService,
    public zone: NgZone,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private profileChangeRequestService: ProfileChangeRequestService) {
    super(commonService, router, routeParams, snackBar, zone);
    this.translationLoaderService.loadTranslations(english, guarati);
    this.studentSearchForm = this.formBuilder.group({ filterText: '' });
    this.studentFilterForm = this.createSchoolVtpSectorFilterForm();

  }

  ngOnInit(): void {
    this.profileChangeRequestService.getDropdownforSchool(this.UserModel).subscribe(results => {


      if (results[1].Success) {
        this.academicYearList = results[1].Results;
      }


      if (results[10].Success) {
        this.vtpList = results[10].Results;
      }
      if (results[11].Success) {
        this.sectorList = results[11].Results;
      }
    });
    this.onLoadSchoolsByCriteria();

    this.SearchBy.PageIndex = 0; // delete after script changed
    this.SearchBy.PageSize = 10; // delete after script changed

    this.studentSearchForm = this.formBuilder.group({ filterText: '' });

    // Load initial schools data
    this.onLoadSchoolsByCriteria();

    this.studentSearchForm.get('filterText').valueChanges.pipe(
      // if character length greater then 2
      filter(res => {
        this.SearchBy.PageIndex = 0;

        if (res.length == 0) {
          this.SearchBy.Name = '';
          this.onLoadSchoolsByCriteria();
          return false;
        }

        return res.length > 2
      }),

      // Time in milliseconds between key events
      debounceTime(650),

      // If previous query is diffent from current   
      distinctUntilChanged()

      // subscription for response
    ).subscribe((searchText: string) => {
      this.SearchBy.Name = searchText;
      this.onLoadSchoolsByCriteria();
    });
  }
  refreshPage() {
    this.tabIndex;
    this.changeTab(this.tabIndex);
  }

  changeTab(event) {
    this.tabIndex = event.index == undefined ? event : event.index;
    if (this.tabIndex === 0) {
      this.onLoadSchoolsByCriteria();

    } else {
      this.onLoadSchoolsByCriteriaCom()
    }
  }
  ngAfterViewInit() {
    this.tableDataSource.paginator = this.ListPaginator;
  }

  onPageIndexChanged(evt) {
    this.SearchBy.PageIndex = evt.pageIndex;
    this.SearchBy.PageSize = evt.pageSize;

    this.onLoadSchoolsByCriteria();
  }

  onLoadSchoolsByCriteria(): any {
    this.IsLoading = true;
    this.SearchBy.SchoolGuid = (this.Constants.GlobalSchoolId == 'undefined' || this.Constants.GlobalSchoolId == 'null') ? null : this.Constants.GlobalSchoolId;
    this.SearchBy.AcademicYearGuid = (this.Constants.GlobalAcademicYearId == 'undefined' || this.Constants.GlobalAcademicYearId == 'null') ? null : this.Constants.GlobalAcademicYearId;
    this.SearchBy.TypeofRequest = "Profile Correction Details";
    this.SearchBy.VTId = this.UserModel.UserTypeId;
    this.SearchBy.RoleCode = this.UserModel.RoleCode;
    this.SearchBy.IsActionTaken = false;
    this.SearchBy.SectorGuid = this.studentFilterForm.controls['SectorId'].value;
    this.SearchBy.RoleCode = this.UserModel.RoleCode;






    this.profileChangeRequestService.GetRequestsByVTIdRequestTypeIsActionTaken(this.SearchBy).subscribe(response => {
      this.displayedColumns = ['COMPLETE', 'STUDENT', 'CHANGES', 'DATE', 'Actions'];

      this.tableDataSource.data = response.Results;
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;
      this.SearchBy.TotalResults = response.TotalResults;

      setTimeout(() => {
        this.ListPaginator.pageIndex = this.SearchBy.PageIndex;
        this.ListPaginator.length = this.SearchBy.TotalResults;
      });

      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  onLoadSchoolsByCriteriaCom(): any {
    this.IsLoading = true;
    this.IsLoading = true;
    this.SearchBy.SchoolGuid = (this.Constants.GlobalSchoolId == 'undefined' || this.Constants.GlobalSchoolId == 'null') ? null : this.Constants.GlobalSchoolId;
    this.SearchBy.AcademicYearGuid = (this.Constants.GlobalAcademicYearId == 'undefined' || this.Constants.GlobalAcademicYearId == 'null') ? null : this.Constants.GlobalAcademicYearId;
    this.SearchBy.TypeofRequest = "Profile Correction Details";
    this.SearchBy.VTId = this.UserModel.UserTypeId;
    this.SearchBy.IsActionTaken = true;
    this.SearchBy.SectorGuid = this.studentFilterForm.controls['SectorId'].value;

    this.SearchBy.RoleCode = this.UserModel.RoleCode;



    this.profileChangeRequestService.GetRequestsByVTIdRequestTypeIsActionTaken(this.SearchBy).subscribe(response => {
      this.displayedColumns = ['STUDENT', 'CHANGES', 'DATE'];

      this.tableDataSource.data = response.Results;
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;
      this.SearchBy.TotalResults = response.TotalResults;



      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
    }, error => {
      console.log(error);
      this.IsLoading = false;
    });
  }

  onChangeStudent(RequestId: string, e: any) {
    var xxx = RequestId;
    this.isChecked = false;
    this.dialogService
      .openConfirmDialog('Is the request completed?')
      .afterClosed()
      .subscribe(confResp => {
        debugger
        if (confResp) {
          this.profileChangeRequestService.SetActionTaken(RequestId)
            .subscribe((schoolResp: any) => {
              this.zone.run(() => {
                if (schoolResp.Success) {
                  this.showActionMessage(
                    this.Constants.Messages.RecordCompletedMessage,
                    this.Constants.Html.SuccessSnackbar
                  );
                }
                this.ngOnInit();
              }, error => {
                console.log('Student  errors =>', error);
              });

            });
          this.ngOnInit();
        } else {
          this.onLoadSchoolsByCriteria();
        }
      });
  }



  onLoadSchoolVTPSectorsByFilters(): any {
    this.SearchBy.PageIndex = 0;
    this.onLoadSchoolsByCriteria();
  }

  resetFilters(): void {
    this.studentFilterForm.reset();
    this.studentFilterForm.get('AcademicYearId').setValue(this.currentAcademicYearId);

    this.onLoadSchoolsByCriteria();
    this.onLoadSchoolsByCriteriaCom();
  }
  //Create SchoolVtpSectorFilter form and returns {FormGroup}
  createSchoolVtpSectorFilterForm(): FormGroup {
    return this.formBuilder.group({
      AcademicYearId: new FormControl(),
      SectorId: new FormControl(),
      VTPId: new FormControl(),
      // SchoolId: new FormControl(),
    });
  }
  showOptions(e: any) {

    return e.checked = false



  }
  studentLink(StudentId) {
    if (this.Constants.GlobalSchoolId != null && this.Constants.GlobalAcademicYearId != null) {
      if (this.Constants.GlobalSchoolId != "undefined" && this.Constants.GlobalAcademicYearId != "undefined") {
        this.router.navigate(['/vt-student-details', StudentId]);
      } else {
        this.zone.run(() => {
          this.showActionMessage(
            'Please Select  AcademicYear',
            this.Constants.Html.SuccessSnackbar
          );
        });
      }

    } else {
      this.zone.run(() => {
        this.showActionMessage(
          'Please Select School & AcademicYear',
          this.Constants.Html.SuccessSnackbar
        );
      });

    }
  }

}
