import { Component, OnInit, NgZone, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, finalize } from 'rxjs/operators';
import { DropdownModel } from 'app/models/dropdown.model';
import { environment } from 'environments/environment';
import { InterfaceModel } from './interface.model';
import { InterfaceService } from './interface.service';

@Component({
  selector: 'app-interface',
  templateUrl: './interface.component.html',
  styleUrls: ['./interface.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class InterfaceComponent extends BaseListComponent<InterfaceModel> implements OnInit {
  interfaceForm: FormGroup;
  interfaceModel: InterfaceModel;
  roomDamgedList: { Id: string, Name: string, IsDisabled: boolean }[];

  currentDate: Date;
  statecodeList: [DropdownModel];
  State: any;
  StateName: any;
  schoolList: [DropdownModel];
  importInstanceId: any;
  SchoolRelatedLink: any;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private translationLoaderService: FuseTranslationLoaderService,
    public zone: NgZone,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private interfaceService: InterfaceService) {
    super(commonService, router, routeParams, snackBar, zone);
    // this.translationLoaderService.loadTranslations(english, guarati);

    this.interfaceModel = new InterfaceModel();
    setTimeout(() => {                           // <<<---using ()=> syntax
      this.onLoadCurrentImportInstanceForState();
    }, 12000);

  }

  ngOnInit(): void {
    this.roomDamgedList = [{ Id: "None", Name: "None", IsDisabled: false }, { Id: "Room's Wall", Name: "Room's Wall", IsDisabled: false }, { Id: 'Window/s', Name: 'Window/s', IsDisabled: false }, { Id: 'Grills', Name: 'Grills', IsDisabled: false }, { Id: 'Floor', Name: 'Floor', IsDisabled: false }, { Id: 'Electrical board/s & Fitting', Name: 'Window/sElectrical board/s & Fitting', IsDisabled: false }]
    this.interfaceService.getDropdownforInterfaces(this.UserModel).subscribe((results) => {
      if (results[0].Success) {
        this.statecodeList = results[0].Results;

        for (const i of results[0].Results) {
          /// this.interfaceForm.get('StateCode').setValue(i.Id);
          this.State = i.Id
          this.StateName = i.Name
        }
      }
      this.currentDate = new Date();
      this.interfaceForm = this.createInterfaceForm();
    });

  }

  onClickStartSyncingData() {
    let requestParams = {
      AuthUserId: "Systemmm",
      RequestType: 0,
      CreatedBy: "Systemmm",
      CreatedOn: this.currentDate,
      UpdatedBy: "Systemmm",
      UpdatedOn: this.currentDate,
      IsActive: true,
      SyncDate: "2023-07-07T11:10",
      StateCode: this.State,
      AuthToken: "Bearer " + this.UserModel.AuthToken
    }

    this.interfaceService.StartSyncingData(requestParams).subscribe(response => {
      console.log(response.Results);
      response.Results;

    }, error => {
      console.log(error);

    });
  }

  onLoadCurrentImportInstanceForState(): any {
    let requestParams = {
      DataId: this.State,
    }

    this.interfaceService.getApiDataPeriodically(requestParams).subscribe(CurtImInstForStateResponse => {
      console.log(CurtImInstForStateResponse.Result);
      CurtImInstForStateResponse.Result;
      if (CurtImInstForStateResponse.Success) {
        this.importInstanceId = CurtImInstForStateResponse.Result["ImportInstanceId"];
        if (CurtImInstForStateResponse.Result["LH2APICallInProgress"] == true && CurtImInstForStateResponse.Result["SchoolImportInProgress"] == false && CurtImInstForStateResponse.Result["IsComplete"] == false) {
          //this.dialogService.openShowDialog("Import data from Lahi is started");
          this.zone.run(() => {
            this.snackBar.open("Import data from Lahi is started", "Dismiss", {
              duration: 10000,
              verticalPosition: 'top',
              horizontalPosition: 'right',
              panelClass: ["error-snackbar"]
            });
          });
          // this.showNoDataFoundSnackBar();
          this.Constants.Messages.LH2APICallInProgressMessage;

          let requestParamsIntermidiateSchools = {
            DataId: false,
          }

          this.interfaceService.GetIntermidiateSchools(requestParamsIntermidiateSchools).subscribe(intermidiateSchoolResp => {
            console.log(intermidiateSchoolResp.Results);
            this.schoolList = intermidiateSchoolResp.Results


          }, error => {
            console.log(error);

          });
        }
        else if (CurtImInstForStateResponse.Result["LH2APICallInProgress"] == false && CurtImInstForStateResponse.Result["SchoolImportInProgress"] == false && CurtImInstForStateResponse.Result["IsComplete"] == false) {

          let requestParamsIntermidiateSchools = {
            DataId: false,
          }

          this.interfaceService.GetIntermidiateSchools(requestParamsIntermidiateSchools).subscribe(intermidiateSchoolResp => {
            console.log(intermidiateSchoolResp.Results);
            this.schoolList = intermidiateSchoolResp.Results

          }, error => {
            console.log(error);

          });
        }
        else if (CurtImInstForStateResponse.Result["LH2APICallInProgress"] == false && CurtImInstForStateResponse.Result["SchoolImportInProgress"] == true && CurtImInstForStateResponse.Result["IsComplete"] == false) {
          //this.Constants.Messages.SchoolImportInProgressMessage
          this.zone.run(() => {
            this.snackBar.open("School Import data from Lahi is started", "Dismiss", {
              duration: 10000,
              verticalPosition: 'top',
              horizontalPosition: 'right',
              panelClass: ["error-snackbar"]
            });
          });
        }
        else if (CurtImInstForStateResponse.Result["LH2APICallInProgress"] == false && CurtImInstForStateResponse.Result["SchoolImportInProgress"] == false && CurtImInstForStateResponse.Result["IsComplete"] == true) {
          this.dialogService.openShowDialog("Import data from Lahi is Complated");

        }
      }


    }, error => {
      console.log(error);

    });
  }

  onChangeSchool(selectedSchoolIds) {
    if (selectedSchoolIds.length > 1) {
      let requestSchoolRelatedParams = {
        ImportInstanceId: this.importInstanceId,
        SchoolList: selectedSchoolIds
      }
      this.interfaceService.GetSchoolRelatedObjects(requestSchoolRelatedParams).subscribe(SchoolRelatedResponse => {
        if (SchoolRelatedResponse.success) {
          this.SchoolRelatedLink = SchoolRelatedResponse.Results;

        }

      }, error => {
        console.log(error);

      });
    } else if (selectedSchoolIds.length == 0) {
      this.dialogService.openShowDialog("Please Select School");
    }

  }

  downloadTxtFile() {

    this.dialogService
      .openConfirmDialog("Do you want to download?")
      .afterClosed()
      .subscribe(confResp => {
        if (confResp) {
          //let pdfReportUrl = this.Constants.Services.BaseUrl + 'Lighthouse/DownloadFile?fileUrl=' + this.SchoolRelatedLink;
          let pdfReportUrl = this.SchoolRelatedLink;
          //this.Constants.Messages.SchoolImportInProgressMessage
        }

      });

  }

  //Create school form and returns {FormGroup}
  createInterfaceForm(): FormGroup {
    return this.formBuilder.group({
      //SchoolId: new FormControl(this.schoolModel.SchoolId),
      SchoolName: new FormControl({ value: this.interfaceModel.SchoolName, disabled: this.PageRights.IsReadOnly }, [Validators.required]),
    });
  }

}
